/** llm:tested */
import m from 'mithril'
import {countries} from '@bitstillery/common/lib/countries'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {Warehouse} from '@/models/data'
import {$m} from '@/app'

interface CanDeactivateResponse {
    success: boolean
    result?: {
        can_deactivate: boolean
        purchase_orders_count: number
        sales_order_count: number
        warehouse: any
    }
    message?: string
}

export class WarehouseDeactivate extends MithrilTsxComponent<any> {
    destination: any
    can_deactivate: any
    purchase_orders_count: any
    sales_orders_count: any
    supplier_artkey: string | undefined

    constructor() {
        super()
        this.destination = window.prop(new Warehouse())
        this.can_deactivate = window.prop(false)
        this.purchase_orders_count = window.prop(false)
        this.sales_orders_count = window.prop(false)

        const warehouse_artkey = m.route.param('artkey')
        if (warehouse_artkey) {
            this.query_warehouse(warehouse_artkey)
        }

        this.supplier_artkey = m.route.param('supplier_artkey')
    }

    query_warehouse(artkey: string) {
        api.call('destinations.can_deactivate_destination', {artkey}, this.set_warehouse.bind(this))
    }

    set_warehouse(resp: CanDeactivateResponse) {
        if (resp.success && resp.result) {
            this.can_deactivate(resp.result.can_deactivate)
            this.purchase_orders_count(resp.result.purchase_orders_count)
            this.sales_orders_count(resp.result.sales_order_count)
            this.destination($m.data.warehouses.create_warehouse(resp.result.warehouse))
        } else {
            const msg = resp.message || 'Something went wrong while retrieving the warehouse. Please try again later.'
            notifier.notify(msg, 'danger')
            m.route.set('/data/warehouses')
        }
    }

    deactivate_destination() {
        if (this.supplier_artkey) {
            api.call('destinations.unlink_supplier_warehouse',
                {warehouse_artkey: this.destination().artkey(), supplier_artkey: this.supplier_artkey},
                this.handle_deactivate_destination.bind(this))
        } else {
            api.call('destinations.deactivate_destination',
                {artkey: this.destination().artkey()},
                this.handle_deactivate_destination.bind(this))
        }
    }

    handle_deactivate_destination(resp: { success: boolean }) {
        if (resp.success) {
            $m.common.observable.broadcast('warehouses_updated', '')
            notifier.notify(`Successfully deactivated ${this.destination().destination_type()} ${this.destination().name()}.`, 'success')
            if (this.supplier_artkey) {
                m.route.set(`/crm/relations/${this.supplier_artkey}/edit/`)
            } else {
                m.route.set('/data/warehouses')
            }
        } else {
            notifier.notify(`Deactivation of warehouse ${this.destination().name()} failed.`, 'danger')
        }
    }

    redirect_after_cancel() {
        if (this.supplier_artkey) {
            m.route.set(`/crm/relations/${this.supplier_artkey}/edit/warehouses/${this.destination().artkey()}/edit`)
        } else {
            m.route.set(`/data/warehouses/${this.destination().artkey()}/edit`)
        }
    }

    view() {
        return (
            <div class="deactivate-warehouse view">
                <div class="row" id="button-bar">
                    <div class="col-lg-12 btn-toolbar">
                        <button class="btn btn-link" type="button"
                            onclick={() => m.route.set(`/data/warehouses/${this.destination().artkey()}/edit`)}>
                            <span class="glyphicon glyphicon-arrow-left"></span> Back to warehouse
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12">
                        <h2>Deactivate warehouse</h2>
                    </div>
                </div>

                {this.can_deactivate() ? (
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                            <div class="alert alert-info">
                                Would you like to deactivate the following warehouse?
                            </div>
                        </div>
                    </div>
                ) : (
                    <div class="row">
                        <div class="col-md-6 col-xs-12">
                            <div class="alert alert-warning">
                                The following warehouse <strong>cannot be deactivated</strong> because it has:
                                <ul>
                                    {this.purchase_orders_count() > 0 &&
                                        <li>{this.purchase_orders_count()} purchase orders</li>}
                                    {this.sales_orders_count() > 0 &&
                                        <li>{this.sales_orders_count()} sales orders</li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}

                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4>{this.destination().name()}</h4>
                            </div>
                            <div class="panel-body">
                                <dl class="dl-horizontal">
                                    <dt>Name</dt>
                                    <dd>{this.destination().name()}</dd>
                                    <dt>Email</dt>
                                    <dd>{this.destination().emailaddress()}</dd>
                                    <dt>Address</dt>
                                    <dd>{this.destination().street_address()}</dd>
                                    <dt>Zip code</dt>
                                    <dd>{this.destination().zip_code()}</dd>
                                    <dt>City</dt>
                                    <dd>{this.destination().city()}</dd>
                                    <dt>Country</dt>
                                    <dd>{countries[this.destination().country_code()]}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-3 col-xs-6">
                        <button class="form-control btn btn-default"
                            onclick={() => this.redirect_after_cancel()}>
                            Cancel
                        </button>
                    </div>
                    {this.can_deactivate() && (
                        <div class="col-md-3 col-xs-6">
                            <button class="form-control btn btn-success"
                                onclick={() => this.deactivate_destination()}>
                                Deactivate
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
