import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {displayable_integer, format_iso_to_date_time} from '@bitstillery/common/ts_utils'
import {Spinner} from '@bitstillery/common/components'
import {Amount} from '@bitstillery/common/components'

import {RequeryListener} from './reports'

import {Decimal} from '@/components/html_components'
import {
    ReportArguments,
    ReportingApi,
    ReportingMetaElement,
    ReportingMetaInformationResponse,
} from '@/factserver_api/reporting_api'

export interface ReportMetaInformationAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

/**
 * Displays the meta information for the report specified in the report arguments.
 */
export class ReportMetaInformation extends MithrilTsxComponent<ReportMetaInformationAttrs> implements RequeryListener {
    reporting_api = new ReportingApi()
    reporting_arguments: ReportArguments
    meta_information: ReportingMetaInformationResponse | null = null
    is_loading = false

    constructor(vnode: m.Vnode<ReportMetaInformationAttrs>) {
        super()

        this.reporting_arguments = vnode.attrs.report_arguments
        this.fetch_meta_information()
        vnode.attrs.add_requery_listener(this)
    }

    onremove(vnode: m.Vnode<ReportMetaInformationAttrs>): void {
        vnode.attrs.add_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        if (!report_arguments.report) {
            return
        }

        this.reporting_arguments = report_arguments
        this.fetch_meta_information()
    }

    fetch_meta_information(): void {
        this.is_loading = true
        this.reporting_api.meta_information_for(this.reporting_arguments).subscribe({
            next: (response: ReportingMetaInformationResponse) => {
                this.meta_information = response
                if (response) {
                    this.meta_information.meta.sort((a: ReportingMetaElement, b: ReportingMetaElement) =>
                        a.label.localeCompare(b.label),
                    )
                }
                this.is_loading = false
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    format_value(value: string | number, value_type: string): JSX.Element | string {
        if (value === null) {
            return ''
        } else if (value_type === 'MONEY') {
            return <Amount amount={value as number} currency={'EUR'} />
        } else if (value_type === 'INTEGER') {
            if (value) {
                value = (+value).toFixed(0)
            }
            return displayable_integer(value as number)
        } else if (value_type === 'DECIMAL') {
            return <Decimal value={value} number_of_fraction_digits={2} />
        } else if (value_type === 'DATETIME') {
            return format_iso_to_date_time(`${value}`)
        } else if (value_type === 'STRING') {
            return value as string
        }
        return ''
    }

    view(): m.Children {
        return (
            <div>
                <hr />
                {!this.is_loading && this.meta_information && (
                    <div className={'columns'}>
                        <div className="column">
                            {this.meta_information.meta
                                .slice(0, Math.ceil(this.meta_information.meta.length / 2))
                                .map((meta) => (
                                    <div className="field-readonly">
                                        <div className="key">{meta.label}</div>
                                        <div className="value">
                                            {this.format_value(meta.value, meta.type)}
                                            {meta.unit ? ` ${meta.unit}` : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className="column">
                            {this.meta_information.meta
                                .slice(-1 * Math.ceil(this.meta_information.meta.length / 2))
                                .map((meta) => (
                                    <div className="field-readonly">
                                        <div className="key">{meta.label}</div>
                                        <div className="value">
                                            {this.format_value(meta.value, meta.type)}
                                            {meta.unit ? ` ${meta.unit}` : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
                {this.is_loading && <Spinner />}
                <hr />
            </div>
        )
    }
}
