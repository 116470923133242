/** llm:tested */
import m from 'mithril'
import {Amount} from '@bitstillery/common/components'
import {orList} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import table from '@/components/table'
import {Collection} from '@/components/collection/collection'
import {from_json} from '@/market/pricelists/components/discover_link'
import utils from '@/_utils'
import {pricelist_link} from '@/data/components/pricelist_link'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {$s} from '@/app'

const BEST_BUY_DAYS = 7

interface Spli {
    bottle: {
        product: {
            name: string
            category: string
        }
        volume: number
        alcohol_percentage: number
        refill_status: string
    }
    supplier_price_list: {
        artkey: string
        supplier: {
            name: string
        }
        start_date: string
        creation_date: string
    }
    aux_info: string
    gift_box_type: string
    rank: number
    total: number
    number_of_bottles_per_case: number
    price_per_case: number
    price_per_bottle: number
    currency: string
    customs_status: string
    incoterm: string
    availability_status: string
}

export class MarketBestBuys extends MithrilTsxComponent<any> {
    splis: Collection
    search_bar_controller: SearchBarControl | null
    product_management_api: ProductManagementApi

    constructor() {
        super()
        this.search_bar_controller = null
        this.product_management_api = new ProductManagementApi()

        this.splis = new Collection({
            api_function_name: 'marketanalysis.get_supplier_price_list_items',
            filter_function: this.is_match.bind(this),
            query_limit: 20,
            additional_params: window.prop({
                only_splis_since_days: BEST_BUY_DAYS,
            }),
            sort_order: [
                {name: 'artkey', direction: 'asc'},
            ],
            default_sort_by: 'score',
            default_sort_order: 'asc',
        })
    }

    submit_search(text: string): void {
        this.splis.update_search_term(text)
        this.splis.submit_search()
    }

    oncreate(): void {
        this.splis.init()

        const q = m.route.param('q')
        if (q) {
            this.submit_search(q.replace(/\+/g, ' '))
        }
    }

    is_match(spli: Spli, term: string): boolean {
        return orList([
            spli.bottle.product.name.toLowerCase().indexOf(term) > -1,
            +spli.bottle.volume === +term,
            +spli.bottle.alcohol_percentage === +term,
            spli.aux_info.toLowerCase().indexOf(term) > -1,
            spli.gift_box_type.toLowerCase().indexOf(term) > -1,
        ])
    }

    view(): m.Vnode {
        const real_discover_link = (bottle_json: any) => {
            return <a class="discover-link" target="_blank" href={from_json(bottle_json)}>
                <span class="glyphicon glyphicon-search"/>
            </a>
        }

        return <div class="c-market-best-buys view">
            <div class="c-filter-group">
                <SearchBar
                    placeholder="Search products..."
                    on_submit={this.submit_search.bind(this)}
                    default_search_text={this.splis.search_term()}
                    search_bar_controller={(search_bar_controller: SearchBarControl) => this.search_bar_controller = search_bar_controller}
                    on_get_suggestions$={(filter_text: string) => this.product_management_api.get_simple_product_names(filter_text)}
                />
            </div>

            {this.splis.show_counter()}

            {table.collection_table(this.splis, [
                {
                    width: 3,
                    function: (spli: Spli) =>
                        m(m.route.Link,
                            {href: pricelist_link.from_bottle_json(spli.supplier_price_list.artkey, spli.bottle)},
                            m('span.glyphicon.glyphicon-new-window'),
                        ),
                },
                {
                    width: 8,
                    field: 'supplier_price_list.supplier.name',
                    name: 'Relation',
                    ellipsis: true,
                },
                {
                    width: 5,
                    name: 'Start date',
                    function: (spli: Spli) => utils.formatDate(spli.supplier_price_list.start_date),
                    sort: true,
                    field: 'supplier_price_list.start_date',
                },
                {
                    width: 5,
                    name: 'Created',
                    function: (spli: Spli) => utils.formatDate(spli.supplier_price_list.creation_date),
                    sort: true,
                    field: 'supplier_price_list.creation_date',
                },
                {
                    width: 3,
                    sort: true,
                    field: 'score',
                    name: 'Rank',
                    function: (spli: Spli) => `${spli.rank} / ${spli.total}`,
                    classes: 'text-center',
                },
                {
                    width: 3,
                    function: (spli: Spli) => real_discover_link(spli.bottle),
                },
                {
                    width: 10,
                    sort: true,
                    field: 'bottle.product.name',
                    name: 'Product',
                    ellipsis: true,
                },
                {
                    width: 4,
                    sort: true,
                    field: 'number_of_bottles_per_case',
                    name: 'Btl / cs',
                    classes: 'number',
                },
                {
                    width: 5,
                    sort: true,
                    field: 'bottle.volume',
                    name: 'Size',
                    classes: 'number',
                    function: (spli: Spli) => (+spli.bottle.volume).toFixed(1),
                },
                {
                    width: 5,
                    sort: true,
                    field: 'bottle.alcohol_percentage',
                    name: 'Alc %',
                    classes: 'number',
                    function: (spli: Spli) => (+spli.bottle.alcohol_percentage).toFixed(1),
                },
                {
                    width: 3,
                    sort: true,
                    field: 'bottle.refill_status',
                    name: 'Ref',
                },
                {
                    width: 3,
                    sort: true,
                    field: 'gift_box_type',
                    name: 'GB',
                    ellipsis: true,
                },
                {
                    width: 7,
                    sort: true,
                    field: 'aux_info',
                    name: 'Aux info',
                    ellipsis: true,
                },
                {
                    width: 7,
                    sort: true,
                    field: 'euro_price_per_case',
                    name: '€ / cs',
                    classes: 'price',
                    function: (spli: Spli) =>
                        <Amount
                            amount={+spli.price_per_case}
                            currency={spli.currency}
                            display_currency={$s.currencies.default}
                        />,
                },
                {
                    width: 7,
                    sort: true,
                    field: 'euro_price_per_bottle',
                    name: '€ / btl',
                    classes: 'price',
                    function: (spli: Spli) =>
                        <Amount
                            amount={+spli.price_per_bottle}
                            currency={spli.currency}
                            display_currency={$s.currencies.default}
                        />,
                },
                {
                    width: 4,
                    sort: true,
                    field: 'customs_status',
                    name: 'Customs',
                },
                {
                    width: 6,
                    sort: true,
                    field: 'bottle.product.category',
                    name: 'Category',
                    ellipsis: true,
                    function: (spli: Spli) => <span class="text-capitalize">{spli.bottle.product.category}</span>,
                },
                {
                    width: 7,
                    sort: true,
                    field: 'incoterm',
                    name: 'Incoterm',
                    ellipsis: true,
                },
                {
                    width: 5,
                    sort: true,
                    field: 'availability_status',
                    name: 'Availability',
                },
            ])}
        </div>
    }
}
