/** llm:tested */
import m from 'mithril'
import {deref} from '@bitstillery/common/utils'
import {notifier} from '@bitstillery/common/app'

import api_ls from '@/api'
import utils from '@/_utils'
import {button_with_icon} from '@/components/_buttons'
import inputs from '@/components/inputs'
import {LogType} from '@/components/logbook/models'

interface LogEntry {
    log_type: LogType
    created_on: string
    created_by: {
        profile: {
            name: string
        }
    }
    message: string
}

interface LogEntriesAttrs {
    log_entries: LogEntry[]
}

interface AddLogEntryAttrs {
    artkey: any
    process: string
    on_add_callback: () => void
}

const log_type_choices: [LogType, string][] = [
    [LogType.MESSAGE, 'Message'],
    [LogType.CONTACT_UPDATE, 'Contact update'],
]

export const LogEntries = (initial_vnode: m.Vnode<LogEntriesAttrs>): m.Component<LogEntriesAttrs> => {
    const {log_entries} = initial_vnode.attrs

    return {
        view: (_vnode: m.Vnode<LogEntriesAttrs>) => [
            log_entries.length > 0 && (
                <div class="logbook">
                    <ul class="timeline">
                        {log_entries.map((log_entry) => (
                            <li>
                                {log_entry.log_type === LogType.CONTACT_UPDATE ? (
                                    <icon class="fa-user timeline-icon" />
                                ) : (
                                    <icon class="fa-envelope timeline-icon" />
                                )}
                                <div class="timeline-item">
                                    <span class="time">{utils.formatDateTime(log_entry.created_on)}</span>
                                    <h3 class="timeline-header">{log_entry.created_by.profile.name}</h3>
                                    <div class="timeline-body">{log_entry.message}</div>
                                </div>
                            </li>
                        ))}
                        <li>
                            <icon class="far.fa-clock timeline-icon" />
                        </li>
                    </ul>
                </div>
            ),
        ],
    }
}

export const AddLogEntry = (initial_vnode: m.Vnode<AddLogEntryAttrs>): m.Component<AddLogEntryAttrs> => {
    const {artkey, process, on_add_callback} = initial_vnode.attrs

    const message = window.prop('')
    const log_type = window.prop('')
    const saving = window.prop(false)

    const submit = () => {
        saving(true)
        const data = {
            artkey: deref(artkey),
            log_type: log_type(),
            message: message(),
        }

        api_ls.callAndThen(process, data, {
            success: () => {
                notifier.notify('Log entry added successfully.', 'success')
                on_add_callback()
            },
            final: () => {
                saving(false)
            },
        })
    }

    return {
        view: (_vnode: m.Vnode<AddLogEntryAttrs>) => [
            <form class="form-horizontal" onsubmit={utils.preventDefault(submit)}>
                {inputs.field('Type', inputs.select(log_type, log_type_choices))}
                {inputs.field('Message', inputs.textarea(message))}
                {inputs.field('', button_with_icon('Add Log Entry', 'fa-pen', {
                    class: 'btn-primary',
                    disabled: saving(),
                }))}
            </form>,
        ],
    }
}
