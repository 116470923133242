/** llm:tested */
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api, notifier} from '@bitstillery/common/app'

import api_ls from '@/api'
import {CheckboxGroup} from '@/components/filter/checkboxgroup'
import {Slider} from '@/components/filter/slider'
import {icon} from '@/components/icon'
import inputs from '@/components/inputs'
import {Modal} from '@/components/modal/modal'
import {removeFromArrayProp} from '@/_utils'
import {FilterPresetHandler, FilterPresetType} from '@/filter_preset/filter_preset'
import {SaveFilterPresetAs} from '@/filter_preset/save_filter_preset_as'
import {$m} from '@/app'

export class ProductSearchMode {
    static OFFER = 'offer'
    static PURCHASE = 'purchase'
    static MARKET = 'market'
}

interface ProductFilterSidebarAttrs {
    collection: any
    mode?: string
}

export class ProductFilterSidebar extends MithrilTsxComponent<ProductFilterSidebarAttrs> {
    collection: any
    mode: string
    show_save_modal: any
    product_categories: any
    brands: any
    loading_categories: any
    loading_brands: any
    filter_presets: any
    filter_preset_choices: any
    selected_filter_preset_artkey: any
    loading_filter_presets: any
    filter_preset_type: FilterPresetType
    filter_preset_handler: FilterPresetHandler

    constructor(vnode: m.Vnode<ProductFilterSidebarAttrs>) {
        super()
        this.collection = vnode.attrs.collection
        this.mode = vnode.attrs.mode || ProductSearchMode.OFFER
        this.show_save_modal = window.prop(false)

        this.product_categories = window.prop([])
        this.brands = window.prop([])
        this.loading_categories = window.prop(true)
        this.loading_brands = window.prop(true)

        this.filter_presets = window.prop([])
        this.filter_preset_choices = window.prop([])
        this.selected_filter_preset_artkey = window.prop(null)
        this.loading_filter_presets = window.prop(true)

        if (this.mode === ProductSearchMode.OFFER) {
            this.filter_preset_type = FilterPresetType.SELECT_OFFER_ITEM
        } else if (this.mode === ProductSearchMode.PURCHASE) {
            this.filter_preset_type = FilterPresetType.SELECT_PURCHASE_ORDER_ITEM
        } else if (this.mode === ProductSearchMode.MARKET) {
            this.filter_preset_type = FilterPresetType.SELECT_MARKET_ITEM
        }

        this.filter_preset_handler = new FilterPresetHandler(
            this.filter_preset_type,
            this.filter_presets,
            this.filter_preset_choices,
            this.selected_filter_preset_artkey,
            this.loading_filter_presets,
        )
    }

    oncreate() {
        this.get_categories()
        this.get_brands()
        this.filter_preset_handler.get_filter_presets()
    }

    async get_categories() {
        const {result} = await api.get('discover/product-categories/select')
        this.product_categories(result.map((it: any) => [it.artkey, it.name]))
    }

    get_brands() {
        api_ls.callAndThen('brands.get_all', {}, {
            success: (resp: any) => {
                this.loading_brands(false)
                const brands = resp.result
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({artkey, name, brand_holder}: any) => [artkey, name, brand_holder['name']])
                this.brands(brands)

                // Add brand holder name to duplicates:
                // 1) First identify the duplicates.
                const all_brand_names: string[] = []
                const duplicate_brand_names: string[] = []
                for (const brand of this.brands()) {
                    if (all_brand_names.includes(brand[1])) {
                        duplicate_brand_names.push(brand[1])
                    } else {
                        all_brand_names.push(brand[1])
                    }
                }

                // 2) Then add the brand holder name to the duplicates.
                for (const brand of this.brands()) {
                    if (duplicate_brand_names.includes(brand[1])) {
                        brand[1] = `${brand[1]} (${brand[2]})`
                    }
                    // Finally, remove the brand holder name element from each tuple in @brands.
                    brand.pop()
                }
            },
        })
    }

    set_filter_preset(artkey: number) {
        this.selected_filter_preset_artkey(+artkey)

        if (artkey) {
            // Find the filter preset object for the provided artkey.
            const preset = this.filter_presets().filter((p: any) => p.artkey === this.selected_filter_preset_artkey()).at(0)

            // Load the filter parameters from the object into the collection.
            if (preset) {
                for (const parameter_name of Object.keys(preset['filters'])) {
                    if (parameter_name in Object.keys(this.collection.filters)) {
                        this.collection.filters[parameter_name](preset['filters'][parameter_name])
                    } else {
                        notifier.notify(`The filter parameter with name ${parameter_name} is not recognized. Check your filter settings and save the preset again to fix this.`, 'warning')
                    }
                }
            }

            // Apply the filter parameters to the collection.
            this.collection.requery()
        }
    }

    process_save_response(artkey: number) {
        this.filter_preset_handler.get_filter_presets()
        this.selected_filter_preset_artkey(artkey)
        this.show_save_modal(false)
    }

    close_save_modal() {
        this.show_save_modal(false)
    }

    reset_all_filters() {
        window.location.reload(true)
    }

    view() {
        if (typeof this.collection.filters === 'undefined') return null

        return <div class="c-filter-sidebar">
            <div class="row"><div class="col-xs-12">
                <label class="control-label">Select a preset</label>
            </div></div>

            <div class="row"><div class="col-xs-12 mb-1">
                {this.loading_filter_presets() ?
                    <Spinner /> :
                    <select
                        value={this.selected_filter_preset_artkey()}
                        onchange={(e: any) => this.set_filter_preset(e.target.value)}
                        disabled={this.filter_preset_choices().length < 2}
                    >
                        {this.filter_preset_choices().map((choice: any) =>
                            <option value={choice[0]}>{choice[1]}</option>,
                        )}
                    </select>
                }
            </div></div>

            <div class="row"><div class="col-xs-12"><div class="btn-group">
                <button
                    class="btn btn-success"
                    onclick={() => this.show_save_modal(true)}
                    disabled={this.loading_filter_presets()}
                    title="Save filter preset"
                >
                    <i class="fa fa-save"></i> Save
                </button>
                <button
                    class="btn btn-danger"
                    onclick={() => this.filter_preset_handler.delete_filter_preset()}
                    disabled={this.loading_filter_presets() || this.selected_filter_preset_artkey() === null}
                    title="Delete filter preset"
                >
                    <i class="fa fa-remove"></i> Delete
                </button>
            </div></div></div>

            <hr />

            <div class="btn-group">
                <button
                    class="btn btn-success"
                    onclick={() => this.collection.requery()}
                    disabled={this.collection.loading()}
                >
                    <i class="glyphicon glyphicon-filter"></i> Apply
                </button>
                <button
                    class="btn btn-default"
                    onclick={() => this.reset_all_filters()}
                >
                    <i class="fa fa-undo"></i> Reset
                </button>
            </div>

            <hr />

            {this.mode === ProductSearchMode.OFFER && [
                <label class="control-label">Offer item type</label>,
                <div>
                    <div class="btn-group mb-1">
                        <button
                            class={this.collection.filters['offer_item_types']().includes('purchase') ? 'btn btn-primary' : 'btn btn-default'}
                            onclick={() => this.collection.filters['offer_item_types']().includes('purchase') ?
                                removeFromArrayProp(this.collection.filters['offer_item_types'], 'purchase') :
                                this.collection.filters['offer_item_types']().push('purchase')}
                        >
                            Purchase
                        </button>
                        <button
                            class={this.collection.filters['offer_item_types']().includes('stock') ? 'btn btn-primary' : 'btn btn-default'}
                            onclick={() => this.collection.filters['offer_item_types']().includes('stock') ?
                                removeFromArrayProp(this.collection.filters['offer_item_types'], 'stock') :
                                this.collection.filters['offer_item_types']().push('stock')}
                        >
                            Stock
                        </button>
                        <button
                            class={this.collection.filters['offer_item_types']().includes('tbo') ? 'btn btn-primary' : 'btn btn-default'}
                            onclick={() => this.collection.filters['offer_item_types']().includes('tbo') ?
                                removeFromArrayProp(this.collection.filters['offer_item_types'], 'tbo') :
                                this.collection.filters['offer_item_types']().push('tbo')}
                        >
                            TBO
                        </button>
                    </div>
                </div>,
            ]}

            {this.mode === ProductSearchMode.OFFER && [
                <label class="control-label">Account</label>,
                <div>
                    <div class="btn-group mb-1">
                        <button
                            class={this.collection.filters['account_slugs']().includes('msi') ? 'btn btn-primary' : 'btn btn-default'}
                            onclick={() => this.collection.filters['account_slugs']().includes('msi') ?
                                removeFromArrayProp(this.collection.filters['account_slugs'], 'msi') :
                                this.collection.filters['account_slugs']().push('msi')}
                        >
                            MSI
                        </button>
                        <button
                            class={this.collection.filters['account_slugs']().includes('msp') ? 'btn btn-primary' : 'btn btn-default'}
                            onclick={() => this.collection.filters['account_slugs']().includes('msp') ?
                                removeFromArrayProp(this.collection.filters['account_slugs'], 'msp') :
                                this.collection.filters['account_slugs']().push('msp')}
                        >
                            MSP
                        </button>
                        <button
                            class={this.collection.filters['account_slugs']().includes('a2bc') ? 'btn btn-primary' : 'btn btn-default'}
                            onclick={() => this.collection.filters['account_slugs']().includes('a2bc') ?
                                removeFromArrayProp(this.collection.filters['account_slugs'], 'a2bc') :
                                this.collection.filters['account_slugs']().push('a2bc')}
                        >
                            A2BC
                        </button>
                        <button
                            class={this.collection.filters['account_slugs']().includes('etr') ? 'btn btn-primary' : 'btn btn-default'}
                            onclick={() => this.collection.filters['account_slugs']().includes('etr') ?
                                removeFromArrayProp(this.collection.filters['account_slugs'], 'etr') :
                                this.collection.filters['account_slugs']().push('etr')}
                        >
                            ETR
                        </button>
                    </div>
                </div>,
            ]}

            <div class="btn-group filter-radio mb-1">
                <label class="control-label">Customs status</label>
                <div>
                    {inputs.radio(this.collection.filters['customs_status'], [
                        {value: 'T1', description: 'T1'},
                        {value: 'T2', description: 'T2'},
                        {value: '', description: 'Both'},
                    ])}
                </div>
            </div>

            <div class="btn-group filter-radio mb-1">
                <label class="control-label">Refill status</label>
                <div>
                    {inputs.radio(this.collection.filters['refill_status'], [
                        {value: 'ref', description: 'Ref'},
                        {value: 'nonref', description: 'Nonref'},
                        {value: '', description: 'Both'},
                    ])}
                </div>
            </div>

            {this.mode in [ProductSearchMode.OFFER, ProductSearchMode.PURCHASE] &&
                <div class="btn-group filter-radio mb-1">
                    <label class="control-label">Tax label</label>
                    <div>
                        {inputs.radio(this.collection.filters['tax_label'], [
                            {value: 'UKDS', description: 'UKDS'},
                            {value: '', description: 'None'},
                            {value: 'any', description: 'Any'},
                        ])}
                    </div>
                </div>
            }

            {this.mode === ProductSearchMode.OFFER && [
                <div class="btn-group filter-radio mb-1">
                    <label class="control-label">Show hidden items</label>
                    <div>
                        {inputs.radio(this.collection.filters['hidden_selection'], [
                            {value: 'visible_only', description: icon('eye-open')},
                            {value: 'hidden_only', description: icon('eye-close')},
                            {value: 'all', description: 'All'},
                        ])}
                    </div>
                </div>,

                <div class="btn-group filter-radio mb-1">
                    <label class="control-label">Arriving soon</label>
                    <div>
                        {inputs.radio(this.collection.filters['arriving_soon'], [
                            {value: true, description: icon('road')},
                            {value: false, description: 'All'},
                        ])}
                    </div>
                </div>,
            ]}

            <hr />

            {this.mode === ProductSearchMode.OFFER && [
                <label class="control-label">Stock age</label>,
                <div class="mb-1">
                    {m(Slider, {
                        prop: this.collection.filters['stock_age_range'],
                        options: {
                            range: {
                                min: 0,
                                '50%': 31,
                                '70%': 100,
                                max: 1000,
                            },
                            infinity: true,
                        },
                    })}
                </div>,

                <label class="control-label">Last price change age</label>,
                <div class="mb-1">
                    {m(Slider, {
                        prop: this.collection.filters['last_price_change_age_days_range'],
                        options: {
                            range: {
                                min: 0,
                                '50%': 31,
                                '70%': 100,
                                max: 1000,
                            },
                            infinity: true,
                        },
                    })}
                </div>,
            ]}

            <label class="control-label">Bottles per case</label>
            <div class="mb-1">
                {m(Slider, {
                    prop: this.collection.filters['bottles_per_case_range'],
                    options: {
                        range: {
                            min: 0,
                            '60%': 25,
                            '80%': 100,
                            '90%': 250,
                            max: 1000,
                        },
                        infinity: true,
                    },
                })}
            </div>

            <label class="control-label">Volume</label>
            <div class="mb-1">
                {m(Slider, {
                    prop: this.collection.filters['volume_range'],
                    options: {
                        range: {
                            min: 0,
                            '40%': 70,
                            '60%': 100,
                            '80%': 1000,
                            max: 10000,
                        },
                        infinity: true,
                    },
                })}
            </div>

            <label class="control-label">Alcohol percentage</label>
            <div class="mb-1">
                {m(Slider, {
                    prop: this.collection.filters['alcohol_percentage_range'],
                    options: {
                        range: {
                            min: 0,
                            '80%': 60,
                            max: 100,
                        },
                    },
                })}
            </div>

            {this.mode === ProductSearchMode.OFFER && [
                <label class="control-label">List quantity</label>,
                <div class="mb-1">
                    {m(Slider, {
                        prop: this.collection.filters['list_quantity_range'],
                        options: {
                            range: {
                                min: 0,
                                '20%': 10,
                                '50%': 50,
                                '70%': 100,
                                '80%': 200,
                                '90%': 1000,
                                max: 10000,
                            },
                            infinity: true,
                        },
                    })}
                </div>,

                <label class="control-label">Base price</label>,
                <div class="mb-1">
                    {m(Slider, {
                        prop: this.collection.filters['base_price_range'],
                        options: {
                            range: {
                                min: 0,
                                '50%': 100,
                                '70%': 200,
                                '90%': 1000,
                                max: 10000,
                            },
                            infinity: true,
                        },
                    })}
                </div>,
            ]}

            <hr />

            {this.loading_categories() ?
                <Spinner /> :
                m(CheckboxGroup, {
                    filter_function: this.collection.filters['categories'],
                    filter_id: 'category',
                    filter_name: 'Category',
                    filter_options: this.product_categories(),
                })
            }

            {this.loading_brands() ?
                <Spinner /> :
                m(CheckboxGroup, {
                    filter_function: this.collection.filters['brands'],
                    filter_id: 'brand',
                    filter_name: 'Brand',
                    filter_options: this.brands(),
                    show_no_value_checkbox: true,
                })
            }

            {m(CheckboxGroup, {
                filter_function: this.collection.filters['gift_box_types'],
                filter_id: 'gift_box_type',
                filter_name: 'Gift box type',
                filter_options: $m.data.gift_box_types.map((gb: string) => [gb, gb]),
                show_no_value_checkbox: true,
            })}

            {this.show_save_modal() &&
                m(Modal, {
                    title: 'Save filter preset as',
                    onclose: () => this.close_save_modal(),
                }, m(SaveFilterPresetAs, {
                    filter_preset_data: this.filter_preset_handler.get_save_request_data(this.collection.filters),
                    done: (artkey: number) => this.process_save_response(artkey),
                    cancel: () => this.close_save_modal(),
                }))
            }
        </div>
    }
}
