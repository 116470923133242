/** llm:tested */
import m from 'mithril'
import {map, compact} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {matchTermIn} from '@/_utils'
import api from '@/api'
import {icon_button, button_with_icon} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import {CollectionTable} from '@/components/collection_table'
import {$m} from '@/app'

interface Creator {
    profile: {
        name: string
    }
}

interface Quote {
    artkey: string
    title: string
    content: string
    name: string
    image_name: string
    creator: Creator
}

export class PortalQuotesList extends MithrilTsxComponent<any> {
    entities: any
    search_input_ctrl: any

    constructor() {
        super()
        this.entities = new Collection({
            api_function_name: 'portal.quote.get_quotes',
            filter_function: this.is_match,
            query_limit: 25,
        })

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.entities,
            placeholder: 'Search for portal quote...',
        })
    }

    oncreate() {
        this.entities.init()
    }

    is_match(quote: Quote, term: string) {
        return matchTermIn(term, map((x: string) => x.toLowerCase(), compact([
            quote.title,
            quote.content,
        ])))
    }

    onremove() {
        this.search_input_ctrl.onremove()
    }

    mark_deleted(artkey: string) {
        api.call('portal.quote.mark_deleted', {artkey}, (resp: any) => {
            if (resp.success) {
                notifier.notify('Successfully deleted quote', 'success')
                this.entities.soft_delete(artkey)
            } else {
                // Note: Keeping original error handler pattern
                $m.common.generic_error_handler()
            }
        })
    }

    view() {
        return <div class="c-portal-quotes view">
            <div class="btn-toolbar">
                {button_with_icon(
                    'Create Portal Quote',
                    'plus',
                    {
                        class: 'btn-default',
                        onclick: () => m.route.set('/portal/portal-quotes/create'),
                    },
                )}
            </div>

            <div class="c-filter-group">
                {SearchInput.view(this.search_input_ctrl)}
            </div>

            <CollectionTable
                collection={this.entities}
                options={{
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: true,
                    autoscale: true,
                    unique_name: 'portal_quotes',
                    onclick: (record: Quote) => {
                        return () => {
                            m.route.set(`/portal/portal-quotes/${record.artkey}/edit`)
                        }
                    },
                }}
                columns={[
                    {
                        width: 2,
                        name: 'Name',
                        field: 'name',
                        sort: true,
                    },
                    {
                        width: 1,
                        name: 'Image name',
                        field: 'image_name',
                        sort: true,
                    },
                    {
                        width: 8,
                        name: 'Message',
                        field: 'content',
                        sort: true,
                        ellipsis: true,
                    },
                    {
                        width: 2,
                        name: 'Author',
                        field: 'creator.profile.name',
                        sort: true,
                    },
                    {
                        width: 1,
                        header: '',
                        name: 'Actions',
                        function: (record: Quote) => {
                            return <div class="btn-toolbar no-click">
                                <div class="btn-group">
                                    {icon_button('pencil', {
                                        class: 'btn-default no-click',
                                        onclick: () => m.route.set(`/portal/portal-quotes/${record.artkey}/edit`),
                                    })}
                                    {icon_button('remove', {
                                        class: 'btn-danger no-click',
                                        onclick: () => this.mark_deleted(record.artkey),
                                    })}
                                </div>
                            </div>
                        },
                    },
                ]}
            />
        </div>
    }
}
