import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {required, validation} from '@bitstillery/common/lib/validation'
import {notifier} from '@bitstillery/common/app'
import {FieldSelect, FieldText} from '@bitstillery/common/components'

import {DangerButton} from '@/components/buttons'
import {BackToListButton} from '@/components/discover'
import {EditAssist, Form} from '@/components/form'
import {AttachmentApi, CreateOrUpdateAttachmentCategoryRequest} from '@/factserver_api/attachment_api'
import {LoggedInUserData, User} from '@/factserver_api/user_api'

export enum AttachmentType {
    ITEM_MUTATION = 'Item Mutation',
    PURCHASE_ORDER = 'Purchase Order',
    RELATION = 'Relation',
    SALES_ORDER = 'Sales Order',
    WAREHOUSE = 'Warehouse',
}

export default class AttachmentCategoryEdit extends MithrilTsxComponent<unknown> {
    api = new AttachmentApi()
    create_or_update_attachment_category_request: CreateOrUpdateAttachmentCategoryRequest = {
        name: '',
        attachment_type: '',
        loendersloot_document_types: '',
    }

    edit_assist: EditAssist
    user: User | null = null
    $v = {
        name: validation([this.create_or_update_attachment_category_request, 'name'], required()),
    }

    constructor() {
        super()
        this.edit_assist = new EditAssist(m.route)
    }

    create_or_update_attachment_category(): void {
        this.api.create_or_update_attachment_category(this.create_or_update_attachment_category_request).subscribe({
            next: () => {
                if (this.edit_assist.is_creating) {
                    notifier.notify(
                        `Successfully created attachment category "${this.create_or_update_attachment_category_request.name}"`,
                        'success',
                    )
                } else {
                    notifier.notify(
                        `Successfully updated attachment category "${this.create_or_update_attachment_category_request.name}"`,
                        'success',
                    )
                }
                m.route.set('/data/attachment-categories')
            },
            error: () => {
                m.redraw()
            },
        })
    }

    delete_attachment_category(): void {
        if (this.edit_assist.artkey) {
            this.api.delete_attachment_category(this.edit_assist.artkey).subscribe({
                next: () => {
                    notifier.notify('Successfully deleted the attachment category', 'success')
                    m.route.set('/data/attachment-categories')
                },
                error: () => {
                    m.redraw()
                },
            })
        }
    }

    // Inputs and buttons are disabled for non-superusers.
    disabled(): boolean {
        if (!this.user) {
            return true
        }
        return !this.user.is_superuser
    }

    oncreate(): void {
        LoggedInUserData.user().subscribe({
            next: (value) => {
                this.user = value
            },
        })

        if (this.edit_assist.artkey) {
            this.api.get_attachment_category(this.edit_assist.artkey).subscribe({
                next: (response) => {
                    Object.assign(this.create_or_update_attachment_category_request, {
                        ...response,
                    })
                    m.redraw()
                },
                error: () => {
                    m.redraw()
                    m.route.set('/data/attachment-categories')
                },
            })
        }
    }

    view(): m.Children {
        return (
            <div className="c-data-attachment-categories-edit view">
                <div className="btn-toolbar">
                    <BackToListButton href={'/data/attachment-categories'} />
                    {this.edit_assist.artkey && (
                        <DangerButton
                            icon_class={'glyphicon glyphicon-trash'}
                            onclick={() => this.delete_attachment_category()}
                            title="Delete attachment category"
                            disabled={this.disabled()}
                        />
                    )}
                </div>

                <Form
                    disabled={this.disabled()}
                    onSubmit={() => this.create_or_update_attachment_category()}
                    submitText={this.edit_assist.is_creating ? 'Create Category' : 'Update Category'}
                    validations={this.$v}
                >
                    <div className="fieldset">
                        <FieldText
                            disabled={this.disabled()}
                            label="Name"
                            model={[this.create_or_update_attachment_category_request, 'name']}
                            placeholder={'Category name'}
                            validation={this.$v.name}
                        />

                        <FieldSelect
                            disabled={this.disabled()}
                            label="Type"
                            model={[this.create_or_update_attachment_category_request, 'attachment_type']}
                            onchange={(value: string) => {
                                if (this.create_or_update_attachment_category_request) {
                                    this.create_or_update_attachment_category_request.attachment_type = value
                                }
                            }}
                            options={Object.values(AttachmentType).map((i) => ({label: i, value: i}))}
                            placeholder="-"
                        />

                        {this.create_or_update_attachment_category_request && <FieldText
                            disabled={this.disabled()}
                            label="Loendersloot document types"
                            model={[this.create_or_update_attachment_category_request, 'loendersloot_document_types']}
                            placeholder={'5001, 5002, 9999'}
                        />}

                    </div>
                </Form>
            </div>
        )
    }
}
