import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'

import {Api, create_download_for_blob, FactserverEmptyResponse} from './api'
import {GenerateRedeemCodeResponse} from './fact2server_api'

export interface GetAllLeadsResponse {
    artkey: number
    name: string
    created_on: string
    source: string
    status: string
    contact_status: LeadContactStatus
    memo: string | null
    manager_user_artkey: number | null
    country_code: string
    converted_to_relation_artkey: number | null
    redeem_code: string | null
    redeem_code_valid_until: string | null
}

export enum LeadStatus {
    UPLOADED = 'uploaded',
    REGISTERED = 'registered',
    ARCHIVED = 'archived',
    CONVERTED = 'converted',
}

export enum LeadContactStatus {
    NOT_YET_APPROACHED = 'NOT_YET_APPROACHED',
    APPROACHED = 'APPROACHED',
    FINALIZED = 'FINALIZED',
}

export enum LeadContactStatusChoices {
    NOT_YET_APPROACHED = 'Not yet approached',
    APPROACHED = 'Approached',
    FINALIZED = 'Finalized',
}

export enum LeadSource {
    REGISTRATION_FORM = 'REGISTRATION_FORM',
    DATA_FILE = 'DATA_FILE',
}

export enum LeadDataFileStatus {
    UPLOADING = 'UPLOADING',
    UPLOADING_FAILED = 'UPLOADING_FAILED',
    PROCESSED = 'PROCESSED',
}

export class LeadDataFile {
    artkey: number
    file_name: string
    description: string
    status: LeadDataFileStatus
}

export interface GetCrmDataFileResponse {
    account: number
    artkey: number
    created_on: string
    file_hash: string
    file_name: string
    description: string
    status: string
}

export interface GetTurnoverGroupsResponse {
    turnover_groups: Array<string>
}

export interface UploadDataFileRequest {
    base64_file: string
    column_mapping: Record<number, string>
    description: string
    generate_redeem_codes: boolean
    file_name: string
}

export interface GetAllDataFilesRequest {
    status?: LeadDataFileStatus
}

export interface GetLeadResponse {
    artkey: number
    name: string
    company_type: string
    operates_online: boolean
    url: string
    status: string
    created_on: string
    was_last_updated_on: string
    contact_status: string
    street_address: string
    zip_code: string
    city: string
    country_code: string
    language: string
    emailaddress: string
    telephone_number: string
    vat_id: string
    memo: string
    message: string
    account_artkey: string
    lead_data_file?: {
        file_name: string
        description: string
    }

    source: LeadSource
    // account: Account
    converted_to_relation_artkey: string
    // contact_persons: any[]
    // warehouses: any[]
    redeem_code: string
    redeem_code_valid_until: string
    company_registration_number: string
    non_mail_indicator: boolean
    nr_of_employees: number
    year_founded: number
    turnover_group: string
    linkedin: string
    facebook: string
    instagram: string
    twitter: string

    manager_artkey?: number
    manager?: {
        artkey: number
        profile: {
            name: string
        }
    }

    contact_persons: {
        first_name: string
        family_name: string
        position: string
        emailaddress: string
        telephone_number: string
    }[]

    warehouses: {
        name: string
        excise_id: string
        excise_location_id: string
        street_address: string
        zip_code: string
        city: string
        country_code: string
    }[]

    log_entries: any
}

export interface PreprocessDataFileResponse {
    header_row: string[]
    column_types: {name: string; required: boolean}[]
    sample_rows: unknown[]
    header_match: Record<number, string>
}

export class CrmApi {
    api = new Api()

    get_lead(artkey: number): Observable<GetLeadResponse> {
        return this.api.post_request('crm.leads.get', {
            artkey: artkey,
        })
    }

    set_lead_status(artkey: number, status: string): Observable<FactserverEmptyResponse> {
        return this.api.post_request('crm.leads.set_status', {
            artkey: artkey,
            status: status,
        })
    }

    set_leads_status(lead_artkeys: number[], new_status: LeadContactStatus): Observable<FactserverEmptyResponse> {
        return this.api.post_fact2server_request('discover/crm/leads/set-contact-status', {
            lead_artkeys: lead_artkeys,
            new_contact_status: new_status,
        })
    }

    quick_update_manager(
        artkey: number,
        user_artkey: number | null,
        memo: string | null,
        contact_status: string,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('crm.leads.quick_update', {
            artkey: artkey,
            manager_user_artkey: user_artkey,
            memo: memo,
            contact_status: contact_status,
        })
    }

    delete_lead(artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('crm.leads.delete', {
            artkey: artkey,
        })
    }

    get_turnover_groups(): Observable<GetTurnoverGroupsResponse> {
        return this.api.post_request('crm.leads.get_turnover_groups', {})
    }

    get_all_data_files(data: GetAllDataFilesRequest): Observable<GetCrmDataFileResponse[]> {
        return this.api.post_request('crm.uploads.get_all', data)
    }

    delete_data_file(artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('crm.uploads.delete_data_file', {
            lead_data_file_artkey: artkey,
        })
    }

    upload_data_file(data: UploadDataFileRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('crm.uploads.upload_data_file', data)
    }

    export_leads_by_artkeys(lead_artkeys: number[], file_name: string): Observable<boolean> {
        return this.api.put('discover/crm/leads/export', {
            lead_artkeys: lead_artkeys,
        }).pipe(
            map((response: Blob) => create_download_for_blob(response, file_name),
            ),
        )
    }

    generate_redeem_codes_by_artkeys(lead_artkeys: number[]): Observable<GenerateRedeemCodeResponse[]> {
        return this.api.post_fact2server_request('discover/crm/leads/generate-redeem-code', {
            lead_artkeys: lead_artkeys,
        })
    }

    generate_redeem_code_by_artkey(lead_artkey: number): Observable<GenerateRedeemCodeResponse> {
        return this.api.post_fact2server_request(`discover/crm/leads/${lead_artkey}/generate-redeem-code`, {
        })
    }

    remove_redeem_code(lead_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.delete(`discover/crm/leads/${lead_artkey}/redeem-code`)
    }

    preprocess_data_file(data: { base64_file: string }): Observable<PreprocessDataFileResponse> {
        return this.api.post_request('crm.uploads.preprocess_data_file', data)
    }
}
