import {config} from '@bitstillery/common/app'

import errors from '@/errors'
import error_handler from '@/error_handler'
import utils from '@/_utils'

export const observable = utils.eventsMixin({})

export function generic_error_handler(error) {
    return errors.generic_handler(error)
}

export function bind() {
    if (config.env !== 'development') {
        window.onerror = error_handler
    }
}
