/** llm:tested */
import m from 'mithril'
import nouislider from 'nouislider'
import wNumb from 'wnumb'
import {MithrilTsxComponent} from 'mithril-tsx-component'

interface SliderOptions {
    range?: { min: number; max: number }
    step?: number
    decimals?: number
    infinity?: boolean
}

interface SliderAttrs {
    prop: Function
    onchange?: (value: number | number[]) => void
    options: SliderOptions
}

// A slider filter component with support for infinity as maximum value.
export class Slider extends MithrilTsxComponent<SliderAttrs> {
    private slider: any
    private prop: Function
    private onchange?: (value: number | number[]) => void
    private options: SliderOptions
    private range: { min: number; max: number }

    constructor(vnode: m.CVnode<SliderAttrs>) {
        super()
        this.slider = null
        this.prop = vnode.attrs.prop
        this.onchange = vnode.attrs.onchange
        this.options = vnode.attrs.options
        this.range = {
            min: 0,
            max: 10000,
        }
    }

    onupdate() {
        // If the @prop changes (e.g. when loading a filter preset): set the
        // slider handles to the prop's value.
        const options = {
            start: this.get_handle_positions(this.prop),
        }
        this.slider.updateOptions(options, true)
    }

    create_slider(vnode: m.VnodeDOM<SliderAttrs>, prop: Function, {range, step, decimals, infinity}: SliderOptions) {
        // Set sane defaults.
        if (range) {
            this.range = range
        }
        if (!step) {
            step = 1
        }
        if (!decimals) {
            decimals = 0
        }

        const connect = [true, false]
        const tooltips = [true]
        if (prop().constructor === Array) {
            // connect the slider's middle part - its effect is the 'blue bar'
            connect.unshift(false)
            // enable tooltips for both slider handles
            tooltips.unshift(true)
        }

        // Create slider.
        this.slider = nouislider.create(vnode.dom, {
            range: this.range,
            start: this.get_handle_positions(prop),
            step: step,
            connect: connect,
            tooltips: tooltips,
            format: wNumb({
                decimals: decimals, // set number of decimals
                edit: (val: string) => {
                    // If the value equals range.max, display the infinity symbol.
                    if (infinity && +val === this.range.max) return '∞'
                    return val
                },
            }),
        })

        this.slider.on('change', (evt: string[]) => {
            // Set the value of the prop to the values of the sliders. Convert
            // "infinity" to null (the infinity symbol is not a number).
            let val_min = isNaN(+evt[0]) ? this.range.min : +evt[0]
            let val_max = isNaN(+evt[1]) ? this.range.max : +evt[1]
            let value: number | number[]

            if (val_max) {
                value = [val_min, val_max]
            } else {
                value = val_min
            }

            if (this.onchange) {
                this.onchange(value)
            } else {
                prop(value)
            }
        })
    }

    // Get the positions for the left (min) and right (max) handles of the slider.
    //
    // @param {Function} prop: Mithril property containing an array with min and max values.
    //
    // @return {array}: An array with min and max values.
    get_handle_positions(prop: Function): number[] {
        if (prop().length === 2) {
            // Initialize the slider handles on the prop's current values.
            // If a value is null, use range.max, as that represents infinity.
            let start_min = prop()[0]
            let start_max = prop()[1]
            if (start_min === null) start_min = this.range.max
            if (start_max === null) start_max = this.range.max
            return [start_min, start_max]
        } else if (prop().constructor === Array) {
            return [this.range.min, this.range.max]
        } else {
            return [prop()]
        }
    }

    view() {
        return m('', {
            oncreate: (vnode: m.VnodeDOM<SliderAttrs>) => {
                this.create_slider(vnode, this.prop, this.options)
            },
        })
    }
}
