/** llm:production */
import m from 'mithril'
import {Button} from '@bitstillery/common/components'
import {$t, notifier} from '@bitstillery/common/app'

import api from '@/api'
import {preventDefault} from '@/_utils'
import {mutation_panel} from '@/stock/components/mutation_panel'
import * as inputs from '@/components/inputs'
import {ItemMutation, ItemMutationStatus} from '@/models/stock'
import {$m} from '@/app'

export class StockMutationInStock {
    item_mutation_reference: string
    mutation: any

    constructor() {
        this.item_mutation_reference = m.route.param('reference')
        this.mutation = window.prop(new ItemMutation())
        this.get_mutation(this.item_mutation_reference)
    }

    get_mutation(item_mutation_reference: string) {
        const data = {
            item_mutation_reference: item_mutation_reference,
            status: ItemMutationStatus.INTRANSIT,
        }
        api.call2('stock.get_item_mutation', data, (resp: any) => {
            this.mutation(new ItemMutation(resp.result))
            this.mutation().targets().map((item: any) => {
                if (item.loendersloot_inspection_item()) {
                    if (!item.lot()) {
                        item.lot(item.loendersloot_inspection_item().lot)
                    }
                    if (!item.bottle_gtin_code()) {
                        item.bottle_gtin_code(item.loendersloot_inspection_item().bottle_gtin)
                    }
                }
            })
        })
    }

    bring_in_stock() {
        const lots = []
        let unfilled_lot_number = false

        for (const item of this.mutation().targets()) {
            if (item.lot()) {
                lots.push({
                    reference: item.reference(),
                    lot: item.lot(),
                    bottle_gtin_code: item.bottle_gtin_code() || null,
                })
            } else {
                unfilled_lot_number = true
            }
        }

        if (unfilled_lot_number) {
            if (!confirm('Not all lot numbers have been filled. Click OK if you still want to bring the items with filled lot numbers in stock')) {
                return
            }
        }

        const data = {
            item_mutation_reference: this.item_mutation_reference,
            lots: lots,
        }
        api.call2('stock.instock_item_mutation', data, () => {
            $m.common.observable.broadcast('stock_updated', '')
            notifier.notify('The items were brought in stock successfully.', 'success')
            m.route.set(`/stock/mutations/manage/${this.item_mutation_reference}`)
        })
    }

    view() {
        return (
            <div class="c-stock-mutation-non-loe view">
                <div class="btn-toolbar">
                    <Button
                        active={false}
                        class="btn-back"
                        icon="back"
                        onclick={() => {
                            m.route.set(`/stock/mutations/manage/${this.mutation().reference()}`)
                        }}
                        variant="toggle"
                    />
                </div>
                {this.mutation() && (
                    mutation_panel(this.mutation,
                        <form class="form-horizontal" onsubmit={preventDefault(this.bring_in_stock.bind(this))}>
                            <div class="panel panel-default">
                                <div class="panel-heading"><div class="panel-title">Enter lot numbers</div></div>
                                <div class="panel-body">
                                    <table class="table">
                                        <thead class="thead-default">
                                            <tr>
                                                <th>P.O. #</th>
                                                <th>Warehouse</th>
                                                <th>Lot #</th>
                                                <th>Bottle GTIN</th>
                                                <th>Product</th>
                                                <th class="number">Btl / cs</th>
                                                <th class="number">Size</th>
                                                <th class="number">Alc %</th>
                                                <th>Ref</th>
                                                <th>GB</th>
                                                <th>Tax label</th>
                                                <th class="number">Cases</th>
                                                <th>Customs status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.mutation().targets().map((item: any) => {
                                                if (item.is_in_transit()) {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <a onclick={() => m.route.set(`/purchase-orders/manage/${item.purchase_order_item().purchase_order().reference()}`)}>
                                                                    {item.purchase_order_item().purchase_order().reference()}
                                                                </a>
                                                            </td>
                                                            <td>{this.mutation().target_warehouse().name}</td>
                                                            <td>{inputs.text(item.lot, {required: false})}</td>
                                                            <td>{inputs.text(item.bottle_gtin_code, {required: false})}</td>
                                                            <td>{item.bottle().product().name()}</td>
                                                            <td class="number">{item.number_of_bottles_per_case()}</td>
                                                            <td class="number">{item.bottle().display_volume()}</td>
                                                            <td class="number">{item.bottle().display_alcohol_percentage()}</td>
                                                            <td>{item.bottle().refill_status()}</td>
                                                            <td>{item.gift_box_type()}</td>
                                                            <td>{item.tax_label()}</td>
                                                            <td class="price">{item.number_of_cases()}</td>
                                                            <td>{item.customs_status()}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Button
                                icon="stock"
                                text={$t('stock.actions.bring_in_stock')}
                                type="info"
                                onclick={() => this.bring_in_stock()}
                            />
                        </form>,
                    )
                )}
            </div>
        )
    }
}
