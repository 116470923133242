/** llm:tested */
import m from 'mithril'
import {head} from 'prelude-ls'
import {FieldMoney, Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {AdditionalType, SalesOrderAdditional} from '@/sales_orders/models'
import {call, callAndThen} from '@/api'
import inputs from '@/components/inputs'
import {a} from '@/_utils'
import {$m} from '@/app'

interface AddAdditionalAttrs {
    sales_order: any
    sales_order_changed: () => void
}

export class AddAdditional {
    sales_order: any
    loading: any
    additional: any
    sales_order_changed: () => void
    active_vouchers: any
    active_voucher_codes: any
    unit: any
    ppu: any

    constructor(vnode: m.Vnode<AddAdditionalAttrs>) {
        this.sales_order = vnode.attrs.sales_order
        this.loading = window.prop(false)
        this.additional = window.prop(new SalesOrderAdditional())
        this.additional().total_value = window.prop('')
        this.sales_order_changed = vnode.attrs.sales_order_changed
        this.init()
        this.active_vouchers = window.prop([])
        this.active_voucher_codes = window.prop([])
        this.unit = window.prop('')
        this.ppu = window.prop(0)
    }

    init() {
        this.additional().artkey('')
        this.additional().description('')
        this.additional().sales_order_additional_type('')
        this.additional().price_per_unit('')
        this.additional().quantity('')
        this.additional().sales_order = this.sales_order
        this.additional().total_value(this.additional().quantity() * this.additional().price_per_unit())
        this.additional().value_per_quantity(0)
    }

    get_relation_vouchers() {
        callAndThen('voucher.get_relation_active_vouchers',
            {relation_artkey: this.sales_order().supplier().artkey(), only_unused: true},
            {
                success: (resp: any) => {
                    this.active_vouchers(resp.result)
                    const voucher_codes = this.active_vouchers().map((voucher: any) => voucher.code)
                    this.active_voucher_codes(voucher_codes)
                },
            },
        )
    }

    add_to_order(e: Event) {
        e.preventDefault()
        this.loading(true)

        const data = {
            sales_order_artkey: this.additional().sales_order().artkey(),
            sales_order_additional_type: this.additional().sales_order_additional_type(),
            description: this.additional().description(),
            quantity: this.additional().quantity(),
            value_type: this.additional().value_type(),
            value_per_quantity: this.additional().value_per_quantity(),
        }
        call('sales.additionals.add_additional_to_sales_order', data, (resp) => this.handle_added_additional(resp))
    }

    handle_added_additional(resp: any) {
        this.loading(false)

        if (resp.success) {
            $m.common.observable.broadcast('sales_order_updated_' + this.sales_order().artkey(), 'Go fetch')
            this.init()
            this.get_relation_vouchers()
            notifier.notify('The additional was added successfully.', 'success')
            if (this.sales_order_changed) {
                this.sales_order_changed()
            }
        } else {
            if (resp.message) {
                notifier.notify(resp.message, 'danger')
            } else {
                $m.common.generic_error_handler()
            }
        }
    }

    is_selecting_voucher() {
        return this.additional().sales_order_additional_type() === AdditionalType.VOUCHER
    }

    onchange_voucher(voucher_code: string) {
        const selected_voucher = head(this.active_vouchers().filter((v: any) => v.code === voucher_code))
        if (!selected_voucher) {
            this.additional().description('')
            this.additional().quantity(0)
            this.additional().value_type('FIXED')
            this.additional().value_per_quantity(0)
        } else {
            this.additional().description(voucher_code)
            this.additional().quantity(1)
            this.additional().value_type(selected_voucher.value_type)
            this.additional().value_per_quantity(-selected_voucher.value)
        }
    }

    view() {
        return m('.mwrap', a(
            m('button.btn.btn-default.mb-2', {
                'data-target': '#add_additional',
                'data-toggle': 'collapse',
                onclick: () => this.get_relation_vouchers(),
            },
            m('span.glyphicon.glyphicon-plus'),
            ' Add additional item to order',
            ),
            m('.collapse#add_additional',
                m('form.flex-form', {onsubmit: (e: Event) => this.add_to_order(e)},
                    m('.fieldset.largest',
                        m('.field-group',
                            inputs.select(this.additional().sales_order_additional_type, AdditionalType.CHOICES, {
                                label: 'Type',
                                required: true,
                            }),
                            this.is_selecting_voucher() ?
                                this.active_voucher_codes().length > 0 ?
                                    inputs.select(this.additional().description, this.active_voucher_codes(), {
                                        label: 'Voucher',
                                        required: true,
                                        empty_option: true,
                                        onchange: (code: string) => this.onchange_voucher(code),
                                    })
                                    : m('.field',
                                        m('label', 'No active vouchers found'),
                                    )
                                : inputs.text(this.additional().description, {
                                    label: 'Description',
                                    required: true,
                                }),
                            !this.is_selecting_voucher() && [
                                inputs.number(this.additional().quantity, {
                                    label: 'Quantity',
                                    required: true,
                                    disabled: this.is_selecting_voucher(),
                                    min: 1,
                                }),
                                inputs.select(this.additional().value_type, ['FIXED', 'PERCENTAGE'], {
                                    label: 'Value type',
                                    required: true,
                                    empty_option: false,
                                }),
                                this.additional().value_type() === 'PERCENTAGE' ?
                                    inputs.number(this.additional().value_per_quantity, {
                                        label: 'Percentage',
                                        required: true,
                                        max: 100,
                                        min: -100,
                                        disabled: this.is_selecting_voucher(),
                                    })
                                    : m(FieldMoney, {
                                        currency: [this.sales_order(), 'was_sold_in'],
                                        disabled: this.is_selecting_voucher(),
                                        label: this.additional().value_type() === 'FIXED' ? 'Price per unit' : 'Value per quantity',
                                        min: -999999,
                                        model: [this.additional(), 'value_per_quantity'],
                                        required: true,
                                    }),
                            ],
                            m('.field',
                                m('label', m.trust('&nbsp;')),
                                m('button.btn.btn-success',
                                    this.loading() ? m(Spinner) : 'Add additional item',
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ))
    }
}
