import {FieldDate, FieldSelect} from '@bitstillery/common/components'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime, DurationObjectUnits} from 'luxon'

import {DateRange, DateRangePickerSize} from '@/market/statistics'
import {DefaultButton} from '@/components/buttons'

interface DateRangePickerAttrs {
    options:{
        date_picker_size: DateRangePickerSize
    }
    date_ranges: DateRange[]
    max_date?: string
    min_date?: string
    disabled?: boolean
    label?: string
    allow_additional_ranges?: boolean
    max_date_ranges?: number
}

export class DateRangePicker extends MithrilTsxComponent<DateRangePickerAttrs> {
    date_picker_for_size(range_size: DateRangePickerSize, date_range: DateRange, disabled: boolean | undefined, index: number): m.Children {
        let preset = 'days'
        const date_picker_options:any = {
            maxDate: Date.now(),
            minDate: '2016-01-01',
            toggleSelected: false,
        }

        if ([DateRangePickerSize.YEAR, DateRangePickerSize.MONTH].includes(range_size)) {
            preset = range_size.toLowerCase()
            date_picker_options['onSelect'] = ({date}) => this.select_date(date, date_range, preset)
        } else if (range_size === DateRangePickerSize.QUARTER) {
            date_picker_options['range'] = true
            date_picker_options['view'] = 'months'
            date_picker_options['minView'] = 'months'
            date_picker_options['buttons'] = [
                {content: 'Q1', onClick: (adp) => this.select_quarter(1, adp)},
                {content: 'Q2', onClick: (adp) => this.select_quarter(2, adp)},
                {content: 'Q3', onClick: (adp) => this.select_quarter(3, adp)},
                {content: 'Q4', onClick: (adp) => this.select_quarter(4, adp)},
            ]
        }
        else {
            date_picker_options['range'] = true
        }

        return <FieldDate
            placeholder="Enter date"
            key={`${range_size}-${index}`}
            model={date_range}
            disabled={disabled}
            preset={preset}
            date_picker_options={date_picker_options}
        />
    }

    // Override for default onSelect to make it always select a range for year and month range sizes
    select_date(date: Date | Date[], date_range: DateRange, range_size: string) {
        if (date instanceof Date) {
            let from_date = DateTime.fromJSDate(date)
            date_range.date = from_date.toISODate()
            date_range.to_date = from_date.endOf(range_size as keyof DurationObjectUnits).toISODate()
        }
    }

    range_size_date_init(date_ranges: DateRange[], range_size: string) {
        // Set the date ranges to an appropriate start and end for the selected range size
        if (range_size !== DateRangePickerSize.CUSTOM) {
            date_ranges.forEach((date_range) => {
                if (date_range.date && Date.parse(date_range.date)) {
                    const start_date = DateTime.fromISO(date_range.date).startOf(range_size as keyof DurationObjectUnits)
                    date_range.date = start_date.toISODate()
                    date_range.to_date = start_date.plus({[range_size]: 1, days: -1}).toISODate()
                }
            })
        }
    }

    select_quarter(quarter: number, adp: any) {
        const year = adp.viewDate.getFullYear()

        const quarter_start = DateTime.fromFormat(`${quarter} ${year}`, 'q yyyy')
        const quarter_end = quarter_start.endOf('quarter')

        adp.selectDate([quarter_start.toJSDate(), quarter_end.toJSDate()])
    }

    max_date_ranges(number_of_ranges: number, max: number | undefined): boolean {
        if (!max) {return false}
        return number_of_ranges >= max
    }

    view(vn: m.Vnode<DateRangePickerAttrs>): m.Children {
        return (
            <div className={'c-date-range-picker'}>
                {vn.attrs.label && (<label>{vn.attrs.label}</label>)}
                <div className={'ranges'}>
                    <FieldSelect
                        disabled={vn.attrs.disabled}
                        model={[vn.attrs.options, 'date_picker_size']}
                        onchange={(): void => {
                            this.range_size_date_init(vn.attrs.date_ranges, vn.attrs.options.date_picker_size)
                        }}
                        options={Object.values(DateRangePickerSize).map((date_picker_size: string) => ({
                            label: date_picker_size,
                            value: date_picker_size,
                        }))}
                    />

                    {vn.attrs.date_ranges.map((date_range, index) => {
                        return this.date_picker_for_size(
                            vn.attrs.options.date_picker_size,
                            date_range,
                            vn.attrs.disabled,
                            index,
                        )
                    })}
                    {vn.attrs.allow_additional_ranges
                        && !this.max_date_ranges(vn.attrs.date_ranges.length, vn.attrs.max_date_ranges)
                        && (<DefaultButton
                            icon_class={'fas fa-plus'}
                            disabled={vn.attrs.disabled}
                            tooltip={'Add date range'}
                            onclick={() => vn.attrs.date_ranges.push({date: null, to_date: null})}
                        />)
                    }
                    {vn.attrs.allow_additional_ranges
                        && vn.attrs.date_ranges.length > 1
                        && (<DefaultButton
                            icon_class={'fas fa-minus'}
                            disabled={vn.attrs.disabled}
                            tooltip={'Add date range'}
                            onclick={() => vn.attrs.date_ranges.pop()}
                        />)
                    }
                </div>
            </div>
        )
    }
}
