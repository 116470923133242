import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {from} from 'rxjs'
import {Button} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'
import {proxy} from '@bitstillery/common/lib/proxy'
import {merge_deep} from '@bitstillery/common/lib/utils'

import {PurchaseOrderDetails} from './components/purchase_order_details'

import {$s} from '@/app'
import {InStockPurchaseOrderItem} from '@/purchase_orders/components/instock_item'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {
    PurchaseApi,
    PurchaseOrderItemResponse,
    PurchaseOrderWithItemsResponse,
    total_number_of_cases_in_items,
} from '@/factserver_api/purchase_api'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
    CollectionTableRowComponentProps,
} from '@/components/collection/collection_table'
import {SaveButton} from '@/components/buttons'
import {PurchaseOrder, PurchaseOrderStatus} from '@/models/purchase_orders'
import {PurchaseOrderItem} from '@/models/purchase_orders'

export default class InStockPurchaseOrder extends MithrilTsxComponent<void> {
    search_text = ''
    purchase_order_artkey: number
    purchase_order: PurchaseOrderWithItemsResponse | null = null
    search_bar_controller: SearchBarControl | null = null
    in_stockable = false
    in_error = false
    suggestions: string[] = []

    model = proxy({
        purchase_order: {},
    })

    purchase_api = new PurchaseApi()
    purchase_order_items_fetcher = new PagedCollectionFetcher<PurchaseOrderItemResponse>(
        'purchase.core.get_purchase_order_items_collection',
        'product',
        undefined,
        6,
    )

    constructor() {
        super()
        this.purchase_order_artkey = +m.route.param('artkey')
        this.load_purchase_order(this.purchase_order_artkey)
        this.purchase_order_items_fetcher.filters['purchase_order_artkey'] = this.purchase_order_artkey
    }

    load_purchase_order(purchase_order_artkey: number): void {
        this.purchase_api.purchase_order_with_items(purchase_order_artkey).subscribe((response) => {
            if (response) {
                this.purchase_order = response[0]
                if (this.purchase_order.status !== PurchaseOrderStatus.CONFIRMED) {
                    notifier.notify(
                        'This order does not have the status confirmed. Only confirmed orders can be brought in stock.',
                        'danger',
                    )
                    m.route.set(`/purchase-orders/manage/${this.purchase_order.artkey}`)
                } else {
                    this.in_stockable = this.determine_if_in_stockable(this.purchase_order)
                    this.in_error = this.determine_if_stock_error(this.purchase_order)

                    const items = this.purchase_order.purchase_order_items

                    this.suggestions = items.map((item) => item.case.bottle.product.name)

                    for (const poi of items) {
                        poi.items
                            .filter((item) => !item.country_of_origin)
                            // @ts-ignore
                            // eslint-disable-next-line
                            .forEach((item) => (item.country_of_origin = item.case.bottle.product.default_country_code))
                    }
                }
                merge_deep(this.model.purchase_order, new PurchaseOrder(this.purchase_order))
                m.redraw()
            }
        })
    }

    /**
     * In error if all Purchase order items are in stock and the poi cases do not match the total number of cases
     * @param purchase_order_with_items
     * @returns
     */
    determine_if_stock_error(purchase_order_with_items: PurchaseOrderWithItemsResponse): boolean {
        const items = purchase_order_with_items.purchase_order_items
        return !items
            .map((poi) => {
                if (poi.is_in_stock) {
                    return total_number_of_cases_in_items(poi) === poi.number_of_cases
                }
                return true
            })
            .every(Boolean)
    }

    /**
     * It is in stockable if all Purchase order items are in stock.
     * @param purchase_order_with_items
     * @returns
     */
    determine_if_in_stockable(purchase_order_with_items: PurchaseOrderWithItemsResponse): boolean {
        const items = purchase_order_with_items.purchase_order_items
        return items
            .map((poi) => {
                return total_number_of_cases_in_items(poi) === poi.number_of_cases && poi.is_in_stock
            })
            .every(Boolean)
    }

    on_item_in_stock = (in_stockable: boolean): void => {
        this.in_stockable = in_stockable
    }

    search_for_search_text = (search_text: string): void => {
        this.search_text = search_text
        this.purchase_order_items_fetcher.set_search_terms(search_text)
    }

    bring_in_stock(): void {
        if (!this.in_stockable) {
            notifier.notify('Purchase order not stockable, please check the incongruities on this page', 'danger')
            return
        }

        this.purchase_api.instock_purchase_order(this.purchase_order_artkey).subscribe({
            next: () => {
                notifier.notify('Purchase order brought in stock successfully.', 'success')
                m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}`)
            },
            error: () => null,
        })
    }

    get_complete_tooltip() {
        if (!this.in_stockable) {
            return 'Unable to complete, not every item is stocked'
        }
        else if (this.in_error) {
            return 'The number of cases in stock and on the purchase order is incongruent for certain items'
        }
    }

    view(): m.Children {
        return (
            <div className="c-instock view">
                <div className="row" id="button-bar">
                    <div className="col-lg-12 btn-toolbar" role="group">
                        <Button
                            text="Back to order"
                            icon="back"
                            onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}`)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h2>Bring purchase order items in stock</h2>{' '}
                    </div>
                </div>

                {this.purchase_order && (
                    <div>
                        <PurchaseOrderDetails purchase_order={() => this.model.purchase_order} />

                        <form className="form-horizontal.col-lg-12" role="form">
                            <div
                                className={`form-group.col-lg-6.col-sm-12 ${
                                    this.purchase_order.was_bought_in === $s.currencies.default ? 'hidden' : ''
                                }`}
                            >
                                <label className="control-label.col-sm-4" for="bought_against_rate">
                                    Exchange rate
                                </label>
                                <div className=".col-sm-8">
                                    {/* inputs.number(@purchase_order!bought_against_rate,
                          {id: 'bought_against_rate', required: true, step: '0.0001'}) */}
                                </div>
                            </div>
                        </form>
                        <p>
                            Please fill out all information on the arrived items. If an item hasn't arrived yet, please
                            leave the lot number empty.
                        </p>
                        <div className="row">
                            <div className="col-xs-12 mb-2">
                                <SaveButton
                                    title="Complete purchase order"
                                    onclick={() => this.bring_in_stock()}
                                    tooltip={this.get_complete_tooltip()}
                                    disabled={!this.in_stockable}
                                />
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-8'}>
                                <label>Product name</label>
                                <SearchBar
                                    placeholder={'Search for name and/or category'}
                                    on_submit={this.search_for_search_text}
                                    on_get_suggestions$={(filter_text: string) =>
                                        from(
                                            this.suggestions.filter((suggestion) =>
                                                suggestion.toLowerCase().includes(filter_text.toLowerCase()),
                                            ),
                                        )
                                    }
                                    suggestions={this.suggestions}
                                    default_search_text={this.purchase_order_items_fetcher.search_text()}
                                    search_bar_controller={(controller: SearchBarControl) =>
                                        (this.search_bar_controller = controller)
                                    }
                                />
                            </div>
                        </div>

                        <CollectionTable<PurchaseOrderItemResponse, InStockPurchaseOrderItemRowAdditionalArgs>
                            collection_fetcher={this.purchase_order_items_fetcher}
                            row_component={InStockPurchaseOrderItemRow}
                            additional_row_component_args={{on_in_stock: this.on_item_in_stock}}
                        >
                            <CollectionTableColumn<PurchaseOrderItemResponse>
                                header_title={() => 'Product'}
                                sort_name={'product'}
                            />
                            <CollectionTableColumn<PurchaseOrderItemResponse> header_title={() => 'Btl / cs'} />
                            <CollectionTableColumn<PurchaseOrderItemResponse> header_title={() => 'Specs'} />
                            <CollectionTableColumn<PurchaseOrderItemResponse> header_title={() => 'GB'} />
                            <CollectionTableColumn<PurchaseOrderItemResponse> header_title={() => 'Tax Label'} />
                            <CollectionTableColumn<PurchaseOrderItemResponse>
                                header_title={() => 'Cases'}
                                sort_name={'cases'}
                            />
                            <CollectionTableColumn<PurchaseOrderItemResponse>
                                header_title={() => 'Suggested sales price / cs'}
                            />
                            <CollectionTableColumn<PurchaseOrderItemResponse> header_title={() => 'Quantity'} />
                            <CollectionTableColumn<PurchaseOrderItemResponse>
                                header_title={() => 'Lot #'}
                                sort_name={'is_in_stock'}
                            />
                            <CollectionTableColumn<PurchaseOrderItemResponse> header_title={() => ''} />
                        </CollectionTable>
                    </div>
                )}
            </div>
        )
    }
}

interface InStockPurchaseOrderItemRowAdditionalArgs {
    on_in_stock: (in_stockable: boolean) => void
}

class InStockPurchaseOrderItemRow extends MithrilTsxComponent<
    CollectionTableRowComponentProps<PurchaseOrderItemResponse, InStockPurchaseOrderItemRowAdditionalArgs>
> {
    purchase_order_item: any
    on_in_stock: (in_stockable: boolean) => void

    constructor(
        vnode: m.VnodeDOM<
            CollectionTableRowComponentProps<PurchaseOrderItemResponse, InStockPurchaseOrderItemRowAdditionalArgs>
        >,
    ) {
        super()
        // Convert to livescript models to be backwards compatible with the livescript component.
        this.purchase_order_item = new PurchaseOrderItem(vnode.attrs.row)
        // Maybe items were created without the default country of origin. This should happen less and less.
        const coo = vnode.attrs.row.country_of_origin
        if (coo) {
            this.purchase_order_item
                .items()
                .filter((it: any) => it.country_of_origin() !== '')
                .forEach((it: any) => it.country_of_origin(coo))
        }

        if (!vnode.attrs.additional_args) {
            throw new Error('Additional args required')
        } else {
            this.on_in_stock = vnode.attrs.additional_args.on_in_stock
        }
    }

    view(): m.Children {
        return this.purchase_order_item
            .items()
            .map((item) => (
                <InStockPurchaseOrderItem
                    purchase_order_item={this.purchase_order_item}
                    item={item}
                    on_in_stock={this.on_in_stock}
                />
            ))
    }
}
