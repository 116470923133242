/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import inputs from '@/components/inputs'

interface RadiobuttonAttrs {
    filter_function: (value: string) => void
    filter_name: string
    filter_options: [string, string][]
}

export class Radiobutton extends MithrilTsxComponent<RadiobuttonAttrs> {
    private filter_function: (value: string) => void
    private filter_name: string
    private filter_options: [string, string][]

    constructor(vnode: m.CVnode<RadiobuttonAttrs>) {
        super()
        this.filter_function = vnode.attrs.filter_function
        this.filter_name = vnode.attrs.filter_name
        this.filter_options = vnode.attrs.filter_options
    }

    view() {
        return (
            <div class="c-filter-radiobutton field">
                <label>{this.filter_name}</label>
                {inputs.radio(
                    this.filter_function,
                    this.filter_options.map((options) => ({
                        value: options[0],
                        description: options[1],
                    })),
                )}
            </div>
        )
    }
}
