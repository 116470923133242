import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {Country} from '@bitstillery/common/components'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'

import {CountriesSelect} from '@/components/html_components'
import {DefaultButton} from '@/components/buttons'
import {RelationsSearch} from '@/components/relation'
import {SearchBarControl} from '@/components/collection/search_bar'
import {GetHiddenInformationResponse, GetRelationResponse} from '@/factserver_api/fact2server_api'
import {RelationApi} from '@/factserver_api/relation_api'
import {PurchaseApi} from '@/factserver_api/purchase_api'
import {StockApi} from '@/factserver_api/stock_api'
import {ProductManagementApi} from '@/factserver_api/product_management_api'

export interface HiddenInformationAttrs {
    hidden_information_helper: HiddenInformationHelper
}

/**
 * This class has a helper structure. The helpers must confirm to this interface and is responsible for storing
 * and retrieving the information.
 */
export interface HiddenInformationHelper {

    hidden_information: GetHiddenInformationResponse | null

    add_hidden_country_code(country_code?: string): void
    add_hidden_relation(relation_artkey?: number): void

    delete_hidden_country_code(country_code: string): void
    delete_hidden_relation(relation_artkey: number): void

    load_hidden_information(): void
}

/** Helper implementation for the PurchaseOrderItem. */
export class HiddenPurchaseOrderItemHelper implements HiddenInformationHelper {
    purchase_api = new PurchaseApi()
    purchase_order_artkey: number
    purchase_order_item_artkey: number
    hidden_information: GetHiddenInformationResponse | null

    constructor(purchase_order_artkey: number, purchase_order_item_artkey: number) {
        this.purchase_order_artkey = purchase_order_artkey
        this.purchase_order_item_artkey = purchase_order_item_artkey
    }

    add_hidden_country_code(country_code?: string): void {
        if (!country_code) {
            return
        }
        this.purchase_api.add_hidden_country_for_item(this.purchase_order_artkey, this.purchase_order_item_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                if (!this.hidden_information.hidden_country_codes.find((cc) => cc === country_code)) {
                    this.hidden_information.hidden_country_codes.push(country_code)
                }
                this.hidden_information.hidden_country_codes.sort()
                m.redraw()
            },
        })
    }

    add_hidden_relation(relation_artkey?: number): void {
        if (!relation_artkey) {
            return
        }
        this.purchase_api.add_hidden_relation_for_item(this.purchase_order_artkey, this.purchase_order_item_artkey, relation_artkey).subscribe({
            next: () => {
                this.load_hidden_information()
            },
        })
    }

    delete_hidden_country_code(country_code: string): void {
        this.purchase_api.delete_hidden_country_for_item(this.purchase_order_artkey, this.purchase_order_item_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                this.hidden_information.hidden_country_codes = this.hidden_information.hidden_country_codes.filter((cc) => cc !== country_code)
                m.redraw()
            },
        })
    }

    delete_hidden_relation(relation_artkey: number): void {
        this.purchase_api.delete_hidden_relation_for_item(this.purchase_order_artkey, this.purchase_order_item_artkey, relation_artkey).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_relations) {
                    return
                }
                this.hidden_information.hidden_relations = this.hidden_information.hidden_relations.filter((hr) => hr.artkey !== relation_artkey)
                m.redraw()
            },
        })
    }

    load_hidden_information(): void {
        this.purchase_api.purchase_order_item_hidden_information(this.purchase_order_artkey, this.purchase_order_item_artkey).subscribe({
            next: (response) => {
                this.hidden_information = response
                m.redraw()
            },
        })
    }

}

/** Helper implementation for the Supplier. */
export class HiddenRelationHelper implements HiddenInformationHelper {
    hidden_information: GetHiddenInformationResponse | null
    relation_api = new RelationApi()
    supplier_artkey: number

    constructor(supplier_artkey: number) {
        this.supplier_artkey = supplier_artkey
    }

    add_hidden_country_code(country_code?: string): void {
        if (!country_code) {
            return
        }
        this.relation_api.add_hidden_country(this.supplier_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                if (!this.hidden_information.hidden_country_codes.find((cc) => cc === country_code)) {
                    this.hidden_information.hidden_country_codes.push(country_code)
                }
                this.hidden_information.hidden_country_codes.sort()
                m.redraw()
            },
        })
    }

    add_hidden_relation(relation_artkey?: number): void {
        if (!relation_artkey) {
            return
        }
        this.relation_api.add_hidden_relation(this.supplier_artkey, relation_artkey).subscribe({
            next: () => {
                this.load_hidden_information()
            },
        })
    }

    delete_hidden_country_code(country_code: string): void {
        this.relation_api.delete_hidden_country(this.supplier_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                this.hidden_information.hidden_country_codes = this.hidden_information.hidden_country_codes.filter((cc) => cc !== country_code)
                m.redraw()
            },
        })
    }

    delete_hidden_relation(relation_artkey: number): void {
        this.relation_api.delete_hidden_relation(this.supplier_artkey, relation_artkey).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_relations) {
                    return
                }
                this.hidden_information.hidden_relations = this.hidden_information.hidden_relations.filter((hr) => hr.artkey !== relation_artkey)
                m.redraw()
            },
        })
    }

    load_hidden_information(): void {
        this.relation_api.get_hidden_information_for_relation(this.supplier_artkey).subscribe({
            next: (response) => {
                this.hidden_information = response
                m.redraw()
            },
        })
    }
}

/** Helper implementation for the StockItem. */
export class HiddenStockItemHelper implements HiddenInformationHelper {
    hidden_information: GetHiddenInformationResponse | null
    stock_api = new StockApi()
    item_artkey: number

    constructor(item_artkey: number) {
        this.item_artkey = item_artkey
    }

    add_hidden_country_code(country_code?: string): void {
        if (!country_code) {
            return
        }
        this.stock_api.add_hidden_country_for_item(this.item_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                if (!this.hidden_information.hidden_country_codes.find((cc) => cc === country_code)) {
                    this.hidden_information.hidden_country_codes.push(country_code)
                }
                this.hidden_information.hidden_country_codes.sort()
                m.redraw()
            },
        })
    }

    add_hidden_relation(relation_artkey?: number): void {
        if (!relation_artkey) {
            return
        }
        this.stock_api.add_hidden_relation_for_item(this.item_artkey, relation_artkey).subscribe({
            next: () => {
                this.load_hidden_information()
            },
        })
    }

    delete_hidden_country_code(country_code: string): void {
        this.stock_api.delete_hidden_country_for_item(this.item_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                this.hidden_information.hidden_country_codes = this.hidden_information.hidden_country_codes.filter((cc) => cc !== country_code)
                m.redraw()
            },
        })
    }

    delete_hidden_relation(relation_artkey: number): void {
        this.stock_api.delete_hidden_relation_for_item(this.item_artkey, relation_artkey).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_relations) {
                    return
                }
                this.hidden_information.hidden_relations = this.hidden_information.hidden_relations.filter((hr) => hr.artkey !== relation_artkey)
                m.redraw()
            },
        })
    }

    load_hidden_information(): void {
        this.stock_api.get_hidden_information_for_stock_item(this.item_artkey).subscribe({
            next: (response) => {
                this.hidden_information = response
                m.redraw()
            },
        })
    }
}

/** Helper implementation for the Product. */
export class HiddenProductHelper implements HiddenInformationHelper {
    hidden_information: GetHiddenInformationResponse | null
    product_api = new ProductManagementApi()
    product_artkey: number

    constructor(product_artkey: number) {
        this.product_artkey = product_artkey
    }

    add_hidden_country_code(country_code?: string): void {
        if (!country_code) {
            return
        }
        this.product_api.add_hidden_country_for_product(this.product_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                if (!this.hidden_information.hidden_country_codes.find((cc) => cc === country_code)) {
                    this.hidden_information.hidden_country_codes.push(country_code)
                }
                this.hidden_information.hidden_country_codes.sort()
                m.redraw()
            },
        })
    }

    add_hidden_relation(relation_artkey?: number): void {
        if (!relation_artkey) {
            return
        }
        this.product_api.add_hidden_relation_for_product(this.product_artkey, relation_artkey).subscribe({
            next: () => {
                this.load_hidden_information()
            },
        })
    }

    delete_hidden_country_code(country_code: string): void {
        this.product_api.delete_hidden_country_for_product(this.product_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                this.hidden_information.hidden_country_codes = this.hidden_information.hidden_country_codes.filter((cc) => cc !== country_code)
                m.redraw()
            },
        })
    }

    delete_hidden_relation(relation_artkey: number): void {
        this.product_api.delete_hidden_relation_for_product(this.product_artkey, relation_artkey).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_relations) {
                    return
                }
                this.hidden_information.hidden_relations = this.hidden_information.hidden_relations.filter((hr) => hr.artkey !== relation_artkey)
                m.redraw()
            },
        })
    }

    load_hidden_information(): void {
        this.product_api.get_hidden_information_for_product(this.product_artkey).subscribe({
            next: (response) => {
                this.hidden_information = response
                m.redraw()
            },
        })
    }
}

/** Helper implementation for the Brand. */
export class HiddenBrandHelper implements HiddenInformationHelper {
    hidden_information: GetHiddenInformationResponse | null
    product_api = new ProductManagementApi()
    brand_artkey: number

    constructor(brand_artkey: number) {
        this.brand_artkey = brand_artkey
    }

    add_hidden_country_code(country_code?: string): void {
        if (!country_code) {
            return
        }
        this.product_api.add_hidden_country_for_brand(this.brand_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                if (!this.hidden_information.hidden_country_codes.find((cc) => cc === country_code)) {
                    this.hidden_information.hidden_country_codes.push(country_code)
                }
                this.hidden_information.hidden_country_codes.sort()
                m.redraw()
            },
        })
    }

    add_hidden_relation(relation_artkey?: number): void {
        if (!relation_artkey) {
            return
        }
        this.product_api.add_hidden_relation_for_brand(this.brand_artkey, relation_artkey).subscribe({
            next: () => {
                this.load_hidden_information()
            },
        })
    }

    delete_hidden_country_code(country_code: string): void {
        this.product_api.delete_hidden_country_for_brand(this.brand_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                this.hidden_information.hidden_country_codes = this.hidden_information.hidden_country_codes.filter((cc) => cc !== country_code)
                m.redraw()
            },
        })
    }

    delete_hidden_relation(relation_artkey: number): void {
        this.product_api.delete_hidden_relation_for_brand(this.brand_artkey, relation_artkey).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_relations) {
                    return
                }
                this.hidden_information.hidden_relations = this.hidden_information.hidden_relations.filter((hr) => hr.artkey !== relation_artkey)
                m.redraw()
            },
        })
    }

    load_hidden_information(): void {
        this.product_api.get_hidden_information_for_brand(this.brand_artkey).subscribe({
            next: (response) => {
                this.hidden_information = response
                m.redraw()
            },
        })
    }
}

/** Helper implementation for the BrandHolder. */
export class HiddenBrandHolderHelper implements HiddenInformationHelper {
    hidden_information: GetHiddenInformationResponse | null
    product_api = new ProductManagementApi()
    brand_holder_artkey: number

    constructor(brand_holder_artkey: number) {
        this.brand_holder_artkey = brand_holder_artkey
    }

    add_hidden_country_code(country_code?: string): void {
        if (!country_code) {
            return
        }
        this.product_api.add_hidden_country_for_brand_holder(this.brand_holder_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                if (!this.hidden_information.hidden_country_codes.find((cc) => cc === country_code)) {
                    this.hidden_information.hidden_country_codes.push(country_code)
                }
                this.hidden_information.hidden_country_codes.sort()
                m.redraw()
            },
        })
    }

    add_hidden_relation(relation_artkey?: number): void {
        if (!relation_artkey) {
            return
        }
        this.product_api.add_hidden_relation_for_brand_holder(this.brand_holder_artkey, relation_artkey).subscribe({
            next: () => {
                this.load_hidden_information()
            },
        })
    }

    delete_hidden_country_code(country_code: string): void {
        this.product_api.delete_hidden_country_for_brand_holder(this.brand_holder_artkey, country_code).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_country_codes) {
                    return
                }
                this.hidden_information.hidden_country_codes = this.hidden_information.hidden_country_codes.filter((cc) => cc !== country_code)
                m.redraw()
            },
        })
    }

    delete_hidden_relation(relation_artkey: number): void {
        this.product_api.delete_hidden_relation_for_brand_holder(this.brand_holder_artkey, relation_artkey).subscribe({
            next: () => {
                if (!this.hidden_information?.hidden_relations) {
                    return
                }
                this.hidden_information.hidden_relations = this.hidden_information.hidden_relations.filter((hr) => hr.artkey !== relation_artkey)
                m.redraw()
            },
        })
    }

    load_hidden_information(): void {
        this.product_api.get_hidden_information_for_brand_holder(this.brand_holder_artkey).subscribe({
            next: (response) => {
                this.hidden_information = response
                m.redraw()
            },
        })
    }
}

export class HiddenInformation extends MithrilTsxComponent<HiddenInformationAttrs> {

    helper: HiddenInformationHelper
    selected_country_code: string = ''
    selected_relation: GetRelationResponse | null = null
    search_bar_control: SearchBarControl | null = null

    data = proxy({
        selected_relations: [] as GetRelationResponse[],
    })

    watches: type_remove_watch_function[] = []

    constructor(vnode: m.Vnode<HiddenInformationAttrs>) {
        super()
        this.helper = vnode.attrs.hidden_information_helper
    }

    oncreate(): any {
        this.watches.push(watch(this.data.selected_relations, () => {
            this.selected_relation = this.data.selected_relations[0]
        }))
        this.helper.load_hidden_information()
    }

    onremove() {
        this.watches.forEach((watch) => watch())
    }

    view(): m.Children {
        if (!this.helper.hidden_information) {
            return <Spinner />
        }

        return (
            <div className={'c-hidden-information'}>
                <div className={'fieldset-group'}>
                    <div className={'fieldset'}>
                        <label>Hidden countries</label>
                    </div>
                    <div className={'fieldset'}>
                        <label>Relation</label>
                    </div>
                </div>
                <div className={'fieldset-group'}>
                    <div className={'fieldset'}>
                        <CountriesSelect
                            model={[this, 'selected_country_code']}
                            onchange={(selected) => this.selected_country_code = selected}
                        />
                        <DefaultButton
                            disabled={!this.selected_country_code}
                            icon_class={'fas fa-plus'}
                            onclick={() => this.helper.add_hidden_country_code(this.selected_country_code)}
                        />
                    </div>
                    <div className={'fieldset'}>
                        <RelationsSearch
                            is_single_select={true}
                            selected_relations={this.data.selected_relations}
                            keep_results_in_search_bar={true}
                            on_selected_relation={(relation: GetRelationResponse | null) => this.selected_relation = relation}
                            selected_relation_name={this.selected_relation?.name}
                            search_bar_controller={(controller: SearchBarControl) =>
                                (this.search_bar_control = controller)
                            }
                        >
                            <DefaultButton
                                icon_class={'fas fa-plus'}
                                disabled={!this.selected_relation}
                                onclick={() => {
                                    this.helper.add_hidden_relation(this.selected_relation?.artkey)
                                    this.search_bar_control?.clear_search_text()
                                }}
                            />
                        </RelationsSearch>

                    </div>
                </div>
                <div className={'fieldset-group'}>
                    <div className={'fieldset hidden-items'}>
                        {this.helper.hidden_information.hidden_country_codes.map((country_code) =>
                            <div className={'hidden-item'} key={country_code}>
                                <Country country_code={country_code} type={'flag_with_country'} size={'l'}/>
                                <DefaultButton icon_class={'fas fa-trash'} onclick={() => this.helper.delete_hidden_country_code(country_code)}/>
                            </div>,
                        )
                        }

                    </div>

                    <div className={'fieldset hidden-items'}>
                        {this.helper.hidden_information.hidden_relations.map((relation) =>
                            <div className={'hidden-item'} key={relation.artkey}>
                                <div>{relation.name}</div>
                                <DefaultButton icon_class={'fas fa-trash'} onclick={() => this.helper.delete_hidden_relation(relation.artkey)}/>
                            </div>,
                        )}
                    </div>
                </div>
            </div>
        )
    }

}
