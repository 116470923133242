/** llm:tested */
import {events} from '@bitstillery/common/app'
import {api, logger} from '@bitstillery/common/app'

import {ExactKeys} from '@/exact'
import {RegionDropDownData} from '@/factserver_api/relation_api'
import api_ls from '@/api'
import {Supplier} from '@/models/suppliers'
import {$m} from '@/app'

export const bottle_refill_statusses = ['ref', 'nonref']
export const collection_states = {}
export const customs_status = ['T1', 'T2']

export const availability_statuses = [
    'Stock',
    '1 week',
    '2 weeks',
    '3 weeks',
    '4 weeks',
    '> 4 weeks',
]

export const client_statuses = [
    'Client',
    'Prospect',
    'Low Potential',
    'Not Active',
    'Do Not Approach',
]

export function company_type_class(status) {
    status = status || ''
    status = status.toLowerCase().replace(' / ', '-').replace('/', '-').replace(' ', '-')
    return `is-${status}`
}

export const company_types = [
    'Competitor',
    'Agent / Importer NL',
    'Agent / Importer',
    'Retail',
    'Wholesale',
    'Trader',
    'Travel Retail / Duty Free',
    'MSI',
    'Logistics / Warehouse',
    'Service partner',
    'Unknown',
]

export const customs_visibility_to_statuses = {
    T1: 'T1',
    T2: 'T2',
    both: 'both',
    hidden: 'both',
    null: 'both',
}

export let exact_keys = {}

// Update list of data products when it is rendered.
export const update_data_products = window.prop(false)

export const gift_box_types = [
    'GB',
    'GB + Glass(es)',
    '+ Glass(es)',
    'GB Bag',
    'Gift Set',
    'Gift Tin',
    'Wooden GB',
    'Cradle',
]

export const humanize_customs_visibility = {
    T1: 'Only T1',
    T2: 'Only T2',
    both: 'Both T1 and T2',
    hidden: 'Hide customs status, show both T1 and T2 items',
    null: 'Nothing configured, show both T1 and T2',
}

export const price_list_frequencies = {
    '1 weeks': 'Weekly',
    '2 weeks': 'Bi-weekly',
    '1 months': 'Monthly',
    '3 months': 'Quarterly',
    '1 years': 'Yearly',
    never: 'Never',
    other: 'Other',
}

export const price_list_frequencies_as_table = [
    ['1 weeks', 'Weekly'],
    ['2 weeks', 'Bi-weekly'],
    ['1 months', 'Monthly'],
    ['3 months', 'Quarterly'],
    ['1 years', 'Yearly'],
    ['never', 'Never'],
    ['other', 'Other'],
]

export let region_drop_down_data = {}

export const sales_promotions = [
    [1, 'Scooter Giveaway'],
    [2, 'November discount'],
    [3, 'June discount'],
    [4, 'Smartwatch giveaway'],
    [5, 'Earbud promotion'],
    [6, 'NL Action 2023'],
    [7, 'Coffee giveaway 2024'],
]

export const supplier_portal_levels = [
    ['demo', 'Demo'],
    ['trial', 'Trial'],
    ['normal', 'Normal'],
    ['trusted', 'Trusted'],
]

export const supplier_portal_customs_visibility = [
    ['T1', humanize_customs_visibility['T1']],
    ['T2', humanize_customs_visibility['T2']],
    ['both', humanize_customs_visibility['both']],
    ['hidden', humanize_customs_visibility['hidden']],
]

export const supplier_transport_costs_payment_methods = [
    ['nopay', 'Does not pay transport costs.'],
    ['include', 'Include transport costs in sales price per case'],
    ['separate', 'Add transport costs as a separate line on the invoice'],
]

// Hardcoded references to same supplier names which are used in logic
// elsewhere. Until a generic way is implemented to treat processes differently
// based on certain suppliers, this solution will do fine.
export const a2bc_supplier_name = 'A2BC Travel Retail BV'
export const msi_supplier_name = 'Moving Spirits International BV'
export const msp_supplier_name = 'Moving Spirits Purchasing BV'

export const scroll_states = {}

export let contact_person = {}
export let vat_rate = {}
export let vat_type = {}
export let payment_term = {}
export let insurance_type = {}
export let item_tag_category = {}
export let item_tag = {}
export let ledgers = {}
export let warehouses = {}
export const incoterms = []

export class Delivery {
    status = window.prop('')
    external_reference = window.prop('')
    weight_in_kilos = window.prop('')
    number_of_euro_pallets = window.prop('')
    number_of_block_pallets = window.prop('')
    loading_in_meters = window.prop('')

    constructor(json: any) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class Warehouse {
    destination_type = window.prop('')
    artkey = window.prop('')
    name = window.prop('')
    emailaddress = window.prop('')
    currency = window.prop('')
    warehouse_base_costs = window.prop('')
    warehouse_costs_per_case = window.prop('')
    street_address = window.prop('')
    zip_code = window.prop('')
    city = window.prop('')
    country_code = window.prop('')
    region_code = window.prop('')
    warehouse_id = window.prop('')
    vat_id = window.prop('')
    excise_id = window.prop('')
    can_handle_our_stock = window.prop(false)

    constructor(json: any) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class DestinationsDataModel {
    create_warehouse(warehouse_json: any) {
        return new Warehouse(warehouse_json)
    }
}

export class VatType {
    artkey = window.prop(null)
    vat_code = window.prop('')
    percentage = window.prop('')
    description = window.prop('')

    constructor(json: any) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class VatTypeDataModel {
    create(vat_type_json: any) {
        return new VatType(vat_type_json)
    }
}

export class VatRate {
    artkey = window.prop(null)
    percentage = window.prop('')
    country_code = window.prop('')
    vat_type_artkey = window.prop('')
    vat_type = window.prop(new VatType({}))

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'vat_type') {
                this.vat_type(new VatType(json[prop]))
                this.vat_type_artkey = json[prop]['artkey']
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export class VatRateDataModel {
    vat_rates = window.prop([])

    constructor() {
        api_ls.callAndThen('vatrates.get_all', {}, {
            success: (resp: any) => {
                this.vat_rates(resp.result.map((vat_rate_json: any) => this.create(vat_rate_json)))
                $m.common.observable.broadcast('vatrates_loaded', this.vat_rates)
            },
        })
    }

    create(json: any) {
        return new VatRate(json)
    }

    get_vat_rates_by_country(country_code: string) {
        return this.vat_rates().filter((vat_rate: any) =>
            vat_rate.country_code() === country_code,
        )
    }
}

export class LedgerType {
    static PURCHASING = 'Purchase'
    static SALES = 'Sales'
}

export class Ledger {
    artkey = window.prop(null)
    ledger_type = window.prop('')
    description = window.prop('')
    ledger_code = window.prop('')
    vat_type_artkey = window.prop('')
    vat_type = window.prop(new VatType({}))

    constructor(json: any = {}) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
            if (prop === 'vat_type') {
                this.vat_type_artkey(json[prop].artkey)
                this.vat_type($m.data.vat_type.create(json[prop]))
            }
        }
    }

    to_string() {
        return this.ledger_code() + ' - ' + this.description()
    }
}

export class LedgersDataModel {
    query_all(target: any, type: string) {
        const set_ledgers_curried = this.set_ledgers.bind(this, target)
        api_ls.call('ledgers.get_all', {type: type}, set_ledgers_curried)
    }

    set_ledgers(target: any, msg: any) {
        target(msg.result.map((ledger_json: any) => this.create_ledger(ledger_json)))
    }

    create_ledger(ledger_json: any) {
        return new Ledger(ledger_json)
    }
}

export class InsuranceType {
    artkey = window.prop('')
    name = window.prop('1 - No transport insurance arranged by MSI / no insurance involved')
    fee = window.prop(0.0)

    constructor(json: any) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class InsuranceTypeDataModel {
    create_insurance_type(insurance_type_json: any) {
        return new InsuranceType(insurance_type_json)
    }
}

export class ContactPerson {
    artkey = window.prop('')
    name = window.prop('')
    first_name = window.prop('')
    family_name = window.prop('')
    birthdate = window.prop('')
    telephone_number = window.prop('')
    mobile_telephone_number = window.prop('')
    emailaddress = window.prop('')
    position = window.prop('')
    salutation = window.prop('')
    language = window.prop('')
    should_receive_mails = window.prop(false)
    should_receive_purchase_enquiries = window.prop(false)
    add_to_hello_dialog = window.prop(false)
    supplier_artkey = window.prop('')
    supplier = window.prop(new Supplier({}))

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'supplier') {
                this.supplier_artkey(json[prop].artkey)
                this.supplier(new Supplier(json[prop]))
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export class ContactPersonDataModel {
    create_contact_person(json: any) {
        return new ContactPerson(json)
    }
}

export class PaymentTerm {
    artkey = window.prop('')
    exact_payment_term_code = window.prop('')
    description = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class PaymentTermDataModel {
    query_all(target: any) {
        const set_payment_terms_curried = this.set_payment_terms.bind(this, target)
        api_ls.call('paymentterms.get_all', {}, set_payment_terms_curried)
    }

    set_payment_terms(target: any, msg: any) {
        target(msg.result.map((payment_term_json: any) => this.create_payment_term(payment_term_json)))
    }

    create_payment_term(payment_term_json: any) {
        return new PaymentTerm(payment_term_json)
    }
}

export class CommentTemplate {
    artkey = window.prop(null)
    title = window.prop('')
    body = window.prop('')

    constructor(json: any) {

        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class ItemTagCategoryCardinality {
    static ONE = 'One'
    static MANY = 'Many'
}

export class ItemTagCategoryConstants {
    static TAX_LABEL = 'Tax Label'
}

export class TaxLabelConstants {
    static NO_TAX_LABEL = 'No Tax Label'
}

export class ItemTagCategory {
    artkey = window.prop('')
    name = window.prop('')
    cardinality = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }

    has_cardinality_one() {
        return this.cardinality() === ItemTagCategoryCardinality.ONE
    }
}

export class ItemTagCategoryDataModel {
    categories = window.prop([])

    constructor() {
        api_ls.call('product_management.get_all_item_tag_categories', {}, this.set_item_tag_categories.bind(this))
    }

    set_item_tag_categories(msg: any) {
        this.categories(msg.result.map((category_json: any) => this.create_category(category_json)))
        $m.common.observable.broadcast('item_tag_categories_loaded', this.categories)
    }

    create_category(category_json: any) {
        return new ItemTagCategory(category_json)
    }

    get_category_by_artkey(artkey: string) {
        return this.categories().find((cat: any) => cat.artkey() === artkey)
    }

    get_category_by_name(name: string) {
        return this.categories().find((cat: any) => cat.name() === name)
    }

    tax_label_category() {
        return this.get_category_by_name(ItemTagCategoryConstants.TAX_LABEL)
    }

    all_categories_except_tax_label() {
        return this.categories().filter((cat: any) => cat.name() !== ItemTagCategoryConstants.TAX_LABEL)
    }
}

export class ItemTag {
    artkey = window.prop('')
    name = window.prop('')
    abbreviation = window.prop('')
    category = window.prop(new ItemTagCategory({}))
    category_artkey = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'item_tag_category') {
                this.category_artkey(json[prop].artkey)
                this.category($m.data.item_tag_category.get_category_by_artkey(json[prop].artkey))
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export class ItemTagDataModel {
    tags = window.prop([])

    constructor() {
        $m.common.observable.subscribe('item_tags_updated', this, this.load_item_tags.bind(this))
        this.load_item_tags()
    }

    load_item_tags() {
        api_ls.call('product_management.get_all_item_tags', {}, this.set_item_tags.bind(this))
    }

    set_item_tags(msg: any) {
        const sorted_tags = msg.result
            .map((tag: any) => this.create_item_tag(tag))
            .sort((a: any, b: any) => a.name() > b.name() ? 1 : -1)

        this.tags(sorted_tags)
        window.tags = this.tags
        $m.common.observable.broadcast('item_tags_loaded', this.tags)
    }

    get_all(artkeys: string[]) {
        return this.tags().filter((tag: any) => artkeys.includes(tag.artkey()))
    }

    create_item_tag(item_tag_json: any) {
        return new ItemTag(item_tag_json)
    }

    get_item_tag(artkey: string) {
        return this.tags().find((tag: any) => tag.artkey() === artkey)
    }

    get_all_from_category(category: any) {
        return this.tags().filter((tag: any) => tag.category_artkey() === category.artkey())
    }

    get_tag_names(artkeys: string[]) {
        return artkeys.map((artkey) => this.get_item_tag(artkey)).map((tag) => tag.name())
    }
}

export class EmailPlaceholderTranslation {
    artkey = window.prop(null)
    language_code = window.prop('')
    translation = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class EmailPlaceholder {
    artkey = window.prop(null)
    placeholder = window.prop('')
    description = window.prop('')
    translations = window.prop([])

    constructor(json: any = {}) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class EmailRecipientType {
    static TO = 'TO'
    static FROM = 'FROM'
    static BCC = 'BCC'
    static CC = 'CC'
}

export class EmailAttachment {
    static INVOICE = 'INVOICE'
    static PROFORMA = 'PROFORMA_INVOICE'
    static OUTTAKE = 'OUTTAKE'
    static PDF_CONTENT_TYPE = 'application/pdf'
}

export class BrandHolder {
    artkey = window.prop(null)
    name = window.prop('')
    requires_import_statement = window.prop(false)

    constructor(json: any) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class Brand {
    artkey = window.prop(null)
    name = window.prop('')
    match_text = window.prop('')
    brand_holder_artkey = window.prop('')
    brand_holder = window.prop(new BrandHolder({}))

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'brand_holder') {
                this.brand_holder(new BrandHolder(json[prop]))
                this.brand_holder_artkey = json[prop]['artkey']
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export class AttachmentCategory {
    artkey = window.prop(null)
    name = window.prop('')
    attachment_type = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export function bind() {
    events.on('identity.login', async() => {
        load_models()
    })
}

export async function load_models() {
    logger.info('[models] loading Discover models...')
    region_drop_down_data = new RegionDropDownData()
    exact_keys = new ExactKeys()
    vat_rate = new VatRateDataModel()
    vat_type = new VatTypeDataModel()
    contact_person = new ContactPersonDataModel()
    payment_term = new PaymentTermDataModel()
    insurance_type = new InsuranceTypeDataModel()
    item_tag_category = new ItemTagCategoryDataModel()
    ledgers = new LedgersDataModel()
    warehouses = new DestinationsDataModel()
    item_tag = new ItemTagDataModel()

    const {result, status_code} = await api.get('discover/data/incoterms')
    if (status_code > 299) {
        return
    }
    incoterms.splice(0, incoterms.length, ...result.map((i) => [i.code, `${i.code} - ${i.description}`]))
}

export function refresh() {
    load_models()
}
