/** llm:tested */
import $ from 'jquery'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

interface DialogButton {
    onclick: () => void
    label: string
}

interface DialogAttrs {
    unique_name: string
    title: string
    body: m.Children
    no_cancel?: boolean
    onclose?: () => void
    onsubmit?: () => void
    extra_footer_buttons?: DialogButton[]
}

class Dialog extends MithrilTsxComponent<DialogAttrs> {
    unique_name: string
    title: string
    body: m.Children
    no_cancel: boolean
    onclose?: () => void
    onsubmit?: () => void
    extra_footer_buttons?: DialogButton[]

    constructor(vnode: m.CVnode<DialogAttrs>) {
        super()
        this.unique_name = vnode.attrs.unique_name
        this.title = vnode.attrs.title
        this.body = vnode.attrs.body
        this.no_cancel = vnode.attrs.no_cancel || false
        this.onclose = vnode.attrs.onclose
        this.onsubmit = vnode.attrs.onsubmit
        this.extra_footer_buttons = vnode.attrs.extra_footer_buttons
    }

    hide() {
        $(this.unique_name).modal('hide')
    }

    close() {
        this.hide()
        if (this.onclose) {
            this.onclose()
        }
    }

    submit() {
        this.hide()
        if (this.onsubmit) {
            this.onsubmit()
        }
    }

    show_modal(vnode: m.VnodeDOM) {
        $(vnode.dom).modal()
    }

    view() {
        const modal_attrs = {
            id: this.unique_name,
            tabindex: '-1',
            role: 'dialog',
            'aria-labelledby': 'confirmationLabel',
            oncreate: this.show_modal.bind(this),
            onupdate: this.show_modal.bind(this),
        }

        return (
            <div class="modal fade" {...modal_attrs}>
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button
                                class="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                onclick={this.close.bind(this)}
                            >
                                ×
                            </button>
                            <h4 class="modal-title" id="confirmationLabel">
                                {this.title}
                            </h4>
                        </div>

                        <div class="modal-body">
                            {this.body}
                        </div>

                        <div class="modal-footer">
                            {!this.no_cancel && (
                                <button
                                    class="btn btn-default"
                                    type="button"
                                    data-dismiss="modal"
                                    onclick={this.close.bind(this)}
                                >
                                    Cancel
                                </button>
                            )}

                            {this.extra_footer_buttons &&
                                this.extra_footer_buttons.map(button => (
                                    <button
                                        class="btn btn-default"
                                        type="button"
                                        data-dismiss="modal"
                                        onclick={button.onclick}
                                    >
                                        {button.label}
                                    </button>
                                ))
                            }

                            <button
                                class="btn btn-primary"
                                type="button"
                                data-dismiss="modal"
                                onclick={this.submit.bind(this)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export function show(options: DialogAttrs): void {
    if (!options.unique_name) {
        // eslint-disable-next-line no-console
        console.error('Provide a unique name for your dialog, otherwise names might collide!')
    }

    const element = document.getElementById('confirmationLocation')
    if (element) {
        m.render(element, m(Dialog, options))
    }
}

export default {
    show,
}
