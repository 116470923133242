/** llm:tested */
import m from 'mithril'
import {all} from 'prelude-ls'
import {Spinner} from '@bitstillery/common/components'

import api from '@/api'
import {$m} from '@/app'

interface Product {
    artkey: () => string
    name: () => string
    bottles: () => Bottle[]
}

interface Bottle {
    alcohol_percentage: () => string
    volume: () => string
    refill_status: () => string
}

export class ProductMerge {
    products: () => Product[]
    product_artkeys: () => string[]
    loading: () => boolean

    constructor() {
        this.products = window.prop([])
        this.product_artkeys = window.prop([])
        this.loading = window.prop(false)
        this.query_products(m.route.param('p'))
    }

    query_products(product_artkeys: string) {
        this.product_artkeys(product_artkeys.split(','))
        const data = {
            product_artkeys: this.product_artkeys(),
        }
        api.call('product_management.get_products_and_bottles', data, this.set_products.bind(this))
    }

    merge_products() {
        this.loading(true)
        const data = {
            product_artkeys: this.product_artkeys(),
        }
        api.call('product_management.merge_products', data, this.handle_merge.bind(this))
    }

    handle_merge(result: { success: boolean; product_artkey: string }) {
        this.loading(false)
        if (result.success) {
            $m.data.update_data_products(true)
            m.route.set('/data/products/' + result.product_artkey + '/merged')
        } else {
            $m.common.generic_error_handler('Something went wrong')
        }
    }

    set_products(result: { products: any[] }) {
        this.products($m.products._create_products(result.products))
    }

    view() {
        return (
            <div class="merge-product view">
                <div class="row" id="button-bar">
                    <div class="col-lg-12 btn-toolbar">
                        <button class="btn btn-link" type="button" onclick={() => m.route.set('/data/products')}>
                            <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                        </button>
                    </div>
                </div>

                <h1>Merge products</h1>
                <div class="row">
                    <div class="col-md-6">
                        <div class="alert alert-info">
                            Would you like to merge the following {this.products().length} products?
                        </div>
                    </div>
                </div>

                {this.products().map((product) => {
                    const final_product = all((p) => p.artkey() >= product.artkey(), this.products())
                    return (
                        <div class="row">
                            <div class="col-md-6">
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h4>{product.name()}</h4>
                                        {final_product && <em>This will be the resulting product.</em>}
                                    </div>
                                    <div class="panel-body">
                                        <div class="col-xs-4"><label>Alcohol %</label></div>
                                        <div class="col-xs-4"><label>Size in cl</label></div>
                                        <div class="col-xs-4"><label>Refill status</label></div>
                                        {product.bottles().map((bottle) => [
                                            <div class="col-xs-4">{(+bottle.alcohol_percentage()).toFixed(1)}</div>,
                                            <div class="col-xs-4">{(+bottle.volume()).toFixed(1)}</div>,
                                            <div class="col-xs-4">{bottle.refill_status().toLowerCase()}</div>,
                                        ])}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

                <div class="row mb-2">
                    <div class="col-md-3 col-xs-6">
                        <button
                            class="form-control btn btn-default"
                            onclick={() => m.route.set('/data/products')}
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-3 col-xs-6">
                        <button
                            class="form-control btn btn-success"
                            onclick={() => this.merge_products()}
                            disabled={this.loading()}
                        >
                            {this.loading() ? <Spinner /> : 'Merge'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
