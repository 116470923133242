import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {Popover} from '@/components/popover/popover'
import {icon} from '@/components/icon'
import {GetSalesOrderFiguresResponse} from '@/factserver_api/fact2server_api'

export interface KeyFiguresAttrs {
    key_figures: GetSalesOrderFiguresResponse
}

export class KeyFigures extends MithrilTsxComponent<KeyFiguresAttrs> {
    key_figures: GetSalesOrderFiguresResponse

    constructor(vnode: m.Vnode<KeyFiguresAttrs>) {
        super()
        this.key_figures = vnode.attrs.key_figures
    }

    view(): m.Children {
        return (
            <div className="stats-group">
                <Popover
                    content="Saved & Finalized Orders"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-info">
                        {icon('fa-recycle')} {this.key_figures.saved_sales_orders}
                    </div>
                </Popover>
                <Popover
                    content="Pending Orders (portal)"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-warning">
                        {icon('fa-recycle')} {this.key_figures.pending_portal_orders}
                    </div>
                </Popover>
                <Popover
                    content="Confirmed Orders"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-success">
                        {icon('fa-bullseye')} {this.key_figures.confirmed_sales_orders}
                    </div>
                </Popover>
                <Popover
                    content="Invoiced Orders"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-primary">
                        {icon('fa-check')} {this.key_figures.invoiced_sales_orders}
                    </div>
                </Popover>
                <Popover
                    content="Order Value"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-info">
                        {icon('fa-euro-sign')} {Number(this.key_figures.total_value ?? 0).formatMoney()}
                    </div>
                </Popover>
                <Popover
                    content="Orders Overdue (1 month)"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-danger">
                        {icon('fa-exclamation')} {this.key_figures.old_orders}
                    </div>
                </Popover>
                <Popover
                    content="Average Order Age (days)"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-info">
                        {icon('fa-calendar')} {this.key_figures.average_order_age.toFixed(1)}
                    </div>
                </Popover>
            </div>
        )
    }
}
