/** llm:tested */
import m from 'mithril'
import {throttle} from '@bitstillery/common/ts_utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

// Expects a 'table' element as the only direct child.
export class FixedTableHeader extends MithrilTsxComponent<any> {
    handler: ((event: Event) => void) | null
    app_element: HTMLElement | null

    constructor() {
        super()
        this.handler = null
        this.app_element = null
    }

    oncreate(vnode: m.Vnode<any>) {
        this.handler = throttle(100, () => this.scroll_fixed(vnode.dom))
        this.app_element = document.getElementById('app')
        if (this.app_element && this.handler) {
            this.app_element.addEventListener('scroll', this.handler)
            this.app_element.addEventListener('resize', this.handler)
        }
    }

    onremove() {
        if (this.handler && this.app_element) {
            this.app_element.removeEventListener('scroll', this.handler)
            this.app_element.removeEventListener('resize', this.handler)
        }
    }

    scroll_fixed(element: HTMLElement) {
        const table = $(element)
        const thead = table.find('thead').first()

        const app_element_top = this.app_element?.getBoundingClientRect().y || 0
        const table_top = table[0].getBoundingClientRect().y
        const thead_height = thead[0].getBoundingClientRect().height

        if ((table_top + thead_height) > app_element_top) {
            table.find('.thead-copy-remove').remove()
            thead.removeClass('sticky')
            thead.removeAttr('top')
        } else if ((table_top + thead_height) < app_element_top && !thead.hasClass('sticky')) {
            const thead_copy = thead.clone()
            thead_copy.insertAfter(thead)
            thead_copy.addClass('thead-copy-remove')
            thead.addClass('sticky')
            thead.css({
                top: app_element_top,
                width: thead_copy.width(),
            })

            // Make the size of the sticky header columns the same of the original copy.
            thead.find('th').each((index, th) => {
                $(th).width(thead_copy.find('th').eq(index).width())
            })
        }
    }

    view(vnode: m.Vnode<any>): m.Children {
        return vnode.children
    }
}

const fix_table_header = (table: m.Children) => {
    return <FixedTableHeader>{table}</FixedTableHeader>
}

export const without_buttons = fix_table_header
export const with_buttons = fix_table_header

export default {
    without_buttons,
    with_buttons,
}
