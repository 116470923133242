/** llm:tested */
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {Ledger, LedgerType} from '@/models/data'
import {$m} from '@/app'

export class LedgerUpsert {
    ledger: any
    user: any
    create: any
    vat_types: any

    constructor() {
        this.ledger = window.prop(new Ledger())
        this.user = window.prop([])
        this.create = window.prop(false)
        this.vat_types = window.prop([])

        api.call('vattypes.get_all', {}, this.set_vat_types.bind(this))

        const ledger_artkey = m.route.param('artkey')
        if (ledger_artkey) {
            this.query_ledger(ledger_artkey)
        } else { // we are creating a new one
            this.create(true)
        }
    }

    set_vat_types(resp: any) {
        if (resp.success) {
            this.vat_types(
                resp.result.map((item: any) => $m.data.vat_type.create(item)),
            )
        } else {
            $m.common.generic_error_handler()
        }
    }

    query_ledger(artkey: string) {
        api.call('ledgers.get_ledger', {artkey: artkey}, this.set_ledger.bind(this))
    }

    set_ledger(resp: any) {
        if (resp.success) {
            try {
                this.ledger($m.data.ledgers.create_ledger(resp.result))
            } catch (e) {
                $m.common.generic_error_handler(e)
            }
        } else {
            notifier.notify('Unknown ledger.', 'danger')
            m.route.set('/data/ledgers')
        }
    }

    save(e: Event) {
        e.preventDefault()

        const data = {
            artkey: this.ledger().artkey(),
            ledger_type: this.ledger().ledger_type(),
            description: this.ledger().description(),
            vat_type_artkey: this.ledger().vat_type_artkey(),
            ledger_code: this.ledger().ledger_code(),
        }
        api.call('ledgers.update_or_create_ledger', data, this.handle_save.bind(this))
    }

    handle_save(result: any) {
        if (!result.success) {
            notifier.notify(result.message, 'danger')
        } else {
            if (this.create()) {
                notifier.notify(`Successfully created new ledger ${this.ledger().ledger_code()}.`, 'success')
                m.route.set('/data/ledgers/' + result.artkey + '/edit')
            } else {
                notifier.notify('Successfully updated ledger.', 'success')
            }
            $m.common.observable.broadcast('ledgers_updated', '')
        }
    }

    set_address(value: string) {
        this.ledger().address(value.replace(/\n/g, '|'))
    }

    view() {
        return (
            <div class="c-edit-ledger view">
                <div class="btn-toolbar">
                    <button class="btn btn-default" type="button" onclick={() => m.route.set('/data/ledgers')}>
                        <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                    </button>
                </div>

                <form class="flex-form" onsubmit={this.save.bind(this)}>
                    <div class="fieldset">
                        <div class="field">
                            <label for="ledger_type">Ledger type</label>
                            <select
                                class="form-control"
                                id="ledger_type"
                                required={true}
                                onchange={(ev: Event) => this.ledger().ledger_type((ev.target as HTMLSelectElement).value)}
                            >
                                <option value=""></option>
                                <option
                                    value={LedgerType.PURCHASING}
                                    selected={this.ledger().ledger_type() === LedgerType.PURCHASING}
                                >
                                    {LedgerType.PURCHASING.capitalizeFirstLetter()}
                                </option>
                                <option
                                    value={LedgerType.SALES}
                                    selected={this.ledger().ledger_type() === LedgerType.SALES}
                                >
                                    {LedgerType.SALES.capitalizeFirstLetter()}
                                </option>
                            </select>
                        </div>
                        <div class="field">
                            <label for="description">Description</label>
                            <textarea
                                class="form-control"
                                id="description"
                                rows={4}
                                autocomplete="off"
                                value={this.ledger().description() || ''}
                                oninput={(ev: Event) => this.ledger().description((ev.target as HTMLTextAreaElement).value)}
                            />
                        </div>
                        <div class="field">
                            <label for="ledger_code">Ledger code</label>
                            <input
                                class="form-control"
                                id="ledger_code"
                                type="number"
                                required={true}
                                placeholder="Ledger code"
                                value={this.ledger().ledger_code() || ''}
                                oninput={(ev: Event) => this.ledger().ledger_code((ev.target as HTMLInputElement).value)}
                            />
                        </div>
                        <div class="field">
                            <label for="vattype">VAT type</label>
                            <select
                                class="form-control"
                                id="vattype"
                                required={true}
                                onchange={(ev: Event) => this.ledger().vat_type_artkey(+(ev.target as HTMLSelectElement).value)}
                            >
                                <option value=""></option>
                                {this.vat_types().map((vat_type: any) => (
                                    <option
                                        value={vat_type.artkey()}
                                        selected={vat_type.artkey() === this.ledger().vat_type_artkey()}
                                    >
                                        {`${vat_type.vat_code()} - ${vat_type.description()}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <button class="btn btn-success btn-submit">
                        {this.create() ? 'Create Ledger' : 'Save Ledger'}
                    </button>
                </form>
            </div>
        )
    }
}
