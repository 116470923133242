/** llm:production */
import $ from 'jquery'
import m from 'mithril'
import {andList} from 'prelude-ls'
import {debounce} from '@bitstillery/common/ts_utils'

class ViewModel {
    input_container_id: () => string
    on_submit_suggestion: (suggestion: string) => void
    suggestions: () => string[]
    min_length: () => number
    hide_suggestions: () => boolean
    found_suggestions: () => string[]
    highlighted_suggestion: () => number | null
    search_term: () => string
    delayed_submit_input: () => void

    constructor(options: any) {
        this.input_container_id = window.prop(options.input_container_id)
        this.on_submit_suggestion = options.on_submit_suggestion
        this.suggestions = window.prop(options['suggestions'] || [])
        this.min_length = window.prop(options['min_length'] || 3)
        // Only use this to check, not to set. For that, use the show_suggestions function.
        this.hide_suggestions = window.prop(true)
        this.found_suggestions = window.prop([])
        this.highlighted_suggestion = window.prop(null)
        this.search_term = window.prop('')

        this.delayed_submit_input = debounce(200, this.submit_oninput)
    }

    oninput = (value: string, is_suggestion: boolean = false) => {
        this.search_term(value)
        if (!is_suggestion) {
            this.delayed_submit_input()
        }
    }

    submit_oninput = () => {
        // Separate from oninput so that it can be called when the suggestions are
        // loaded afterwards.
        if (this.search_term().length > this.min_length()) {
            this.show_suggestions(true)
        } else {
            this.show_suggestions(false)
        }
    }

    show_suggestions = (to_show: boolean) => {
        // Whether the suggestions should be shown. Triggers filtering of suggestions
        // if needed, and empties it to not unnecesarily render data (can slow down a lot
        // with lots of suggestions.
        if (to_show) {
            this.filter_suggestions()
            this.hide_suggestions(false)
        } else {
            // Empty to save rendering unnecessary data in the view.
            this.found_suggestions([])
            this.highlighted_suggestion(null)
            this.hide_suggestions(true)
        }
        m.redraw()
    }

    onclick = () => {
        if (this.search_term().length > 0) {
            this.show_suggestions(true)
        }
    }

    onkeydown = (e: KeyboardEvent) => {
        this.submit_keydown(e)
    }

    filter_suggestions = () => {
        // Careful: The suggestions list can be huge. Calling this will force the
        // found_suggestions to be set. Make sure you only call it when it is necessary.
        if (this.search_term().length > 0) {
            this.found_suggestions(this.suggestions().filter(this.is_suggestion_match))
        } else {
            this.found_suggestions(this.suggestions())
        }
        // It makes no sense to render more than 50 suggestions. If you do, it does slow
        // down a lot though. So, let's not.
        this.found_suggestions(this.found_suggestions().slice(0, 50))
    }

    is_suggestion_match = (suggestion: string) => {
        // Check if a suggestion maps the serach term.
        return andList(this.search_term().toLowerCase().split(' ').map(term =>
            suggestion.toLowerCase().trim().indexOf(term) > -1,
        ))
    }

    highlight_suggestion = (index: number) => {
        if (this.highlighted_suggestion() !== index) {
            this.highlighted_suggestion(index)
        }
    }

    highlight_next_suggestion = () => {
        if (this.found_suggestions().length > 0) {
            if (this.highlighted_suggestion() === (this.found_suggestions().length - 1) || this.highlighted_suggestion() === null) {
                this.highlighted_suggestion(0)
            } else {
                this.highlighted_suggestion(this.highlighted_suggestion()! + 1)
            }
            document.getElementById('suggestion-' + this.highlighted_suggestion())?.scrollIntoView(false)
        }
    }

    highlight_previous_suggestion = () => {
        if (this.found_suggestions().length > 0) {
            if (this.highlighted_suggestion() === null) {
                this.highlighted_suggestion(0)
            } else if (this.highlighted_suggestion() === 0) {
                this.highlighted_suggestion(this.found_suggestions().length - 1)
            } else {
                this.highlighted_suggestion(this.highlighted_suggestion()! - 1)
            }
            document.getElementById('suggestion-' + this.highlighted_suggestion())?.scrollIntoView(false)
        }
    }

    submit_keydown = (e: KeyboardEvent) => {
        switch (e.keyCode) {
        case 13: // enter
            e.preventDefault()
            if (this.highlighted_suggestion() !== null) {
                const suggestion = this.found_suggestions()[this.highlighted_suggestion()!]
                if (suggestion) {
                    this.submit_suggestion(suggestion)
                }
            }
            this.show_suggestions(false)
            break
        case 40: // arrow down
            this.show_suggestions(true)
            this.highlight_next_suggestion()
            break
        case 38: // arrow up
            this.show_suggestions(true)
            this.highlight_previous_suggestion()
            break
        case 27: // escape
            this.show_suggestions(false)
            break
        case 9: // tab
            this.show_suggestions(false)
            break
        default:
            return
        }
    }

    submit_suggestion = (suggestion: string) => {
        this.on_submit_suggestion(suggestion)
        this.show_suggestions(false)
    }

    reset = () => {
        this.search_term('')
        this.show_suggestions(false)
    }
}

export class Controller {
    vm: ViewModel
    click_handler: (e: Event) => void

    constructor(options: any) {
        this.vm = new ViewModel(options)

        this.click_handler = (e: Event) => {
            if (!$(e.target).closest(this.vm.input_container_id()).length &&
                    !$(e.target).is(this.vm.input_container_id())) {
                this.vm.show_suggestions(false)
            }
        }

        $(document).on('click', this.click_handler)
    }

    onremove() {
        $(document).off('click', this.click_handler)
    }

    get_found_suggestions() {
        return this.vm.found_suggestions()
    }

    oninput(...args: any[]) {
        this.vm.oninput(...args)
    }

    onkeydown(e: KeyboardEvent) {
        this.vm.onkeydown(e)
    }

    onclick(e: Event) {
        this.vm.onclick(e)
    }

    set_suggestions(suggestions: string[]) {
        this.vm.suggestions(suggestions)
        this.vm.submit_oninput()
    }

    reset() {
        this.vm.reset()
    }

    update_suggestions(...args: any[]) {
        this.vm.search_term(...args)
        this.vm.show_suggestions(true)
    }
}

export const view = function(ctrl: Controller) {
    if (ctrl.vm.found_suggestions().length) {
        return (
            <div class="autocomplete-suggestions" hidden={ctrl.vm.hide_suggestions()}>
                {ctrl.vm.found_suggestions().map((suggestion, index) => (
                    <div
                        id={'suggestion-' + index}
                        class={'autocomplete-suggestion' + (index === ctrl.vm.highlighted_suggestion() ? ' highlighted' : '')}
                        onclick={() => ctrl.vm.submit_suggestion(suggestion)}
                        onmouseover={() => ctrl.vm.highlight_suggestion(index)}
                    >
                        {suggestion}
                    </div>
                ))}
            </div>
        )
    }
    return null
}

export default {
    controller: Controller,
    view,
}
