import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FieldSelect, Icon, Spinner, Tippy} from '@bitstillery/common/components'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {get_url_params, set_url_params} from '@bitstillery/common/url_utils'
import {Amount} from '@bitstillery/common/components'

import {UserDropDown} from '../components/users'

import {KeyFigures} from './key_figures'

import {accountIcon} from '@/accounts'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {SalesApi, SalesOrderStatus} from '@/factserver_api/sales_api'
import {DefaultButton} from '@/components/buttons'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {GetSalesOrderFiguresResponse, GetSalesOrdersCollectionViewResponse} from '@/factserver_api/fact2server_api'

interface SalesOrderURLParams {
    status?: string
    sales_manager_artkey?: string
    search_text?: string
    overdue_orders?: boolean
    in_progress_orders?: boolean
}

export default class SalesOrderList extends MithrilTsxComponent<unknown> {
    search_bar_controller: SearchBarControl | null = null

    params: SalesOrderURLParams = get_url_params<SalesOrderURLParams>()
    sales_api = new SalesApi()
    key_figures: GetSalesOrderFiguresResponse | null = null
    show_as_exact_export = false

    show_for_status: Array<string> = [
        SalesOrderStatus.SAVED,
        SalesOrderStatus.CONFIRMED,
    ]

    oninit() {
        this.sales_order_fetcher = new PagedCollectionFetcherWithGET(
            'discover/sales-orders/collection-view',
            'reference',
            () => this.fetch_key_figures(),
            50,
            false,
        )

        this.sales_order_fetcher.filters['status'] = this.params.status !== undefined
            ? this.params.status : `${SalesOrderStatus.SAVED},${SalesOrderStatus.CONFIRMED}`

        if (this.params.sales_manager_artkey) {
            this.sales_order_fetcher.filters['sales_manager_artkey'] = this.params.sales_manager_artkey
        }

        if (this.params.overdue_orders !== undefined) {
            this.sales_order_fetcher.filters['overdue_orders'] = this.params.overdue_orders
        }

        if (this.params.in_progress_orders !== undefined) {
            this.sales_order_fetcher.filters['in_progress_orders'] = this.params.in_progress_orders
        }

        if (!this.sales_order_fetcher.filters['exact_export']) {
            this.sales_order_fetcher.filters['exact_export'] = this.show_as_exact_export
        }

        this.fetch_key_figures()
    }

    search_for_search_text = (search_text: string): void => {
        this._remove_dashboard_filters()
        this.sales_order_fetcher.set_search_terms(search_text)
        this.fetch_key_figures()
    }

    _remove_dashboard_filters = (): void => {
        delete this.params.overdue_orders
        delete this.params.in_progress_orders
        delete this.sales_order_fetcher.filters['overdue_orders']
        delete this.sales_order_fetcher.filters['in_progress_orders']
        set_url_params(this.params)
    }

    fetch_key_figures(): void {
        this.key_figures = null
        this.sales_api.key_figures(this.sales_order_fetcher.search_terms, this.sales_order_fetcher.filters).subscribe((response) => {
            this.key_figures = response
            m.redraw()
        })
    }

    view(): m.Children {
        return <div className="c-sales-order-list view">
            <div className="btn-toolbar">
                <DefaultButton
                    title={'Create sales order'}
                    icon_class={'fas fa-plus'}
                    onclick={() => m.route.set('/sales-orders/manage/create')}
                />
                <DefaultButton
                    title={'Prepare Exact export'}
                    icon_class={'fas fa-file-export'}
                    onclick={() => m.route.set('/sales-orders/manage/exact')}
                />
                {!this.key_figures && <div className="stats-group"><Spinner/></div>}
                {this.key_figures && <KeyFigures key_figures={this.key_figures}/>}
            </div>
            <div className="c-filter-group">
                <SearchBar
                    placeholder={'Search for order ref., relation, destination, warehouse ref., invoice nr.'}
                    on_submit={this.search_for_search_text}
                    default_search_text={this.sales_order_fetcher.search_text()}
                    search_bar_controller={(controller: SearchBarControl) =>
                        (this.search_bar_controller = controller)
                    }
                />
                <FieldSelect
                    model={[this.sales_order_fetcher.filters, 'status']}
                    onchange={(value: string) => {
                        this.params.status = value
                        this._remove_dashboard_filters()

                        this.sales_order_fetcher.filters.status = value
                        this.sales_order_fetcher.reset_and_query()
                    }}
                    options={[
                        {value: SalesOrderStatus.SAVED, label: 'Saved'},
                        {value: SalesOrderStatus.CONFIRMED, label: 'Confirmed'},
                        {value: `${SalesOrderStatus.SAVED},${SalesOrderStatus.CONFIRMED}`, label: 'Saved or Confirmed'},
                        {value: SalesOrderStatus.INVOICED, label: 'Invoiced'},
                        {value: SalesOrderStatus.CANCELLED, label: 'Cancelled'},
                    ]}
                    placeholder="-"
                />

                <UserDropDown
                    cache_key="sales-order-list-manager"
                    model={[this.sales_order_fetcher.filters, 'sales_manager_artkey']}
                    onchange={(user_artkey: string) => {
                        this.params.sales_manager_artkey = user_artkey
                        this._remove_dashboard_filters()
                        this.sales_order_fetcher.reset_and_query()
                    }}
                />

            </div>
            <CollectionTable<GetSalesOrdersCollectionViewResponse, void>
                on_row_click={(row: GetSalesOrdersCollectionViewResponse) => m.route.set(`/sales-orders/manage/${row.artkey}`)}
                collection_fetcher={this.sales_order_fetcher}
            >
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => '#'}
                    sort_name={'artkey'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        const children = [
                            row.reference,
                            row.has_attachments && <Icon
                                context={'Has one or more attachments'}
                                name={'paperclip'}
                                size={'s'}
                                type={'info'}
                            />,
                            row.delivery_status && <Icon
                                context={`Delivery status Green: ${row.delivery_status}`}
                                size={'s'}
                                name={'logistics'}
                                type={'info'}
                            />,
                        ]
                        return <div className={'sales-order-reference'}>{children}</div>
                    }}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Order Date'}
                    sort_name={'created_on'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => format_iso_to_date(row.created_on)}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Status'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        return [
                            row.combined_status,
                            (row.combined_status === SalesOrderStatus.INVOICED && <div>
                                <a href={`#!/sales-orders/manage/${row.artkey}/invoice`}>{row.invoice_number}</a>
                                <div>{format_iso_to_date(row.is_invoiced_on)}</div>
                            </div>),
                        ]}
                    }
                    sort_name={this.sales_order_fetcher.filters['status'] === SalesOrderStatus.INVOICED ? 'invoice_number' : 'combined_status'}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Relation'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        return [
                            accountIcon({slug: row.supplier_account_slug, name: row.supplier_account_name}),
                            row.supplier_name,
                        ]
                    }}
                    sort_name={'supplier_name'}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Destination'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        if (row.destination_name) {
                            return row.destination_name
                        }
                        else if (row.destination_location) {
                            return row.destination_name
                        }
                        return '-'
                    }}
                    sort_name={'destination_name'}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Incoterm'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        return <Tippy content={row.incoterm_and_location}>
                            <abbr title={''}>
                                {row.incoterm}
                            </abbr>
                        </Tippy>
                    }}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Cases'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => row.number_of_confirmed_cases}
                    sort_name={'number_of_cases'}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Total value'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) =>
                        <Amount
                            amount={row.was_sold_for}
                            currency={row.was_sold_in}
                            rate={row.sold_against_rate}
                        />
                    }
                    sort_name={'total_value'}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Margin'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => <Amount
                        amount={row.margin}
                        currency={row.was_sold_in}
                    />}
                    sort_name={'margin'}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Margin %'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        if (row.margin_percentage) {
                            return row.margin_percentage.toFixed(2) + '%'
                        }
                        return '-'
                    }}
                    sort_name={'margin_percentage'}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Sales manager'}
                    sort_name={'sales_manager_name'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        return [
                            `${row.sales_manager_name} `,
                            row.was_handled_by_name !== row.sales_manager_name && <Icon
                                context={`Created by ${row.was_handled_by_name}`}
                                name={'profile'}
                                size={'s'}
                                type={'info'}
                            />,
                        ]
                    }}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Warehouse'}
                    sort_name={'warehouse_status'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        if (row.warehouse_status) {
                            return `${row.warehouse_status} ${ row.warehouse_reference ? row.warehouse_reference : ''}`
                        }
                        return '-'
                    }}
                />
                <CollectionTableColumn<GetSalesOrdersCollectionViewResponse>
                    header_title={() => 'Stock'}
                    sort_name={'is_complete'}
                    data_field={(row: GetSalesOrdersCollectionViewResponse) => {
                        const all_in_stock = row.is_complete && this.show_for_status.includes(row.sales_order_status)
                        let context: string
                        if (this.show_for_status.includes(row.sales_order_status)) {
                            context = `${all_in_stock ? 'All' : 'Not all'} items are in stock or TBOs in an order`
                        } else {
                            context = 'Not applicable'
                        }

                        return <Icon
                            context={context}
                            name='stock'
                            size='s'
                            type={all_in_stock ? 'success' : 'default'}
                        />
                    }}
                />
            </CollectionTable>
        </div>
    }
}
