import m from 'mithril'
import {
    Button,
    ButtonGroup,
    CellAvailability,
    CellProduct,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    FieldMoney,
    FieldSwitch,
    FieldText,
    Icon,
    PanelContext,
    PanelFilters,
    Ranking,
    RowActionDelete,
    RowActionEdit,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {$t, api, notifier} from '@bitstillery/common/app'
import {proxy} from '@bitstillery/common/lib/proxy'
import {invalid_fields, invalid_fields_format, required, validation} from '@bitstillery/common/lib/validation'
import {watch} from '@bitstillery/common/lib/store'
import {Amount, SubAmount} from '@bitstillery/common/components'

import {OfferItems} from './components/items'

import {$s} from '@/app'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {ProductSearchMisses} from '@/components/market_info/product_search_misses'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {OfferHistory} from '@/components/market_info/offer_history'
import {CaseLabels} from '@/components/labels'
import {MarginPercentage} from '@/components/html_components'
import {GetOfferItemsCollectionViewResponse} from '@/factserver_api/fact2server_api.ts'
import {BottleThroughput} from '@/components/market_info/bottle_throughput'

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Rank',
        render: (row: GetOfferItemsCollectionViewResponse) => {
            return <Ranking model={{
                rank: row.market_rank,
                tip: () => `Average difference : ${$s.currencies.default} ${Number(row.avg_competitor_diff).formatMoney()} / ${(+row.avg_competitor_diff_percentage).toFixed(1)}%`,
                total:row.market_total,
            }} />
        },
        width: '80px',
    }, {
        name: 'Product name',
        render: (row: GetOfferItemsCollectionViewResponse) => <CellProduct
            {...row}
            additional_details={<CaseLabels
                show_as_icons={true}
                case={{
                    best_before_date: row.best_before_date,
                    serialized_item_tags: row.case_serialized_item_tags,
                    remark: row.item_remark,
                    tax_label: row.tax_label,
                }}
            />}
        />,
    }, {
        render: (row: GetOfferItemsCollectionViewResponse) => <CellAvailability row={row} />,
        name: 'Availability',
    }, {
        render: (row: GetOfferItemsCollectionViewResponse) => {
            if (!row.avg_euro_was_bought_for) return '-'
            if ($s.include_excise) {
                return [
                    <Amount
                        amount={+row.avg_euro_was_bought_for + +row.case_excise_nl}
                        currency={$s.currencies.default}
                    />,
                    <SubAmount
                        label="Ex Duty"
                        amount={row.avg_euro_was_bought_for}
                        currency={$s.currencies.default}
                    />,
                ]
            }

            return <Amount
                amount={row.avg_euro_was_bought_for}
                currency={$s.currencies.default}
            />
        },
        name: 'Purchase price',
    }, {
        render: (row: GetOfferItemsCollectionViewResponse) => {
            return <div className="td-group">
                <div className="price-base">
                    {(() => {
                        if (row.spot_price_per_case) {
                            return [
                                <Icon
                                    name="ribbon"
                                    tip={`Spotlight price; regular price is: ${row.price_per_case}`}
                                    type="info"
                                />,
                                <Amount
                                    amount={row.spot_price_per_case}
                                    currency={$s.currencies.default}
                                />,
                            ]
                        }

                        if (row.price_per_case) {
                            const elements = [<Amount
                                amount={+row.price_per_case}
                                currency={$s.currencies.default}
                            />]

                            if (row.price_up || row.price_down) {
                                elements.push(<Icon
                                    name={row.price_up ? 'arrowUpCircle' : 'arrowDownCircle'}
                                    size="xs"
                                    tip={() => {
                                        if (row.price_up) {
                                            return `Raised from ${(+row.previous_price_per_case).formatMoney()} ${$s.currencies.default}`
                                        }
                                        return `Reduced from ${(+row.previous_price_per_case).formatMoney()} ${$s.currencies.default}`
                                    }}
                                    type="warning"
                                />)
                            }
                            return elements
                        }
                        return '-'
                    })()}
                </div>
            </div>
        },
        name: 'Base Sales price',
    }, {
        render: (row: GetOfferItemsCollectionViewResponse, context) => {
            let margin_percentage = 0
            if (row.edit) {
                margin_percentage = context.calculate_margin_percentage(row)
            } else if (row.avg_margin_percentage) {
                margin_percentage = +row.avg_margin_percentage
            }

            return <MarginPercentage value={margin_percentage / 100} />
        },
        name: 'Margin',
    }, {
        render: (row: GetOfferItemsCollectionViewResponse) => {
            if (!row.max_stock_age && row.min_stock_age) return '-'
            if (row.max_stock_age === row.min_stock_age) {
                return row.max_stock_age
            }

            return <div className="td-group stock-age-group">
                <div className="stock-age">
                    <Icon
                        name="stock"
                        size="xs"
                        tip={() => {
                            return `Oldest stock age: ${row.max_stock_age} days\nNewest stock age: ${row.min_stock_age} days`
                        }}
                        type="warning"
                    />
                    <span>{row.max_stock_age}-{row.min_stock_age}</span>
                </div>
            </div>
        },
        name: 'Stock Age',
    }, {
        render: (row: GetOfferItemsCollectionViewResponse) => {
            return row.price_age_in_days || '0'
        },
        name: 'Price Age',
    }, {
        render: (row: GetOfferItemsCollectionViewResponse) => <div className="td-group stock-age-group">
            <BottleThroughput
                type={'Short'}
                prefix={'Sold'}
                throughput={{
                    last_month: row.case_quantity_sold_last_month,
                    last_quarter: row.case_quantity_sold_last_quarter,
                    last_year: row.case_quantity_sold_last_year,
                }}
            />
            <BottleThroughput
                type={'Short'}
                prefix={'Purchase'}
                throughput={{
                    last_month: row.case_quantity_bought_last_month,
                    last_quarter: row.case_quantity_bought_last_quarter,
                    last_year: row.case_quantity_bought_last_year,
                }}
            />
        </div>
        ,
        name: 'Throughput',
    },
]

export class PricelistOfferitemList extends MithrilTsxComponent<any> {
    data = proxy({
        silent_price_change: false,
    })

    watchers = [] as any

    $v = {}

    product_management_api = new ProductManagementApi()

    calculate_margin_percentage(row: GetOfferItemsCollectionViewResponse) {
        if (!row.avg_euro_was_bought_for) {
            return 0
        }
        return (+row.price_per_case - +row.avg_euro_was_bought_for) / +row.avg_euro_was_bought_for * 100
    }

    context_update() {
        const row = $s.context.data as any
        row.price_per_case = Number(row.price_per_case).toFixed(2)
        Object.assign(this.$v, {
            price_per_case: validation([row, 'price_per_case'], required()),
        })
    }

    async oninit() {
        this.watchers.push(watch($s.context, 'id', this.context_update.bind(this)))
        collection.init({
            endpoint: {
                method: 'get',
                meta: true,
                path: 'discover/offer-items/collection-view',
            },
        })
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    async row_delete(row) {
        await api.post('pricelist.delete_tbo_offer_item', {key: row.artkey})
        collection.soft_delete(row.artkey)
        notifier.notify(`TBO item ${row.product_name} was removed`, 'success')
    }

    view() {
        const row = $s.context.data as GetOfferItemsCollectionViewResponse

        return <div className="c-pricelist-offeritems view-container">
            <PanelFilters collection={collection}>
            </PanelFilters>

            <CollectionView mode="table">
                <CollectionHeader collection={collection} columns={columns} />
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    context={this}
                    row_actions={(row) => {
                        const elements = [] as any
                        if (row.offer_item_type === 'tbo') {
                            elements.push(<RowActionDelete
                                row={row}
                                row_delete={this.row_delete.bind(this, row)}
                            />)

                            if (row.originating_spli_artkey) {
                                elements.push(<Button
                                    disabled={row.loading}
                                    icon="refresh"
                                    onclick={async(e) => {
                                        e.stopPropagation()
                                        await api.post('pricelist.re_approve_tbo_offer_item', {offer_item_artkey: row.artkey})
                                        collection.soft_delete(row.artkey)
                                        notifier.notify(`TBO item ${row.product_name} is back to approval`, 'success')
                                    }}
                                    tip={() => 'Re-approve offer item'}
                                    type="warning"
                                    variant="toggle"
                                />)
                            }
                        }

                        return [
                            ...elements,

                            <RowActionEdit
                                collection={collection}
                                context={{name: 'edit_price', title: 'Edit Price'}}
                                mode="panel"
                                row={row}
                            />,
                            <Button
                                active={row.is_hidden}
                                disabled={row.loading}
                                icon={row.is_hidden ? 'eyeRemove' : 'eye'}
                                onclick={async(e) => {
                                    e.stopPropagation()
                                    await api.post('pricelist.update_offer_item', {
                                        artkey: row.artkey,
                                        maximum_quantity: row.maximum_quantity,
                                        minimum_quantity: row.minimum_quantity,
                                        sales_price_per_case: row.price_per_case,
                                        delivery_period: row.delivery_period,
                                        is_hidden: true,
                                        silent_price_change: true,
                                    })

                                    if (row.is_hidden) {
                                        notifier.notify(`Offer item "${row.product_name}" is visible again`, 'info')
                                    } else {
                                        notifier.notify(`Offer item "${row.product_name}" is now hidden`, 'warning')
                                    }
                                    row.is_hidden = !row.is_hidden
                                }}
                                tip={() => {
                                    if (row.is_hidden) {
                                        return 'Make offer item visible again'
                                    }
                                    return 'Hide offer item (disappears without hidden filter)'
                                }}
                                type={'warning'}
                                variant="toggle"
                            />,

                            <Button
                                active={row.spotlight_rank}
                                disabled={row.loading}
                                icon="ribbon"
                                onclick={async() => {
                                    if (row.spotlight_rank) {
                                        await api.post('spotlight.delete_spotlight_item', {artkey: row.artkey})
                                        row.spotlight_rank = null
                                    } else {
                                        const {result} = await api.post('spotlight.create_spotlight_item', {offer_item_artkey: row.artkey}) as any
                                        row.spotlight_rank = result.rank
                                    }
                                    m.redraw()
                                }}
                                tip={() => row.spotlight_rank ? `Rank ${row.spotlight_rank} in Spotlight; remove from Spotlight` : 'Add item to Spotlight'}
                                type={row.spotlight_rank ? 'brand' : 'default'}
                                variant="toggle"
                            />,
                        ]
                    }}
                    row_detail={(row) => {
                        return [
                            <div className="columns">
                                <div className="column is-12">
                                    <OfferItems offer_item_artkey={row.artkey} />
                                    <ProductSearchMisses product_name={row.product_name} />
                                </div>
                            </div>,
                            <div className="columns">
                                <div className="column is-12">
                                    <OfferHistory
                                        bottle_artkey={row.bottle_artkey}
                                        customs_status={row.case_customs_status}
                                    />
                                </div>
                            </div>,
                            <CaseInfoPanel
                                bottle_artkey={row.bottle_artkey}
                                current_supplier_artkey={row.offer_item_type === 'tbo' ? row.supplier_artkey : null}
                                ignore_ref={true}
                            />,
                        ]
                    }}
                    row_status={(row: GetOfferItemsCollectionViewResponse) => {
                        return {
                            render: [
                                <Icon
                                    name="image"
                                    tip={row.has_product_photo ? 'This product has one or more photos.' : 'This product has no photos.'}
                                    type={row.has_product_photo ? 'info' : 'default'}
                                />,
                                <Icon
                                    name="ribbon"
                                    tip={row.spot_price_per_case ? 'This item is in the spotlight' : 'This item is not in the spotlight.'}
                                    type={row.spot_price_per_case ? 'brand' : 'default'}
                                />,
                                <Icon
                                    name={row.is_hidden ? 'eyeRemove' : 'eye'}
                                    tip={row.is_hidden ? 'This item is hidden' : 'This item is visible.'}
                                    type={row.is_hidden ? 'warning' : 'default'}
                                />,
                            ],
                            type: row.is_hidden ? 'warning' : 'default',
                        }
                    }}
                />
            </CollectionView>

            <PanelContext collection={collection}>
                <div className="content">
                    {$s.context.name === 'edit_price' && <div className="fieldset">
                        <div className="field-group">
                            <FieldText
                                help={'Set minimum order amount in portal'}
                                label="Min quantity"
                                max={row.maximum_order_quantity || row.number_of_cases_available}
                                min={0}
                                model={[row, 'minimum_order_quantity']}
                                type="number"
                            />
                            <FieldText
                                label="Max quantity"
                                help={'Set maximum order amount in portal'}
                                max={row.offer_item_type === 'tbo' ? undefined : row.number_of_cases_available}
                                min={row.minimum_order_quantity || 1}
                                model={[row, 'maximum_order_quantity']}
                                type="number"
                            />

                        </div>
                        {row.offer_item_type === 'tbo' && <FieldText
                            label="Delivery period (TBO)"
                            help="This is the time it takes to order the product in weeks"
                            min={1}
                            model={[row, 'delivery_period']}
                            type="number"
                        />}
                        <FieldMoney
                            currency={[$s.currencies, 'default']}
                            help='Base sales price for product'
                            min={0}
                            model={[row, 'price_per_case']}
                            validation={this.$v.price_per_case}
                        />

                        <FieldSwitch
                            help="Update the price of this item without notifying "
                            label="Silent price change"
                            model={[this.data, 'silent_price_change']}
                        />
                    </div>}
                    <ButtonGroup>
                        <Button
                            disabled={invalid_fields(this.$v).length}
                            icon="save"
                            onclick={async() => {
                                await api.post('pricelist.update_offer_item', {
                                    artkey: row.artkey,
                                    maximum_quantity: row.maximum_order_quantity,
                                    minimum_quantity: row.minimum_order_quantity,
                                    sales_price_per_case: row.price_per_case,
                                    delivery_period: row.delivery_period_in_weeks,
                                    is_hidden: row.is_hidden,
                                    silent_price_change: this.data.silent_price_change,
                                })

                                if (this.data.silent_price_change) {
                                    notifier.notify($t('notifications.saved_offer_items_alt', {count: 1}), 'info')
                                } else {
                                    notifier.notify($t('notifications.saved_offer_items', {count: 1}), 'success')
                                }

                                collection.update_context()
                            }}
                            text="Save Offer Item"
                            tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                            type="success"
                        />
                    </ButtonGroup>
                </div>
            </PanelContext>
        </div>
    }
}
