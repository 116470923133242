/** llm:tested */
import m from 'mithril'
import {map} from 'prelude-ls'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {preventDefault} from '@/_utils'
import confirmation from '@/components/confirmation'
import api from '@/api'
import {$m} from '@/app'

interface Field {
    label: string
    type: string
    view: (entity: any) => m.Vnode
}

interface GenericEditAttrs {
    create_entity_func: () => any
    unique_name: string
    readable_entity_name: string
    base_url: string
    back_url?: string
    broadcast_on_update?: string
    update_or_create_api_call: string
    fields: Field[]
    additional_params?: any
    can_deactivate?: boolean
    deactivate_api_call?: string
    noedit?: boolean
    query_api_call?: string
    className?: string
}

export class GenericEdit extends MithrilTsxComponent<any> {
    entity: any
    user: any
    unique_name: string
    create_entity_func: () => any
    readable_entity_name: any
    base_url: any
    back_url: any
    broadcast_on_update: any
    update_or_create_api_call: any
    fields: any
    additional_params: any
    can_deactivate: any
    deactivate_api_call: any
    noedit: boolean
    create: any

    constructor(vnode: m.Vnode<GenericEditAttrs>) {
        super()
        this.entity = window.prop(vnode.attrs.create_entity_func())
        this.user = window.prop([])

        this.unique_name = vnode.attrs.unique_name
        this.create_entity_func = vnode.attrs.create_entity_func
        this.readable_entity_name = window.prop(vnode.attrs.readable_entity_name)
        this.base_url = window.prop(vnode.attrs.base_url)
        this.back_url = window.prop(vnode.attrs.back_url || vnode.attrs.base_url)
        this.broadcast_on_update = window.prop(vnode.attrs.broadcast_on_update || '')
        this.update_or_create_api_call = window.prop(vnode.attrs.update_or_create_api_call)
        this.fields = window.prop(vnode.attrs.fields)
        this.additional_params = vnode.attrs.additional_params || window.prop({})
        this.can_deactivate = window.prop(vnode.attrs.can_deactivate || vnode.attrs.deactivate_api_call !== undefined)
        this.deactivate_api_call = window.prop(vnode.attrs.deactivate_api_call)

        this.noedit = vnode.attrs.noedit
        this.create = window.prop(false)

        const artkey = m.route.param('artkey')
        if (artkey) {
            this.query_entity(artkey, vnode.attrs.query_api_call)
        } else { // We are creating a new one
            this.create(true)
        }
    }

    query_entity(artkey: string, query_api_call: string) {
        const data = this.additional_params()
        data.artkey = artkey
        api.call(query_api_call, data, this.set_entity.bind(this))
    }

    set_entity(resp: any) {
        if (resp.success) {
            this.entity(this.create_entity_func(resp.result))
        } else {
            notifier.notify(`Unknown ${this.readable_entity_name()}.`, 'danger')
            m.route.set(this.base_url())
        }
    }

    save() {
        const data = this.additional_params()
        data.artkey = this.entity().artkey()

        for (const field of this.fields()) {
            let value = this.entity()[field.label]()
            if (field.type === 'number') {
                value = +value
            }
            data[field.label] = value
        }

        api.call(this.update_or_create_api_call(), data, this.handle_save.bind(this))
    }

    handle_save(result: any) {
        if (!result.success) {
            if (result.message) {
                notifier.notify(result.message, 'danger')
            } else {
                $m.common.generic_error_handler()
            }
        } else {
            if (this.broadcast_on_update()) {
                $m.common.observable.broadcast(this.broadcast_on_update(), '')
            }
            if (this.create()) {
                notifier.notify(`Successfully created new ${this.readable_entity_name()}.`, 'success')
                if (!this.noedit) {
                    m.route.set(`${this.base_url().replace(/\/+$/, '')}/${result.artkey}/edit`, {key: 'edit'})
                } else {
                    m.route.set(`${this.base_url()}`)
                }
            } else {
                notifier.notify(`Successfully updated ${this.readable_entity_name()}.`, 'success')
            }
        }
    }

    deactivate() {
        if (this.deactivate_api_call() !== undefined) {
            confirmation.show({
                title: `Deactivate ${this.readable_entity_name()}`,
                message: `Are you sure you want to delete this ${this.readable_entity_name()}?`,
                unique_name: `${this.unique_name}_deactivate_confirm`,
                onconfirm: () => {
                    const data = this.additional_params()
                    data.artkey = this.entity().artkey()
                    api.call(this.deactivate_api_call(), data, this.handle_deactivate.bind(this))
                },
            })
        } else {
            m.route.set(`${this.base_url()}/${this.entity().artkey()}/deactivate`)
        }
    }

    handle_deactivate(result: any) {
        if (!result.success) {
            if (result.message) {
                notifier.notify(result.message, 'danger')
            } else {
                $m.common.generic_error_handler()
            }
        } else {
            notifier.notify(`Successfully deleted ${this.readable_entity_name()}.`, 'success')
            if (this.broadcast_on_update()) {
                $m.common.observable.broadcast(this.broadcast_on_update(), '')
            }
            m.route.set(this.back_url())
        }
    }

    button_text() {
        if (this.readable_entity_name) {
            if (this.create()) {
                return `Create ${this.readable_entity_name()}`
            } else {
                return `Update ${this.readable_entity_name()}`
            }
        } else {
            if (this.create()) {
                return 'Create'
            } else {
                return 'Update'
            }
        }
    }

    view(vnode: m.Vnode<GenericEditAttrs>) {
        return <div class={classes('c-generic-edit view', vnode.attrs.className)}>
            <div class="btn-toolbar">
                <button
                    class="btn btn-default"
                    type="button"
                    onclick={() => m.route.set(this.back_url())}
                >
                    <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                </button>
                {this.can_deactivate() && !this.create() &&
                    <button
                        class="btn btn-danger"
                        type="button"
                        onclick={this.deactivate.bind(this)}
                    >
                        <span class="glyphicon glyphicon-trash"></span> Deactivate
                    </button>
                }
            </div>

            <form class="flex-form" onsubmit={preventDefault(this.save.bind(this))}>
                <div class="fieldset">
                    {map((field: Field) =>
                        field.view(this.entity())
                    , this.fields())}
                </div>

                <button class="btn btn-success btn-submit">
                    {this.button_text()}
                </button>
            </form>
        </div>
    }
}
