import {Observable, of, Subject} from 'rxjs'
import {filter, mergeAll, toArray} from 'rxjs/operators'
import {api} from '@bitstillery/common/app'
import {proxy} from '@bitstillery/common/lib/proxy'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {logger} from '@bitstillery/common/app'

import {is_not_null_or_undefined_type_guard} from '../rxjs_utils'

import {Api, FactserverEmtpyRequest} from './api'

export interface GetItemTagResponse {
    artkey: number
    name: string
    abbreviation: string
    item_tag_category: {
        artkey: number
        name: string
    }
}

export const TAX_LABEL_CATEGORY = 'Tax Label'
export const PACKAGING_CATEGORY = 'Packaging'
export const PACK_SIZE_CATEGORY = 'Pack Size'
export const DAMAGES_CATEGORY = 'Damages'
export const GENERAL_CATEGORY = 'General'

/**
 * Manages item tags data and provides functionality to set tag selections.
 * This class handles the storage and manipulation of item tag data,
 * including options for different tag categories and their selections.
 */
export class TagModel {
    data: any
    item_tags: any = []

    async load_data() {
        const {result} = await api.post('product_management.get_all_item_tags', {}, false) as any
        this.item_tags.splice(0, this.item_tags.length, ...result)
        this.data = proxy({
            Damages: {
                options: this.item_tags.filter((i) => i.item_tag_category.name === 'Damages').map((i) => ({value: i.artkey, label: i.name})),
                selection: [],
            },
            General: {
                options: this.item_tags.filter((i) => i.item_tag_category.name === 'General').map((i) => ({value: i.artkey, label: i.name})),
                selection: [],
            },
            Packaging: {
                options: this.item_tags.filter((i) => i.item_tag_category.name === 'Packaging').map((i) => ({value: i.artkey, label: i.name})),
                selection: '',
            },
            'Pack Size': {
                options: this.item_tags.filter((i) => i.item_tag_category.name === 'Pack Size').map((i) => ({value: i.artkey, label: i.name})),
                selection: '',
            },
            'Tax Label': {
                options: this.item_tags.filter((i) => i.item_tag_category.name === 'Tax Label').map((i) => ({value: i.artkey, label: i.name})),
                selection: [],
            },
        })
    }

    serialize_selection() {
        let selection = []
        for (const tag_category of Object.values(this.data)) {
            if (Array.isArray(tag_category.selection)) {
                if (tag_category.selection.length) {
                    selection.push(...tag_category.selection)
                }
            } else {
                if (tag_category.selection) {
                    selection.push(tag_category.selection)
                }
            }
        }

        selection = [...new Set(selection)]

        return selection
    }

    set_selection(item_tag_ids: number[]) {
        logger.info(`[TagModel] set selection: (${item_tag_ids.length})`)
        const selection = {}
        for (const tag_id of item_tag_ids) {

            const tag_data = this.item_tags.find((i) => i.artkey === tag_id)
            if (!tag_data) {
                continue
            }
            const tag_category = tag_data.item_tag_category.name

            if (Array.isArray(this.data[tag_category].selection)) {
                if (!selection[tag_category]) {
                    selection[tag_category] = {selection: []}
                }
                selection[tag_category].selection.push(tag_id)
            } else {
                if (!selection[tag_category]) {
                    selection[tag_category] = {selection: ''}
                }
                selection[tag_category].selection = tag_id
            }
        }
        merge_deep(this.data, selection)
    }

}

export class ItemTagsDropDownData {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: ItemTagsDropDownData | null = null
    /** Cached results, when available */
    private result: GetItemTagResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetItemTagResponse[]> = new Subject<GetItemTagResponse[]>()

    private constructor() {
        this.api
            .post_request<FactserverEmtpyRequest, GetItemTagResponse[]>('product_management.get_all_item_tags', {})
            .subscribe({
                next: (response: GetItemTagResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): ItemTagsDropDownData {
        if (ItemTagsDropDownData._instance === null) {
            ItemTagsDropDownData._instance = new ItemTagsDropDownData()
        }

        return ItemTagsDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static item_tags$(): Observable<GetItemTagResponse[]> {
        if (ItemTagsDropDownData.instance().drop_down_data$.isStopped) {
            return of(ItemTagsDropDownData.instance().result)
        }

        return ItemTagsDropDownData.instance().drop_down_data$
    }

    /** Returns the observable for the item tags of a specific category. */
    public static item_tags_for_category$(category: string): Observable<GetItemTagResponse[]> {
        return ItemTagsDropDownData.item_tags$().pipe(
            filter(is_not_null_or_undefined_type_guard),
            mergeAll(),
            filter((item_tag: GetItemTagResponse) => item_tag.item_tag_category.name === category),
            toArray(),
        )
    }

    public static artkeys_for_category(category: string): string[] {
        return (
            ItemTagsDropDownData.instance()
                .result.filter((tag) => tag.item_tag_category.name === category)
                .map((item) => `${item.artkey}`) || []
        )
    }

    public static is_artkey_in_category(artkey: string, category: string): boolean {
        const artkeys_for_category = ItemTagsDropDownData.artkeys_for_category(category)
        return artkeys_for_category.includes(artkey)
    }

    public static get_item_tag_for_artkey(artkey: string): GetItemTagResponse | undefined {
        return ItemTagsDropDownData.instance().result.find((tag) => tag.artkey === +artkey)
    }

    /* ----------------------------------------------------------------------
    Tax label access functions
    ------------------------------------------------------------------------- */
    public static tax_label_item_tags$(): Observable<GetItemTagResponse[]> {
        return ItemTagsDropDownData.item_tags_for_category$(TAX_LABEL_CATEGORY)
    }
}
