/** llm:tested */
import m from 'mithril'
import {DateTime} from 'luxon'
import {filter, toArray, mergeAll} from 'rxjs/operators'
import {classes} from '@bitstillery/common/lib/utils'
import {Button} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {EmailProcessFlow, EmailBatchType, EmailBatch} from './models'
import templates from './templates'

import * as api from '@/api'
import {icon_button, text_button} from '@/components/_buttons'
import * as inputs from '@/components/inputs'
import {EmailPreview} from '@/email/components/email_preview'
import {Offer} from '@/models/offers'
import {RelationDropDown} from '@/components/relation'
import {RelationDropDownData} from '@/factserver_api/relation_api'
import {UserDropDown} from '@/components/users'
import {ProcessManageOffer} from '@/offer/offers/processes'
import {Texteditor} from '@/components/inputs'

export class ScheduleType {
    static ASAP = 'ASAP'
    static SCHEDULED = 'SCHEDULED'
    static ONCE_A_WEEK = 'ONCE_A_WEEK'
    static MONDAY_TILL_FRIDAY = 'MONDAY_TILL_FRIDAY'
    static DAILY = 'DAILY'

    static choices = {
        ASAP: 'As soon as possible',
        SCHEDULED: 'On a certain date/time',
        ONCE_A_WEEK: 'Repeat on a weekday',
        MONDAY_TILL_FRIDAY: 'Repeat every weekday',
        DAILY: 'Every day',
    }
}

export class WeekDays {
    static SUN = 'SUN'
    static MON = 'MON'
    static TUE = 'TUE'
    static WED = 'WED'
    static THU = 'THU'
    static FRI = 'FRI'
    static SAT = 'SAT'

    static choices = {
        SUN: 'Sunday',
        MON: 'Monday',
        TUE: 'Tuesday',
        WED: 'Wednesday',
        THU: 'Thursday',
        FRI: 'Friday',
        SAT: 'Saturday',
    }
}

interface EmailPlaceholder {
  placeholder: string
  description: string
}

export class EmailUpsert extends MithrilTsxComponent<any> {
    supplier_artkey: any
    preview_body: any
    email_batch: EmailBatch
    footer: any
    create: boolean
    loading: boolean
    offer: any
    email_batch_artkey: string
    offer_artkey: string
    offers: any
    language: any
    email_placeholders: any
    selected_placeholder: any
    placeholder_description: Record<string, string>
    schedule_type: any
    selected_weekday: any
    selected_scheduled_date: any
    selected_scheduled_time: any
    sent_from_sales_manager: any

    constructor() {
        super()
        this.supplier_artkey = window.prop('')
        this.preview_body = window.prop('Wait while fetching relations ...')

        this.email_batch = new EmailBatch()
        this.email_batch.batch_type(EmailBatchType.NOTHING)
        this.footer = window.prop('')

        this.create = false
        this.loading = true
        this.email_batch.body('#contact.salutation# #contact.first_name#')

        this.offer = window.prop(new Offer())

        this.email_batch_artkey = m.route.param('artkey')
        if (this.email_batch_artkey) {
            this.query_batch(this.email_batch_artkey)
        } else {
            this.create = true
            this.query_footer()
        }

        this.offer_artkey = m.route.param('offer')
        if (this.offer_artkey) {
            this.email_batch.batch_type(EmailBatchType.CUSTOM_OFFER)
            this.email_batch.offer_artkey(this.offer_artkey)
        }

        this.offers = window.prop([])
        this.query_custom_offers()
        this.language = window.prop(null)

        this.email_placeholders = window.prop(null)
        this.selected_placeholder = window.prop('')
        this.placeholder_description = {}

        api.callAndThen('email_i18n_placeholders.get_all_email_placeholders', {}, {
            success: (resp: {result: EmailPlaceholder[]}) => {
                this.email_placeholders(resp.result.map((ph) => ph.placeholder).sort())
                resp.result.forEach((ph) => this.placeholder_description[ph.placeholder] = ph.description)
            },
        })

        this.schedule_type = window.prop(ScheduleType.ASAP)
        this.selected_weekday = window.prop(WeekDays.MON)
        this.selected_scheduled_date = window.prop(null)
        this.selected_scheduled_time = window.prop(null)
        this.sent_from_sales_manager = window.prop(true)
    }

    oncreate(): void {
        this.initialize_preview()
    }

    initialize_preview(): void {
        this.preview_body('Select a client for a preview ...')
        if (this.offer_artkey) {
            this.query_offer(this.offer_artkey)
        }
    }

    get_process_flow(): EmailProcessFlow {
        if (this.offer_artkey) {
            return EmailProcessFlow.OFFERS
        }
        return EmailProcessFlow.MAILS
    }

    query_footer(): void {
        api.callAndThen('pricelistbatch.get_footer', {}, {
            success: (resp: {result: string}) => {
                this.loading = false
                this.footer(resp.result)
            },
        })
    }

    query_batch(artkey: string): void {
        api.callAndThen('email.batch.get', {offer_email_batch_artkey: artkey}, {
            success: (resp: {result: any}) => {
                this.email_batch.from_js(resp.result)

                if (this.email_batch.cron_expression().split(' ')[4] === '1-5') {
                    this.schedule_type(ScheduleType.MONDAY_TILL_FRIDAY)
                } else if (this.email_batch.cron_expression().split(' ')[4] !== '*') {
                    this.selected_weekday(this.email_batch.cron_expression().split(' ')[4])
                    this.schedule_type(ScheduleType.ONCE_A_WEEK)
                } else if (this.email_batch.cron_expression().includes('* * * *')) {
                    this.schedule_type(ScheduleType.DAILY)
                } else {
                    this.schedule_type(ScheduleType.SCHEDULED)
                }
                this.selected_scheduled_date(DateTime.fromISO(this.email_batch.next_run()).toISODate())
                this.selected_scheduled_time(DateTime.fromISO(this.email_batch.next_run()).toFormat('HH:mm'))

                if (this.email_batch.offer_artkey()) {
                    this.query_custom_offers()
                }
                this.loading = false
            },
        })
    }

    query_custom_offers(): void {
        const data = this.email_batch.offer_artkey()
            ? {include_offer_artkey: this.email_batch.offer_artkey()}
            : {}

        api.callAndThen('offer.get_offer_records', data, {
            success: (resp: {result: any[]}) => {
                this.offers(resp.result)
            },
        })
    }

    query_offer(artkey: string): void {
        const data = {
            artkey,
            include_suppliers: true,
        }

        api.callAndThen('offer.get_offer', data, {
            success: (resp: {result: any}) => {
                this.offer(new Offer(resp.result))

                if (resp.result.suppliers.length === 1) {
                    this.supplier_artkey(`${resp.result.suppliers[0].artkey}`)
                }

                this.refresh_preview()
            },
            failure: () => {
                notifier.notify('Unknown offer.', 'danger')
                m.route.set('/offer/offers')
            },
        })
    }

    save(e: Event): void {
        e.preventDefault()

        const body = this.email_batch.body()
        const scheduled_datetime = DateTime.fromISO(`${this.selected_scheduled_date()}T${this.selected_scheduled_time()}`)

        if (this.email_batch.batch_type() !== EmailBatchType.CUSTOM_OFFER) {
            this.email_batch.offer_artkey(null)
        }

        const data = {
            offer_email_batch_artkey: this.email_batch.artkey() || null,
            subject: this.email_batch.subject(),
            body,
            pricelist_type: this.email_batch.batch_type(),
            offer_artkey: this.email_batch.offer_artkey(),
            sent_from_user_artkey: this.email_batch.use_sent_from_user_artkey(),
            scheduling_type: this.schedule_type(),
            scheduled_datetime,
            scheduled_weekday: this.selected_weekday(),
        }

        api.callAndThen('pricelistbatch.create_or_update', data, {
            success: (result: {result: {artkey: string}}) => {
                if (this.create) {
                    notifier.notify(`Successfully created new e-mail batch '${this.email_batch.subject()}'.`, 'success')
                    if (this.get_process_flow() === EmailProcessFlow.OFFERS) {
                        m.route.set(`/offer/offers/${this.offer_artkey}/email/${result.result.artkey}/recipient-selection`)
                    } else if (this.get_process_flow() === EmailProcessFlow.MAILS) {
                        m.route.set(`/crm/email/${result.result.artkey}/recipient-selection`)
                    }
                } else {
                    notifier.notify('Successfully updated e-mail batch.', 'success')
                    if (this.get_process_flow() === EmailProcessFlow.OFFERS) {
                        m.route.set(`/offer/offers/${this.offer_artkey}/email/${result.result.artkey}/recipient-selection`)
                    } else {
                        m.route.set('/crm/email/' + this.email_batch.artkey())
                    }
                }
            },
        })
    }

    insert_content(value: string): void {
        window.$('#summernote').summernote('insertText', value + ' ')
        this.refresh_preview()
    }

    after_update(): void {
        m.redraw()
    }

    insert_template_pricelist(): void {
        this.email_batch.subject('#subject_pricelist#')
        this.email_batch.body(templates.pricelist)
        this.email_batch.batch_type(EmailBatchType.ENTIRE_PRICELIST)
    }

    insert_template_offer(): void {
        this.email_batch.subject('#subject_offer#')
        this.email_batch.body(templates.offer)
        this.email_batch.batch_type(EmailBatchType.CUSTOM_OFFER)
    }

    insert_template_spot_offer(): void {
        this.email_batch.subject('#subject_spotoffer#')
        this.email_batch.body(templates.spotOffer)
        this.email_batch.batch_type(EmailBatchType.CUSTOM_OFFER)
    }

    insert_template_purchase_request(): void {
        this.email_batch.subject('#subject_purchase#')
        this.email_batch.body(templates.purchaseRequest)
        this.email_batch.batch_type(EmailBatchType.PURCHASE_ENQUIRY)
    }

    refresh_preview(): void {
        if (this.supplier_artkey()) {
            if (this.email_batch.batch_type() !== EmailBatchType.CUSTOM_OFFER) {
                this.email_batch.offer_artkey(null)
            }
            const data = {
                offer_email_batch_artkey: this.email_batch.artkey() || null,
                subject: this.email_batch.subject(),
                body: this.email_batch.body(),
                pricelist_type: this.email_batch.batch_type(),
                offer_artkey: this.email_batch.offer_artkey(),
                supplier_artkey: this.supplier_artkey(),
                from_email_address_artkey: this.email_batch.use_sent_from_user_artkey(),
            }
            this.preview_body('fetching preview...')
            api.callAndThen('pricelistbatch.get_rendered_email_for_supplier', data, {
                success: (resp: {result: {message?: string; body: string; country_language: string}}) => {
                    if (resp.result.message !== undefined) {
                        this.preview_body('Error while rendering preview: ' + resp.result.message)
                    } else {
                        this.preview_body(resp.result.body)
                        this.language(resp.result.country_language)
                    }
                },
            })
        }
    }

    view(): m.Children {
        return (
            <div class={classes('.c-process-new-offer-step-5', 'view', {process: this.get_process_flow() === EmailProcessFlow.OFFERS})}>
                {this.get_process_flow() === EmailProcessFlow.OFFERS ? (
                    <ProcessManageOffer
                        active="email_content"
                        context={{
                            offer_artkey: this.email_batch.offer_artkey(),
                            email_batch_artkey: this.email_batch.artkey(),
                        }}
                    />
                ) : (
                    <div class="btn-toolbar">
                        <button
                            class="btn btn-default"
                            type="button"
                            onclick={() => {
                                if (this.email_batch.artkey()) {
                                    m.route.set(`/crm/email/${this.email_batch.artkey()}`)
                                } else {
                                    m.route.set('/crm/email')
                                }
                            }}
                        >
                            <span class="glyphicon glyphicon-arrow-left" /> Back to list
                        </button>
                    </div>
                )}

                {!this.loading && (
                    <div class="c-email-edit step-content">
                        <form onsubmit={(e: Event) => this.save(e)}>
                            <div class="fieldset-group large">
                                <div class="fieldset">
                                    <div class="fieldset-label">Email Composer</div>

                                    {this.email_batch.batch_type() === EmailBatchType.PURCHASE_ENQUIRY ? (
                                        <UserDropDown
                                            label="From address"
                                            model={[this.email_batch, 'use_sent_from_user_artkey']}
                                        />
                                    ) : this.sent_from_sales_manager() ? (
                                        inputs.checkbox(this.sent_from_sales_manager, {
                                            label: 'From sales manager',
                                            help: 'Use sales manager of the relation in from address',
                                        })
                                    ) : (
                                        <UserDropDown
                                            label="From address"
                                            help="Note that all the relations will see the mail address of the selected sales manager."
                                            model={[this.email_batch, 'use_sent_from_user_artkey']}
                                            onchange={(user_artkey: string) => {
                                                if (user_artkey) {
                                                    this.sent_from_sales_manager(false)
                                                } else if (user_artkey === '' && this.email_batch.use_sent_from_user_artkey()) {
                                                    this.sent_from_sales_manager(true)
                                                }
                                            }}
                                        />
                                    )}

                                    <div class="field">
                                        <label>Email subject</label>
                                        {inputs.text(this.email_batch.subject, {required: true, id: 'subject'})}
                                    </div>

                                    <div class="field-group">
                                        {inputs.select(this.schedule_type, ScheduleType.choices, {
                                            empty_option: false,
                                            label: 'Scheduling type',
                                        })}

                                        {this.schedule_type() === ScheduleType.ONCE_A_WEEK ? (
                                            inputs.select(this.selected_weekday, WeekDays.choices, {
                                                empty_option: false,
                                                label: 'Weekdays',
                                            })
                                        ) : this.schedule_type() === ScheduleType.SCHEDULED && ([
                                            inputs.date(this.selected_scheduled_date, {
                                                label: 'Date',
                                                required: true,
                                            }),
                                            inputs.time(this.selected_scheduled_time, {
                                                label: 'Time',
                                                required: true,
                                            }),
                                        ])}
                                    </div>

                                    <div class="field">
                                        <label>Templates</label>
                                        <div class="btn-group">
                                            {this.get_process_flow() === EmailProcessFlow.MAILS && (
                                                <a
                                                    class="btn btn-default template"
                                                    onclick={() => this.insert_template_pricelist()}
                                                >
                                                    Pricelist
                                                </a>
                                            )}
                                            <a
                                                class="btn btn-default template"
                                                onclick={() => this.insert_template_offer()}
                                            >
                                                Offer
                                            </a>
                                            <a
                                                class="btn btn-default template"
                                                onclick={() => this.insert_template_spot_offer()}
                                            >
                                                Spot offer
                                            </a>
                                            {this.get_process_flow() === EmailProcessFlow.MAILS && (
                                                <a
                                                    class="btn btn-default template"
                                                    onclick={() => this.insert_template_purchase_request()}
                                                >
                                                    Purchase inquiry
                                                </a>
                                            )}
                                        </div>
                                    </div>

                                    <div class="field">
                                        <Texteditor
                                            text={this.email_batch.body}
                                            required={true}
                                            after_update={() => this.after_update()}
                                        />
                                    </div>

                                    {this.get_process_flow() === EmailProcessFlow.MAILS &&
                                        this.email_batch.batch_type() !== EmailBatchType.PURCHASE_ENQUIRY && (
                                        <div class="field">
                                            <label>Include</label>
                                            {inputs.radio(this.email_batch.batch_type, [
                                                {value: EmailBatchType.ENTIRE_PRICELIST, description: 'Entire pricelist'},
                                                {value: EmailBatchType.CUSTOM_OFFER, description: 'Custom offer'},
                                                {value: EmailBatchType.NOTHING, description: 'Nothing'},
                                            ])}
                                        </div>
                                    )}

                                    {this.get_process_flow() === EmailProcessFlow.MAILS &&
                                        this.email_batch.batch_type() === EmailBatchType.CUSTOM_OFFER &&
                                        inputs.offer(this.offers, this.email_batch.offer_artkey, {
                                            label: 'Custom offer',
                                            required: true,
                                        })}

                                    <div class="field">
                                        <label>Generic variables</label>
                                        <div class="control">
                                            {this.email_placeholders() && inputs.select(
                                                this.selected_placeholder, this.email_placeholders(), {},
                                            )}
                                            {icon_button('plus', {
                                                class: 'btn-default no-click',
                                                onclick: (e: Event) => {
                                                    e.preventDefault()
                                                    this.insert_content('#' + this.selected_placeholder() + '#')
                                                },
                                            })}
                                        </div>
                                        <div class="help">
                                            Variable for: {this.placeholder_description[this.selected_placeholder()]}
                                        </div>
                                    </div>
                                </div>

                                <div class="fieldset large">
                                    <div class="fieldset-label">Email Preview</div>

                                    <div class="refresh-wrapper">
                                        <div class="field">
                                            <label>Relation</label>
                                            {this.offer_artkey && this.offer().suppliers && this.offer().suppliers().length > 0 ? (
                                                <RelationDropDown
                                                    get_all_for_drop_down_response$={RelationDropDownData.relations().pipe(
                                                        mergeAll(),
                                                        filter((relation) => this.offer().suppliers().map((supplier) => supplier.artkey).includes(relation.artkey)),
                                                        toArray(),
                                                    )}
                                                    model={[this, 'supplier_artkey']}
                                                    onchange={() => this.refresh_preview()}
                                                />
                                            ) : !this.offer_artkey && (
                                                <RelationDropDown
                                                    get_all_for_drop_down_response$={RelationDropDownData.relations()}
                                                    model={[this, 'supplier_artkey']}
                                                    onchange={() => this.refresh_preview()}
                                                />
                                            )}
                                        </div>

                                        <Button
                                            onclick={(e: Event) => {
                                                e.preventDefault()
                                                this.refresh_preview()
                                            }}
                                            text="Refresh Preview"
                                            type="info"
                                        />
                                        <div class={`flag flag-icon flag-icon-${this.language()}`} />
                                    </div>

                                    <div class="field">
                                        <div class="content-preview">
                                            <EmailPreview email={this.preview_body} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="btn-toolbar">
                                {text_button(
                                    this.create ? 'Create Batch' : 'Update Batch',
                                    !this.email_batch.body(),
                                    {type: 'submit', class: 'btn-success btn-submit'},
                                )}
                            </div>
                        </form>
                    </div>
                )}
            </div>
        )
    }
}
