/** llm:tested */
/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Higher order function that returns an event handler that will stop the
 * propagation of the event and then call fn. By default the fn argument
 * is an empty function, thus you can use this function as follows:
 *
 *     {onclick: stopPropagation()}
 */
export const stopPropagation = (fn: (e: Event) => void = () => {}) => (e: Event) => {
    e.stopPropagation()
    fn(e)
}

export const preventDefault = (fn: (e: Event) => void) => (e: Event) => {
    e.preventDefault()
    fn(e)
}
