import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {DateTime} from 'luxon'
import {
    proforma_or_invoice_renderer,
    ProformaInvoiceRendererAttrs,
    ProformaInvoice,
} from '@bitstillery/common/pdf/proforma_invoice_renderer'
import {CasesOrBottles} from '@bitstillery/common/pdf/pdf'
import {SalesOrder} from '@bitstillery/common/models/sales_order'
import {Account} from '@bitstillery/common/models/account'
import {switchMap} from 'rxjs/operators'
import {from, Observable} from 'rxjs'
import {Tippy} from '@bitstillery/common/components'
import {AccountSlug} from '@bitstillery/common/account/account.ts'
import {notifier} from '@bitstillery/common/app'

import {LoenderslootFlow, SendToLoenderslootModal} from './send_to_loendersloot_modal'

import {show} from '@/components/confirmation'
import {$m} from '@/app'
import {DefaultButton} from '@/components/buttons'
import {EditAssist} from '@/components/form'
import {PDFHelper} from '@/components/pdf_helper'
import {
    DeliveryStatus,
    GetFastSalesOrderWithItemsResponse, is_exw_loendersloot, is_origin_loendersloot,
    SalesApi,
    SalesOrderStatus,
} from '@/factserver_api/sales_api'
import {LoenderslootApi} from '@/factserver_api/loendersloot_api'
import {AttachmentApi, AttachmentType, GetAttachmentResponse} from '@/factserver_api/attachment_api'
import {LOENDERSLOOT_WAREHOUSE_ARTKEY} from '@/factserver_api/factserver_generic'

export interface DeliveryButtonsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    on_changed: () => unknown
}

/**
 * Shows the status buttons for delivery related actions.
 * - Green transport.
 * - Confirm green transport.
 * - Sent to Loendersloot.
 */
// eslint-disable-next-line no-redeclare
export class DeliveryButtons extends MithrilTsxComponent<DeliveryButtonsAttrs> {
    sales_api = new SalesApi()
    attachment_api = new AttachmentApi()
    loendersloot_api = new LoenderslootApi()
    confirm_sent_to_loendersloot = false
    edit_assist: EditAssist = new EditAssist(m.route)
    loendersloot_flow = LoenderslootFlow.OUTBOUND
    pdf_helper = new PDFHelper<ProformaInvoiceRendererAttrs>(proforma_or_invoice_renderer)

    confirm_green_transport(vnode: m.Vnode<DeliveryButtonsAttrs>): void {
        this.sales_api.confirm_green_transport(vnode.attrs.sales_order.artkey).subscribe(() => {
            notifier.notify('Green transport confirmed', 'success')
            vnode.attrs.on_changed()
        })
    }

    signal_changed(vnode: m.Vnode<DeliveryButtonsAttrs>): void {
        if (vnode.attrs.on_changed) {
            vnode.attrs.on_changed()
        }
    }

    send_to_loendersloot(vnode: m.Vnode<DeliveryButtonsAttrs>): void {
        this.confirm_sent_to_loendersloot = false
        this.signal_changed(vnode)
        m.redraw()
    }

    close_sent_to_loendersloot_confirmation(): void {
        this.confirm_sent_to_loendersloot = false
    }

    cancel_pre_advice(vnode: m.Vnode<DeliveryButtonsAttrs>) {
        show({
            title: 'Confirm the cancelling of the pre-advice',
            unique_name: 'cancel-pre-advice',
            message: 'The related buy-from-account Sales order and Purchase order will be cancelled and Loendersloot will be notified of this cancel.',
            onconfirm: () => {
                this.loendersloot_api.cancel_pre_advice(vnode.attrs.sales_order.artkey).subscribe({
                    next: () => {
                        notifier.notify('The pre-advice is cancelled.', 'success')
                        this.signal_changed(vnode)
                        vnode.attrs.sales_order.pre_advice_cancelled_on = DateTime.now().toISO()
                        m.redraw()
                    }})
            },
        })
    }

    all_sales_order_items_are_stocked(sales_order: GetFastSalesOrderWithItemsResponse): boolean {
        return sales_order.sales_order_items.every((item) => item.item.lot)
    }

    upload_pdf_to_related_purchase_order(vnode: m.Vnode<DeliveryButtonsAttrs>): Observable<any> {
        const buy_from_account_sales_order_artkey = vnode.attrs.sales_order.buy_from_account_sales_order_artkey
        const buy_from_account_purchase_order_artkey = vnode.attrs.sales_order.buy_from_account_purchase_order_artkey
        const buy_from_account_sales_order_account = vnode.attrs.sales_order.buy_from_account_sales_order_account_slug

        if (!buy_from_account_sales_order_account || !buy_from_account_sales_order_artkey || !buy_from_account_purchase_order_artkey) {
            return from([])
        }

        // Upload attachment to purchase order. First get sales order, create pdf and upload.
        return this.sales_api.get_fast_sales_order_with_items(buy_from_account_sales_order_artkey, true)
            .pipe(
                // generate PDF and upload.
                switchMap((response: GetFastSalesOrderWithItemsResponse) => {
                    const account = $m.accounts.get_account_by_slug(response.account.slug)
                    this.pdf_helper.render_base64_encoded({
                        sales_order: response as SalesOrder,
                        account: PDFHelper._as_account(account) as Account,
                        show_article_code: false,
                        show_country_of_origin: true,
                        show_cbs_code: false,
                        cases_or_bottles: CasesOrBottles.cases,
                        decimal_locale: 'en',
                        show_liters_of_alcohol: false,
                        group_similar_items: true,
                        proforma_or_invoice: ProformaInvoice.INVOICE,
                    })
                    const pdf_file_name = `Invoice - ${response?.invoice_number || ''} - ${account.name() || ''}.pdf`
                    const blob = `data:application/pdf;base64,${this.pdf_helper.pdf_as_base64_encoded()}`
                    return this.attachment_api.upload_attachment({
                        purchase_order_artkey: buy_from_account_purchase_order_artkey,
                        file_name: pdf_file_name,
                        attachment_type: AttachmentType.PURCHASE_ORDER,
                        file: blob,
                    })
                }),
                // Set category on uploaded PDF.
                switchMap((response: GetAttachmentResponse) => this.attachment_api.categorize_attachment({
                    attachment_artkey: response.artkey,
                    attachment_type: AttachmentType.PURCHASE_ORDER,
                    description: 'Invoice buy-from-account',
                    category: 'Supplier invoice',
                    valid_until: null,
                })),
            )
    }

    confirm_pre_advice(vnode: m.Vnode<DeliveryButtonsAttrs>) {
        const destination_is_loendersloot = vnode.attrs.sales_order.destination.artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY
        const origin_is_loendersloot = vnode.attrs.sales_order.origin.name === vnode.attrs.sales_order.destination.name
        const is_exw = vnode.attrs.sales_order.incoterm === 'EXW'
        const is_loendersloot_release = is_exw && origin_is_loendersloot && destination_is_loendersloot

        const api_call = is_loendersloot_release
            ? this.loendersloot_api.confirm_pre_advice_with_release(vnode.attrs.sales_order.artkey)
            : this.loendersloot_api.confirm_pre_advice_with_outbound(vnode.attrs.sales_order.artkey)
        api_call.pipe(
            switchMap(() => this.upload_pdf_to_related_purchase_order(vnode)),
        ).subscribe({
            next: () => {
                if (is_loendersloot_release) {
                    notifier.notify('The release is requested at Loendersloot.', 'success')
                } else {
                    notifier.notify('The outbound is requested at Loendersloot.', 'success')
                }
                vnode.attrs.sales_order.pre_advice_confirmed_on = DateTime.now().toISO()
                this.signal_changed(vnode)
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    view(vnode: m.Vnode<DeliveryButtonsAttrs>): m.Children {

        const any_item_needs_buy_from_account = vnode.attrs.sales_order.sales_order_items.find(
            (soi) => soi.item.account.slug !== vnode.attrs.sales_order.account.slug,
        )
        const sales_order = vnode.attrs.sales_order
        const is_reseller = sales_order.account.slug === AccountSlug.ETR
        const is_exw = is_exw_loendersloot(sales_order)
        const can_be_sent_to_loendersloot = is_origin_loendersloot(sales_order) && [SalesOrderStatus.CONFIRMED, SalesOrderStatus.INVOICED].includes(sales_order.combined_status)
        const is_release = can_be_sent_to_loendersloot && is_exw && !any_item_needs_buy_from_account
        const is_outbound = can_be_sent_to_loendersloot && !is_exw && !any_item_needs_buy_from_account
        const loendersloot_confirmed = vnode.attrs.sales_order.pre_advice_reference || vnode.attrs.sales_order.warehouse_reference
        const is_pre_advice_sent = vnode.attrs.sales_order.sent_pre_advice_to_loendersloot_on
        const is_release_outbound_sent = vnode.attrs.sales_order.sent_to_loendersloot_on
        const has_unstocked_sales_order_items = !this.all_sales_order_items_are_stocked(vnode.attrs.sales_order)

        const is_pre_loading_possible = is_outbound && !loendersloot_confirmed && !has_unstocked_sales_order_items && !is_pre_advice_sent
        const is_release_possible = is_release && !loendersloot_confirmed && !has_unstocked_sales_order_items && !is_pre_advice_sent

        const is_pre_advice_possible = can_be_sent_to_loendersloot && (is_exw || is_reseller)

        const elements: m.Child[] = []

        if (this.confirm_sent_to_loendersloot) {
            elements.push(<SendToLoenderslootModal
                on_close={() => this.close_sent_to_loendersloot_confirmation()}
                sales_order={vnode.attrs.sales_order}
                on_sent_to_loendersloot={() => this.send_to_loendersloot(vnode)}
                loendersloot_flow={this.loendersloot_flow}
            />)
        }

        if (!sales_order.delivery.status &&
            [SalesOrderStatus.CONFIRMED, SalesOrderStatus.INVOICED].includes(sales_order.combined_status)
        ) {
            elements.push(<DefaultButton
                icon_class={'fas fa-truck'}
                onclick={() =>
                    m.route.set(
                        `/sales-orders/manage/${
                            vnode.attrs.sales_order?.artkey || ''
                        }/create-green-shipment`,
                    )
                }
                disabled={is_release}
                title={' Green transport'}
            />)
        }

        if (sales_order.delivery.status === DeliveryStatus.CREATED) {
            elements.push(<DefaultButton
                icon_class={'fas fa-truck'}
                onclick={() => this.confirm_green_transport(vnode)}
                disabled={is_release}
                title={' Confirm Green transport'}
            />)
        }

        if (is_release_possible) {
            elements.push(<DefaultButton
                icon_class={'fas fa-building'}
                onclick={() => {
                    this.loendersloot_flow = LoenderslootFlow.OUTBOUND
                    this.confirm_sent_to_loendersloot = true
                }}
                disabled={
                    !sales_order.destination?.artkey
                }
                title={' Direct release at Loendersloot'}
            />)
        }

        if (is_pre_loading_possible) {
            elements.push(<DefaultButton
                icon_class={'fas fa-car'}
                onclick={() => {
                    this.loendersloot_flow = LoenderslootFlow.OUTBOUND
                    this.confirm_sent_to_loendersloot = true
                }}
                disabled={
                    !sales_order.destination?.artkey
                }
                title={' Pre-loading at Loendersloot'}
            />)
        }

        if (is_pre_advice_possible) {
            elements.push(
                <Tippy
                    content={vnode.attrs.sales_order.pre_advice_failure_reason ? vnode.attrs.sales_order.pre_advice_failure_reason : 'Send pre-advice to loendersloot'}
                >
                    <DefaultButton
                        icon_class={'fas fa-book-open'}
                        onclick={() => {
                            this.loendersloot_flow = LoenderslootFlow.PRE_ADVICE
                            this.confirm_sent_to_loendersloot = true
                        }}
                        disabled={
                            !sales_order.destination?.artkey ||
                            loendersloot_confirmed ||
                            has_unstocked_sales_order_items ||
                            is_release_outbound_sent ||
                            is_pre_advice_sent
                        }
                        title={' Pre-advice at Loendersloot'}
                        additional_class={vnode.attrs.sales_order.pre_advice_failure_reason ? 'btn-danger' : ''}
                    />
                </Tippy>,
            )
        }

        if (
            [SalesOrderStatus.CONFIRMED, SalesOrderStatus.INVOICED].includes(sales_order.combined_status) &&
            sales_order.pre_advice_reference
        ) {
            elements.push(<DefaultButton
                icon_class={'glyphicon glyphicon-remove'}
                onclick={() => {
                    this.cancel_pre_advice(vnode)
                }}
                disabled={
                    !sales_order.destination?.artkey ||
                sales_order.warehouse_reference ||
                sales_order.pre_advice_cancelled_on ||
                sales_order.pre_advice_confirmed_on
                }
                title={' Cancel pre-advice'}
            />)

            elements.push(<DefaultButton
                icon_class={'fas fa-book-open'}
                onclick={() => {
                    const message = is_exw
                        ? 'Loendersloot will release the goods.'
                        : 'Loendersloot will start pre-loading the goods.'
                    show({
                        title: 'Confirm the pre-advice',
                        unique_name: 'confirm-pre-advice',
                        message: `The related buy-from-account Sales order and Purchase order will be invoiced/stocked and ${message}`,
                        onconfirm: () => {
                            this.confirm_pre_advice(vnode)
                        },
                    })
                }}
                disabled={
                    !sales_order.destination?.artkey ||
                sales_order.warehouse_reference ||
                sales_order.pre_advice_cancelled_on ||
                sales_order.pre_advice_confirmed_on
                }
                title={is_exw ? ' Release pre-advice' : ' Pre-loading pre-advice'}
            />)
        }

        return elements
    }
}
