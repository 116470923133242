/** llm:tested */
import m from 'mithril'
import {map, filter} from 'prelude-ls'
import {Country} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {button_with_icon, icon_button} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import api from '@/api'
import confirmation from '@/components/confirmation'
import inputs from '@/components/inputs'
import {EmailPlaceholder} from '@/models/data'
import {$m} from '@/app'

interface Translation {
    artkey: string
    language_code: string
    translation: string
}

export class EmailPlaceholdersUpsert {
    artkey: string
    create: any
    email_placeholder: any
    can_show_delete_translation_button: any
    translations: any
    required_langs: string[]
    lang_code_to_flag: Record<string, string>

    constructor() {
        this.artkey = m.route.param('artkey')
        this.create = window.prop(false)
        this.email_placeholder = window.prop(new EmailPlaceholder())
        this.can_show_delete_translation_button = window.prop(false)
        this.translations = window.prop(null)

        this.required_langs = ['en', 'de', 'fr', 'nl']
        this.lang_code_to_flag = {
            en: 'gb',
            de: 'de',
            nl: 'nl',
            fr: 'fr',
        }

        if (this.artkey) {
            api.callAndThen('email_i18n_placeholders.get_email_placeholder', {
                artkey: this.artkey,
            }, {
                success: (result: any) => {
                    this.email_placeholder().artkey(result.result.artkey)
                    this.email_placeholder().description(result.result.description)
                    this.email_placeholder().placeholder(result.result.placeholder)
                    this.email_placeholder().translations(result.result.translations)
                },
            })
        } else {
            this.create(true)
        }

        this.translations = new Collection({
            api_function_name: 'email_i18n_placeholders.get_translations_for_email_placeholder',
            query_limit: 25,
            additional_params: window.prop({
                email_placeholder_artkey: this.artkey,
            }),
        })

        $m.common.observable.subscribe('collection.email_i18n_placeholders.get_translations_for_email_placeholder.after-call', this, () => {
            this.can_show_delete_translation_button(this.has_translations_for_all_languages())
        })
    }

    oncreate() {
        this.translations.init()
    }

    has_translations_for_all_languages() {
        const mapped_lang_codes = map((translation: Translation) => translation.language_code, this.email_placeholder().translations())
        return new Set(mapped_lang_codes).size >= this.required_langs.length
    }

    add_translation(e: Event) {
        e.preventDefault()
        this.email_placeholder().translations().push({
            language_code: this.required_langs[0],
            translation: '',
        })
        api.callAndThen('email_i18n_placeholders.upsert_email_placeholder_with_translations', {
            artkey: this.email_placeholder().artkey(),
            description: this.email_placeholder().description(),
            placeholder: this.email_placeholder().placeholder(),
            translations: this.email_placeholder().translations(),
        }, {
            success: (result: any) => {
                notifier.notify('An empty translation was saved', 'success')
                this.email_placeholder().artkey(result.result.artkey)
                this.email_placeholder().description(result.result.description)
                this.email_placeholder().placeholder(result.result.placeholder)
                this.email_placeholder().translations(result.result.translations)
                this.translations.requery()
            },
        })
    }

    delete_email_placeholder() {
        confirmation.show({
            title: 'Delete email placeholder',
            message: 'Are you sure you want to delete this email placeholder?',
            unique_name: 'email_placeholder_deactivate_confirm',
            onconfirm: () => {
                api.callAndThen('email_i18n_placeholders.delete_email_placeholder', {
                    artkey: this.artkey,
                }, {
                    success: () => {
                        notifier.notify('Email placeholder deleted', 'success')
                        m.route.set('/data/email-placeholders')
                    },
                })
            },
        })
    }

    delete_translation(artkey: string) {
        this.email_placeholder().translations(
            filter((translation: Translation) => translation.artkey !== artkey,
                this.email_placeholder().translations()),
        )
        api.callAndThen('email_i18n_placeholders.upsert_email_placeholder_with_translations', {
            artkey: this.email_placeholder().artkey(),
            description: this.email_placeholder().description(),
            placeholder: this.email_placeholder().placeholder(),
            translations: this.email_placeholder().translations(),
        }, {
            success: (result: any) => {
                notifier.notify('Translation has been successfully deleted', 'success')
                this.email_placeholder().artkey(result.result.artkey)
                this.email_placeholder().description(result.result.description)
                this.email_placeholder().placeholder(result.result.placeholder)
                this.email_placeholder().translations(result.result.translations)
                this.translations.requery()
            },
        })
    }

    save_email_placeholder(e: Event) {
        e.preventDefault()
        if (!this.create() && !this.has_translations_for_all_languages()) {
            notifier.notify('At least one translation is required for each language', 'danger')
            return
        }

        api.callAndThen('email_i18n_placeholders.upsert_email_placeholder_with_translations', {
            artkey: this.email_placeholder().artkey(),
            description: this.email_placeholder().description(),
            placeholder: this.email_placeholder().placeholder(),
            translations: this.email_placeholder().translations(),
        }, {
            success: (result: any) => {
                notifier.notify('Email placeholder updated', 'success')
                if (this.create()) {
                    m.route.set(`/data/email-placeholders/${result.result.artkey}/edit`, {key: 'edit'})
                } else {
                    m.route.set('/data/email-placeholders')
                }
            },
        })
    }

    view() {
        return <div class="c-email-placeholder-edit view">
            <div class="btn-toolbar">
                <button class="btn btn-default" type="button" onclick={() => m.route.set('/data/email-placeholders')}>
                    <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                </button>
                {!this.create() &&
                    <button class="btn btn-danger pull-right"
                        type="button"
                        onclick={() => this.delete_email_placeholder()}>
                        <span class="glyphicon glyphicon-trash"></span> Deactivate
                    </button>
                }
            </div>

            <form onsubmit={(e: Event) => this.save_email_placeholder(e)}>
                <div class="fieldset">
                    <div class="field">
                        <label>Placeholder</label>
                        {inputs.text(this.email_placeholder().placeholder, {required: true})}
                    </div>
                    <div class="field">
                        <label for="description">Description</label>
                        {inputs.text(this.email_placeholder().description, {required: true, id: 'description'})}
                    </div>

                    <div class="field">
                        {button_with_icon(this.create() ? 'Create' : 'Update', 'ok', {
                            class: 'btn-success',
                        })}
                        {!this.create() && button_with_icon('Translation', 'plus', {
                            class: 'btn-default',
                            onclick: (e: Event) => this.add_translation(e),
                        })}
                    </div>
                </div>

                {!this.create() &&
                    <CollectionTable
                        collection={this.translations}
                        options={{
                            sticky_header: true,
                            with_buttons: true,
                            unique_name: 'email_placeholder_translations',
                        }}
                        row_model={(row: any) => ({
                            ...row,
                            new_translation: window.prop(row.translation),
                            new_language_code: window.prop(row.language_code),
                        })}
                        columns={[
                            {
                                header: 'Flag',
                                name: 'Flag',
                                width: 0.5,
                                function: (record: any) =>
                                    <Country country_code={this.lang_code_to_flag[record.new_language_code()]} />,
                            },
                            {
                                name: 'Language code',
                                width: 0.5,
                                header: 'Language Code',
                                function: (record: any) =>
                                    inputs.select(record.new_language_code, this.required_langs, {
                                        after_update: () => {
                                            const translation_to_update = filter(
                                                (translation: Translation) => translation.artkey === record.artkey,
                                                this.email_placeholder().translations(),
                                            )
                                            translation_to_update[0].language_code = record.new_language_code()
                                            record.language_code = record.new_language_code()
                                        },
                                    }),
                            },
                            {
                                name: 'Translation',
                                header: 'Translation',
                                function: (record: any) =>
                                    inputs.textarea(record.new_translation, {
                                        required: true,
                                        id: 'translation',
                                        oninput: (ev: Event) => {
                                            const translation_to_update = filter(
                                                (translation: Translation) => translation.artkey === record.artkey,
                                                this.email_placeholder().translations(),
                                            )
                                            translation_to_update[0].translation = (ev.target as HTMLTextAreaElement).value
                                        },
                                    }),
                            },
                            {
                                width: 1,
                                name: 'Actions',
                                header: '',
                                function: (record: any) =>
                                    <div class="btn-toolbar no-click">
                                        <div class="btn-group">
                                            {!this.create() && this.can_show_delete_translation_button() && (() => {
                                                const alternatives = this.translations.items().filter(
                                                    (translation: Translation) => translation.language_code === record.language_code,
                                                )
                                                if (alternatives.length > 1) {
                                                    return icon_button('remove', {
                                                        class: 'btn-danger no-click',
                                                        title: 'Remove this translation',
                                                        onclick: (e: Event) => {
                                                            e.preventDefault()
                                                            this.delete_translation(record.artkey)
                                                        },
                                                    })
                                                }
                                            })()}
                                        </div>
                                    </div>,
                            },
                        ]} />
                }
            </form>
        </div>
    }
}
