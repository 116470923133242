import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {BottlePurchaseOrders} from './bottle_purchase_orders'
import {BottleSalesOrders} from './bottle_sales_orders'
import {BottleMarket} from './bottle_market'
import {BottleStock} from './bottle_stock'

import {SupplierPriceListItem} from '@/factserver_api/marketanalysis_api'

export interface CaseInfoPanelAttrs {
    // The only mandatory field.
    bottle_artkey: number
    specs?: {
        product_name?: string
        volume?: number
        alcohol_percentage?: number
        refill_status?: string
    }
    supplier_name?: string[]
    current_supplier_price_list_artkey?: number

    // If true: Return both ref and nonref. If false: Only exact matches.
    ignore_ref?: boolean

    // The below narrows down the result.
    case_artkey?: number
    current_supplier_artkey?: number
    // The client we are looking at.
    current_client_artkey?: number
    case_customs_status?: string
    item_best_before_date?: string
    item_tags_sorted_artkeys?: string

    // Are we on the op=op page
    up_is_up?: boolean

    add_to_order?: (spli: SupplierPriceListItem) => void
    add_custom_offer_item_to_purchase_order?: (custom_offer_item: unknown) => void
    custom_offer_item: () => any
    disable_add_to_po?: boolean
    exclude_competitors?: boolean
    exclude_case_artkey_from_market?: boolean
    serialized_item_tags?: string

    preferred_competitors?: string
    supplier_price_list_artkey?: number

    // Should values include excise.
    with_excise?: boolean
    // Show prices / bottle if true, otherwise not.
    bottle_mode?: boolean
    with_pagination?: boolean
}

/**
 * This component renders background information for a case.
 * It can be used in any place where an item that is directly related to a case
 * appears, to show immediately relevant information regarding the case.
 */
export class CaseInfoPanel extends MithrilTsxComponent<CaseInfoPanelAttrs> {
    view(vnode: m.Vnode<CaseInfoPanelAttrs>): m.Children {
        return [
            <BottleMarket {...vnode.attrs} />,
            <BottleStock {...vnode.attrs} />,
            <div className="columns">
                <div className="column">
                    <BottlePurchaseOrders {...vnode.attrs} />
                </div>
                <div className="column">
                    <BottleSalesOrders {...vnode.attrs} />
                </div>
            </div>,
        ]
    }
}
