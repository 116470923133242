let api_host, api_host_new, hostname, product_photo_host, sentry_dsn, frontend_host,
    a2bc_portal_base_url, portal_base_url, sentry_dist

api_host = '/api/v1'
api_host_new = '/api/v2'

if (process.env.NODE_ENV === 'development') {
    hostname = window.location.hostname

    // TODO: This can be merged with frontend_host.
    a2bc_portal_base_url = 'http://portal.localhost'
    frontend_host = 'http://discover.localhost'
    portal_base_url = 'http://portal.localhost'

    product_photo_host = 'https://photos.staging.v5.springtimegroup.nl'
    sentry_dsn = process.env.MSI_TEST_SENTRY ? process.env.MSI_SENTRY_DSN : ''
    sentry_dist = `discover-msp-${process.env.NODE_ENV}`
} else if (process.env.NODE_ENV === 'staging') {
    a2bc_portal_base_url = 'https://portal-a2bc.staging.v5.springtimegroup.nl'
    hostname = 'staging.v5.springtimegroup.nl'

    portal_base_url = `https://portal.${hostname}`
    frontend_host = `https://${window.location.hostname}`
    product_photo_host = `https://photos.${hostname}`
    sentry_dsn = process.env.MSI_SENTRY_DSN
    sentry_dist = `discover-msp-${process.env.NODE_ENV}` // portal-a2bc-staging or discover-msi-production
} else if (process.env.NODE_ENV === 'production') {
    a2bc_portal_base_url = 'https://portal.a2bc.com'
    hostname = 'movingspirits.nl'

    portal_base_url = `https://portal.${hostname}`
    frontend_host = `https://${window.location.hostname}`
    product_photo_host = `https://photos.${hostname}`
    sentry_dsn = process.env.MSI_SENTRY_DSN
    sentry_dist = `discover-msp-${process.env.NODE_ENV}` // portal-a2bc-staging or discover-msi-production
}

export const config = {
    a2bc_portal_base_url,
    api_host,
    api_host_new,
    api_user: 'MovingSpirits',
    api_password: 'aj9ad7DDYPasdsssad823gjafjds0DUDjd9Dkddik',
    env: process.env.NODE_ENV,
    frontend_host,
    portal_base_url,
    product_photo_host,
    slugs_with_green_api: ['msi', 'a2bc'],
    sentry_dsn,
    sentry_dist,
}
