import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Observable} from 'rxjs'
import {FieldSelect} from '@bitstillery/common/components'
import {FieldSelectAttrs} from '@bitstillery/common/types/field'

import {GetIncotermsResponse} from '@/factserver_api/fact2server_api'

interface IncotermsDropDownAttrs extends FieldSelectAttrs {
    get_all_for_drop_down_response$: Observable<GetIncotermsResponse[]>
}

export class IncotermsDropDown extends MithrilTsxComponent<IncotermsDropDownAttrs> {
    options: GetIncotermsResponse[] = []

    oncreate(vnode: m.Vnode<IncotermsDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe((options) => {
            this.options = options
        })
    }

    view(vnode: m.Vnode<IncotermsDropDownAttrs>): m.Children {
        return <FieldSelect
            disabled={vnode.attrs.disabled}
            help={vnode.attrs.help}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={this.options.map((incoterm) => ({
                value: `${incoterm.code}`,
                label: `${incoterm.code} - ${incoterm.description}`,
            }))}
            placeholder={vnode.attrs.placeholder}
            validation={vnode.attrs.validation}
        />
    }
}
