import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_money_with_symbol} from '@bitstillery/common/ts_utils'
import {classes} from '@bitstillery/common/lib/utils'
import {logger} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'

import {$s} from '@/app'
import {FigureWidget} from '@/components/box'
import {DashboardApi, PurchaseFiguresResponse} from '@/factserver_api/dashboard_api'
import {UserStatsModel} from '@/dashboard/figures_dashboard'

export class MinePurchaseWidget extends MithrilTsxComponent<UserStatsModel> {
    is_loading = false
    empty_sales_figure = {
        count: 0,
        euro_was_bought_for: 0,
        number_of_cases: 0,
    }

    watchers = []

    purchase_figures: PurchaseFiguresResponse = {
        confirmed: this.empty_sales_figure,
        saved: this.empty_sales_figure,
        overdue: this.empty_sales_figure,
        stock: {
            number_of_cases_available: 0,
            number_of_cases_in_stock: 0,
            number_of_cases_in_sales: 0,
            number_of_cases_in_purchase: 0,
            number_of_cases_in_transit: 0,
            value_available: '0',
            value_in_stock: '0',
            value_in_sales: '0',
            value_in_purchase: '0',
            value_in_transit: '0',
        },
        open_tbos: 0,
        confirmed_tbos: 0,
        to_confirm_tbos: 0,
        ordered_tbos: 0,
    }

    dashboard_api = new DashboardApi()

    oninit(vnode: m.Vnode<UserStatsModel>) {
        this.watchers.push(watch(vnode.attrs.model, 'user_artkey', (user_artkey) => {
            this.fetch_data(user_artkey)
        }))
        this.fetch_data(vnode.attrs.model.user_artkey)
    }

    fetch_data(user_artkey: number | null) {
        if (this.is_loading || !user_artkey) return
        logger.info(`[MinePurchaseWidget] fetch_sales_statistics for user: ${user_artkey}`)

        this.is_loading = true

        this.dashboard_api
            .purchase_figures({
                sales_manager_artkey: user_artkey,
            })
            .subscribe({
                next: (purchase_figures: PurchaseFiguresResponse) => {
                    this.purchase_figures = purchase_figures
                },
                complete: () => {
                    this.is_loading = false
                    m.redraw()
                },
            })

        m.redraw()
    }

    view(vnode: m.Vnode<UserStatsModel>) {
        return (
            <div className={classes('c-widget-individual-purchase dashboard-widget', {
                loading: this.is_loading,
            })}>
                <div className="widget-title"><span className="text">Purchases this month</span></div>

                <div className="widget-body">
                    <FigureWidget
                        color={'purchase'}
                        icon={'fas fa-exclamation'}
                        title={'overdue'}
                        link={`/purchase-orders/manage?purchase_order_status=Overdue&user_artkey=${vnode.attrs.model.user_artkey || ''}`}
                        figure={format_money_with_symbol(this.purchase_figures.overdue.euro_was_bought_for, 'EUR', $s.identity.user.decimal_locale)}
                        sub_title={`In ${
                            this.purchase_figures.overdue.count
                        } orders and ${this.purchase_figures.overdue.number_of_cases.toLocaleString()} cases`}
                    />

                    <FigureWidget
                        color={'purchase'}
                        icon={'glyphicon glyphicon-shopping-cart'}
                        title={'saved'}
                        link={`/purchase-orders/manage?purchase_order_status=Saved&user_artkey=${vnode.attrs.model.user_artkey || ''}`}
                        figure={format_money_with_symbol(this.purchase_figures.saved.euro_was_bought_for, 'EUR', $s.identity.user.decimal_locale)}
                        sub_title={`In ${
                            this.purchase_figures.saved.count
                        } orders and ${this.purchase_figures.saved.number_of_cases.toLocaleString()} cases`}
                    />

                    <FigureWidget
                        color={'purchase'}
                        icon={'glyphicon glyphicon-road'}
                        title={'confirmed'}
                        link={`/purchase-orders/manage?purchase_order_status=Confirmed&user_artkey=${vnode.attrs.model.user_artkey || ''}`}
                        figure={format_money_with_symbol(this.purchase_figures.confirmed.euro_was_bought_for, 'EUR', $s.identity.user.decimal_locale)}
                        sub_title={`In ${
                            this.purchase_figures.confirmed.count
                        } orders and ${this.purchase_figures.confirmed.number_of_cases.toLocaleString()} cases`}
                    />

                    <FigureWidget
                        color={'purchase'}
                        icon={'glyphicon glyphicon-home'}
                        title={'in stock'}
                        link={'/stock/manage'}
                        figure={format_money_with_symbol(+this.purchase_figures.stock.value_in_stock, 'EUR', $s.identity.user.decimal_locale)}
                        sub_title={
                            <span>
                                In {this.purchase_figures.stock.number_of_cases_in_stock?.toLocaleString()} cases.
                            </span>
                        }
                    />
                </div>
            </div>
        )
    }
}
