/** llm:tested */
import m from 'mithril'
import {AccountSlug} from '@bitstillery/common/account/account'
import {config} from '@bitstillery/common/app'

import {maybeMap, formatDateTime} from '@/_utils'
import {icon_button, button_with_icon} from '@/components/_buttons'
import {CollectionTable} from '@/components/collection_table'
import {Collection} from '@/components/collection/collection'
import {contact_person_link, relation_link} from '@/components/entity_links'
import SearchInput from '@/components/collection/search_input'

interface PortalUser {
    artkey: string
    name: string
    contact_person: {
        name: string
        supplier: any
    }
    last_seen_timestamp: string
    number_of_sessions: number
    invite_code: string
}

export class PortalUserList {
    portal_users: any
    search_input_ctrl: any

    constructor() {
        this.portal_users = new Collection({
            api_function_name: 'portal.user_management.get_all_users',
            query_limit: 25,
            default_sort_by: 'last_activity_timestamp',
            default_sort_order: 'desc',
            filter_serverside: true,
            paged: true,
            additional_params: () => ({}),
        })

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.portal_users,
            placeholder: 'Search for portal user...',
        })
    }

    oncreate() {
        this.portal_users.init()
    }

    onremove() {
        this.search_input_ctrl.onremove()
    }

    view() {
        return (
            <div class="c-portal-user-list view">
                <div class="btn-toolbar">
                    {button_with_icon('Create Portal User', 'plus', {
                        class: 'btn-default',
                        onclick: () => m.route.set('/portal/portal-users/create'),
                    })}
                </div>

                <div class="c-filter-group">
                    {SearchInput.view(this.search_input_ctrl)}
                </div>

                {this.portal_users.pager()}

                <CollectionTable
                    collection={this.portal_users}
                    options={{
                        search_table_style: true,
                        sticky_header: true,
                        with_buttons: true,
                        autoscale: true,
                        unique_name: 'portal_users',
                        onclick: (record: PortalUser) => () =>
                            m.route.set(`/portal/portal-users/${record.artkey}/activity`),
                    }}
                    columns={[
                        {
                            width: 4,
                            name: 'Username',
                            field: 'name',
                            sort: true,
                        },
                        {
                            width: 12,
                            name: 'Contact person',
                            field: 'contact_person.name',
                            function: (record: PortalUser) =>
                                contact_person_link(record.contact_person, {
                                    onclick: (event: Event) => event.stopPropagation(),
                                }),
                        },
                        {
                            width: 8,
                            name: 'Relation',
                            field: 'contact_person.supplier',
                            transform: relation_link,
                        },
                        {
                            width: 5,
                            name: 'Last seen',
                            sort: true,
                            descending: true,
                            field: 'last_seen_timestamp',
                            transform: maybeMap(formatDateTime),
                        },
                        {
                            width: 5,
                            name: 'Times seen',
                            sort: true,
                            descending: true,
                            field: 'number_of_sessions',
                        },
                        {
                            width: 8,
                            name: 'Invite URL',
                            field: 'invite_code',
                            classes: ['no-click'],
                            function: (user: PortalUser) => {
                                if (!user.invite_code) {
                                    return ''
                                }
                                const account_slug = user.contact_person.supplier.sales_account.slug
                                const portal_base_url = account_slug === AccountSlug.ETR
                                    ? config.a2bc_portal_base_url
                                    : config.portal_base_url

                                return (
                                    <div class="btn-toolbar no-click">
                                        {button_with_icon(`${portal_base_url}/#!/invite/${user.invite_code}`, 'fa-copy', {
                                            onclick: () =>
                                                navigator.clipboard
                                                    .writeText(`${portal_base_url}/#!/invite/${user.invite_code}`)
                                                    .then(() => alert('Copied link to clipboard.'))
                                                    // eslint-disable-next-line no-console
                                                    .catch(() => console.log('Cannot copy')),
                                        })}
                                    </div>
                                )
                            },
                        },
                        {
                            width: 5,
                            header: '',
                            name: 'Actions',
                            function: (record: PortalUser) => (
                                <div class="btn-toolbar no-click">
                                    <div class="btn-group">
                                        {icon_button('pencil', {
                                            class: 'btn-default no-click',
                                            onclick: () =>
                                                m.route.set(`/portal/portal-users/${record.artkey}/edit`),
                                        })}
                                        {icon_button('fa-snowboarding', {
                                            class: 'btn-default no-click',
                                            onclick: () =>
                                                m.route.set(`/portal/portal-users/${record.artkey}/activity`),
                                        })}
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        )
    }
}
