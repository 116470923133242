import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import m from 'mithril'
import {to_specs} from '@bitstillery/common/models/item'
import {Country} from '@bitstillery/common/components/ui/country/country'
import {FieldMoney, FieldText, FieldTextArea} from '@bitstillery/common/components'

import {NumberInput} from '@/components/input_numbers'
import {
    DamagesDropDown,
    CustomsStatusDropDown,
    GeneralDropDown,
    GiftBoxTypeDropDown, PackagingLabelDropDown,
    PackSizeDropDown,
    TaxLabelDropDown,
} from '@/components/case_inputs'
import {CountriesSelect, InputDate} from '@/components/html_components'
import {CancelButton, SaveButton} from '@/components/buttons'
import {TagModel} from '@/factserver_api/item_tags'

interface EditPurchaseOrderItemAttrs {
    purchase_order_item: any
    onsave: (row: any) => unknown
    oncancel: (row: any) => unknown
}

export class EditPurchaseOrderItem extends MithrilTsxComponent<EditPurchaseOrderItemAttrs> {

    tag_model:any

    async oninit(vnode) {
        this.tag_model = new TagModel()
        await this.tag_model.load_data()
        const tag_ids = vnode.attrs.purchase_order_item.serialized_item_tags.split(',').filter((i) => i).map((i) => Number(i)) as any
        this.tag_model.set_selection(tag_ids)
    }

    view(vnode: m.Vnode<EditPurchaseOrderItemAttrs>): m.Children {
        const purchase_order_item = vnode.attrs.purchase_order_item
        if (!this.tag_model.data) return null
        return (<div className={'c-purchase-order-item-edit'}>
            <h3>Edit {purchase_order_item.product_name} {to_specs(purchase_order_item, 'en')}</h3>
            <form className={'flex-form'}>
                <div className={'fieldset-group'}>
                    <div className={'fieldset'}>
                        <div className={'fieldset-label'}>Purchase order item</div>
                        <NumberInput
                            label={'Bottles per case'}
                            value={purchase_order_item.number_of_bottles}
                            oninput={(val) => purchase_order_item.number_of_bottles = val}
                        />
                        <NumberInput
                            label={'Number of cases'}
                            value={purchase_order_item.number_of_cases}
                            oninput={(val) => purchase_order_item.number_of_cases = val}
                        />
                        <CustomsStatusDropDown
                            label="Customs status"
                            model={[purchase_order_item, 'customs_status']}
                        />
                        <GiftBoxTypeDropDown model={[purchase_order_item, 'gift_box_type']} />
                        <FieldMoney
                            currency={[purchase_order_item, 'was_bought_in']}
                            label={'Net price per case'}
                            model={[purchase_order_item, 'was_bought_for']}
                        />
                        <FieldMoney
                            currency={[purchase_order_item, 'was_bought_in']}
                            label={'Suggested price per case'}
                            model={[purchase_order_item, 'suggested_price_per_case']}
                            placeholder={'Suggested price per case'}
                        />
                        <FieldTextArea
                            label={'Remark'}
                            model={[purchase_order_item, 'remark']}
                        />
                        <FieldText
                            label={'Bottle GTIN'}
                            model={[purchase_order_item, 'bottle_gtin_code']}
                        />
                        <FieldText
                            label="Case GTIN"
                            model={[purchase_order_item, 'case_gtin_code']}
                        />
                    </div>
                </div>
                <div className={'fieldset-group'}>
                    <div className={'fieldset'}>
                        <div className={'fieldset-label'}>Tags</div>
                        <TaxLabelDropDown
                            label="Tax label"
                            model={[purchase_order_item, 'tax_label']}
                        />

                        {/* Connect tag_model to purchase_order_item.serialized_item_tags */}
                        <DamagesDropDown
                            model={this.tag_model.data['Damages'].selection}
                            options={this.tag_model.data['Damages'].options}
                        />
                        <GeneralDropDown
                            model={this.tag_model.data['General'].selection}
                            options={this.tag_model.data['General'].options}
                        />
                        <PackSizeDropDown
                            model={[this.tag_model.data['Pack Size'], 'selection']}
                            options={this.tag_model.data['Pack Size'].options}
                        />
                        <PackagingLabelDropDown
                            model={[this.tag_model.data['Packaging'], 'selection']}
                            options={this.tag_model.data['Packaging'].options}
                        />
                        <CountriesSelect
                            label={
                                <div>
                                    {purchase_order_item.country_of_origin &&
                                        <Country country_code={purchase_order_item.country_of_origin} size={'s'}>Country of origin</Country>}
                                    {!purchase_order_item.country_of_origin && 'Country of origin'}
                                </div>}
                            model={[purchase_order_item, 'country_of_origin']}
                        />
                        <InputDate
                            label="Best Before Date"
                            value={purchase_order_item.best_before_date}
                            onchange={(value: DateTime) => {
                                purchase_order_item.best_before_date = value ? value.toISODate() : undefined
                            }}
                        />
                        <NumberInput
                            label={'Cases per pallet'}
                            value={purchase_order_item.cases_per_pallet}
                            oninput={(value: number) => purchase_order_item.cases_per_pallet = value}
                        />
                        <NumberInput
                            label={'Cases per pallet layer'}
                            value={purchase_order_item.cases_per_pallet_layer}
                            oninput={(value: number) => purchase_order_item.cases_per_pallet_layer = value}
                        />
                    </div>
                </div>
                <div className={'btn-group'}>
                    <SaveButton title={'Save'} onclick={() => {
                        // Attach the tag model to the purchase order item, so we can serialize the tags
                        // in the parent component (PurchaseOrderItemsDetail).
                        purchase_order_item.tag_model = this.tag_model
                        vnode.attrs.onsave(vnode.attrs.purchase_order_item)
                    }}/>
                    <CancelButton title={'Cancel'} onclick={() => vnode.attrs.oncancel(vnode.attrs.purchase_order_item)}/>
                </div>
            </form>
        </div>)
    }
}
