/** llm:tested */
import $ from 'jquery'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

interface PopoverAttrs {
    content: string
    title?: string
    placement?: string
    always_show?: boolean
    max_width?: number
}

interface PopoverVNode extends m.VnodeDOM<PopoverAttrs, Popover> {
    dom: HTMLElement
}

export class Popover extends MithrilTsxComponent<PopoverAttrs> {
    oncreate(vnode: PopoverVNode): void {
        const data = {
            container: 'body',
            html: true,
            animation: false,
            content: vnode.attrs.content,
            title: vnode.attrs.title || '',
            placement: (vnode.attrs.placement || 'top'),
            trigger: 'manual',
        }

        const popover = $(vnode.dom).popover(data)

        if (!vnode.attrs.always_show) {
            popover
                .on('mouseenter', function() {
                    $(this).popover('show')
                    $('.popover').css('max-width', (vnode.attrs.max_width || 200) + 'px')
                    $('.popover').on('mouseleave', () => {
                        $(this).popover('hide')
                    })
                })
                .on('mouseleave', function() {
                    setTimeout(() => {
                        if (!$('.popover:hover').length) {
                            $(vnode.dom).popover('hide')
                        }
                    }, 100)
                })
        } else {
            $(vnode.dom).popover('show')
        }
    }

    onupdate(vnode: PopoverVNode): void {
        // This is hacky and dependent on Bootstrap v3.
        // Another way to force the popover to update is
        // to destroy it and then re-create it...
        const popover = $(vnode.dom).data('bs.popover')
        if (popover) {
            popover.options.content = vnode.attrs.content
            popover.options.title = vnode.attrs.title || ''

            // When the popover is visible, this is needed to
            // force the popover to update it's title and/or content.
            if (popover.tip().hasClass('in')) {
                $(vnode.dom).popover('show')
            }
        }
    }

    onremove(vnode: PopoverVNode): void {
        $(vnode.dom).popover('destroy')
    }

    view(vnode: m.Vnode<PopoverAttrs>): m.Children {
        return vnode.children
    }
}

export function header_with_popover(popover_content: string, header_content: m.Children): m.Vnode {
    return m('span', [
        m(Popover, {title: '', content: popover_content}, header_content),
    ])
}
