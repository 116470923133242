/** llm:tested */
import m from 'mithril'
import {map, compact} from 'prelude-ls'

import {accountIcon} from '@/accounts'
import {matchTermIn, maybeMap, formatDateTime} from '@/_utils'
import {icon_link, link} from '@/components/link'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import {CollectionTable} from '@/components/collection_table'

interface PortalUser {
    artkey: string
    name: string
    contact_person: {
        artkey: string
        name: string
        supplier: {
            artkey: string
            name: string
            sales_account: any
        }
    }
}

interface SearchLog {
    portal_user: PortalUser
    timestamp: string
    search_terms: string
    number_of_results: number
}

export class PortalSearchLog {
    entities: Collection
    search_input_ctrl: any

    constructor() {
        this.entities = new Collection({
            api_function_name: 'portal.search.get_logs',
            filter_function: this.is_match,
            query_limit: 50,
            default_sort_by: 'timestamp',
            default_sort_order: 'desc',
        })

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.entities,
            placeholder: 'Search for portal user...',
        })
    }

    oncreate() {
        this.entities.init()
    }

    is_match(log: SearchLog, term: string) {
        return matchTermIn(term, map((x: string) => x.toLowerCase(), compact([
            log.portal_user.name,
            log.portal_user.contact_person.name,
            log.portal_user.contact_person.supplier.name,
            log.search_terms,
        ])))
    }

    onremove() {
        this.search_input_ctrl.onremove()
    }

    terms_to_comma_separated(record: SearchLog) {
        return JSON.parse(record.search_terms).join(',')
    }

    view() {
        return <div class="c-portal-search-log view">
            <div class="c-filter-group">
                {SearchInput.view(this.search_input_ctrl)}
            </div>
            {m(CollectionTable, {
                collection: this.entities,
                options: {
                    sticky_header: true,
                    autoscale: true,
                    unique_name: 'portal_search_log',
                },
                columns: [
                    {
                        width: 4,
                        name: 'Username',
                        field: 'portal_user',
                        sort: true,
                        transform: (portal_user: PortalUser) =>
                            link(`/portal/portal-users/${portal_user.artkey}/edit`, portal_user.name, {class: 'no-click'}),
                    },
                    {
                        width: 12,
                        name: 'Contact person',
                        sort: false,
                        field: 'portal_user.contact_person',
                        transform: (contact_person: PortalUser['contact_person']) =>
                            link(`/crm/relations/${contact_person.supplier.artkey}/contact-persons/${contact_person.artkey}/edit`,
                                contact_person.name, {class: 'no-click'}),
                    },
                    {
                        width: 8,
                        name: 'Relation',
                        sort: false,
                        field: 'portal_user.contact_person.supplier',
                        transform: (supplier: PortalUser['contact_person']['supplier']) => [
                            <span class="mr-05">{accountIcon(supplier.sales_account)}</span>,
                            link(`/crm/relations/${supplier.artkey}/edit`, supplier.name, {class: 'no-click'}),
                        ],
                    },
                    {
                        width: 5,
                        name: 'Search moment',
                        sort: true,
                        field: 'timestamp',
                        transform: maybeMap(formatDateTime),
                    },
                    {
                        width: 12,
                        name: 'Search',
                        sort: false,
                        field: 'search_terms',
                        function: this.terms_to_comma_separated,
                    },
                    {
                        width: 4,
                        name: 'Links',
                        sort: false,
                        field: 'search_terms',
                        function: (record: SearchLog) => {
                            const final_terms = this.terms_to_comma_separated(record)
                            return <span>
                                {icon_link(`/offer/relation-dashboard?r=${record.portal_user.contact_person.supplier.artkey}&q=${final_terms}`, 'piggy-bank')}
                                {' '}
                                {icon_link(`/market/discover?q=${final_terms}`, 'search')}
                            </span>
                        },
                    },
                    {
                        width: 6,
                        name: 'Number of search results',
                        sort: true,
                        field: 'number_of_results',
                    },
                ],
            })}
        </div>
    }
}
