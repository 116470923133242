/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericEdit} from '@/data/generic/generic_edit'
import {Field} from '@/data/generic/generic_field'
import {CommentTemplate} from '@/models/data'

export class CommentTemplatesUpsert extends MithrilTsxComponent<any> {
    view(_vnode:m.Vnode<any>) {
        return <GenericEdit
            readable_entity_name="Comment template"
            base_url="/data/commenttemplates"
            query_api_call="comment_templates.get_comment_template"
            update_or_create_api_call="comment_templates.update_or_create_comment_template"
            fields={[
                new Field('Title', 'title', 'input', 'text'),
                new Field('Body', 'body', 'textarea', 'text', {rows: 4, required: true}),
            ]}
            create_entity_func={(json: any) => new CommentTemplate(json)}
            broadcast_on_update="commenttemplates_updated"
        />
    }
}
