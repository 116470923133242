/** llm:tested */
import m from 'mithril'
import {Amount} from '@bitstillery/common/components'

import {
    formatDate,
    maybeMap,
    pluralize,
} from '@/_utils'
import {select} from '@/components/inputs'
import {button_with_icon} from '@/components/_buttons'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import {Modal} from '@/components/modal/modal'
import {AddToOrder} from '@/purchase_orders/components/add_to_order'
import {accountIcon} from '@/accounts'
import {TboStatus} from '@/sales_orders/models'
import {SearchBar} from '@/components/collection/search_bar'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {SupplierPriceListItem} from '@/models/suppliers'
import {$m} from '@/app'

const TBO_STATUS_SELECTION = [
    [TboStatus.OPEN, 'Show open TBO items'],
    [TboStatus.ORDERED, 'Show ordered TBO items'],
    [TboStatus.CONFIRMED, 'Show confirmed TBO items'],
    [TboStatus.IN_PROGRESS, 'Show all in progress TBO items'],
    [TboStatus.ALL, 'Show all TBO items'],
]

export default class PurchaseOrderTBO {
    tbo_items: any
    search_status: any
    selected: any
    only_mine: any
    search_bar_controller: any
    product_management_api: ProductManagementApi
    search_input_ctrl: any

    constructor() {
        this.tbo_items = new Collection({
            api_function_name: 'sales.tbo.get_tbo_items',
            additional_params: () => this.additional_params(),
            query_limit: 25,
            sort_order: [],
            default_sort_by: 'sales_order.date',
            default_sort_order: 'asc',
            filter_serverside: true,
            dont_reuse: true,
        })

        this.search_status = window.prop(TboStatus.OPEN)
        this.selected = null
        this.only_mine = window.prop(false)

        this.search_bar_controller = null
        this.product_management_api = new ProductManagementApi()
    }

    submit_search(text: string) {
        this.tbo_items.update_search_term(text)
        this.tbo_items.submit_search()
    }

    oncreate() {
        this.tbo_items.init()

        const q = m.route.param('q')
        if (q) {
            this.submit_search(q.replace(/\+/g, ' '))
        }
        const show_only_mine = m.route.param('only_mine')
        if (show_only_mine) {
            this.only_mine(true)
        }
        const status = m.route.param('status')
        if (status) {
            this.search_status(status)
        }

        $m.common.observable.subscribe('product_names_updated', this, () =>
            this.search_input_ctrl.set_suggestions($m.products.get_names()))
    }

    additional_params() {
        const params: any = {
            only_mine: this.only_mine(),
        }

        if (this.search_status() === TboStatus.ALL) {
            params['status'] = [TboStatus.OPEN, TboStatus.ORDERED, TboStatus.CONFIRMED]
        } else if (this.search_status() === TboStatus.IN_PROGRESS) {
            params['status'] = [TboStatus.OPEN, TboStatus.ORDERED]
        } else {
            params['status'] = [this.search_status()]
        }
        return params
    }

    update_url_state() {
        m.route.set('/purchase-orders/tbo', {
            status: this.search_status(),
            only_mine: this.only_mine(),
        })
    }

    set_search_status(search_status: string) {
        this.search_status(search_status)
        this.tbo_items.requery()
        this.update_url_state()
    }

    add_to_order(tbo_item: any, spli: any, custom_offer_item: any) {
        this.selected = {
            tbo_item: tbo_item,
            spli: spli,
            custom_offer_item: custom_offer_item,
        }
    }

    close_add_to_order() {
        this.selected = null
        m.redraw()
    }

    refresh_and_close_add_to_order() {
        this.selected = null
        this.tbo_items.requery()
    }

    view() {
        return <div class="c-purchase-tbo view">
            {this.selected && (() => {
                const modal_attrs = {
                    title: this.selected.custom_offer_item ?
                        'Add custom TBO offer item to purchase order' :
                        'Add market item to purchase order',
                    onclose: () => this.close_add_to_order(),
                }

                let supplier_artkey
                if (this.selected.spli) {
                    supplier_artkey = this.selected.spli.supplier_price_list().supplier().artkey()
                } else if (this.selected.custom_offer_item) {
                    supplier_artkey = this.selected.custom_offer_item.supplier.artkey
                }

                return <Modal {...modal_attrs}>
                    <AddToOrder
                        selected_spli={this.selected.spli}
                        supplier_artkey={supplier_artkey}
                        on_added_item={() => this.refresh_and_close_add_to_order()}
                        oncancel={() => this.close_add_to_order()}
                        tbo_item={this.selected.tbo_item}
                        custom_offer_item={this.selected.custom_offer_item}
                    />
                </Modal>
            })()}

            <div class="c-filter-group">
                <SearchBar
                    placeholder="Search for products, specs, TBO supplier, tax label or other item tags..."
                    on_submit={(text: string) => this.submit_search(text)}
                    default_search_text={this.tbo_items.search_term()}
                    search_bar_controller={(controller: any) => this.search_bar_controller = controller}
                    on_get_suggestions$={(filter_text: string) =>
                        this.product_management_api.get_simple_product_names(filter_text)}
                />

                {select(this.search_status, TBO_STATUS_SELECTION,
                    {onchange: (val: any) => this.set_search_status(val)})}

                {button_with_icon('Only my sales TBO\'s', 'glyphicon-user',
                    this.only_mine() ?
                        {
                            class: 'btn-danger',
                            onclick: () => {
                                this.only_mine(false)
                                this.tbo_items.requery()
                                this.update_url_state()
                            },
                        } :
                        {
                            class: 'btn-default',
                            onclick: () => {
                                this.only_mine(true)
                                this.tbo_items.requery()
                                this.update_url_state()
                            },
                        },
                )}
            </div>

            {this.tbo_items.show_counter()}

            <CollectionTable
                collection={this.tbo_items}
                options={{
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: false,
                    autoscale: true,
                    unique_name: 'tbo_items_table',
                }}
                view_details={(record: any) => {
                    if (!record.purchase_order.artkey) {
                        const market_data = {
                            bottle_artkey: record.bottle_artkey,
                            case_artkey: record.case_artkey,
                            exclude_case_artkey_from_market: true,
                            current_supplier_artkey: record.supplier.artkey,
                            customs_status: record.case_customs_status,
                            up_is_up: true,
                            add_to_order: (spli: any) => {
                                spli.supplier_price_list.supplier = spli.supplier
                                this.add_to_order(record, new SupplierPriceListItem(spli, null))
                            },
                            add_custom_offer_item_to_purchase_order: (custom_offer_item: any) => {
                                this.add_to_order(record, null, custom_offer_item)
                            },
                        }

                        return <CaseInfoPanel {...market_data} />
                    }
                }}
                columns={[
                    {
                        width: 4,
                        name: 'Sales Order',
                        field: 'sales_order.artkey',
                        transform: (artkey: string) =>
                            <a href={`/sales-orders/manage/${artkey}`}>{'S' + artkey}</a>,
                        sort: true,
                    },
                    {
                        width: 4,
                        name: 'Order Date',
                        field: 'sales_order.date',
                        transform: formatDate,
                        sort: true,
                    },
                    {
                        width: 4,
                        name: 'Purchase Date',
                        field: 'purchase_order.date',
                        transform: formatDate,
                        sort: true,
                    },
                    {
                        width: 4,
                        name: 'ETA',
                        field: 'purchase_order.expected_delivery_date',
                        transform: formatDate,
                        sort: true,
                    },
                    {
                        width: 10,
                        name: 'Buyer',
                        field: 'sales_order.buyer.name',
                        sort: true,
                        ellipsis: true,
                    },
                    {
                        width: 10,
                        name: 'Sales manager',
                        field: 'sales_order.buyer.sales_manager.name',
                        sort: true,
                        ellipsis: true,
                    },
                    {
                        width: 8,
                        name: 'For account',
                        field: 'sales_order.account.name',
                        ellipsis: true,
                        sort: true,
                        function: (record: any) => [
                            <span class="mr-05">{accountIcon(record.sales_order.account)}</span>,
                            record.sales_order.account.name,
                        ],
                    },
                    {
                        width: 12,
                        name: 'Product',
                        sort: true,
                        field: 'product_name',
                        ellipsis: true,
                    },
                    {
                        width: 5,
                        name: 'Category',
                        sort: true,
                        field: 'product_category',
                        ellipsis: true,
                        transform: (category: string) =>
                            <span class="text-capitalize">{category}</span>,
                    },
                    {
                        width: 3,
                        name: 'Btl / cs',
                        sort: true,
                        field: 'case_number_of_bottles',
                        default_visible: false,
                        classes: ['number'],
                    },
                    {
                        width: 4,
                        name: 'Size',
                        sort: true,
                        field: 'bottle_volume',
                        default_visible: false,
                        function: (record: any) =>
                            <span>{(+record.bottle_volume).toFixed(1)}cl</span>,
                        classes: ['number'],
                    },
                    {
                        width: 4,
                        name: 'Alc %',
                        sort: true,
                        field: 'bottle_alcohol_percentage',
                        default_visible: false,
                        function: (record: any) =>
                            <span>{(+record.bottle_alcohol_percentage).toFixed(1)}%</span>,
                        classes: ['number'],
                    },
                    {
                        width: 3,
                        name: 'Ref',
                        sort: true,
                        default_visible: false,
                        field: 'bottle_refill_status',
                    },
                    {
                        width: 10,
                        name: 'Specs',
                        sort: false,
                        function: (record: any) => {
                            const specs = []
                            specs.push(record.case_number_of_bottles)
                            specs.push((+record.bottle_volume).toFixed(1))
                            specs.push((+record.bottle_alcohol_percentage).toFixed(1))
                            specs.push(record.bottle_refill_status)
                            return specs.join(' / ')
                        },
                    },
                    {
                        width: 4,
                        name: 'GB',
                        sort: true,
                        field: 'case_gift_box_type',
                        default_visible: false,
                        ellipsis: true,
                    },
                    {
                        width: 4,
                        name: 'Tax Label',
                        sort: true,
                        field: 'case_tax_label',
                        default_visible: false,
                        ellipsis: true,
                    },
                    {
                        width: 5,
                        header: 'BBD',
                        name: 'Best before date',
                        sort: true,
                        field: 'case_best_before_date',
                        default_visible: false,
                        transform: (date: string) => maybeMap(formatDate, date),
                    },
                    {
                        width: 10,
                        name: 'Features',
                        function: (record: any) => {
                            const features = []
                            if (record.case_gift_box_type)
                                features.push(record.case_gift_box_type)
                            if (record.case_tax_label)
                                features.push(record.case_tax_label)
                            if (record.case_best_before_date)
                                features.push(`BBD: ${formatDate(record.case_best_before_date)}`)
                            if (record.case_damages)
                                features.push(record.case_damages.replace(', ', ' / '))
                            if (record.case_general_tags)
                                features.push(record.case_general_tags.replace(', ', ' / '))
                            if (record.case_pack_size)
                                features.push(record.case_pack_size)
                            if (record.case_packaging)
                                features.push(record.case_packaging)
                            return features.join(' / ')
                        },
                    },
                    {
                        width: 3,
                        header: 'Cus.',
                        name: 'Customs status',
                        sort: true,
                        field: 'case_customs_status',
                    },
                    {
                        width: 4,
                        header: 'Qty.',
                        name: 'Quantity',
                        sort: true,
                        field: 'number_of_cases',
                    },
                    {
                        width: 10,
                        name: 'Price / cs',
                        sort: true,
                        field: 'price_per_case',
                        classes: ['price'],
                        function: (record: any) =>
                            <Amount
                                amount={+record.price_per_case}
                                excise={+record.excise_per_case}
                                currency={record.currency}
                                rate={+record.rate}
                            />,
                    },
                    {
                        width: 10,
                        name: 'Preferred supplier',
                        sort: true,
                        field: 'supplier.name',
                        ellipsis: true,
                    },
                    {
                        width: 5,
                        name: 'Status',
                        sort: true,
                        field: 'status',
                    },
                    {
                        width: 5,
                        name: 'PO#',
                        field: 'purchase_order.artkey',
                        transform: (artkey: string) => purchase_order_link(artkey),
                    },
                    {
                        width: 6,
                        name: 'Delivery period',
                        sort: true,
                        field: 'delivery_period',
                        classes: ['number'],
                        transform: (delivery_period: number) =>
                            delivery_period ?
                                `${delivery_period} ${pluralize(delivery_period, 'week', 'weeks')}` :
                                '-',
                    },
                    {
                        width: 2,
                        header: '',
                        name: 'Actions',
                        function: () => ' ',
                    },
                ]}
            />
        </div>
    }
}

function purchase_order_link(artkey: string) {
    if (artkey) {
        return <a href={`#!/purchase-orders/manage/P${artkey}`}>
            {`P${artkey}`}
        </a>

    }
    return <span/>
}
