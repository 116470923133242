/** llm:tested */
import m from 'mithril'
import {Button, FieldSelect, FieldVatId} from '@bitstillery/common/components'
import {
    conditional,
    invalid_fields,
    invalid_fields_format,
    required,
    validation,
    vat_id,
} from '@bitstillery/common/lib/validation'
import {european_union_countries} from '@bitstillery/common/lib/countries'
import {and} from '@bitstillery/common/lib/validation'
import {Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {Lead} from './models'

import api_ls from '@/api'
import {button_with_icon} from '@/components/_buttons'
import {checkbox, text} from '@/components/inputs'
import {CountriesSelect} from '@/components/html_components'
import {UserDropDown} from '@/components/users'
import {$m} from '@/app'

export class LeadEdit {
    loading: any
    lead: any
    artkey: string
    $v: any

    query_and_set_lead = (
        artkey: string,
        lead: (l: Lead) => void,
        loading: (state: boolean) => void,
    ): void => {
        loading(true)
        api_ls.callAndThen('crm.leads.get', {artkey: artkey}, {
            success: (resp: { result: any }) => {
                lead(new Lead(resp.result))
            },
            final: () => {
                loading(false)
            },
        })
    }

    constructor() {
        this.loading = window.prop(true)
        this.lead = window.prop(new Lead())
        this.artkey = m.route.param('artkey')

        this.query_and_set_lead(this.artkey, this.lead, this.loading)

        this.$v = {
            country_code: validation([this.lead, 'country_code'], required()),
            lead_name: validation([this.lead, 'name'], required()),
            manager_artkey: validation([this.lead, 'manager.artkey'], required()),
            vat_id: validation([this.lead, 'vat_id'], conditional(() => {
                const country_code = this.lead().country_code()
                return european_union_countries.includes(country_code)
            }, and([required(), vat_id([this.lead, 'country_code'])]))),
        }
    }

    submit() {
        const lead_fields = [
            'artkey',
            'name',
            'company_type',
            'operates_online',
            'street_address',
            'zip_code',
            'city',
            'country_code',
            'vat_id',
            'emailaddress',
            'telephone_number',
            'url',
            'linkedin',
            'facebook',
            'instagram',
            'twitter',
        ]
        const data: any = {}
        for (const field of lead_fields) {
            data[field] = this.lead()[field]()
        }

        data['manager_artkey'] = this.lead().manager().artkey()

        const lead_contact_fields = [
            'first_name',
            'family_name',
            'position',
            'emailaddress',
            'telephone_number',
        ]
        data['contact_person'] = {}
        for (const field of lead_contact_fields) {
            data['contact_person'][field] = this.lead().contact_persons()[0][field]()
        }

        const lead_warehouse_fields = [
            'name',
            'excise_id',
            'excise_location_id',
            'street_address',
            'zip_code',
            'city',
            'country_code',
        ]
        data['warehouse'] = {}
        for (const field of lead_warehouse_fields) {
            data['warehouse'][field] = this.lead().warehouses()[0][field]()
        }

        api_ls.callAndThen('crm.leads.edit', data, {
            success: (_resp) => {
                notifier.notify('Successfully updated lead.', 'success')
                m.route.set(`/crm/leads/${this.lead().artkey()}`)
            },
        })
    }

    view() {
        if (this.loading()) {
            return <Spinner />
        }

        const invalid = invalid_fields(this.$v)

        return (
            <form class="c-lead-edit view">
                <div class="btn-toolbar">
                    {button_with_icon('Back', 'arrow-left', {
                        onclick: () => m.route.set(`/crm/leads/${this.artkey}`),
                        class: 'btn-default',
                    })}
                </div>
                <div class="fieldset-group">
                    <div class="fieldset">
                        <div class="fieldset-label">General information</div>
                        {text(this.lead().name, {
                            label: 'Lead name',
                            placeholder: 'Lead name',
                            validation: this.$v.lead_name,
                        })}

                        <UserDropDown
                            label="Manager"
                            model={[this.lead().manager(), 'artkey']}
                            validation={this.$v.manager_artkey}
                        />

                        <CountriesSelect
                            label="Country"
                            model={[this.lead(), 'country_code']}
                            validation={this.$v.country_code}
                        />

                        <FieldVatId
                            country_code={this.lead().country_code()}
                            disabled={!european_union_countries.includes(this.lead().country_code())}
                            model={[this.lead, 'vat_id']}
                            validation={this.$v.vat_id}
                        />

                        <FieldSelect
                            label="Company type"
                            model={[this.lead(), 'company_type']}
                            options={$m.data.company_types.map((company) => ({
                                label: company,
                                value: company,
                            }))}
                            placeholder="Select Company type..."
                            validation={this.$v.company_type}
                        />
                    </div>

                    <div class="fieldset">
                        <div class="fieldset-label">Address & media</div>
                        <div class="field-group">
                            {text(this.lead().city, {label: 'City'})}
                        </div>
                        <div class="field-group">
                            {text(this.lead().street_address, {label: 'Address'})}
                            {text(this.lead().zip_code, {label: 'Zip code'})}
                        </div>

                        {text(this.lead().telephone_number, {
                            label: 'Phone number',
                            placeholder: '+31501234567',
                        })}
                        <div class="field-group">
                            {text(this.lead().url, {
                                label: 'Website',
                                placeholder: 'https://company.org',
                            })}
                            {checkbox(this.lead().operates_online, {
                                label: 'Operates a webshop',
                            })}
                        </div>

                        {text(this.lead().emailaddress, {
                            label: 'Email address',
                        })}

                        <div class="field-group">
                            {text(this.lead().linkedin, {
                                icon: 'fab fa-linkedin',
                                label: 'LinkedIn',
                                placeholder: 'linkedin.com/company/...',
                            })}
                            {text(this.lead().facebook, {
                                icon: 'fab fa-facebook',
                                label: 'Facebook',
                                placeholder: 'facebook.com/...',
                            })}
                        </div>
                        <div class="field-group">
                            {text(this.lead().instagram, {
                                icon: 'fab fa-instagram',
                                label: 'Instagram',
                                placeholder: 'instagram.com/...',
                            })}
                            {text(this.lead().twitter, {
                                icon: 'fab fa-twitter',
                                label: 'Twitter',
                                placeholder: 'twitter.com/...',
                            })}
                        </div>
                    </div>
                </div>

                <div class="fieldset-group">
                    <div class="fieldset">
                        {(() => {
                            const contact_person = this.lead().contact_persons()[0]
                            return [
                                <div class="fieldset-label">Contact info</div>,
                                <div class="field-group">
                                    {text(contact_person.first_name, {label: 'First name'})}
                                    {text(contact_person.family_name, {label: 'Last name'})}
                                </div>,
                                text(contact_person.position, {label: 'Position'}),
                                text(contact_person.emailaddress, {label: 'Email address'}),
                                text(contact_person.telephone_number, {label: 'Phone number'}),
                            ]
                        })()}
                    </div>

                    <div class="fieldset">
                        {(() => {
                            const warehouse = this.lead().warehouses()[0]
                            return [
                                <div class="fieldset-label">Warehouse</div>,
                                text(warehouse.excise_id, {
                                    label: 'Excise ID',
                                    help: 'Unique identifier for this warehouse',
                                    placeholder: 'e.g. NL00000123ABC',
                                }),
                                text(warehouse.excise_location_id, {label: 'Excise Location ID'}),
                                text(warehouse.name, {
                                    label: 'Name',
                                    placeholder: 'Warehouse name',
                                }),
                                <div class="field-group">
                                    <CountriesSelect
                                        label="Country"
                                        model={[warehouse, 'country_code']}
                                        placeholder="Select Warehouse country..."
                                    />
                                    {text(warehouse.city, {
                                        label: 'City',
                                        placeholder: 'Warehouse city',
                                    })}
                                </div>,
                                <div class="field-group">
                                    {text(warehouse.street_address, {
                                        label: 'Address',
                                        placeholder: 'Warehouse address',
                                    })}
                                    {text(warehouse.zip_code, {
                                        label: 'Zip code',
                                        placeholder: 'Warehouse zip code',
                                    })}
                                </div>,
                            ]
                        })()}
                    </div>
                </div>

                <div class="c-submit">
                    <Button
                        className="btn-submit"
                        icon="save"
                        disabled={invalid.length}
                        onclick={() => this.submit()}
                        text="Update Lead"
                        type="success"
                    />
                    {invalid_fields_format(invalid, 'tip')}
                </div>
            </form>
        )
    }
}
