/** llm:tested */
import m from 'mithril'
import {Amount, FieldMoney} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {PurchaseOrderDetails} from './components/purchase_order_details'

import {call, callAndThen} from '@/api'
import {with_buttons} from '@/components/table/fixed_header'
import {PurchaseOrder} from '@/models/purchase_orders'
import {$m} from '@/app'
import {select, text, date} from '@/components/inputs'

const transport_ledger_map: Record<string, string> = {
    7000: '7130',
    7001: '7131',
    7002: '7131',
    7003: '7132',
    7004: '7133',
}

export default class PurchaseOrderFinancials extends MithrilTsxComponent<unknown> {
    purchase_order: any
    invoice_amount: any
    readonly: any
    transport_costs_ledger: any

    constructor() {
        super()
        this.purchase_order = window.prop(new PurchaseOrder())
        this.invoice_amount = window.prop('')
        this.readonly = window.prop(true)
        this.transport_costs_ledger = window.prop('')
        const purchase_order_artkey = +m.route.param('artkey')
        this.load_purchase_order(+purchase_order_artkey)
    }

    load_purchase_order(purchase_order_artkey: number) {
        const data = {
            purchase_order_artkey: purchase_order_artkey,
        }
        call('purchase.core.get_purchase_orders_with_items', data, this.set_purchase_order)
    }

    set_purchase_order = (resp: any) => {
        if (resp.success) {
            this.purchase_order($m.purchase_orders.create_purchase_order(resp.result[0]))
            if (this.purchase_order().was_sent_to_financial_information_system_on()) {
                this.readonly(true)
            } else {
                this.readonly(false)
                if (!this.purchase_order().invoiced_other_costs()) {
                    this.purchase_order().invoiced_other_costs(this.purchase_order().other_costs())
                }
                if (!this.purchase_order().invoiced_transport_costs()) {
                    this.purchase_order().invoiced_transport_costs(this.purchase_order().transport_costs())
                }
                if (!this.purchase_order().invoiced_pallet_costs()) {
                    this.purchase_order().invoiced_pallet_costs(this.purchase_order().pallet_costs())
                }
                if (!this.purchase_order().invoiced_waste_fund_costs()) {
                    this.purchase_order().invoiced_waste_fund_costs(this.purchase_order().waste_fund_costs())
                }
                if (!this.purchase_order().invoice_price_difference()) {
                    this.purchase_order().invoice_price_difference(this.purchase_order().other_costs())
                }

                this.transport_costs_ledger(transport_ledger_map[this.purchase_order().supplier().purchase_ledger().ledger_code()])
            }

            if (this.purchase_order().intake_date() && !this.purchase_order().is_invoiced_on()) {
                this.purchase_order().is_invoiced_on(this.purchase_order().intake_date())
            }
        } else {
            $m.common.generic_error_handler()
        }
    }

    submit = (event: Event) => {
        event.preventDefault()
        this.save_data()
    }

    save_data = (callback?: (resp: any) => void) => {
        if (!callback) {
            callback = this.handle_save_response
        }
        const data = {
            purchase_order_artkey: +this.purchase_order().artkey(),
            is_invoiced_on: this.purchase_order().is_invoiced_on(),
            supplier_invoice_reference: this.purchase_order().supplier_invoice_reference(),
            invoiced_transport_costs: +this.purchase_order().invoiced_transport_costs(),
            invoiced_pallet_costs: +this.purchase_order().invoiced_pallet_costs(),
            invoiced_other_costs: +this.purchase_order().invoiced_other_costs(),
            invoiced_waste_fund_costs: +this.purchase_order().invoiced_waste_fund_costs(),
            invoice_price_difference: +this.purchase_order().invoice_price_difference(),
            invoiced_amount: +this.purchase_order().invoiced_amount(),
        }
        call('purchase.core.update_financial_data', data, callback)
    }

    handle_save_response = (resp: any) => {
        if (!resp.success) {
            if (resp.message) {
                notifier.notify(resp.message, 'danger')
            } else {
                $m.common.generic_error_handler()
            }
        } else {
            notifier.notify('The order was updated successfully.', 'success')
        }
    }

    export_to_exact = (event: Event) => {
        event.preventDefault()
        if (this.purchase_order().invoiced_transport_costs() > 0 && this.transport_costs_ledger() === '') {
            notifier.notify('Please select a transport ledger.', 'danger')
            return
        }

        this.save_data((resp: any) => {
            if (!resp.success) {
                if (resp.message) {
                    notifier.notify(resp.message, 'danger')
                } else {
                    $m.common.generic_error_handler()
                }
                return
            }

            const data = $m.data.exact_keys.get_tokens()
            data['purchase_order_artkey'] = +this.purchase_order().artkey()
            data['transport_costs_ledger'] = this.transport_costs_ledger()

            callAndThen('purchase.exact.export_purchase_order_to_exact', data, {
                success: () => {
                    notifier.notify('Order successfully exported to Exact!', 'success')
                    m.route.set('/purchase-orders/manage/' + this.purchase_order().artkey())
                },
            })
        })
    }

    total_costs_po = () => {
        return +this.purchase_order().invoiced_other_costs() +
               +this.purchase_order().invoiced_transport_costs() +
               +this.purchase_order().invoiced_pallet_costs() +
               +this.purchase_order().waste_fund_costs()
    }

    view() {
        return <div class="c-purchase-financials view">
            <div class="btn-toolbar" role="group">
                <button class="btn btn-default" type="button"
                    onclick={() => m.route.set('/purchase-orders/manage/' + this.purchase_order().artkey())}>
                    <span class="glyphicon glyphicon-arrow-left"></span> Back to order
                </button>
            </div>

            <h2>Financial administration</h2>

            {this.purchase_order() && this.purchase_order().artkey() &&
                <div>
                    <PurchaseOrderDetails purchase_order={this.purchase_order} />

                    {with_buttons(
                        <table class="table search-table" style="width: 100%">
                            <thead class="thead-default">
                                <tr>
                                    <th>Product</th>
                                    <th>Bottles / case</th>
                                    <th>Specs</th>
                                    <th>Giftbox</th>
                                    <th>Cases ordered</th>
                                    <th>Price per case</th>
                                    <th>Total cost</th>
                                </tr>
                            </thead>
                            {this.purchase_order().purchase_order_items().map((item: any, index: number) =>
                                <FinancialsPurchaseOrderItem item={item} index={index} />,
                            )}
                        </table>,
                    )}

                    <div class="fieldset">
                        {text(
                            () =>
                                this.purchase_order().supplier().purchase_ledger().ledger_code() +
                                    ' - ' +
                                    this.purchase_order().supplier().purchase_ledger().description(), {
                                disabled: true,
                                label: 'Purchase ledger',
                            })}
                        <div class="field-group">
                            {date(this.purchase_order().intake_date, {
                                disabled: true,
                                label: 'Intake date',
                                required: true,
                            })}
                            {date(this.purchase_order().is_invoiced_on, {
                                disabled: true,
                                label: 'Invoice date',
                            })}
                        </div>

                        <div class="field-group">
                            {text(this.purchase_order().supplier_invoice_reference, {
                                disabled: this.readonly(),
                                label: 'Supplier invoice reference',
                                required: true,
                            })}

                            <FieldMoney
                                className="no-spinner"
                                currency={[this.purchase_order(), 'was_bought_in']}
                                disabled={this.readonly()}
                                label="Total invoiced amount"
                                model={[this.purchase_order(), 'invoiced_amount']}
                            />
                        </div>

                        {select(this.transport_costs_ledger, {
                            7130: '7130 - Inkoopkosten (transport NL 0%)',
                            7131: '7131 - Inkoopkosten (transport NL hoog%)',
                            7132: '7132 - Inkoopkosten (transport NL binnen EU)',
                            7133: '7133 - Inkoopkosten (transport NL buiten EU)',
                        },{
                            disabled: this.readonly(),
                            empty_option: true,
                            label: 'Transport ledger',
                        },
                        )}

                        <div class="field-group">
                            <FieldMoney
                                className="no-spinner"
                                currency={[this.purchase_order(), 'was_bought_in']}
                                disabled={this.readonly()}
                                label="Invoiced transport costs"
                                model={[this.purchase_order(), 'invoiced_transport_costs']}
                            />

                            <FieldMoney
                                className="no-spinner"
                                currency={[this.purchase_order(), 'was_bought_in']}
                                disabled={this.readonly()}
                                label="Invoiced pallet costs"
                                model={[this.purchase_order(), 'invoiced_pallet_costs']}
                            />

                            <FieldMoney
                                className="no-spinner"
                                currency={[this.purchase_order(), 'was_bought_in']}
                                disabled={this.readonly()}
                                label="Invoiced other costs"
                                model={[this.purchase_order(), 'invoiced_other_costs']}
                            />
                        </div>

                        {this.purchase_order().supplier().country_code() === 'NL' &&
                            <FieldMoney
                                className="no-spinner"
                                currency={[this.purchase_order(), 'was_bought_in']}
                                disabled={this.readonly()}
                                help="Netherlands only"
                                label="Invoiced waste fund (afvalfonds) costs"
                                model={[this.purchase_order(), 'invoiced_waste_fund_costs']}
                            />
                        }

                        <FieldMoney
                            className="no-spinner"
                            currency={[this.purchase_order(), 'was_bought_in']}
                            disabled={this.readonly()}
                            label="Price differences"
                            min={-10000}
                            model={[this.purchase_order(), 'invoice_price_difference']}
                        />

                        {(() => {
                            const result = +((+this.purchase_order().was_bought_for()) -
                                (+this.purchase_order().invoiced_amount() -
                                this.total_costs_po() +
                                +this.purchase_order().invoice_price_difference())).toFixed(2)
                            return <p class={result !== 0 ? 'text-danger' : 'text-success'}>
                                Result: {result}
                            </p>
                        })()}

                        {!this.purchase_order().was_sent_to_financial_information_system_on() &&
                            <div class="btn-toolbar">
                                <button class="btn btn-primary" type="submit" onclick={this.export_to_exact}>
                                    <span class="glyphicon glyphicon-export"></span> Save and export to Exact
                                </button>
                                <button class="btn btn-info" type="submit" onclick={this.submit}>
                                    <span class="glyphicon glyphicon-save"></span> Save but do not export
                                </button>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    }
}

class FinancialsPurchaseOrderItem extends MithrilTsxComponent<unknown> {
    item: any
    index: any

    constructor(vnode: any) {
        super()
        this.item = window.prop(vnode.attrs.item)
        this.index = window.prop(vnode.attrs.index)
    }

    view() {
        return <tbody class="table-row">
            <tr>
                <td>{this.item().bottle().product().name()}</td>
                <td>{this.item().number_of_bottles_per_case()}</td>
                <td>
                    {(+this.item().bottle().volume()).toFixed(1)}cl /
                    {(+this.item().bottle().alcohol_percentage()).toFixed(1)}% /
                    {this.item().bottle().refill_status()}
                </td>
                <td>{this.item().gift_box_type()}</td>
                <td>{this.item().number_of_cases()}</td>
                <td>
                    <Amount
                        amount={+this.item().was_bought_for()}
                        currency={this.item().purchase_order().was_bought_in()}
                        rate={this.item().purchase_order().bought_against_rate()}
                    />
                </td>
                <td>
                    <Amount
                        amount={+this.item().number_of_cases() * +this.item().was_bought_for()}
                        currency={this.item().purchase_order().was_bought_in()}
                        rate={this.item().purchase_order().bought_against_rate()}
                    />
                </td>
            </tr>
        </tbody>
    }
}
