/** llm:tested */
import m from 'mithril'
import {AccountSlug} from '@bitstillery/common/account/account'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {config, notifier} from '@bitstillery/common/app'

import {ProcessManageOffer} from './processes'
import {CloneOffer} from './components/clone_offer'

import api_ls from '@/api'
import {accountIconBySlug} from '@/accounts'
import {show} from '@/components/confirmation'
import {icon_button} from '@/components/_buttons'
import {CollectionTable} from '@/components/collection_table'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import {icon} from '@/components/icon'
import {checkbox} from '@/components/inputs'
import {Modal} from '@/components/modal/modal'
import {formatDate, formatDateTime} from '@/_utils'
import {OfferType, Priority} from '@/models/offers'
import {$m} from '@/app'

export class OfferList extends MithrilTsxComponent<unknown> {
    offers: any
    search_input_ctrl: any
    source_offer: any

    constructor() {
        super()
        this.offers = new Collection({
            api_function_name: 'offer.get_offer_records',
            default_sort_by: 'start_date',
            default_sort_order: 'desc',
            filter_function: this.is_match,
            query_limit: 25,
            additional_params: () => ({
                show_expired: this.offers.show_expired(),
                show_mine_only: this.offers.show_mine_only(),
            }),
        })

        if (typeof this.offers.show_expired === 'undefined') {
            this.offers.show_expired = window.prop(false)
        }
        if (typeof this.offers.show_mine_only === 'undefined') {
            this.offers.show_mine_only = window.prop(false)
        }

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.offers,
            placeholder: 'Search for title, remark and/or creator.',
        })

        this.source_offer = window.prop(null)
    }

    oncreate() {
        this.offers.init()
    }

    close_clone_offer_modal() {
        this.source_offer(null)
        this.offers.requery()
    }

    delete_offer(artkey: string) {
        const data = {
            artkey: artkey,
        }
        api_ls.callAndThen('offer.delete_offer', data, {
            success: () => {
                this.offers.requery()
                notifier.notify('Successfully deleted the offer.', 'success')
                m.route.set('/offer/offers')
            },
        })
    }

    is_match(offer: any, term: string) {
        return offer.title.toLowerCase().indexOf(term) > -1 ||
            offer.remark.toLowerCase().indexOf(term) > -1 ||
            offer.created_by.profile.name.toLowerCase().indexOf(term) > -1
    }

    toggle_active_only(value?: boolean) {
        if (value !== undefined) {
            this.offers.show_expired(value)
            this.offers.requery()
        } else {
            return this.offers.show_expired()
        }
    }

    toggle_mine_only(value?: boolean) {
        if (value !== undefined) {
            this.offers.show_mine_only(value)
            this.offers.requery()
        } else {
            return this.offers.show_mine_only()
        }
    }

    view() {
        return <div class="c-offer-offers view process">
            <ProcessManageOffer
                active="list"
                context={{}}
            />

            <div class="step-content">
                <div class="c-filter-group">
                    {SearchInput.view(this.search_input_ctrl)}
                    {checkbox(this.toggle_active_only.bind(this), {label: 'Show expired offers'})}
                    {checkbox(this.toggle_mine_only.bind(this), {label: 'Show my offers only'})}
                </div>

                {this.offers.show_counter()}

                <CollectionTable
                    collection={this.offers}
                    options={{
                        search_table_style: true,
                        sticky_header: true,
                        with_buttons: false,
                        autoscale: true,
                        unique_name: 'offer_records',
                        onclick: (record: any) => {
                            m.route.set(`/offer/offers/${record.artkey}/details`)
                        },
                    }}
                    columns={[
                        {
                            width: 10,
                            name: 'Title',
                            sort: true,
                            field: 'title',
                        },
                        {
                            width: 10,
                            name: 'Remark',
                            sort: true,
                            field: 'remark',
                            ellipsis: true,
                        },
                        {
                            width: 7,
                            name: 'Start Date',
                            sort: true,
                            field: 'start_date',
                            transform: formatDate,
                        },
                        {
                            width: 7,
                            name: 'End Date',
                            sort: true,
                            field: 'end_date',
                            transform: formatDate,
                        },
                        {
                            width: 10,
                            name: 'Published on',
                            sort: true,
                            field: 'published_timestamp',
                            transform: formatDateTime,
                        },
                        {
                            width: 20,
                            name: 'Portal link',
                            field: 'hash',
                            classes: ['no-click'],
                            function: (record: any) => {
                                if (record.published_timestamp) {
                                    return [
                                        <PortalOfferLink
                                            account={AccountSlug.MSP}
                                            offer_hash={record.hash}
                                        />,
                                        <PortalOfferLink
                                            account={AccountSlug.ETR}
                                            offer_hash={record.hash}
                                        />,
                                    ]
                                } else {
                                    return 'offer is not published yet'
                                }
                            },
                        },
                        {
                            width: 10,
                            name: 'Creator',
                            field: 'created_by.profile.name',
                            sort: true,
                            ellipsis: true,
                        },
                        {
                            width: 10,
                            name: 'Incoterm',
                            field: 'incoterm',
                            sort: true,
                            ellipsis: true,
                            function: (record: any) => {
                                if (record.incoterm) {
                                    return `${record.incoterm} - ${record.incoterm_location}`
                                } else {
                                    return 'default for relation'
                                }
                            },
                        },
                        {
                            width: 8,
                            name: 'Offer type',
                            sort: true,
                            field: 'offer_type',
                            transform: (value: string) => OfferType.all[value],
                        },
                        {
                            width: 8,
                            name: 'Priority',
                            sort: true,
                            field: 'priority',
                            transform: (value: string) => Priority.all[value],
                        },
                        {
                            width: 8,
                            name: 'Actions',
                            header: '',
                            function: (record: any) => {
                                return <div class="btn-toolbar no-click">
                                    <div class="btn-group">
                                        {icon_button('pencil', {
                                            class: 'btn-default no-click',
                                            onclick: () => m.route.set(`/offer/offers/${record.artkey}/details`),
                                            title: 'Edit offer',
                                        })}
                                        {icon_button('duplicate', {
                                            class: 'btn-default no-click',
                                            onclick: () => this.source_offer(record),
                                            title: 'Clone offer',
                                        })}
                                        {icon_button('remove', {
                                            class: 'btn-default no-click',
                                            onclick: () => show({
                                                title: 'Delete offer',
                                                message: 'Are you sure you want to delete this offer?',
                                                onconfirm: () => this.delete_offer(record.artkey),
                                                unique_name: 'offer_delete_confirm',
                                            }),
                                            title: 'Delete offer',
                                        })}
                                    </div>
                                </div>
                            },
                        },
                    ]}
                />

                {this.source_offer() &&
                    <Modal
                        title="Clone offer"
                        onclose={() => this.close_clone_offer_modal()}
                    >
                        <CloneOffer
                            source_offer={this.source_offer}
                            done={() => this.close_clone_offer_modal()}
                            cancel={() => this.close_clone_offer_modal()}
                        />
                    </Modal>
                }
            </div>
        </div>
    }
}

export class PortalOfferLink extends MithrilTsxComponent<unknown> {
    account: string
    hash: string

    constructor(vnode: any) {
        super()
        this.account = vnode.attrs.account
        this.hash = vnode.attrs.offer_hash
    }

    account_icon() {
        if (this.account === AccountSlug.MSI) {
            return accountIconBySlug(AccountSlug.MSI, $m.data.msi_supplier_name)
        } else if (this.account === AccountSlug.MSP) {
            return accountIconBySlug(AccountSlug.MSP, $m.data.msp_supplier_name)
        } else if (this.account === AccountSlug.A2BC) {
            return accountIconBySlug(AccountSlug.A2BC, $m.data.a2bc_supplier_name)
        } else if (this.account === AccountSlug.ETR) {
            return accountIconBySlug(AccountSlug.ETR, $m.data.a2bc_supplier_name)
        }
    }

    link() {
        if ([AccountSlug.MSI, AccountSlug.MSP].includes(this.account)) {
            return `${config.portal_base_url}/#!/offers?offer_hash=${this.hash}`
        } else if ([AccountSlug.A2BC, AccountSlug.ETR].includes(this.account)) {
            return `${config.a2bc_portal_base_url}/#!/offers?offer_hash=${this.hash}`
        }
    }

    view() {
        return <span>
            {this.account_icon()}
            <span> </span>
            <a
                id="msi-link"
                target="_blank"
                href={this.link()}
            >
                {this.link()}
            </a>
            <span> </span>
            <span
                class="copy-to-clipboard-icon"
                href="#"
                onclick={() => navigator.clipboard.writeText(this.link()).then(() => {
                    alert('Copied link to clipboard.')
                })}
            >
                {icon('fa-copy')}
            </span>
        </span>
    }
}
