import m from 'mithril'

export const from_bottle = function(bottle) {
    return '#!/market/discover?q=' +
            bottle.product.name + ' ' +
            (+bottle.volume).toFixed(1) + ' ' +
            (+bottle.alcohol_percentage).toFixed(1)
}

export const from_json = function(bottle) {
    return '#!/market/discover?q=' +
            bottle.product.name + ' ' +
            (+bottle.volume).toFixed(1) + ' ' +
            (+bottle.alcohol_percentage).toFixed(1)
}

export const from_properties = function(product_name, bottle_volume, bottle_alcohol_percentage) {
    return '#!/market/discover?search_terms=' +
            product_name + ' ' + (+bottle_volume).toFixed(1) + ' ' + (+bottle_alcohol_percentage).toFixed(1)
}

export const from_properties2 = function(product_name, bottle_volume, bottle_alcohol_percentage) {
    return '/market/discover?search_terms=' +
            product_name + ' ' + (+bottle_volume).toFixed(1) + ' ' + (+bottle_alcohol_percentage).toFixed(1)
}

export const link_from_bottle = function(bottle) {
    return m('a.discover-link', {target: '_blank', href: from_bottle(bottle)},
        m('span.glyphicon.glyphicon-search'),
    )
}
