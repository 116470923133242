import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    ButtonGroup,
    Country,
    FieldCheckbox,
    FieldSelect,
    FieldText,
    PanelContext,
    FieldListInput,
} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {watch} from '@bitstillery/common/lib/store'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {invalid_fields, invalid_fields_format, validation} from '@bitstillery/common/lib/validation'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api, notifier} from '@bitstillery/common/app'

import {$s} from '@/app'
import {
    CreateEntityResponse,
    CreateOrUpdateProductCategoryRequest,
    GetProductCategoriesResponse,
    GetProductCategoryResponse,
    GetVatRateResponse,
    ProductBottleType,
} from '@/factserver_api/fact2server_api'

interface PanelProductCategoryAttrs {
    collection: CollectionProxy
    edit_model: {
        artkey: number
    }
}

export class PanelProductCategory extends MithrilTsxComponent<PanelProductCategoryAttrs> {

    $v: any = {}
    watchers = [] as any
    data = proxy({
        request: {
            name: '',
            cbs_code: '',
            cbs_code_gte_200: '',
            cbs_code_gte_1000: '',
            be_vat_rate_artkey: 0,
            nl_vat_rate_artkey: 0,
            de_vat_rate_artkey: 0,
            parent_artkey: null,
            priority: 0,
            is_spirit: true,
            default_product_bottle_type: ProductBottleType.HEAVY_GLASS,
        } as CreateOrUpdateProductCategoryRequest,

        alternatives: {
            disabled: false,
            items: [] as string[],
            new_item: '',
        },

        be_vat_rates: [] as GetVatRateResponse[],
        de_vat_rates: [] as GetVatRateResponse[],
        nl_vat_rates: [] as GetVatRateResponse[],

        main_categories: [] as { artkey: number; name: string }[],
    })

    async oncreate() {
        this.watchers.push(watch($s.context, 'data', this.update_request.bind(this)))

        const {status_code, result} = await api.get<GetVatRateResponse[]>('discover/data/vat-rates')
        if (status_code > 299) {
            return
        }
        this.data.be_vat_rates = result.filter((vat) => vat.country_code === 'BE')
        this.data.nl_vat_rates = result.filter((vat) => vat.country_code === 'NL')
        this.data.de_vat_rates = result.filter((vat) => vat.country_code === 'DE')
        this.data.request.be_vat_rate_artkey = this.data.be_vat_rates.at(0)?.artkey || -1
        this.data.request.de_vat_rate_artkey = this.data.de_vat_rates.at(0)?.artkey || -1
        this.data.request.nl_vat_rate_artkey = this.data.nl_vat_rates.at(0)?.artkey || -1

        const {result: result_categories} = await api.get<GetProductCategoriesResponse>('discover/product-categories')
        this.data.main_categories = result_categories.categories.map((cat) => {
            return {
                artkey: cat.artkey, name: cat.name,
            }
        })

    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    async update_request() {
        if (!$s.context.data.artkey) {
            return
        }
        $s.context.loading = true
        const {
            status_code,
            result,
        } = await api.get<GetProductCategoryResponse>(`discover/product-categories/${$s.context.data.artkey}`)
        if (status_code > 299) {
            notifier.notify('Cannot load the Product Category')
            $s.context.loading = false
            return
        }
        merge_deep(this.data.request, result)
        this.data.alternatives.new_item = ''
        this.data.alternatives.items = result.alternative_names ?? []
        $s.context.loading = false
    }

    async save_data(vnode: m.Vnode<PanelProductCategoryAttrs>) {
        this.data.request.parent_artkey = this.data.request.parent_artkey ? this.data.request.parent_artkey : null
        if ($s.context.name === 'add_product_category') {
            const {
                status_code,
                result,
            } = await api.post<CreateEntityResponse>('discover/product-categories', this.data.request, true)
            if (status_code > 299) {
                notifier.notify('Cannot create the Product Category', 'warning')
                return
            }
            $s.context.id = result.artkey
            await vnode.attrs.collection.update_context()
            notifier.notify('The Product Category was created.')
        } else {
            const {status_code} = await api.put(`discover/product-categories/${$s.context.id}`, this.data.request)
            if (status_code > 299) {
                notifier.notify('Cannot update the Product Category', 'warning')
                return
            }
            await vnode.attrs.collection.update_context()
            notifier.notify('The Product Category was updated.')
        }
        Object.assign($s.context, {
            data: {},
            icon: null,
            id: null,
            name: null,
        })

    }

    view(vnode: m.Vnode<PanelProductCategoryAttrs>) {
        return <PanelContext collection={vnode.attrs.collection}>
            <div className="content">
                {!this.data.loading && ($s.context.name === 'edit_product_category' || $s.context.name === 'add_product_category') &&
                    <div className="context-well">
                        <div className="field-group">
                            <FieldText
                                label="Category name"
                                model={[this.data.request, ['name']]}
                                placeholder="Product Category name"
                            />
                        </div>
                        <div className="field-group">
                            <FieldText
                                label="CBS Code"
                                model={[this.data.request, ['cbs_code']]}
                            />
                            <FieldText
                                label="CBS Code >= 200"
                                model={[this.data.request, ['cbs_code_gte_200']]}
                            />
                            <FieldText
                                label="CBS Code >= 1000"
                                model={[this.data.request, ['cbs_code_gte_1000']]}
                            />
                        </div>
                        <div className="field-group">
                            <FieldSelect
                                label={<span>VAT Rate NL{' '}<Country country_code={'nl'}/></span>}
                                model={[this.data.request, ['nl_vat_rate_artkey']]}
                                options={this.data.nl_vat_rates.map((i) => ({
                                    label: i.percentage,
                                    value: i.artkey,
                                }))}
                            />
                            <FieldSelect
                                label={<span>VAT Rate DE{' '}<Country country_code={'de'}/></span>}
                                model={[this.data.request, ['de_vat_rate_artkey']]}
                                options={this.data.de_vat_rates.map((i) => ({
                                    label: i.percentage,
                                    value: i.artkey,
                                }))}
                            />
                            <FieldSelect
                                label={<span>VAT Rate BE{' '}<Country country_code={'be'}/></span>}
                                model={[this.data.request, ['be_vat_rate_artkey']]}
                                options={this.data.be_vat_rates.map((it) => ({
                                    label: it.percentage,
                                    value: it.artkey,
                                }))}
                            />
                        </div>
                        <div className="field-group">
                            <FieldCheckbox
                                label={'Is spirit'}
                                model={[this.data.request, 'is_spirit']}
                            />
                        </div>
                        <div className="field-group">
                            <FieldSelect
                                label={'Parent'}
                                model={[this.data.request, 'parent_artkey']}
                                placeholder={'No parent'}
                                options={this.data.main_categories.map((it) => ({
                                    label: it.name,
                                    value: it.artkey,
                                }))}
                            />
                        </div>
                        <div className="field-group">
                            <FieldSelect
                                label="Default bottle"
                                model={[this.data.request, 'default_product_bottle_type']}
                                options={Object.entries(ProductBottleType).map((value, label) => ({label, value}))}
                            />
                        </div>
                        <FieldListInput
                            label='Alternative names'
                            model={[this.data.alternatives, 'new_item']}
                            items={this.data.alternatives.items}
                            placeholder={'Alternative name (atleast 3 characters)'}
                            validation={{
                                new_item: validation([this.data.alternatives, 'new_item'], {
                                    validate: (value: string) => {
                                        if (
                                            value.length >= 3 &&
                                            !this.data.alternatives.items.some((name) => name.toLowerCase() === value.toLowerCase())
                                        ) {
                                            return false
                                        }
                                        return this
                                    },
                                    label: '*',
                                    message: '',
                                }),
                            }}
                            onadd={() => {
                                if (this.data.alternatives.new_item) {
                                    this.data.alternatives.disabled = true
                                    api.put(
                                        `discover/product-categories/${$s.context.data.artkey}/alternative`,
                                        {alternative_name: this.data.alternatives.new_item},
                                    ).then((resp) => {
                                        if (resp.status_code > 299) {
                                            notifier.notify(`Alternative name "${this.data.alternatives.new_item}" cannot be added`, 'warning')
                                            this.data.alternatives.disabled = false
                                            return
                                        }
                                        notifier.notify(`Alternative name "${this.data.alternatives.new_item}" was added`, 'success')
                                        this.data.alternatives.items.push(this.data.alternatives.new_item)
                                        this.data.alternatives.new_item = ''
                                        this.data.alternatives.disabled = false
                                    })
                                }
                            }}
                            onremove={(name: string) => {
                                this.data.alternatives.disabled = true
                                api.delete(
                                    `discover/product-categories/${$s.context.data.artkey}/alternative/${name}`,
                                ).then((resp) => {
                                    if (resp.status_code > 299) {
                                        notifier.notify(`Alternative name "${name}" cannot be removed`, 'warning')
                                        this.data.alternatives.disabled = false
                                        return
                                    }
                                    this.data.alternatives.items = this.data.alternatives.items.filter((n) => n !== name)
                                    notifier.notify(`Alternative name "${name}" was removed`, 'success')
                                    this.data.alternatives.disabled = false
                                })
                            }}
                        />
                        <ButtonGroup>
                            <Button
                                icon="save"
                                onclick={async() => await this.save_data(vnode)}
                                text={'Save'}
                                tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                                type="success"
                            />
                        </ButtonGroup>

                    </div>}
            </div>

        </PanelContext>
    }
}
