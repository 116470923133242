/** llm:tested */
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {PortalQuote} from '@/portal/models'
import {$m} from '@/app'

interface QuoteResponse {
    success: boolean
    result?: any
    message?: string
    artkey?: string
}

export class PortalQuotesUpsert {
    quote: PortalQuote
    create: boolean

    constructor() {
        this.quote = new PortalQuote()
        this.create = false

        const artkey = m.route.param('artkey')
        if (artkey) {
            this.query_quote(artkey)
        } else {
            this.create = true
        }
    }

    query_quote(artkey: string) {
        api.call('portal.quote.get_single_quote', {artkey: artkey}, this.set_quote.bind(this))
    }

    set_quote(resp: QuoteResponse) {
        if (resp.success) {
            try {
                this.quote = new PortalQuote(resp.result)
            } catch (e) {
                $m.common.generic_error_handler(e)
            }
        } else {
            notifier.notify('Unknown quote.', 'danger')
            m.route.set('/portal/portal-quotes')
        }
    }

    save(e: Event) {
        e.preventDefault()

        const data = {
            artkey: this.quote.artkey(),
            name: this.quote.name(),
            content: this.quote.content(),
            image_name: this.quote.image_name(),
        }
        api.call('portal.quote.create_or_update_quote', data, this.handle_save.bind(this))
    }

    handle_save(result: QuoteResponse) {
        if (!result.success) {
            notifier.notify(result.message, 'danger')
        } else {
            if (this.create) {
                notifier.notify('Successfully created new portal quote.', 'success')
                m.route.set('/portal/portal-quotes/' + result.artkey + '/edit')
            } else {
                notifier.notify('Successfully updated quote.', 'success')
            }
        }
    }

    view() {
        return (
            <div class="c-portal-quotes-edit view">
                <div class="btn-toolbar">
                    <button
                        class="btn btn-default"
                        type="button"
                        onclick={() => m.route.set('/portal/portal-quotes')}
                    >
                        <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                    </button>
                </div>

                <form class="flex-form" onsubmit={(e) => this.save(e)}>
                    <div class="fieldset">
                        <div class="field">
                            <label for="quote_name">Name</label>
                            <input
                                class="form-control"
                                id="quote_name"
                                type="text"
                                required={true}
                                placeholder="quote name"
                                value={this.quote.name() || ''}
                                oninput={(ev: InputEvent) =>
                                    this.quote.name((ev.target as HTMLInputElement).value)}
                            />
                        </div>
                        <div class="field">
                            <label for="quote_image_name">Image name</label>
                            <input
                                class="form-control"
                                id="quote_image_name"
                                type="text"
                                required={true}
                                placeholder="quote image name"
                                value={this.quote.image_name() || ''}
                                oninput={(ev: InputEvent) =>
                                    this.quote.image_name((ev.target as HTMLInputElement).value)}
                            />
                        </div>
                        <div class="field">
                            <label for="quote_content">Message</label>
                            <textarea
                                class="form-control"
                                id="quote_content"
                                rows={15}
                                placeholder="quote message"
                                value={this.quote.content() || ''}
                                oninput={(ev: InputEvent) =>
                                    this.quote.content((ev.target as HTMLInputElement).value)}
                            />
                        </div>
                    </div>
                    <button class="btn btn-success btn-submit">
                        {this.create ? 'Create Quote' : 'Update Quote'}
                    </button>
                </form>
            </div>
        )
    }
}
