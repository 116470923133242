/** llm:tested */
import m from 'mithril'
import {map, id} from 'prelude-ls'
import {isFunction} from '@bitstillery/common/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import * as inputs from '@/components/inputs'

export class Field extends MithrilTsxComponent<any> {
    name: string
    label: string
    element: string
    type: string
    attrs: Record<string, any>
    help_text?: string

    constructor(name: string, label: string, element: string, type: string, attrs?: Record<string, any>, help_text?: string) {
        super()
        this.name = name
        this.label = label
        this.element = element
        this.type = type
        this.attrs = attrs || {}
        this.help_text = help_text
    }

    view_element(entity: m.Vnode<any>) {
        const attrs: Record<string, any> = {
            id: this.label,
            placeholder: this.name,
            required: true,
            autocomplete: 'off',
            value: entity[this.label]() || '',
            oninput: (ev: InputEvent) => entity[this.label]((ev.target as HTMLInputElement).value),
        }

        if (this.element === 'input') {
            attrs.type = this.type
        }

        for (const [key, value] of Object.entries(this.attrs)) {
            attrs[key] = value
        }

        return m(
            `${this.element}.form-control`,
            attrs,
        )
    }

    view(entity: m.Vnode<any>) {
        return <div class="field">
            <label for={this.label}>{this.name}</label>
            {this.view_element(entity)}
            {this.help_text && <div class="help">{this.help_text}</div>}
        </div>
    }
}

export class EnumField extends Field {
    options: () => any[]
    get_key: (option: any) => any
    get_value: (option: any) => any

    constructor(
        name: string,
        label: string,
        options: (() => any[]) | any[],
        attrs?: Record<string, any>,
        get_key?: (option: any) => any,
        get_value?: (option: any) => any,
    ) {
        super(name, label, 'select', '', attrs)
        this.options = isFunction(options) ? options : window.prop(options)
        this.get_key = get_key || id
        this.get_value = get_value || id
    }

    view_element(entity: m.Vnode<any>) {
        const attrs: Record<string, any> = {
            id: this.label,
            placeholder: this.name,
            required: true,
            autocomplete: 'off',
            onchange: (e: Event) => {
                const key = (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].value
                entity[this.label](key)
            },
        }

        for (const [key, value] of Object.entries(this.attrs)) {
            attrs[key] = value
        }

        return <select class="form-control" {...attrs}>
            <option value=""></option>
            {map((option: any) => {
                const key = this.get_key(option)
                return (
                    <option
                        value={key}
                        selected={+entity[this.label]() === key}
                    >
                        {this.get_value(option)}
                    </option>
                )
            }, this.options())}
        </select>

    }
}

export class CheckboxField extends Field {
    view(entity: m.Vnode<any>) {
        return inputs.checkbox(entity[this.label], {label: this.name})
    }
}
