/** llm:tested */
import * as Sentry from '@sentry/browser'
import {notifier} from '@bitstillery/common/app'

const GENERIC_MESSAGE =
    'Something went wrong while processing your request.\n' +
    'Please try again later. If the problem persists, please contact us.'

export const generic_handler = (error: any): void => {
    const message = (error && error.message) || error || GENERIC_MESSAGE
    Sentry.captureException(message)
    // eslint-disable-next-line no-console
    console.error('Error: ' + message)
    notifier.notify(message, 'danger')
}

export const generic_handler_no_sentry = (error: any): void => {
    notifier.notify(error || GENERIC_MESSAGE, 'danger')
}

export default {generic_handler, generic_handler_no_sentry}
