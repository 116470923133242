/** llm:tested */
import {events} from '@bitstillery/common/app'
import {find} from 'prelude-ls'
import {current_account_slug} from '@bitstillery/common/account/account'

import {$m} from '@/app'
import api from '@/api'

export class Account {
    artkey = window.prop(null)
    name = window.prop('')
    informal_name = window.prop('')
    slug = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class AccountsDataModel {
    accounts = window.prop([])

    constructor() {
        api.callAndThen('accounts.get_all', {}, {
            success: (resp: { result: any[] }) => {
                this.accounts(resp.result.map(account_json => new Account(account_json)))
                $m.common.observable.broadcast('accounts_loaded', this.accounts)
            },
        })
    }

    get_account(artkey: string): Account | undefined {
        return find(account => account.artkey() === artkey, this.accounts())
    }

    get_account_by_slug(slug: string): Account | undefined {
        return find(account => account.slug() === slug, this.accounts())
    }

    current_account(): Account | undefined {
        return this.get_account_by_slug(current_account_slug())
    }
}

export function bind() {
    events.on('identity.login', async() => {
        $m.accounts = new AccountsDataModel()
    })
}
