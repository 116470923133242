/** llm:tested */
import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {callAndThen} from '@/api'
import {view, Controller} from '@/components/collection/autocomplete'
import {SalesOrderCreditItem, SalesOrderItem} from '@/sales_orders/models'
import {number} from '@/components/inputs'
import {$m} from '@/app'

interface AddCreditItemAttrs {
    sales_order: any
    sales_order_changed?: () => void
}

export class AddCreditItem extends MithrilTsxComponent<AddCreditItemAttrs> {
    sales_order_credit_item: any
    data: any
    sales_order: any
    sales_order_changed: any
    creditable_products: any
    loading_products: any
    loading_items: boolean
    loading: any
    autocomplete_ctrl: any
    product: any
    product_name: any
    creditable_sales_order_items: any

    constructor(vnode: m.Vnode<AddCreditItemAttrs>) {
        super()
        this.sales_order_credit_item = window.prop(new SalesOrderCreditItem())
        this.data = proxy({
            _total_value: function() {
                if (!this.sales_order_credit_item) {
                    return 0
                }
                if (this.sales_order_credit_item().number_of_cases() && this.sales_order_credit_item().sales_order_item().price_per_case()) {
                    return this.sales_order_credit_item().number_of_cases() * this.sales_order_credit_item().sales_order_item().price_per_case()
                } else {
                    return 0
                }
            },
        })

        this.sales_order = vnode.attrs.sales_order
        this.sales_order_changed = vnode.attrs.sales_order_changed
        this.creditable_products = window.prop([])
        this.init()

        this.loading_products = window.prop(false)
        this.loading_items = false
        this.loading = window.prop(false)

        this.autocomplete_ctrl = new Controller({
            input_container_id: '#search_input_container',
            on_submit_suggestion: (e) => this.on_submit_suggestion(e),
        })
    }

    init() {
        this.product = window.prop('')
        this.product_name = window.prop('')
        this.creditable_sales_order_items = window.prop([])
    }

    load_creditable_products() {
        if (this.creditable_products().length === 0) {
            this.loading_products(true)
            const data = {
                supplier_artkey: this.sales_order().supplier().artkey(),
            }
            callAndThen('sales.credit.get_creditable_products_for_supplier', data, {
                success: (resp: any) => {
                    this.creditable_products(resp.result)
                    const creditable_product_names = this.creditable_products().map((product: any) => product.name)
                    this.autocomplete_ctrl.set_suggestions(creditable_product_names)
                },
                final: () => {
                    this.loading_products(false)
                },
            })
        }
    }

    select_product(value: string, is_suggestion = false) {
        this.product_name(value)
        this.autocomplete_ctrl.oninput(value, is_suggestion)
        this.product(this.creditable_products().filter((p: any) => p.name === value).at(0))
        this.reset_item()
    }

    on_submit_suggestion(value: string) {
        this.select_product(value, true)
        this.loading_items = true
        const data = {
            supplier_artkey: this.sales_order().supplier().artkey(),
            product_artkey: this.product().artkey,
        }

        callAndThen('sales.credit.get_creditable_sales_order_items_for_supplier_and_product', data, {
            success: (resp: any) => {
                this.creditable_sales_order_items(resp.result.map((item: any) => new SalesOrderItem(item)))
            },
            final: () => {
                this.loading_items = false
            },
        })
    }

    set_item(item_artkey: number) {
        if (item_artkey) {
            this.sales_order_credit_item().sales_order_item(this.creditable_sales_order_items().filter((i: any) => i.artkey() === +item_artkey).at(0))
        } else {
            this.sales_order_credit_item().sales_order_item(new SalesOrderItem())
        }
    }

    reset_item() {
        this.creditable_sales_order_items([])
        this.sales_order_credit_item().sales_order_item(new SalesOrderItem())
    }

    add_to_order(e: Event) {
        e.preventDefault()
        this.loading(true)

        const data = {
            sales_order_artkey: this.sales_order().artkey(),
            sales_order_item_artkey: this.sales_order_credit_item().sales_order_item().artkey(),
            number_of_cases: this.sales_order_credit_item().number_of_cases(),
        }

        callAndThen('sales.credit.add_credit_item_to_sales_order', data, {
            success: (resp: any) => {
                if (this.sales_order_changed) {
                    this.sales_order_changed()
                } else {
                    const sales_order_credit_item_obj = new SalesOrderCreditItem(resp.result, this.sales_order())
                    this.sales_order().sales_order_credit_items().push(sales_order_credit_item_obj)
                    $m.common.observable.broadcast('sales_order_updated_' + this.sales_order().artkey(), 'Go fetch')
                }
                this.init()
                this.autocomplete_ctrl.reset()
                notifier.notify('The credit item was added successfully.', 'success')
            },
            final: () => {
                this.loading(false)
            },
        })
    }

    onremove() {
        this.autocomplete_ctrl.onremove()
    }

    view() {
        return <div class="mwrap">
            <button class="btn btn-default mb-2"
                data-target="#add_credit_item"
                data-toggle="collapse"
                onclick={() => this.load_creditable_products()}>
                <span class="glyphicon glyphicon-plus"></span> Add credit item to order
            </button>
            <div class="collapse" id="add_credit_item">
                {this.loading_products() ? <Spinner /> :
                    <form class="flex-form" onsubmit={(e: Event) => this.add_to_order(e)}>
                        <div class="fieldset largest">
                            <div class="field-group">
                                <div class="field">
                                    <label>Product</label>
                                    <div class="control">
                                        <input
                                            autofocus
                                            value={this.product_name()}
                                            type="text"
                                            onclick={(e: Event) => this.autocomplete_ctrl.onclick(e)}
                                            autocomplete="off"
                                            onkeydown={(e: KeyboardEvent) => this.autocomplete_ctrl.onkeydown(e)}
                                            oninput={(e: InputEvent) => this.select_product((e.target as HTMLInputElement).value)}
                                        />
                                        {view(this.autocomplete_ctrl)}
                                        <button class="btn btn-default"
                                            tabindex={-1}
                                            onclick={() => this.init()}
                                            type="button">
                                            <span class="glyphicon glyphicon-remove-sign"></span>
                                        </button>
                                    </div>
                                </div>
                                <div class="field">
                                    <label>
                                        Item
                                        <small> (from last half year)</small>
                                    </label>
                                    {this.loading_items ? <Spinner /> :
                                        <select
                                            required={true}
                                            onchange={(ev: Event) => this.set_item(+(ev.target as HTMLSelectElement).value)}>
                                            <option selected value="">-</option>
                                            {this.creditable_sales_order_items() &&
                                                this.creditable_sales_order_items().map((sales_order_item: any) => {
                                                    let option_text = sales_order_item.sales_order().reference()
                                                    option_text += ' - ' + (+sales_order_item.price_per_case()).formatMoney() + ' ' + sales_order_item.sales_order().was_sold_in()
                                                    option_text += ' - ' + sales_order_item.item().bottle().to_specs()
                                                    option_text += ' - ' + sales_order_item.item().number_of_bottles_per_case() + ' btl/cs'
                                                    if (sales_order_item.item().gift_box_type()) {
                                                        option_text += ' - ' + sales_order_item.item().gift_box_type()
                                                    }
                                                    if (sales_order_item.item().tax_label()) {
                                                        option_text += ' - ' + sales_order_item.item().tax_label()
                                                    }
                                                    option_text += ' - ' + sales_order_item.number_of_cases() + ' cs'
                                                    return <option
                                                        value={+sales_order_item.artkey()}
                                                        selected={this.sales_order_credit_item().sales_order_item().artkey() === sales_order_item.artkey()}>
                                                        {option_text}
                                                    </option>
                                                })
                                            }
                                        </select>
                                    }
                                </div>
                                {number(this.sales_order_credit_item().number_of_cases, {
                                    label: 'Cases',
                                    required: true,
                                    min: 1,
                                })}
                                <div class="c-field-text field">
                                    <label>Total value ({this.sales_order_credit_item().sales_order_item().sales_order().was_sold_in()})</label>
                                    <input
                                        disabled={true}
                                        value={this.sales_order_credit_item().number_of_cases() * this.sales_order_credit_item().sales_order_item().price_per_case()}
                                    />
                                </div>
                                <div class="field">
                                    <label>{m.trust('&nbsp;')}</label>
                                    <button class="btn btn-success">
                                        {this.loading() ? <Spinner /> : 'Add credit item'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </div>
    }
}
