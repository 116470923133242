/** llm:tested */
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {Supplier} from '@/models/suppliers'
import {$m} from '@/app'

interface DeactivateResponse {
    success: boolean
    result?: {
        can_deactivate: boolean
        supplier_price_lists_count: number
        purchase_orders_count: number
        sales_order_count: number
        supplier: any
    }
}

export class CrmRelationsDeactivate {
    supplier: any
    can_deactivate: any
    supplier_price_lists_count: any
    purchase_orders_count: any
    sales_orders_count: any

    constructor() {
        this.supplier = window.prop(new Supplier())
        this.can_deactivate = window.prop(false)
        this.supplier_price_lists_count = window.prop(false)
        this.purchase_orders_count = window.prop(false)
        this.sales_orders_count = window.prop(false)

        const supplier_artkey = m.route.param('artkey')
        if (supplier_artkey) {
            this.query_supplier(supplier_artkey)
        }
    }

    query_supplier(artkey: string) {
        api.call('suppliers.can_deactivate_supplier', {artkey: artkey}, this.set_supplier.bind(this))
    }

    set_supplier(resp: DeactivateResponse) {
        if (resp.success && resp.result) {
            this.can_deactivate(resp.result.can_deactivate)
            this.supplier_price_lists_count(resp.result.supplier_price_lists_count)
            this.purchase_orders_count(resp.result.purchase_orders_count)
            this.sales_orders_count(resp.result.sales_order_count)
            this.supplier(new Supplier(resp.result.supplier))
        }
    }

    deactivate_supplier() {
        api.call('suppliers.deactivate_supplier',
            {artkey: this.supplier().artkey()},
            this.handle_deactivate_supplier.bind(this))
    }

    handle_deactivate_supplier(resp: { success: boolean }) {
        if (resp.success) {
            $m.common.observable.broadcast('suppliers_updated', '')
            notifier.notify(`Successfully deactivated relation ${this.supplier().name()}.`, 'success')
            m.route.set('/crm/relations')
        } else {
            notifier.notify(`Deleting of relation ${this.supplier().name()} failed.`, 'danger')
        }
    }

    view() {
        return <div class="deactivate-supplier view">
            <div class="row" id="button-bar">
                <div class="btn-toolbar">
                    <button class="btn btn-link" type="button"
                        onclick={() => m.route.set(`/crm/relations/${this.supplier().artkey()}/edit`)}>
                        <span class="glyphicon glyphicon-arrow-left"></span> Back to relation
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <h2>Deactivate relation</h2>
                </div>
            </div>

            {this.can_deactivate() ?
                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="alert alert-info">
                            Would you like to deactivate the following relation?
                        </div>
                    </div>
                </div>
                :
                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="alert alert-warning">
                            The following relation <strong>cannot be deactivated</strong> because it has:
                            <ul>
                                {this.supplier_price_lists_count() > 0 &&
                                    <li>{this.supplier_price_lists_count()} price lists</li>
                                }
                                {this.purchase_orders_count() > 0 &&
                                    <li>{this.purchase_orders_count()} purchase orders</li>
                                }
                                {this.sales_orders_count() > 0 &&
                                    <li>{this.sales_orders_count()} sales orders</li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            }

            <div class="row">
                <div class="col-md-6 col-xs-12">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4>{this.supplier().name()}</h4>
                        </div>
                        <div class="panel-body">
                            <dl class="dl-horizontal">
                                <dt>Name</dt>
                                <dd>{this.supplier().name()}</dd>
                                <dt>Email</dt>
                                <dd>{this.supplier().emailaddress()}</dd>
                                <dt>URL</dt>
                                <dd>{this.supplier().url()}</dd>
                                <dt>Address</dt>
                                <dd>{this.supplier().street_address()}</dd>
                                <dt>Zip code</dt>
                                <dd>{this.supplier().zip_code()}</dd>
                                <dt>City</dt>
                                <dd>{this.supplier().city()}</dd>
                                <dt>Country</dt>
                                <dd>{this.supplier().country_code()}</dd>
                                <dt>Company Type</dt>
                                <dd>{this.supplier().company_type()}</dd>
                                <dt>Client Status</dt>
                                <dd>{this.supplier().client_status()}</dd>
                                <dt>Purchase manager</dt>
                                <dd>{this.supplier().purchase_manager().profile().name()}</dd>
                                <dt>Sales manager</dt>
                                <dd>{this.supplier().sales_manager().profile().name()}</dd>
                                <dt>Relation nr</dt>
                                <dd>{this.supplier().relation_nr()}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-3 col-xs-6">
                    <button class="form-control btn btn-default"
                        onclick={() => m.route.set(`/crm/relations/${this.supplier().artkey()}/edit`)}>
                        Cancel
                    </button>
                </div>
                {this.can_deactivate() &&
                    <div class="col-md-3 col-xs-6">
                        <button class="form-control btn btn-success"
                            onclick={this.deactivate_supplier.bind(this)}
                            id="deactivate">
                            Deactivate
                        </button>
                    </div>
                }
            </div>
        </div>
    }
}
