/** llm:production */
import m from 'mithril'
import {filter} from 'prelude-ls'
import {Button} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import * as inputs from '@/components/inputs'
import {items_table} from '@/stock/components/items_table'
import {ItemForm} from '@/stock/components/item_form'
import {Item, ItemMutationReason} from '@/models/stock'
import {$m, $s} from '@/app'

export default class MutationCreateUpdateProduct {
    item: any
    items: any
    base_item_json: any
    new_items: any
    reason: any
    description: any
    update_type: string

    constructor() {
        const item_reference = m.route.param('reference')
        this.update_type = m.route.param('update_type')

        this.item = window.prop(new Item())
        this.items = window.prop([])
        this.base_item_json = window.prop({})

        this.new_items = window.prop([])

        this.reason = window.prop('')
        this.description = window.prop('')

        if (this.update_type === 'correct-bottles') {
            this.reason(ItemMutationReason.CORRECT_BOTTLES_PER_CASE)
        }

        this.get_item(item_reference)
    }

    create_base_item(json: any) {
        this.base_item_json(json)
        this.base_item_json().lot = ''
        this.base_item_json().number_of_cases_in_stock = ''
    }

    get_item(item_reference: string) {
        const data = {
            item_reference: item_reference,
        }

        api.call2('stock.get_item', data, (resp: any) => {
            this.item(new Item(resp.result))
            this.items([this.item()])
            this.new_items([window.prop(new Item(resp.result))])
            this.create_base_item(resp.result)
        })
    }

    create_mutation(e: Event) {
        e.preventDefault()

        if (this.new_items().length === 0) {
            notifier.notify('Cannot update product, please create items in updated stock.', 'info')
            return
        }
        const empty_items = filter((item: any) => item().country_of_origin() === null || item().country_of_origin() === '', this.new_items())
        if (empty_items.length > 0) {
            notifier.notify('Cannot update product, country of origin is a required field.', 'info')
            return
        }

        const new_items = this.new_items().map((item: any) => item().toObject())

        const data = {
            user_artkey: $s.identity.artkey,
            item_mutation_reason: this.reason(),
            description: this.description(),
            item_artkey: +this.item().artkey(),
            new_items: new_items,
        }

        api.call2('stock.create_item_mutation_update_product', data, (resp: any) => {
            $m.common.observable.broadcast('stock_updated', '')
            notifier.notify('Created mutation successfully', 'success')
            m.route.set(`/stock/mutations/manage/${resp.result}`)
        })
    }

    fields() {
        if (this.update_type === 'correct-bottles') {
            return ['number_of_bottles_per_case']
        } else {
            return [
                'customs_status',
                'gift_box_type',
                'lot',
                'number_of_bottles_per_case',
                'product',
                'stock',
            ]
        }
    }

    view() {
        return (
            <div class="c-create-mutation view">
                <div class="btn-toolbar">
                    <Button
                        active={false}
                        icon="back"
                        onclick={() => m.route.set(`/stock/manage/${this.item().reference()}`)}
                        variant="toggle"
                    />
                </div>

                {this.update_type === 'correct-bottles' ? (
                    <h2>Correct number of bottles per case</h2>
                ) : (
                    <h2>Update product</h2>
                )}

                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <div class="panel-title">Current stock</div>
                    </div>
                    <div class="panel-body">
                        {items_table(this.items)}
                    </div>
                </div>

                <form class="flex-form full-width" onsubmit={this.create_mutation.bind(this)}>
                    <ItemForm
                        new_items={this.new_items}
                        base_item_json={this.base_item_json}
                        fields={this.fields()}
                        reason={this.reason}
                        update_type={this.update_type}
                    />

                    {this.update_type !== 'correct-bottles' && (
                        <div class="field">
                            <label for="type">Reason</label>
                            {inputs.select(this.reason, ItemMutationReason.all, {required: true})}
                        </div>
                    )}

                    <div class="field">
                        <label for="description">Description</label>
                        {inputs.textarea(this.description, {required: true})}
                    </div>

                    <div class="btn-toolbar">
                        <Button
                            class_name="btn-submit"
                            onclick={this.create_mutation.bind(this)}
                            text="Update Product"
                            type="success"
                        />
                    </div>
                </form>
            </div>
        )
    }
}
