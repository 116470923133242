/** llm:tested */
import m from 'mithril'
import {FieldSelect} from '@bitstillery/common/components'
import {supported_portal_languages} from '@bitstillery/common/lib/i18n'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {button_with_icon} from '@/components/_buttons'
import {contact_person_link, relation_link} from '@/components/entity_links'
import inputs from '@/components/inputs'
import {PortalUser} from '@/portal/models'
import {ContactPersonDropdown} from '@/components/contact_person'
import {UserManagementApi} from '@/factserver_api/user_management_api'

export class PortalUserUpsert extends MithrilTsxComponent<any> {
    user: PortalUser
    repeat_password: any
    _custom_password: boolean
    _generate_password: boolean
    user_management_api: UserManagementApi
    generate_password: any
    custom_password: any
    base_url: string
    is_email: boolean
    create: any
    reset_otp: any
    delete_user: any
    generate_invite_code: any

    constructor() {
        super()
        this.user = new PortalUser()
        this.repeat_password = window.prop('')

        this._custom_password = false
        this._generate_password = false
        this.user_management_api = new UserManagementApi()

        this.generate_password = (value?: boolean) => {
            if (value !== undefined) {
                this._generate_password = value
                if (value && this._custom_password) {
                    this._custom_password = false
                }
            }
            return this._generate_password
        }

        this.custom_password = (value?: boolean) => {
            if (value !== undefined) {
                this._custom_password = value
                if (value && this._generate_password) {
                    this._generate_password = false
                } else if (!value && this.create()) {
                    this._generate_password = true
                }
            }
            return this._custom_password
        }

        this.base_url = '/portal/portal-users'

        const artkey = m.route.param('artkey')
        if (artkey) {
            this.is_email = false
            this.create = window.prop(false)
            const data = {artkey: artkey}

            api.callAndThen('portal.user_management.get_user', data, {
                success: (resp: any) => {
                    this.user = new PortalUser(resp.result)
                },
                failure: () => {
                    notifier.notify('Unknown portal user.', 'danger')
                    m.route.set(this.base_url)
                },
            })
        } else {
            this.is_email = true
            this.create = window.prop(true)

            this.generate_password(true)
            this.user.contact_person_artkey(m.route.param('contact_person_artkey'))
        }

        this.reset_otp = window.prop(false)
        this.delete_user = window.prop(false)
        this.generate_invite_code = window.prop(false)
    }

    valid_password() {
        return this.user.password() === this.repeat_password()
    }

    button_text() {
        if (this.create()) {
            return 'Create User'
        } else if (this.delete_user()) {
            return 'Delete User'
        } else {
            return 'Update User'
        }
    }

    valid() {
        if (this.create()) {
            return (this.user.password() && this.valid_password()) || this.generate_password()
        } else {
            return this.valid_password() || this.generate_password()
        }
    }

    save() {
        if (this.delete_user()) {
            const data = {
                artkey: this.user.artkey(),
            }
            api.call('portal.user_management.delete_user', data, this.handle_save.bind(this))
        } else {
            const data = {
                artkey: this.user.artkey(),
                name: this.user.name(),
                language: this.user.language,
                password: this.user.password(),
                contact_person_artkey: this.user.contact_person_artkey(),
                reset_otp: this.reset_otp(),
                generate_password: this.generate_password(),
                generate_invite_code: this.generate_invite_code(),
            }
            api.call('portal.user_management.create_or_update_user', data, this.handle_save.bind(this))
        }
    }

    handle_save(result: any) {
        if (!result.success) {
            if (result.message) {
                notifier.notify(result.message, 'danger')
            } else {
                notifier.notify('An error occurred', 'danger')
            }
        } else {
            if (this.create()) {
                notifier.notify('Successfully created new portal user.', 'success')
            } else {
                if (this.delete_user()) {
                    notifier.notify('Successfully deleted portal user.', 'warning')
                } else {
                    notifier.notify('Successfully updated portal user.', 'success')
                }
            }
            m.route.set(`${this.base_url}`)
        }
    }

    send_password_reset_email() {
        this.user_management_api.request_change_password_email(this.user.artkey()).subscribe({
            next: () => {
                notifier.notify('Email sent to the portal user.', 'success')
            },
            error: () => {
                notifier.notify('Email sending failed.', 'warning')
            },
        })
    }

    view() {
        const a = (...args: any[]) => [...args]

        return <div class="c-portal-user-edit view">
            {a(
                <div class="btn-toolbar">
                    {button_with_icon('Back to list', 'arrow-left', {
                        class: 'btn-default',
                        onclick: () => m.route.set(this.base_url),
                    })}
                    {button_with_icon('Send password reset email', 'envelope', {
                        class: 'btn-default',
                        onclick: () => this.send_password_reset_email(),
                    })}
                </div>,

                <form class="flex-form" onsubmit={(e) => {
                    e.preventDefault()
                    this.save()
                }}>
                    <div class="fieldset">
                        {this.is_email ?
                            inputs.email(this.user.name, {
                                label: this.is_email ? 'Username (e-mail)' : 'Username',
                                required: true,
                            })
                            :
                            inputs.text(this.user.name, {
                                label: 'Username',
                                required: true,
                            })
                        }

                        {!this.create() &&
                            <div class="field">
                                <label>Relation</label>
                                <div>
                                    {relation_link(this.user.contact_person().supplier())}
                                </div>
                            </div>
                        }

                        {this.create() ?
                            <ContactPersonDropdown
                                disabled={!this.create()}
                                label="Contact person"
                                model={[this.user, 'contact_person_artkey']}
                            />
                            :
                            <label>{contact_person_link(this.user.contact_person())}</label>
                        }

                        <FieldSelect
                            label="Portal language"
                            model={[this.user, 'language']}
                            options={supported_portal_languages.map((i) => ({
                                label: i[1],
                                value: i[0],
                            }))}
                            help="Language of the portal, specifically for this portal user"
                            translate={{prefix: ''}}
                        />

                        {!this.create() && a(
                            inputs.checkbox(this.reset_otp, {
                                help: 'On the next login the user must scan the new OTP',
                                label: 'Reset OTP',
                            }),

                            inputs.checkbox(this.delete_user, {
                                help: 'Revoke portal access for this account',
                                label: 'Delete account',
                            }),
                        )}

                        {inputs.checkbox(this.generate_invite_code, {
                            help: 'Generate a new invite code (existing code, if any, will be disabled). The user will not receive an email with this code.',
                            label: 'Generate invite code',
                        })}

                        <div class="field">
                            <button class={this.delete_user() ? 'btn btn-danger btn-submit' : 'btn btn-success btn-submit'}
                                disabled={bool_to_disabled(!this.valid())}>
                                {this.button_text()}
                            </button>
                            {(this.create() && !this.custom_password()) && this.generate_password() &&
                                <div class="field">
                                    A randomly generated password will be sent to specified contact person.
                                </div>
                            }
                        </div>
                    </div>
                </form>,
            )}
        </div>
    }
}

const bool_to_disabled = (value: boolean): string => value ? 'disabled' : ''
