/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericEdit} from '@/data/generic/generic_edit'
import {EnumField, Field} from '@/data/generic/generic_field'
import api from '@/api'
import {$m} from '@/app'

interface VatType {
    artkey(): string
    vat_code(): string
    description(): string
}

export class VatRateUpsert extends MithrilTsxComponent<any> {
    vat_types: any
    fields: any[]

    constructor() {
        super()
        this.vat_types = window.prop([])

        api.call('vattypes.get_all', {}, (resp: any) => {
            this.vat_types(resp.result.map((item: any) => $m.data.vat_type.create(item)))
        })

        this.fields = [
            new Field('Country code', 'country_code', 'input', 'text'),
            new Field('Percentage', 'percentage', 'input', 'number', {min: 0, step: 0.01}),
            new EnumField('VAT Type', 'vat_type_artkey',
                this.vat_types,
                {required: true},
                (vat_type: VatType) => vat_type.artkey(),
                (vat_type: VatType) => `${vat_type.vat_code()} - ${vat_type.description()}`,
            ),
        ]
    }

    view(_vnode: m.Vnode<any>) {
        return <GenericEdit
            readable_entity_name="VAT rate"
            base_url="/data/vatrates"
            query_api_call="vatrates.get"
            update_or_create_api_call="vatrates.update_or_create"
            fields={this.fields}
            create_entity_func={$m.data.vat_rate.create}
            broadcast_on_update="vatrates_updated"
        />
    }
}
