/** llm:tested */
import {ContactPerson} from '@/models/data'

export class PortalUser {
    artkey: Function
    name: Function
    password: Function
    last_seen_timestamp: Function
    contact_person_artkey: Function
    contact_person: Function
    number_of_sessions: Function
    language?: string

    constructor(json: any) {
        this.artkey = window.prop('')
        this.name = window.prop('')
        this.password = window.prop('')
        this.last_seen_timestamp = window.prop('')
        this.contact_person_artkey = window.prop('')
        this.contact_person = window.prop(new ContactPerson({}))
        this.number_of_sessions = window.prop('')

        this.load(json)
    }

    load(json: any) {
        for (const prop in json) {
            if (prop === 'contact_person') {
                this.contact_person_artkey(json[prop].artkey)
                this.contact_person(new ContactPerson(json[prop]))
            } else if (prop === 'language') {
                this.language = json[prop]
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }
    }
}
export class PortalNewsItem {
    artkey: Function
    title: Function
    date: Function
    content: Function
    creator: Function
    creator_artkey: Function

    constructor(json: any) {
        this.artkey = window.prop('')
        this.title = window.prop('')
        this.date = window.prop('')
        this.content = window.prop('')
        this.creator = window.prop({})
        this.creator_artkey = window.prop('')

        for (const prop in json) {
            if (prop === 'creator') {
                this.creator_artkey(json[prop].artkey)
                this.creator(json[prop])
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }
    }
}

export class PortalTip {
    artkey: Function
    title: Function
    content: Function
    creator: Function
    creator_artkey: Function

    constructor(json: any) {
        this.artkey = window.prop('')
        this.title = window.prop('')
        this.content = window.prop('')
        this.creator = window.prop({})
        this.creator_artkey = window.prop('')

        for (const prop in json) {
            if (prop === 'creator') {
                this.creator_artkey(json[prop].artkey)
                this.creator(json[prop])
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }
    }
}

export class PortalQuote {
    artkey: Function
    name: Function
    content: Function
    creator: Function
    creator_artkey: Function
    image_name: Function

    constructor(json: any) {
        this.artkey = window.prop('')
        this.name = window.prop('')
        this.content = window.prop('')
        this.creator = window.prop({})
        this.creator_artkey = window.prop('')
        this.image_name = window.prop('')

        for (const prop in json) {
            if (prop === 'creator') {
                this.creator_artkey(json[prop].artkey)
                this.creator(json[prop])
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }
    }
}
