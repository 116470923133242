/** llm:tested */
import {any, all, head} from 'prelude-ls'
import m from 'mithril'
import {object_to_query_string, strip_empty_values_nested_obj} from '@bitstillery/common/lib/utils'
import {proxy, watch} from '@bitstillery/common/lib/proxy'
import {unique_id} from '@bitstillery/common/lib/utils.ts'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api} from '@bitstillery/common/app'
import {notifier} from '@bitstillery/common/app'

import api_ls from '@/api'
import {a, allEqual, format_date_html5, formatDate, joinMaybes} from '@/_utils'
import {FilterType, BottleFilters, SearchFilter} from '@/market/explore/models'
import {SearchFields} from '@/market/explore/search_fields'
import {ShowPurchaseHistory} from '@/market/explore/show_purchase_history'
import {ShowSalesHistory} from '@/market/explore/show_sales_history'
import {ShowStock} from '@/market/explore/show_stock'
import {ShowSupplierPriceListItems} from '@/market/explore/show_supplier_price_list_items'
import {ShowBottleMarket} from '@/market/explore/show_bottle_market'
import {ShowOfferHistory} from '@/market/explore/show_offer_history'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {$m} from '@/app'

export class MarketExplore extends MithrilTsxComponent<any> {
    totals: any
    loading_price_list_items: any
    loading_historic_price_list_items: any
    loading_offer_history: any
    loading_purchase_history: any
    loading_sales_history: any
    loading_stock_items: any
    historic_price_list_items: any
    offer_history: any
    purchase_history: any
    sales_history: any
    stock_items: any
    search_id: any
    limit: any
    search_filters: any
    start_date: any
    end_date: any
    from_date: any
    to_date: any
    year_date: any
    month_date: any
    _year_input_value: any
    _month_input_value: any
    date_selection_value: any
    account_selection_value: any
    selected_header: any
    selected_filter_type: any
    selected_show_specs: any
    product_management_api: ProductManagementApi
    selected_product_name: any
    selected_supplier_name: any
    selected_bottle_filters: any
    watchers: any[]
    historic_sort_collection: any

    constructor() {
        super()
        this.totals = window.prop({
            purchase_history: {
                total: '',
                count: 0,
            },
            sales_history: {
                total: '',
                margin: '',
                count: 0,
            },
            offer_history: {count: 0},
            stock: {
                count: 0,
                in_stock: 0,
                in_purchase: 0,
                in_sales: 0,
                available: 0,
            },
            pricelist: {count: 0},
            pricelist_history: {count: 0},
        })

        this.loading_price_list_items = window.prop(false)
        this.loading_historic_price_list_items = window.prop(false)
        this.loading_offer_history = window.prop(false)
        this.loading_purchase_history = window.prop(false)
        this.loading_sales_history = window.prop(false)
        this.loading_stock_items = window.prop(false)
        this.historic_price_list_items = window.prop([])
        this.offer_history = window.prop([])
        this.purchase_history = window.prop([])
        this.sales_history = window.prop([])
        this.stock_items = window.prop([])
        this.search_id = window.prop(unique_id())

        this.limit = window.prop(10)
        this.search_filters = window.prop([new SearchFilter()])

        this.start_date = window.prop('')
        this.end_date = window.prop('')
        this.from_date = window.prop('')
        this.to_date = window.prop('')
        this.year_date = window.prop('')
        this.month_date = window.prop('')
        this._year_input_value = window.prop('')
        this._month_input_value = window.prop('')
        this.date_selection_value = window.prop('all')
        this.account_selection_value = window.prop('')

        this.selected_header = window.prop('')
        this.selected_filter_type = window.prop('')
        this.selected_show_specs = window.prop(false)

        this.product_management_api = new ProductManagementApi()

        this.selected_product_name = window.prop('')
        this.selected_supplier_name = window.prop('')
        this.selected_bottle_filters = window.prop(new BottleFilters())

        this.watchers = []
        this.historic_sort_collection = proxy({
            state: {
                ready: false,
                sort: {
                    options: [['start_date', 'Start date'], ['end_date', 'End date']],
                    by: 'end_date',
                    order: 'desc',
                },
            },
        })

        this.watchers.push(
            watch(this.historic_sort_collection.state.sort, 'by', () => this.get_historic_price_list_items()),
            watch(this.historic_sort_collection.state.sort, 'order', () => this.get_historic_price_list_items()),
        )

        this.year(new Date().getFullYear())
        this.month(new Date())
    }

    async oncreate() {
        const q_case = m.route.param('case')
        const q_supplier = m.route.param('supplier')
        const q_year = m.route.param('year')

        if (q_year) {
            this.year(q_year)
            this.date_selection_value('year')
        }

        if (q_case || q_supplier) {
            const data = {
                case_artkey: q_case,
                supplier_artkey: q_supplier,
            }

            const query_str = object_to_query_string(strip_empty_values_nested_obj(data))
            const resp = await api.get('discover/explore/get-case-and-supplier?' + query_str)

            if (resp.success) {
                this.set_case_and_supplier(resp.result)
                const search_filter = this.search_filters()[0]
                this.product_management_api.get_bottles_for_product(search_filter.product().artkey()).subscribe({
                    next: (bottles) => {
                        search_filter.volume_options([...new Set(bottles.map((bottle) => +bottle.volume))].sort((a, b) => a - b))
                        search_filter.alcohol_percentage_options([...new Set(bottles.map((bottle) => +bottle.alcohol_percentage))].sort((a, b) => a - b))
                        search_filter.refill_status_options([...new Set(bottles.map((bottle) => bottle.refill_status))].sort())
                    },
                })
            } else {
                $m.common.generic_error_handler()
            }
        }
    }

    onremove() {
        this.watchers.map((watcher) => watcher())
    }

    year(value?: string | number) {
        if (value !== undefined) {
            this._year_input_value(value)
            this.year_date(new Date(+value, 0, 1))
        } else {
            return this._year_input_value()
        }
    }

    month(value?: Date) {
        if (value !== undefined) {
            this._month_input_value(value.getMonthInputFormat())
            this.month_date(value)
        } else {
            return this._month_input_value()
        }
    }

    set_case_and_supplier(result: any) {
        const search_filter = new SearchFilter()

        if (result.supplier) {
            search_filter.supplier().artkey(result.supplier.artkey)
            search_filter.supplier().name(result.supplier.name)
            search_filter.selected_relations().push(result.supplier)
        }

        if (result.case) {
            search_filter.case().number_of_bottles(result.case.case_number_of_bottles)
            search_filter.case().tax_label(result.case.tax_label)
            search_filter.case().gift_box_type(result.case.gift_box_type)
            search_filter.bottle().volume(+result.case.volume)
            search_filter.bottle().alcohol_percentage(+result.case.alcohol_percentage)
            search_filter.bottle().refill_status(result.case.refill_status)
            search_filter.product_name(result.case.name)
            search_filter.product().artkey(result.case.product_artkey)
            search_filter.product().name(result.case.product_name)
        }

        this.search_filters([search_filter])
        this.update()
    }

    date_selection(value?: string) {
        if (value !== undefined) {
            this.date_selection_value(value)
        } else {
            return this.date_selection_value()
        }
    }

    account_selection(value?: string) {
        if (value !== undefined) {
            this.account_selection_value(value)
        } else {
            return this.account_selection_value()
        }
    }

    set_year_dates() {
        const date = this.year_date()
        this.start_date(new Date(date.getFullYear(), 0, 1))
        this.end_date(new Date(date.getFullYear() + 1, 0, 0))
    }

    set_month_dates() {
        const date = this.month_date()
        this.start_date(new Date(date.getFullYear(), date.getMonth(), 1))
        this.end_date(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    }

    set_start_and_end_date() {
        switch (this.date_selection_value()) {
        case 'all':
            this.start_date('')
            this.end_date('')
            break
        case 'year':
            this.set_year_dates()
            break
        case 'month':
            this.set_month_dates()
            break
        case 'range':
            this.start_date(this.from_date())
            this.end_date(this.to_date())
            break
        }
    }

    _build_query_string(page_index?: number) {
        const filters = {
            product_filters: this.search_filters().map(sf => sf.to_json()),
            start_date: format_date_html5(this.start_date()),
            end_date: format_date_html5(this.end_date()),
            account_slug: this.account_selection_value(),
            limit: this.limit(),
            offset: page_index ? this.limit() * (page_index - 1) : undefined,
        }

        return object_to_query_string(strip_empty_values_nested_obj(filters, ['tax_label']))
    }

    get_query() {
        return {
            start_date: format_date_html5(this.start_date()),
            end_date: format_date_html5(this.end_date()),
            account_slug: this.account_selection_value(),
            filters: this.search_filters().map(sf => sf.to_json()),
        }
    }

    is_valid_filter(search_filters: any[]) {
        return any((f: any) =>
            f.product().artkey() || f.supplier().artkey() || f.selected_relations().length,
        search_filters,
        )
    }

    all_equal_and_not_empty(list: any[]) {
        return allEqual(list) && head(list)
    }

    have_constant_product() {
        return this.all_equal_and_not_empty(
            this.search_filters().map(sf => sf.product().artkey()),
        )
    }

    have_constant_supplier() {
        return this.all_equal_and_not_empty(
            this.search_filters().map(sf => sf.selected_relations()[0]?.artkey),
        )
    }

    have_constant_specs() {
        return allEqual(this.search_filters().map(sf => sf.bottle().to_json()))
    }

    have_incomplete_specs() {
        return !all(sf => sf.bottle().is_full(), this.search_filters())
    }

    get_filter_type() {
        const has_supplier = this.search_filters()[0]?.selected_relations()[0]?.artkey

        if (this.have_constant_product() && has_supplier) {
            return FilterType.BOTH
        } else if (this.have_constant_product()) {
            return FilterType.PRODUCT
        } else if (has_supplier) {
            return FilterType.RELATION
        } else {
            return FilterType.VARIOUS
        }
    }

    get_header(filter_type: FilterType, search_filters: any[]) {
        const supplier = search_filters[0].supplier().name()
        const product = joinMaybes(' ', [
            search_filters[0].product().name(),
            search_filters[0].bottle().show(),
            search_filters[0].product().category(),
        ])

        if (filter_type === FilterType.BOTH) {
            return `${product} from ${supplier}`
        } else if (filter_type === FilterType.RELATION) {
            return supplier
        } else if (filter_type === FilterType.PRODUCT) {
            return product
        } else {
            return 'Various products and/or relations'
        }
    }

    async get_table_totals(table_name: string, filters: any) {
        const {result: totals} = await api.get(
            'discover/explore/' + table_name.replace('_', '-') + '/totals?' + object_to_query_string(filters),
        )
        this.totals()[table_name] = totals
        m.redraw()
    }

    async get_all_totals() {
        const filters = {
            product_filters: this.search_filters().map(sf => sf.to_json()),
            start_date: format_date_html5(this.start_date()),
            end_date: format_date_html5(this.end_date()),
            account_slug: this.account_selection_value(),
        }

        const stripped_filters = strip_empty_values_nested_obj(filters, ['tax_label'])

        await Promise.all([
            this.get_table_totals('purchase_history', stripped_filters),
            this.get_table_totals('sales_history', stripped_filters),
            this.get_table_totals('offer_history', stripped_filters),
            this.get_table_totals('stock', stripped_filters),
            this.get_table_totals('pricelist_history', stripped_filters),
        ])
    }

    async get_purchase_history(page_index?: number) {
        this.loading_purchase_history(true)

        const query_str = this._build_query_string(page_index)
        const {result: purchase_history} = await api.get('discover/explore/purchase-history?' + query_str)
        this.purchase_history(purchase_history || [])

        this.loading_purchase_history(false)
        m.redraw()
    }

    async get_sales_history(page_index?: number) {
        this.loading_sales_history(true)

        const query_str = this._build_query_string(page_index)
        const {result: sales_history} = await api.get('discover/explore/sales-history?' + query_str)
        this.sales_history(sales_history || [])

        this.loading_sales_history(false)
        m.redraw()
    }

    async get_stock_items(page_index?: number) {
        this.loading_stock_items(true)

        const query_str = this._build_query_string(page_index)
        const {result: stock} = await api.get('discover/explore/stock?' + query_str)
        this.stock_items(stock || [])

        this.loading_stock_items(false)
        m.redraw()
    }

    async get_historic_price_list_items(page_index?: number) {
        this.loading_price_list_items(true)

        let query_str = this._build_query_string(page_index)
        query_str += '&sort_by=' + this.historic_sort_collection.state.sort.by +
                    '&sort_order=' + this.historic_sort_collection.state.sort.order

        const {result: historic_price_list_items} = await api.get('discover/explore/pricelist-history?' + query_str)
        this.historic_price_list_items(historic_price_list_items || [])

        this.historic_sort_collection.state.ready = true
        this.loading_price_list_items(false)
        m.redraw()
    }

    async get_offer_history(page_index?: number) {
        this.loading_offer_history(true)
        this.offer_history([])

        const query_str = this._build_query_string(page_index)
        const {result: offer_history} = await api.get('discover/explore/offer-history?' + query_str)
        this.offer_history(offer_history || [])

        this.loading_offer_history(false)
        m.redraw()
    }

    async update() {
        this.search_id(unique_id())
        if (!this.is_valid_filter(this.search_filters())) {
            notifier.notify('Please select at least a product or a relation.', 'danger')
            return
        }

        const filter_type = this.get_filter_type()
        this.selected_show_specs(!this.have_constant_specs() || this.have_incomplete_specs())
        this.selected_header(this.get_header(filter_type, this.search_filters()))
        this.clear_data()

        if (this.have_constant_supplier()) {
            this.selected_supplier_name(this.search_filters()[0].supplier().name())
        } else {
            this.selected_supplier_name(undefined)
        }

        if (this.have_constant_product()) {
            this.selected_product_name(this.search_filters()[0].product().name())
        } else {
            this.selected_product_name(undefined)
        }

        if (this.have_constant_specs()) {
            this.selected_bottle_filters(this.search_filters()[0].bottle().clone())
        } else {
            this.selected_bottle_filters(new BottleFilters())
        }

        this.set_start_and_end_date()

        await Promise.all([
            this.get_all_totals(),
            this.get_purchase_history(),
            this.get_sales_history(),
            this.get_offer_history(),
            this.get_stock_items(),
            this.get_historic_price_list_items(),
        ])

        this.selected_filter_type(filter_type)
        this.selected_header(this.get_header(filter_type, this.search_filters()))
        m.redraw()
    }

    reset() {
        this.search_filters([new SearchFilter()])
        this.start_date(undefined)
        this.end_date(undefined)
        this.date_selection_value('all')
        this.account_selection_value(undefined)
        this.clear_data()
        this.search_id(unique_id())
        m.redraw()
    }

    clear_data() {
        this.historic_price_list_items([])
        this.offer_history([])
        this.purchase_history([])
        this.sales_history([])
        this.stock_items([])
        this.totals({
            purchase_history: {
                total: '',
                count: 0,
            },
            sales_history: {
                total: '',
                margin: '',
                count: 0,
            },
            offer_history: {count: 0},
            stock: {
                count: 0,
                in_stock: 0,
                in_purchase: 0,
                in_sales: 0,
                available: 0,
            },
            pricelist: {count: 0},
            pricelist_history: {count: 0},
        })
    }

    async download_sales_history_excel() {
        const data = {
            query: this.get_query(),
        }
        const resp = await api_ls.call('explore.export_sales_history_to_excel', data)
        if (resp.success) {
            a.download_binary_file_from_base64_str(
                resp.result,
                'Explore - Sales History ' + formatDate(new Date()) + '.xlsx',
            )
        } else {
            $m.common.generic_error_handler()
        }
    }

    async download_purchase_history_excel() {
        const data = {
            query: this.get_query(),
        }
        const resp = await api_ls.call('explore.export_purchase_history_to_excel', data)
        if (resp.success) {
            a.download_binary_file_from_base64_str(
                resp.result,
                'Explore - Purchase History ' + formatDate(new Date()) + '.xlsx',
            )
        } else {
            $m.common.generic_error_handler()
        }
    }

    view() {
        return <div class="c-market-explore view">
            <SearchFields
                search_filters={this.search_filters}
                date_selection={this.date_selection.bind(this)}
                account_selection={this.account_selection.bind(this)}
                month={this.month.bind(this)}
                year={this.year.bind(this)}
                from_date={this.from_date}
                to_date={this.to_date}
                limit={this.limit}
                reset={this.reset.bind(this)}
                update={this.update.bind(this)}
                key="search_fields"
            />

            <div key={this.search_id()}>
                <ShowPurchaseHistory
                    title="Purchase history"
                    unique_name="purchase_history"
                    purchase_history={this.purchase_history()}
                    purchase_history_total={this.totals()['purchase_history']['total']}
                    download_callback={this.download_purchase_history_excel.bind(this)}
                    limit={this.limit()}
                    count={() => this.totals()['purchase_history']['count']}
                    fetch_page={this.get_purchase_history.bind(this)}
                    filter_type={this.selected_filter_type()}
                    header={this.selected_header()}
                    show_specs={this.selected_show_specs()}
                    loading={this.loading_purchase_history()}
                    search_filters={this.search_filters().map((sf) => sf.to_json())}
                    collapsible={true}
                    collapsed={false}
                />
                <ShowSalesHistory
                    title="Sales history"
                    unique_name="sales_history"
                    sales_history={this.sales_history()}
                    sales_history_total={this.totals()['sales_history']['total']}
                    download_callback={this.download_sales_history_excel.bind(this)}
                    limit={this.limit()}
                    count={() => this.totals()['sales_history']['count']}
                    fetch_page={this.get_sales_history.bind(this)}
                    filter_type={this.selected_filter_type()}
                    header={this.selected_header()}
                    show_specs={this.selected_show_specs()}
                    loading={this.loading_sales_history()}
                    search_filters={this.search_filters().map((sf) => sf.to_json())}
                    collapsible={true}
                    collapsed={false}
                />
                <ShowStock
                    title="Stock"
                    unique_name="stock"
                    description="Ignores the selected date."
                    stock={this.stock_items}
                    limit={this.limit}
                    stock_totals={this.totals()['stock']}
                    count={() => this.totals()['stock']['count']}
                    fetch_page={this.get_stock_items.bind(this)}
                    filter_type={this.selected_filter_type}
                    header={this.selected_header}
                    show_specs={this.selected_show_specs}
                    loading={this.loading_stock_items.bind(this)}
                    collapsible={true}
                    collapsed={false}
                />
                <ShowBottleMarket
                    title="Pricelists (active)"
                    unique_name="price_list_items"
                    description="Ignores the selected date and tax label."
                    search_filters={this.search_filters()}
                    filter_type={this.selected_filter_type()}
                    collapsible={true}
                    collapsed={false}
                />
                <ShowOfferHistory
                    offer_history_items={this.offer_history()}
                    fetch_page={this.get_offer_history.bind(this)}
                    loading={this.loading_offer_history()}
                    description="Ignores the selected account slug."
                    count={this.totals()['offer_history']['count']}
                    limit={this.limit()}
                    collapsible={true}
                    collapsed={false}
                    header={this.selected_header()}
                />
                <ShowSupplierPriceListItems
                    title="Pricelists (historic)"
                    unique_name="historic_price_list_items"
                    description="Products on inactive pricelists. Ignores the selected tax label."
                    supplier_price_list_items={this.historic_price_list_items}
                    product_name={this.selected_product_name}
                    supplier_name={this.selected_supplier_name}
                    bottle_filters={this.selected_bottle_filters}
                    limit={this.limit}
                    count={() => this.totals()['pricelist_history']['count']}
                    fetch_page={this.get_historic_price_list_items.bind(this)}
                    filter_type={this.selected_filter_type}
                    header={this.selected_header}
                    show_specs={this.selected_show_specs}
                    loading={this.loading_historic_price_list_items}
                    collapsible={true}
                    collapsed={true}
                    sort_collection={this.historic_sort_collection}
                />
            </div>
        </div>
    }
}
