import m from 'mithril'
import {AccountSlug} from '@bitstillery/common/account/account'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t, notifier} from '@bitstillery/common/app'
import {FieldSelect, FieldText} from '@bitstillery/common/components'

import {$s} from '@/app'
import {CheckBox, CountriesMultiSelect} from '@/components/html_components'
import {EditAssist, Form} from '@/components/form'
import {NumberInput} from '@/components/input_numbers'
import {UpdateUserRequest, UserManagementApi} from '@/factserver_api/user_management_api'
import {User} from '@/factserver_api/user_api'

export interface UserDetailsAttrs {
    api: UserManagementApi
    update_user_request: UpdateUserRequest
    edit_assist: EditAssist
    logged_in_user: User
    is_self: boolean
    check_disabled: (allow_if_self?: boolean) => boolean
    LIST_ROUTE: string
}

export default class UserDetails extends MithrilTsxComponent<any> {
    change_super_rights(update_user_request: UpdateUserRequest, is_self: boolean): void {
        if (is_self) {
            notifier.notify('Unable to change rights: You cannot change your own super rights', 'danger')
            return
        }

        update_user_request.is_superuser = !update_user_request.is_superuser
    }

    change_trader_rights(update_user_request: UpdateUserRequest): void {
        update_user_request.is_trader = !update_user_request.is_trader
        update_user_request.trading_countries = []
        update_user_request.user_accounts = []
    }

    change_user_accounts(account_variable: string, update_user_request: UpdateUserRequest): void {
        if (update_user_request.user_accounts.includes(account_variable)) {
            update_user_request.user_accounts = update_user_request.user_accounts.filter((x) => x !== account_variable)
        } else {
            update_user_request.user_accounts.push(account_variable)
        }
    }

    update_or_create_user(vnode: m.Vnode<UserDetailsAttrs>): boolean {
        if (!vnode.attrs.update_user_request) {
            notifier.notify('Unable to save user: Something went wrong please reload this page', 'danger')
            return false
        }

        if (!this.validate_email(vnode.attrs.update_user_request.profile.emailaddress)) {
            notifier.notify('Unable to save user: Invalid email address given', 'danger')
            return false
        }

        if (
            vnode.attrs.update_user_request.profile.a2bc_emailaddress &&
            (!this.validate_email(vnode.attrs.update_user_request.profile.emailaddress) ||
            vnode.attrs.update_user_request.profile.a2bc_emailaddress.split('@')[1] !== 'a2bc.com')
        ) {
            notifier.notify('Unable to save user: Invalid a2bc email address given', 'danger')
            return false
        }

        vnode.attrs.api.update_or_create_user(vnode.attrs.update_user_request).subscribe({
            next: () => {
                m.route.set(vnode.attrs.LIST_ROUTE)
                notifier.notify(vnode.attrs.edit_assist.is_creating ? 'User created' : 'User updated', 'success')
                // TODO Replace with logged in user once decimal local implemented there
                $s.identity.user.decimal_locale = vnode.attrs.update_user_request.decimal_locale
            },
            error: () => {
                m.redraw()
            },
        })
        return false
    }

    validate_email(email: string): boolean {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    view(vnode: m.Vnode<UserDetailsAttrs>): m.Children {
        return (
            <Form
                onSubmit={() => this.update_or_create_user(vnode)}
                submitText={vnode.attrs.edit_assist.is_creating ? 'Create User' : 'Update User'}
                edit_assist={vnode.attrs.edit_assist}
                submit_disabled={vnode.attrs.check_disabled(true)}
            >
                <div className="fieldset-group">
                    <div className="fieldset">
                        <div className="fieldset-label">Profile</div>
                        <FieldText
                            disabled={vnode.attrs.check_disabled()}
                            label="Username"
                            model={[vnode.attrs.update_user_request, 'name']}
                            oninput={(value: string) => (vnode.attrs.update_user_request.name = value.toLowerCase())}
                            placeholder={'Insert a username'}
                        />

                        <FieldText
                            disabled={vnode.attrs.check_disabled(true)}
                            label="Name"
                            placeholder={'Insert a name'}
                            model={[vnode.attrs.update_user_request.profile, 'name']}
                        />

                        <FieldText
                            disabled={vnode.attrs.check_disabled(true)}
                            help="Address for MSI-related emails"
                            label="Email"
                            model={[vnode.attrs.update_user_request.profile, 'emailaddress']}
                            placeholder={'Insert an email address'}
                        />

                        <FieldText
                            label="Address"
                            disabled={vnode.attrs.check_disabled(true)}
                            model={[vnode.attrs.update_user_request.profile, 'address']}
                            placeholder={'Insert a living address'}
                        />

                        <div className="field-group">
                            <FieldText
                                disabled={vnode.attrs.check_disabled(true)}
                                label="Telephone number"
                                model={[vnode.attrs.update_user_request.profile, 'telephone_number']}
                                placeholder={'Insert a landline number'}
                            />

                            <FieldText
                                disabled={vnode.attrs.check_disabled(true)}
                                label="Mobile number"
                                model={[vnode.attrs.update_user_request.profile, 'mobile_telephone_number']}
                                placeholder={'Insert a mobile phone number'}
                            />
                        </div>

                        {vnode.attrs.is_self && <FieldSelect
                            label="Decimal locale"
                            model={[vnode.attrs.update_user_request, 'decimal_locale']}
                            onchange={(value: string) => (vnode.attrs.update_user_request.decimal_locale = value)}
                            options={[
                                {label: 'EN: Dot (.) as decimal separator', value: 'en'},
                                {label: 'NL: Comma (,) as decimal separator', value: 'nl'},
                            ]}
                        />}

                        <FieldText
                            disabled={vnode.attrs.check_disabled(true)}
                            label={$t('data_user_manage.profile.label.gitlab')}
                            placeholder="Enter a Gitlab username to receive feedback"
                            model={[vnode.attrs.update_user_request.profile, 'gitlab_username']}
                        />
                    </div>

                    {vnode.attrs.admin_view && $s.identity.user.is_superuser && <div className="fieldset">
                        <div className="fieldset-label">Permissions</div>

                        <CheckBox
                            help="Helps relations with their sales orders"
                            label="Trader"
                            checked={vnode.attrs.update_user_request.is_trader}
                            onchange={() => this.change_trader_rights(vnode.attrs.update_user_request)}
                        />

                        {vnode.attrs.update_user_request.is_trader && <CountriesMultiSelect
                            label="Trading countries"
                            model={vnode.attrs.update_user_request.trading_countries}
                        />}

                        <CheckBox
                            help="Purchases goods for stock & relation orders"
                            label="Purchaser"
                            checked={vnode.attrs.update_user_request.is_purchaser}
                            onchange={() =>
                                (vnode.attrs.update_user_request.is_purchaser =
                                        !vnode.attrs.update_user_request.is_purchaser)
                            }
                        />

                        <CheckBox
                            help="Keeps track of leads & relations"
                            label="CRM Manager"
                            checked={vnode.attrs.update_user_request.is_crm_manager}
                            onchange={() =>
                                (vnode.attrs.update_user_request.is_crm_manager =
                                        !vnode.attrs.update_user_request.is_crm_manager)
                            }
                        />

                        <CheckBox
                            help="Responsible for finalizing Purchaser workflows"
                            label="Stock Manager"
                            checked={vnode.attrs.update_user_request.is_stock_manager}
                            onchange={() =>
                                (vnode.attrs.update_user_request.is_stock_manager =
                                        !vnode.attrs.update_user_request.is_stock_manager)
                            }
                        />
                        <CheckBox
                            help="Administrator privileges in Discover"
                            label="Super User"
                            checked={vnode.attrs.update_user_request.is_superuser}
                            onchange={() => this.change_super_rights(vnode.attrs.update_user_request, vnode.attrs.is_self)}
                            disabled={vnode.attrs.is_self}
                        />

                        {vnode.attrs.admin_view && <FieldText
                            disabled={vnode.attrs.check_disabled()}
                            help="Address for A2BC-related emails"
                            label="A2BC Email"
                            model={[vnode.attrs.update_user_request.profile, 'a2bc_emailaddress']}
                            placeholder="you@a2bc.com"
                        />}

                        {vnode.attrs.admin_view && vnode.attrs.update_user_request.is_trader && <CheckBox
                            help="Receive A2BC-related notifications"
                            label="A2BC notifications"
                            checked={vnode.attrs.update_user_request.user_accounts.includes(AccountSlug.A2BC)}
                            onchange={() =>
                                this.change_user_accounts(AccountSlug.A2BC, vnode.attrs.update_user_request)
                            }
                            disabled={vnode.attrs.check_disabled()}
                        />}

                        {vnode.attrs.admin_view && [
                            <CheckBox
                                help="Mail MSI Bank statements overview"
                                label="ABN MSI mailing"
                                checked={vnode.attrs.update_user_request.get_bank_mail_msi}
                                onchange={() =>
                                    (vnode.attrs.update_user_request.get_bank_mail_msi =
                                        !vnode.attrs.update_user_request.get_bank_mail_msi)
                                }
                            />,

                            <CheckBox
                                help="Mail A2BC Bank statements overview"
                                label="ABN A2BC mailing"
                                checked={vnode.attrs.update_user_request.get_bank_mail_a2bc}
                                onchange={() =>
                                    (vnode.attrs.update_user_request.get_bank_mail_a2bc =
                                        !vnode.attrs.update_user_request.get_bank_mail_a2bc)
                                }
                            />,

                            <CheckBox
                                help="Compensation for remote work & travel costs"
                                label="Compensable"
                                checked={vnode.attrs.update_user_request.is_compensable}
                                onchange={() =>
                                    (vnode.attrs.update_user_request.is_compensable =
                                        !vnode.attrs.update_user_request.is_compensable)
                                }
                            />,

                            vnode.attrs.update_user_request.is_compensable ?
                                <NumberInput
                                    help="Daily travel distance in km"
                                    label="Travel distance"
                                    value={vnode.attrs.update_user_request.daily_travel_distance}
                                    minimum={0}
                                    oninput={(value: number) => {
                                        vnode.attrs.update_user_request.daily_travel_distance = value
                                    }}
                                />
                                : null,
                        ]}
                    </div>}
                </div>
            </Form>
        )
    }
}
