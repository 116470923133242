import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {current_account_slug} from '@bitstillery/common/account/account'
import {Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {AttachmentHelper, AttachmentList, UploadAttachment} from '../components/attachment_list'
import {Link} from '../components/discover'
import {SelectionController, SelectionEnabledComponent} from '../components/selection_controller'
import {EdiMessages} from '../components/edi_messages'

import {SalesOrderBasketItems} from './sales_order_view/basket_items'
import {SalesOrderTBOItems} from './sales_order_view/tbo_items'
import {SalesOrderCreditItems} from './sales_order_view/credit_items'
import {SalesOrderAdditionalItems} from './sales_order_view/additional_items'
import {SalesOrderItems} from './sales_order_view/items'
import {RelationMemo} from './sales_order_view/relation_memo'
import {Details} from './sales_order_view/details'
import {DownloadButtons} from './sales_order_view/download_buttons'
import {StatusButtons} from './sales_order_view/status_buttons'
import {DeliveryButtons} from './sales_order_view/delivery_buttons'
import {SalesOrderHistoricalItems} from './sales_order_view/historical_items'
import {LoenderslootOuttake} from './sales_order_view/loendersloot_outtake'

import {accountIcon} from '@/accounts'
import {Modal} from '@/components/modal/modal'
import {show} from '@/components/confirmation'
import {download_binary_file_from_base64_str, formatDate} from '@/_utils'
import {SalesOrderEmailCorrespondence} from '@/sales_orders/components/sales_order_email_correspondence'
import {InvoiceOrder, InvoiceOrderSalesOrderAttrs} from '@/sales_orders/components/invoice_order'
import {DownloadExcelResponse} from '@/factserver_api/factserver_generic'
import {DangerButton, DefaultButton} from '@/components/buttons'
import {AttachmentType} from '@/factserver_api/attachment_api'
import {
    GetFastSalesOrderWithItemsResponse,
    LoenderslootOuttakeResponse,
    SalesApi,
    SalesOrderItem,
    SalesOrderStatus,
    DownloadPhotosRequest, is_origin_loendersloot,
} from '@/factserver_api/sales_api'
import {CheckBox} from '@/components/html_components'
import {
    GetBuyFromAccountPurchaseOrderResponse,
    GetBuyFromAccountSalesOrderResponse,
} from '@/factserver_api/fact2server_api.ts'
import {auto_attach_buy_from_account_supplier_invoices} from '@/purchase_orders/auto_attach_bfa_attachments.ts'

enum ShowModus {
    ITEMS,
    ATTACHMENTS,
    EMAIL,
    LOENDERSLOOT_REPORT,
    HISTORICAL_ITEMS,
    EDI_MESSAGES = 5,
}

export default class SalesOrderView extends MithrilTsxComponent<unknown> {
    sales_order_artkey: number
    currently_showing = ShowModus.ITEMS
    sales_api = new SalesApi()
    sales_order_response: GetFastSalesOrderWithItemsResponse | null = null
    buy_from_account_sales_orders_response: GetBuyFromAccountSalesOrderResponse[] = []
    buy_from_account_purchase_orders_response: GetBuyFromAccountPurchaseOrderResponse[] = []
    sales_order_to_invoice = false

    attachment_helper: AttachmentHelper

    selection_controller = new SelectionController(['soi', 'tbo'])

    is_loading = false
    is_loading_loendersloot_outtake = false
    is_zipping_photos = false
    loendersloot_outtake: LoenderslootOuttakeResponse | null = null

    show_download_modal = false
    include_attachments = true
    include_item_photos = true

    constructor() {
        super()

        const route_key = m.route.param('artkey')
        if (route_key.startsWith('S')) {
            this.sales_order_artkey = +route_key.substr(1)
            m.route.set(`/sales-orders/manage/${this.sales_order_artkey}`)
        } else {
            this.sales_order_artkey = +m.route.param('artkey')
        }

        this.attachment_helper = new AttachmentHelper(this.sales_order_artkey, AttachmentType.SALES_ORDER)

        const requested_tab = m.route.param('tab')
        if (requested_tab) {
            // @ts-ignore
            this.currently_showing = +requested_tab
        }
    }

    oncreate(): void {
        this._fetch_sales_order_with_items()
        this._fetch_loendersloot_outtake()
    }

    onupdate(): void {
        if (this.sales_order_response && +m.route.param('artkey') !== this.sales_order_artkey) {
            this.sales_order_artkey = +m.route.param('artkey')
            this.sales_order_response = null
            this._fetch_sales_order_with_items()
            this.attachment_helper.load_attachments()
        }
    }

    /* --------------------------------------------------------------------------------
       Fetch io methods
     --------------------------------------------------------------------------------*/
    _fetch_buy_from_account_orders(): void {
        Promise.all([
            this.sales_api.get_buy_from_account_sales_orders(this.sales_order_artkey),
            this.sales_api.get_buy_from_account_purchase_orders(this.sales_order_artkey),
        ],
        ).then((result) => {
            this.buy_from_account_sales_orders_response = result[0]
            this.buy_from_account_purchase_orders_response = result[1]
            m.redraw()
        },
        )
    }

    _fetch_sales_order_with_items(): void {
        this.sales_api
            .get_fast_sales_order_with_items(this.sales_order_artkey)
            .subscribe((response: GetFastSalesOrderWithItemsResponse) => {
                if (response === null || response.account.slug !== current_account_slug()) {
                    notifier.notify('This sales order does not exist', 'warning')
                    m.route.set('/sales-orders/manage')
                    return
                }

                this.sales_order_response = response
                this.sales_order_response.sales_order_items = response.sales_order_items.map((soi: SalesOrderItem) => {
                    soi.item.case.remark = soi.item.remark
                    return soi
                })
                this._fetch_buy_from_account_orders()
                m.redraw()
            })
    }

    _fetch_loendersloot_outtake(): void {
        this.is_loading_loendersloot_outtake = true
        this.sales_api.get_loendersloot_outtake(this.sales_order_artkey).subscribe({
            next: (response: LoenderslootOuttakeResponse) => {
                this.loendersloot_outtake = response
                this.is_loading_loendersloot_outtake = false
                m.redraw()
            },
            error: () => {
                this.is_loading_loendersloot_outtake = false
                m.redraw()
            },
        })
    }

    save_status_update(new_status: SalesOrderStatus): void {
        this.sales_api.set_sales_order_status(this.sales_order_artkey, new_status).subscribe({
            next: () => {
                if (new_status === SalesOrderStatus.INVOICED) {
                    m.route.set(`/sales-orders/manage/${this.sales_order_response?.artkey || ''}/invoice`)
                } else {
                    this._fetch_sales_order_with_items()
                }
            },
        })
    }

    confirm_status_update(new_status: SalesOrderStatus): void {
        if (new_status === SalesOrderStatus.INVOICED) {
            this.sales_order_to_invoice = true
        } else if (
            this.sales_order_response &&
            new_status === SalesOrderStatus.CONFIRMED &&
            this.sales_order_response.basket_items?.length > 0
        ) {
            show({
                title: 'Confirm status change',
                message: `Are you sure you want to set ${
                    this.sales_order_response?.reference || '-'
                } to '${new_status}'?`,
                onconfirm: () =>
                    show({
                        title: 'Unconfirmed basket items',
                        message:
                            'There are still unconfirmed portal basket item(s). These will be removed. Are you sure to confirm?',
                        onconfirm: () => this.save_status_update(new_status),
                        unique_name: 'confirm-status-change-2',
                    }),
                unique_name: 'confirm-status-change',
            })
        } else {
            show({
                title: 'Confirm status change',
                message: `Are you sure you want to set ${
                    this.sales_order_response?.reference || '-'
                } to '${new_status}'?`,
                onconfirm: () => this.save_status_update(new_status),
                unique_name: 'confirm-status-change',
            })
        }
    }

    /* --------------------------------------------------------------------------------
       Util methods
     --------------------------------------------------------------------------------*/
    export_to_excel(): void {
        this.sales_api.export_invoice_to_excel(this.sales_order_artkey).subscribe((response: DownloadExcelResponse) => {
            if (this.sales_order_response) {
                download_binary_file_from_base64_str(
                    response.excel_binary,
                    `Sales order ${this.sales_order_response.supplier.name} ${
                        this.sales_order_response.reference
                    } ${formatDate(new Date())}.xlsx`,
                )
            }
        })
    }

    close_invoice_confirmation(): void {
        this.sales_order_to_invoice = false
        this._fetch_sales_order_with_items()
    }

    sales_order_as_invoice_order(): InvoiceOrderSalesOrderAttrs {
        return {
            artkey: () => this.sales_order_response?.artkey || 0,
            reference: () => this.sales_order_response?.reference || '',
            vat_id: () => this.sales_order_response?.vat_id || '',
            vat_country_code: () => this.sales_order_response?.vat_country_code || '',
            is_vat_shifted: this.sales_order_response?.is_vat_shifted || false,
            destination_location: () => this.sales_order_response?.destination_location || '',
            destination: () => {
                return {name: () => this.sales_order_response?.destination.name || ''}
            },
            origin: () => {
                return {name: () => this.sales_order_response?.origin.name || ''}
            },
            incoterm: () => this.sales_order_response?.incoterm || '',
            incoterm_location: () => this.sales_order_response?.incoterm_location || '',
            insurance_type: () => {
                return {name: () => this.sales_order_response?.insurance_type.name || ''}
            },
            is_buy_from_account_sales_order: () => this.buy_from_account_purchase_orders_response?.length > 0,
        }
    }

    is_cancelled(): boolean {
        return this.sales_order_response?.combined_status === SalesOrderStatus.CANCELLED
    }

    is_invoiced(): boolean {
        return this.sales_order_response?.combined_status === SalesOrderStatus.INVOICED
    }

    switch_tab(new_tab: ShowModus): void {
        this.currently_showing = new_tab
        m.route.set(`/sales-orders/manage/${this.sales_order_artkey}`, {
            ...m.route.param(),
            tab: new_tab,
        })
    }

    download_photos(): void {
        this.is_zipping_photos = true

        let download_request: DownloadPhotosRequest = {
            include_attachments: this.include_attachments,
            include_item_photos: this.include_item_photos,
        }

        this.sales_api.queue_download_all_sales_order_photos(this.sales_order_artkey, download_request).subscribe({
            next: () => {
                notifier.notify(
                    'Successfully queued the sales order photos for download, you will be notified when the zip is ready to be downloaded your <a href="#!/my-discover/downloads" target="_blank">download center</a>.',
                    'success',
                )
                this.is_zipping_photos = false
                this.show_download_modal = false
                m.redraw()
            },
            error: () => {
                this.is_zipping_photos = false
                m.redraw()
            },
        })
    }

    view(): m.Children {
        if (!this.sales_order_response || this.is_loading) return <Spinner/>
        const is_loendersloot = is_origin_loendersloot(this.sales_order_response)
        return (
            <div className="c-sales-orders view">
                {this.sales_order_to_invoice && (
                    <Modal
                        title={`Invoice Sales Order ${this.sales_order_artkey}`}
                        onclose={() => this.close_invoice_confirmation()}
                    >
                        <InvoiceOrder
                            close={() => this.close_invoice_confirmation()}
                            sales_order={() => this.sales_order_as_invoice_order()}
                        />
                    </Modal>
                )}
                {this.show_download_modal && (
                    <Modal
                        title={'Download sales order photos'}
                        onclose={() => this.show_download_modal = false}
                    >
                        <p>Download the photo's associated with the sales order {this.sales_order_response.reference}</p>
                        <b>Include: </b>
                        <CheckBox
                            checked={this.include_attachments}
                            disabled={!this.include_item_photos}
                            onchange={() => this.include_attachments = !this.include_attachments}
                        >Attached photos</CheckBox>
                        <CheckBox
                            checked={this.include_item_photos}
                            disabled={!this.include_attachments}
                            onchange={() => this.include_item_photos = !this.include_item_photos}
                        >Item photos</CheckBox>
                        {!this.is_zipping_photos &&
                                <DefaultButton
                                    title={' Queue download'}
                                    additional_class={'pull-right'}
                                    icon_class={'fas fa-clock'}
                                    onclick={() => {
                                        this.download_photos()
                                    }}
                                />
                        }
                        {this.is_zipping_photos && <div className={'pull-right'}><Spinner/></div>}
                    </Modal>
                )}

                <div className="btn-toolbar">
                    <DefaultButton
                        title=" Back to list"
                        icon_class="glyphicon glyphicon-arrow-left"
                        additional_class="btn-default"
                        onclick={() => m.route.set('/sales-orders/manage')}
                    />
                    {!this.sales_order_response.is_part_of_buy_from_account && <DefaultButton
                        icon_class={'glyphicon glyphicon-edit'}
                        disabled={[SalesOrderStatus.INVOICED, SalesOrderStatus.CANCELLED].includes(
                            this.sales_order_response.combined_status,
                        )}
                        onclick={() =>
                            m.route.set(
                                `/sales-orders/manage/${this.sales_order_response?.artkey || ''}/edit`,
                            )
                        }
                        title={' Edit'}
                    />}
                    <StatusButtons
                        sales_order={this.sales_order_response}
                        purchase_orders={this.buy_from_account_purchase_orders_response}
                        update_status_sales_order={(new_status: SalesOrderStatus) =>
                            this.confirm_status_update(new_status)
                        }
                    />
                    <DownloadButtons
                        sales_order={this.sales_order_response}
                        export_to_excel={() => this.export_to_excel()}
                    />
                    <DefaultButton
                        title={' Download photos'}
                        tooltip={'Download all stock photos and attached product photos'}
                        onclick={() => this.show_download_modal = true}
                        disabled={this.is_zipping_photos}
                        icon_class={'glyphicon glyphicon-download'}
                    />

                    {this.is_invoiced() &&
                        this.sales_order_response.sales_order_credit_items.find(
                            (credit) => !credit.item.lot,
                        ) && (
                        <DefaultButton
                            onclick={() =>
                                m.route.set(`/sales-orders/manage/${this.sales_order_artkey}/instock`)
                            }
                            icon_class={'glyphicon glyphicon-home'}
                            title={' Bring credit items in stock'}
                        />
                    )}
                    {!this.sales_order_response.is_part_of_buy_from_account && <DeliveryButtons
                        sales_order={this.sales_order_response}
                        on_changed={() => this._fetch_sales_order_with_items()}
                    />}

                    {!this.sales_order_response.is_part_of_buy_from_account && <div>
                        {!(this.is_invoiced()) && <DefaultButton
                            icon_class={'glyphicon glyphicon-arrow-right'}
                            onclick={() =>
                                m.route.set(
                                    `/sales-orders/manage/${this.sales_order_artkey}/move-items`,
                                )
                            }
                            title={' Move items'}
                        />}
                    </div>}

                    {!(this.is_cancelled() || this.is_invoiced()) && <DangerButton
                        icon_class={'glyphicon glyphicon-star'}
                        onclick={() => this.confirm_status_update(SalesOrderStatus.CANCELLED)}
                        disabled={this.sales_order_response.pre_advice_reference && !(this.sales_order_response.pre_advice_cancelled_on || this.sales_order_response.pre_advice_confirmed_on)}
                        title={' Cancel order'}
                    />}
                    {
                        this.is_invoiced()
                        && this.buy_from_account_purchase_orders_response
                        && this.buy_from_account_purchase_orders_response.filter((po) => !po.has_supplier_invoice).length > 0
                        && (
                            <DefaultButton
                                icon_class={'glyphicon glyphicon-book'}
                                onclick={() => {
                                    auto_attach_buy_from_account_supplier_invoices(this.sales_order_artkey)
                                    // Unable to find a way to await all updates done by function above, so just assume it worked and update has_supplier_invoice to true
                                    this.buy_from_account_purchase_orders_response.forEach((po) => po.has_supplier_invoice = true)
                                }}
                                title={' Attach buy from account files'}
                                tooltip={'Attach related supplier invoices to buy from account purchase orders'}
                            />
                        )}
                </div>

                {this.sales_order_response?.is_part_of_buy_from_account &&
                <div className={'alert alert-info'}>
                    <span className={'glyphicon glyphicon-info-sign'} />
                    <span>This sales order is part of a buy-from-account action from another reseller.
                        {' '}
                        <Link
                            href={`/sales-orders/manage/${this.sales_order_response.is_part_of_buy_from_account_sales_order_artkey}?account=${this.sales_order_response.is_part_of_buy_from_account_sales_order_account_slug}`}
                        >
                            {this.sales_order_response.is_part_of_buy_from_account_sales_order_reference}{' '}
                            {accountIcon({
                                slug: this.sales_order_response.is_part_of_buy_from_account_sales_order_account_slug || '',
                                name: this.sales_order_response.is_part_of_buy_from_account_sales_order_account_slug || '',
                            })}
                        </Link>

                    </span>
                </div>}

                <div className="columns">
                    <div className="column is-10">
                        <Details
                            sales_order={this.sales_order_response}
                            buy_from_account_purchase_orders_response={this.buy_from_account_purchase_orders_response}
                            buy_from_account_sales_orders_response={this.buy_from_account_sales_orders_response}
                        />
                    </div>
                    <div className="column is-2">
                        <UploadAttachment attachment_helper={this.attachment_helper} />
                    </div>
                </div>

                <RelationMemo sales_order={this.sales_order_response} />

                <ul className={'nav nav-tabs pl-2 mb-2'}>
                    <li
                        role={'presentation'}
                        className={this.currently_showing === ShowModus.ITEMS ? 'active' : ''}
                    >
                        <a
                            href={'#'}
                            onclick={(e: MouseEvent) => {
                                this.switch_tab(ShowModus.ITEMS)
                                e.preventDefault()
                            }}
                        >
                            Items
                        </a>
                    </li>
                    <li
                        role={'presentation'}
                        className={this.currently_showing === ShowModus.ATTACHMENTS ? 'active' : ''}
                    >
                        <a
                            href={'#'}
                            onclick={(e: MouseEvent) => {
                                this.switch_tab(ShowModus.ATTACHMENTS)
                                e.preventDefault()
                            }}
                        >
                            {`Attachments (${this.attachment_helper.attachments.length})`}
                        </a>
                    </li>
                    <li
                        role={'presentation'}
                        className={this.currently_showing === ShowModus.EMAIL ? 'active' : ''}
                    >
                        <a
                            href={'#'}
                            onclick={(e: MouseEvent) => {
                                this.switch_tab(ShowModus.EMAIL)
                                e.preventDefault()
                            }}
                        >
                            {`Email (${this.sales_order_response.number_of_emails})`}
                        </a>
                    </li>
                    {is_loendersloot &&
                        <li
                            role={'presentation'}
                            className={this.currently_showing === ShowModus.EDI_MESSAGES ? 'active' : ''}
                        >
                            <a
                                href={'#'}
                                onclick={(e: MouseEvent) => {
                                    this.switch_tab(ShowModus.EDI_MESSAGES)
                                    e.preventDefault()
                                }}
                            >
                                {`EDI (${this.sales_order_response.number_of_edi_messages})`}
                            </a>
                        </li>
                    }
                    {this.loendersloot_outtake && (
                        <li
                            role={'presentation'}
                            className={this.currently_showing === ShowModus.LOENDERSLOOT_REPORT ? 'active' : ''}
                        >
                            <a
                                href={'#'}
                                onclick={(e: MouseEvent) => {
                                    this.switch_tab(ShowModus.LOENDERSLOOT_REPORT)
                                    e.preventDefault()
                                }}
                            >
                                {'Loendersloot outtake'}
                            </a>
                        </li>
                    )}
                    <li
                        role={'presentation'}
                        className={this.currently_showing === ShowModus.HISTORICAL_ITEMS ? 'active' : ''}
                    >
                        <a
                            href={'#'}
                            onclick={(e: MouseEvent) => {
                                this.switch_tab(ShowModus.HISTORICAL_ITEMS)
                                e.preventDefault()
                            }}
                        >
                            {'Historical items'}
                        </a>
                    </li>
                </ul>

                {this.currently_showing === ShowModus.EMAIL && (
                    <span>
                        <SalesOrderEmailCorrespondence
                            sales_order={() => {
                                return {
                                    artkey: () => this.sales_order_artkey,
                                }
                            }}
                        />
                    </span>
                )}
                {this.currently_showing === ShowModus.ATTACHMENTS && (
                    <span>
                        <AttachmentList attachment_helper={this.attachment_helper} />
                    </span>
                )}

                {this.currently_showing === ShowModus.HISTORICAL_ITEMS && (
                    <span>
                        <SalesOrderHistoricalItems sales_order={this.sales_order_response} />
                    </span>
                )}

                {this.currently_showing === ShowModus.LOENDERSLOOT_REPORT && (
                    <span>
                        <LoenderslootOuttake loendersloot_outtake={this.loendersloot_outtake} />
                    </span>
                )}

                {this.currently_showing === ShowModus.EDI_MESSAGES && (
                    <EdiMessages sales_order_artkey={this.sales_order_artkey} />
                )}

                {this.currently_showing === ShowModus.ITEMS && (
                    <span>
                        <SalesOrderItems
                            sales_order={this.sales_order_response}
                            register_selection_enabled_component={(component: SelectionEnabledComponent) =>
                                this.selection_controller.register_selection_enabled_component(component)
                            }
                            sales_order_changed={() => this._fetch_sales_order_with_items()}
                        />

                        <SalesOrderAdditionalItems
                            sales_order={this.sales_order_response}
                            register_selection_enabled_component={(component: SelectionEnabledComponent) =>
                                this.selection_controller.register_selection_enabled_component(component)
                            }
                            sales_order_changed={() => this._fetch_sales_order_with_items()}
                        />

                        <SalesOrderCreditItems
                            sales_order={this.sales_order_response}
                            register_selection_enabled_component={(component: SelectionEnabledComponent) =>
                                this.selection_controller.register_selection_enabled_component(component)
                            }
                            sales_order_changed={() => this._fetch_sales_order_with_items()}
                        />

                        <SalesOrderTBOItems
                            sales_order={this.sales_order_response}
                            sales_order_changed={() => this._fetch_sales_order_with_items()}
                            register_selection_enabled_component={(component: SelectionEnabledComponent) =>
                                this.selection_controller.register_selection_enabled_component(component)
                            }
                        />

                        <SalesOrderBasketItems
                            sales_order={this.sales_order_response}
                            sales_order_changed={() => this._fetch_sales_order_with_items()}
                        />
                    </span>
                )}
            </div>
        )
    }
}
