/** llm:tested */
import {events} from '@bitstillery/common/app'

import {MithrilProp} from '@/types'
import {Brand, VatRate} from '@/models/data'
import {$m} from '@/app'

export enum ProductBottleType {
    LIGHT_GLASS = 'Glass (light)',
    HEAVY_GLASS = 'Glass (heavy)',
    PET = 'PET',
    STONE = 'Stone',
    PREMIX_RTD_GLASS = 'Premix / RTD glass',
    PREMIX_RTD_CAN = 'Premix / RTD can',
    PREMIX_RTD_PET = 'Premix / RTD PET',
}
export class Product {
    artkey: MithrilProp<string | undefined>
    name: MithrilProp<string | undefined>
    category: MithrilProp<string | undefined>
    bottles: MithrilProp<[]>
    default_country_code: MithrilProp<string | undefined>
    product_bottle_type: MithrilProp<string | undefined>
    requires_import_statement: MithrilProp<boolean | undefined>
    product_category: MithrilProp<ProductCategory>
    brand: MithrilProp<Brand>
    hide_from_pricelist_for_countries: MithrilProp<string[] | undefined>
    hide_from_pricelist_for_suppliers: MithrilProp<string[] | undefined>

    constructor(
        artkey?: string,
        name?: string,
        category?: string,
        default_country_code?: string,
        product_bottle_type?: string,
        requires_import_statement?: boolean,
        hide_from_pricelist_for_countries?: string[],
        hide_from_pricelist_for_suppliers?: string[],
    ) {
        this.artkey = window.prop(artkey)
        this.name = window.prop(name)
        this.category = window.prop(category)
        this.bottles = window.prop([])
        this.default_country_code = window.prop(default_country_code)
        this.product_bottle_type = window.prop(product_bottle_type)
        this.requires_import_statement = window.prop(requires_import_statement)
        this.product_category = window.prop(new ProductCategory({}))
        this.brand = window.prop(new Brand())
        this.hide_from_pricelist_for_countries = window.prop(hide_from_pricelist_for_countries)
        this.hide_from_pricelist_for_suppliers = window.prop(hide_from_pricelist_for_suppliers)
    }

    to_string(): string {
        return this.name()
    }
}

export class ProductCategory {
    artkey = window.prop('')
    name = window.prop('')
    default_product_bottle_type = window.prop('')
    cbs_code = window.prop('')
    cbs_code_gte_200 = window.prop('')
    cbs_code_gte_1000 = window.prop('')
    nl_vat_rate = window.prop(new VatRate())
    de_vat_rate = window.prop(new VatRate())
    be_vat_rate = window.prop(new VatRate())
    priority = window.prop('')
    parent = {
        artkey: window.prop(''),
        name: window.prop(''),
    }

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'nl_vat_rate') {
                this.nl_vat_rate(new VatRate(json[prop]))
            } else if (prop === 'de_vat_rate') {
                this.de_vat_rate(new VatRate(json[prop]))
            } else if (prop === 'be_vat_rate') {
                this.be_vat_rate(new VatRate(json[prop]))
            } else if (prop === 'parent') {
                if (json[prop]) {
                    this.parent.artkey(json[prop].artkey)
                    this.parent.name(json[prop].name)
                }
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export class ProductsDataModel {
    constructor() {
        $m.common.observable.subscribe('bottle_added', this, this.load_products_and_bottles)
        $m.common.observable.subscribe('bottles_updated', this, this.load_products_and_bottles)
    }

    _create_products = (products_and_bottles: any[]): Product[] => {
        const temp_products: { [key: string]: Product } = {}

        for (const product_and_bottle of products_and_bottles) {
            let product_object = temp_products[product_and_bottle.product_artkey]
            if (!product_object) {
                product_object = this.create_product({
                    artkey: product_and_bottle.product_artkey,
                    name: product_and_bottle.product_name,
                    category: product_and_bottle.product_category,
                    default_country_code: product_and_bottle.product_default_country_code,
                    requires_import_statement: product_and_bottle.product_requires_import_statement,
                })
            }
            const bottle = $m.bottles.create_bottle({
                artkey: product_and_bottle.bottle_artkey,
                volume: product_and_bottle.bottle_volume,
                alcohol_percentage: product_and_bottle.bottle_alcohol_percentage,
                refill_status: product_and_bottle.bottle_refill_status,
                cbs_code: product_and_bottle.bottle_cbs_code,
            }, product_object)
            product_object.bottles().push(bottle)
            temp_products[product_and_bottle.product_artkey] = product_object
        }
        return Object.values(temp_products)
    }

    create_products(products: any[]): Product[] {
        return products.map(product => this.create_product(product))
    }

    create_product(product: any): Product {
        const product_object = new Product(
            product.artkey,
            product.name,
            product.category,
            product.default_country_code,
            product.product_bottle_type,
            product.requires_import_statement,
            product.hide_from_pricelist_for_countries,
            product.hide_from_pricelist_for_suppliers,
        )

        if ('bottles' in product) {
            product_object.bottles($m.bottles.create_bottles(product.bottles, product_object))
        }
        if ('product_category' in product) {
            product_object.product_category(new ProductCategory(product.product_category))
        }
        if ('brand' in product) {
            product_object.brand(new Brand(product.brand))
        }
        return product_object
    }

    create_and_add_product = (product_data: any): void => {
        let product_object = this.get_product_by_artkey(product_data.artkey)
        if (!product_object) {
            product_object = this.create_product(product_data)
            this.add_product(product_object)
        } else if ('bottles' in product_data) {
            product_object.bottles($m.bottles.create_bottles(product_data.bottles, product_object))
        }
        $m.common.observable.broadcast('products_loaded', 'Go!')
    }

    // Note: Method stub added as it's referenced but not defined in the original
    get_product_by_artkey(_artkey: string): Product | undefined {
        // Implementation needed
        return undefined
    }

    // Note: Method stub added as it's referenced but not defined in the original
    add_product(_product: Product): void {
        // Implementation needed
    }

    // Note: Method stub added as it's referenced but not defined in the original
    load_products_and_bottles(): void {
        // Implementation needed
    }
}

export function bind() {
    events.on('identity.login', async() => {
        $m.products = new ProductsDataModel()
    })
}
