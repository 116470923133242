import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/lib/specs'
import {type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'

import {TabViewControl} from '../relation_dashboard'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {RelationDashboardData, RelationDashboardFilter} from '@/offer/relation-dashboard/relation_dashboard_model'
import {GetHiddenItemsResponse} from '@/factserver_api/fact2server_api'

interface HiddenOfferItemsAttrs {
    register_tab_view_control: (tab_view: TabViewControl) => void
    relation_dashboard_data: RelationDashboardData
    relation_dashboard_filters: RelationDashboardFilter
}

export class HiddenOfferItems extends MithrilTsxComponent<HiddenOfferItemsAttrs> implements TabViewControl {
    hidden_items_fetcher: PagedCollectionFetcherWithGET<GetHiddenItemsResponse>
    watches: type_remove_watch_function[] = []

    constructor(vnode: m.Vnode<HiddenOfferItemsAttrs>) {
        super()
        vnode.attrs.register_tab_view_control(this)
        this.hidden_items_fetcher = new PagedCollectionFetcherWithGET<GetHiddenItemsResponse>(
            `discover/relations/${vnode.attrs.relation_dashboard_data.selected_relation?.artkey}/hidden-information/collection-view`,
            'product_name',
        )
    }

    oncreate(vnode: m.VnodeDOM<HiddenOfferItemsAttrs, this>): any {
        if (!vnode.attrs.relation_dashboard_data.selected_relation) {
            return
        }

        this.hidden_items_fetcher.filters.supplier_artkey = vnode.attrs.relation_dashboard_data.selected_relation.artkey
        this.hidden_items_fetcher.filters.product_name = vnode.attrs.relation_dashboard_filters.product_name
        this.hidden_items_fetcher.filters.product_category_artkeys = vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys
        this.hidden_items_fetcher.filters.gtin = vnode.attrs.relation_dashboard_filters.gtin

        this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
        this.update_only_show_type(vnode.attrs.relation_dashboard_filters)

        this.watches = [
            watch(
                vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys,
                () => {
                    this.hidden_items_fetcher.filters.product_category_artkeys = vnode.attrs.relation_dashboard_filters.selected_product_category_artkeys || []
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                't1_filter',
                () => {
                    this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                't2_filter',
                () => {
                    this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_stock',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_tbo',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'show_purchase',
                () => {
                    this.update_only_show_type(vnode.attrs.relation_dashboard_filters)
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'product_name',
                () => {
                    this.hidden_items_fetcher.filters.product_name = vnode.attrs.relation_dashboard_filters.product_name
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'customs_status',
                () => {
                    this.update_customs_status_filter(vnode.attrs.relation_dashboard_filters)
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
            watch(
                vnode.attrs.relation_dashboard_filters,
                'gtin',
                () => {
                    this.hidden_items_fetcher.filters.gtin = vnode.attrs.relation_dashboard_filters.gtin
                    this.hidden_items_fetcher.reset_and_query()
                },
            ),
        ]
    }

    onremove(): any {
        this.watches.forEach((unsubscribe) => unsubscribe())
    }

    update_only_show_type(filter: RelationDashboardFilter) {
        const show_types: string[] = []
        if (filter.show_tbo) {
            show_types.push('tbo')
        }
        if (filter.show_purchase) {
            show_types.push('purchase')
        }
        if (filter.show_stock) {
            show_types.push('stock')
        }
        this.hidden_items_fetcher.filters.only_show_type = show_types
    }

    update_customs_status_filter(filter: RelationDashboardFilter) {
        if (filter.t1_filter !== filter.t2_filter) {
            if (filter.t1_filter) {
                this.hidden_items_fetcher.filters.customs_status = 'T1'
            } else {
                this.hidden_items_fetcher.filters.customs_status = 'T2'
            }
        } else {
            this.hidden_items_fetcher.filters.customs_status = ''
        }
    }

    is_loading(): boolean {
        return this.hidden_items_fetcher.is_fetching
    }

    view(): m.Children {
        return (
            <span>
                <CollectionTable<GetHiddenItemsResponse, void>
                    collection_fetcher={this.hidden_items_fetcher}
                >
                    <CollectionTableColumn<GetHiddenItemsResponse>
                        header_title={() => 'Product'}
                        sort_name={'product_name'}
                        data_field={(row: GetHiddenItemsResponse) => {
                            return <div className="td-group">
                                <span className="header">
                                    {row.product_name}
                                </span>
                                <span>{to_specs(row)}</span>
                                {row.case_article_code && <span className="details">{row.case_article_code}</span>}
                            </div>}
                        }
                    />

                    <CollectionTableColumn<GetHiddenItemsResponse>
                        header_title={() => 'Reason'}
                        data_field={(row: GetHiddenItemsResponse) =>
                            row.hidden_by_rule
                        }
                    />

                    <CollectionTableColumn<GetHiddenItemsResponse>
                        header_title={() => 'Brand'}
                        sort_name={'brand_name'}
                        data_field={(row: GetHiddenItemsResponse) => {
                            let brand = (row.brand_name ?? '')
                            if (row.brand_holder_name) {
                                brand += ` (${row.brand_holder_name})`
                            }
                            return brand
                        }}
                    />
                </CollectionTable>
            </span>
        )
    }
}
