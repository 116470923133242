import m from 'mithril'
import {countries} from '@bitstillery/common/lib/countries'
import {Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'

import {CheckboxGroup} from '@/components/filter/checkboxgroup'
import {Collection} from '@/components/collection/collection'
import {$m, $s} from '@/app'
import {RelationDropDownData} from '@/factserver_api/relation_api'
import {Radiobutton} from '@/components/filter/radiobutton'
import SearchInput from '@/components/collection/search_input'
import {radio} from '@/components/inputs'
import {button_with_icon} from '@/components/_buttons'
import {UserCheckboxGroup} from '@/components/users'

interface FilterSidebarAttrs {
    collection: Collection
}

interface FilterSidebarState {
    loading_accounts: boolean
    loading_currencies: boolean
}

export class FilterSidebar extends MithrilTsxComponent<FilterSidebarAttrs> {

    collection: Collection
    search_input_ctrl: SearchInput.controller
    state: FilterSidebarState = proxy({
        loading_accounts: true,
        loading_currencies: true,
    })

    constructor(vnode: m.Vnode<FilterSidebarAttrs>) {
        super()
        this.collection = vnode.attrs.collection

        if (m.route.param('manager')) {
            this.collection.sales_manager([m.route.param('manager')])
            this.collection.purchase_manager([m.route.param('manager')])
        }
        if (m.route.param('client_status')) {
            this.collection.client_status([m.route.param('client_status')])
        }

        this.search_input_ctrl = new SearchInput.controller({
            autocomplete: true,
            collection: this.collection,
            placeholder: 'Name, address, country or status',
            suggestions: [],
        })
    }

    oncreate() {
        // Wait with rendering the backend-related CheckBoxGroups
        // until their filter options are loaded from the factserver.
        $m.common.observable.subscribe('accounts_loaded', this, () => this.state.loading_accounts = false)
        $m.common.observable.subscribe('currencies_loaded', this, () => this.state.loading_currencies = false)

        // If currencies and users are already loaded earlier, the broadcasts
        // for the subscribes above are not executed, so check that here.
        if ($m.accounts.accounts().length > 0) this.state.loading_accounts = false

        RelationDropDownData.names().subscribe((names) => {
            this.search_input_ctrl.set_suggestions(names)
        })
    }

    /**
     * Reset all filters to their default values.
     */
    reset_all_filters() {
        // NOTE: It is difficult to uncheck the checkboxes in the CheckBoxGroup
        // components from here, because we need to access the state of those
        // CheckBoxGroup components. Therefore, for now, just reload the page
        // instead.
        window.location.reload()
    }

    view() {
        return (
            <div className="c-filter-sidebar">
                {SearchInput.view(this.search_input_ctrl)}
                <div className="btn-group mt-2">
                    {button_with_icon('Only my relations', 'glyphicon-user', {
                        class: this.collection.relation_filter() === 'mine' ? 'btn-primary' : 'btn-default',
                        onclick: () => {
                            this.collection.requery_filter(this.collection.relation_filter)(this.collection.relation_filter() === 'mine' ? 'all' : 'mine')
                        },
                    })}
                    {button_with_icon('Reset all filters', 'fa-undo', {
                        class: 'btn-default',
                        onclick: this.reset_all_filters,
                    })}
                </div>
                <hr />
                <div className="btn-group filter-radio mb-1">
                    <label className="control-label">Buyers</label>
                    <div>
                        {radio(this.collection.requery_filter(this.collection.buyers), [
                            {description: 'Yes', value: 'true'},
                            {description: 'No', value: 'false'},
                            {description: 'Both', value: ''},
                        ])}
                    </div>
                </div>
                <div className="btn-group filter-radio mb-1">
                    <label className="control-label">Suppliers</label>
                    <div>
                        {radio(this.collection.requery_filter(this.collection.suppliers), [
                            {description: 'Yes', value: 'true'},
                            {description: 'No', value: 'false'},
                            {description: 'Both', value: ''},
                        ])}
                    </div>
                </div>
                <hr />
                {this.state.loading_accounts ? (
                    <Spinner />
                ) : (
                    <CheckboxGroup
                        filter_function={this.collection.requery_filter(this.collection.sales_account)}
                        filter_id="sales_account"
                        filter_name="Sales Account"
                        filter_options={$m.accounts.accounts().map((i) => [i.slug(), i.name()])}
                    />
                )}

                <CheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.country)}
                    filter_id="country"
                    filter_name="Country"
                    filter_options={Object.entries(countries).map((i) => [i[0], `${i[0]} - ${i[1]}`])}
                />

                <CheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.company_type)}
                    filter_id="company_type"
                    filter_name="Company type"
                    filter_options={$m.data.company_types.map((i) => [i, i])}
                    show_no_value_checkbox={false}
                />

                <CheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.client_status)}
                    filter_id="client_status"
                    filter_name="Client status"
                    filter_options={$m.data.client_statuses.map((i) => [i, i])}
                    show_no_value_checkbox={true}
                />

                <UserCheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.purchase_manager)}
                    filter_id="purchase_manager"
                    filter_name="Purchase Manager"
                />

                <UserCheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.sales_manager)}
                    filter_id="sales_manager"
                    filter_name="Sales Manager"
                />

                <CheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.price_list_frequency)}
                    filter_id="price_list_frequency"
                    filter_name="Price list frequency"
                    filter_options={Object.entries($m.data.price_list_frequencies)}
                    show_no_value_checkbox={true}
                />

                <CheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.portal_level)}
                    filter_id="portal_level"
                    filter_name="Portal level"
                    filter_options={$m.data.supplier_portal_levels}
                    show_no_value_checkbox={true}
                />

                <CheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.customs_status_visibility)}
                    filter_id="customs_status_visibility"
                    filter_name="Customs status visibility"
                    filter_options={$m.data.supplier_portal_customs_visibility}
                    show_no_value_checkbox={true}
                />

                <CheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.currency)}
                    filter_id="currency"
                    filter_name="Currency"
                    filter_options={$s.currencies.all.map((i) => [i, i])}
                />

                <CheckboxGroup
                    filter_function={this.collection.requery_filter(this.collection.active_sales_promotions)}
                    filter_id="sales_promotions"
                    filter_name="Sales Promotions"
                    filter_options={$m.data.sales_promotions}
                    show_no_value_checkbox={true}
                />

                <Radiobutton
                    filter_function={this.collection.requery_filter(this.collection.operates_online)}
                    filter_name="Operates online"
                    filter_options={[
                        [true, 'Yes'],
                        [false, 'No'],
                        ['', 'All'],
                    ]}
                />

                <Radiobutton
                    filter_function={this.collection.requery_filter(this.collection.should_receive_offer_mails)}
                    filter_name="Receives offer mails"
                    filter_options={[
                        ['true', 'Yes'],
                        ['false', 'No'],
                        ['', 'All'],
                    ]}
                />

                <Radiobutton
                    filter_function={this.collection.requery_filter(this.collection.should_receive_purchase_mails)}
                    filter_name="Receives purchase mails"
                    filter_options={[
                        ['true', 'Yes'],
                        ['false', 'No'],
                        ['', 'All'],
                    ]}
                />

                <Radiobutton
                    filter_function={this.collection.requery_filter(this.collection.has_valid_signed_trademark_declaration)}
                    filter_name="Has valid signed trademark declaration"
                    filter_options={[
                        [true, 'Yes'],
                        [false, 'No'],
                        ['', 'All'],
                    ]}
                />

                <Radiobutton
                    filter_function={this.collection.requery_filter(this.collection.has_valid_lexis_nexis)}
                    filter_name="Checked LexisNexis"
                    filter_options={[
                        [true, 'Yes'],
                        [false, 'No'],
                        ['', 'All'],
                    ]}
                />
            </div>
        )
    }
}
