/** llm:production */
import m from 'mithril'

import autoComplete from '@/components/collection/autocomplete'
import {$m} from '@/app'

interface SearchInputOptions {
    collection: any
    placeholder?: string
    autocomplete?: boolean
    input_id?: string
    disabled?: () => boolean
    suggestions?: any[]
}

class ViewModel {
    collection_instance: any
    placeholder: string
    autocomplete: boolean
    input_id: string
    disabled: () => boolean
    autocomplete_ctrl: any

    constructor(options: SearchInputOptions) {
        this.collection_instance = options.collection
        this.placeholder = options.placeholder || ''
        this.autocomplete = options['autocomplete'] || false
        this.input_id = options.input_id || ''
        this.disabled = options.disabled || (() => false)

        if (this.autocomplete) {
            this.autocomplete_ctrl = new autoComplete.controller({
                input_container_id: '#search-input-container',
                on_submit_suggestion: (suggestion: any) => {
                    this.oninput(suggestion, true)
                    this.collection_instance.submit_search()
                },
                suggestions: options.suggestions,
            })
        }
    }

    init() {
        if (!this.collection_instance) {
            $m.common.generic_error_handler('No collection provided for SearchInput')
            return
        }
    }

    oninput_event(event: Event) {
        this.oninput((event.target as HTMLInputElement).value)
    }

    oninput(search_term: string, is_suggestion: boolean = false) {
        this.collection_instance.update_search_term(search_term)
        if (this.autocomplete) {
            this.autocomplete_ctrl.oninput(search_term, is_suggestion)
        }
    }

    reset_search() {
        this.collection_instance.reset_search()
    }

    get_input_value() {
        return this.collection_instance.search_term()
    }

    onclick(e: Event) {
        if (this.autocomplete) {
            this.autocomplete_ctrl.onclick(e)
        }
    }

    onkeydown(e: KeyboardEvent) {
        if (this.autocomplete) {
            this.autocomplete_ctrl.onkeydown(e)
        }
        this.collection_instance.submit_search_event(e)
    }
}

class Controller {
    vm: ViewModel
    set_suggestions?: (suggestions: any[]) => void

    constructor(options: SearchInputOptions) {
        this.vm = new ViewModel(options)

        if (options.autocomplete) {
            this.set_suggestions = (suggestions: any[]) => {
                this.vm.autocomplete_ctrl.set_suggestions(suggestions)
            }
        }
    }

    reset() {
        this.vm.reset_search()
    }

    submit_search(search_term: string) {
        this.vm.oninput(search_term)
        this.vm.collection_instance.submit_search()
    }

    onremove() {
        if (this.vm.autocomplete) {
            this.vm.autocomplete_ctrl.onremove()
        }
    }
}

const view = (ctrl: Controller) => {
    return m('.c-search-input#search-input-container', [
        m(`input#${ctrl.vm.input_id}`, {
            autofocus: true,
            autocomplete: 'off',
            value: ctrl.vm.get_input_value(),
            type: 'text',
            id: 'search-input',
            disabled: ctrl.vm.disabled(),
            placeholder: ctrl.vm.placeholder,
            onclick: ctrl.vm.onclick.bind(ctrl.vm),
            onkeydown: ctrl.vm.onkeydown.bind(ctrl.vm),
            oninput: ctrl.vm.oninput_event.bind(ctrl.vm),
        }),
        ctrl.vm.autocomplete && autoComplete.view(ctrl.vm.autocomplete_ctrl),
        m('button.btn.btn-default', {
            tabindex: -1,
            onclick: ctrl.reset.bind(ctrl),
            type: 'button',
            disabled: ctrl.vm.disabled(),
        }, m('span.glyphicon.glyphicon-remove-sign')),
    ])
}

export default {
    view,
    ViewModel,
    controller: Controller,
}
