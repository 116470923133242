import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Spinner} from '@bitstillery/common/components'

import SendPurchaseOrderToLoendersloot from './send_purchase_order_to_loendersloot'

import {Modal} from '@/components/modal/modal'
import {DangerButton, DefaultButton, SuccessButton} from '@/components/buttons'
import {GetPurchaseOrderResponse, PurchaseOrderStatus} from '@/factserver_api/fact2server_api'
import {PurchaseApi} from '@/factserver_api/purchase_api'
import {LOENDERSLOOT_WAREHOUSE_ARTKEY} from '@/factserver_api/factserver_generic'

export interface PurchaseOrderButtonsAttrs {
    purchase_order: GetPurchaseOrderResponse
    reload_needed: () => unknown
}

export default class PurchaseOrderButtons extends MithrilTsxComponent<PurchaseOrderButtonsAttrs> {

    purchase_api = new PurchaseApi()
    purchase_order_artkey: number
    export_to_excel_running = false
    export_stock_differences_to_excel_running = false
    excel_file_name: string
    excel_stock_differences_file_name: string
    show_modal_send_to_loendersloot = false
    is_unconfirming = false

    oncreate(vnode: m.Vnode<PurchaseOrderButtonsAttrs>): void {
        this.purchase_order_artkey = vnode.attrs.purchase_order.artkey
        this.excel_file_name = `Purchase Order ${vnode.attrs.purchase_order.reference} - ${vnode.attrs.purchase_order.supplier.name}.xlsx`
        this.excel_stock_differences_file_name = `Purchase Order ${vnode.attrs.purchase_order.reference} - ${vnode.attrs.purchase_order.supplier.name} - Differences.xlsx`
    }

    export_to_excel(): void {
        this.export_to_excel_running = true
        this.purchase_api.export_to_excel(this.purchase_order_artkey, this.excel_file_name).subscribe({
            next: () => {
                this.export_to_excel_running = false
                m.redraw()
            },
            error: () => {
                this.export_to_excel_running = false
                m.redraw()
            },
        })
    }

    export_stock_differences_to_excel(): void {
        this.export_stock_differences_to_excel_running = true
        this.purchase_api.export_stock_differences_to_excel(this.purchase_order_artkey, this.excel_stock_differences_file_name).subscribe({
            next: () => {
                this.export_stock_differences_to_excel_running = false
                m.redraw()
            },
            error: () => {
                this.export_stock_differences_to_excel_running = false
                m.redraw()
            },
        })
    }

    unconfirm(vnode: m.Vnode<PurchaseOrderButtonsAttrs>): void {
        this.is_unconfirming = true
        this.purchase_api.unconfirm_purchase_order(this.purchase_order_artkey).subscribe({
            next: () => {
                vnode.attrs.reload_needed()
                this.is_unconfirming = false
                m.redraw()
            },
        })
    }

    toggle_communication_status(vnode: m.Vnode<PurchaseOrderButtonsAttrs>): void {
        this.purchase_api.toggle_is_communicated(this.purchase_order_artkey).subscribe({
            next: () => {
                vnode.attrs.reload_needed()
                m.redraw()
            },
        })
    }

    view(vnode: m.Vnode<PurchaseOrderButtonsAttrs>): m.Children {
        const purchase_order = vnode.attrs.purchase_order
        const supplier = purchase_order.supplier
        const has_pois = purchase_order.number_of_order_lines > 0
        const target_is_loendersloot = purchase_order.target_warehouse.artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY
        const is_status_saved = purchase_order.status === PurchaseOrderStatus.Saved
        const is_status_confirmed = purchase_order.status === PurchaseOrderStatus.Confirmed
        const is_status_onhold = purchase_order.status === PurchaseOrderStatus.OnHold
        const is_buy_from_account = purchase_order.buy_from_account_sales_order

        const can_edit = purchase_order.status !== PurchaseOrderStatus.StockedAndBooked
        const can_show_rfp = purchase_order.status !== PurchaseOrderStatus.Cancelled
        const can_show_arrival_notice = (is_status_confirmed || is_status_onhold)
        const can_back_to_saved = is_status_confirmed && !is_buy_from_account
        const can_confirm = is_status_saved && has_pois
        const can_send_to_loendersloot = !is_buy_from_account && target_is_loendersloot
        const can_in_stock_loendersloot = (is_status_confirmed || is_status_onhold) && !is_buy_from_account && has_pois && target_is_loendersloot
        const can_in_stock = (is_status_confirmed || is_status_onhold) && !is_buy_from_account && has_pois
        const can_in_fis = purchase_order.status === PurchaseOrderStatus.ReadyForFIS
        const can_cancel = is_status_saved || (is_status_confirmed && !has_pois)
        const can_toggle_communication_status = !is_buy_from_account
        const communication_text = purchase_order.is_communicated_on ? 'Reopen PO (not yet communicated to supplier)' : 'Close PO (communicated to supplier)'
        const disable_confirm = purchase_order.requires_import_statement && (!supplier.is_verified && !purchase_order.has_import_statement)

        return (<div className={'btn-toolbar'}>
            <DefaultButton
                icon_class={'glyphicon glyphicon-arrow-left'}
                title={'Back to list'}
                onclick={() => m.route.set('/purchase-orders/manage')}
            />
            {this.show_modal_send_to_loendersloot && <Modal
                title={'Send to Loendersloot'}
                onclose={() => this.show_modal_send_to_loendersloot = false}

            >
                <SendPurchaseOrderToLoendersloot
                    purchase_order={vnode.attrs.purchase_order}
                    onsend_completed={() => {
                        this.show_modal_send_to_loendersloot = false
                        vnode.attrs.reload_needed()
                    }}
                    oncancel={() => {
                        this.show_modal_send_to_loendersloot = false
                        vnode.attrs.reload_needed()
                    }}
                />
            </Modal>}
            {can_edit &&
                <DefaultButton
                    icon_class={'glyphicon glyphicon-edit'}
                    title={'Edit order'}
                    onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/edit`)}
                />
            }
            {/** Status buttons **/}
            {can_back_to_saved && <DefaultButton
                icon_class={'glyphicon glyphicon-backward'}
                title={'Back to saved'}
                additional_classes={'btn-success'}
                disabled={this.is_unconfirming}
                onclick={() => this.unconfirm(vnode)}
            />}
            {this.is_unconfirming && <Spinner />}
            {can_confirm && <DefaultButton
                icon_class={'glyphicon glyphicon-road'}
                title={'Confirmed by supplier'}
                onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/confirm`)}
                disabled={disable_confirm}
            />}

            {/** Download pdf buttons **/}
            {can_show_rfp && !is_status_saved &&
                <DefaultButton
                    icon_class={'glyphicon glyphicon-file'}
                    title={'Download RFP'}
                    onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/request-for-proposal`)}
                />
            }
            {can_show_rfp && is_status_saved &&
                <SuccessButton
                    icon_class={'glyphicon glyphicon-file'}
                    title={'Download RFP'}
                    onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/request-for-proposal`)}
                />
            }
            {can_show_arrival_notice &&
                <DefaultButton
                    icon_class={'glyphicon glyphicon-file'}
                    title={'Arrival notice'}
                    onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/arrival-notice`)}
                />
            }
            <DefaultButton
                icon_class={'fas fa-file-excel'}
                title={'Export to excel'}
                disabled={this.export_to_excel_running}
                onclick={() => this.export_to_excel()}
            />
            <DefaultButton
                icon_class={'fas fa-file-excel'}
                title={'Export stock differences'}
                disabled={this.export_stock_differences_to_excel_running}
                onclick={() => this.export_stock_differences_to_excel()}
            />
            {has_pois && <DefaultButton
                icon_class={'glyphicon glyphicon-arrow-right'}
                title={'Move items'}
                onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/move-items`)}
            />}
            {can_send_to_loendersloot && <DefaultButton
                icon_class={'glyphicon glyphicon-export'}
                title={'Send to Loendersloot'}
                onclick={() => this.show_modal_send_to_loendersloot = true}
            />}
            {can_in_stock_loendersloot && <DefaultButton
                icon_class={'glyphicon glyphicon-home'}
                title={'In stock Loendersloot'}
                onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/instock_loendersloot`)}
            />}
            {can_in_stock && <DefaultButton
                icon_class={'glyphicon glyphicon-home'}
                title={'In stock'}
                onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/instock`)}
            />}
            {can_in_fis && <DefaultButton
                icon_class={'fas fa-wallet'}
                title={'Financial administration'}
                onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/financials`)}
            />}
            {can_cancel && <DangerButton
                onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}/cancel`)}
                icon_class={'fas fa-trash'}
                title={'Cancel order'}
            />}
            {can_toggle_communication_status && <DefaultButton
                icon_class={'fas fa-satellite-dish'}
                title={communication_text}
                onclick={() => this.toggle_communication_status(vnode)}
            />}
        </div>)
    }
}
