/** llm:tested */
import m from 'mithril'

import {Popover} from '@/components/popover/popover'

interface IconAttributes extends m.Attributes {
    [key: string]: any
}

interface IconWithPopoverProps {
    iconId: string
    title?: string
    content: m.Children
    attrs?: IconAttributes
}

export const icon = (id: string, attrs: IconAttributes = {}): m.Vnode => {
    return <span class={icon_class(id)} {...attrs} />
}

export const icon_class = (id: string): string => {
    if (id.startsWith('fab')) {
        return `${id}`
    } else if (id.startsWith('fa')) {
        if (id.startsWith('fa-')) {
            return `fas ${id}`
        } else {
            return `${id}`
        }
    }
    // Flag icons: icon 'flag-icon-nl'
    else if (id.startsWith('flag-')) {
        return `flag-icon ${id}`
    } else if (id.startsWith('glyphicon-')) {
        return `glyphicon ${id}`
    } else {
        return `glyphicon glyphicon-${id}`
    }
}

export const icon_with_popover = ({iconId, title, content, attrs = {}}: IconWithPopoverProps): m.Vnode => {
    return <Popover title={title} content={content}>
        {icon(iconId, attrs)}
    </Popover>
}
