import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api, notifier} from '@bitstillery/common/app'
import {FieldSelect} from '@bitstillery/common/components'

import {SuccessButton} from '../buttons'

import {Modal} from '@/components/modal/modal'
import {$s} from '@/app'
import {UserManagementApi} from '@/factserver_api/user_management_api'

export class UserScheduleStatus extends MithrilTsxComponent<any> {
    api = new UserManagementApi()
    modal_status = ''

    async oninit() {
        await this.poll_user_artkey()
    }

    async poll_user_artkey() {
        if (!$s.identity.user.artkey) {
            setTimeout(() => {
                Promise.resolve(this.log_user_status())
            }, 500)
            return
        }
        await this.log_user_status()
    }

    async log_user_status() {
        try {
            const {result, status_code} = await api.post('user_schedules.get_user_status_today', {user_artkey: $s.identity.user.artkey}) as any
            if (status_code === 420) {
                return
            }
            $s.schedule.status = result.status
            if (!result.standard_schedule) {
                // requery status to keep it up to date
                window.setTimeout(() => this.log_user_status(), (1000 * 60) * 10)
            } else {
                // Select the standard schedule setting in the modal
                this.modal_status = result.status
                $s.schedule.confirmed_today = false
            }
        } catch (err) {
            notifier.notify('Something went wrong trying to load in your status, please reload', 'danger')
            $s.schedule.status = 'UNKNOWN'
        }
    }

    view(): m.Children {
        if ($s.schedule.confirmed_today || [null, 'VACATION'].includes($s.schedule.status)) return
        return (
            <Modal title={'Daily status log'}>
                Hello, we have yet to record a status for you today. <br />
                Please enter your status for today in the following dropdown <br />
                {this.modal_status === 'SICK' && (
                    <b>
                        <br />
                        When calling in sick, your status will updated to "sick" everyday, until you manually
                        update it to a different status!
                        <br />
                    </b>
                )}
                <div className={'pull-right'}>
                    <SuccessButton
                        title={'Submit'}
                        disabled={this.modal_status === ''}
                        onclick={async() => {
                            const {result} = await api.post('user_schedules.manual_schedule_status_log', {
                                user_artkey: $s.identity.user.artkey,
                                status: this.modal_status,
                            }) as any
                            $s.schedule.status = result.status
                            $s.schedule.confirmed_today = true
                            // start the requery status loop after the user has manually set their status
                            window.setTimeout(() => this.log_user_status(), (1000 * 60) * 10)
                        }}
                    />
                </div>
                <div className={'pull-right'}>
                    <FieldSelect
                        model={[this, 'modal_status']}
                        onchange={(value: string) => (this.modal_status = value)}
                        options={[
                            {value: 'OFF', label: 'Off'},
                            {value: 'HOME', label: 'Home'},
                            {value: 'OFFICE', label: 'Office'},
                            {value: 'SICK', label: 'Sick'},
                        ]}
                        placeholder="Select status..."
                    />
                </div>
            </Modal>
        )
    }
}
