/** llm:tested */

import m from 'mithril'
import {Link, Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {EmailProcessFlow} from '../models'

import api_ls from '@/api'
import {button_with_icon} from '@/components/_buttons'
import {show_confirmation} from '@/components/confirmation'
import {formatDateTime, preventDefault} from '@/_utils'
import {EmailBatchRecipients} from '@/email/details/email_batch_recipients'
import {EmailBatchSentEmails} from '@/email/details/email_batch_sent_emails'
import {EmailBatchStatus, EmailBatchType} from '@/email/models'
import {ProcessManageOffer} from '@/offer/offers/processes'

const SHOWING = {
    RECIPIENTS: 'RECIPIENTS',
    SENT_MAIL: 'SENT_MAIL',
} as const

type EmailBatchData = {
    artkey: string
    offer_artkey: string
    status: string
    batch_type: string
    subject: string
    created_on: string
    created_by: {
        profile: {
            name: string
            emailaddress: string
        }
    }
    use_sent_from_user: {
        profile: {
            emailaddress: string
        }
    } | null
    is_pending: boolean
    next_run: string
}

export class CrmEmailBatch extends MithrilTsxComponent<any> {
    artkey: any
    offer_artkey: string
    email_batch: (resp?: object) => EmailBatchData | null
    is_offer: any
    currently_showing: string
    number_of_recipients: number
    number_of_emails: number

    constructor() {
        super()
        this.artkey = window.prop(m.route.param('artkey'))
        this.offer_artkey = m.route.param('offer')
        this.email_batch = window.prop(null)
        this.is_offer = window.prop(false)
        this.currently_showing = SHOWING.RECIPIENTS
    }

    get_process_flow() {
        if (this.offer_artkey) {
            return EmailProcessFlow.OFFERS
        }
        return EmailProcessFlow.MAILS
    }

    oncreate() {
        this.fetch_email_batch()
    }

    fetch_email_batch() {
        api_ls.callAndThen('email.batch.get', {offer_email_batch_artkey: this.artkey()}, {
            success: (resp: any) => {
                this.email_batch(resp.result)
                if (this.email_batch()?.offer_artkey) {
                    this.is_offer(true)
                }
                this.number_of_recipients = resp.number_of_recipients
                this.number_of_emails = resp.number_of_emails
            },
        })
    }

    view() {
        if (this.email_batch() === null) {
            return <Spinner />
        }

        return <div class="c-communication-batches view process">
            {this.get_process_flow() === EmailProcessFlow.OFFERS && (
                <ProcessManageOffer
                    active="email_send"
                    context={{
                        offer_artkey: this.email_batch()?.offer_artkey,
                        email_batch_artkey: this.email_batch()?.artkey,
                    }}
                />
            )}

            <div class="step-content">
                <div class="btn-toolbar">
                    {this.get_process_flow() === EmailProcessFlow.MAILS &&
                        <button class="btn btn-default" type="button" onclick={() => m.route.set('/crm/email')}>
                            <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                        </button>
                    }

                    {this.email_batch() && [EmailBatchStatus.UNDER_CONSTRUCTION, EmailBatchStatus.WAITING_FOR_NEXT_RUN].includes(this.email_batch()?.status || '') && [
                        this.get_process_flow() === EmailProcessFlow.MAILS && [
                            button_with_icon('Edit email', 'edit', {
                                class: 'btn-default',
                                onclick: () => {
                                    if (this.is_offer()) {
                                        m.route.set(`/offer/offers/${this.email_batch()?.offer_artkey}/email/${this.artkey()}/edit`)
                                    } else {
                                        m.route.set(`/crm/email/${this.artkey()}/edit`)
                                    }
                                },
                            }),
                            button_with_icon('Select recipients', 'user', {
                                class: 'btn-default',
                                onclick: () => {
                                    if (this.is_offer()) {
                                        m.route.set(`/offer/offers/${this.email_batch()?.offer_artkey}/email/${this.artkey()}/recipient-selection`)
                                    } else {
                                        m.route.set(`/crm/email/${this.artkey()}/recipient-selection`)
                                    }
                                },
                            }),
                            button_with_icon('Delete batch', 'remove', {
                                class: 'btn-danger pull-right',
                                onclick: () => show_confirmation({
                                    title: 'Confirm email batch delete',
                                    message: 'Are you sure you want to delete this email batch?',
                                    unique_name: 'delete_email_batch',
                                    onconfirm: () => {
                                        api_ls.callAndThen('pricelistbatch.delete', {offer_email_batch_artkey: this.artkey()}, {
                                            success: () => {
                                                notifier.notify('Successfully deleted offer email batch.', 'success')
                                                if (this.is_offer()) {
                                                    m.route.set(`/offer/offers/${this.email_batch()?.offer_artkey}/email/create`)
                                                } else {
                                                    m.route.set('/crm/email')
                                                }
                                            },
                                        })
                                    },
                                }),
                            }),
                        ],
                        !this.email_batch()?.is_pending && (
                            button_with_icon('Send email to all recipients', 'send', {
                                class: 'btn-success',
                                onclick: () => show_confirmation({
                                    title: 'Confirm email dispatch',
                                    message: `Are you ready to email ${this.number_of_recipients} recipients?`,
                                    unique_name: 'dispatch',
                                    onconfirm: () => {
                                        api_ls.callAndThen('email.batch.confirm_for_dispatch', {email_batch_artkey: this.email_batch()?.artkey}, {
                                            success: () => {
                                                notifier.notify('Successfully dispatched offer email batch!', 'success')
                                                if (this.is_offer()) {
                                                    m.route.set(`/offer/offers/${this.email_batch()?.offer_artkey}/email/${this.artkey()}/email-send?key=${Date.now()}`)
                                                } else {
                                                    m.route.set('/crm/email')
                                                }
                                            },
                                        })
                                    },
                                }),
                            },
                            )),
                    ]}
                </div>

                <div class="field">
                    {this.email_batch()?.use_sent_from_user &&
                        <div class="row">
                            <label class="control-label col-sm-2">From email address</label>
                            <div class="col-sm-10">{this.email_batch()?.use_sent_from_user.profile.emailaddress}</div>
                        </div>
                    }
                    <div class="row">
                        <label class="control-label col-sm-2">Subject</label>
                        <div class="col-sm-10">{this.email_batch()?.subject}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Created on</label>
                        <div class="col-sm-10">{formatDateTime(this.email_batch()?.created_on)}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Created by</label>
                        <div class="col-sm-10">{this.email_batch()?.created_by.profile.name}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Status</label>
                        <div class="col-sm-10">{EmailBatchStatus.choices[this.email_batch()?.status]}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Type</label>
                        <div class="col-sm-10">{EmailBatchType.choices[this.email_batch()?.batch_type]}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Related offer</label>
                        <div class="col-sm-10">{this.is_offer() ? 'Yes' : 'No'}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Scheduled to be sent?</label>
                        <div class="col-sm-10">
                            {this.email_batch()?.is_pending ? 'Yes' :
                                this.email_batch()?.status === EmailBatchStatus.UNDER_CONSTRUCTION ?
                                    'No, click \'Send email to all recipients\' to enable' :
                                    'Has been sent'}
                        </div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Scheduled for</label>
                        <div class="col-sm-10">{formatDateTime(this.email_batch()?.next_run)}</div>
                    </div>
                </div>

                <ul class="nav nav-tabs pl-2 mb-2 row">
                    <li role="presentation" class={this.currently_showing === SHOWING.RECIPIENTS ? 'active' : ''}>
                        <Link
                            href={m.route.get()}
                            onclick={(e: Event) => {
                                preventDefault(e)
                                this.currently_showing = SHOWING.RECIPIENTS
                                this.fetch_email_batch()
                            }}>
                            Recipients ({this.number_of_recipients})
                        </Link>
                    </li>
                    <li role="presentation" class={this.currently_showing === SHOWING.SENT_MAIL ? 'active' : ''}>
                        <Link
                            href={m.route.get()}
                            onclick={(e: Event) => {
                                preventDefault(e)
                                this.currently_showing = SHOWING.SENT_MAIL
                                this.fetch_email_batch()
                            }}
                        >
                            Sent email ({this.number_of_emails})
                        </Link>
                    </li>
                </ul>

                {this.email_batch() && (
                    <div class="collectionpanel">
                        {this.currently_showing === SHOWING.RECIPIENTS ?
                            <EmailBatchRecipients email_batch={this.email_batch} /> :
                            <EmailBatchSentEmails email_batch={this.email_batch} />
                        }
                    </div>
                )}
            </div>
        </div>
    }
}
