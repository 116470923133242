/** llm:tested */
import m from 'mithril'
import {Button, Spinner} from '@bitstillery/common/components'
import {slice} from 'prelude-ls'
import {$t} from '@bitstillery/common/app'

import {Column, Table} from '@/components/table2'

interface Collection {
    paged: boolean
    offset: number
    limit: number
    loading: () => boolean
    search_result: () => any[]
    sort_icon: (field: string) => any
    sort: (field: string, ascending: boolean, sort_order: any[]) => void
    can_show_more_items: () => boolean
    hide_show_more_items?: boolean
    show_more: () => void
    pager: () => any
}

export class CollectionTable extends Table {
    collection: Collection

    constructor(vnode: m.Vnode<{ collection: Collection }>) {
        super(vnode)
        this.collection = vnode.attrs.collection
    }

    view_column_header(column: Column): string | m.Children {
        const header = super.view_column_header(column)
        if (column.sort || column.sort_function) {
            return [header, ' ', this.collection.sort_icon(column.field || '')]
        } else {
            return header
        }
    }

    get_body_items() {
        // If the collection is paged, only return one page of search results.
        if (this.collection.paged) {
            return slice(
                this.collection.offset,
                (+this.collection.offset) + (+this.collection.limit),
                this.collection.search_result(),
            )
        }
        // Otherwise, return all search results.
        return this.collection.search_result()
    }

    sort_column(field: string, ascending = true, sort_order: any[] = []) {
        this.collection.sort(field, ascending, sort_order)
    }

    view_footer() {
        let content:any = null

        if (this.collection.loading()) {
            content = <Spinner className="table-spinner" />
        } else if (!this.collection.search_result().length) {
            content = <div className="no-results">No results found.</div>
        } else if (this.collection.paged) {
            content = this.collection.pager()
        } else if ((!this.collection.paged) && (this.collection.can_show_more_items()) && (!this.collection.hide_show_more_items)) {
            content = <Button
                className="mt-2"
                icon="chevronRightDouble"
                onclick={this.collection.show_more.bind(this.collection)}
                text={$t('table.fetch_more')}
                type="info"
            />
        }

        if (content !== null) {
            return <tbody>
                <tr>
                    <td className="table-footer" colspan="100%">
                        {content}
                    </td>
                </tr>
            </tbody>
        }

        return null
    }
}
