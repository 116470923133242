/** llm:tested */
import m from 'mithril'
import {deref} from '@bitstillery/common/utils'

interface RankItem {
    rank: any // Using 'any' since original type is unknown
    total: any // Using 'any' since original type is unknown
}

/**
 * Returns a conditionally colored ranking.
 */
export const rank = (item: RankItem): m.Vnode => {
    const rank = deref(item.rank)
    const total = deref(item.total)

    let color_class: string

    if (rank === 1 && total > 1) {
        // The item is ranked first of multiple items.
        color_class = 'analysis-good-color'
    } else if (rank > 1 && rank === total) {
        // The item is ranked last of multiple items.
        color_class = 'analysis-bad-color'
    } else {
        // The item is ranked somewhere in the middle or is a single item.
        color_class = 'analysis-neutral-color'
    }

    return <span class={`rank ${color_class}`}>{`${rank} / ${total}`}</span>
}
