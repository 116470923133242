import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {$s, $t, store} from '@bitstillery/common/app'
import {Button, FieldText} from '@bitstillery/common/components'
import {api, notifier} from '@bitstillery/common/app'

import {RelationOfferItems} from './components/relation_offer_items'
import {HiddenOfferItems} from './components/hidden_offer_items'
import {SpecialOfferItems} from './components/special_offer_items'
import {PanelRelationDetails} from './components/panel_relation_details'

import {OnOffButton} from '@/components/buttons'
import {RelationsSearch} from '@/components/relation'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {MultiResultSearchBar} from '@/components/html_components'
import {RelationApi} from '@/factserver_api/relation_api'
import {GetAllProductCategoriesResponse} from '@/factserver_api/product_categories'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {GetRelationResponse} from '@/factserver_api/fact2server_api'
import {RelationDashboardData, RelationDashboardFilter} from '@/offer/relation_dashboard/relation_dashboard_model'
import {create_download_for_blob} from '@/factserver_api/api'

enum SelectableMainTab {
    OFFER_ITEMS = 'offer-items',
    HIDDEN_OFFER_ITEMS = 'hidden-offer-items',
    SPECIAL_OFFER_ITEMS = 'special-offer-items',
}

export interface TabViewControl {
    is_loading(): boolean
}

interface RelationDashboardUrlParams {
    relation_artkey?: string
    selected_tab?: SelectableMainTab
    gtin?: string
    product_name?: string
    show_stock: boolean
    show_purchase: boolean
    show_tbo: boolean
    show_t1: boolean
    show_t2: boolean
}

export class OfferRelationDashboard extends MithrilTsxComponent<unknown> {
    relation_api = new RelationApi()
    product_management_api = new ProductManagementApi()

    selected_tab = SelectableMainTab.OFFER_ITEMS
    is_loading_export_to_excel = false

    default_product_search = ''

    tab_view_controls: TabViewControl[] = []

    data: RelationDashboardData = proxy({
        categories: [] as GetAllProductCategoriesResponse,
        selected_relation: null,
        selected_relation_artkey: null,
        selected_relations: [] as GetRelationResponse[],
        relation_exchange_rate: null,
    })

    watchers: type_remove_watch_function[] = []

    filters: RelationDashboardFilter = proxy({
        product_name: '',
        gtin: '',
        selected_product_category_artkeys: [],
        t1_filter: true,
        t2_filter: true,
        show_stock: true,
        show_purchase: true,
        show_tbo: true,
    })

    gtin_search_value = ''

    relation_search_bar: SearchBarControl | null = null
    product_search_bar_controller: SearchBarControl | null = null

    async oninit() {
        this.watchers.push(watch(this.data.selected_relations, () => {
            if (!this.data.selected_relations.length) {
                $s.context.name = ''
            } else {
                setTimeout(() => {
                    $s.context.name = 'data_card'
                }, 500)
            }

            this.data.selected_relation_artkey = this.data.selected_relations[0]?.artkey
        }))
        this.watchers.push(watch(this.data, 'selected_relation_artkey', () => {
            if (this.data.selected_relation_artkey) {
                this.fetch_relation()
            } else {
                this.data.selected_relation = null
            }
        }))

        const {result: categories_result} = await api.get('discover/product-categories/select')
        this.data.categories = categories_result

        const params = m.route.param() as RelationDashboardUrlParams
        if (params.relation_artkey) {
            this.data.selected_relation_artkey = +params.relation_artkey
        }
        if (params.selected_tab) {
            this.selected_tab = params.selected_tab
        }
        if (params.product_name) {
            this.filters.product_name = params.product_name
        }
        if (params.gtin) {
            this.filters.gtin = params.gtin
            this.gtin_search_value = params.gtin
        }

        this.filters.show_stock = params.show_stock === undefined || params.show_stock
        this.filters.show_purchase = params.show_purchase === undefined || params.show_purchase
        this.filters.show_tbo = params.show_tbo === undefined || params.show_tbo
        this.filters.t1_filter = params.show_t1 === undefined || params.show_t1
        this.filters.t2_filter = params.show_t2 === undefined || params.show_t2
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    update_url_params() {
        let route = m.route.get()
        if (route.includes('?')) {
            route = route.split('?')[0]
        }

        const params: RelationDashboardUrlParams = {
            show_tbo: this.filters.show_tbo,
            show_purchase: this.filters.show_purchase,
            show_stock: this.filters.show_stock,
            show_t1: this.filters.t1_filter,
            show_t2: this.filters.t2_filter,
            selected_tab: this.selected_tab,
            relation_artkey: undefined,
            product_name: undefined,
        }
        if (this.data.selected_relation) {
            params.relation_artkey = this.data.selected_relation.artkey.toString()
        }
        if (this.filters.product_name) {
            params.product_name = this.filters.product_name
        }
        if (this.filters.gtin) {
            params.gtin = this.filters.gtin
        }

        params.selected_tab = this.selected_tab
        m.route.set(route, params)
    }

    on_select_tab(requested_tab: SelectableMainTab): void {
        this.selected_tab = requested_tab
        this.update_url_params()
    }

    async export_to_excel() {
        if (!this.data.selected_relation) {
            return
        }
        this.is_loading_export_to_excel = true
        let customs_status = ''
        if (this.filters.t1_filter !== this.filters.t2_filter) {
            if (this.filters.t1_filter) {
                customs_status = 'T1'
            } else {
                customs_status = 'T2'
            }
        }
        const only_show_type: string[] = []
        if (this.filters.show_tbo) {
            only_show_type.push('tbo')
        }
        if (this.filters.show_purchase) {
            only_show_type.push('purchase')
        }
        if (this.filters.show_stock) {
            only_show_type.push('stock')
        }

        const request = {
            product_name: this.filters.product_name,
            gtin: this.filters.gtin,
            product_category_artkeys: this.filters.selected_product_category_artkeys,
            customs_status: customs_status,
            only_show_type: only_show_type,
        }

        const {
            status_code,
            result: blob,
        } = await api.get<Blob>(`discover/relations/${this.data.selected_relation.artkey}/price-list/excel?sort_by=product_name&filters=${JSON.stringify(request)}`)
        if (status_code === 404) {
            notifier.notify('No records found')
        } else if (status_code < 300) {
            create_download_for_blob(blob, `export-price-list-${this.data.selected_relation.name}.xlsx`)
        } else {
            notifier.notify('Excel cannot be created')
        }

        this.is_loading_export_to_excel = false
        m.redraw()
    }

    fetch_relation(): void {
        this.data.relation_exchange_rate = null
        this.data.selected_relation = null
        const artkey = this.data.selected_relation_artkey
        this.relation_api.get_relation(artkey).subscribe({
            next: (response) => {
                this.data.selected_relation = response
                if (this.data.selected_relations.length === 0) {
                    this.data.selected_relations.push(response)
                }
                if (this.relation_search_bar) {
                    this.relation_search_bar.set_and_submit_search_text(response.name, true)
                }
                this.update_url_params()
                this.data.relation_exchange_rate = $s.currencies.exchange_rates[this.data.selected_relation.currency].rate
            },
            error: () => m.redraw(),
        })
    }

    search_for_search_text = (product_name: string): void => {
        this.filters.product_name = product_name
        this.update_url_params()
    }

    register_tab_view_control(tab_view_control: TabViewControl): void {
        this.tab_view_controls.push(tab_view_control)
    }

    view() {
        return (
            <div className="c-offer-relation-dashboard view-container">
                <div className="view">
                    <div className="btn-toolbar">
                        <Button
                            active={$s.context.name === 'data_card'}
                            className="btn-data-card"
                            disabled={!this.data.selected_relation || this.is_loading_export_to_excel}
                            icon="list"
                            onclick={() => {
                                Object.assign($s.context, {
                                    default: !$s.context.default,
                                    id: $s.context.id === 'data_card' ? null : 'data_card',
                                    name: $s.context.name === 'data_card' ? null : 'data_card',
                                })
                                store.save()
                            }}
                            tip="Toggle Data Card"
                            text="Data Card"
                            type="default"
                            variant="context"
                        />
                        <Button
                            icon="excel"
                            onclick={() => this.export_to_excel()}
                            text={' Export to pricelist'}
                            disabled={!this.data.selected_relation || this.is_loading_export_to_excel}
                            variant="context"
                        />

                    </div>
                    <div className="stats-group-wrapper">
                        <div className="filter-group-wrapper">
                            <div className="c-filter-group vertical">
                                <div className="relation-search-bar">
                                    <RelationsSearch
                                        label="Relation"
                                        is_single_select={true}
                                        selected_relations={this.data.selected_relations}
                                        selected_relation_name={this.data.selected_relations[0]?.name}
                                        keep_results_in_search_bar={true}
                                        search_bar_controller={(controller: SearchBarControl) =>
                                            (this.relation_search_bar = controller)
                                        }
                                    />

                                </div>
                                <SearchBar
                                    label={'Product'}
                                    placeholder={'Filter Product...'}
                                    on_submit={this.search_for_search_text}
                                    search_bar_controller={(sbc: SearchBarControl) => {
                                        this.product_search_bar_controller = sbc
                                        sbc.set_and_submit_search_text(this.filters.product_name, true)
                                    }}
                                    on_get_suggestions$={(filter_text: string) =>
                                        this.product_management_api.get_simple_product_names(filter_text)
                                    }
                                    default_search_text={this.default_product_search}
                                    disabled={!this.data.selected_relation || this.tab_view_controls.find((control) => control.is_loading())}
                                />
                                <FieldText
                                    disabled={!this.data.selected_relation || this.tab_view_controls.find((control) => control.is_loading())}
                                    label={'GTIN'}
                                    model={[this, 'gtin_search_value']}
                                    onfocusout={() => {
                                        this.update_url_params()
                                        this.filters.gtin = this.gtin_search_value
                                    }}
                                    placeholder={'Enter gtin'}
                                />

                                <MultiResultSearchBar
                                    label={'Product categories'}
                                    disabled={!this.data.selected_relation || this.tab_view_controls.find((control) => control.is_loading())}
                                    selection={this.filters.selected_product_category_artkeys}
                                    suggestions={this.data.categories}
                                    placeholder="Filter Product Categories..."
                                />
                            </div>

                            <div className="c-filter-group">
                                <div className="btn-group">
                                    <OnOffButton
                                        is_on={() => this.filters.show_stock}
                                        disabled={!this.data.selected_relation || this.tab_view_controls.find((control) => control.is_loading())}
                                        onclick={() => {
                                            this.filters.show_stock = !this.filters.show_stock
                                            this.update_url_params()
                                        }}
                                    >
                                        Stock
                                    </OnOffButton>
                                    <OnOffButton
                                        is_on={() => this.filters.show_purchase}
                                        disabled={!this.data.selected_relation || this.tab_view_controls.find((control) => control.is_loading())}
                                        onclick={() => {
                                            this.filters.show_purchase = !this.filters.show_purchase
                                            this.update_url_params()
                                        }}
                                    >
                                        Purchase
                                    </OnOffButton>
                                    <OnOffButton
                                        is_on={() => this.filters.show_tbo}
                                        disabled={!this.data.selected_relation || this.tab_view_controls.find((control) => control.is_loading())}
                                        onclick={() => {
                                            this.filters.show_tbo = !this.filters.show_tbo
                                            this.update_url_params()
                                        }}
                                    >
                                        TBO
                                    </OnOffButton>
                                </div>
                                <div className="btn-group">
                                    <OnOffButton
                                        is_on={() => this.filters.t1_filter}
                                        disabled={!this.data.selected_relation || this.tab_view_controls.find((control) => control.is_loading())}
                                        onclick={() => {
                                            this.filters.t1_filter = !this.filters.t1_filter
                                            this.update_url_params()
                                        }}
                                    >
                                        T1
                                    </OnOffButton>
                                    <OnOffButton
                                        is_on={() => this.filters.t2_filter}
                                        disabled={!this.data.selected_relation || this.tab_view_controls.find((control) => control.is_loading())}
                                        onclick={() => {
                                            this.filters.t2_filter = !this.filters.t2_filter
                                            this.update_url_params()
                                        }}
                                    >
                                        T2
                                    </OnOffButton>
                                </div>
                            </div>
                        </div>

                    </div>

                    {this.data.selected_relation && this.data.relation_exchange_rate && (
                        <span>
                            <ul className={'nav nav-tabs'}>
                                <li
                                    role={'presentation'}
                                    className={this.selected_tab === SelectableMainTab.OFFER_ITEMS ? 'active' : ''}
                                >
                                    <a onclick={() => this.on_select_tab(SelectableMainTab.OFFER_ITEMS)}>Offer items</a>
                                </li>
                                <li
                                    role={'presentation'}
                                    className={this.selected_tab === SelectableMainTab.SPECIAL_OFFER_ITEMS ? 'active' : ''}
                                >
                                    <a onclick={() => this.on_select_tab(SelectableMainTab.SPECIAL_OFFER_ITEMS)}>
                                        Special offer items
                                    </a>
                                </li>
                                <li
                                    role={'presentation'}
                                    className={this.selected_tab === SelectableMainTab.HIDDEN_OFFER_ITEMS ? 'active' : ''}
                                >
                                    <a onclick={() => this.on_select_tab(SelectableMainTab.HIDDEN_OFFER_ITEMS)}>
                                        Hidden offer items
                                    </a>
                                </li>
                            </ul>

                            {this.selected_tab === SelectableMainTab.OFFER_ITEMS && (
                                <RelationOfferItems
                                    register_tab_view_control={(control: TabViewControl) =>
                                        this.register_tab_view_control(control)
                                    }
                                    relation_dashboard_data={this.data}
                                    relation_dashboard_filters={this.filters}
                                />
                            )}

                            {this.selected_tab === SelectableMainTab.SPECIAL_OFFER_ITEMS && (
                                <SpecialOfferItems
                                    register_tab_view_control={(control: TabViewControl) =>
                                        this.register_tab_view_control(control)
                                    }
                                    relation_dashboard_data={this.data}
                                    relation_dashboard_filters={this.filters}
                                />
                            )}

                            {this.selected_tab === SelectableMainTab.HIDDEN_OFFER_ITEMS && (
                                <HiddenOfferItems
                                    register_tab_view_control={(control: TabViewControl) =>
                                        this.register_tab_view_control(control)
                                    }
                                    relation_dashboard_data={this.data}
                                    relation_dashboard_filters={this.filters}
                                />
                            )}
                        </span>
                    )}
                </div>
                {this.data.selected_relation && <PanelRelationDetails
                    fetch_relation={this.fetch_relation.bind(this)}
                    relation={this.data.selected_relation}
                    title={$t(`panels.context.title_${$s.context.name}`)}
                />}
            </div>
        )
    }
}
