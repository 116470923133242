import {FieldSelectMany} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {api} from '@bitstillery/common/app'
import {notifier} from '@bitstillery/common/app'

import {CollectionTable} from '@/components/collection_table'
import {icon} from '@/components/icon'
import {header_with_popover} from '@/components/popover/popover'
import {afterUpdate} from '@/_utils'
import {$m} from '@/app'
import {checkbox, select} from '@/components/inputs'

export class CommunicationView extends MithrilTsxComponent<any> {
    constructor(vnode) {
        super()

        this.collection = vnode.attrs.collection

        this.columns = [
            {field: 'name', name: 'Name', sort: true, width: 6},
            {
                field: 'is_supplier',
                function: (record) => checkbox(record.is_supplier, ''),
                name: 'Supplier',
                sort: true,
                width: 2,
            },
            {
                field: 'is_buyer',
                function: (record) => checkbox(record.is_buyer, ''),
                name: 'Buyer',
                sort: true,
                width: 2,
            },
            {
                field: 'should_receive_offer_mails',
                function: (record) => checkbox(record.should_receive_offer_mails, ''),
                header: header_with_popover(
                    'Only relations with this setting enabled will receive offer emails.',
                    m('span', 'Offer mails ', icon('fa-question-circle')),
                ),
                name: 'Offer mails',
                sort: true,
                width: 2,
            },
            {
                field: 'should_receive_purchase_mails',
                function: (record) => checkbox(record.should_receive_purchase_mails, ''),
                header: header_with_popover(
                    'Only relations with this setting enabled will receive purchase enquiries.',
                    m('span', 'Purchase mails ', icon('fa-question-circle')),
                ),
                name: 'Purchase mails',
                sort: true,
                width: 2,
            },
            {
                function: (record) => {
                    return <FieldSelectMany
                        model={record.persons_mailing_enabled()}
                        onchange={(value) => {
                            this.change_prop(record.artkey, 'persons_mailing_enabled', value)
                        }}
                        options={(() => {
                            if (!record.persons_mailing_enabled_options_mapped) {
                                record.persons_mailing_enabled_options_mapped = record.persons_mailing_enabled_options.map((i) => ({value: i[0], label: i[1]}))
                            }
                            return record.persons_mailing_enabled_options_mapped
                        })()}
                        variant="inline"
                    />
                },
                name: 'Offer contacts',
                sort: true,
                width: 7,
            },
            {
                function: (record) => {
                    return <FieldSelectMany
                        model={record.persons_purchase_enquiries_enabled()}
                        onchange={(value) => {
                            this.change_prop(record.artkey, 'persons_purchase_enquiries_enabled', value)
                        }}
                        options={record.persons_purchase_enquiries_enabled_options}
                        variant="inline"
                    />
                },
                name: 'Purchase contacts',
                sort: true,
                width: 7,
            },
            {
                field: 'client_status',
                function: (record) =>
                    select(
                        record.client_status,
                        $m.data.client_statuses.map((i) => [i, i]),
                        {empty_option: true},
                    ),
                name: 'Client Status',
                sort: true,
                width: 4,
            },
            {
                field: 'portal_level',
                function: (record) =>
                    select(record.portal_level, $m.data.supplier_portal_levels, {empty_option: true}),
                name: 'Portal Level',
                sort: true,
                width: 4,
            },
            {
                field: 'company_type',
                function: (record) =>
                    select(
                        record.company_type,
                        $m.data.company_types.map((i) => [i, i]),
                        {empty_option: true},
                    ),
                name: 'Company Type',
                sort: true,
                width: 4,
            },
            {
                field: 'operates_online',
                function: (record) => checkbox(record.operates_online, ''),
                name: 'Operates Online',
                sort: true,
                width: 4,
            },
        ]
    }

    async change_prop(artkey, property_name, value) {
        const data = {artkey}
        data[property_name] = value

        await api.post('suppliers.update_relation_communication_settings', data, false)

        notifier.notify('Updated!', 'success', 1500)

        // Sync up underlying relations collection. This is done client side and not be fetching from server.
        // For the flattened properties extra effort has to be done to sync the collection.
        const relation = this.collection.items().filter((rel) => String(rel.artkey) === String(artkey))[0]
        if (property_name === 'persons_mailing_enabled') {
            relation.contact_persons.forEach((i) => (i.should_receive_mails = false))
            relation.contact_persons
                .filter((i) => i.artkey in value)
                .forEach((i) => (i.should_receive_mails = true))
        } else if (property_name === 'persons_purchase_enquiries_enabled') {
            relation.contact_persons.forEach((i) => (i.should_receive_purchase_enquiries = false))
            relation.contact_persons
                .filter((i) => i.artkey in value)
                .forEach((i) => (i.should_receive_purchase_enquiries = true))
        } else if (!['persons_mailing_enabled', 'persons_purchase_enquiries_enabled'].includes(property_name)) {
            this.collection.update_item_property(artkey, property_name, value)
        }
    }

    on_prop_change(record, prop_name, method) {
        return afterUpdate(method.bind(this, record.artkey, prop_name), prop(record[prop_name]))
    }

    row_model(r) {
        const persons_mailing_enabled = r.contact_persons
            .filter((i) => i.should_receive_mails && i.is_deleted === false)
            .map((i) => String(i.artkey))
        r.persons_mailing_enabled = persons_mailing_enabled
        r.persons_mailing_enabled = this.on_prop_change(r, 'persons_mailing_enabled', this.change_prop)
        r.persons_mailing_enabled_options = r.contact_persons
            .filter((i) => i.is_deleted === false)
            .map((i) => [String(i.artkey), i.emailaddress])

        const persons_purchase_enquiries_enabled = r.contact_persons
            .filter((i) => i.should_receive_purchase_enquiries && i.is_deleted === false)
            .map((i) => String(i.artkey))
        r.persons_purchase_enquiries_enabled = persons_purchase_enquiries_enabled
        r.persons_purchase_enquiries_enabled = this.on_prop_change(
            r,
            'persons_purchase_enquiries_enabled',
            this.change_prop,
        )
        r.persons_purchase_enquiries_enabled_options = r.contact_persons
            .filter((i) => i.is_deleted === false)
            .map((i) => ({value: String(i.artkey), label: i.emailaddress}))

        r.is_supplier = this.on_prop_change(r, 'is_supplier', this.change_prop)
        r.is_buyer = this.on_prop_change(r, 'is_buyer', this.change_prop)
        r.should_receive_offer_mails = this.on_prop_change(r, 'should_receive_offer_mails', this.change_prop)
        r.should_receive_purchase_mails = this.on_prop_change(r, 'should_receive_purchase_mails', this.change_prop)
        r.client_status = this.on_prop_change(r, 'client_status', this.change_prop)
        r.company_type = this.on_prop_change(r, 'company_type', this.change_prop)
        r.operates_online = this.on_prop_change(r, 'operates_online', this.change_prop)
        r.portal_level = this.on_prop_change(r, 'portal_level', this.change_prop)

        return r
    }

    view(vnode: m.Vnode<any>) {
        return <CollectionTable
            key="relation_communication"
            collection={vnode.attrs.collection}
            options={{
                autoscale: true,
                search_table_style: true,
                sticky_header: true,
                with_buttons: true,
            }}
            row_model={this.row_model.bind(this)}
            columns={this.columns}
        />
    }
}
