/** llm:tested */
import m from 'mithril'
import {head} from 'prelude-ls'
import {FieldMoney, Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier, api} from '@bitstillery/common/app'

import {call} from '@/api'
import {button_with_icon, icon_button} from '@/components/_buttons'
import {view, Controller} from '@/components/collection/autocomplete'
import {SalesOrderItem} from '@/sales_orders/models'
import {Bottle} from '@/models/bottles'
import {Item} from '@/models/stock'
import {convert_from_euro_to_currency} from '@/factserver_api/currencies'
import {$m, $s} from '@/app'

export class AddItemToSalesOrderClassic extends MithrilTsxComponent<unknown> {
    sales_order: any
    sales_order_item_added: any
    sellable_products: any
    is_collapsed: boolean
    is_focused: boolean
    loading_products: any
    loading: any
    autocomplete_ctrl: any
    product: any
    product_name: any
    bottle: any
    sellable_bottles: any
    sales_order_item: any

    constructor(vnode: any) {
        super()
        this.sales_order = vnode.attrs.sales_order
        this.sales_order_item_added = vnode.attrs.sales_order_item_added
        this.sellable_products = window.prop([])
        this.is_collapsed = true
        this.is_focused = this.is_collapsed

        this.init()

        this.loading_products = window.prop(false)
        this.loading = window.prop(false)

        this.autocomplete_ctrl = new Controller({
            input_container_id: '#search_input_container',
            on_submit_suggestion: async(e) => await this.on_submit_suggestion(e),
        })

        // Start loading the products immediately.
        this.load_sellable_products()
    }

    init() {
        this.product = window.prop('')
        this.product_name = window.prop('')

        this.bottle = window.prop(new Bottle())
        this.sellable_bottles = window.prop([])

        this.sales_order_item = window.prop(new SalesOrderItem())
        this.sales_order_item().sales_order = this.sales_order
    }

    reset_search() {
        this.init()
        this.autocomplete_ctrl.oninput('')
    }

    load_sellable_products() {
        if (this.sellable_products().length === 0) {
            this.loading_products(true)
            call('sales.items.get_sellable_products_with_bottles', {}, (resp) => this.set_sellable_products(resp))
        }
    }

    set_sellable_products(resp: any) {
        if (resp.success) {
            this.sellable_products($m.products.create_products(resp.result))
            const sellable_product_names = this.sellable_products().map((product: any) => product.name())
            this.autocomplete_ctrl.set_suggestions(sellable_product_names)
        } else {
            $m.common.generic_error_handler()
        }
        this.loading_products(false)
        m.redraw()
    }

    select_product(value: string, is_suggestion = false) {
        this.product_name(value)
        this.autocomplete_ctrl.oninput(value, is_suggestion)
        this.product(this.sellable_products().filter((p: any) => p.name() === value).at(0))
        this.reset_bottle()
        this.reset_item()
        m.redraw()
    }

    async on_submit_suggestion(value: string) {
        this.select_product(value, true)

        const {result: bottles} = await api.get(
            'discover/products/' + this.product().artkey() + '/sellable-bottles?relation_artkey=' + this.sales_order().supplier().artkey(),
        )

        this.sellable_bottles($m.bottles.create_bottles(bottles, this.product()))
        m.redraw()
    }

    async set_bottle(bottle_artkey: number) {
        if (bottle_artkey) {
            this.bottle(this.sellable_bottles().filter((b: any) => b.artkey() === +bottle_artkey).at(0))

            let request_url = `discover/bottles/${bottle_artkey}/sellable-items/${this.sales_order().supplier().artkey()}`
            if (this.sales_order().was_sold_in()) {
                request_url += `?sales_order_currency=${this.sales_order().was_sold_in()}`
            }

            const {result: items} = await api.get(request_url)
            this.bottle().items(items.map((item: any) => new Item(item)))
            this.bottle().items().sort((first: any, second: any) => first.stock_age() > second.stock_age() ? -1 : 1)
            m.redraw()
        }

        this.reset_item()
    }

    reset_bottle() {
        this.bottle(new Bottle())
        this.sellable_bottles([])
    }

    set_item(item_artkey: number) {
        if (item_artkey) {
            const item = head(this.bottle().items().filter((i: any) => i.artkey() === +item_artkey))

            this.sales_order_item().item(item)

            // Set the initial value of the price input.
            if (this.sales_order().supplier().name() === $m.data.a2bc_supplier_name) {
                // For A2BC BV, the sales prices always have a 2% margin over the
                // purchase price. Note that we hardcoded both the margin and the
                // supplier name here. Both references are removed if and when the
                // default-margin-per-supplier field is implemented.
                // For calculating the 2%, the sales price is the baseline, so we
                // divide by 0.98 - the purchase price is 2% below the sales price.
                // We also convert to the sales order's currency using the portal
                // exchange rate.
                this.sales_order_item().price_per_case(convert_from_euro_to_currency(
                    +item.euro_was_bought_for() / 0.98,
                    this.sales_order().was_sold_in(),
                    $s.currencies.exchange_rates[this.sales_order().was_sold_in()].portal_rate,
                ).toFixed(2))
            } else if (item.relation_sales_price()) {
                this.sales_order_item().price_per_case((+item.relation_sales_price()).toFixed(2))
            } else if (item.purchase_order_item().suggested_price_per_case()) {
                this.sales_order_item().price_per_case(item.purchase_order_item().suggested_price_per_case())
            } else {
                // TODO no target price known for this item
                this.sales_order_item().price_per_case('')
            }
        } else {
            this.reset_item()
        }
    }

    reset_item() {
        this.sales_order_item().item(new Item())
    }

    add_to_order() {
        this.loading(true)

        const data = {
            sales_order_artkey: this.sales_order_item().sales_order().artkey(),
            item_artkey: this.sales_order_item().item().artkey(),
            number_of_cases: this.sales_order_item().number_of_cases(),
            price_per_case: this.sales_order_item().price_per_case(),
        }

        call('sales.items.add_item_to_sales_order', data, (e) => this.handle_added_sales_order_item(e))
    }

    handle_added_sales_order_item(resp: any) {
        this.loading(false)
        this.is_focused = true

        if (resp.success) {
            notifier.notify('The item was added successfully.', 'success')
            this.sales_order_item().number_of_cases('')
            this.sales_order_item().price_per_case('')
            this.sales_order_item().item().artkey('')
            this.product = window.prop('')
            this.product_name = window.prop('')

            this.bottle = window.prop(new Bottle())
            this.sellable_bottles = window.prop([])

            if (this.sales_order_item_added) {
                this.sales_order_item_added()
            }
        } else {
            notifier.notify(resp.message, 'danger')
        }

        document.getElementById('product_name_input')?.focus()
    }

    onremove() {
        this.autocomplete_ctrl.onremove()
    }

    view() {
        return <div class="mwrap">
            {button_with_icon('Add product to order (classic)', 'plus', {
                class: 'btn btn-default mb-2',
                'data-target': '#add_item_classic',
                'data-toggle': 'collapse',
                onclick: () => {
                    this.is_collapsed = !this.is_collapsed
                    return true
                },
            })}

            <div class="collapse" id="add_item_classic">
                {this.loading_products() ? <Spinner /> :
                    <div class="fieldset largest">
                        <div class="field-group">
                            <div class="field">
                                <label>Product</label>
                                <div class="control">
                                    <input
                                        id="product_name_input"
                                        value={this.product_name()}
                                        type="text"
                                        onclick={(e: any) => this.autocomplete_ctrl.onclick(e)}
                                        onkeydown={(e: any) => this.autocomplete_ctrl.onkeydown(e)}
                                        oninput={(ev: any) => this.select_product(ev.target.value)}
                                        onupdate={(event: any) => {
                                            if (this.is_collapsed !== this.is_focused) {
                                                this.is_focused = this.is_collapsed
                                                if (!this.is_collapsed) {
                                                    event.dom.focus()
                                                }
                                            }
                                        }}
                                        autocomplete="off"
                                    />

                                    {view(this.autocomplete_ctrl)}

                                    {icon_button('remove-sign', {
                                        class: 'btn-default',
                                        tabindex: -1,
                                        onclick: () => this.reset_search(),
                                    })}
                                </div>
                            </div>

                            <div class="c-field-select field">
                                <label>Specs</label>
                                <select
                                    required={true}
                                    onchange={(ev: any) => this.set_bottle(+ev.target.value)}
                                >
                                    <option selected value="">-</option>
                                    {this.sellable_bottles() &&
                                        this.sellable_bottles().sort((a, b) => +a.volume - +b.volume).map((bottle: any) =>
                                            <option
                                                value={bottle.artkey()}
                                                selected={this.bottle().artkey() === bottle.artkey()}
                                            >
                                                {bottle.to_specs()}
                                            </option>,
                                        )
                                    }
                                </select>
                            </div>

                            <div class="c-field-select field">
                                <label>Item</label>
                                <select
                                    required={true}
                                    onchange={(ev: any) => this.set_item(+ev.target.value)}
                                >
                                    <option selected value="">-</option>
                                    {this.product() && this.bottle() &&
                                        this.bottle().items().map((item: any) => {
                                            const was_bought_for = convert_from_euro_to_currency(
                                                +item.euro_was_bought_for(),
                                                this.sales_order().was_sold_in(),
                                            )
                                            let option_text = item.lot() ? item.lot() : item.purchase_order_item().purchase_order().reference()
                                            option_text += ` - In: ${was_bought_for.formatMoney()} ${this.sales_order().was_sold_in()}`

                                            if (item.relation_sales_price()) {
                                                if (item.relation_sales_price() === item.relation_base_price()) {
                                                    option_text += ' - Target: '
                                                } else {
                                                    option_text += ' - Offer: '
                                                }
                                                option_text += `${(+item.relation_sales_price()).formatMoney()} ${this.sales_order().was_sold_in()}`
                                            }

                                            option_text += ` - ${item.case().number_of_bottles()} btl/cs`
                                            option_text += ` - ${item.number_of_cases_available()} available`
                                            option_text += ` - ${item.case().customs_status()}`
                                            if (item.case().gift_box_type()) {
                                                option_text += ` - ${item.case().gift_box_type()}`
                                            }
                                            if (item.case().tax_label()) {
                                                option_text += ` - ${item.case().tax_label()}`
                                            }
                                            if (item.case().no_eu_address()) {
                                                option_text += ' - No EU address'
                                            }

                                            return <option
                                                value={item.artkey()}
                                                selected={item.artkey() === this.sales_order_item().item().artkey()}
                                            >
                                                {option_text}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>

                            <div class="c-field-number field">
                                <label>Cases</label>
                                <input
                                    value={this.sales_order_item().number_of_cases()}
                                    oninput={(ev: any) => this.sales_order_item().number_of_cases(ev.target.value)}
                                    type="number"
                                    step={1}
                                    min={1}
                                    required={true}
                                />
                            </div>

                            <FieldMoney
                                currency={[this.sales_order(), 'was_sold_in']}
                                label={this.sales_order().includes_excise() ? 'Price per case (incl. excise)' : 'Price per case'}
                                model={[this.sales_order_item(), 'price_per_case']}
                                placeholder="Price per case"
                                required={true}
                            />

                            <div class="field">
                                <label>{m.trust('&nbsp;')}</label>
                                <button
                                    class="btn btn-success btn-submit"
                                    onclick={() => this.add_to_order()}
                                >
                                    {this.loading() ? <Spinner /> : 'Add product'}
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    }
}
