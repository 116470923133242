import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    ButtonGroup,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Country,
    PanelFilters,
    RowActionDelete,
    RowActionEdit,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api} from '@bitstillery/common/app'
import {proxy} from '@bitstillery/common/lib/proxy'
import {notifier} from '@bitstillery/common/app'

import {GetProductCategoryCollectionResponse} from '@/factserver_api/fact2server_api'
import {$s} from '@/app'
import {PanelProductCategory} from '@/data/product_categories/panel_product_category'

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Name',
        render: (row: GetProductCategoryCollectionResponse) => row.name,
    },
    {
        name: 'VAT rate',
        render: (row: GetProductCategoryCollectionResponse) => <span>
            <div>
                <Country country_code={'nl'}/>
                {` ${row.nl_vat_rate_percentage}%`}
            </div>
            <div>
                <Country country_code={'de'}/>
                {` ${row.de_vat_rate_percentage}%`}
            </div>
            <div>
                <Country country_code={'be'}/>
                {` ${row.be_vat_rate_percentage}%`}
            </div>
        </span>,
    },
    {
        name: 'CBS code',
        render: (row: GetProductCategoryCollectionResponse) => <span>
            <div>{row.cbs_code}</div>
            {row.cbs_code_gte_200 && <div>{`>= 200: ${row.cbs_code_gte_200}`}</div>}
            {row.cbs_code_gte_1000 && <div>{`>= 1000: ${row.cbs_code_gte_1000}`}</div>}
        </span>,
    },
    {
        name: 'Parent category',
        render: (row: GetProductCategoryCollectionResponse) => row.parent_product_category_name,
    },
    {
        name: 'Bottle type',
        render: (row: GetProductCategoryCollectionResponse) => row.default_product_bottle_type,
    },
]

export class ProductCategoryList extends MithrilTsxComponent<unknown> {

    edit_model = proxy({
        product_category_artkey: null,
    })

    oninit() {
        collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: 'discover/product-categories/collection-view',
            },
        })
    }

    view(_vnode: m.Vnode<any>) {
        return <div className="c-product-categories-list view-container">
            <PanelFilters collection={collection}/>
            <CollectionView mode="table">

                <div className="btn-toolbar">
                    <ButtonGroup classname="ml-2">
                        <Button
                            active={$s.context.id === null && $s.context.name === 'edit_spli'}
                            icon="plus"
                            text="Add Product Category"
                            onclick={() => {
                                Object.assign($s.context, {
                                    data: {
                                        artkey: null,
                                    },
                                    id: null,
                                    name: 'add_product_category',
                                    title: 'Add Product Category',
                                })
                            }}
                            type="info"
                            variant="context"
                        />
                    </ButtonGroup>
                </div>

                <CollectionHeader collection={collection} columns={columns}/>
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row: GetProductCategoryCollectionResponse) => {
                        return [
                            <RowActionEdit
                                collection={collection}
                                context={{
                                    mode: 'panel',
                                    name: 'edit_product_category',
                                    title: 'Edit Product Category',
                                }}
                                mode="panel"
                                row={row}
                                type="default"
                            />,
                            <RowActionDelete
                                icon={'trash'}
                                row={row}
                                row_delete={async() => {
                                    const {
                                        status_code,
                                        result,
                                    } = await api.delete(`/discover/product-categories/${row.artkey}`)
                                    if (status_code > 299) {
                                        notifier.notify(`Cannot delete the category: ${result.detail}`)
                                        return
                                    }
                                    notifier.notify('Category deleted')
                                    collection.soft_delete(row.artkey)
                                }}
                                variant="deactivate"
                            />,

                        ]
                    }}
                />
            </CollectionView>
            <PanelProductCategory
                collection={collection}
                icon={'list'}
                edit_model={this.edit_model}
            />
        </div>
    }
}
