import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {notifier} from '@bitstillery/common/app'
import {Amount, FieldMoney, FieldSelect, Spinner} from '@bitstillery/common/components'

import {UserDropDown} from '../components/users'
import {PercentInput} from '../components/decimal_input'

import {SalesApi, Target} from '@/factserver_api/sales_api'
import {SaveButton} from '@/components/buttons'

export default class SalesTarget extends MithrilTsxComponent<unknown> {
    sales_api = new SalesApi()

    now = DateTime.local()
    // all available years in the data. Selectable with a drop down.
    years: number[] = []
    selected_year: number = this.now.year
    // all targets from the api response. This is all the data for the user (or the account if no user selected).
    targets: Target[] = []
    selected_user_artkey = ''
    is_loading = false

    oncreate(): void {
        this.fetch_targets_for_user()
    }

    fetch_targets_for_user(): void {
        this.is_loading = true
        this.targets = []
        this.sales_api
            .get_targets_for_user({
                user_artkey: this.selected_user_artkey ? +this.selected_user_artkey : undefined,
            })
            .subscribe((response) => {
                this.is_loading = false
                if (response) {
                    this.targets = response.targets
                    const has_this_year = this.targets.find((target) => target.year === this.now.year) !== undefined
                    const has_next_year = this.targets.find((target) => target.year === this.now.year + 1) !== undefined

                    // add some placeholders for missing years (first time or next year).
                    if (!has_this_year) {
                        Array.from({length: 12}, (x, i) => i + 1).forEach((month) =>
                            this.targets.push({
                                year: this.now.year,
                                month: month,
                                gross_margin_percentage: 0,
                                turnover: 0,
                            }),
                        )
                    }
                    if (!has_next_year) {
                        Array.from({length: 12}, (x, i) => i + 1).forEach((month) =>
                            this.targets.push({
                                year: this.now.year + 1,
                                month: month,
                                gross_margin_percentage: 0,
                                turnover: 0,
                            }),
                        )
                    }
                    this.years = Array.from(new Set(this.targets.map((target) => target.year))).sort()

                    // reset year to now if user has no data for currently selected year.
                    if (!this.targets.map((target) => target.year).includes(this.selected_year)) {
                        this.selected_year = this.now.year
                    }
                    m.redraw()
                }
            })
    }

    save(): void {
        this.sales_api
            .create_or_update_targets({
                year: this.selected_year,
                user_artkey: this.selected_user_artkey ? +this.selected_user_artkey : undefined,
                targets: this.targets.filter((target) => target.year === this.selected_year),
            })
            .subscribe(() => {
                this.fetch_targets_for_user()
                notifier.notify('Targets updated', 'info')
                m.redraw()
            })
    }

    month_year_to_datetime(month: number, year: number): DateTime {
        return DateTime.fromFormat(`${year}-${month}-01`, 'yyyy-M-dd')
    }

    view(): m.Children | void | null {
        return (
            <div className="c-sales-targets view">
                <div className="c-filter-group">
                    <FieldSelect
                        model={[this, 'selected_year']}
                        options={this.years.map((year) => ({label: `${year}`, value: `${year}`}))}
                    />

                    <UserDropDown
                        model={[this, 'selected_user_artkey']}
                        onchange={(user_artkey: string | null) => {
                            this.selected_user_artkey = user_artkey || ''
                            this.fetch_targets_for_user()
                        }}
                        placeholder="Global for account"
                    />
                </div>

                <div className="c-targets-forecasts fieldset">
                    {this.is_loading && <Spinner />}
                    {(() => {
                        if (this.loading || this.targets.length === 0) return
                        return this.targets
                            .filter((target) => target.year === this.selected_year)
                            .map((target) => (
                                <div className="field-group" key={`target-${this.selected_user_artkey}-${target.year}${target.month}-${target.artkey || 0}`}>
                                    <div className="field center">
                                        <label>{this.month_year_to_datetime(target.month, target.year).toFormat('MMMM')}</label>
                                    </div>
                                    <FieldMoney
                                        min={0}
                                        model={[target, 'turnover']}
                                        required={true}
                                        show_addon={false}
                                    />

                                    <PercentInput
                                        minimum={0}
                                        required={true}
                                        value={+target.gross_margin_percentage}
                                        on_value={(value: number) => (target.gross_margin_percentage = value)}
                                    />

                                    <Amount
                                        className="field center"
                                        currency={'EUR'}
                                        amount={(target.gross_margin_percentage / 100) * target.turnover}
                                    />

                                </div>
                            ))
                    })()}
                    {!this.is_loading && this.targets.length > 0 && (
                        <SaveButton onclick={() => this.save()} />
                    )}
                </div>
            </div>
        )
    }
}
