/** llm:tested */
import m from 'mithril'
import {odd, orList} from 'prelude-ls'
import {Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import api from '@/api'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import confirmation from '@/components/confirmation'
import fixedTableHeader from '@/components/table/fixed_header'

interface CommentTemplateData {
    artkey: string
    title: string
    body: string
}

export class CommentTemplatesList extends MithrilTsxComponent<any> {
    comment_templates: any
    search_input_ctrl: any

    constructor() {
        super()
        this.comment_templates = new Collection({
            api_function_name: 'comment_templates.get_all',
            filter_function: this.is_match,
            query_limit: 25,
            sort_order: [
                {name: 'title', direction: 'asc'},
            ],
            default_sort_by: 'title',
            default_sort_order: 'asc',
        })

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.comment_templates,
            placeholder: 'Search for title and/or body...',
        })
    }

    oncreate() {
        this.comment_templates.init()
    }

    is_match(comment_template: CommentTemplateData, term: string) {
        return orList([
            comment_template.title.toLowerCase().indexOf(term) > -1,
            comment_template.body.toLowerCase().indexOf(term) > -1,
        ])
    }

    select_comment_template(artkey: string) {
        m.route.set('/data/commenttemplates/' + artkey + '/edit')
    }

    delete_comment_template(artkey: string) {
        const data = {artkey}
        api.call('comment_templates.delete_comment_template', data, () => {
            this.comment_templates.soft_delete(artkey)
        })
    }

    onremove() {
        this.search_input_ctrl.onremove()
    }

    view() {
        return (
            <div class="c-comment-templates view">
                <div class="btn-toolbar">
                    <button
                        class="btn btn-default"
                        type="button"
                        onclick={() => m.route.set('/data/commenttemplates/create')}
                    >
                        <span class="glyphicon glyphicon-plus"></span> Create Comment template
                    </button>
                </div>

                <div class="c-filter-group">
                    {SearchInput.view(this.search_input_ctrl)}
                </div>

                {fixedTableHeader.with_buttons(
                    <table class="table search-table clickable">
                        <thead class="thead-default">
                            <tr>
                                <th onclick={this.comment_templates.sort.bind(this, 'title')}>
                                    Title {this.comment_templates.sort_icon('title')}
                                </th>
                                <th onclick={this.comment_templates.sort.bind(this, 'body')}>
                                    Body {this.comment_templates.sort_icon('body')}
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.comment_templates.search_result().map((comment_template: CommentTemplateData, index: number) => (
                                <tr class={odd(index) ? 'odd' : 'even'}>
                                    <td
                                        class="col-sm-3"
                                        onclick={() => this.select_comment_template(comment_template.artkey)}
                                        style="cursor: pointer"
                                    >
                                        {comment_template.title}
                                    </td>
                                    <td
                                        class="col-sm-8"
                                        onclick={() => this.select_comment_template(comment_template.artkey)}
                                        style={{overflow: 'ellipsis'}}
                                    >
                                        {comment_template.body}
                                    </td>
                                    <td class="btn-toolbar">
                                        <div class="btn-group">
                                            <button
                                                class="btn btn-danger"
                                                type="button"
                                                onclick={() => confirmation.show({
                                                    title: 'Delete comment template',
                                                    message: 'Are you sure you want to delete this comment template?',
                                                    onconfirm: () => this.delete_comment_template(comment_template.artkey),
                                                    unique_name: 'comment_template_item_delete_confirm',
                                                })}
                                            >
                                                <span class="glyphicon glyphicon-remove"></span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!this.comment_templates.search_result().length && !this.comment_templates.loading() &&
                                <tr><td colspan="100%">No results found.</td></tr>
                            }
                            {this.comment_templates.loading() &&
                                <tr><td colspan="100%"><Spinner /></td></tr>
                            }
                            {this.comment_templates.can_show_more_items() &&
                                <tr>
                                    <td colspan="100%">
                                        <button
                                            class="btn btn-info"
                                            onclick={this.comment_templates.show_more}
                                            id="show-more"
                                        >
                                            Show more results.
                                        </button>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>,
                )}
            </div>
        )
    }
}
