import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import beautify from 'xml-beautifier'
import {notifier} from '@bitstillery/common/app'
import {FieldSelect} from '@bitstillery/common/components'

import {CommunicationApi, GetLoenderslootMessagesResponse, loendersloot_message_status} from './communication_api'

import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {DangerButton, SuccessButton} from '@/components/buttons'

class LoenderslootMessageXML extends MithrilTsxComponent<
    CollectionTableRowComponentProps<GetLoenderslootMessagesResponse, void>
> {
    view(vnode: m.Vnode<CollectionTableRowComponentProps<GetLoenderslootMessagesResponse, void>>): m.Children {
        if (!vnode.attrs.row.is_showing_details) {
            return <span />
        }

        return (
            <div className={'col-sm-12'} style={'white-space: pre;'}>
                {beautify(vnode.attrs.row.raw_xml)}
            </div>
        )
    }
}

export default class LoenderslootMessages extends MithrilTsxComponent<unknown> {
    api = new CommunicationApi()
    message_fetcher = new PagedCollectionFetcher<unknown>(
        'communication.get_loendersloot_messages',
        'created_on',
        undefined,
        25,
        false,
    )

    delete_message(row: GetLoenderslootMessagesResponse): void {
        this.api.delete_loendersloot_message(row.artkey).subscribe({
            next: () => {
                notifier.notify('Successfully deleted loendersloot message', 'success')
                this.message_fetcher.reset_and_query()
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    resolve_message(row: GetLoenderslootMessagesResponse): void {
        this.api.resolve_loendersloot_message(row.artkey).subscribe({
            next: () => {
                notifier.notify('Updated loendersloot message status to processed', 'success')
                this.message_fetcher.reset_and_query()
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    view(): m.Children {
        return (
            <div className="c-messages-loendersloot view">
                <div className="c-filter-group">
                    <div className="filter-field vertical">
                        <label>Message status</label>
                        <FieldSelect
                            model={[this.message_fetcher.filters, 'loendersloot_message_status']}
                            onchange={() => {
                                this.message_fetcher.reset_and_query()
                            }}
                            options={[
                                {value: loendersloot_message_status.RECEIVED, label: 'Unprocessed'},
                                {value: loendersloot_message_status.PROCESSED, label: 'Processed'},
                                {value: loendersloot_message_status.FAILED, label: 'Failed'},
                            ]}
                            placeholder="Select Message status..."
                        />
                    </div>
                </div>
                <CollectionTable<GetLoenderslootMessagesResponse, void>
                    collection_fetcher={this.message_fetcher}
                    on_row_click={(row: GetLoenderslootMessagesResponse) =>
                        (row.is_showing_details = !row.is_showing_details)
                    }
                    on_row_click_component={LoenderslootMessageXML}
                >
                    <CollectionTableColumn
                        header_title={() => 'Created on'}
                        data_field={(row: GetLoenderslootMessagesResponse) => row.created_on}
                    />
                    <CollectionTableColumn
                        header_title={() => 'Type'}
                        data_field={(row: GetLoenderslootMessagesResponse) => {
                            if (row.message_type) {
                                return row.message_type.replace('_', ' ')
                            }
                        }}
                    />
                    <CollectionTableColumn
                        header_title={() => 'Message status'}
                        data_field={(row: GetLoenderslootMessagesResponse) =>
                            row.status === loendersloot_message_status.RECEIVED
                                ? 'unprocessed'
                                : row.status.toLowerCase()
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Number of retries'}
                        data_field={(row: GetLoenderslootMessagesResponse) =>
                            row.number_of_retries === 0 ? '-' : row.number_of_retries
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => 'Failure reason'}
                        data_field={(row: GetLoenderslootMessagesResponse) => row.last_reason_of_failure}
                    />
                    <CollectionTableColumn
                        header_title={() => 'Next retry'}
                        data_field={(row: GetLoenderslootMessagesResponse) =>
                            row.next_retry_moment !== null
                                ? DateTime.fromISO(row.next_retry_moment).toFormat('yyyy-MM-dd HH:mm:ss')
                                : '-'
                        }
                    />
                    <CollectionTableColumn
                        header_title={() => ''}
                        data_field={(row: GetLoenderslootMessagesResponse) => (
                            <div>
                                {row.status !== loendersloot_message_status.PROCESSED && (
                                    <div class={'pull-right btn-group'}>
                                        <SuccessButton
                                            title={' '}
                                            tooltip={'Manually resolved'}
                                            icon_class={'glyphicon glyphicon-ok'}
                                            onclick={() => this.resolve_message(row)}
                                        />
                                        <DangerButton
                                            tooltip={'Delete'}
                                            icon_class={'glyphicon glyphicon-trash'}
                                            onclick={() => this.delete_message(row)}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </CollectionTable>
            </div>
        )
    }
}
