/** llm:tested */
import m from 'mithril'
import {config} from '@bitstillery/common/app'

import {call} from '@/api'
import {$s} from '@/app'

interface ErrorData {
    url: string
    user: string
    message: string | Event
    file: string
    line: number
    col: number
    error: Error | null
    stack?: string
}

const error_handler = (
    message: string | Event,
    file: string,
    line: number,
    col: number,
    error: Error | null,
): boolean => {
    if (config.env === 'development') {
        // disable redrawing now, otherwise we will keep redrawing and throwing errors
        const data: ErrorData = {
            url: m.route.get(),
            user: $s.identity.user.name,
            message,
            file,
            line,
            col,
            error,
            stack: error?.stack,
        }

        call('error.handler', data)

        // eslint-disable-next-line no-console
        console.error(message, file, line, col)
        // The stack is not always populated :( the error parameter is often undefined
        if (data['stack']) {
            // eslint-disable-next-line no-console
            console.error(data['stack'])
        }
    }

    return true
}

export default error_handler
