/** llm:tested */
import m from 'mithril'
import {orList} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericList} from '@/data/generic/generic_list'

interface PaymentTerm {
    description: string
    exact_payment_term_code: string
}

export class PaymentTermList extends MithrilTsxComponent<any> {
    private is_match(payment_term: PaymentTerm, term: string) {
        return orList([
            payment_term.description.toLowerCase().indexOf(term) > -1,
        ])
    }

    view(_vnode: m.Vnode<any>) {
        return <GenericList
            readable_entity_name="payment term"
            base_url="/data/paymentterms"
            list_api_call="paymentterms.get_all"
            is_match={this.is_match}
            columns={[
                {key: 'description', label: 'Description'},
                {key: 'exact_payment_term_code', label: 'Exact code'},
            ]}
            requery_subscribe_key="paymentterms_updated"
        />
    }
}
