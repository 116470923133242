/** llm:tested */
import m from 'mithril'
import {Amount, FieldMoney} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {PurchaseOrderDetails} from './components/purchase_order_details'

import {number, date} from '@/components/inputs'
import {call, callAndThen} from '@/api'
import {format_date_html5} from '@/_utils'
import {BottleDetails} from '@/components/market_info/bottle_details'
import {with_buttons} from '@/components/table/fixed_header'
import {PurchaseOrderStatus} from '@/models/purchase_orders'
import {$m, $s} from '@/app'

interface PurchaseOrderItem {
    artkey: () => number
    suggested_price_per_case: () => number
    bottle: () => any
    number_of_bottles_per_case: () => number
    gift_box_type: () => string
    number_of_cases: () => number
    was_bought_for: () => number
    purchase_order: () => any
    bottle_artkey: () => number
}

export default class ConfirmPurchaseOrder extends MithrilTsxComponent<unknown> {
    saving: any
    purchase_order: () => any

    constructor() {
        super()
        this.saving = window.prop(false)
        this.purchase_order = window.prop(null)
        const purchase_order_artkey = +m.route.param('artkey')
        if (purchase_order_artkey) {
            this.load_purchase_order(+purchase_order_artkey)
        }
    }

    load_purchase_order(purchase_order_artkey: number) {
        const data = {
            purchase_order_artkey: purchase_order_artkey,
        }
        call('purchase.core.get_purchase_orders_with_items', data, this.set_purchase_order)
    }

    set_purchase_order = (resp: any) => {
        if (resp.success) {
            this.purchase_order($m.purchase_orders.create_purchase_order(resp.result[0]))
            if (![PurchaseOrderStatus.SAVED, PurchaseOrderStatus.CONFIRMED].includes(this.purchase_order().status())) {
                notifier.notify('This order does not have the status saved. Only saved orders can be confirmed.', 'info')
                m.route.set('/purchase_orders/manage/' + this.purchase_order().artkey())
            }

            // Set default confirmation date values.
            if (!this.purchase_order().confirmation_date()) {
                this.purchase_order().confirmation_date(format_date_html5(new Date()))
            }

            // Calculate the rate ratio between the currency of the warehouse
            // and the currency of the purchase order.
            const warehouse_rate = $s.currencies.exchange_rates[this.purchase_order().target_warehouse().currency()].rate
            const order_rate = +this.purchase_order().bought_against_rate()
            const warehouse_to_order_rate = order_rate / warehouse_rate

            // Set default warehouse costs values.
            if (!this.purchase_order().warehouse_base_costs()) {
                this.purchase_order().warehouse_base_costs(this.purchase_order().target_warehouse().warehouse_base_costs() * warehouse_to_order_rate)
            }
            if (!this.purchase_order().warehouse_costs_per_case()) {
                this.purchase_order().warehouse_costs_per_case(this.purchase_order().target_warehouse().warehouse_costs_per_case() * warehouse_to_order_rate)
            }
        }
    }

    submit = () => {
        if (!this.purchase_order().confirmation_date()) {
            notifier.notify('Please fill out a valid confirmation date.', 'danger')
            return
        }
        if (!this.purchase_order().expected_delivery_date()) {
            notifier.notify('Please fill out a valid expected delivery date.', 'danger')
            return
        }
        if (!this.purchase_order().bought_against_rate()) {
            notifier.notify('Please fill out a valid exchange rate.', 'danger')
            return
        }
        if (this.purchase_order().warehouse_base_costs() === null || this.purchase_order().warehouse_base_costs() === '') {
            notifier.notify('Please fill out the base costs for the warehouse.', 'danger')
            return
        }
        if (this.purchase_order().warehouse_costs_per_case() === null || this.purchase_order().warehouse_costs_per_case() === '') {
            notifier.notify('Please fill out the costs per case for the warehouse.', 'danger')
            return
        }
        if (this.purchase_order().transport_costs() === null || this.purchase_order().transport_costs() === '') {
            notifier.notify('Please fill out the costs for transport.', 'danger')
            return
        }
        if (this.purchase_order().supplier().country_code() === 'NL' &&
            (this.purchase_order().waste_fund_costs() === '' || this.purchase_order().waste_fund_costs() === null)) {
            notifier.notify('Please fill out the costs for the waste fund.', 'danger')
            return
        }

        this.saving(true)

        const suggested_prices: { [key: number]: number } = {}
        for (const item of this.purchase_order().purchase_order_items()) {
            if (+item.suggested_price_per_case()) {
                suggested_prices[+item.artkey()] = +item.suggested_price_per_case()
            }
        }

        const data = {
            purchase_order_artkey: +this.purchase_order().artkey(),
            bought_against_rate: +this.purchase_order().bought_against_rate(),
            confirmation_date: this.purchase_order().confirmation_date(),
            expected_delivery_date: this.purchase_order().expected_delivery_date(),
            suggested_sales_prices: suggested_prices,
        }

        callAndThen('purchase.core.confirm_purchase_order', data, {
            success: () => {
                notifier.notify('This order was confirmed successfully.', 'success')
                // Broadcast the change, so the offer item list and
                // stock lists are requeried.
                $m.common.observable.broadcast('stock_updated')
                m.route.set('/purchase-orders/manage/' + this.purchase_order().artkey())
            },
            final: () => {
                this.saving(false)
            },
        })
    }

    view() {
        return <div class="c-purchase-order-confirm view">
            <div class="btn-toolbar">
                <button class="btn btn-default" type="button"
                    onclick={() => m.route.set('/purchase-orders/manage/' + this.purchase_order().artkey())}>
                    <span class="glyphicon glyphicon-arrow-left"></span> Back to order
                </button>
            </div>
            <h2>Confirm purchase order</h2>
            {this.purchase_order() &&
                <PurchaseOrderDetails purchase_order={() => this.purchase_order()} />
            }
            {this.purchase_order() && this.purchase_order().artkey() &&
                <form>
                    <div class="col-sm-6">
                        <div class="field">
                            <label for="confirmation_date">Order confirmation date by supplier *</label>
                            {date(this.purchase_order().confirmation_date, {required: true})}
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="field">
                            <label for="expected_delivery_date">Expected delivery date *</label>
                            {date(this.purchase_order().expected_delivery_date, {required: true})}
                        </div>
                        <div class={`field ${this.purchase_order().was_bought_in() === $s.currencies.default ? 'hidden' : ''}`}>
                            <label for="bought_against_rate">Exchange rate *</label>
                            {number(this.purchase_order().bought_against_rate, {required: true, step: '0.0001'})}
                        </div>
                    </div>
                </form>
            }
            {this.purchase_order() &&
                with_buttons(
                    <table class="table search-table">
                        <thead class="thead-default">
                            <tr>
                                <th>Product</th>
                                <th>Btl / cs</th>
                                <th>Specs</th>
                                <th>GB</th>
                                <th>Cases</th>
                                <th>Net price</th>
                                <th>Gross price</th>
                                <th>Net price (€)</th>
                                <th>Gross price (€)</th>
                                <th>Total value</th>
                                <th>Suggested sales price / cs</th>
                            </tr>
                        </thead>
                        {this.purchase_order().purchase_order_items().map((item: PurchaseOrderItem, index: number) =>
                            <ConfirmPurchaseOrderItem
                                item={item}
                                index={index}
                                purchase_order={this.purchase_order}
                            />,
                        )}
                    </table>,
                )
            }
            <button class={`btn btn-default ${!this.saving() ? 'hidden' : ''}`} disabled={true}>
                Saving...
            </button>
            <button class={`btn btn-primary ${this.saving() ? 'hidden' : ''}`} onclick={this.submit}>
                <span class="glyphicon glyphicon-road"></span> Confirm order
            </button>
        </div>
    }
}

class ConfirmPurchaseOrderItem extends MithrilTsxComponent<unknown> {
    item: () => PurchaseOrderItem
    index: () => number
    show_market: any
    purchase_order: () => any
    total_costs_per_case: number

    constructor(vnode: m.Vnode<any>) {
        super()
        this.item = window.prop(vnode.attrs.item)
        this.index = window.prop(vnode.attrs.index)
        this.show_market = window.prop(false)
        this.purchase_order = vnode.attrs.purchase_order
    }

    was_bought_for_plus_costs = () => {
        this.total_costs_per_case = (+this.purchase_order().warehouse_base_costs() +
            +this.purchase_order().transport_costs()) /
            +this.purchase_order().number_of_cases() +
            +this.purchase_order().warehouse_costs_per_case()
        return +this.item().was_bought_for() + +this.total_costs_per_case
    }

    toggle_market = () => {
        this.show_market(!this.show_market())
    }

    view() {
        return <tbody class="table-row">
            <tr class="clickable">
                <td onclick={this.toggle_market}>{this.item().bottle().product().name()}</td>
                <td onclick={this.toggle_market}>{this.item().number_of_bottles_per_case()}</td>
                <td onclick={this.toggle_market}>
                    {(+this.item().bottle().volume()).toFixed(1)}cl /
                    {(+this.item().bottle().alcohol_percentage()).toFixed(1)}% /
                    {this.item().bottle().refill_status()}
                </td>
                <td onclick={this.toggle_market}>{this.item().gift_box_type()}</td>
                <td onclick={this.toggle_market}>{this.item().number_of_cases()}</td>
                <td onclick={this.toggle_market}>
                    <Amount
                        amount={+this.item().was_bought_for()}
                        currency={this.item().purchase_order().was_bought_in()}
                        rate={this.item().purchase_order().bought_against_rate()}
                    />
                </td>
                <td onclick={this.toggle_market}>
                    <Amount
                        amount={this.was_bought_for_plus_costs()}
                        currency={this.item().purchase_order().was_bought_in()}
                        rate={this.item().purchase_order().bought_against_rate()}
                    />
                </td>
                <td onclick={this.toggle_market}>
                    <Amount
                        amount={+this.item().was_bought_for()}
                        currency={this.item().purchase_order().was_bought_in()}
                        rate={this.item().purchase_order().bought_against_rate()}
                        display_currency={$s.currencies.default}
                    />
                </td>
                <td onclick={this.toggle_market}>
                    <Amount
                        amount={this.was_bought_for_plus_costs()}
                        currency={this.item().purchase_order().was_bought_in()}
                        rate={this.item().purchase_order().bought_against_rate()}
                        display_currency={$s.currencies.default}
                    />
                </td>
                <td onclick={this.toggle_market}>
                    <Amount
                        amount={+this.item().number_of_cases() * +this.item().was_bought_for()}
                        currency={this.item().purchase_order().was_bought_in()}
                        rate={this.item().purchase_order().bought_against_rate()}
                        display_currency={$s.currencies.default}
                    />
                </td>
                <td>
                    <FieldMoney
                        currency={[$s.currencies, 'default']}
                        model={[this.item(), 'suggested_price_per_case']}
                    />
                </td>
            </tr>
            {this.show_market() &&
                <tr class="unclickable">
                    <td class="details-row" colspan="100%">
                        <BottleDetails bottle_artkey={this.item().bottle_artkey()} />
                    </td>
                </tr>
            }
        </tbody>
    }
}
