import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils.ts'
import {Amount, Tippy} from '@bitstillery/common/components'

import {Link} from '../components/discover'

import {accountIconBySlug} from '@/accounts'
import {formatDate} from '@/_utils'
import {pricelist_link} from '@/data/components/pricelist_link'
import {$m, $s} from '@/app'
import {Bottle} from '@/factserver_api/item_model'
import {PagedCollectionFetcher} from '@/components/collection/collection_table'
import {Decimal} from '@/components/html_components'
import {DefaultButton, LinkButton} from '@/components/buttons'
import {convert_from_currency_to_euro} from '@/factserver_api/currencies'

export interface MarketItem {
    artkey: number
    aux_info: string
    is_available: boolean | null
    availability_status: string
    currency: string
    customs_status: string
    gift_box_type: string
    incoterm: string
    number_of_bottles_per_case: number
    number_of_cases: number
    price_per_bottle: string
    price_per_case: string
    suppliers_item_code: string | null
    suppliers_hs_code: string | null
    case_gross_weight_in_kg: string | null
    cases_per_pallet_layer: number | null
    cases_per_pallet: number | null
    supplier: {
        company_type: string
        name: string
        account: {
            artkey: number
            slug: string
            name: string
        }
    }
    supplier_price_list: {
        artkey: number
        supplier_price_list_description: string
        start_date: Date
    }
    exists_in_offer?: number
}

export interface DiscoverBottle extends Bottle {
    market: Array<MarketItem>
    excise_nl: number
}

interface DiscoverTableAttrs {
    discover_fetcher: PagedCollectionFetcher<DiscoverBottle>
    open_add_to_offer_modal: (market_item: MarketItem, bottle: DiscoverBottle) => void
}

export class DiscoverTable extends MithrilTsxComponent<DiscoverTableAttrs> {
    open_add_to_offer_modal: (market_item: MarketItem, bottle: DiscoverBottle) => void

    constructor(vnode: m.Vnode<DiscoverTableAttrs>) {
        super()
        this.open_add_to_offer_modal = vnode.attrs.open_add_to_offer_modal
    }

    /**
     * Compare function used for sorting the records in the market items table on price, cheapest first.
     *
     * @param x: The MarketItem to compare with y
     * @param y: The MarketItem to compare with x
     *
     * @return 1 to put y before x, -1 to put x before y, 0 to keep original order.
     */
    sort_market_item_on_price(x: MarketItem, y: MarketItem): number {
        if (
            convert_from_currency_to_euro(+x.price_per_bottle, x.currency) >
            convert_from_currency_to_euro(+y.price_per_bottle, y.currency)
        )
            return 1
        if (
            convert_from_currency_to_euro(+x.price_per_bottle, x.currency) <
            convert_from_currency_to_euro(+y.price_per_bottle, y.currency)
        )
            return -1
        return 0
    }

    /**
     * Render the market items table for a certain DiscoverBottle.
     *
     * @param bottle: the DiscoverBottle to render the market items table for.
     *
     * @return The table with market items.
     */
    market_items(bottle: DiscoverBottle): m.Children {
        return (
            <table className="table">
                <thead className="thead-default">
                    <tr>
                        <th />
                        <th>Relation</th>
                        <th>Quantity</th>
                        <th>Btl / cs</th>
                        <th>Price per case</th>
                        <th>€ per case</th>
                        <th>€ per bottle</th>
                        <th>Customs</th>
                        <th>Incoterm</th>
                        <th>Avail. st.</th>
                        <th>Gift box</th>
                        <th>Aux info</th>
                        <th>List start date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {bottle.market
                        .sort((x, y) => this.sort_market_item_on_price(x, y))
                        .map((market_item) => (
                            <tr
                                key={market_item.artkey}
                                className={classes([
                                    $m.data.company_type_class(market_item.supplier.company_type),
                                    `account-slug-${market_item.supplier.account.slug}`],
                                )}
                            >
                                <td>
                                    <Link
                                        target="_blank"
                                        href={pricelist_link.from_bottle_json(
                                            market_item.supplier_price_list.artkey,
                                            bottle,
                                        )}
                                    >
                                        <span className={'glyphicon glyphicon-new-window'} />
                                    </Link>
                                </td>
                                <td>
                                    {accountIconBySlug(market_item.supplier.account.slug, market_item.supplier.account.name)}
                                    {this._strike_through_if_not_available(market_item.is_available, market_item.supplier.name)}
                                    &nbsp;
                                    {market_item.supplier_price_list.supplier_price_list_description && (
                                        <Tippy
                                            content={`<strong>Description</strong>
                                                      <div>${market_item.supplier_price_list.supplier_price_list_description}</div>`}
                                            allowHTML={true}
                                        >
                                            <span className={'glyphicon glyphicon-info-sign'} />
                                        </Tippy>
                                    )}
                                </td>
                                <td>{market_item.number_of_cases || '-'}</td>
                                <td>{market_item.number_of_bottles_per_case || '-'}</td>
                                <td>
                                    <Amount
                                        amount={market_item.price_per_case}
                                        currency={market_item.currency}
                                        excise={bottle.excise_nl * market_item.number_of_bottles_per_case}
                                    />
                                </td>
                                <td>
                                    <Amount
                                        amount={market_item.price_per_case}
                                        currency={market_item.currency}
                                        display_currency={$s.currencies.default}
                                        excise={bottle.excise_nl * market_item.number_of_bottles_per_case}
                                    />
                                </td>
                                <td>
                                    <Amount
                                        amount={market_item.price_per_bottle}
                                        currency={market_item.currency}
                                        display_currency={$s.currencies.default}
                                        excise={bottle.excise_nl}
                                    />
                                </td>
                                <td>{market_item.customs_status}</td>
                                <td>{market_item.incoterm}</td>
                                <td>{market_item.availability_status}</td>
                                <td>{market_item.gift_box_type}</td>
                                <td>
                                    {market_item.aux_info && (
                                        <div>
                                            <span>{` ${market_item.aux_info}`}</span>
                                        </div>
                                    )}
                                    {market_item.case_gross_weight_in_kg && (
                                        <div>
                                            <label>Case weight:{'  '}</label>
                                            <span>{` ${market_item.case_gross_weight_in_kg}`}</span>
                                        </div>
                                    )}
                                    {market_item.cases_per_pallet_layer && (
                                        <div>
                                            <label>Cases per layer:{'  '}</label>
                                            <span>{` ${market_item.cases_per_pallet_layer}`}</span>
                                        </div>
                                    )}
                                    {market_item.cases_per_pallet && (
                                        <div>
                                            <label>Cases per pallet:{'  '}</label>
                                            <span>{` ${market_item.cases_per_pallet}`}</span>
                                        </div>
                                    )}
                                </td>
                                <td>{formatDate(market_item.supplier_price_list.start_date)}</td>
                                <td>
                                    <div className="row-actions">
                                        <DefaultButton
                                            disabled={
                                                market_item.supplier.company_type === 'Competitor' ||
                                                market_item.exists_in_offer ||
                                                market_item.is_available === false
                                            }
                                            icon_class={'glyphicon glyphicon-duplicate'}
                                            onclick={() => this.open_add_to_offer_modal(market_item, bottle)}
                                            tooltip={
                                                market_item.exists_in_offer
                                                    ? 'Item is already in this offer'
                                                    : 'Add to offer'
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        )
    }

    _strike_through_if_not_available(is_available: boolean | null, text: string): m.Children {
        if (is_available === false) {
            return <Tippy content={'Item is not available'}><s class={'analysis-bad-color'}>{text}</s></Tippy>
        }
        return text
    }

    view(vnode: m.Vnode<DiscoverTableAttrs>): m.Children {
        return (
            <div className="c-discover-table">
                <div className="panel panel-primary">
                    <div className="panel-heading discover-heading no-select">
                        <div className="row">
                            <div
                                className="col-xs-3 fl-c"
                                onclick={() => vnode.attrs.discover_fetcher.set_sort_column('product.name')}
                            >
                                Product {vnode.attrs.discover_fetcher.sort_column_for('product.name')}
                            </div>
                            <div
                                className="col-xs-2 fl-c"
                                onclick={() => vnode.attrs.discover_fetcher.set_sort_column('volume')}
                            >
                                Size in cl {vnode.attrs.discover_fetcher.sort_column_for('volume')}
                            </div>
                            <div
                                className="col-xs-2 fl-c"
                                onclick={() => vnode.attrs.discover_fetcher.set_sort_column('alcohol_percentage')}
                            >
                                Alcohol % {vnode.attrs.discover_fetcher.sort_column_for('alcohol_percentage')}
                            </div>
                            <div
                                className="col-xs-1 fl-c"
                                onclick={() => vnode.attrs.discover_fetcher.set_sort_column('refill_status')}
                            >
                                Refill {vnode.attrs.discover_fetcher.sort_column_for('refill_status')}
                            </div>
                            <div
                                className="col-xs-2 fl-c"
                                onclick={() => vnode.attrs.discover_fetcher.set_sort_column('product.category')}
                            >
                                Category {vnode.attrs.discover_fetcher.sort_column_for('product.category')}
                            </div>
                            <div className="col-xs-1 fl-c">Excise NL</div>
                            <div className="col-xs-1" />
                        </div>
                    </div>
                </div>
                {vnode.attrs.discover_fetcher.fetched_rows.map((bottle) => (
                    <div className="panel panel-default">
                        <div className="panel-heading" style={{height: '40px'}}>
                            <div className="panel-title col-xs-3">
                                <a href={`#bottle-${bottle.artkey}`} data-toggle="collapse">
                                    <span className={'glyphicon glyphicon-chevron-right'} />
                                </a>
                                {bottle.product.name}
                            </div>
                            <div className="col-xs-2">
                                <Decimal value={bottle.volume} number_of_fraction_digits={1} />
                            </div>
                            <div className="col-xs-2">
                                <Decimal value={bottle.alcohol_percentage} number_of_fraction_digits={1} />
                            </div>
                            <div className="col-xs-1">{bottle.refill_status}</div>
                            <div className="col-xs-2 text-capitalize">{bottle.product.category}</div>
                            <div className="col-xs-1">
                                <Amount amount={bottle.excise_nl} currency={$s.currencies.default} />
                            </div>
                        </div>
                        <div className="panel-body collapse in" id={`bottle-${bottle.artkey}`}>
                            {bottle.market.length ? (
                                <div key={`${bottle.artkey}`}>{this.market_items(bottle)}</div>
                            ) : (
                                <p key={`${bottle.artkey}`}>Not on active price lists</p>
                            )}
                        </div>
                    </div>
                ))}
                {!vnode.attrs.discover_fetcher.no_more_results && (
                    <LinkButton onclick={() => vnode.attrs.discover_fetcher.fetch_next()}>Show more results</LinkButton>
                )}
            </div>
        )
    }
}
