/** llm:tested */
export enum AgeInDaysEnum {
    WEEK = 'Last week',
    MONTH = 'Last month',
    YEAR = 'Last year',
    ALL = 'All'
}

export const AgeInDays = {
    WEEK: AgeInDaysEnum.WEEK,
    MONTH: AgeInDaysEnum.MONTH,
    YEAR: AgeInDaysEnum.YEAR,
    ALL: AgeInDaysEnum.ALL,

    all: [AgeInDaysEnum.WEEK, AgeInDaysEnum.MONTH, AgeInDaysEnum.YEAR, AgeInDaysEnum.ALL],
    choices: {
        [AgeInDaysEnum.WEEK]: AgeInDaysEnum.WEEK,
        [AgeInDaysEnum.MONTH]: AgeInDaysEnum.MONTH,
        [AgeInDaysEnum.YEAR]: AgeInDaysEnum.YEAR,
        [AgeInDaysEnum.ALL]: AgeInDaysEnum.ALL,
    },

    as_days: {
        [AgeInDaysEnum.WEEK]: 7,
        [AgeInDaysEnum.MONTH]: 30,
        [AgeInDaysEnum.YEAR]: 360,
        [AgeInDaysEnum.ALL]: 9999,
    },
} as const

export enum AggregationLevelEnum {
    DAY = 'day',
    MONTH = 'month'
}

export const AggregationLevel = {
    DAY: AggregationLevelEnum.DAY,
    MONTH: AggregationLevelEnum.MONTH,

    all: [AggregationLevelEnum.DAY, AggregationLevelEnum.MONTH],
    choices: {
        [AggregationLevelEnum.DAY]: AggregationLevelEnum.DAY,
        [AggregationLevelEnum.MONTH]: AggregationLevelEnum.MONTH,
    },
} as const

export enum GraphFunctionEnum {
    CUMULATIVE = 'Cumulative',
    ROLLING_AVERAGE = 'Rolling Average',
    NUMBER_OF_CASES = 'Aggregated per month'
}

export const GraphFunction = {
    CUMULATIVE: GraphFunctionEnum.CUMULATIVE,
    ROLLING_AVERAGE: GraphFunctionEnum.ROLLING_AVERAGE,
    NUMBER_OF_CASES: GraphFunctionEnum.NUMBER_OF_CASES,

    all: [GraphFunctionEnum.CUMULATIVE, GraphFunctionEnum.ROLLING_AVERAGE, GraphFunctionEnum.NUMBER_OF_CASES],
    choices: {
        [GraphFunctionEnum.CUMULATIVE]: GraphFunctionEnum.CUMULATIVE,
        [GraphFunctionEnum.ROLLING_AVERAGE]: GraphFunctionEnum.ROLLING_AVERAGE,
        [GraphFunctionEnum.NUMBER_OF_CASES]: GraphFunctionEnum.NUMBER_OF_CASES,
    },
} as const

export enum StatFunctionEnum {
    PREDICTED_STOCK = 'Actual stock + prediction',
    PREDICTED_SALES = 'Actual sales + prediction (accumulated)',
    ACTUAL_STOCK = 'Actual stock'
}

export const StatFunction = {
    PREDICTED_STOCK: StatFunctionEnum.PREDICTED_STOCK,
    PREDICTED_SALES: StatFunctionEnum.PREDICTED_SALES,
    ACTUAL_STOCK: StatFunctionEnum.ACTUAL_STOCK,

    all: [StatFunctionEnum.PREDICTED_SALES, StatFunctionEnum.PREDICTED_STOCK, StatFunctionEnum.ACTUAL_STOCK],
    choices: {
        [StatFunctionEnum.PREDICTED_SALES]: StatFunctionEnum.PREDICTED_SALES,
        [StatFunctionEnum.PREDICTED_STOCK]: StatFunctionEnum.PREDICTED_STOCK,
        [StatFunctionEnum.ACTUAL_STOCK]: StatFunctionEnum.ACTUAL_STOCK,
    },
} as const
