/** llm:tested */
import m from 'mithril'
import {orList} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericList} from '@/data/generic/generic_list'

interface InsuranceType {
    name: string
    fee: number
}

export class InsuranceTypesList extends MithrilTsxComponent<any> {
    is_match(insurance_type: InsuranceType, term: string): boolean {
        return orList([
            insurance_type.name.toLowerCase().indexOf(term) > -1,
        ])
    }

    view(_vnode: m.Vnode<any>) {
        return <GenericList
            readable_entity_name="insurance type"
            base_url="/data/insurancetypes"
            list_api_call="insurancetypes.get_all"
            is_match={this.is_match}
            columns={[
                {key: 'name', label: 'Name'},
                {key: 'fee', label: 'Fee (permillage)'},
            ]}
            requery_subscribe_key="insurancetypes_updated"
        />
    }
}
