/** llm:tested */
interface PricelistLink {
    from_bottle: (pricelist_artkey: string | null, bottle: any) => string
    from_bottle_json: (pricelist_artkey: string | null, bottle: any) => string
    create: (pricelist_artkey: string | null, product_name: string, volume: number, alcohol_percentage: number) => string
}

interface MsiPricelistLink {
    create: (product_name: string, volume: number, alcohol_percentage: number) => string
}

const pricelist_link: PricelistLink = {
    from_bottle: (pricelist_artkey, bottle) => {
        return pricelist_link.create(
            pricelist_artkey,
            bottle.product().name(),
            +bottle.volume(),
            +bottle.alcohol_percentage(),
        )
    },

    from_bottle_json: (pricelist_artkey, bottle) => {
        return pricelist_link.create(
            pricelist_artkey,
            bottle.product.name,
            +bottle.volume,
            +bottle.alcohol_percentage,
        )
    },

    create: (pricelist_artkey, product_name, volume, alcohol_percentage) => {
        if (pricelist_artkey === null) {
            // Then it's an offer item in disguise.
            return msi_pricelist_link.create(product_name, volume, alcohol_percentage)
        }

        return `/market/pricelists/${pricelist_artkey}?search=${product_name} ${volume.toFixed(1)} ${alcohol_percentage.toFixed(1)}`
    },
}

const msi_pricelist_link: MsiPricelistLink = {
    create: (product_name, volume, alcohol_percentage) => {
        const url = `/pricelist/offer-item-list?q=${product_name} ${volume.toFixed(1)} ${alcohol_percentage.toFixed(1)}`
        return url.replace(/ /g, '+')
    },
}

export {pricelist_link, msi_pricelist_link}
