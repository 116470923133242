/** llm:tested */
import m from 'mithril'
import {reject} from 'prelude-ls'
import {Spinner} from '@bitstillery/common/components'
import {Icon} from '@bitstillery/common/components'
import {Amount} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {formatDate, formatPercentage} from '@/_utils'
import {call} from '@/api'
import {icon_with_popover} from '@/components/icon'
import {icon_button} from '@/components/_buttons'
import {$m} from '@/app'

interface StockItem {
    artkey: string
    warehouse_name: string
    item_entry_date: string
    expected_delivery_date: string
    item_number_of_cases_in_stock: number
    item_number_of_cases_in_purchase: number
    item_number_of_cases_in_sales: number
    item_number_of_cases_available: number
    item_throughput: number | null
    case_number_of_bottles: number
    case_gift_box_type: string
    case_tax_label: string
    case_customs_status: string
    item_was_bought_for: number
    case_excise_nl: number
    purchase_order_was_bought_in: string
    purchase_order_bought_against_rate: number
    item_lot: string
    item_reference: string
}

interface OfferItemsAttrs {
    offer_item_artkey: string
}

export class OfferItems extends MithrilTsxComponent<OfferItemsAttrs> {
    loading: boolean = false
    items: StockItem[] = []
    offer_item_artkey: string

    constructor(vnode: m.Vnode<OfferItemsAttrs>) {
        super()
        this.offer_item_artkey = vnode.attrs.offer_item_artkey
        this.query_items()
    }

    query_items() {
        if (!this.offer_item_artkey) {
            this.items = []
            return
        }
        this.loading = true
        const data = {offer_item_artkey: this.offer_item_artkey}
        call('stock.get_stock_records', data, (resp: any) => {
            this.loading = false
            if (resp.success) {
                this.items = resp.result.stock_items
            }
        })
    }

    unapprove_item(artkey: string) {
        // TODO confirm are you sure?
        const data = {item_artkey: artkey}
        call('approval.unapprove_item', data, (resp: any) => {
            if (resp.success) {
                this.items = reject((item: StockItem) => item.artkey === artkey, this.items)
            } else {
                $m.common.generic_error_handler()
            }
        })
    }

    view() {
        return <div class="c-offer-items collection-widget">
            <div class="header">
                <div class="title">
                    <Icon name="specialOffer" type="info" />
                    Offered items
                </div>
            </div>
            <div class="content">
                {this.loading ? <Spinner /> :
                    this.items.length > 0 ?
                        <table class="table table-condensed">
                            <thead class="thead-default">
                                <tr>
                                    <th>Warehouse</th>
                                    <th>Entry date</th>
                                    <th class="number">{icon_with_popover({
                                        iconId: 'home',
                                        content: 'Number of cases in stock',
                                    })}</th>
                                    <th class="number">{icon_with_popover({
                                        iconId: 'shopping-cart',
                                        content: 'Number of cases in purchase',
                                    })}</th>
                                    <th class="number">{icon_with_popover({
                                        iconId: 'screenshot',
                                        content: 'Number of cases in sales',
                                    })}</th>
                                    <th class="number">{icon_with_popover({
                                        iconId: 'fa-shield-alt',
                                        content: 'Number of cases available',
                                    })}</th>
                                    <th class="number">{icon_with_popover({
                                        iconId: 'flash',
                                        content: 'Throughput',
                                    })}</th>
                                    <th class="number">Btl / cs</th>
                                    <th>Gift box</th>
                                    <th>Tax label</th>
                                    <th>Customs</th>
                                    <th class="price">Bought for (per case)</th>
                                    <th>Lot</th>
                                    <th>#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.items.map((stock: StockItem) =>
                                    <tr>
                                        <td>{stock.warehouse_name}</td>
                                        <td>{formatDate(stock.item_entry_date) ||
                                            'ETA: ' + formatDate(stock.expected_delivery_date)}</td>
                                        <td class="number">{stock.item_number_of_cases_in_stock || '-'}</td>
                                        <td class="number">{stock.item_number_of_cases_in_purchase}</td>
                                        <td class="number">{stock.item_number_of_cases_in_sales}</td>
                                        <td class="number">{stock.item_number_of_cases_available}</td>
                                        <td class="number">
                                            {stock.item_throughput !== null ?
                                                formatPercentage(stock.item_throughput) :
                                                '- %'}
                                        </td>
                                        <td class="number">{stock.case_number_of_bottles}</td>
                                        <td>{stock.case_gift_box_type}</td>
                                        <td>{stock.case_tax_label}</td>
                                        <td>{stock.case_customs_status}</td>
                                        <td class="price">
                                            <Amount
                                                amount={+stock.item_was_bought_for}
                                                excise={stock.case_excise_nl}
                                                currency={stock.purchase_order_was_bought_in}
                                                rate={stock.purchase_order_bought_against_rate}
                                            />
                                        </td>
                                        <td>{stock.item_lot}</td>
                                        <td>
                                            {icon_button('refresh', {
                                                class: 'btn-default btn-xs no-click',
                                                onclick: () => this.unapprove_item(stock.artkey),
                                            })}
                                            {' '}
                                            {icon_button('fa-expand-arrows-alt', {
                                                class: 'btn-default btn-xs no-click',
                                                onclick: () => m.route.set(`/stock/manage/${stock.item_reference}`),
                                            })}
                                        </td>
                                    </tr>,
                                )}
                            </tbody>
                        </table>
                        : <p>Not in stock.</p>
                }
            </div>
        </div>
    }
}
