/* eslint-disable @typescript-eslint/naming-convention */
import {
    groupBy,
    sum,
    maximum,
    sortBy,
} from 'lodash'

type Selector<T, R> = (value: T) => R

/**
 * Dereference a prop followed by a path of optional selectors.
 * This function is useful if you don't know if the prop you have
 * is a window.prop (function) or just a plain value.
 *
 * example:
 *     const purchaseOrder = prop({reference: prop('P123')});
 *     deref(purchaseOrder, (po) => po.reference)
 *     // returns: 'P123'
 *
 *     // the following inputs give the same answer:
 *     // purchaseOrder = window.prop({reference: 'P123'})
 *     // purchaseOrder = {reference: window.prop('P123')}
 *     // purchaseOrder = {reference: 'P123'}
 */
export function deref<T, R>(prop: T | (() => T), ...selectors: Selector<any, any>[]): T | R {
    if (selectors.length > 0) {
        const [fn, ...rest] = selectors
        return deref(fn(deref(prop)), ...rest)
    }

    if (isFunction(prop)) {
        return (prop as Function)()
    }

    return prop
}

/**
 * Checks if a value is a function
 */
export function isFunction(value: unknown): value is Function {
    return typeof value === 'function'
}

/**
 * Capitalize the first letter of all words in a string.
 */
export function titleize(str: string): string {
    return str.replace(/\b[a-z]/g, (letter) => letter.toUpperCase())
}

// Export commonly used lodash functions
export {
    groupBy,
    sum,
    maximum,
    sortBy,
}
