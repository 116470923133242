/** llm:tested */
import m from 'mithril'
import {map, compact} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {matchTermIn, formatDateTime} from '@/_utils'
import {icon_button, button_with_icon} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import {CollectionTable} from '@/components/collection_table'
import api from '@/api'

interface NewsItem {
    artkey: string
    title: string
    content: string
    date: string
    creator: {
        profile: {
            name: string
        }
    }
}

export class PortalNewsList extends MithrilTsxComponent<any> {
    private entities: Collection
    private search_input_ctrl: any

    constructor() {
        super()
        this.entities = new Collection({
            api_function_name: 'portal.news.get_news',
            filter_function: this.is_match,
            query_limit: 25,
            default_sort_by: 'date',
            default_sort_order: 'desc',
        })

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.entities,
            placeholder: 'Search for portal news item...',
        })
    }

    oncreate() {
        this.entities.init()
    }

    is_match(news: NewsItem, term: string) {
        return matchTermIn(term, map((x: string) => x.toLowerCase(), compact([
            news.title,
            news.content,
        ])))
    }

    onremove() {
        this.search_input_ctrl.onremove()
    }

    mark_deleted(artkey: string) {
        api.call('portal.news.mark_deleted', {artkey}, (resp: any) => {
            if (resp.success) {
                notifier.notify('Successfully deleted news item', 'success')
                this.entities.soft_delete(artkey)
            } else {
                m.redraw()
            }
        })
    }

    view() {
        return <div class="c-portal-news view">
            <div class="btn-toolbar">
                {button_with_icon('Create Portal News', 'plus', {
                    class: 'btn-default',
                    onclick: () => m.route.set('/portal/portal-news/create'),
                })}
            </div>

            <div class="c-filter-group">
                {SearchInput.view(this.search_input_ctrl)}
            </div>

            {m(CollectionTable, {
                collection: this.entities,
                options: {
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: true,
                    autoscale: true,
                    unique_name: 'portal_news',
                    onclick: (record: NewsItem) => {
                        return () => {
                            m.route.set(`/portal/portal-news/${record.artkey}/edit`)
                        }
                    },
                },
                columns: [
                    {
                        width: 1,
                        name: 'Date',
                        field: 'date',
                        sort: true,
                        transform: formatDateTime,
                    },
                    {
                        width: 3,
                        name: 'Title',
                        field: 'title',
                        sort: true,
                    },
                    {
                        width: 10,
                        name: 'Message',
                        field: 'content',
                        sort: true,
                        ellipsis: true,
                    },
                    {
                        width: 2,
                        name: 'Author',
                        field: 'creator.profile.name',
                        sort: true,
                    },
                    {
                        width: 1,
                        header: '',
                        name: 'Actions',
                        function: (record: NewsItem) => {
                            return <div class="btn-toolbar no-click">
                                <div class="btn-group">
                                    {icon_button('pencil', {
                                        class: 'btn-default no-click',
                                        onclick: () => m.route.set(`/portal/portal-news/${record.artkey}/edit`),
                                    })}
                                    {icon_button('remove', {
                                        class: 'btn-danger no-click',
                                        onclick: () => this.mark_deleted(record.artkey),
                                    })}
                                </div>
                            </div>
                        },
                    },
                ],
            })}
        </div>
    }
}
