/** llm:tested*/
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api_ls from '@/api'
import {button_with_icon, text_button} from '@/components/_buttons'
import inputs from '@/components/inputs'
import {Offer, OfferType, Priority} from '@/models/offers'
import {IncotermsDropDown} from '@/components/incoterms'
import {IncotermsDropDownData} from '@/factserver_api/incoterms_api'

interface Attrs {
    source_offer: any
    done: () => void
    cancel: () => void
}

export class CloneOffer extends MithrilTsxComponent<Attrs> {
    private source_offer: any
    private done: () => void
    private cancel: () => void
    private copy_products: any
    private copy_relations: any
    private published: any
    private saving: any
    private new_offer: any
    private use_default_incoterm: any

    constructor(vnode: m.Vnode<Attrs>) {
        super()
        this.source_offer = vnode.attrs.source_offer
        this.done = vnode.attrs.done
        this.cancel = vnode.attrs.cancel

        this.copy_products = window.prop(true)
        this.copy_relations = window.prop(false)
        this.published = window.prop(false)
        this.saving = window.prop(false)

        // Initialize new offer with some initial values.
        this.new_offer = window.prop(new Offer())
        this.new_offer().title(this.source_offer().title)
        this.new_offer().remark(this.source_offer().remark)
        this.new_offer().incoterm(this.source_offer().incoterm)
        this.new_offer().incoterm_location(this.source_offer().incoterm_location)
        this.new_offer().offer_type(this.source_offer().offer_type)

        this.use_default_incoterm = window.prop(this.new_offer().incoterm() === null)
    }

    save = (e: Event) => {
        e.preventDefault()

        // Basic form validation.
        const today = new Date()
        const start_date = new Date(this.new_offer().start_date())
        const end_date = new Date(this.new_offer().end_date())
        if (end_date.getTime() < today.getTime() - 1) {
            notifier.notify('Could not clone the offer; the end date may not be in the past.', 'danger')
            return
        }

        if (start_date > end_date) {
            notifier.notify('Could not clone the offer; the start date may not be later than the end date.', 'danger')
            return
        }

        if (this.use_default_incoterm()) {
            this.new_offer().incoterm('')
            this.new_offer().incoterm_location('')
        }

        if (this.new_offer().incoterm() && !this.new_offer().incoterm_location()) {
            notifier.notify('Could not clone the offer; when entering an incoterm, also enter an incoterm location.', 'danger')
            return
        }

        if (this.new_offer().incoterm_location() && !this.new_offer().incoterm()) {
            notifier.notify('Could not clone the offer; when entering an incoterm location, also enter an incoterm.', 'danger')
            return
        }

        if (!this.saving()) {
            // Everything is OK so far; clone the offer.
            this.saving(true)
            const data = {
                source_offer_artkey: this.source_offer().artkey,
                title: this.new_offer().title(),
                remark: this.new_offer().remark(),
                start_date: this.new_offer().start_date(),
                end_date: this.new_offer().end_date(),
                incoterm: this.new_offer().incoterm(),
                incoterm_location: this.new_offer().incoterm_location(),
                offer_type: this.new_offer().offer_type(),
                priority: this.new_offer().priority(),
                published: this.published(),
                copy_products: this.copy_products(),
                copy_relations: this.copy_relations(),
            }

            api_ls.callAndThen('offer.clone_offer', data, {
                success: (resp: any) => {
                    notifier.notify(`Successfully cloned custom offer "${this.source_offer().title}" into "${resp.result.title}".`, 'success')
                    this.done()
                },
                failure: (resp: any) => {
                    notifier.notify(resp.message, 'danger')
                    this.saving(false)
                },
            })
        }
    }

    view() {
        return <div class="c-clone-offer">
            <form class="flex-form" onsubmit={this.save}>
                <div class="fieldset large">
                    <div class="field-readonly">
                        <div class="key">Clone from:</div>
                        <div class="value">{this.source_offer().title}</div>
                    </div>

                    {inputs.text(this.new_offer().title, {
                        label: 'Title',
                        required: true,
                        placeholder: 'Title',
                    })}

                    {inputs.text(this.new_offer().remark, {
                        label: 'Remark',
                        placeholder: 'Remark',
                    })}

                    <div class="field-group">
                        {inputs.date(this.new_offer().start_date, {
                            label: 'Start date',
                            required: true,
                        })}

                        {inputs.date(this.new_offer().end_date, {
                            label: 'End date',
                            required: true,
                        })}
                    </div>

                    <div class="field">
                        <label>Incoterm to use</label>
                        {inputs.radio(this.use_default_incoterm, [
                            {value: true, description: 'Default'},
                            {value: false, description: 'Override'},
                        ])}
                    </div>

                    {!this.use_default_incoterm() &&
                        <div class="field">
                            <label>Incoterm</label>
                            <IncotermsDropDown
                                get_all_for_drop_down_response$={IncotermsDropDownData.incoterms()}
                                model={[this.new_offer(), 'incoterm']}
                            />
                        </div>
                    }

                    {!this.use_default_incoterm() &&
                        inputs.text(this.new_offer().incoterm_location, {
                            label: 'Incoterm location',
                            placeholder: 'Incoterm location',
                        })
                    }

                    <div class="field">
                        <label>Offer type</label>
                        {inputs.radio(this.new_offer().offer_type,
                            Object.entries(OfferType.offer_type_options).map(([value, description]) =>
                                ({value, description})),
                        )}
                    </div>

                    <div class="field">
                        <label>Priority</label>
                        {inputs.radio(this.new_offer().priority,
                            Object.entries(Priority.all).map(([value, description]) =>
                                ({value: +value, description})),
                        )}
                    </div>

                    <hr />

                    {inputs.checkbox(this.copy_products, {
                        label: 'Copy products',
                    })}

                    {inputs.checkbox(this.copy_relations, {
                        label: 'Copy relations',
                    })}

                    {inputs.checkbox(this.published, {
                        label: 'Published',
                    })}
                </div>

                <div class="btn-toolbar">
                    {text_button('Cancel', {
                        class: 'btn-default',
                        onclick: this.cancel,
                    })}

                    {button_with_icon('Clone offer', 'duplicate', {
                        class: 'btn-success',
                        disabled: this.saving(),
                    })}
                </div>
            </form>
        </div>
    }
}
