import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Country, FieldSelect} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/models/item'
import {Spinner} from '@bitstillery/common/components'
import {Amount} from '@bitstillery/common/components'

import {AddCreditItem} from '@/sales_orders/components/add_credit_item'
import {SalesOrderAttr} from '@/sales_orders/components/add_item_to_sales_order'
import {$s} from '@/app'
import {SelectionEnabledComponent} from '@/components/selection_controller'
import {NumberInput} from '@/components/input_numbers'
import {CancelButton, DangerButton, DefaultButton, SuccessButton} from '@/components/buttons'
import {Link} from '@/components/discover'
import {CaseLabels} from '@/components/labels'
import {Percent} from '@/components/html_components'
import {
    calculate_number_of_bottles_credit,
    calculate_total_ex_duty_credit,
    calculate_total_margin_credit,
    calculate_total_value_credit,
    GetFastSalesOrderWithItemsResponse,
    SalesApi,
    SalesOrderCreditItem,
    SalesOrderItem,
    SalesOrderStatus,
} from '@/factserver_api/sales_api'

export interface SalesOrderCreditItemsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_changed: () => void
    register_selection_enabled_component: (component: SelectionEnabledComponent) => void
}

export interface SalesOrderCreditItemAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_credit_item: SalesOrderCreditItem
    sales_order_changed: () => void
    register_selection_enabled_component: (component: SelectionEnabledComponent) => void
}

/**
 * Display a sales order credit item.
 */
export class SalesOrderCreditItemView
    extends MithrilTsxComponent<SalesOrderCreditItemAttrs>
    implements SelectionEnabledComponent {
    sales_api = new SalesApi()
    sales_order_item_changed: () => void
    is_editing = false
    is_selecting = false
    is_performing_api_call = false
    sales_order_item_artkey: number
    supplier_artkey: number
    product_artkey: number

    creditable_sales_order_items: SalesOrderItem[] = []

    constructor(vnode: m.Vnode<SalesOrderCreditItemAttrs>) {
        super()
        this.sales_order_item_changed = vnode.attrs.sales_order_changed
        this.sales_order_item_artkey = vnode.attrs.sales_order_credit_item.sales_order_item.artkey
        this.supplier_artkey = vnode.attrs.sales_order.supplier.artkey
        this.product_artkey = vnode.attrs.sales_order_credit_item.sales_order_item.item.case.bottle.product.artkey
        vnode.attrs.register_selection_enabled_component(this)
    }

    oncreate(): void {
        this.fetch_creditable_soi_for_supplier_and_product()
    }

    cancel_edit(): void {
        this.is_editing = false
        this.sales_order_item_changed()
    }

    delete_sales_order_credit_item(sales_order_credit_item_artkey: number): void {
        this.is_performing_api_call = true
        this.sales_api.delete_sales_order_credit_item(sales_order_credit_item_artkey).subscribe({
            next: () => {
                this.sales_order_item_changed()
                this.is_performing_api_call = false
            },
            error: () => {
                this.is_performing_api_call = false
                m.redraw()
            },
        })
    }

    end_selection(): void {
        this.is_selecting = false
    }

    fetch_creditable_soi_for_supplier_and_product(): void {
        this.sales_api
            .creditable_soi_for_supplier_and_product(
                this.sales_order_item_artkey,
                this.supplier_artkey,
                this.product_artkey,
            )
            .subscribe((response: SalesOrderItem[]) => (this.creditable_sales_order_items = response))
    }

    save_edit(soc: SalesOrderCreditItem): void {
        if (soc.number_of_cases < 1 || !this.sales_order_item_artkey) {
            return
        }
        this.is_performing_api_call = true
        this.sales_api.update_credit_item(soc.artkey, this.sales_order_item_artkey, soc.number_of_cases).subscribe({
            next: () => {
                this.is_performing_api_call = false
                this.is_editing = false
                this.sales_order_item_changed()
            },
            error: () => {
                this.is_performing_api_call = false
            },
        })
    }

    start_selection(): void {
        this.is_selecting = true
    }

    view(vnode: m.Vnode<SalesOrderCreditItemAttrs>): m.Children {
        const soc = vnode.attrs.sales_order_credit_item
        const sales_order = vnode.attrs.sales_order
        const _case = vnode.attrs.sales_order_credit_item.sales_order_item.item.case
        return (
            <tbody className={'table-row'} key={`${soc.artkey}`}>
                <tr>
                    <td>
                        <span>
                            {_case.bottle.product.name}
                            <span className={'help-block'}>{_case.bottle.product.category}</span>
                        </span>
                    </td>
                    <td>{_case.number_of_bottles}</td>
                    <td>{to_specs(_case.bottle, $s.identity.user.decimal_locale)}</td>
                    <td>{_case.gift_box_type}</td>
                    <td>
                        <CaseLabels case={_case} show_as_icons={true} />
                    </td>
                    <td>{_case.customs_status}</td>
                    <td>
                        <Country country_code={soc.sales_order_item.item.country_of_origin} type={'flag_with_country'}/>
                    </td>
                    {this.is_editing && (
                        <td>
                            <FieldSelect
                                model={[this, 'sales_order_item_artkey']}
                                options={this.creditable_sales_order_items.map((i) => ({
                                    value: i.artkey,
                                    label: `${i.sales_order.reference} ${i.number_of_cases}cs ${i.item.case.bottle.volume} / ${i.item.case.bottle.alcohol_percentage} % ${i.item.case.bottle.refill_status}`,
                                }))}
                            />
                        </td>
                    )}
                    {!this.is_editing && (
                        <td>
                            <Link
                                href={`/sales-orders/manage/${soc.sales_order_item.sales_order.artkey}`}
                                target={'_self'}
                            >
                                {soc.sales_order_item.sales_order.reference}
                            </Link>
                        </td>
                    )}
                    <td className={'price'}>
                        <Amount
                            amount={soc.sales_order_item.item.was_bought_for}
                            currency={soc.sales_order_item.item.was_bought_in}
                            rate={soc.sales_order_item.item.bought_against_rate}
                            excise={soc.sales_order_item.excise_per_case}
                            display_currency={sales_order.was_sold_in}
                            display_rate={sales_order.sold_against_rate}
                        />
                        <span className={'help-block'}>
                            <div>
                                Costs:{' '}
                                <Amount
                                    amount={soc.sales_order_item.item.euro_was_bought_for_plus_costs}
                                    currency={'EUR'}
                                    excise={soc.sales_order_item.excise_per_case}
                                />
                            </div>
                        </span>
                    </td>
                    {!this.is_editing && <td className={'number'}>{soc.number_of_cases}</td>}
                    {this.is_editing && (
                        <td className={'number'}>
                            <NumberInput
                                required={true}
                                minimum={0}
                                value={soc.number_of_cases}
                                oninput={(value: number) => (soc.number_of_cases = value)}
                            />
                        </td>
                    )}
                    <td className={'number'}>{calculate_number_of_bottles_credit(soc)}</td>
                    <td className={'price'}>
                        <span>
                            <Amount
                                amount={soc.sales_order_item.price_per_case}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                            {sales_order.includes_excise && (
                                <span className={'help-block'}>
                                    Duty:{' '}
                                    <Amount
                                        amount={soc.sales_order_item.excise_per_case}
                                        currency={sales_order.was_sold_in}
                                        rate={sales_order.sold_against_rate}
                                    />
                                </span>
                            )}
                        </span>
                    </td>
                    <td className={'price'}>
                        <Amount
                            amount={calculate_total_value_credit(soc)}
                            currency={sales_order.was_sold_in}
                            rate={sales_order.sold_against_rate}
                        />
                        {sales_order.includes_excise && (
                            <span className={'help-block'}>
                                Ex Duty:{' '}
                                <Amount
                                    amount={calculate_total_ex_duty_credit(soc)}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />
                            </span>
                        )}
                    </td>
                    <td className={'price'}>
                        <Amount
                            amount={calculate_total_margin_credit(soc, sales_order)}
                            currency={sales_order.was_sold_in}
                            rate={sales_order.sold_against_rate}
                        />
                    </td>
                    <td>
                        <Percent value={+soc.sales_order_item.margin_percentage * -1} />
                    </td>
                    {!this.is_performing_api_call && (
                        <td>
                            {!this.is_selecting && (
                                <span>
                                    {!this.is_editing && sales_order.combined_status !== SalesOrderStatus.INVOICED && (
                                        <div className={'pull-right'}>
                                            <DefaultButton
                                                icon_class={'glyphicon glyphicon-pencil'}
                                                onclick={() => (this.is_editing = true)}
                                            />
                                            <DangerButton
                                                icon_class={'glyphicon glyphicon-remove'}
                                                onclick={() => this.delete_sales_order_credit_item(soc.artkey)}
                                            />
                                        </div>
                                    )}
                                    {this.is_editing && (
                                        <div className={'pull-right'}>
                                            <CancelButton onclick={() => this.cancel_edit()} />
                                            <SuccessButton
                                                icon_class={'glyphicon glyphicon-ok'}
                                                title={' '}
                                                onclick={() => this.save_edit(soc)}
                                            />
                                        </div>
                                    )}
                                </span>
                            )}
                        </td>
                    )}
                    {this.is_performing_api_call && (
                        <td>
                            <div style={'width: 81px'}>
                                <Spinner />
                            </div>
                        </td>
                    )}
                </tr>
            </tbody>
        )
    }
}

/**
 * Shows the sales order credit items of a sales order
 */
export class SalesOrderCreditItems extends MithrilTsxComponent<SalesOrderCreditItemsAttrs> {
    sales_order_as_function_view(vnode: m.Vnode<SalesOrderCreditItemsAttrs>): () => SalesOrderAttr {
        const sales_order = vnode.attrs.sales_order
        return () => {
            return {
                artkey: () => sales_order.artkey,
                includes_excise: () => sales_order.includes_excise,
                was_sold_in: () => sales_order.was_sold_in,
                supplier: () => {
                    return {
                        artkey: () => sales_order.supplier.artkey,
                        name: () => sales_order.supplier.name,
                        currency: () => sales_order.supplier.currency,
                        portal_customs_visibility: () => sales_order.supplier.portal_customs_visibility,
                    }
                },
            }
        }
    }

    view(vnode: m.Vnode<SalesOrderCreditItemsAttrs>): m.Children {
        return (
            <span>
                {!vnode.attrs.sales_order.is_part_of_buy_from_account && vnode.attrs.sales_order.combined_status !== SalesOrderStatus.CANCELLED && (
                    <AddCreditItem
                        sales_order_changed={vnode.attrs.sales_order_changed}
                        sales_order={this.sales_order_as_function_view(vnode)}
                    />
                )}
                {vnode.attrs.sales_order.sales_order_credit_items.length > 0 && (
                    <div className={'row'}>
                        <div className={'col-sm-12'}>
                            <h4>Credit items</h4>
                        </div>
                        <div className={'col-sm-12'}>
                            <table className={'table search-table clickable'}>
                                <thead className={'thead-default'}>
                                    <tr>
                                        <th>Product</th>
                                        <th>Btl / cs</th>
                                        <th>Specs</th>
                                        <th>GB</th>
                                        <th>Features</th>
                                        <th>Cus.</th>
                                        <th>Country of Origin</th>
                                        <th>S.O. #</th>
                                        <th className={'price'}>Purchase / cs</th>
                                        <th className={'number'}>Cases</th>
                                        <th className={'number'}>Bottles</th>
                                        <th className={'price'}>Price / cs</th>
                                        <th className={'price'}>Total value</th>
                                        <th className={'price'}>Margin (€)</th>
                                        <th>Margin %</th>
                                        <th/>
                                    </tr>
                                </thead>
                                {/* Display the sales_order items.*/}
                                {vnode.attrs.sales_order.sales_order_credit_items.map((soc) => (
                                    <SalesOrderCreditItemView
                                        sales_order={vnode.attrs.sales_order}
                                        sales_order_credit_item={soc}
                                        register_selection_enabled_component={
                                            vnode.attrs.register_selection_enabled_component
                                        }
                                        sales_order_changed={vnode.attrs.sales_order_changed}
                                    />
                                ))}
                            </table>
                        </div>
                    </div>
                )}
            </span>
        )
    }
}
