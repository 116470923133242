/** llm:tested */
import m from 'mithril'
import {sum, map} from 'prelude-ls'

import {mExt, joinClasses} from '@/_utils'

interface Column {
    name?: string
    width?: number | string
    classes?: string[]
    [key: string]: any
}

/**
 * Scale the width's of the given columns so that their total
 * is equal to 100 (%).
 *
 * Each column is expected to have a numeric width attribute.
 *
 * example:
 *     >>> auto_scale_columns([{name: 'A', width: 1}, {name: 'B', width: 3}])
 *     [{name: 'A', width: 25}, {name: 'B', width: 75}]
 */
export const auto_scale_columns = (columns: Column[]): Column[] => {
    const total_width = sum(map((col: Column) => col.width as number, columns))
    const factor = 100 / total_width
    return map((col: Column) => ({
        ...col,
        width: (col.width as number) * factor,
    }), columns)
}

/**
 * Make a colgroup tag given the list of columns.
 *
 * Each column must have a numeric width attribute.
 */
export const colgroup = (columns: Column[]) => {
    return m('colgroup',
        columns.map(column => {
            if (column.width) {
                if (typeof column.width === 'string') {
                    return m('col', {style: {width: column.width}})
                } else {
                    return m('col', {style: {width: column.width.toFixed(1) + '%'}})
                }
            } else {
                return m('col')
            }
        }),
    )
}

/**
 * Make a table tag.
 */
export const table = mExt('table')

/**
 * Create a table header given the set of columns.
 *
 * view_column is a function that is responsible for filling each header cell.
 * The first argument (cell in the example below) is a m_ext mithril tag.
 * You are expected to call this function and return it's output.
 *
 * example:
 *     view_column = (cell, column, index) => cell(column.title)
 *     header([{title: 'A', width: 1}], view_column)
 */
export const header = (
    columns: Column[],
    view_column: (cell: any, column: Column, index: number) => any,
) => {
    return m('thead.thead-default',
        m('tr',
            columns.map((col:any, index) =>
                view_column(
                    mExt('th', {class: joinClasses(col.classes)}),
                    col,
                    index,
                ),
            ),
        ),
    )
}

export default {
    auto_scale_columns,
    colgroup,
    table,
    header,
}
