import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'
import {proxy} from '@bitstillery/common/lib/proxy'

import {UserDropDown} from '../components/users'

import {DieselAndCurrencyWidget} from './widgets/diesel_and_currency_widget'
import {MinePurchaseWidget} from './widgets/mine_purchase'
import {YearSalesIndexesWidget} from './widgets/year_sales_indexes_widget'
import {ThisMonthSalesStatusWidget} from './widgets/mine_sales_widget'
import {SalesStatisticsWidget} from './widgets/sales_statistics_widget'
import {PurchaseStatisticsWidget} from './widgets/purchase_statistics_widget'
import {RelationsWidget} from './widgets/relations_widget'
import {SalesOrderTurnoverWidget} from './widgets/sales_order_turnover_widget'

import {DEFAULT_ROUTE} from '@/routes'
import {$m, $s} from '@/app'
import {StockValueWidget} from '@/dashboard/widgets/stock_value_widget'

export interface UserStatsModel {
    loading: boolean
    user_artkey: number | null
}

export default class FiguresDashboard extends MithrilTsxComponent<unknown> {
    selected_user_artkey: number | null = null

    data = proxy({
        loading: true,
        user_artkey: null,
    })

    async oncreate() {
        const search_text = window.location.search
        if (search_text) {
            const code_param = /\?code=(.*)/.exec(search_text)
            if (code_param) {
                if (code_param.length !== 2) {
                    notifier.notify('Authentication code has changed at exact. Programming error!', 'warning')
                    return
                }
                const auth_code = code_param[1]
                // Remove the one-time code querystring from exact from the url. Only
                // replacing with a hashbang doesn't trigger the navigation bar, so
                // it is done twice.
                history.replaceState({}, '', '/')
                history.replaceState({}, '', `#!${DEFAULT_ROUTE}`)

                $m.data.exact_keys.login_oauth(auth_code)
            }
        }
    }

    view(): m.Children {
        return (
            <div className="c-dashboard-figures view">
                <div className="columns">
                    <div className="column">
                        <YearSalesIndexesWidget />
                    </div>

                    <div className="column">
                        <DieselAndCurrencyWidget />
                        <StockValueWidget />
                    </div>
                </div>

                <div className="management-panel">
                    <div className="c-filter-group">
                        <UserDropDown
                            cache_key="mine-dashboard-user"
                            model={[this, 'selected_user_artkey']}
                            onchange={(artkey) => {
                                this.data.user_artkey = artkey
                                this.data.loading = false
                            }}
                            placeholder={`Whole ${$s.session.account.slug} account...`}
                        />
                    </div>
                    <div className="columns">
                        <div className="column">
                            {/* done */}
                            <ThisMonthSalesStatusWidget model={this.data}/>
                            {/* done */}
                            <SalesOrderTurnoverWidget model={this.data}/>
                            {/* done */}
                            <RelationsWidget model={this.data} />
                        </div>
                        <div className="column">
                            {/* done */}
                            <MinePurchaseWidget model={this.data}/>
                            {/* done */}
                            <SalesStatisticsWidget model={this.data}/>
                            {/* done */}
                            <PurchaseStatisticsWidget model={this.data}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
