/** llm:tested */
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'

import inputs from '@/components/inputs'
import {button_with_icon} from '@/components/_buttons'
import {check_vat_id} from '@/data/vatcheck/actions'
import {CountriesSelect} from '@/components/html_components'

interface VatCheckResult {
    vat_id: string
    country_code: string
    valid: boolean
    trader_name: string
}

export default function(): m.Component {
    this.vat_id = window.prop('')
    this.country_code = window.prop('')
    this.prod_mode = window.prop(true)
    this.result = window.prop<VatCheckResult | null>(null)
    this.loading = window.prop(false)

    return {
        view: (_vnode: m.Vnode<any>) => {
            return (
                <div className="c-vat-check view">
                    <form onsubmit={() => {
                        check_vat_id(
                            this.result,
                            this.loading,
                            null,
                            this.country_code(),
                            this.vat_id(),
                            this.prod_mode(),
                        )
                    }}>
                        <div className="fieldset">
                            <p>
                                Verify a VAT number with the
                                <a href="https://ec.europa.eu/taxation_customs/vies/"
                                    target="_blank">
                                    European VIES API
                                </a>
                                .
                            </p>
                            {inputs.field('VAT ID', inputs.text(this.vat_id))}
                            {inputs.field('Country',
                                <CountriesSelect
                                    model={[this, 'country_code']}
                                    only_eu={true}
                                />,
                            )}
                            {inputs.checkbox(this.prod_mode, {
                                help: 'This should be left enabled for regular VAT checks.',
                                label: 'Use real VIES API',
                            })}
                            {inputs.field('', button_with_icon('Check VAT ID', 'fa-check-double', {
                                class: 'btn-success btn-submit',
                            }))}
                        </div>
                    </form>
                    {this.loading() ?
                        <Spinner /> :
                        this.result() && [
                            <h3>Check result</h3>,
                            <div className="fieldset">
                                <div className="field-readonly">
                                    <div className="key">VAT ID</div>
                                    <div className="value">{this.result()!.vat_id}</div>
                                </div>
                                <div className="field-readonly">
                                    <div className="key">Country</div>
                                    <div className="value">{this.result()!.country_code}</div>
                                </div>
                                <div className="field-readonly">
                                    <div className="key">Result</div>
                                    <div className="value">
                                        {this.result()!.valid ? 'Valid' : 'Invalid'}
                                    </div>
                                </div>
                                <div className="field-readonly">
                                    <div className="key">Trader</div>
                                    <div className="value">{this.result()!.trader_name}</div>
                                </div>
                            </div>,
                        ]
                    }
                </div>
            )
        },
    }
}
