/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {$m} from '@/app'
import {PortalTip} from '@/portal/models'

interface SaveResponse {
    success: boolean
    message?: string
    artkey?: string
}

export class PortalTipsUpsert extends MithrilTsxComponent<any> {
    tip: PortalTip
    create: boolean

    constructor() {
        super()
        this.tip = new PortalTip()
        this.create = false

        const artkey = m.route.param('artkey')
        if (artkey) {
            this.query_tip(artkey)
        } else {
            this.create = true
        }
    }

    query_tip(artkey: string) {
        api.call('portal.tip.get_single_tip', {artkey: artkey}, this.set_tip.bind(this))
    }

    set_tip(resp: any) {
        if (resp.success) {
            try {
                this.tip = new PortalTip(resp.result)
            } catch (e) {
                $m.common.generic_error_handler(e)
            }
        } else {
            notifier.notify('Unknown tip.', 'danger')
            m.route.set('/portal/portal-tips')
        }
    }

    save(e: Event) {
        e.preventDefault()

        const data = {
            artkey: this.tip.artkey(),
            title: this.tip.title(),
            content: this.tip.content(),
        }
        api.call('portal.tip.create_or_update_tip', data, this.handle_save.bind(this))
    }

    handle_save(result: SaveResponse) {
        if (!result.success) {
            notifier.notify(result.message || '', 'danger')
        } else {
            if (this.create) {
                notifier.notify(`Successfully created new portal tip '${this.tip.title()}'.`, 'success')
                m.route.set('/portal/portal-tips/' + result.artkey + '/edit')
            } else {
                notifier.notify('Successfully updated tip.', 'success')
            }
        }
    }

    view() {
        return (
            <div class="c-tips view">
                <div class="btn-toolbar">
                    <button
                        class="btn btn-default"
                        type="button"
                        onclick={() => m.route.set('/portal/portal-tips')}
                    >
                        <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                    </button>
                </div>

                <form class="flex-form" onsubmit={(e) => this.save(e)}>
                    <div class="fieldset">
                        <div class="field">
                            <label for="tip_title">Title</label>
                            <input
                                class="form-control"
                                id="tip_title"
                                type="text"
                                required={true}
                                placeholder="tip title"
                                value={this.tip.title() || ''}
                                oninput={(ev: InputEvent) =>
                                    this.tip.title((ev.target as HTMLInputElement).value)}
                            />
                        </div>
                        <div class="field">
                            <label for="tip_content">Message</label>
                            <textarea
                                class="form-control"
                                id="tip_content"
                                rows={15}
                                placeholder="tip message"
                                value={this.tip.content() || ''}
                                oninput={(ev: InputEvent) =>
                                    this.tip.content((ev.target as HTMLTextAreaElement).value)}
                            />
                        </div>
                    </div>
                    <button class="btn btn-success btn-submit">
                        {this.create ? 'Create Tip' : 'Update Tip'}
                    </button>
                </form>
            </div>
        )
    }
}
