/** llm:tested */
import {compact, map, groupBy} from 'prelude-ls'

import {Supplier} from '@/models/suppliers'
import {$m} from '@/app'

export class BulkUpdateField {
    static QUANTITY_UPDATES = 'quantity_updates'
    static PRICE_UPDATES = 'price_updates'
    static IS_SPECIAL_UPDATES = 'is_special_updates'

    static all = [
        BulkUpdateField.QUANTITY_UPDATES,
        BulkUpdateField.PRICE_UPDATES,
        BulkUpdateField.IS_SPECIAL_UPDATES,
    ]
}

export class OfferType {
    static NORMAL = 'normal'
    static SPECIAL = 'special'
    static PRICELIST = 'pricelist'

    static all = {
        [OfferType.NORMAL]: 'Normal offer',
        [OfferType.SPECIAL]: 'Special offer',
        [OfferType.PRICELIST]: 'Pricelist',
    }

    static offer_type_options = {
        [OfferType.NORMAL]: 'Normal offer',
        [OfferType.SPECIAL]: 'Special offer',
    }
}

export class Priority {
    static UNIQUE = 1
    static BULK = 2

    static all = {
        [Priority.UNIQUE]: 'Unique',
        [Priority.BULK]: 'Bulk',
    }
}

export class Offer {
    artkey = window.prop('')
    title = window.prop('')
    remark = window.prop('')
    start_date = window.prop(null)
    end_date = window.prop(null)
    incoterm = window.prop('')
    incoterm_location = window.prop('')
    priority = window.prop(1)
    offer_type = window.prop('special')
    created_timestamp = window.prop(null)
    published_timestamp = window.prop(null)
    portal_popup_artkey = window.prop(null)
    portal_popup = window.prop(new PortalPopup({}))
    relation_filters = window.prop(null)

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'portal_popup') {
                this.portal_popup(new PortalPopup(json[prop]))
                this.portal_popup_artkey(this.portal_popup().artkey())
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }
    }
}

export class CustomOfferItem {
    artkey = window.prop('')
    offer_artkey = window.prop('')
    supplier = window.prop(new Supplier({}))
    supplier_artkey = window.prop('')
    delivery_period = window.prop(null)
    is_special = window.prop(false)
    remark = window.prop('')
    position = window.prop(null)
    minimum_quantity = window.prop(null)
    maximum_quantity = window.prop(null)
    offer_item_artkey = window.prop(null)
    offer_item_quantity = window.prop(null)
    custom_quantity = window.prop(null)
    quantity = window.prop(null)
    original_price = window.prop(null)
    custom_price_per_case = window.prop(null)
    price_per_case = window.prop(null)
    euro_price_per_case = window.prop(null)
    currency = window.prop('')
    purchase_price_per_case = window.prop(null)
    purchase_currency = window.prop(null)
    name = window.prop('')
    category = window.prop('')
    bottle_artkey = window.prop(null)
    volume = window.prop(null)
    alcohol_percentage = window.prop(null)
    refill_status = window.prop('ref')
    case_artkey = window.prop(null)
    number_of_bottles = window.prop(null)
    gift_box_type = window.prop('')
    tax_label = window.prop('')
    customs_status = window.prop('')
    best_before_date = window.prop(null)
    item_tags = window.prop([])
    item_tags_sorted_artkeys = window.prop(null)

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'supplier') {
                this.supplier(new Supplier(json[prop]))
                this.supplier_artkey(this.supplier().artkey())
            } else if (prop === 'item_tags') {
                this.item_tags(json[prop] || [])
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }
    }

    item_tags_per_category(): any {
        return groupBy(
            (tag: any) => tag.category_artkey(),
            compact(map($m.data.item_tag.get_item_tag, this.item_tags())),
        )
    }

    price_per_bottle(): number {
        return this.price_per_case() / this.number_of_bottles()
    }

    euro_price_per_bottle(): number {
        return this.euro_price_per_case() / this.number_of_bottles()
    }
}

export class PortalPopup {
    artkey = window.prop('')
    title = window.prop('')
    text = window.prop('')
    image_s3_key = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            (this as any)[prop] = window.prop(json[prop])
        }
    }
}
