import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {FieldSelectMany, FieldSelect} from '@bitstillery/common/components'
import {FieldSelectManyAttrs, FieldSelectAttrs} from '@bitstillery/common/types/field'
import {Observable} from 'rxjs'

import {$m} from '@/app'
import {GetItemTagResponse, ItemTagsDropDownData} from '@/factserver_api/item_tags'

interface TaxLabelDropDownAttrs extends FieldSelectAttrs {
    get_all_for_drop_down_response$: Observable<GetItemTagResponse[]>
}
export class TaxLabelDropDown extends MithrilTsxComponent<TaxLabelDropDownAttrs> {
    options: GetItemTagResponse[] = []
    get_item_tag_response$ = ItemTagsDropDownData.tax_label_item_tags$()

    oninit() {
        this.get_item_tag_response$.subscribe({
            next: (response) => (this.options = response),
        })
    }

    view(vnode: m.Vnode<TaxLabelDropDownAttrs>): m.Children {
        return <FieldSelect
            disabled={vnode.attrs.disabled}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={this.options.map((item_tag: GetItemTagResponse) => ({value: item_tag.name, label: item_tag.name}))}
            placeholder="Select Tax label..."
        />
    }
}

export class CustomsStatusDropDown extends MithrilTsxComponent<FieldSelectAttrs> {
    customs_statuses: string[] = ['T1', 'T2']

    view(vnode: m.Vnode<FieldSelectAttrs>): m.Children {
        return <FieldSelect
            disabled={vnode.attrs.disabled}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={this.customs_statuses.map((custom_status: string) => ({value: custom_status, label: custom_status}))}
        />
    }
}

export class PackagingLabelDropDown extends MithrilTsxComponent<FieldSelectAttrs> {

    view(vnode: m.Vnode<FieldSelectAttrs>): m.Children {
        return <FieldSelect
            disabled={vnode.attrs.disabled}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={vnode.attrs.options}
            placeholder="Packaging label"
        />
    }
}

export class PackSizeDropDown extends MithrilTsxComponent<FieldSelectManyAttrs> {

    view(vnode: m.Vnode<FieldSelectManyAttrs>): m.Children {
        return <FieldSelect
            disabled={vnode.attrs.disabled}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={vnode.attrs.options}
            placeholder="Select Pack size..."
        />
    }
}

export class DamagesDropDown extends MithrilTsxComponent<FieldSelectManyAttrs> {

    view(vnode: m.Vnode<FieldSelectManyAttrs>): m.Children {
        return <FieldSelectMany
            disabled={vnode.attrs.disabled}
            help={vnode.attrs.help}
            label={vnode.attrs.label ? vnode.attrs.label : 'Damages'}
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={vnode.attrs.options}
            placeholder="Select damages..."
        />
    }
}

export class GeneralDropDown extends MithrilTsxComponent<FieldSelectManyAttrs> {

    view(vnode: m.Vnode<FieldSelectManyAttrs>): m.Children {
        return <FieldSelectMany
            disabled={vnode.attrs.disabled}
            help={vnode.attrs.help}
            label={vnode.attrs.label ? vnode.attrs.label : 'General tags'}
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={vnode.attrs.options}
            placeholder="Select general tags..."
        />
    }
}

export class GiftBoxTypeDropDown extends MithrilTsxComponent<FieldSelectAttrs> {
    view(vnode: m.Vnode<FieldSelectAttrs>): m.Children {
        return <FieldSelect
            label="Giftbox Type"
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={$m.data.gift_box_types.map((type) => ({value: type, label: type}))}
            placeholder={vnode.attrs.placeholder ?? 'Select Giftbox Type...'}
        />
    }
}
