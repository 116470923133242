/** llm:tested */
import m from 'mithril'
import {join, min} from 'prelude-ls'
import {Amount, FieldMoney} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {formatDate, formatPercentage, maybeMap, pluralize} from '@/_utils'
import * as api from '@/api'
import * as inputs from '@/components/inputs'
import {icon_with_popover} from '@/components/icon'
import {icon_button} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import {rank} from '@/components/rank'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {SearchBar} from '@/components/collection/search_bar'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {$s, $m} from '@/app'

type Record = {
    artkey: string
    market_rank: number
    market_total: number
    [key: string]: any
}

export class OfferSilentStock extends MithrilTsxComponent<any> {
    silent_stock: any
    search_bar_controller: any
    product_management_api: ProductManagementApi
    search_input_ctrl: any

    constructor() {
        super()
        this.silent_stock = new Collection({
            api_function_name: 'pricelist.get_offer_item_records',
            query_limit: 25,
            sort_order: [
                {name: 'case_number_of_bottles', direction: 'asc'},
                {name: 'bottle_volume', direction: 'asc'},
                {name: 'bottle_alcohol_percentage', direction: 'asc'},
                {name: 'bottle_refill_status', direction: 'desc'},
            ],
            default_sort_by: 'product_name',
            default_sort_order: 'asc',
            filter_serverside: true,
            additional_params: () => ({
                only_show_type: ['purchase', 'stock'],
                silent_stock: true,
            }),
            dont_reuse: true,
        })
        this.search_bar_controller = null
        this.product_management_api = new ProductManagementApi()
    }

    submit_search = (text: string) => {
        this.silent_stock.update_search_term(text)
        this.silent_stock.submit_search()
    }

    oncreate() {
        const query = m.route.param('q')
        if (query) {
            this.search_input_ctrl.submit_search(query.replace(/\+/g, ' '))
        } else {
            this.silent_stock.init()
        }

        const q = m.route.param('q')
        if (q) {
            this.search_input_ctrl.submit_search(q.replace(/\+/g, ' '))
        }
    }

    save_offer_item = (record: Record, callback: () => void) => {
        const data = {
            artkey: record.artkey,
            maximum_quantity: record.maximum_quantity(),
            minimum_quantity: record.minimum_quantity(),
            sales_price_per_case: record.price_per_case(),
            delivery_period: record.delivery_period(),
            is_hidden: record.is_hidden,
            silence: true,
        }

        api.call2('pricelist.update_offer_item', data, callback)
    }

    color_class = (value: number) => {
        if (value > 8.8) {
            return 'analysis-good-color'
        } else if (value < 0) {
            return 'analysis-bad-color'
        } else {
            return ''
        }
    }

    view() {
        return <div class="c-pricelist-silent-stock view">
            <div class="alert alert-info">
                This screen shows all offer items that have:
                <ul>
                    <li>throughput &lt; 10%</li>
                    <li>no sales in the last month</li>
                    <li>come into stock more than 2 months ago</li>
                </ul>
                You can change the price here or ignore the item for a month by clicking on the thumbs up icon.
            </div>

            <div class="c-filter-group">
                <SearchBar
                    placeholder="Search products..."
                    on_submit={this.submit_search}
                    default_search_text={this.silent_stock.search_term()}
                    search_bar_controller={(search_bar_controller: any) => this.search_bar_controller = search_bar_controller}
                    on_get_suggestions$={(filter_text: string) => this.product_management_api.get_simple_product_names(filter_text)}
                />
            </div>

            {this.silent_stock.show_counter()}

            <CollectionTable
                collection={this.silent_stock}
                options={{
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: false,
                    autoscale: true,
                    unique_name: 'silent_stock_list',
                }}
                row_model={(record: Record) => {
                    Object.assign(record, {
                        edit: window.prop(false),
                        maximum_quantity: window.prop(record.maximum_quantity),
                        minimum_quantity: window.prop(record.minimum_quantity),
                        delivery_period: window.prop(record.delivery_period),
                        price_per_case: window.prop(record.price_per_case),
                        old_maximum_quantity: record.maximum_quantity,
                        old_minimum_quantity: record.minimum_quantity,
                        old_delivery_period: record.delivery_period,
                        old_price_per_case: record.price_per_case,
                    })
                    return record
                }}
                view_details={(record: Record) => (
                    <CaseInfoPanel
                        case_artkey={record.case_artkey}
                        case_customs_status={record.case_customs_status}
                        item_best_before_date={record.item_best_before_date}
                        item_tags_sorted_artkeys={record.item_tags_sorted_artkeys}
                        bottle_artkey={record.bottle_artkey}
                        current_supplier_artkey={record.offer_item_type === 'tbo' ? record.supplier_artkey : null}
                    />
                )}
                columns={[
                    {
                        width: 6,
                        name: 'Rank',
                        sort: true,
                        field: 'market_score',
                        descending: true,
                        function: (record: Record) => rank(extract_rank(record)),
                    },
                    {
                        width: 4,
                        name: 'Article code',
                        field: 'case_article_code',
                        sort: true,
                    },
                    {
                        width: 12,
                        name: 'Product',
                        sort: true,
                        field: 'product_name',
                        ellipsis: true,
                    },
                    {
                        width: 5,
                        name: 'Category',
                        sort: true,
                        field: 'product_category_name',
                        ellipsis: true,
                        function: (record: Record) => <span class="text-capitalize">{record.product_category_name}</span>,
                    },
                    {
                        width: 3,
                        name: 'Btl / cs',
                        sort: true,
                        field: 'case_number_of_bottles',
                        default_visible: false,
                        classes: ['number'],
                    },
                    {
                        width: 4,
                        name: 'Size',
                        sort: true,
                        field: 'bottle_volume',
                        default_visible: false,
                        function: (record: Record) => (
                            <span>{(+record.bottle_volume).toFixed(1)}cl</span>
                        ),
                        classes: ['number'],
                    },
                    {
                        width: 4,
                        name: 'Alc %',
                        sort: true,
                        field: 'bottle_alcohol_percentage',
                        default_visible: false,
                        function: (record: Record) => (
                            <span>{(+record.bottle_alcohol_percentage).toFixed(1)}%</span>
                        ),
                        classes: ['number'],
                    },
                    {
                        width: 3,
                        name: 'Ref',
                        sort: true,
                        default_visible: false,
                        field: 'bottle_refill_status',
                    },
                    {
                        width: 10,
                        name: 'Specs',
                        sort: false,
                        function: (record: Record) => {
                            const specs = []
                            specs.push(record.case_number_of_bottles)
                            specs.push((+record.bottle_volume).toFixed(1))
                            specs.push((+record.bottle_alcohol_percentage).toFixed(1))
                            specs.push(record.bottle_refill_status)
                            return join(' / ', specs)
                        },
                    },
                    {
                        width: 4,
                        name: 'GB',
                        sort: true,
                        field: 'case_gift_box_type',
                        default_visible: false,
                        ellipsis: true,
                    },
                    {
                        width: 4,
                        name: 'Tax Label',
                        sort: true,
                        field: 'case_tax_label',
                        default_visible: false,
                        ellipsis: true,
                    },
                    {
                        width: 5,
                        header: 'BBD',
                        name: 'Best before date',
                        sort: true,
                        field: 'item_best_before_date',
                        default_visible: false,
                        transform: maybeMap(formatDate),
                    },
                    {
                        width: 10,
                        name: 'Features',
                        function: (record: Record) => {
                            const features = []
                            if (record.case_gift_box_type) {
                                features.push(record.case_gift_box_type)
                            }
                            if (record.case_tax_label) {
                                features.push(record.case_tax_label)
                            }
                            if (record.item_best_before_date) {
                                features.push(`BBD: ${formatDate(record.item_best_before_date)}`)
                            }
                            if (record.item_damages) {
                                features.push(record.item_damages.replace(', ', ' / '))
                            }
                            if (record.item_general_tags) {
                                features.push(record.item_general_tags.replace(', ', ' / '))
                            }
                            if (record.item_pack_size) {
                                features.push(record.item_pack_size)
                            }
                            if (record.item_packaging) {
                                features.push(record.item_packaging)
                            }
                            return join(' / ', features)
                        },
                    },
                    {
                        width: 3,
                        header: 'Cus.',
                        name: 'Customs status',
                        sort: true,
                        field: 'case_customs_status',
                    },
                    {
                        width: 6,
                        name: 'Stock value',
                        sort: true,
                        field: 'euro_total_stock_value',
                        classes: ['price'],
                        function: (record: Record) => {
                            if (record.offer_item_type !== 'tbo') {
                                return <Amount
                                    amount={+record.euro_total_stock_value}
                                    currency={$s.currencies.default}
                                />
                            }
                            return '-'
                        },
                    },
                    {
                        width: 2,
                        name: 'Number of cases in stock',
                        header: icon_with_popover({
                            iconId: 'home',
                            content: 'Number of cases in stock',
                        }),
                        sort: true,
                        field: 'number_of_cases_in_stock',
                        classes: ['number'],
                    },
                    {
                        width: 2,
                        name: 'Number of cases in purchase',
                        header: icon_with_popover({
                            iconId: 'shopping-cart',
                            content: 'Number of cases in purchase',
                        }),
                        sort: true,
                        field: 'number_of_cases_in_purchase',
                        classes: ['number'],
                    },
                    {
                        width: 2,
                        name: 'Number of cases in sales',
                        header: icon_with_popover({
                            iconId: 'screenshot',
                            content: 'Number of cases in sales',
                        }),
                        sort: true,
                        field: 'number_of_cases_in_sales',
                        classes: ['number'],
                    },
                    {
                        width: 2,
                        name: 'Number of cases available',
                        header: icon_with_popover({
                            iconId: 'fa-shield-alt',
                            content: 'Number of cases available',
                        }),
                        sort: true,
                        field: 'number_of_cases_available',
                        classes: ['number'],
                    },
                    {
                        width: 3,
                        name: 'Throughput',
                        header: icon_with_popover({
                            iconId: 'flash',
                            content: 'Throughput',
                        }),
                        field: 'item_throughput',
                        sort: true,
                        classes: ['number'],
                        transform: maybeMap(formatPercentage),
                    },
                    {
                        width: 7,
                        header: 'Max Qty',
                        name: 'Maximum Quantity',
                        classes: ['number'],
                        function: (record: Record) => {
                            if (record.edit()) {
                                return inputs.number(record.maximum_quantity, {min: 0})
                            }
                            if (record.maximum_quantity() !== null) {
                                return record.maximum_quantity()
                            }
                            return '-'
                        },
                    },
                    {
                        width: 7,
                        header: 'MOQ',
                        name: 'Minimum Order Quantity',
                        classes: ['number'],
                        function: (record: Record) => {
                            if (record.edit()) {
                                return inputs.number(record.minimum_quantity, {min: 0})
                            }
                            if (record.minimum_quantity() !== null) {
                                return record.minimum_quantity()
                            }
                            return '-'
                        },
                    },
                    {
                        width: 5,
                        header: 'List Qty',
                        name: 'List Quantity',
                        field: 'list_quantity',
                        classes: ['number'],
                        function: (record: Record) => {
                            if (record.maximum_quantity() !== null) {
                                return min(record.maximum_quantity(), record.number_of_cases_available)
                            }
                            return record.number_of_cases_available
                        },
                    },
                    {
                        width: 10,
                        name: 'Avg purchase / cs',
                        sort: true,
                        field: 'avg_purchase_price',
                        classes: ['price'],
                        function: (record: Record) => {
                            if (record.avg_purchase_price) {
                                return <Amount
                                    amount={record.avg_purchase_price}
                                    currency={$s.currencies.default}
                                />
                            }
                            return '-'
                        },
                    },
                    {
                        width: 15,
                        name: 'Sales / cs',
                        sort: true,
                        field: 'price_per_case',
                        classes: ['price'],
                        function: (record: Record) => {
                            if (record.edit()) {
                                return <FieldMoney
                                    currency={[$s.currencies, 'default']}
                                    model={[record, 'price_per_case']}
                                />
                            }
                            if (+record.price_per_case()) {
                                return <Amount
                                    amount={+record.price_per_case()}
                                    currency={$s.currencies.default}
                                />
                            }
                            return '-'
                        },
                    },
                    {
                        width: 6,
                        name: 'Margin %',
                        sort: true,
                        field: 'margin_percentage',
                        classes: ['price'],
                        function: (record: Record) => {
                            const margin_percentage = record.edit()
                                ? (record.price_per_case() - record.avg_purchase_price) / record.avg_purchase_price * 100
                                : +record.margin_percentage
                            return <span class={this.color_class(margin_percentage)}>{margin_percentage.toFixed(2)}%</span>
                        },
                    },
                    {
                        width: 6,
                        name: 'List price/btl',
                        sort: true,
                        field: 'list_price_per_bottle',
                        classes: ['price'],
                        default_visible: false,
                        function: (record: Record) => (
                            <Amount
                                amount={() => +record.price_per_case() / +record.case_number_of_bottles}
                                currency={$s.currencies.default}
                            />
                        ),
                    },
                    {
                        width: 4,
                        name: 'Delivery period',
                        sort: true,
                        field: 'delivery_period',
                        classes: ['number'],
                        function: (record: Record) => {
                            if (record.edit()) {
                                return inputs.number(record.delivery_period, {min: 1})
                            }
                            if (record.delivery_period() !== null) {
                                return `${record.delivery_period()} ${pluralize(record.delivery_period(), 'week', 'weeks')}`
                            }
                            return '-'
                        },
                    },
                    {
                        width: 3,
                        name: 'Item type',
                        sort: true,
                        field: 'offer_item_type',
                        transform: (type: string) => {
                            if (type === 'tbo') return 'TBO'
                            return type.charAt(0).toUpperCase() + type.slice(1)
                        },
                    },
                    {
                        width: 18,
                        header: '',
                        name: 'Actions',
                        function: (record: Record) => (
                            <div class="btn-toolbar no-click">
                                <div class="btn-group">
                                    {record.edit() ? icon_button('ban-circle', {
                                        class: 'btn-info no-click',
                                        onclick: () => {
                                            record.price_per_case(record.old_price_per_case)
                                            record.delivery_period(record.old_delivery_period)
                                            record.maximum_quantity(record.old_maximum_quantity)
                                            record.minimum_quantity(record.old_minimum_quantity)
                                            record.edit(false)
                                        },
                                    }) : icon_button('pencil', {
                                        class: 'btn-default no-click',
                                        onclick: () => record.edit(true),
                                    })}

                                    {icon_button('thumbs-up', {
                                        class: 'btn-success no-click',
                                        onclick: () => {
                                            this.save_offer_item(record, () => {
                                                if (record.old_price_per_case !== record.price_per_case()) {
                                                    $m.common.observable.broadcast('stock_updated')
                                                }
                                                notifier.notify('Silenced item for one month.', 'success')
                                                this.silent_stock.soft_delete(record.artkey)
                                            })
                                        },
                                    })}
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    }
}

function extract_rank(record: Record) {
    return {
        rank: record.market_rank,
        total: record.market_total,
    }
}
