/** llm:tested */
import m from 'mithril'
import {countries} from '@bitstillery/common/lib/countries'
import {FieldMoney, FieldSelect, Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import inputs from '@/components/inputs'
import {Warehouse} from '@/models/data'
import {CountriesSelect} from '@/components/html_components'
import {AttachmentList, AttachmentHelper, UploadAttachment} from '@/components/attachment_list'
import {AttachmentType} from '@/factserver_api/attachment_api'
import {$m, $s} from '@/app'

export class WarehouseUpsert extends MithrilTsxComponent<any> {
    destination: any
    attachment_to_categorize: boolean
    attachments: any
    user: any
    create: any
    country_to_region$: any
    regions: any
    supplier_artkey: string
    destination_artkey: string
    display_name: any
    attachment_helper: AttachmentHelper | null

    constructor() {
        super()
        this.destination = window.prop(null)
        this.attachment_to_categorize = false
        this.attachments = window.prop([])
        this.user = window.prop([])

        this.create = window.prop(false)
        this.country_to_region$ = null
        this.regions = window.prop([])

        this.supplier_artkey = m.route.param('supplier_artkey')
        this.destination_artkey = m.route.param('artkey')

        this.display_name = window.prop('')

        if (this.destination_artkey) {
            this.query_destination(this.destination_artkey)
            this.attachment_helper = new AttachmentHelper(this.destination_artkey, AttachmentType.WAREHOUSE)
        } else {
            this.create(true)
            this.destination(new Warehouse())
            this.attachment_helper = null
        }
    }

    redirect_to_deactivate_page(artkey: string) {
        const base_url = this.supplier_artkey
            ? `/crm/relations/${this.supplier_artkey}/edit/warehouses/`
            : '/data/warehouses/'
        m.route.set(`${base_url}${artkey}/deactivate`)
    }

    redirect_to_edit_page(artkey: string) {
        const base_url = this.supplier_artkey
            ? `/crm/relations/${this.supplier_artkey}/edit/warehouses/`
            : '/data/warehouses/'
        m.route.set(`${base_url}${artkey}/edit`, {key: 'edit'})
    }

    redirect_to_previous_page() {
        const url = this.supplier_artkey
            ? `/crm/relations/${this.supplier_artkey}/edit?tab=destinations`
            : '/data/warehouses'
        m.route.set(url)
    }

    query_destination(artkey: string) {
        api.callAndThen('destinations.get_destination', {artkey: artkey}, {
            success: (resp: any) => {
                this.destination($m.data.warehouses.create_warehouse(resp.result))
                this.update_regions(this.destination().country_code())
            },
            failure: () => {
                notifier.notify(`Unknown ${this.destination().destination_type()}.`, 'danger')
                this.redirect_to_previous_page()
            },
        })
    }

    update_regions(country_code: string) {
        $m.data.region_drop_down_data.for_country_code(country_code).subscribe((regions: any) => {
            this.regions(regions.map((region: any) => ({
                label: `${region.name} (${region.code})`,
                value: region.code,
            })))

            if (!this.regions().find((region: any) => region.value === this.destination().region_code())) {
                this.destination().region_code('')
            }
            m.redraw()
        })
    }

    save(e: Event) {
        e.preventDefault()

        const data: any = {
            destination_type: this.destination().destination_type(),
            artkey: this.destination().artkey(),
            can_handle_our_stock: this.destination().can_handle_our_stock(),
            name: this.destination().name(),
            emailaddress: this.destination().emailaddress(),
            street_address: this.destination().street_address(),
            zip_code: this.destination().zip_code(),
            city: this.destination().city(),
            country_code: this.destination().country_code(),
            region_code: this.destination().region_code(),
            warehouse_id: this.destination().warehouse_id(),
            vat_id: this.destination().vat_id(),
            excise_id: this.destination().excise_id(),
            currency: this.destination().currency(),
            warehouse_base_costs: +this.destination().warehouse_base_costs(),
            warehouse_costs_per_case: +this.destination().warehouse_costs_per_case(),
        }

        if (this.supplier_artkey) {
            data.supplier_artkey = this.supplier_artkey
        }
        api.call('destinations.update_or_create_destination', data, this.handle_save.bind(this))
    }

    handle_save(result: any) {
        if (!result.success) {
            notifier.notify(result.message, 'danger')
        } else {
            $m.common.observable.broadcast('warehouses_updated', '')
            if (this.create()) {
                notifier.notify(`Successfully created new ${this.destination().destination_type()} ${this.destination().name()}.`, 'success')
                this.redirect_to_edit_page(result.artkey)
            } else {
                notifier.notify(`Successfully updated ${this.destination().destination_type()}.`, 'success')
            }
        }
    }

    set_street_address(value: string) {
        this.destination().street_address(value.replace(/\n/g, '|'))
    }

    get_display_name() {
        this.display_name('')
        if (this.destination().name()) {
            this.display_name(this.display_name() + this.destination().name())
        }
        if (this.destination().name() && this.destination().country_code()) {
            this.display_name(this.display_name() + ' - ')
        }
        if (this.destination().country_code()) {
            this.display_name(this.display_name() + countries[this.destination().country_code()])
        }

        return this.display_name()
    }

    view() {
        if (this.destination() === null) {
            return <Spinner />
        }

        return <div class="c-destination-edit view">
            <div class="btn-toolbar">
                <button class="btn btn-default" type="button" onclick={() => this.redirect_to_previous_page()}>
                    <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                </button>
                {this.destination().artkey() &&
                    <button class="btn btn-danger" type="button"
                        onclick={() => this.redirect_to_deactivate_page(this.destination().artkey())}>
                        <span class="glyphicon glyphicon-trash"></span> Deactivate
                    </button>
                }
            </div>

            <form class="flex-form" onsubmit={(e: Event) => this.save(e)}>
                {this.attachment_helper &&
                    <div class="fieldset large">
                        <UploadAttachment attachment_helper={this.attachment_helper} />
                        <AttachmentList attachment_helper={this.attachment_helper} />
                    </div>
                }

                <div class="fieldset-group">
                    <div class="fieldset">
                        <div class="fieldset-label">General Information</div>

                        {inputs.text(this.destination().name, {label: 'Name', required: true, placeholder: 'Name'})}
                        {inputs.select(this.destination().destination_type,
                            {warehouse: 'warehouse', airport: 'airport', seaport: 'seaport'},
                            {label: 'Destination type', required: true, empty_option: false, disabled: !this.create()})}

                        {this.destination().destination_type() !== 'warehouse' &&
                            inputs.text(this.get_display_name(), {
                                disabled: true,
                                label: 'Invoice diplay name',
                                placeholder: 'Name displayed on the invoice',
                            })
                        }

                        {inputs.text(this.destination().warehouse_id, {label: 'Location ID'})}
                        {inputs.checkbox(this.destination().can_handle_our_stock, {
                            disabled: false,
                            help: 'Enable this if this warehouse can handle our stock.',
                            label: 'Warehouse with our stock',
                        })}
                    </div>

                    <div class="fieldset">
                        <div class="fieldset-label">Contact</div>
                        {this.destination().destination_type() === 'warehouse' &&
                            inputs.text(this.destination().emailaddress, {label: 'Email', placeholder: 'Email'})
                        }
                        <div class="field-group">
                            <CountriesSelect
                                label="Country"
                                model={[this.destination(), 'country_code']}
                                onchange={(value: string) => this.update_regions(value)}
                            />
                            {inputs.text(this.destination().city, {label: 'City', required: true})}
                        </div>

                        {this.regions().length > 0 &&
                            <FieldSelect
                                disabled={!this.regions() || this.regions().length === 0}
                                help={!this.regions() || this.regions().length === 0
                                    ? 'No regions available for the selected country'
                                    : undefined}
                                label="Region"
                                model={[this.destination(), 'region_code']}
                                options={this.regions()}
                                placeholder="Select a region..."
                            />
                        }

                        {this.destination().destination_type() === 'warehouse' &&
                            <div class="field-group">
                                {inputs.text(this.destination().street_address, {
                                    label: 'Address',
                                    required: this.destination().destination_type() === 'warehouse',
                                })}
                                {inputs.text(this.destination().zip_code, {label: 'Zip code'})}
                            </div>
                        }
                    </div>

                    {this.destination().destination_type() === 'warehouse' &&
                        <div class="fieldset">
                            <div class="fieldset-label">Financial</div>
                            {inputs.text(this.destination().excise_id, {label: 'Excise ID'})}
                            {inputs.text(this.destination().vat_id, {label: 'VAT ID'})}

                            {!this.supplier_artkey && [
                                inputs.select(this.destination().currency, $s.currencies.all, {label: 'Currency'}),
                                <div class="field-group">
                                    <FieldMoney
                                        currency={[this.destination(), 'currency']}
                                        label="Warehouse base costs"
                                        model={[this.destination(), 'warehouse_base_costs']}
                                    />
                                    <FieldMoney
                                        currency={[this.destination(), 'currency']}
                                        label="Warehouse costs per case"
                                        model={[this.destination(), 'warehouse_costs_per_case']}
                                    />
                                </div>,
                            ]}
                        </div>
                    }
                </div>

                <button class="btn btn-success btn-submit">
                    {this.create() ? 'Create Destination' : 'Update Destination'}
                </button>
            </form>
        </div>
    }
}
