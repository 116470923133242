import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {countries, european_union_countries} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'
import {Observable} from 'rxjs'
import {displayable_float, format_percentage, random_string} from '@bitstillery/common/ts_utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Validator} from '@bitstillery/common/lib/validation'
import {FieldSelectMany, FieldSelect, FieldTextArea, Icon} from '@bitstillery/common/components'
import {FieldSelectManyAttrs, FieldSelectAttrs, FieldTextAreaAttrs} from '@bitstillery/common/types/field'

import {MaybeObservable} from './relation'
import {SearchBar} from './collection/search_bar'
import {CancelButton, SaveButton} from './buttons'

import {randomString} from '@/_utils'
import {$s} from '@/app'
import {AllMarginThresholds, GetMarginThresholdResponse} from '@/factserver_api/margins_api'
import {CommentTemplateResponse} from '@/factserver_api/comment_templates'

export interface DropDownOptionAttrs {
    value: string
    __selected?: boolean /* For internal drop-down usage. Used for selecting the selected option. */
}

/**
 * Generates drop down option for usage in the empty option tag.
 * @param title The title to display (for instance "Select..." or "Pick an option..."
 */
export function empty_option(title: JSX.Element | string): JSX.Element {
    return <DropDownOption value={''}>{title}</DropDownOption>
}

/**
 * Inner 'options' for a drop down select. The title (representation) of the option is taken
 * from the vnode.children. The value is in vnode.attrs.value attribute.
 *
 * Usage:
 *      <DropDownOption value="13">Option number 13</DropDownOption>
 */
export class DropDownOption extends MithrilTsxComponent<DropDownOptionAttrs> {
    view(vnode: m.Vnode<DropDownOptionAttrs>): m.Children {
        return (
            <option value={vnode.attrs.value} selected={vnode.attrs.__selected}>
                {vnode.children}
            </option>
        )
    }
}

interface CheckBoxAttrs {
    id?: string
    checked: boolean
    disabled?: boolean
    onchange: (event: Event) => unknown
    icon?: JSX.Element
}

export class CheckBox extends MithrilTsxComponent<CheckBoxAttrs> {
    view(vnode: m.Vnode<CheckBoxAttrs>): m.Children {
        const check_box_id = vnode.attrs.id || random_string(12, false)
        return (
            <span className={classes('c-field-checkbox field', vnode.attrs.className, {
                disabled: vnode.attrs.disabled,
            })}>
                <div className="control">
                    <input
                        id={check_box_id}
                        type="checkbox"
                        disabled={vnode.attrs.disabled || false}
                        onchange={vnode.attrs.onchange}
                        checked={vnode.attrs.checked}
                    />
                    {(() => {
                        if (vnode.children.length > 0) {
                            return <label for={check_box_id}>{vnode.children}</label>
                        }
                        if (vnode.attrs.label) {
                            return <label for={check_box_id}>
                                {vnode.attrs.label}{vnode.attrs.icon && vnode.attrs.icon}
                                {vnode.attrs.context && <Icon name="question" tip={vnode.attrs.context} size="s" type="info" />}
                            </label>
                        }
                    })()}
                </div>
                {vnode.attrs.help && <div className="help">{vnode.attrs.help}</div>}
            </span>
        )
    }
}

interface CountriesSelectAttrs extends FieldSelectAttrs {
    only_eu?: boolean
}

export class CountriesSelect extends MithrilTsxComponent<CountriesSelectAttrs> {
    options: Array<{label: string; value: string}> = []

    async oninit(vnode) {
        this.options.splice(0, this.options.length, ...Object.keys(countries)
            .filter((country_code) => {
                if (!vnode.attrs.only_eu) return true
                return european_union_countries.includes(country_code)
            })
            .map((country_code) => ({
                label: countries[country_code] ? `${country_code} - ${countries[country_code]}` : country_code,
                value: country_code,
            })))
    }

    view(vnode: m.Vnode<CountriesSelectAttrs>): m.Children {
        return <FieldSelect
            disabled={vnode.attrs.disabled}
            help={vnode.attrs.help}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            onchange={async(value) => {
                if (vnode.attrs.onchange) {
                    vnode.attrs.onchange(value)
                }
            }}
            options={this.options}
            placeholder={vnode.attrs.placeholder || 'Select a country'}
            validation={vnode.attrs.validation}
        />
    }
}

export class CountriesMultiSelect extends MithrilTsxComponent<FieldSelectManyAttrs> {
    view(vnode: m.Vnode<FieldSelectManyAttrs>): m.Children {
        return <FieldSelectMany
            disabled={vnode.attrs.disabled}
            help={vnode.attrs.help}
            label={vnode.attrs.label ? vnode.attrs.label : 'Country'}
            model={vnode.attrs.model}
            onchange={vnode.attrs.onchange}
            options={Object.keys(countries).map((country_code: string) => ({
                label: countries[country_code] ? `${country_code} - ${countries[country_code]}` : country_code,
                value: country_code,
            }))}
            placeholder="Add a country..."
            variant="inline"
        />
    }
}

interface FormGroupAttrs {
    label?: string
    help_text?: string
}

export class FormGroup extends MithrilTsxComponent<FormGroupAttrs> {

    view(vnode: m.Vnode<FormGroupAttrs>): m.Children {
        return (
            <div className="field">
                <label>{vnode.attrs.label && vnode.attrs.label}</label>
                {vnode.children}
                {vnode.attrs.help_text && <div className={'help'}>{vnode.attrs.help_text}</div>}
            </div>
        )
    }
}

interface InputDateAttrs extends Validator {
    min?: string
    max?: string
    value: string | undefined
    onchange: (new_value: DateTime | null) => unknown
    disabled?: boolean
    required?: boolean
}

export class InputDate extends MithrilTsxComponent<InputDateAttrs> {
    view(vnode: m.Vnode<InputDateAttrs>): m.Children {
        const value =
            vnode.attrs.value && vnode.attrs.value.includes('T')
                ? DateTime.fromISO(vnode.attrs.value).toISODate()
                : vnode.attrs.value
        const element = (
            <input
                type="date"
                min={vnode.attrs.min}
                max={vnode.attrs.max}
                value={value}
                required={vnode.attrs.required}
                disabled={vnode.attrs.disabled}
                onchange={(event: InputEvent) => {
                    const event_value = (event.target as HTMLInputElement).value
                    vnode.attrs.onchange(event_value ? DateTime.fromISO(event_value) : null)
                }}
                className="no-click"
            />
        )

        if (!vnode.attrs.label) {
            return element
        }

        return (
            <div className={classes('c-field-date field', {
                disabled: vnode.attrs.disabled,
            })}>
                <label>{vnode.attrs.label}</label>
                {element}
            </div>
        )
    }
}

export interface RadioSelectionChoice {
    description: string
    value: string
    title?: string
}

interface RadioSelectionAttrs {
    value: string
    onclick: (value: string) => void
    choices: Array<RadioSelectionChoice>
    options?: { name?: string }
}

export class RadioSelection extends MithrilTsxComponent<RadioSelectionAttrs> {
    view(vnode: m.Vnode<RadioSelectionAttrs>): m.Children {
        const options = vnode.attrs.choices
        return (
            <div data-toggle="buttons" class="btn-group">
                {vnode.attrs.choices.map((choice: RadioSelectionChoice) => {
                    const name = options?.name || randomString(8)
                    return (
                        <label
                            className={vnode.attrs.value === choice.value ? 'btn btn-info' : 'btn btn-default'}
                            onclick={() => {
                                vnode.attrs.onclick(choice.value)
                            }}
                            title={choice.title}
                        >
                            <input type="radio" name={name} autocomplete="off" />
                            {choice.description}
                        </label>
                    )
                })}
            </div>
        )
    }
}

/**
 * A SearchBar variant with autosuggest, that allows multiple items
 * to be selected at the bottom of the field.
 */
export class MultiResultSearchBar extends MithrilTsxComponent<any> {
    data = proxy({
        model: '',
        show_suggestions: false,
    })

    view(vn): m.Children {
        return (
            <div className="c-multi-result-searchbar">
                <SearchBar
                    placeholder={vn.attrs.placeholder}
                    label={vn.attrs.label}
                    default_search_text={''}
                    disabled={vn.attrs.disabled}
                    matched_suggestions={
                        vn.attrs.suggestions
                            .filter((i) => i.name.toLowerCase().includes(this.data.model.toLowerCase()))
                            .map((category) => category.name)
                    }
                    model={[this.data, 'model']}
                    show_suggestions={this.data.show_suggestions}
                    onclear={() => {
                        vn.attrs.selection.splice(0, vn.attrs.selection.length)
                    }}
                    oninput={() => {
                        if (!this.data.show_suggestions) {
                            this.data.show_suggestions = true
                        }
                    }}
                    on_submit={(searchText) => {
                        this.data.show_suggestions = false
                        this.data.model = ''
                        const searchterm = searchText.toLowerCase()
                        if (!vn.attrs.suggestions.length) return
                        const matching_suggestion = vn.attrs.suggestions.find((i) => i.name.toLowerCase().includes(searchterm))
                        if (!matching_suggestion) return
                        vn.attrs.selection.push(matching_suggestion.artkey)
                    }}
                />
                <div className="selected-terms">
                    {vn.attrs.suggestions.filter((i) => vn.attrs.selection.includes(i.artkey)).map((suggestion) => (
                        <span
                            tabindex="0"
                            className="label label-default"
                            onclick={() => {
                                const index = vn.attrs.selection.findIndex((i) => i === suggestion.artkey)
                                if (index >= 0) {
                                    vn.attrs.selection.splice(index, 1)
                                }
                            }}
                        >
                            {suggestion.name}
                            <span className="glyphicon glyphicon-remove remove" />
                        </span>
                    ))}
                </div>
            </div>
        )
    }
}

interface DecimalAttrs {
    number_of_fraction_digits?: number // default 2
    value: number
}

/** Display a decimal value with formatting as specified by the user settings. */
export class Decimal extends MithrilTsxComponent<DecimalAttrs> {
    decimal_locale: string
    constructor() {
        super()
        this.decimal_locale = $s.identity.user.decimal_locale
    }

    view(vnode: m.Vnode<DecimalAttrs>): m.Children {
        return (
            <span>
                {displayable_float(vnode.attrs.value, vnode.attrs.number_of_fraction_digits, this.decimal_locale)}
            </span>
        )
    }
}

/** Display a decimal value with formatting as a percent as specified by the user settings. */
export class Percent extends MithrilTsxComponent<DecimalAttrs> {
    decimal_locale: string
    constructor() {
        super()
        this.decimal_locale = $s.identity.user.decimal_locale
    }

    view(vnode: m.Vnode<DecimalAttrs>): m.Children {
        return <span>{format_percentage(vnode.attrs.value, this.decimal_locale)}</span>
    }
}

interface CommentTemplateDropDownAttrs extends FieldSelectAttrs {
    selected_comment_template_artkey: string
    /** The data source for the drop down options. Fill eg with CommentTemplateDropDownData.comment_templates() */
    get_all_for_drop_down_response$: Observable<CommentTemplateResponse[]>
}

export class CommentTemplateDropDown extends MithrilTsxComponent<CommentTemplateDropDownAttrs> {
    comment_templates: CommentTemplateResponse[] = []

    oncreate(vnode: m.Vnode<CommentTemplateDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe(
            (comment_templates) => (this.comment_templates = comment_templates),
        )
    }

    view(vnode: m.Vnode<CommentTemplateDropDownAttrs>): m.Children {
        return (
            <MaybeObservable observed={vnode.attrs.get_all_for_drop_down_response$}>
                <FieldSelect
                    disabled={vnode.attrs.disabled}
                    model={vnode.attrs.model}
                    onchange={vnode.attrs.onchange}
                    options={this.comment_templates?.map((comment_template) => ({
                        label: comment_template.title,
                        value: `${comment_template.artkey}`,
                    }))}
                    placeholder="Select a template"
                    validation={vnode.attrs.validation}
                />
            </MaybeObservable>
        )
    }
}

interface MarginPercentageAttrs {
    value: string | null // Margin percentage as a fraction.
}

export class MarginPercentage extends MithrilTsxComponent<MarginPercentageAttrs> {
    val: GetMarginThresholdResponse | null = null
    low_margin_range = 8
    start_target_margin_range = 8

    oncreate(): void {
        AllMarginThresholds.get(DateTime.now()).subscribe({
            next: (value) => {
                this.low_margin_range = +(value.end_low_margin_range || '0')
                this.start_target_margin_range = +(value.start_target_margin_range || '0')
                m.redraw()
            },
        })
    }

    view(vnode: m.Vnode<MarginPercentageAttrs>): m.Children {
        const margin_percentage = vnode.attrs.value !== null ? +vnode.attrs.value * 100 : Infinity
        let color_class = 'analysis-mwah-color'
        if (margin_percentage < this.low_margin_range) {
            color_class = 'analysis-bad-color'
        } else if (margin_percentage > this.start_target_margin_range) {
            color_class = 'analysis-good-color'
        }

        return (
            <span className={color_class}>
                <Percent value={margin_percentage / 100} number_of_fraction_digits={1} />
            </span>
        )
    }
}

interface RadioOptionAttrs {
    className: string
    day: string
    title: string
    value: string
    onclick: (value: string) => void
}

export class RadioButtonOption extends MithrilTsxComponent<RadioOptionAttrs> {
    view(vnode: m.Vnode<RadioOptionAttrs>): m.Children {
        return (
            <button
                value={vnode.attrs.value}
                className={vnode.attrs.className}
                onclick={(value: InputEvent) => {
                    vnode.attrs.onclick((value.target as HTMLButtonElement).value)
                }}
            >
                {vnode.attrs.title}
            </button>
        )
    }
}

interface FavouriteStarAttrs {
    is_favourite: boolean
    onclick: (is_favourite: boolean) => unknown
}

export class FavouriteStar extends MithrilTsxComponent<FavouriteStarAttrs> {
    view(vnode: m.Vnode<FavouriteStarAttrs>): m.Children {
        return (
            <div className={'btn'}
                onclick={() => vnode.attrs.onclick(!vnode.attrs.is_favourite)}
                title={'Favourite'}
            >
                {vnode.attrs.is_favourite && <span className={'glyphicon glyphicon-star'} style={'color: gold'} />}
                {!vnode.attrs.is_favourite && (
                    <span className={'glyphicon glyphicon-star-empty'} style={'color: gray'} />
                )}
            </div>
        )
    }
}

interface RangedInputAttrs {
    title?: JSX.Element | string
    type: 'single' | 'double'
    open_end: boolean // whether the max-value is a do everything above the max value.
    min: number
    max: number
    step: number

    value_left: string
    oninput_left: (value: string) => unknown
    value_right?: string
    oninput_right?: (value: string) => unknown
}

/**
 * Ranged input component, renders a slider with:
 * - type = single => single value, aka a volume slider (volume as in audio).
 * - type = double => renders a range (min-max value) slider with two values.
 *
 * If the RangedInput has a value_left and a value_right (in the case of type = double).
 */
export class RangedInput extends MithrilTsxComponent<RangedInputAttrs> {
    oninput_left(ev: InputEvent, vnode: m.Vnode<RangedInputAttrs>): boolean {
        const new_value_left = (ev.target as HTMLInputElement).value
        if (
            vnode.attrs.type === 'double' &&
            +new_value_left >= +(vnode.attrs.value_right || 0) &&
            vnode.attrs.oninput_right
        ) {
            vnode.attrs.oninput_right(`${+new_value_left + 1}`)
        }
        if (vnode.attrs.oninput_left) {
            vnode.attrs.oninput_left(new_value_left)
        }
        return true
    }

    oninput_right(ev: InputEvent, vnode: m.Vnode<RangedInputAttrs>): boolean {
        const new_value_right = (ev.target as HTMLInputElement).value
        if (+new_value_right <= +vnode.attrs.value_left) {
            vnode.attrs.oninput_left(`${+new_value_right - 1}`)
            return true
        }
        if (vnode.attrs.oninput_right) {
            vnode.attrs.oninput_right(new_value_right)
        }
        return true
    }

    view(vnode: m.Vnode<RangedInputAttrs>): m.Children {
        return (
            <span className="c-ranged-input">
                <label>
                    {vnode.attrs.title && `${vnode.attrs.title}: `}
                    {vnode.attrs.type === 'single' && !vnode.attrs.open_end && <span>{vnode.attrs.value_left}</span>}
                    {vnode.attrs.type === 'single' && vnode.attrs.open_end && (
                        <span>
                            {+vnode.attrs.value_left === vnode.attrs.max
                                ? `>${vnode.attrs.value_left}`
                                : vnode.attrs.value_left}
                        </span>
                    )}
                    {vnode.attrs.type === 'double' && !vnode.attrs.open_end && (
                        <span>
                            {vnode.attrs.value_left} - {vnode.attrs.value_right}
                        </span>
                    )}
                    {vnode.attrs.type === 'double' && vnode.attrs.open_end && (
                        <span>
                            {+(vnode.attrs.value_right || '0') === vnode.attrs.max
                                ? `${vnode.attrs.value_left} - ∞`
                                : `${vnode.attrs.value_left} - ${vnode.attrs.value_right}`}
                        </span>
                    )}
                </label>
                <div>
                    <div className={'ranges'}>
                        <input
                            type={'range'}
                            className={vnode.attrs.type === 'double' ? 'first' : 'only'}
                            min={vnode.attrs.min}
                            max={vnode.attrs.max}
                            step={vnode.attrs.step}
                            value={vnode.attrs.value_left}
                            oninput={(ev: InputEvent) => this.oninput_left(ev, vnode)}
                        />
                        {vnode.attrs.type === 'double' && (
                            <input
                                type={'range'}
                                className={'second'}
                                min={vnode.attrs.min}
                                max={vnode.attrs.max}
                                step={vnode.attrs.step}
                                value={vnode.attrs.value_right}
                                oninput={(ev: InputEvent) => this.oninput_right(ev, vnode)}
                            />
                        )}
                    </div>
                </div>
            </span>
        )
    }
}

interface InlineEditableTextAttrs extends FieldTextAreaAttrs {
    oninput: (value: string) => unknown
    value: string
    onsave: () => unknown
    oncancel: (original_text: string) => unknown
}

export class InlineEditableText extends MithrilTsxComponent<InlineEditableTextAttrs> {

    is_editing = false
    popped_text = ''

    start_editing(vnode: m.Vnode<InlineEditableTextAttrs>) {
        this.is_editing = true
        this.popped_text = vnode.attrs.model[0][vnode.attrs.model[1]]
    }

    cancel_editing(vnode: m.Vnode<InlineEditableTextAttrs>) {
        this.is_editing = false
        vnode.attrs.oncancel(this.popped_text)
    }

    save_editing(vnode: m.Vnode<InlineEditableTextAttrs>) {
        this.is_editing = false
        vnode.attrs.onsave()
    }

    view(vnode: m.Vnode<InlineEditableTextAttrs>): m.Children {
        const children: m.Children = [
            !this.is_editing && <div className={'read-only-text'} onclick={() => this.start_editing(vnode)}>
                {vnode.attrs.model[0][vnode.attrs.model[1]]}
                <div className={'fas fa-edit'} style={'font-size: small; cursor: pointer;margin-left: 6px;'}/>
            </div>,
            this.is_editing && <div className={'edit-text'}>
                <FieldTextArea
                    model={vnode.attrs.model}
                    oninput={vnode.attrs.oninput}
                    rows={5}
                />
                <CancelButton onclick={() => this.cancel_editing(vnode)}/>
                <SaveButton onclick={() => this.save_editing(vnode)}/>
            </div>,
        ]

        return <div className={'c-inline-edit'}>{children}</div>
    }
}
