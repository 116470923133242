import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {watch} from '@bitstillery/common/lib/store'

import {PieChart} from '@/components/adminlte/pie_chart'
import {LeafletMap} from '@/components/adminlte/leaflet_map'
import {HorizontalBarChart} from '@/components/adminlte/horizontal_bar_chart'
import {DashboardApi, TopRelation} from '@/factserver_api/dashboard_api'
import {UserStatsModel} from '@/dashboard/figures_dashboard'

/**
 * Display three widgets:
 * - location of relations in a leaflet map.
 * - ratio of suppliers per client status.
 * - the top turnover relations.
 */
export class RelationsWidget extends MithrilTsxComponent<UserStatsModel> {
    static client_status_canvas_id = 'relation-widget-client-status'
    static relations_locations_id = 'relations-locations-map'
    static top_relations_canvas_id = 'top-relations-locations'

    // Used to tear down charts after loading api data
    // and the component is already gone.
    is_removing = false
    is_loading = false

    client_status_pie_chart: PieChart | null = null
    top_relations_bar_chart: HorizontalBarChart | null = null
    top_relations: TopRelation[] = []
    relations_locations_map: LeafletMap | null = null

    dashboard_api = new DashboardApi()

    now = DateTime.local()
    period_from = DateTime.local(this.now.year, this.now.month, 1, 0, 0, 0, 0)
    period_till = this.now.plus({days: 1})
    watchers = []

    oninit(vnode: m.Vnode<UserStatsModel>): void {
        this.watchers.push(watch(vnode.attrs.model, 'user_artkey', (user_artkey) => {
            this.fetch_data(user_artkey)
        }))
    }

    oncreate(vnode: m.Vnode<UserStatsModel>): void {
        this.is_removing = false

        this.client_status_pie_chart = new PieChart(RelationsWidget.client_status_canvas_id)
        this.client_status_pie_chart.labels([])
        this.client_status_pie_chart.legend_position('bottom')
        this.client_status_pie_chart.title('')
        this.client_status_pie_chart.onclick((value: number, label: string) => {
            window.open(`#!/crm/relations?manager=${vnode.attrs.model.user_artkey}&client_status=${label}`, '_blank')
        })

        this.top_relations_bar_chart = new HorizontalBarChart(RelationsWidget.top_relations_canvas_id, true, false)
        this.top_relations_bar_chart.labels([])
        this.top_relations_bar_chart.title('')

        this.relations_locations_map = new LeafletMap(RelationsWidget.relations_locations_id)
    }

    onremove(): void {
        this.is_removing = true
        this.client_status_pie_chart.chart.destroy()
        this.relations_locations_map.destroy()
        this.top_relations_bar_chart.chart.destroy()
    }

    fetch_data(user_artkey: number | null | undefined): void {
        if (!user_artkey) {
            return
        }

        this.is_loading = true
        this.dashboard_api
            .relations_figures({
                period_from: this.period_from.toISODate(),
                period_till: this.period_till.toISODate(),
                sales_manager_artkey: user_artkey,
            })
            .subscribe({
                next: (response) => {
                    if (this.is_removing) return

                    this.client_status_pie_chart?.labels(
                        response.client_statuses.map((client_status) => client_status.client_status),
                    )
                    this.client_status_pie_chart?.data(
                        response.client_statuses.map((client_status) => client_status.count),
                    )
                    this.top_relations = response.top_relations
                    this.top_relations_bar_chart?.data(
                        response.top_relations.slice(0, 7).map((relation) => relation.turnover),
                    )
                    this.top_relations_bar_chart?.labels(
                        response.top_relations.slice(0, 7).map((relation) => relation.name),
                    )
                    this.relations_locations_map?.add_markers(
                        response.locations.map((location) => {
                            return {
                                href: `#!/crm/relations/${location.artkey}/edit`,
                                name: location.name,
                                latitude: location.latitude,
                                longitude: location.longitude,
                                color: location.is_buyer ? '#3388ff' : '#F5D29B',
                            }
                        }),
                    )

                    this.top_relations_bar_chart?.title(['Relation Performance - confirmed & invoiced'])
                    this.client_status_pie_chart?.title('Relation Type')
                },
                complete: () => {
                    this.is_loading = false
                    m.redraw()
                },
            })
    }

    view() {
        return (
            <div className="c-widget-relations dashboard-widget">
                <div className="widget-title">
                    <span className="text">Relations</span>
                </div>
                <div className="widget-body">
                    <div className="chart-wrapper top-relations">
                        <canvas id={RelationsWidget.top_relations_canvas_id} />
                    </div>
                    <div className="charts-wrapper">
                        <div id={RelationsWidget.relations_locations_id} className="relation-map" />
                        <div className="chart-wrapper client-status">
                            <canvas id={RelationsWidget.client_status_canvas_id} />
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}
