/** llm:tested */
import m from 'mithril'

import {icon} from '@/components/icon'
import {copy} from '@/_utils'

interface LinkOptions {
    [key: string]: any
}

export const link = (url: string, content: m.Children, options: LinkOptions = {}) => {
    const args = copy({
        ...options,
        href: url,
    })

    return <m.route.Link {...args}>{content}</m.route.Link>
}

export const blank_link = (url: string, content: m.Children, options: LinkOptions = {}) => {
    const args = copy({
        ...options,
        href: `${url}`,
        target: '_blank',
    })

    return <m.route.Link {...args}>{content}</m.route.Link>
}

export const icon_link = (url: string, icon_id: string, options: LinkOptions = {}) => {
    const args = copy({
        ...options,
        href: url,
    })

    return <m.route.Link {...args}>{icon(icon_id)}</m.route.Link>
}

export const blank_icon_link = (url: string, icon_id: string, options: LinkOptions = {}) => {
    const args = copy({
        ...options,
        href: `${url}`,
        target: '_blank',
    })

    return <m.route.Link {...args}>{icon(icon_id)}</m.route.Link>
}
