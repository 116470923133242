import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {to_specs} from '@bitstillery/common/models/item'
import {Amount} from '@bitstillery/common/components'

import {Modal} from '@/components/modal/modal'
import {AddToSalesOrder} from '@/offer/components/add_to_sales_order'
import {$s} from '@/app'
import {Link} from '@/components/discover'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {DefaultButton} from '@/components/buttons'
import {Percent} from '@/components/html_components'
import {
    GetFastSalesOrderWithItemsResponse,
    GetHistoricalItemsResponse,
    HistoricalItemType,
} from '@/factserver_api/sales_api'

export interface SalesOrderHistoricalItemsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
}

/**
 * Shows the historical items in a list.
 */
export class SalesOrderHistoricalItems extends MithrilTsxComponent<SalesOrderHistoricalItemsAttrs> {
    historical_items_fetcher = new PagedCollectionFetcher<GetHistoricalItemsResponse>(
        'sales.historical_items.get',
        'created_on',
        null,
        50,
        false,
    )

    show_add_to_sales_order = false
    add_to_sales_order_row: GetHistoricalItemsResponse | null = null

    oncreate(vnode: m.Vnode<SalesOrderHistoricalItemsAttrs>): void {
        this.historical_items_fetcher.filters['sales_order_artkey'] = vnode.attrs.sales_order.artkey
    }

    view(): m.Children {
        return (
            <span>
                {this.show_add_to_sales_order && this.add_to_sales_order_row && (
                    <Modal title={'Add to sales order'} onclose={() => (this.show_add_to_sales_order = false)}>
                        <AddToSalesOrder
                            on_added_item={() => (this.show_add_to_sales_order = false)}
                            supplier_artkey={this.add_to_sales_order_row.relation_artkey}
                            supplier_currency={this.add_to_sales_order_row.currency}
                            offer_item={{
                                case_artkey: this.add_to_sales_order_row.case_artkey,
                                product_name: this.add_to_sales_order_row.product_name,
                            }}
                        />
                    </Modal>
                )}

                <CollectionTable<GetHistoricalItemsResponse, void> collection_fetcher={this.historical_items_fetcher}>
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => 'Type'}
                        sort_name={'type'}
                        data_field={(row: GetHistoricalItemsResponse) => {
                            const source = row.source
                            if (source === HistoricalItemType.SALES_ORDER_TBO_ITEM) {
                                return (
                                    <span>
                                        <span className={'glyphicon glyphicon-shopping-cart'} /> TBO item
                                    </span>
                                )
                            } else if (source === HistoricalItemType.SALES_ORDER_ITEM) {
                                return (
                                    <span>
                                        <span className={'glyphicon glyphicon-screenshot'} /> Sales item
                                    </span>
                                )
                            } else if (source === HistoricalItemType.SALES_ORDER_CREDIT_ITEM) {
                                return (
                                    <span>
                                        <span className={'glyphicon glyphicon-minus'} /> Credit item
                                    </span>
                                )
                            } else if (source === HistoricalItemType.SALES_ORDER_ADDITIONAL) {
                                return (
                                    <span>
                                        <span className={'glyphicon glyphicon-plus-sign'} /> Additional
                                    </span>
                                )
                            } else if (source === HistoricalItemType.SALES_ORDER_PORTAL_ITEM) {
                                return (
                                    <span>
                                        <span className={'fas fa-clipboard'} /> Portal
                                    </span>
                                )
                            }
                        }}
                    />
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => 'Cases'}
                        td_class_name={'number'}
                        data_field={(row: GetHistoricalItemsResponse) => row.number_of_units}
                    />
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => 'Price / cs'}
                        td_class_name={'price'}
                        data_field={(row: GetHistoricalItemsResponse) => {
                            if (
                                row.source === HistoricalItemType.SALES_ORDER_ADDITIONAL &&
                                row.description.includes('PERCENTAGE')
                            ) {
                                return <Percent value={row.price_per_unit / 100} />
                            }
                            return <Amount amount={row.price_per_unit} currency={row.currency} />
                        }}
                    />
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => 'Description'}
                        data_field={(row: GetHistoricalItemsResponse) => row.description}
                    />
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => 'Product'}
                        sort_name={'product_name'}
                        data_field={(row: GetHistoricalItemsResponse) => {
                            if (row.product_name) {
                                return (
                                    <Link
                                        href={`/offer/relation-dashboard/?relation_artkey=${row.relation_artkey}&product_query=${row.product_name}`}
                                    >
                                        view '{row.product_name}' on price list
                                    </Link>
                                )
                            }
                        }}
                    />
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => 'Specs'}
                        data_field={(row: GetHistoricalItemsResponse) => {
                            if (row.alcohol_percentage !== undefined && row.volume && row.refill_status) {
                                return (
                                    <span>
                                        {to_specs(
                                            {
                                                alcohol_percentage: `${row.alcohol_percentage}`,
                                                volume: `${row.volume}`,
                                                refill_status: row.refill_status,
                                            },
                                            $s.identity.user.decimal_locale,
                                        )}
                                    </span>
                                )
                            }
                        }}
                    />
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => 'On'}
                        sort_name={'created_on'}
                        data_field={(row: GetHistoricalItemsResponse) => format_iso_to_date(row.created_on)}
                    />
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => 'By'}
                        data_field={(row: GetHistoricalItemsResponse) => row.created_by}
                    />
                    <CollectionTableColumn<GetHistoricalItemsResponse>
                        header_title={() => ''}
                        data_field={(row: GetHistoricalItemsResponse) => {
                            if ([HistoricalItemType.SALES_ORDER_ITEM].includes(row.source)) {
                                return (
                                    <DefaultButton
                                        icon_class={'glyphicon glyphicon-screenshot'}
                                        onclick={() => {
                                            this.show_add_to_sales_order = true
                                            this.add_to_sales_order_row = row
                                        }}
                                    />
                                )
                            }
                        }}
                    />
                </CollectionTable>
            </span>
        )
    }
}
