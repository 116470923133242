/** llm:tested */
import m from 'mithril'
import {find, map} from 'prelude-ls'
import {isFunction} from '@bitstillery/common/utils'
import {countries, european_union_countries, required_region_country_codes} from '@bitstillery/common/lib/countries'
import {excise_countries} from '@bitstillery/common/excise'
import {format_money} from '@bitstillery/common/lib/format'
import {proxy} from '@bitstillery/common/lib/proxy'
import {
    FieldCheckbox,
    FieldMoney,
    FieldSelect,
    FieldText,
    FieldVatId,
    Spinner,
} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import * as validators from '@bitstillery/common/lib/validation'
import {
    conditional,
    invalid_fields,
    invalid_fields_format,
    password,
    required,
    validation,
    vat_id,
    website,
} from '@bitstillery/common/lib/validation'
import {current_account_slug} from '@bitstillery/common/account/account'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import inputs from '@/components/inputs'
import {PercentInput} from '@/components/decimal_input'
import {languages} from '@/components/languages'
import {link} from '@/components/link'
import {Table} from '@/components/table2'
import {Lead} from '@/crm/leads/models'
import {
    joinMaybes,
    formatDateTime,
} from '@/_utils'
import {AddLogEntry, LogEntries} from '@/components/logbook/entries'
import {RelationApi} from '@/factserver_api/relation_api'
import {CountriesSelect} from '@/components/html_components'
import {AttachmentList, AttachmentHelper, UploadAttachment} from '@/components/attachment_list'
import {AttachmentType} from '@/factserver_api/attachment_api'
import {UserDropDown} from '@/components/users'
import {IncotermsDropDown} from '@/components/incoterms'
import {IncotermsDropDownData} from '@/factserver_api/incoterms_api'
import {HiddenInformation, HiddenRelationHelper} from '@/purchase_orders/view_purchase_order/hidden_information'
import {ContactPerson, LedgerType} from '@/models/data'
import {Supplier} from '@/models/suppliers'
import {$m, $s} from '@/app'

// Constants
const price_quantity_preference = [
    ['bottle', 'Bottle'],
    ['case', 'Case'],
]

const help = {
    buyer_only: 'Only for relations that are buyer',
    has_sales_orders: 'Fixed because relation already has sales orders',
    supplier_only: 'Only for relations that are supplier',
}

interface SupplierEditData {
    amount_spent: number
    $f: {
        tokens_spent: number
        promotion_selected: boolean
        penalty?: number
    }
    msi_supplier_artkey: string | null
    tab: string
    threshold: number
    tokens: {
        draft: number
        redeemable: number
    }
    turnover: {
        draft: number
        redeemable: number
        penalty: number | null
    }
}

export class CrmRelationsUpsert {
    supplier = window.prop<Supplier | null>(null)
    user = window.prop<any[]>([])
    success_message = window.prop<string>('')
    purchase_ledgers = window.prop<any[]>([])
    sales_ledgers = window.prop<any[]>([])
    payment_terms = window.prop<any[]>([])
    price_list_frequency_select = window.prop<string>('')
    price_list_frequency_amount = window.prop<number>(1)
    price_list_frequency_unit = window.prop<string>('days')

    sales_promotion_november_promotion_active = window.prop<boolean>(false)
    sales_promotion_scooter_giveaway_active = window.prop<boolean>(false)

    contact_persons = window.prop<any[] | null>(null)
    warehouses = window.prop<any[]>([])
    primary_warehouse_name = window.prop<string | null>(null)

    create = window.prop<boolean>(false)
    create_from_lead = window.prop<boolean>(false)
    create_contact_person = window.prop<boolean>(false)

    hide_from_pricelist_for_countries_selected = window.prop<string[]>([])
    hide_from_pricelist_for_suppliers_selected = window.prop<string[]>([])

    contact_person = window.prop<ContactPerson>(new ContactPerson())

    has_portal_access = window.prop<boolean>(false)
    enable_portal_access = window.prop<boolean>(false)
    password = window.prop<string>('')
    repeat_password = window.prop<string>('')

    private _custom_password: boolean = false
    private _generate_password: boolean = false
    lead_artkey: string = ''
    attachment_helper: AttachmentHelper | null = null
    relation_api: RelationApi
    regions = window.prop<any[]>([])
    country_to_region$ = null
    region_is_required = window.prop<boolean>(false)
    data: SupplierEditData

    $v: Record<string, any>

    constructor() {
        window.supplier = this.supplier

        $m.data.ledgers.query_all(this.purchase_ledgers, LedgerType.PURCHASING)
        $m.data.ledgers.query_all(this.sales_ledgers, LedgerType.SALES)
        $m.data.payment_term.query_all(this.payment_terms)

        const pathname = m.parsePathname(m.route.get())
        const params = Object.fromEntries(new URLSearchParams(pathname.params))

        this.relation_api = new RelationApi()

        this.data = proxy({
            amount_spent: 0,
            $f: {
                tokens_spent: 0,
                promotion_selected: false,
            },
            msi_supplier_artkey: null,
            tab: params.tab || 'relation',
            threshold: 0,
            tokens: {
                draft: 0,
                redeemable: 0,
            },
            turnover: {
                draft: 0,
                redeemable: 0,
                penalty: null,
            },
        })

        this.loadSupplier()

        this.$v = {
            company_type: validation([this.supplier, 'company_type'], required()),
            country_code: validation([this.supplier, 'country_code'], required()),
            client_status: validation([this.supplier, 'client_status'], required()),
            city: validation([this.supplier, 'city'], required()),
            currency: validation([this.supplier, 'currency'], required()),
            customs_status: validation([this.supplier, 'customs_status'], required()),
            price_list_frequency: validation(
                [this, 'price_list_frequency_select'],
                conditional(() => this.supplier()?.is_supplier(), required()),
            ),
            portal_customs_visibility: validation([this.supplier, 'portal_customs_visibility'], required()),
            purchase_manager_artkey: validation([this.supplier, 'purchase_manager.artkey'], required()),
            price_list_manager_artkey: validation([this.supplier, 'price_list_manager.artkey'], required()),
            price_preference: validation([this.supplier, 'price_preference'], required()),
            purchase_ledger_artkey: validation([this.supplier, 'purchase_ledger_artkey'], required()),
            purchase_payment_term_artkey: validation(
                [this.supplier, 'purchase_payment_term_artkey'],
                conditional(() => this.supplier()?.is_supplier(), required()),
            ),
            region_code: validation(
                [this.supplier, 'region_code'],
                conditional(() => this.region_is_required(), required()),
            ),
            relation_name: validation([this.supplier, 'name'], required()),
            sales_ledger_artkey: validation([this.supplier, 'sales_ledger_artkey'], required()),
            sales_manager_artkey: validation([this.supplier, 'sales_manager.artkey'], required()),
            sales_payment_term_artkey: validation(
                [this.supplier, 'sales_payment_term_artkey'],
                conditional(() => this.supplier()?.is_buyer(), required()),
            ),
            street_address: validation([this.supplier, 'street_address'], required()),
            zip_code: validation([this.supplier, 'zip_code'], required()),
            contact_emailaddress: validation(
                [this.contact_person, 'emailaddress'],
                conditional(() => this.create_contact_person() || this.create_from_lead(), required()),
            ),
            contact_first_name: validation(
                [this.contact_person, 'first_name'],
                conditional(() => this.create_contact_person() || this.create_from_lead(), required()),
            ),
            contact_family_name: validation(
                [this.contact_person, 'family_name'],
                conditional(() => this.create_contact_person() || this.create_from_lead(), required()),
            ),
            contact_password: validation(
                [this, 'password'],
                conditional(
                    () => (this.create_contact_person() || this.create_from_lead()) && (this.create() && this.custom_password()),
                    password(),
                ),
            ),
            contact_password_verify: validation([this, 'repeat_password'], {
                label: '*',
                message: 'Passwords do not match',
                validate: () => {
                    this.label = '**'
                    if (this.password() !== this.repeat_password()) {
                        return this
                    }
                    return false
                },
            }),
            url: validation([this.supplier, 'url'], conditional(() => this.supplier()?.url(), website())),
            vat_id: validation(
                [this.supplier, 'vat_id'],
                conditional(
                    () => {
                        const country_code = this.supplier()?.country_code()
                        return european_union_countries.includes(country_code)
                    },
                    validators.and([required(), vat_id([this.supplier, 'country_code'])]),
                ),
            ),
        }
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async loadSupplier() {
        this.lead_artkey = ''
        const supplier_artkey = m.route.param('artkey')

        if (supplier_artkey) {
            await this.query_supplier(supplier_artkey)
            await this.load_msi_supplier(supplier_artkey)
            this.attachment_helper = new AttachmentHelper(supplier_artkey, AttachmentType.RELATION)
        } else {
            // We are creating a new one
            this.attachment_helper = null
            this.create(true)
            this.supplier(new Supplier())
            this.lead_artkey = m.route.param('lead_artkey')
            if (this.lead_artkey) {
                // We are converting a lead; make sure to wait until
                // the lead data is loaded before updating regions.
                // The supplier country_code needs to load first.
                await this.query_lead(this.lead_artkey)
                this.create_from_lead(true)
            }

            this.update_regions(this.supplier()?.country_code() ? this.supplier()?.country_code() : '')
        }
    }

    update_regions(country_code: string) {
        $m.data.region_drop_down_data.for_country_code(country_code).subscribe((regions: any) => {
            this.regions(
                regions.map((region: any) => ({
                    label: `${region.name} (${region.code})`,
                    value: region.code,
                })),
            )
            this.region_is_required(required_region_country_codes.includes(this.supplier()?.country_code()))
            if (!this.regions().find((region: any) => region.value === this.supplier()?.region_code())) {
                this.supplier()?.region_code('')
            }
            m.redraw()
        })
    }

    shallow_copy_while_preserving_functions(from: any, to: any) {
        // Copies the 'value of function' in the from object to the corresponding functions of the to object by invoking.
        // This is required because the render tree has references to the functions declared in the constructor.
        // All except 'make_setting_prop', it is not copyable since it needs args.
        Object.entries(from)
            .filter(attr => !['make_setting_prop'].includes(attr[0]))
            .map(attr => {
                const attr_key = attr[0]
                const are_functions = to[attr_key] && from[attr_key] && isFunction(to[attr_key]) && isFunction(from[attr_key])
                if (are_functions) {
                    to[attr_key](from[attr_key]())
                }
            })
    }

    query_lead(artkey: string): Promise<void> {
        return new Promise((resolve) => {
            api.callAndThen('crm.leads.get', {artkey}, {
                success: (resp: any) => {
                    const lead = new Lead(resp.result)
                    this.shallow_copy_while_preserving_functions(lead, this.supplier())
                    this.supplier()?.artkey(null)
                    if (lead.manager()) {
                        this.supplier()?.purchase_manager()?.artkey(lead.manager()?.artkey())
                        this.supplier()?.sales_manager()?.artkey(lead.manager()?.artkey())
                    }
                    if (lead.contact_persons()) {
                        this.shallow_copy_while_preserving_functions(lead.contact_persons()[0], this.contact_person())
                        this.contact_person()?.artkey(null)
                    }
                    resolve()
                },
            })
        })
    }

    query_supplier(artkey: string): Promise<void> {
        return new Promise((resolve) => {
            this.supplier(null)
            api.callAndThen(
                'suppliers.get_supplier',
                {artkey},
                {
                    success: (resp: any) => {
                        const active_promotion = resp.result.active_promotion
                        this.supplier(new Supplier(resp.result))
                        if (this.supplier()?.operates_online() === null) {
                            this.supplier()?.operates_online(false)
                        }

                        if (active_promotion) {
                            this.data.amount_spent = Number(resp.result.amount_spent)
                            this.data.threshold = active_promotion.additional_json_data.threshold
                            this.data.$f.penalty = resp.result.penalty
                            Object.assign(this.data.$f, {
                                promotion_selected: resp.result.promotion_selected,
                                tokens_spent: this.data.amount_spent / this.data.threshold,
                            })

                            Object.assign(this.data.turnover, {
                                draft: resp.result.promotion_turnover_draft,
                                redeemable: resp.result.promotion_turnover_redeemable,
                            })

                            Object.assign(this.data.tokens, {
                                draft: Math.floor(resp.result.promotion_turnover_draft / this.data.threshold),
                                redeemable: Math.floor(resp.result.promotion_turnover_redeemable / this.data.threshold),
                            })
                        }

                        // construct a list with the selected countries as country_codes
                        this.hide_from_pricelist_for_countries_selected(
                            this.supplier()?.hide_from_pricelist_for_countries()?.map(
                                (excluded: any) => excluded.country_code,
                            ) || [],
                        )
                        this.hide_from_pricelist_for_suppliers_selected(
                            this.supplier()?.hide_from_pricelist_for_suppliers()?.map(
                                (excluded: any) => '' + excluded.artkey,
                            ) || [],
                        )

                        // Set the price_list_frequency_x input field values.
                        const price_list_frequency = this.supplier()?.price_list_frequency()
                        if (price_list_frequency && price_list_frequency in Object.keys($m.data.price_list_frequencies)) {
                            // If the frequency occurs in the default frequency set, use it.
                            this.price_list_frequency_select(price_list_frequency)
                        } else if (price_list_frequency) {
                            // Otherwise, use the custom fields.
                            this.price_list_frequency_select('other')
                            const [amount, unit] = price_list_frequency.split(' ')
                            this.price_list_frequency_amount(amount)
                            this.price_list_frequency_unit(unit)
                        }

                        this.update_regions(this.supplier()?.country_code() || '')
                        // Fetch warehouses after we have the supplier.
                        api.call2('suppliers.get_all_supplier_warehouses', {
                            supplier_artkey: this.supplier()?.artkey(),
                        }, this.set_warehouses.bind(this))
                        resolve()
                    },
                    failure: () => {
                        m.route.set('/crm/relations')
                        return {}
                    },
                },
            )

            const data = {supplier_artkey: artkey}
            api.callAndThen('contact_persons.get_all', data, {
                success: (resp: any) => this.contact_persons(resp.result),
            })
        })
    }

    valid_password() {
        return this.password() === this.repeat_password()
    }

    warehouse_names() {
        return map(w => w.name, this.warehouses())
    }

    set_warehouses(resp: any) {
        this.warehouses(resp.result)
        const primary_warehouse = find(w => w.artkey === this.supplier()?.primary_warehouse_artkey(), this.warehouses())
        if (primary_warehouse) {
            this.primary_warehouse_name(primary_warehouse.name)
        }
    }

    save() {
        this.complete_supplier_url()
        if (this.create_contact_person()) {
            (this.password() && this.valid_password()) || this.generate_password()
        }

        // Determine the price_list_frequency value from the price_list_frequency_x
        // input field values.
        let price_list_frequency: string | null = null
        if (this.price_list_frequency_select() === '') {
            price_list_frequency = null
        } else if (this.price_list_frequency_select() === 'other') {
            price_list_frequency = [this.price_list_frequency_amount(), this.price_list_frequency_unit()].join(' ')
        } else {
            price_list_frequency = this.price_list_frequency_select()
        }

        const data = {
            amount_spent: this.data.$f.tokens_spent * this.data.threshold,
            penalty: this.data.$f.penalty,
            artkey: this.supplier()?.artkey(),
            name: this.supplier()?.name(),
            emailaddress: this.supplier()?.emailaddress(),
            telephone_number: this.supplier()?.telephone_number(),
            url: this.supplier()?.url(),
            street_address: this.supplier()?.street_address(),
            zip_code: this.supplier()?.zip_code(),
            city: this.supplier()?.city(),
            country_code: this.supplier()?.country_code(),
            region_code: this.supplier()?.region_code(),
            company_type: this.supplier()?.company_type(),
            client_status: this.supplier()?.client_status(),
            operates_online: this.supplier()?.operates_online(),
            is_supplier: this.supplier()?.is_supplier(),
            is_buyer: this.supplier()?.is_buyer(),
            incoterm: this.supplier()?.incoterm(),
            should_receive_offer_mails: this.supplier()?.should_receive_offer_mails(),
            should_receive_purchase_mails: this.supplier()?.should_receive_purchase_mails(),
            purchase_ledger_artkey: this.supplier()?.purchase_ledger_artkey(),
            sales_ledger_artkey: this.supplier()?.sales_ledger_artkey(),
            low_tariff_sales_ledger_artkey: this.supplier()?.low_tariff_sales_ledger_artkey(),
            purchase_manager_artkey: +this.supplier()?.purchase_manager()?.artkey(),
            price_list_manager_artkey: +this.supplier()?.price_list_manager()?.artkey(),
            sales_manager_artkey: +this.supplier()?.sales_manager()?.artkey(),
            vat_id: this.supplier()?.vat_id(),
            excise_id: this.supplier()?.excise_id(),
            memo: this.supplier()?.memo(),
            linkedin: this.supplier()?.linkedin(),
            facebook: this.supplier()?.facebook(),
            instagram: this.supplier()?.instagram(),
            twitter: this.supplier()?.twitter(),
            sales_payment_term_artkey: +this.supplier()?.sales_payment_term_artkey(),
            purchase_payment_term_artkey: +this.supplier()?.purchase_payment_term_artkey(),
            price_list_frequency,
            portal_level: this.supplier()?.portal_level(),
            portal_customs_visibility: this.supplier()?.portal_customs_visibility(),
            mix_customs_on_pricelist: this.supplier()?.mix_customs_on_pricelist(),
            portal_account_expiry: this.supplier()?.portal_account_expiry(),
            currency: this.supplier()?.currency(),
            customs_status: this.supplier()?.customs_status(),
            transport_costs_payment_method: this.supplier()?.transport_costs_payment_method(),
            transport_costs_per_case: this.supplier()?.transport_costs_per_case(),
            include_ukds_in_price: this.supplier()?.include_ukds_in_price(),
            include_excise_in_price: this.supplier()?.include_excise_in_price(),
            show_excise_in_portal: this.supplier()?.show_excise_in_portal(),
            minimum_order_amount: this.supplier()?.minimum_order_amount(),
            price_markup_percentage: this.supplier()?.price_markup_percentage(),
            primary_warehouse_artkey: this.primary_warehouse() ? this.primary_warehouse()?.artkey : null,
            use_incoterm_on_pricelist: this.supplier()?.use_incoterm_on_pricelist(),
            price_preference: this.supplier()?.price_preference()?.toLowerCase(),
            promotion_selected: this.data.$f.promotion_selected,
            emailaddress_financial_documents: this.supplier()?.emailaddress_financial_documents(),
            emailaddress_rfp_documents: this.supplier()?.emailaddress_rfp_documents(),
            lead_artkey: this.lead_artkey,
            hide_from_pricelist_for_countries: this.hide_from_pricelist_for_countries_selected(),
            hide_from_pricelist_for_suppliers: this.hide_from_pricelist_for_suppliers_selected().map(artkey => +artkey),
        }

        if (this.create_from_lead() || this.create_contact_person()) {
            data.contact_person = {
                artkey: this.contact_person()?.artkey(),
                first_name: this.contact_person()?.first_name(),
                family_name: this.contact_person()?.family_name(),
                telephone_number: this.contact_person()?.telephone_number(),
                mobile_telephone_number: this.contact_person()?.mobile_telephone_number(),
                emailaddress: this.contact_person()?.emailaddress(),
                birthdate: this.contact_person()?.birthdate() ? this.contact_person()?.birthdate()?.split('T')[0] : null,
                position: this.contact_person()?.position(),
                salutation: this.contact_person()?.salutation(),
                language: this.contact_person()?.language(),
                enable_portal_access: this.enable_portal_access(),
                should_receive_mails: this.contact_person()?.should_receive_mails(),
                should_receive_purchase_enquiries: this.contact_person()?.should_receive_purchase_enquiries(),
                add_to_hello_dialog: this.contact_person()?.add_to_hello_dialog(),
                password: this.password(),
                generate_password: this.generate_password(),
            }
        }

        api.call('suppliers.create_or_update_supplier', data, this.handle_save)
    }

    handle_save = (result: any) => {
        if (!result.success) {
            notifier.notify(result.message, 'danger')
        } else {
            if (this.create()) {
                notifier.notify(`Successfully created new relation ${this.supplier()?.name()}.`, 'success')
                m.route.set('/crm/relations')
            } else {
                notifier.notify('Successfully updated relation.', 'success')
            }
            $m.common.observable.broadcast('suppliers_updated', '')
        }
    }

    complete_supplier_url() {
        if (this.supplier()?.url() && !this.supplier()?.url()?.match(/^https?:/)) {
            this.supplier()?.url('http://' + this.supplier()?.url())
        }
    }

    primary_warehouse() {
        return find(w => w.name === this.primary_warehouse_name(), this.warehouses())
    }

    base_url() {
        return `/crm/relations/${this.supplier()?.artkey()}`
    }

    contact_person_edit_url(contact_person: any) {
        return `${this.base_url()}/contact-persons/${contact_person.artkey}/edit`
    }

    contact_person_create_url() {
        if (this.create_from_lead() || this.create()) {
            this.create_contact_person(true)
        } else {
            m.route.set(`${this.base_url()}/contact-persons/create`)
        }
    }

    warehouse_edit_url(warehouse: any) {
        return `${this.base_url()}/edit/warehouses/${warehouse.artkey}/edit`
    }

    warehouse_select_url() {
        return `${this.base_url()}/edit/warehouses/select`
    }

    clear_cache() {
        api.callAndThen(
            'suppliers.clear_portal_cache_for_supplier',
            {
                supplier_artkey: this.supplier()?.artkey(),
            },
            {
                success: () => {
                    notifier.notify(
                        `Portal cache cleared for ${this.supplier()?.name()}. Price list generation takes about 10 seconds.`,
                        'info',
                    )
                },
            },
        )
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    setTab(tabName: string) {
        this.data.tab = tabName
        m.route.set(`${this.base_url()}/edit?tab=${tabName}`)
    }

    load_msi_supplier(supplier_artkey: string) {
        if (current_account_slug() === 'msi') {
            return
        }
        this.relation_api.load_alternative_supplier(supplier_artkey).subscribe({
            next: (response: any) => {
                this.data.msi_supplier_artkey = response.alternate_relation_artkey
            },
        })
    }

    import_price_lists_from_msi(import_from: any) {
        this.relation_api.import_price_lists(import_from, this.data.msi_supplier_artkey).subscribe({
            next: (response: any) => {
                notifier.notify(`Transferred ${response.rowcount} price lists from MSI.`, 'info')
                m.redraw()
            },
        })
    }

    generate_password(value?: boolean) {
        if (value !== undefined) {
            this._generate_password = value
            if (value && this._custom_password) {
                this._custom_password = false
            }
        }
        return this._generate_password
    }

    custom_password(value?: boolean) {
        if (value !== undefined) {
            this._custom_password = value
            if (value && this._generate_password) {
                this._generate_password = false
            } else if (!value && this.create()) {
                this._generate_password = true
            }
        }
        return this._custom_password
    }

    view() {
        if (this.supplier() === null) {
            return <Spinner />
        }

        const invalid = invalid_fields(this.$v)
        const turnover = this.data.turnover
        const tokens = this.data.tokens

        // Left-hand column for creating/editing the relation.
        const supplier_name_disabled = this.supplier()?.has_sales_orders() || this.supplier()?.has_purchase_orders()
        let address_hint = ''
        if (this.supplier()?.longitude_latitude_checked_on() !== null && this.supplier()?.longitude() === null) {
            address_hint = 'address not verified'
        } else if (this.supplier()?.longitude_latitude_checked_on() === null && this.supplier()?.longitude() === null) {
            address_hint = 'address is not yet verified'
        } else if (this.supplier()?.longitude_latitude_checked_on() !== null && this.supplier()?.longitude() !== null) {
            address_hint = 'address verified and found'
        }

        return (
            <div class="c-crm-relations-edit view">
                <div class="btn-toolbar">
                    <button
                        class="btn btn-default"
                        type="button"
                        onclick={() => m.route.set('/crm/relations')}
                    >
                        <span class="glyphicon glyphicon-arrow-left"></span>
                        {' Back to list'}
                    </button>

                    {this.supplier() && this.supplier()?.artkey() && [
                        <button
                            class="btn btn-danger"
                            type="button"
                            onclick={() => m.route.set(`/crm/relations/${this.supplier()?.artkey()}/deactivate`)}
                        >
                            <span class="glyphicon glyphicon-trash"></span>
                            {' Deactivate Relation'}
                        </button>,
                        <button
                            class="btn btn-warning"
                            type="button"
                            onclick={() => this.clear_cache()}
                        >
                            <span class="glyphicon glyphicon-flash"></span>
                            {' Clear cache + Portal price list'}
                        </button>,
                    ]}
                </div>

                <div class="c-tabs">
                    <ul class="nav nav-tabs">
                        <li class={classes('nav-link', {active: this.data.tab === 'relation'})}
                            onclick={() => this.setTab('relation')}>
                            <a>Relation</a>
                        </li>
                        <li class={classes('nav-link', {
                            active: this.data.tab === 'contacts',
                            disabled: this.create(),
                        })}
                        onclick={() => !this.create() && this.setTab('contacts')}>
                            <a>Contacts</a>
                        </li>
                        <li class={classes('nav-link', {
                            active: this.data.tab === 'hide_information',
                            disabled: this.create(),
                        })}
                        onclick={() => !this.create() && this.setTab('hide_information')}>
                            <a>Visibility</a>
                        </li>
                        <li class={classes('nav-link', {
                            active: this.data.tab === 'destinations',
                            disabled: this.create(),
                        })}
                        onclick={() => !this.create() && this.setTab('destinations')}>
                            <a>Destinations</a>
                        </li>
                        <li class={classes('nav-link', {
                            active: this.data.tab === 'logbook',
                            disabled: this.create(),
                        })}
                        onclick={() => !this.create() && this.setTab('logbook')}>
                            <a>Logbook</a>
                        </li>
                        <li class={classes('nav-link', {
                            active: this.data.tab === 'attachments',
                            disabled: this.create(),
                        })}
                        onclick={() => !this.create() && this.setTab('attachments')}>
                            <a>Attachments</a>
                        </li>
                    </ul>

                    {/* Relation Tab */}
                    <div class={classes('c-tab', {active: this.data.tab === 'relation'})}>
                        <form class="flex-form" onsubmit={(e) => e.preventDefault()}>
                            <div class="fieldset-group">
                                <div class="fieldset">
                                    <div class="fieldset-label">General Information</div>
                                    {!this.create() && inputs.text(this.supplier()?.relation_nr, {
                                        disabled: true,
                                        label: 'Relation ID',
                                        help: 'A unique immutable number that identifies this relation',
                                    })}

                                    {inputs.text(this.supplier()?.name, {
                                        placeholder: 'Relation name',
                                        autocomplete: 'off',
                                        disabled: !$s.identity.user.is_superuser && supplier_name_disabled,
                                        label: 'Relation name',
                                        help: $s.identity.user.is_superuser ? '(!) This field would normally be disabled; proceed with caution' : 'Becomes unchangeable after the first sales/purchase order has been made',
                                        validation: this.$v.relation_name,
                                    })}

                                    <FieldSelect
                                        label="Company type"
                                        model={[this.supplier(), 'company_type']}
                                        options={$m.data.company_types.map((company) => ({
                                            label: company,
                                            value: company,
                                        }))}
                                        placeholder="Select Company type..."
                                        validation={this.$v.company_type}
                                    />

                                    <FieldSelect
                                        label="Client status"
                                        model={[this.supplier(), 'client_status']}
                                        options={$m.data.client_statuses.map((status) => ({
                                            label: status,
                                            value: status,
                                        }))}
                                        placeholder="Select Client status..."
                                        validation={this.$v.client_status}
                                    />

                                    <div class="field-group">
                                        <UserDropDown
                                            label="Manager (purchase)"
                                            model={[this.supplier()?.purchase_manager(), 'artkey']}
                                            validation={this.$v.purchase_manager_artkey}
                                        />
                                    </div>

                                    <div class="field-group">
                                        <UserDropDown
                                            label="Manager (sales)"
                                            model={[this.supplier()?.sales_manager(), 'artkey']}
                                            validation={this.$v.sales_manager_artkey}
                                        />
                                    </div>

                                    <div class="field-group">
                                        <UserDropDown
                                            label="Manager (price list)"
                                            model={[this.supplier()?.price_list_manager(), 'artkey']}
                                            validation={this.$v.price_list_manager_artkey}
                                        />
                                    </div>

                                    <CountriesSelect
                                        placeholder="Select a country..."
                                        label="Country"
                                        model={[this.supplier(), 'country_code']}
                                        onchange={(value) => this.update_regions(value)}
                                        validation={this.$v.country_code}
                                    />

                                    <FieldVatId
                                        country_code={this.supplier()?.country_code()}
                                        disabled={!european_union_countries.includes(this.supplier()?.country_code())}
                                        model={[this.supplier, 'vat_id']}
                                        validation={this.$v.vat_id}
                                    />

                                    <FieldSelect
                                        help="Currency to trade in"
                                        label="Currency"
                                        model={[this.supplier(), 'currency']}
                                        options={$s.currencies.all.map((currency) => ({
                                            label: currency,
                                            value: currency,
                                        }))}
                                        placeholder="Select Currency..."
                                        validation={this.$v.currency}
                                    />

                                    {inputs.textarea(this.supplier()?.memo, {
                                        label: 'Relation memo',
                                    })}
                                </div>

                                <div class="fieldset">
                                    <div class="fieldset-label">Address & Media</div>

                                    <FieldSelect
                                        disabled={!this.regions() || this.regions().length === 0}
                                        help={!this.regions() || this.regions().length === 0
                                            ? 'No regions available for the selected country'
                                            : undefined}
                                        label="Region"
                                        model={[this.supplier(), 'region_code']}
                                        options={this.regions()}
                                        placeholder="Select a region..."
                                        validation={this.$v.region_code}
                                    />

                                    {inputs.text(this.supplier()?.city, {
                                        help: address_hint,
                                        label: 'City',
                                        validation: this.$v.city,
                                    })}

                                    <div class="field-group">
                                        <div class="field-group">
                                            {inputs.text(this.supplier()?.street_address, {
                                                label: 'Address',
                                                validation: this.$v.street_address,
                                            })}

                                            {inputs.text(this.supplier()?.zip_code, {
                                                label: 'Zip code',
                                                validation: this.$v.zip_code,
                                            })}
                                        </div>
                                    </div>

                                    {inputs.text(this.supplier()?.telephone_number, {
                                        label: 'Phone number',
                                        placeholder: '+31501234567',
                                    })}

                                    {inputs.text(this.supplier()?.url, {
                                        label: 'Website',
                                        onblur: () => this.complete_supplier_url(),
                                        validation: this.$v.url,
                                        placeholder: 'https://company.org',
                                    })}

                                    {inputs.checkbox(this.supplier()?.operates_online, {
                                        help: 'Relation has a webshop',
                                        label: 'Operates online',
                                    })}

                                    {inputs.text(this.supplier()?.emailaddress, {
                                        label: 'Email address',
                                        onblur: (value) => {
                                            if (!this.supplier()?.emailaddress_financial_documents()) {
                                                this.supplier()?.emailaddress_financial_documents(value)
                                            }
                                        },
                                        placeholder: 'info@company.org',
                                    })}

                                    {inputs.text(this.supplier()?.emailaddress_rfp_documents, {
                                        label: 'Email address (RFP)',
                                    })}

                                    <div class="field-group">
                                        <div class="field-group">
                                            {inputs.text(this.supplier()?.linkedin, {
                                                icon: 'fab fa-linkedin',
                                                label: 'LinkedIn',
                                                placeholder: 'linkedin.com/company/...',
                                            })}
                                            {inputs.text(this.supplier()?.facebook, {
                                                icon: 'fab fa-facebook',
                                                label: 'Facebook',
                                                placeholder: 'facebook.com/...',
                                            })}
                                        </div>
                                    </div>

                                    <div class="field-group">
                                        <div class="field-group">
                                            {inputs.text(this.supplier()?.instagram, {
                                                icon: 'fab fa-instagram',
                                                label: 'Instagram',
                                                placeholder: 'instagram.com/...',
                                            })}
                                            {inputs.text(this.supplier()?.twitter, {
                                                icon: 'fab fa-twitter',
                                                label: 'Twitter',
                                                placeholder: 'twitter.com/...',
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="fieldset-group">
                                <div class="fieldset">
                                    <div class="fieldset-label">Suppliers</div>

                                    <FieldSelect
                                        disabled={this.supplier()?.has_purchase_orders()}
                                        help={
                                            this.supplier()?.has_purchase_orders()
                                                ? 'Supplier already has purchase orders'
                                                : 'Purchase ledger for purchases from this supplier'
                                        }
                                        label="Purchase ledger"
                                        model={[this.supplier(), 'purchase_ledger_artkey']}
                                        options={this.purchase_ledgers().map(ledger => ({
                                            label: ledger.description(),
                                            value: ledger.artkey(),
                                        }))}
                                        placeholder="Select ledger..."
                                        validation={this.$v.purchase_ledger_artkey}
                                    />

                                    {inputs.checkbox(this.supplier()?.is_supplier, {
                                        help: 'We may buy products from this relation',
                                        label: 'Relation is supplier',
                                    })}

                                    {inputs.checkbox(this.supplier()?.should_receive_purchase_mails, {
                                        disabled: !this.supplier()?.is_supplier(),
                                        help: 'Purchase mails are sent to relation contacts',
                                        label: 'Receive purchase mails',
                                    })}

                                    <FieldSelect
                                        disabled={!this.supplier()?.is_supplier()}
                                        help={this.supplier()?.is_supplier()
                                            ? 'Payment terms this supplier uses'
                                            : help.supplier_only}
                                        label="Purchase terms"
                                        model={[this.supplier(), 'purchase_payment_term_artkey']}
                                        options={this.payment_terms().map(term => ({
                                            label: term.description(),
                                            value: term.artkey(),
                                        }))}
                                        placeholder="Select payment terms..."
                                        validation={this.$v.purchase_payment_term_artkey}
                                    />

                                    <FieldSelect
                                        disabled={!this.supplier()?.is_supplier()}
                                        help={
                                            this.supplier()?.is_supplier()
                                                ? 'Relates to the shelf life of supplier pricelists from this supplier'
                                                : help.supplier_only
                                        }
                                        label="Pricelist refresh rate"
                                        model={[this, 'price_list_frequency_select']}
                                        options={$m.data.price_list_frequencies_as_table.map(frequency => ({
                                            value: frequency[0],
                                            label: frequency[1],
                                        }))}
                                        placeholder="Select pricelist sustainability..."
                                        validation={this.$v.price_list_frequency}
                                    />

                                    {/* Custom frequency inputs */}
                                    {this.price_list_frequency_select() === 'other' && (
                                        <div class="field">
                                            <label>Pricelist frequency</label>
                                            <div className="control">
                                                {inputs.number(this.price_list_frequency_amount)}
                                                {inputs.radio(this.price_list_frequency_unit, [
                                                    {value: 'days', description: 'Days'},
                                                    {value: 'weeks', description: 'Weeks'},
                                                    {value: 'months', description: 'Months'},
                                                    {value: 'years', description: 'Years'},
                                                ])}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div class="fieldset">
                                    <div class="fieldset-label">Buyers</div>
                                    <FieldSelect
                                        disabled={this.supplier()?.has_sales_orders()}
                                        help={
                                            this.supplier()?.has_sales_orders()
                                                ? help.has_sales_orders
                                                : this.supplier()?.is_buyer()
                                                    ? 'Sales ledger for purchases from this buyer'
                                                    : help.buyer_only
                                        }
                                        label="Sales ledger"
                                        model={[this.supplier(), 'sales_ledger_artkey']}
                                        options={this.sales_ledgers().map(ledger => ({
                                            value: ledger.artkey(),
                                            label: ledger.description(),
                                        }))}
                                        placeholder="Select ledger..."
                                        validation={this.$v.sales_ledger_artkey}
                                    />

                                    {inputs.checkbox(this.supplier()?.is_buyer, {
                                        help: 'This relation may buy products from us',
                                        label: 'Relation is buyer',
                                    })}

                                    {inputs.checkbox(this.supplier()?.should_receive_offer_mails, {
                                        disabled: !this.supplier()?.is_buyer(),
                                        help: 'Offer mails are sent to relation contacts',
                                        label: 'Receive offer mails',
                                    })}

                                    <FieldSelect
                                        disabled={!this.supplier()?.is_buyer()}
                                        help={this.supplier()?.is_buyer() ? 'Payment terms we use for this buyer' : help.buyer_only}
                                        label="Sales terms"
                                        model={[this.supplier(), 'sales_payment_term_artkey']}
                                        options={this.payment_terms().map(payment_term => ({
                                            label: payment_term.description(),
                                            value: payment_term.artkey(),
                                        }))}
                                        placeholder="Select payment terms..."
                                        validation={this.$v.sales_payment_term_artkey}
                                    />
                                    <FieldSelect
                                        disabled={!this.supplier()?.is_buyer() || this.supplier()?.has_sales_orders()}
                                        help={
                                            this.supplier()?.has_sales_orders()
                                                ? help.has_sales_orders
                                                : this.supplier()?.is_buyer()
                                                    ? 'Sales ledger for low tariff purchases from this buyer'
                                                    : help.buyer_only
                                        }
                                        label="Sales ledger (low tariff)"
                                        model={[this.supplier(), 'low_tariff_sales_ledger_artkey']}
                                        options={this.sales_ledgers().map(ledger => ({
                                            value: ledger.artkey(),
                                            label: ledger.description(),
                                        }))}
                                        placeholder="Select ledger..."
                                    />

                                    <FieldMoney
                                        currency={[this.supplier(), 'currency']}
                                        help="Enables free shipping incentive in the portal"
                                        label="Minimum order amount"
                                        model={[this.supplier(), 'minimum_order_amount']}
                                    />

                                    <PercentInput
                                        help="Percentage added to the relation's prices in the portal"
                                        label="Price markup"
                                        value={this.supplier()?.price_markup_percentage()}
                                        minimum={-10}
                                        on_value={(value) => {
                                            this.supplier()?.price_markup_percentage(value)
                                        }}
                                    />
                                </div>
                            </div>

                            <div class="fieldset-group">
                                <div class="fieldset">
                                    <div class="fieldset-label">Transport</div>
                                    {inputs.text(this.supplier()?.excise_id, {
                                        label: 'Excise ID',
                                        help: 'Identifies a registered trader within the excise community',
                                        placeholder: 'e.g. NL00000123ABC',
                                    })}
                                    <div class="field-group">
                                        <FieldSelect
                                            help={
                                                !this.supplier()?.portal_customs_visibility()
                                                    ? ''
                                                    : (this.supplier()?.portal_customs_visibility() === this.supplier()?.customs_status()) || this.supplier()?.customs_status() === 'T1'
                                                        ? `Portal visibility is set to ${this.supplier()?.portal_customs_visibility()}`
                                                        : `Warning: Portal visibility is set to ${this.supplier()?.portal_customs_visibility()}`
                                            }
                                            label="Customs status"
                                            model={[this.supplier, 'customs_status']}
                                            options={[
                                                {label: 'T1', value: 'T1'},
                                                {label: 'T2', value: 'T2'},
                                            ]}
                                            validation={this.$v.customs_status}
                                        />

                                        <FieldSelect
                                            label="Customs Visibility"
                                            help={
                                                this.supplier()?.portal_customs_visibility() === this.supplier()?.customs_status() || this.supplier()?.customs_status() === 'T1'
                                                    ? `Supplier customs status is set to ${this.supplier()?.customs_status()}`
                                                    : `Warning! Supplier customs status is set to ${this.supplier()?.customs_status()}`
                                            }
                                            model={[this.supplier(), 'portal_customs_visibility']}
                                            options={$m.data.supplier_portal_customs_visibility.map((i) => ({
                                                label: i[1],
                                                value: i[0],
                                            }))}
                                            placeholder="Select visibility..."
                                            validation={this.$v.portal_customs_visibility}
                                        />
                                    </div>

                                    {!this.create() && (
                                        <FieldSelect
                                            label="Primary destination"
                                            help="Add or modify destinations from the 'Destinations' tab"
                                            model={[this.supplier(), 'primary_warehouse_name']}
                                            options={this.warehouse_names().map((name) => ({
                                                label: name,
                                                value: name,
                                            }))}
                                            placeholder="Select a destination..."
                                        />
                                    )}

                                    <IncotermsDropDown
                                        get_all_for_drop_down_response$={IncotermsDropDownData.incoterms()}
                                        help={
                                            !this.supplier()?.incoterm() || this.supplier()?.incoterm() in ['EXW', 'FCA']
                                                ? 'No transport costs will be calculated'
                                                : this.supplier()?.incoterm() && this.primary_warehouse()
                                                    ? `Incoterm of warehouse: ${this.supplier()?.incoterm()} - ${this.primary_warehouse()?.city} - ${countries[this.primary_warehouse()?.country_code]} (${this.primary_warehouse()?.name}).`
                                                    : 'Incoterm: EXW - Roosendaal - Netherlands (Loendersloot).'
                                        }
                                        label="Incoterms"
                                        model={[this.supplier(), 'incoterm']}
                                        placeholder="Select Incoterms..."
                                    />

                                    <FieldSelect
                                        disabled={!['EXW', 'FCA'].includes(this.supplier()?.incoterm())}
                                        help={this.supplier()?.incoterm() in ['EXW', 'FCA'] ? 'Transport costs payment method' : 'Only with Ex works & Free Carrier Incoterm'}
                                        label="Payment method"
                                        model={[this.supplier(), 'transport_costs_payment_method']}
                                        options={$m.data.supplier_transport_costs_payment_methods.map((i) => ({
                                            label: i[1],
                                            value: i[0],
                                        }))}
                                        placeholder="Select payment method..."
                                    />

                                    <FieldMoney
                                        currency={[this.supplier(), 'currency']}
                                        help="Added to the price on the price list & portal"
                                        label="Transport costs per case"
                                        model={[this.supplier(), 'transport_costs_per_case']}
                                    />

                                    {inputs.checkbox(this.supplier()?.use_incoterm_on_pricelist, {
                                        disabled: !(this.supplier()?.incoterm() && this.primary_warehouse()),
                                        help: this.supplier()?.incoterm() && this.primary_warehouse() ? 'Please account for potential transport costs!' : 'Only for relations with an incoterm & primary warehouse set',
                                        label: 'Use selected incoterm on pricelist and portal',
                                    })}

                                    {this.primary_warehouse() && this.supplier()?.use_incoterm_on_pricelist() && (
                                        <div class="alert alert-danger">
                                            <span>The following incoterms will be applied for this relation:</span>
                                            <br/>
                                            <span class="text-muted">
                                                {`${this.supplier()?.incoterm()} - ${this.primary_warehouse()?.city} - ${this.primary_warehouse()?.country_code} (${this.primary_warehouse()?.name})`}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                {/* Portal Settings fieldset */}
                                <div class="fieldset">
                                    <div class="fieldset-label">Portal Settings</div>
                                    <div class="field-readonly">
                                        <div class="key">Price list generated on</div>
                                        <div class="value">{formatDateTime(this.supplier()?.relation_price_list_generated_on())}</div>
                                    </div>

                                    <FieldSelect
                                        label="Portal level"
                                        help="Trial & demo accounts expire; normal requires 2fa login"
                                        model={[this.supplier(), 'portal_level']}
                                        options={$m.data.supplier_portal_levels.map((i) => ({
                                            label: i[1],
                                            value: i[0],
                                        }))}
                                        placeholder="Select trust level..."
                                    />

                                    {inputs.date(this.supplier()?.portal_account_expiry, {
                                        disabled: !(this.supplier()?.portal_level() in ['trial', 'demo']),
                                        help:
                                            this.supplier()?.portal_level() in ['trial', 'demo']
                                                ? 'Account becomes inactive after specified date'
                                                : 'Only enabled for trial & demo accounts',
                                        label: 'Account expiry',
                                    })}

                                    <FieldSelect
                                        label="Price/quantity unit"
                                        model={[this.supplier(), 'price_preference']}
                                        options={price_quantity_preference.map((i) => ({
                                            label: i[1],
                                            value: i[0],
                                        }))}
                                        validation={this.$v.price_preference}
                                    />

                                    {inputs.checkbox(this.supplier()?.show_excise_in_portal, {
                                        help: 'Show excise prices separately, next to the product prices in the portal',
                                        label: 'Show excise in portal',
                                    })}

                                    {inputs.checkbox(this.supplier()?.include_excise_in_price, {
                                        disabled: !excise_countries.includes(this.supplier()?.country_code()),
                                        help:
                                            excise_countries.includes(this.supplier()?.country_code())
                                                ? 'Include excise costs in all shown prices'
                                                : `Only enabled for countries: ${excise_countries.join(', ')}`,
                                        label: 'Include excise in price',
                                    })}

                                    {inputs.checkbox(this.supplier()?.include_ukds_in_price, {
                                        disabled: this.supplier()?.currency() !== 'GBP',
                                        help:
                                            this.supplier()?.currency() === 'GBP'
                                                ? 'Need to add a UKDS sticker on bottles?'
                                                : 'Only in use when trading currency is set to GBP',
                                        label: 'Add UKDS sticker to price',
                                    })}

                                    {this.supplier()?.portal_customs_visibility() === 'both' && (
                                        inputs.checkbox(this.supplier()?.mix_customs_on_pricelist, {
                                            help: 'Relates to pricelists; no separate grey section',
                                            label: 'Mix products from different customs statuses',
                                        })
                                    )}

                                    {this.supplier()?.active_promotion() && (
                                        <FieldCheckbox
                                            label={`Promotion: ${this.supplier()?.active_promotion()?.name}`}
                                            help="Whether relation takes part in this active promotion"
                                            model={[this.data.$f, 'promotion_selected']}
                                            placeholder="No promotion selected..."
                                        />
                                    )}

                                    {this.data.$f.promotion_selected && (
                                        <div class="promotion-stats mt-3">
                                            <div class="stat">
                                                <div class="key">Turnover:</div>
                                                <div class="value">
                                                    {`${format_money(turnover.redeemable)} redeemable (${format_money(turnover.draft)} draft)`}
                                                </div>
                                            </div>

                                            <div class="stat">
                                                <div class="key">Tokens Available:</div>
                                                <div class="value">{`${tokens.redeemable - this.data.$f.tokens_spent} (x${this.data.threshold})`}</div>
                                            </div>

                                            <FieldText
                                                label="Tokens redeemed"
                                                help="Tokens this customer already redeemed during the promotion"
                                                max={tokens.redeemable}
                                                min={0}
                                                model={[this.data.$f, 'tokens_spent']}
                                                type="number"
                                            />

                                            <FieldMoney
                                                currency={[this.supplier(), 'currency']}
                                                help="Amount to be subtracted from the total turnover"
                                                label="Penalty"
                                                model={[this.data.$f, 'penalty']}
                                            />
                                        </div>
                                    )}
                                </div>

                            </div>

                            <div class="c-submit">
                                <button
                                    class="btn btn-success btn-submit"
                                    disabled={invalid.length > 0}
                                    onclick={(e) => {
                                        e.preventDefault()
                                        this.save()
                                    }}
                                >
                                    {this.create() ? 'Create Relation' : 'Update Relation'}
                                </button>

                                {invalid_fields_format(invalid)}
                            </div>
                        </form>
                    </div>

                    {/* Other tabs */}
                    <div class={classes('c-tab', {active: this.data.tab === 'contacts'})}>
                        <div class="btn-toolbar">
                            <button
                                class="btn btn-default"
                                type="button"
                                onclick={() => this.contact_person_create_url()}
                            >
                                <span class="glyphicon glyphicon-plus"></span>
                                {' Create contact'}
                            </button>
                        </div>

                        {this.contact_persons() !== null && (
                            <Table
                                items={this.contact_persons}
                                options={{
                                    autoscale: true,
                                    onclick: (cp) => {
                                        m.route.set(this.contact_person_edit_url(cp))
                                    },
                                }}
                                columns={[
                                    {
                                        name: 'Name',
                                        field: 'name',
                                        width: 20,
                                    },
                                    {
                                        name: 'Telephone nr.',
                                        field: 'telephone_number',
                                        width: 15,
                                    },
                                    {
                                        name: 'Mobile tel.nr.',
                                        field: 'mobile_telephone_number',
                                        width: 15,
                                    },
                                    {
                                        name: 'E-mail',
                                        field: 'emailaddress',
                                        width: 25,
                                    },
                                    {
                                        name: 'Portal user',
                                        width: 15,
                                        function: (record) => {
                                            const portal_user = find_active_portal_user(record.portal_users)
                                            if (portal_user && !portal_user.is_deleted) {
                                                return link(`/portal/portal-users/${portal_user.artkey}/edit`, portal_user.name, {class: 'no-click'})
                                            } else {
                                                return m('em', 'No portal user')
                                            }
                                        },
                                    },
                                    {
                                        name: 'Last seen',
                                        width: 15,
                                        function: (record) => {
                                            const portal_user = find_active_portal_user(record.portal_users)
                                            if (portal_user) {
                                                return formatDateTime(portal_user.last_seen_timestamp)
                                            } else {
                                                return m('em', '-')
                                            }
                                        },
                                    },
                                    {
                                        name: 'Salutation',
                                        field: 'salutation',
                                        width: 10,
                                        default_visible: false,
                                    },
                                    {
                                        name: 'Language',
                                        field: 'language',
                                        width: 10,
                                        default_visible: false,
                                        function: (record) => {
                                            if (record.language) {
                                                return `${languages[record.language]}`
                                            } else {
                                                return '-'
                                            }
                                        },
                                    },
                                    {
                                        name: 'Column selector',
                                        header: ' ',
                                        value: ' ',
                                        width: 1,
                                    },
                                ]}
                            />
                        )}
                    </div>

                    <div class={classes('c-tab', {active: this.data.tab === 'hide_information'})}>
                        {!this.create() && (
                            <HiddenInformation
                                hidden_information_helper={new HiddenRelationHelper(this.supplier()?.artkey())}
                            />
                        )}
                    </div>

                    <div class={classes('c-tab', {active: this.data.tab === 'logbook'})}>
                        {!this.create() && this.supplier() && (
                            <div class="fieldset">
                                {this.supplier() && this.supplier()?.artkey() && [
                                    <LogEntries
                                        log_entries={this.supplier()?.log_entries()}
                                    />,
                                    <AddLogEntry
                                        artkey={this.supplier()?.artkey()}
                                        process="suppliers.add_log_entry"
                                        on_add_callback={() => this.query_supplier(this.supplier()?.artkey())}
                                    />,
                                ]}
                            </div>
                        )}
                    </div>

                    <div class={classes('c-tab', {active: this.data.tab === 'attachments'})}>
                        {this.supplier() && this.supplier()?.artkey() && [
                            <AttachmentList
                                attachment_helper={this.attachment_helper}
                            />,
                            <UploadAttachment
                                attachment_helper={this.attachment_helper}
                            />,
                        ]}
                    </div>

                    <div class={classes('c-tab', {active: this.data.tab === 'destinations'})}>
                        <div class="btn-toolbar">
                            <button
                                class="btn btn-default"
                                type="button"
                                onclick={() => m.route.set(`${this.base_url()}/edit/warehouses/create`)}
                            >
                                <span class="glyphicon glyphicon-plus"></span>
                                {' Create Destination'}
                            </button>
                            <button
                                class="btn btn-default"
                                type="button"
                                onclick={() => m.route.set(this.warehouse_select_url())}
                            >
                                <span class="glyphicon glyphicon-plus"></span>
                                {' Select Destinations'}
                            </button>
                        </div>

                        {this.supplier() && this.supplier()?.artkey() && (
                            <Table
                                items={this.warehouses}
                                options={{
                                    autoscale: true,
                                    onclick: (w) => m.route.set(this.warehouse_edit_url(w)),
                                }}
                                columns={[
                                    {
                                        name: 'Name',
                                        field: 'name',
                                        width: 15,
                                    },
                                    {
                                        name: 'Address',
                                        function: (w) => joinMaybes(' ', [
                                            w.street_address,
                                            w.zip_code,
                                            w.city,
                                        ]),
                                        width: 40,
                                    },
                                    {
                                        name: 'Country code',
                                        field: 'country_code',
                                        width: 10,
                                    },
                                    {
                                        name: 'Location ID',
                                        field: 'warehouse_id',
                                        default_visible: false,
                                        width: 15,
                                    },
                                    {
                                        name: 'Excise ID',
                                        field: 'excise_id',
                                        default_visible: false,
                                        width: 15,
                                    },
                                    {
                                        name: 'Column selector',
                                        header: ' ',
                                        value: ' ',
                                        width: 1,
                                    },
                                ]}
                            />
                        )}
                    </div>

                </div>
            </div>
        )
    }
}

interface PortalUser {
  is_deleted: boolean
  [key: string]: any
}

function find_active_portal_user(portal_users: PortalUser[]): PortalUser | undefined {
    return portal_users.find(portal_user => !portal_user.is_deleted)
}
