/** llm:tested */
/* eslint-disable @typescript-eslint/naming-convention */
import m from 'mithril'
import {current_account_slug} from '@bitstillery/common/account/account'
import {generate_transaction_id} from '@bitstillery/common/ts_utils'
import {debounce} from '@bitstillery/common/ts_utils'
import {config} from '@bitstillery/common/app'

import errors from '@/errors'
import {$m, $s} from '@/app'

class NotLoggedInException {}

class RequestException {}
class RequestCancelledException {}

const UNSECURED_PROCESSES = ['users.get_pre_auth_token', 'auth.forgot_password', 'auth.reset_password']

const handle_not_logged_in = debounce(250, () => {
    $m.identity.logout()
})

const handle_request_exception = debounce(250, () => {
    errors.generic_handler()
})

const callViaHttp = (function_name: string, data: any, callback?: (resp: any) => void) => {
    if (!UNSECURED_PROCESSES.includes(function_name) && !$s.identity.token) {
        return
    }

    const options: m.RequestOptions<any> = {
        url: config.api_host + '/' + function_name,
        method: 'POST',
        background: true,
        body: {
            data: {
                ...data,
                _account_slug: current_account_slug(),
            },
        },
        extract: clearIdOnUnauthorized,
        config: (xhr) => {
            // Basic auth for the first line of defense
            const authorization = 'Basic ' + btoa(`${config.api_user}:${config.api_password}`)
            xhr.setRequestHeader('Authorization', authorization)

            // JWT for authentication
            if ($s.identity.token) {
                xhr.setRequestHeader('X-Auth-Token', $s.identity.token)
            }

            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            xhr.setRequestHeader('X-transaction-id', 'disco-' + generate_transaction_id())
        },
    }

    const request = m.request(options)

    const redraw_callback = (resp: any) => {
        callback?.(resp)
        m.redraw()
    }

    const handle_error = (error: any) => {
        if (error instanceof NotLoggedInException) {
            $m.identity.logout_debounced()
        } else if (error instanceof RequestException) {
            handle_request_exception()
        } else if (error instanceof RequestCancelledException) {
            // eslint-disable-next-line no-console
            console.log('Cancelled request.')
        } else {
            errors.generic_handler(error.message || error.title || error)
        }
    }

    if (callback) {
        request.then(redraw_callback, handle_error)
    } else {
        return request
    }
}

export const call = callViaHttp

// vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
// New definitions imported from Portal.
// The above call will be deprecated in the future.
// vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

interface CallOptions {
    success: (resp: any) => void
    failure?: (resp: any) => void
    final?: (resp: any) => void
    redraw?: boolean
}

export const call2 = (
    process: string,
    data: any,
    success_cb: (resp: any) => void,
    failure_cb: ((resp: any) => void) | null = null,
    final_cb: ((resp: any) => void) | null = null,
    redraw = true,
) => {
    if (!UNSECURED_PROCESSES.includes(process) && !$s.identity.token) {
        return
    }

    // Stores a reference to the XHR
    const transport = window.prop(null)

    data._account_slug = current_account_slug()

    const req = m.request({
        url: config.api_host + '/' + process,
        method: 'POST',
        background: true,
        body: {data},
        config: (xhr) => {
            // First apply getRequestHeaders, then store the xhr in transport
            const configuredXhr = getRequestHeaders(xhr)
            transport(configuredXhr)
            return configuredXhr
        },
        extract: clearIdOnUnauthorized,
    })

    const handle_response = (resp: any) => {
        if (resp.success) {
            success_cb(resp)
        } else if (failure_cb) {
            failure_cb(resp)
        } else {
            errors.generic_handler_no_sentry(resp.message)
        }

        if (final_cb) {
            final_cb(resp)
        }

        if (redraw) m.redraw()
    }

    const handle_error = (error: any) => {
        if (error instanceof NotLoggedInException) {
            handle_not_logged_in()
        } else if (error instanceof RequestException) {
            handle_request_exception()
        } else if (error instanceof RequestCancelledException) {
            handle_request_exception()
        } else {
            errors.generic_handler(error.message || error.title || error)
        }
    }

    return {
        promise: req.then(handle_response, handle_error),
        abort: () => {
            // Detach mithril's handlers before we abort the request.
            // If we don't do this, big red errors will show up in the console..
            const t = transport()
            if (t) {
                t.onreadystatechange = null
                t.abort()
            }
        },
    }
}

export const callAndThen = (process: string, data: any, {success, failure, final, redraw}: CallOptions) => {
    if (redraw === undefined) redraw = true
    return call2(process, data, success, failure, final, redraw)
}

export const getRequestHeaders = (xhr: XMLHttpRequest) => {
    // Add basic auth for first line of defence.
    xhr.setRequestHeader('Authorization', basicAuthHeader())
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.setRequestHeader('X-transaction-id', 'disco-' + generate_transaction_id())
    const token = $s.identity.token
    if (token) {
        xhr.setRequestHeader('X-Auth-Token', token)
    }

    return xhr
}

const basicAuthHeader = () => {
    return 'Basic ' + btoa(`${config.api_user}:${config.api_password}`)
}

const clearIdOnUnauthorized = (xhr: XMLHttpRequest) => {
    if (xhr.status === 401) {
        $s.identity.token = null
        throw new NotLoggedInException()
    }
    if (xhr.status > 499) {
        throw new RequestException()
    }
    if (xhr.status === 0) {
        throw new RequestCancelledException()
    }

    return JSON.parse(xhr.responseText)
}

export default {call, call2, callAndThen}
