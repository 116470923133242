/** llm:tested */
import m from 'mithril'
import {Obj} from 'prelude-ls'
import {classes} from '@bitstillery/common/lib/utils'
import {Spinner} from '@bitstillery/common/components'
import {Amount} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {api, notifier} from '@bitstillery/common/app'

import api_ls from '@/api'
import {$m, $s} from '@/app'
import {Bottle} from '@/models/bottles'
import {Case} from '@/models/stock'

interface BottleGTINModelAttrs {
    artkey?: string
    bottle?: any
    gtin?: string
}

class BottleGTINModel {
    artkey: () => string
    bottle_artkey: () => string
    bottle: () => Bottle
    gtin: () => string

    constructor(json: BottleGTINModelAttrs) {
        this.artkey = window.prop('')
        this.bottle_artkey = window.prop('')
        this.bottle = window.prop(new Bottle())
        this.gtin = window.prop('')

        for (const prop in json) {
            if (prop === 'bottle') {
                this.bottle_artkey(json[prop].artkey)
                this.bottle($m.bottles.create_bottle(json[prop], $m.products.create_product(json[prop]['product'])))
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    toJS() {
        return {
            artkey: +this.artkey(),
            bottle_artkey: +this.bottle_artkey(),
            gtin: +this.gtin(),
        }
    }
}

interface BottleOnlyFormAttrs {
    bottle: any
    on_check: (event: Event) => void
}

export class BottleOnlyForm extends MithrilTsxComponent<BottleOnlyFormAttrs> {
    bottle: () => any
    on_check: (event: Event) => void
    loading_source_lines: () => boolean
    show_cases: () => boolean
    cases: () => Case[]
    loading_cases: () => boolean
    show_bottle_gtins: () => boolean
    bottle_gtins: () => BottleGTINModel[]
    loading_bottle_gtins: () => boolean
    deleting_bottle_gtin: () => boolean
    new_bottle_gtin: () => string
    case_to_edit: () => any

    constructor(vnode: m.Vnode<BottleOnlyFormAttrs>) {
        super()
        this.bottle = window.prop(vnode.attrs.bottle)
        this.on_check = vnode.attrs.on_check
        this.loading_source_lines = window.prop(false)
        this.show_cases = window.prop(false)
        this.cases = window.prop([])
        this.loading_cases = window.prop(false)
        this.show_bottle_gtins = window.prop(false)
        this.bottle_gtins = window.prop([])
        this.loading_bottle_gtins = window.prop(false)
        this.deleting_bottle_gtin = window.prop(false)
        this.new_bottle_gtin = window.prop('')
        this.case_to_edit = window.prop(null)
    }

    toggle_bottle_cases() {
        if (this.show_cases()) {
            this.show_cases(false)
            this.cases([])
        } else {
            this.show_cases(true)
            this.load_bottle_cases()
        }
    }

    load_bottle_cases() {
        this.loading_cases(true)
        const data = {
            bottle_artkey: this.bottle().artkey(),
        }
        api_ls.call('product_management.get_cases_for_bottle', data, this.handle_bottle_cases.bind(this))
    }

    handle_bottle_cases(result: any) {
        if (result.success) {
            this.cases(result['result'].map((json_data: any) => new Case(json_data)))
        } else {
            $m.common.generic_error_handler()
        }
        this.loading_cases(false)
    }

    toggle_bottle_gtins() {
        if (this.show_bottle_gtins()) {
            this.show_bottle_gtins(false)
            this.bottle_gtins([])
        } else {
            this.show_bottle_gtins(true)
            this.load_bottle_gtins()
        }
    }

    load_bottle_gtins() {
        this.loading_bottle_gtins(true)
        const data = {
            bottle_artkey: this.bottle().artkey(),
        }
        api_ls.call('product_management.get_bottle_gtins_for_bottle', data, this.handle_bottle_gtins.bind(this))
    }

    handle_bottle_gtins(result: any) {
        if (result.success) {
            this.bottle_gtins(result['result'].map((json_data: any) => new BottleGTINModel(json_data)))
        } else {
            $m.common.generic_error_handler()
        }
        this.loading_bottle_gtins(false)
    }

    handle_bottle_gtin_delete(result: any) {
        if (result.success) {
            notifier.notify('Successfully deleted the bottle gtin.', 'success')
            this.load_bottle_gtins()
        } else {
            $m.common.generic_error_handler()
        }
        this.deleting_bottle_gtin(false)
    }

    add_bottle_gtin_to_spec() {
        if (!this.new_bottle_gtin()) {
            return notifier.notify('Unable to add GTIN: No GTIN was entered', 'danger')
        }

        for (const bottle_gtin of this.bottle_gtins()) {
            if (this.new_bottle_gtin() === bottle_gtin.gtin()) {
                return notifier.notify('Unable to add GTIN: This GTIN is already saved on this bottle', 'danger')
            }
        }

        const data = {
            bottle_artkey: this.bottle().artkey(),
            bottle_gtin_code: this.new_bottle_gtin(),
        }
        api_ls.call('product_management.add_bottle_gtin_to_spec', data, this.handle_add_bottle_gtin.bind(this))
    }

    handle_add_bottle_gtin(response: any) {
        if (!response.message) {
            notifier.notify('Successfully added the bottle gtin: ' + response.result, 'success')
            this.new_bottle_gtin('')
            this.load_bottle_gtins()
        } else {
            notifier.notify(response.message, 'danger')
        }
    }

    start_edit_case(caze: any) {
        this.case_to_edit(window.prop(null))
        this.case_to_edit().artkey = window.prop(caze.artkey())
        this.case_to_edit().cases_per_pallet = window.prop(caze.cases_per_pallet())
        this.case_to_edit().cases_per_pallet_layer = window.prop(caze.cases_per_pallet_layer())
    }

    cancel_edit_case() {
        this.case_to_edit(null)
    }

    async save_case(caze: any) {
        const data = {
            cases_per_pallet: this.case_to_edit().cases_per_pallet(),
            cases_per_pallet_layer: this.case_to_edit().cases_per_pallet_layer(),
        }
        const response = await api.put('discover/cases/' + this.case_to_edit().artkey(), data)

        if (response.success) {
            notifier.notify('Successfully updated the case.', 'success')
            caze.cases_per_pallet(data.cases_per_pallet)
            caze.cases_per_pallet_layer(data.cases_per_pallet_layer)
        } else {
            $m.common.generic_error_handler()
        }

        this.case_to_edit(null)
    }

    view() {
        return <div class="c-bottle-only-form">
            <div class="fieldset large">
                <div class="field-group">
                    <div class="field">
                        <label for={'bottle-cb' + this.bottle().artkey()}>{m.trust('&nbsp;')}</label>
                        <input
                            type="checkbox"
                            id={'bottle-cb' + this.bottle().artkey()}
                            onchange={this.on_check}
                            value={this.bottle().artkey()}
                        />
                    </div>
                    <div class="field">
                        <label>Size in cl</label>
                        <input
                            type="number"
                            required={true}
                            step={0.1}
                            min={0.1}
                            value={(+this.bottle().volume()).toFixed(1)}
                            onchange={(ev: Event) => this.bottle().volume((ev.target as HTMLInputElement).value)}
                        />
                    </div>
                    <div class="field">
                        <label>Alcohol %</label>
                        <input
                            type="number"
                            required={true}
                            step={0.1}
                            min={0}
                            value={(+this.bottle().alcohol_percentage()).toFixed(1)}
                            onchange={(ev: Event) => this.bottle().alcohol_percentage((ev.target as HTMLInputElement).value)}
                        />
                    </div>
                    <div class="field">
                        <label>Refill status</label>
                        <select
                            required={true}
                            value={this.bottle().refill_status().toLowerCase()}
                            onchange={(ev: Event) => this.bottle().refill_status((ev.target as HTMLSelectElement).value)}
                        >
                            {$m.data.bottle_refill_statusses.map((refill_status: string) =>
                                <option>{refill_status}</option>,
                            )}
                        </select>
                    </div>
                    <div class="field">
                        <label>Dutch excise</label>
                        <Amount
                            amount={this.bottle().excise_nl()}
                            currency={$s.currencies.default}
                        />
                    </div>
                </div>
                <div class="btn-group">
                    <button
                        class={classes({'btn btn-default': true, active: this.show_cases()})}
                        onclick={() => this.toggle_bottle_cases()}
                    >
                        <span class="fas fa-cubes" />
                    </button>
                    <button
                        class={classes({'btn btn-default': true, active: this.show_bottle_gtins()})}
                        onclick={() => this.toggle_bottle_gtins()}
                    >
                        <span class="glyphicon glyphicon-barcode" />
                    </button>
                    {this.bottle().dirty() &&
                        <button class="btn btn-default" onclick={() => this.bottle().reset()}>
                            Reset
                        </button>
                    }
                </div>
            </div>

            {this.show_cases() &&
                <div class="well large">
                    {this.loading_cases() ?
                        <Spinner />
                        : Obj.empty(this.cases()) ?
                            <span>No cases found for this spec.</span>
                            :
                            <table class="table search-table">
                                <thead class="thead-default">
                                    <tr>
                                        <th>Number of bottles</th>
                                        <th>Gift box type</th>
                                        <th>Tax label</th>
                                        <th>Weight</th>
                                        <th>Dimensions (l/w/h)</th>
                                        <th>CPP</th>
                                        <th>CPL</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {this.cases().map((caze: Case) =>
                                    <tr>
                                        <td>{caze.number_of_bottles()}</td>
                                        <td>{caze.gift_box_type()}</td>
                                        <td>{caze.tax_label()}</td>
                                        <td>{caze.weight()}</td>
                                        <td>
                                            {caze.length() && caze.width() && caze.height() &&
                                            `${caze.length()} x ${caze.width()} x ${caze.height()}`
                                            }
                                        </td>
                                        <td>
                                            {this.case_to_edit() && this.case_to_edit().artkey() === caze.artkey() ?
                                                <input
                                                    type="number"
                                                    value={this.case_to_edit().cases_per_pallet()}
                                                    onchange={(ev: Event) => this.case_to_edit().cases_per_pallet((ev.target as HTMLInputElement).value)}
                                                />
                                                :
                                                caze.cases_per_pallet()
                                            }
                                        </td>
                                        <td>
                                            {this.case_to_edit() && this.case_to_edit().artkey() === caze.artkey() ?
                                                <input
                                                    type="number"
                                                    value={this.case_to_edit().cases_per_pallet_layer()}
                                                    onchange={(ev: Event) => this.case_to_edit().cases_per_pallet_layer((ev.target as HTMLInputElement).value)}
                                                />
                                                :
                                                caze.cases_per_pallet_layer()
                                            }
                                        </td>
                                        <td>
                                            {this.case_to_edit() && this.case_to_edit().artkey() === caze.artkey() ?
                                                <div class="btn-group">
                                                    <button class="btn btn-success" onclick={() => this.save_case(caze)}>
                                                        <span class="glyphicon glyphicon-floppy-disk" />
                                                    </button>
                                                    <button class="btn btn-default" onclick={() => this.cancel_edit_case()}>
                                                        <span class="glyphicon glyphicon-ban-circle" />
                                                    </button>
                                                </div>
                                                :
                                                <button
                                                    class="btn btn-default"
                                                    disabled={this.case_to_edit()}
                                                    onclick={() => this.start_edit_case(caze)}
                                                >
                                                    <span class="glyphicon glyphicon-pencil" />
                                                </button>
                                            }
                                        </td>
                                    </tr>,
                                )}
                            </table>
                    }
                </div>
            }

            {this.show_bottle_gtins() &&
                <div class="well large">
                    {this.loading_bottle_gtins() ?
                        <Spinner />
                        : Obj.empty(this.bottle_gtins()) ?
                            <div class="panel-body">
                                <div class="field-group">
                                    <label>GTIN code</label>
                                    <div class="field">
                                        <input
                                            placeholder="GTIN code (12/13 characters)"
                                            type="text"
                                            value={this.new_bottle_gtin()}
                                            onchange={(ev: Event) => this.new_bottle_gtin((ev.target as HTMLInputElement).value)}
                                        />
                                        <div class="help">No bottle gtins found on this spec yet. You can create a new bottle gtin on this spec:</div>
                                    </div>
                                    <button class="btn btn-info" onclick={() => this.add_bottle_gtin_to_spec()}>
                                    Add GTIN <span class="glyphicon glyphicon-plus" />
                                    </button>
                                </div>
                            </div>
                            :
                            <table class="table search-table">
                                <thead class="thead-default">
                                    <tr>
                                        <th>Bottle GTIN</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                {this.bottle_gtins().map((gtin: BottleGTINModel) =>
                                    <tr>
                                        <td>{gtin.gtin()}</td>
                                        <td>
                                            <button
                                                class="btn btn-default"
                                                value={gtin.gtin()}
                                                disabled={this.deleting_bottle_gtin()}
                                                onclick={() => {
                                                    if (confirm(`Are you sure that you want to delete the bottle gtin: ${gtin.gtin()}`)) {
                                                        this.deleting_bottle_gtin(true)
                                                        const data = {
                                                            bottle_gtin: gtin.gtin(),
                                                        }
                                                        api_ls.call('product_management.delete_bottle_gtin', data, this.handle_bottle_gtin_delete.bind(this))
                                                    }
                                                }}
                                            >
                                                <span class="glyphicon glyphicon-trash" />
                                            </button>
                                        </td>
                                    </tr>,
                                )}
                                <tr>
                                    <td>
                                        <input
                                            placeholder="Enter bottle GTIN"
                                            type="text"
                                            value={this.new_bottle_gtin()}
                                            onchange={(ev: Event) => this.new_bottle_gtin((ev.target as HTMLInputElement).value)}
                                        />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" onclick={() => this.add_bottle_gtin_to_spec()}>
                                            <span class="glyphicon glyphicon-plus" />
                                        </button>
                                    </td>
                                </tr>
                            </table>
                    }
                </div>
            }
        </div>
    }
}
