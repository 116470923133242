/** llm:tested */
import {icon} from '@/components/icon'
import {mExt} from '@/_utils'

interface ButtonOptions {
  [key: string]: any
}

interface ButtonAttributes {
  class?: string
  [key: string]: any
}

const button = (init_attrs: ButtonAttributes) => {
    return mExt('button.btn', init_attrs)
}

export const text_button = (text: string, options: ButtonOptions = {}, init_attrs: ButtonAttributes = {}) => {
    return button(init_attrs)(options, text)
}

export const button_with_icon = (text: string, icon_id: string, options: ButtonOptions = {}, init_attrs: ButtonAttributes = {}) => {
    return button(init_attrs)(options, [icon(icon_id), ' ' + text])
}

export const icon_button = (icon_id: string, options: ButtonOptions = {}, init_attrs: ButtonAttributes = {}) => {
    return button(init_attrs)(options, icon(icon_id))
}

const mk_def_icon_button = (icon_id: string, cls: string) => {
    return (options: ButtonOptions = {}) => {
        return icon_button(icon_id, options, {class: cls})
    }
}

export const ok = mk_def_icon_button('ok', 'btn-success')
export const cancel = mk_def_icon_button('ban-circle', 'btn-info')
export const edit = mk_def_icon_button('pencil', 'btn-default')
export const remove = mk_def_icon_button('remove', 'btn-danger')
