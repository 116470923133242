/** llm:tested */
import m from 'mithril'
import {Amount, Link} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {accountIcon} from '@/accounts'
import {
    formatDate, maybeMap, pluralize, capitalize,
} from '@/_utils'
import {select} from '@/components/inputs'
import {icon} from '@/components/icon'
import {link} from '@/components/link'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import {from_properties} from '@/market/pricelists/components/discover_link'
import {for_product} from '@/market/pricelists/components/explore_link'
import {TboStatus} from '@/sales_orders/models'
import {UserDropDown} from '@/components/users'
import {SearchBar} from '@/components/collection/search_bar'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {$s} from '@/app'

const TBO_STATUS_SELECTION = [
    [TboStatus.IN_PROGRESS, 'Show all in progress TBO items'],
    [TboStatus.NOT_YET_APPROVED, 'Show not yet approved TBO items'],
    [TboStatus.OPEN, 'Show open TBO items'],
    [TboStatus.ORDERED, 'Show ordered TBO items'],
    [TboStatus.CONFIRMED, 'Show confirmed TBO items'],
    [TboStatus.ALL, 'Show all TBO items'],
] as const

export class SalesOrdersAssignedStock extends MithrilTsxComponent<unknown> {
    tbo_items: Collection
    sales_manager_artkey: any
    search_status: any
    search_bar_controller: any
    product_management_api: ProductManagementApi

    constructor() {
        super()
        this.tbo_items = new Collection({
            api_function_name: 'sales.tbo.get_tbo_items',
            additional_params: () => this.additional_params(),
            query_limit: 25,
            sort_order: [],
            default_sort_by: 'sales_order.date',
            default_sort_order: 'asc',
            filter_serverside: true,
            dont_reuse: true,
        })
        this.sales_manager_artkey = window.prop($s.identity.artkey)

        this.search_status = window.prop('')
        this.search_bar_controller = null
        this.product_management_api = new ProductManagementApi()
    }

    submit_search(text: string) {
        this.tbo_items.update_search_term(text)
        this.tbo_items.submit_search()
    }

    oncreate() {
        this.tbo_items.init()

        const q = m.route.param('q')
        if (q) {
            this.submit_search(q.replace(/\+/g, ' '))
        }
    }

    additional_params() {
        const params = {}
        if (this.sales_manager_artkey()) {
            params['sales_manager_artkey'] = this.sales_manager_artkey()
        }

        if (this.search_status() === TboStatus.ALL) {
            params['status'] = [TboStatus.OPEN, TboStatus.NOT_YET_APPROVED, TboStatus.ORDERED, TboStatus.CONFIRMED]
        } else if (this.search_status() === TboStatus.IN_PROGRESS) {
            params['status'] = [TboStatus.OPEN, TboStatus.NOT_YET_APPROVED, TboStatus.ORDERED]
        } else if (this.search_status()) {
            params['status'] = [this.search_status()]
        } else {
            params['status'] = [TboStatus.OPEN]
        }
        return params
    }

    set_search_status(search_status: string) {
        this.search_status(search_status)
        this.tbo_items.requery()
    }

    view() {
        return <div class="c-sales-assigned-stock view">
            <div class="c-filter-group">
                <SearchBar
                    placeholder="Search for products, specs, TBO supplier, tax label or other item tags..."
                    on_submit={this.submit_search.bind(this)}
                    default_search_text={this.tbo_items.search_term()}
                    search_bar_controller={(search_bar_controller: any) => this.search_bar_controller = search_bar_controller}
                    on_get_suggestions$={(filter_text: string) => this.product_management_api.get_simple_product_names(filter_text)}
                />

                {select(this.search_status, TBO_STATUS_SELECTION, {
                    onchange: this.set_search_status.bind(this),
                })}

                <UserDropDown
                    model={[this, 'sales_manager_artkey']}
                    onchange={() => this.tbo_items.requery()}
                />
            </div>

            {this.tbo_items.show_counter()}

            <CollectionTable
                collection={this.tbo_items}
                options={{
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: false,
                    autoscale: true,
                    unique_name: 'assigned_stock_table',
                }}
                view_details={(record: any) => {
                    if (!record.purchase_order.artkey) {
                        const market_data = {
                            bottle_artkey: record.bottle_artkey,
                            current_supplier_artkey: record.supplier.artkey,
                            customs_status: record.case_customs_status,
                        }
                        return <CaseInfoPanel {...market_data} />
                    }
                }}
                columns={[
                    {
                        width: 4,
                        name: 'Sales Order',
                        field: 'sales_order.artkey',
                        transform: (artkey: string) =>
                            link(`/sales-orders/manage/${artkey}`, 'S' + artkey),
                        sort: true,
                    },
                    {
                        width: 4,
                        name: 'Order Date',
                        field: 'sales_order.date',
                        transform: formatDate,
                        sort: true,
                    },
                    {
                        width: 4,
                        name: 'Purchase Date',
                        field: 'purchase_order.date',
                        transform: formatDate,
                        sort: true,
                    },
                    {
                        width: 4,
                        name: 'ETA',
                        field: 'purchase_order.expected_delivery_date',
                        transform: formatDate,
                        sort: true,
                    },
                    {
                        width: 6,
                        name: 'Delivery period',
                        sort: true,
                        field: 'delivery_period',
                        classes: ['number'],
                        transform: (delivery_period: number) =>
                            delivery_period
                                ? `${delivery_period} ${pluralize(delivery_period, 'week', 'weeks')}`
                                : '-',
                    },
                    {
                        width: 10,
                        name: 'Buyer',
                        field: 'sales_order.buyer.name',
                        sort: true,
                        ellipsis: true,
                        function: (record: any) => [
                            <span class="mr-05">{accountIcon(record.sales_order.account)}</span>,
                            record.sales_order.buyer.name,
                        ],
                    },
                    {
                        width: 5,
                        name: 'Status',
                        sort: true,
                        field: 'status',
                    },
                    {
                        width: 5,
                        name: 'PO#',
                        field: 'purchase_order.artkey',
                        transform: maybeMap(purchase_order_link),
                    },
                    {
                        width: 5,
                        name: 'Sales manager',
                        field: 'sales_order.buyer.sales_manager.name',
                    },
                    {
                        width: 1,
                        header: '',
                        name: 'Discover link',
                        function: (record: any) =>
                            <a class="discover-link no-click"
                                target="_blank"
                                href={from_properties(
                                    record.product_name,
                                    record.bottle_volume,
                                    record.bottle_alcohol_percentage,
                                )}
                            >
                                {icon('search.no-click')}
                            </a>,
                    },
                    {
                        width: 1,
                        header: '',
                        name: 'Explore link',
                        function: (record: any) =>
                            <td>
                                {for_product(
                                    icon('fa-compass.fa-lg.no-click'),
                                    record.case_artkey,
                                    record.supplier_artkey,
                                )}
                            </td>,
                    },
                    {
                        width: 12,
                        name: 'Product',
                        sort: true,
                        field: 'product_name',
                        ellipsis: true,
                    },
                    {
                        width: 5,
                        name: 'Category',
                        sort: true,
                        field: 'product_category',
                        ellipsis: true,
                        transform: capitalize,
                    },
                    {
                        width: 3,
                        name: 'Btl / cs',
                        sort: true,
                        field: 'case_number_of_bottles',
                        default_visible: false,
                        classes: ['number'],
                    },
                    {
                        width: 4,
                        name: 'Size',
                        sort: true,
                        field: 'bottle_volume',
                        default_visible: false,
                        function: (record: any) =>
                            <span>{(+record.bottle_volume).toFixed(1)}cl</span>,
                        classes: ['number'],
                    },
                    {
                        width: 4,
                        name: 'Alc %',
                        sort: true,
                        field: 'bottle_alcohol_percentage',
                        default_visible: false,
                        function: (record: any) =>
                            <span>{(+record.bottle_alcohol_percentage).toFixed(1)}%</span>,
                        classes: ['number'],
                    },
                    {
                        width: 3,
                        name: 'Ref',
                        sort: true,
                        default_visible: false,
                        field: 'bottle_refill_status',
                    },
                    {
                        width: 10,
                        name: 'Specs',
                        sort: false,
                        function: (record: any) => {
                            const specs = []
                            specs.push(record.case_number_of_bottles)
                            specs.push((+record.bottle_volume).toFixed(1))
                            specs.push((+record.bottle_alcohol_percentage).toFixed(1))
                            specs.push(record.bottle_refill_status)
                            return specs.join(' / ')
                        },
                    },
                    {
                        width: 4,
                        name: 'GB',
                        sort: true,
                        field: 'case_gift_box_type',
                        default_visible: false,
                        ellipsis: true,
                    },
                    {
                        width: 4,
                        name: 'Tax Label',
                        sort: true,
                        field: 'case_tax_label',
                        default_visible: false,
                        ellipsis: true,
                    },
                    {
                        width: 5,
                        header: 'BBD',
                        name: 'Best before date',
                        sort: true,
                        field: 'case_best_before_date',
                        default_visible: false,
                        transform: maybeMap(formatDate),
                    },
                    {
                        width: 10,
                        name: 'Features',
                        function: (record: any) => {
                            const features = []
                            if (record.case_gift_box_type) {
                                features.push(record.case_gift_box_type)
                            }
                            if (record.case_tax_label) {
                                features.push(record.case_tax_label)
                            }
                            if (record.case_best_before_date) {
                                features.push(`BBD: ${formatDate(record.case_best_before_date)}`)
                            }
                            if (record.case_damages) {
                                features.push(record.case_damages.replace(', ', ' / '))
                            }
                            if (record.case_general_tags) {
                                features.push(record.case_general_tags.replace(', ', ' / '))
                            }
                            if (record.case_pack_size) {
                                features.push(record.case_pack_size)
                            }
                            if (record.case_packaging) {
                                features.push(record.case_packaging)
                            }
                            return features.join(' / ')
                        },
                    },
                    {
                        width: 10,
                        name: 'Purchase / cs',
                        sort: true,
                        field: 'price_per_case',
                        classes: ['price'],
                        function: (record: any) =>
                            <Amount
                                amount={+record.price_per_case}
                                excise={+record.excise_per_case}
                                currency={$s.currencies.default}
                            />,
                    },
                    {
                        width: 10,
                        name: 'Sales / cs',
                        sort: true,
                        field: 'price_per_case',
                        classes: ['price'],
                        function: (record: any) =>
                            <Amount
                                amount={+record.price_per_case}
                                excise={+record.excise_per_case}
                                currency={$s.currencies.default}
                            />,
                    },
                    {
                        width: 2,
                        name: 'Number of cases in purchase',
                        header: 'Number of cases in purchase',
                        sort: true,
                        field: 'purchase_order_item.number_of_cases',
                        classes: ['number'],
                    },
                    {
                        width: 2,
                        name: 'Number of cases in sales',
                        header: 'Number of cases in sales',
                        sort: true,
                        field: 'number_of_cases',
                        classes: ['number'],
                    },
                    {
                        width: 3,
                        header: 'Cus.',
                        name: 'Customs status',
                        sort: true,
                        field: 'case_customs_status',
                    },
                ]}
            />
        </div>
    }
}

function purchase_order_link(artkey: string) {
    return <Link
        href={`/purchase-orders/manage/P${artkey}`}
    >
        P{artkey}
    </Link>
}
