/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {config, notifier} from '@bitstillery/common/app'

import {ProcessManageOffer} from './processes'

import api_ls from '@/api'
import {button_with_icon} from '@/components/_buttons'
import confirmation from '@/components/confirmation'
import inputs from '@/components/inputs'
import {format_date_html5} from '@/_utils'
import {Offer, OfferType, Priority} from '@/models/offers'
import {FileDropArea} from '@/components/file_drop_area'
import {IncotermsDropDown} from '@/components/incoterms'
import {IncotermsDropDownData} from '@/factserver_api/incoterms_api'
import {$m} from '@/app'

const DEFAULT_NEW_ARRIVALS_PERIOD = 7

class OfferTemplate {
    static NORMAL = ''
    static NEW_ARRIVALS = 'new_arrivals'
    static NEW_ARRIVALS_DRANKGIGANT = 'new_arrivals_drankgigant'

    static all = {
        [OfferTemplate.NORMAL]: 'Normal',
        [OfferTemplate.NEW_ARRIVALS]: 'New arrivals',
        [OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]: 'Drankgigant.nl BV',
    }
}

class OfferTemplateSettings {
    static OFFER_REMARK = 'Created with template'

    static help_text = {
        [OfferTemplate.NORMAL]: 'Select this for creating a custom offer as usual.',
        [OfferTemplate.NEW_ARRIVALS]: 'Creates an offer with products in stock that are either new had a recent price change.',
        [OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]: 'Creates an offer for Drankgigant.nl BV, with products in stock that are either new or had a recent price change.',
    }

    static redirect_to = {
        [OfferTemplate.NORMAL]: 'relations',
        [OfferTemplate.NEW_ARRIVALS]: 'relations',
        [OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]: 'email/create',
    }

    static save_button_text = {
        [OfferTemplate.NORMAL]: 'Create offer & select relations',
        [OfferTemplate.NEW_ARRIVALS]: 'Create and proceed to select relations',
        [OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]: 'Create and directly proceed to compose email',
    }

    static skip_select_products = {
        [OfferTemplate.NORMAL]: false,
        [OfferTemplate.NEW_ARRIVALS]: true,
        [OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]: true,
    }

    static title = {
        [OfferTemplate.NORMAL]: '',
        [OfferTemplate.NEW_ARRIVALS]: 'New arrivals',
        [OfferTemplate.NEW_ARRIVALS_DRANKGIGANT]: 'New arrivals for Drankgigant.nl BV',
    }
}

export class OfferUpsert extends MithrilTsxComponent<unknown> {
    create: any
    saving: any
    offer: any
    published: any
    enable_portal_popup: any
    portal_popup_image_data: string | null
    portal_popup_image_filename: string | null
    use_default_incoterm: any
    offer_template: any
    new_arrivals_period: any
    offer_artkey: string
    email_batch_artkey: string

    constructor() {
        super()
        this.create = window.prop(true)
        this.saving = window.prop(false)

        this.offer = window.prop(new Offer())
        this.offer().incoterm('EXW')
        this.published = window.prop(false)
        this.enable_portal_popup = window.prop(false)
        this.portal_popup_image_data = null
        this.portal_popup_image_filename = null
        this.use_default_incoterm = window.prop(true)

        this.offer_template = window.prop(OfferTemplate.NORMAL)
        this.new_arrivals_period = window.prop(DEFAULT_NEW_ARRIVALS_PERIOD)

        this.offer_artkey = m.route.param('artkey')
        if (this.offer_artkey) {
            this.query_offer(this.offer_artkey)
            this.create(false)
        }

        this.email_batch_artkey = m.route.param('email_batch')
    }

    offer_type_help_text(offer_type: string) {
        if (offer_type === OfferType.NORMAL) {
            return 'Select this for offers where not every product is a special offer, such as the weekly offer.'
        } else if (offer_type === OfferType.SPECIAL) {
            return 'Select this for (short) offers in which every product is a special offer.'
        }
    }

    priority_help_text(priority: number) {
        if (priority === Priority.UNIQUE) {
            return 'Select this for the most important offers. Unique offers always override bulk offers.'
        } else if (priority === Priority.BULK) {
            return 'Select this for less important offers. Bulk offers are always overridden by unique offers.'
        }
    }

    query_offer(artkey: string) {
        api_ls.callAndThen('offer.get_offer', {artkey: artkey}, {
            success: (resp: any) => {
                this.offer(new Offer(resp.result))
                if (this.offer().published_timestamp()) {
                    this.published(true)
                }
                if (this.offer().incoterm()) {
                    this.use_default_incoterm(false)
                }
                this.enable_portal_popup(this.offer().portal_popup_artkey() !== '')
            },
            failure: () => {
                notifier.notify('Unknown offer.', 'danger')
                m.route.set('/offer/offers')
            },
        })
    }

    save(e: Event) {
        e.preventDefault()

        if (this.offer().published_timestamp() && !this.published()) {
            confirmation.show({
                title: 'Unpublishing offer',
                message: 'You are unpublishing an already published offer. This can change prices for all relations that were selected for this offer. Are you sure you want to continue?',
                unique_name: 'unpublish_confirm',
                onconfirm: () => {
                    this.save_offer()
                },
            })
        } else {
            this.save_offer()
        }
    }

    save_offer() {
        const today = new Date()
        const start_date = new Date(this.offer().start_date())
        const end_date = new Date(this.offer().end_date())

        if (this.create() && (end_date < new Date(today.setDate(today.getDate() - 1)))) {
            notifier.notify('Could not save the offer; the end date may not be in the past.', 'danger')
            return
        }

        if (start_date > end_date) {
            notifier.notify('Could not save the offer; the start date may not be later than the end date.', 'danger')
            return
        }

        if (this.use_default_incoterm()) {
            this.offer().incoterm('')
            this.offer().incoterm_location('')
        }

        if (this.offer().incoterm() && !this.offer().incoterm_location()) {
            notifier.notify('Could not save the offer; when entering an incoterm, also enter an incoterm location.', 'danger')
            return
        }

        if (this.offer().incoterm_location() && !this.offer().incoterm()) {
            notifier.notify('Could not save the offer; when entering an incoterm location, also enter an incoterm.', 'danger')
            return
        }

        if (!this.saving()) {
            this.saving(true)
            const data: any = {
                artkey: this.offer().artkey(),
                title: this.offer().title(),
                remark: this.offer().remark(),
                start_date: this.offer().start_date(),
                end_date: this.offer().end_date(),
                incoterm: this.offer().incoterm(),
                incoterm_location: this.offer().incoterm_location(),
                offer_type: this.offer().offer_type(),
                priority: this.offer().priority(),
                published: this.published(),
                enable_portal_popup: this.enable_portal_popup(),
                portal_popup_title: this.offer().portal_popup().title(),
                portal_popup_text: this.offer().portal_popup().text(),
                portal_popup_image: this.portal_popup_image_data,
                portal_popup_image_filename: this.portal_popup_image_filename,
            }

            if (this.create()) {
                data.template = this.offer_template()
                if ([OfferTemplate.NEW_ARRIVALS, OfferTemplate.NEW_ARRIVALS_DRANKGIGANT].includes(this.offer_template())) {
                    data.new_arrivals_period = this.new_arrivals_period()
                }
            }

            api_ls.callAndThen('offer.create_or_update_offer', data, {
                success: (resp: any) => {
                    $m.common.observable.broadcast('offer_updated')
                    if (this.create()) {
                        notifier.notify(`Successfully created new offer "${resp.result.title}".`, 'success')
                    } else {
                        notifier.notify(`Successfully updated offer "${resp.result.title}".`, 'success')
                    }

                    let redirect_url = `/offer/offers/${resp.result.artkey}/${OfferTemplateSettings.redirect_to[this.offer_template()]}`
                    if (this.email_batch_artkey) {
                        redirect_url += `/?email_batch=${this.email_batch_artkey}`
                    }
                    if (OfferTemplateSettings.skip_select_products[this.offer_template()]) {
                        if (this.email_batch_artkey) {
                            redirect_url += '&'
                        } else {
                            redirect_url += '/?'
                        }
                        redirect_url += 'skip_select_products=true'
                    }
                    m.route.set(redirect_url)
                },
                failure: (resp: any) => {
                    notifier.notify(resp.message, 'danger')
                    this.saving(false)
                },
            })
        }
    }

    save_button_text() {
        if (this.create()) {
            return OfferTemplateSettings.save_button_text[this.offer_template()]
        } else {
            return 'Save and proceed to select relations'
        }
    }

    select_offer_template(value?: string) {
        if (value !== undefined) {
            this.offer_template(value)

            if (value === OfferTemplate.NORMAL) {
                this.offer(new Offer())
            } else if ([OfferTemplate.NEW_ARRIVALS, OfferTemplate.NEW_ARRIVALS_DRANKGIGANT].includes(this.offer_template())) {
                this.offer().title(OfferTemplateSettings.title[value])
                this.offer().remark(OfferTemplateSettings.OFFER_REMARK)
                this.offer().offer_type(OfferType.NORMAL)
                this.set_dates()
            }
        } else {
            return this.offer_template()
        }
    }

    set_dates() {
        const today = new Date()
        const end_of_period = new Date()
        end_of_period.setDate(today.getDate() + this.new_arrivals_period())
        this.offer().start_date(format_date_html5(today))
        this.offer().end_date(format_date_html5(end_of_period))
    }

    set_new_arrivals_period(value?: number) {
        if (value !== undefined) {
            this.new_arrivals_period(value)
            this.set_dates()
        } else {
            return this.new_arrivals_period()
        }
    }

    handle_filedrop(file: File, contents: string) {
        this.portal_popup_image_filename = file.name
        this.portal_popup_image_data = contents
        m.redraw()
    }

    view() {
        return <div class="c-process-new-offer-step-1 view process">
            <ProcessManageOffer
                active="details"
                context={{
                    email_batch_artkey: this.email_batch_artkey,
                    offer_title: this.offer().title(),
                    offer_artkey: this.offer_artkey,
                }}
            />

            <div class="step-content">
                <form class="flex-form" onsubmit={this.save.bind(this)}>
                    <div class="fieldset">
                        {this.create() &&
                            <div class="field">
                                <label>Offer template</label>
                                {inputs.radio(this.select_offer_template.bind(this),
                                    Object.entries(OfferTemplate.all).map(([value, description]) => ({
                                        value,
                                        description,
                                        title: OfferTemplateSettings.help_text[value],
                                    })),
                                )}
                            </div>
                        }

                        {this.create() && [OfferTemplate.NEW_ARRIVALS, OfferTemplate.NEW_ARRIVALS_DRANKGIGANT].includes(this.offer_template()) &&
                            <div class="field">
                                <label>New arrivals period</label>
                                {inputs.number(this.set_new_arrivals_period.bind(this), {required: true})}
                                <div class="help">The number for days for the stock age and the latest price change of this offer's products.</div>
                            </div>
                        }

                        {inputs.text(this.offer().title, {
                            label: 'Title',
                            required: true,
                            placeholder: 'Title',
                        })}

                        {inputs.text(this.offer().remark, {
                            label: 'Remark',
                            placeholder: 'Remark',
                        })}

                        <div class="field-group">
                            {inputs.date(this.offer().start_date, {
                                label: 'Start date',
                                required: true,
                            })}

                            {inputs.date(this.offer().end_date, {
                                label: 'End date',
                                required: true,
                            })}
                        </div>

                        {inputs.checkbox(this.published, {
                            help: 'When an offer is published, the prices in this offer are updated in the Portal for the selected relations.',
                            label: 'Published',
                        })}

                        <div class="field">
                            <label>Incoterm to use</label>
                            {inputs.radio(this.use_default_incoterm, [
                                {
                                    value: true,
                                    description: 'Default',
                                    title: 'Select this to use the default incoterm that is configured for each relation that you select in this offer.',
                                },
                                {
                                    value: false,
                                    description: 'Override',
                                    title: 'Select this to define a custom incoterm that applies to all relations that you select in this offer.',
                                },
                            ])}
                        </div>

                        {!this.use_default_incoterm() &&
                            <div class="field">
                                <label>Incoterm</label>
                                <IncotermsDropDown
                                    get_all_for_drop_down_response$={IncotermsDropDownData.incoterms()}
                                    model={[this.offer(), 'incoterm']}
                                />
                            </div>
                        }

                        {!this.use_default_incoterm() &&
                            <div class="field">
                                <label>Incoterm location</label>
                                {inputs.text(this.offer().incoterm_location, {
                                    placeholder: 'Incoterm location',
                                })}
                            </div>
                        }

                        <div class="field">
                            <label>Offer type</label>
                            {inputs.radio(this.offer().offer_type,
                                Object.entries(OfferType.offer_type_options).map(([value, description]) => ({
                                    value,
                                    description,
                                    title: this.offer_type_help_text(value),
                                })),
                            )}
                        </div>

                        <div class="field">
                            <label>Priority</label>
                            {inputs.radio(this.offer().priority,
                                Object.entries(Priority.all).map(([value, description]) => ({
                                    value: +value,
                                    description,
                                    title: this.priority_help_text(+value),
                                })),
                            )}
                        </div>

                        <div class="field">
                            {inputs.checkbox(this.enable_portal_popup, {
                                label: 'Enable portal popup',
                            })}
                        </div>

                        {this.enable_portal_popup() && [
                            <div class="field">
                                <label>Popup title</label>
                                {inputs.text(this.offer().portal_popup().title, {
                                    required: true,
                                    placeholder: 'Popup title',
                                })}
                            </div>,

                            <div class="field">
                                <label>Popup text</label>
                                {inputs.textarea(this.offer().portal_popup().text, {
                                    placeholder: 'Popup text',
                                })}
                            </div>,

                            <div class="field">
                                <label>Popup image</label>
                                <FileDropArea
                                    onupload={(file: File, contents: string) => this.handle_filedrop(file, contents)}
                                />

                                {this.offer().portal_popup().image_s3_key() && !this.portal_popup_image_filename &&
                                    <img
                                        src={`${config.product_photo_host}/${this.offer().portal_popup().image_s3_key()}`}
                                        width={200}
                                    />
                                }

                                {this.offer().portal_popup().image_s3_key() && this.portal_popup_image_filename && [
                                    <label>Replace old image</label>,
                                    <img
                                        src={`${config.product_photo_host}/${this.offer().portal_popup().image_s3_key()}`}
                                        width={200}
                                    />,
                                    <label class="col-sm-2">With new image</label>,
                                    <img
                                        src={this.portal_popup_image_data}
                                        width={200}
                                    />,
                                ]}

                                {!this.offer().portal_popup().image_s3_key() && this.portal_popup_image_filename && [
                                    <label>New image</label>,
                                    <img
                                        src={this.portal_popup_image_data}
                                        width={200}
                                    />,
                                ]}
                            </div>,
                        ]}
                    </div>

                    {button_with_icon(this.save_button_text(), 'arrow-right', {
                        class: 'btn-success btn-submit',
                        disabled: this.saving(),
                    })}
                </form>
            </div>
        </div>
    }
}
