/** llm:production */
import m from 'mithril'
import {filter, orList, find} from 'prelude-ls'
import {Button, ButtonGroup, DataCard, Spinner} from '@bitstillery/common/components'
import {$t, notifier} from '@bitstillery/common/app'

import {formatDate} from '@/_utils'
import * as api from '@/api'
import {Popover} from '@/components/popover/popover'
import * as inputs from '@/components/inputs'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import * as discoverLink from '@/market/pricelists/components/discover_link'
import * as confirmation from '@/components/confirmation'
import * as fixedTableHeader from '@/components/table/fixed_header'
import {IncotermsDropDown} from '@/components/incoterms'
import {IncotermsDropDownData} from '@/factserver_api/incoterms_api'
import {DestinationsDropDown} from '@/components/destinations'
import {DestinationsDropDownData} from '@/factserver_api/destinations_api'
import {Item, ItemMutation, ItemMutationPart, StockSearchMode} from '@/models/stock'
import {$m, $s} from '@/app'

export class StockMoveToWarehouse {
    mutation: any
    edit: any
    parts_to_delete: any
    is_loading: any
    is_loading_item_mutation: any
    target_warehouse_artkey: any
    item_mutation_reference: string
    stock: any
    search_input_ctrl: any

    constructor() {
        this.mutation = window.prop(new ItemMutation())
        this.mutation().incoterm('EXW')
        this.edit = window.prop(false)
        this.parts_to_delete = window.prop([])
        this.is_loading = window.prop(false)
        this.is_loading_item_mutation = window.prop(false)

        this.target_warehouse_artkey = window.prop('')

        this.item_mutation_reference = m.route.param('reference')

        if (this.item_mutation_reference) {
            this.edit(true)
            this.get_mutation(this.item_mutation_reference)
        }

        this.stock = new Collection({
            api_function_name: 'stock.get_stock_records',
            filter_function: this.is_match,
            query_limit: 50,
            sort_order: [
                {name: 'bottle_volume', direction: 'desc'},
                {name: 'bottle_alcohol_percentage', direction: 'desc'},
                {name: 'bottle_refill_status', direction: 'desc'},
                {name: 'artkey', direction: 'asc'},
            ],
            default_sort_by: 'purchase_order_reference',
            default_sort_order: 'desc',
            additional_params: this.additional_params,
            data_key: 'stock_items',
        })

        if (!this.stock.mode) {
            this.stock.mode = window.prop(StockSearchMode.INSTOCK_ONLY)
        }

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.stock,
            placeholder: 'Search for products, specs, order reference, lot or warehouse...',
        })
    }

    oncreate() {
        this.stock.init()
    }

    get_mutation = (item_mutation_reference: string) => {
        this.is_loading_item_mutation(true)
        const data = {
            item_mutation_reference: item_mutation_reference,
        }
        api.callAndThen('stock.get_item_mutation', data, {
            success: (resp: any) => {
                this.mutation(new ItemMutation(resp.result))
            },
            final: () => {
                this.is_loading_item_mutation(false)
            },
        })
    }

    source_warehouse_artkey = () => {
        if (this.mutation().sources().length === 0) {
            return ''
        } else {
            return this.mutation().sources()[0].warehouse_artkey()
        }
    }

    toggle_mode = (value: any) => {
        this.stock.mode(value)
        this.stock.requery()
    }

    additional_params = () => {
        return {mode: this.stock.mode()}
    }

    is_match = (stock: any, term: string, additional_params: any) => {
        if (additional_params.mode === StockSearchMode.AVAILABLE_ONLY && stock.number_of_cases_available <= 0) {
            return false
        } else if (additional_params.mode === StockSearchMode.INSTOCK_ONLY && stock.number_of_cases_in_stock <= 0) {
            return false
        } else if (additional_params.mode === StockSearchMode.AVAILABLE_OR_INSTOCK && (stock.number_of_cases_in_stock <= 0 && stock.number_of_cases_available <= 0)) {
            return false
        }

        return orList([
            stock.product_name.toLowerCase().indexOf(term) > -1,
            stock.product_category_name.toLowerCase().indexOf(term) > -1,
            +stock.bottle_volume === +term,
            +stock.bottle_alcohol_percentage === +term,
            stock.bottle_refill_status.toLowerCase() === term,
            stock.case_gift_box_type?.toLowerCase().indexOf(term) > -1,
            stock.case_tax_label?.toLowerCase().indexOf(term) > -1,
            stock.warehouse_name.toLowerCase().indexOf(term) > -1,
            stock.purchase_order_artkey === +term,
            stock.purchase_order_reference.toLowerCase() === term,
            stock.item_lot.toLowerCase() === term,
            stock.item_reference === term,
        ])
    }

    in_items_to_move = (item_reference: string) => {
        const result = find((part: any) => {
            return find((source: any) => source.reference() === item_reference, part.sources())
        }, this.mutation().parts())

        return result !== undefined
    }

    add_item = (item_reference: string) => {
        if (this.in_items_to_move(item_reference)) {
            notifier.notify('Item already on list of items to move.', 'info')
            return
        }

        const data = {
            item_reference: item_reference,
        }

        api.call2('stock.get_item', data, (resp: any) => {
            const source_item = new Item(resp.result)

            if (this.source_warehouse_artkey() !== '' && this.source_warehouse_artkey() !== source_item.warehouse_artkey()) {
                notifier.notify('Item is not from the same warehouse as the rest', 'warning')
                return
            }

            const number_of_cases_in_stock = resp.result.number_of_cases_in_stock
            resp.result.number_of_cases_in_stock = 0
            const leftover_item = new Item(resp.result)

            resp.result.warehouse = {artkey: this.mutation().target_warehouse_artkey()}
            resp.result.lot = ''
            resp.result.entry_date = ''
            resp.result.number_of_cases = number_of_cases_in_stock
            resp.result.number_of_shipped_cases = 0
            const target_item = new Item(resp.result)

            const part = new ItemMutationPart({})
            part.item_mutation(this.mutation())
            part.item_mutation_artkey(this.mutation().artkey)
            part.sources([source_item])
            part.targets([leftover_item, target_item])

            this.mutation().parts().push(part)
        })
    }

    create_or_update_warehouse_move = () => {
        if (this.mutation().parts().length === 0) {
            if (this.mutation().artkey() === '') {
                notifier.notify('No items added yet.', 'danger')
                return
            } else {
                confirmation.show({
                    title: 'Delete mutation',
                    message: 'No items are left, do you want to delete this mutation?',
                    onconfirm: () => {
                        const data = {
                            item_mutation_artkey: this.mutation().artkey(),
                        }
                        api.call('stock.delete_warehouse_move_mutation', data, (resp: any) => {
                            if (resp.success) {
                                notifier.notify('Successfully deleted mutation.', 'success')
                                $m.common.observable.broadcast('mutations_updated')
                                m.route.set('/stock/mutations/manage')
                            } else {
                                if (resp.message) {
                                    notifier.notify(resp.message, 'danger')
                                } else {
                                    $m.common.generic_error_handler()
                                }
                            }
                        })
                    },
                    unique_name: 'warehouse_move_mutation_delete_confirmation',
                })
                return
            }
        }

        const items = filter((part: any) => part.target_item(), this.mutation().parts()).map((part: any) => ({
            source_item_artkey: part.source_item().artkey(),
            cases_to_move: part.target_item().number_of_cases(),
        }))

        this.is_loading(true)
        const data = {
            user_artkey: $s.identity.artkey,
            item_mutation_artkey: this.mutation().artkey(),
            target_warehouse_artkey: this.mutation().target_warehouse_artkey(),
            expected_delivery_date: this.mutation().expected_delivery_date(),
            description: this.mutation().description(),
            parts_to_delete: this.parts_to_delete(),
            items: items,
            incoterm: this.mutation().incoterm(),
            incoterm_location: this.mutation().incoterm_location(),
            number_of_block_pallets: this.mutation().number_of_block_pallets(),
            number_of_euro_pallets: this.mutation().number_of_euro_pallets(),
        }

        api.callAndThen('stock.create_or_update_warehouse_move_mutation', data, {
            success: (resp: any) => {
                $m.common.observable.broadcast('stock_updated')
                const is_created = this.mutation().artkey() === ''

                this.parts_to_delete([])
                this.mutation(new ItemMutation(resp.result))

                const created_or_updated = is_created ? 'created' : 'updated'
                notifier.notify(`Successfully ${created_or_updated} warehouse move mutation.`, 'success')
                $m.common.observable.broadcast('mutations_updated')

                if (is_created) {
                    const reference = this.mutation().reference()
                    m.route.set(`/stock/mutations/manage/${reference}`)
                }
            },
            failure: (resp: any) => {
                notifier.notify(resp.message, 'danger')
            },
            final: () => {
                this.is_loading(false)
            },
        })
    }

    delete_part = (part: any) => {
        if (part.artkey()) {
            this.parts_to_delete().push(part.artkey())
        }
        this.mutation().parts(this.mutation().parts().filter((p: any) => p !== part))
    }

    view() {
        return (
            <div class="c-move-to-warehouse view">
                <div class="btn-toolbar">
                    <Button
                        active={false}
                        icon="back"
                        onclick={() => m.route.set('/stock/mutations/manage/' + this.mutation().reference())}
                        variant="toggle"
                    />
                </div>

                {this.edit() && (
                    <DataCard
                        className="box-small mb-2"
                        model={{
                            data: [
                                {label: 'Reference', value: this.mutation().reference()},
                                {label: 'Creation Date', value: formatDate(this.mutation().created_on())},
                                {label: 'Created by', value: this.mutation().user().profile().name()},
                            ],
                        }}
                        type="dense"
                    />
                )}

                <form>
                    <div class="fieldset-group">
                        <div class="fieldset">
                            <div class="field">
                                <label>Target Warehouse</label>
                                {/* REQUIRED FIELD */}
                                <DestinationsDropDown
                                    get_all_for_drop_down_response$={DestinationsDropDownData.destinations()}
                                    model={[this, 'target_warehouse_artkey']}
                                    onchange={(artkey: string) => {
                                        this.mutation().target_warehouse_artkey(artkey)
                                        if (this.target_warehouse_artkey()) {
                                            DestinationsDropDownData.get(+this.target_warehouse_artkey()).subscribe(
                                                (response: any) => {
                                                    this.mutation().target_warehouse().name = response.name
                                                },
                                            )
                                        }
                                    }}
                                />
                            </div>

                            <div class="field">
                                <label for="incoterm">Incoterm</label>
                                {/* REQUIRED FIELD */}
                                <IncotermsDropDown
                                    get_all_for_drop_down_response$={IncotermsDropDownData.incoterms()}
                                    model={[this.mutation(), 'incoterm']}
                                />
                            </div>
                            <div class="field">
                                <label for="incoterm_location">Incoterm location</label>
                                <input
                                    id="incoterm_location"
                                    class="form-control"
                                    type="text"
                                    value={this.mutation().incoterm_location()}
                                    oninput={(ev: Event) => this.mutation().incoterm_location((ev.target as HTMLInputElement).value)}
                                />
                            </div>
                        </div>

                        <div class="fieldset">
                            <div class="field-group">
                                <div class="field">
                                    <label>Number of euro pallets</label>
                                    {inputs.number(this.mutation().number_of_euro_pallets)}
                                </div>
                                <div class="field">
                                    <label>Number of block pallets</label>
                                    {inputs.number(this.mutation().number_of_block_pallets)}
                                </div>
                            </div>
                            <div class="field">
                                <label>Expected delivery date</label>
                                {inputs.date(this.mutation().expected_delivery_date)}
                            </div>
                            <div class="field">
                                <label>Description</label>
                                {inputs.textarea(this.mutation().description)}
                            </div>
                        </div>
                    </div>

                    <div class="panel panel-success">
                        <div class="panel-heading">
                            <div class="panel-title">{this.edit() ? 'Add extra items' : 'Items to move'}</div>
                        </div>
                        <div class="panel-body">
                            {this.is_loading_item_mutation() ? (
                                <Spinner />
                            ) : this.mutation().parts().length > 0 ? (
                                <table class="table">
                                    <thead class="thead-default stock-table">
                                        <tr>
                                            <th>#</th>
                                            <th>Source warehouse</th>
                                            <th>Lot #</th>
                                            <th>Product</th>
                                            <th class="number">Btl / cs</th>
                                            <th class="number">Size / Alc % / Ref</th>
                                            <th class="number">Cases</th>
                                            <th class="number">Stock</th>
                                            <th>Target warehouse</th>
                                            <th>Cases to move</th>
                                            <th>Leftover cases</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {this.mutation().parts().filter((part: any) => part.target_item()).map((part: any) => {
                                        let number_of_cases_in_stock = part.source_item().number_of_cases_in_stock()
                                        if (part.artkey() !== '') {
                                            number_of_cases_in_stock = part.source_item().number_of_cases() -
                                                (part.leftover_item() ? part.leftover_item().number_of_shipped_cases() : 0)
                                        }

                                        const purchase_order = part.source_item().purchase_order_item().purchase_order()
                                        return (
                                            <tbody class="table-row">
                                                <tr>
                                                    <td>
                                                        <m.route.Link
                                                            target="_blank"
                                                            href={`/purchase-orders/manage/${purchase_order.artkey()}`}
                                                        >
                                                            {purchase_order.reference()}
                                                        </m.route.Link>
                                                    </td>
                                                    <td>{part.source_item().warehouse_artkey() ? part.source_item().warehouse().name() : ''}</td>
                                                    <td><samp>{part.source_item().lot()}</samp></td>
                                                    <td>{part.source_item().bottle().product().name()}</td>
                                                    <td class="number">{part.source_item().number_of_bottles_per_case()}</td>
                                                    <td>
                                                        {[
                                                            part.source_item().bottle().display_volume(),
                                                            part.source_item().bottle().display_alcohol_percentage(),
                                                            part.source_item().bottle().refill_status(),
                                                        ].join(' ')}
                                                    </td>
                                                    <td class="number">{part.source_item().number_of_cases()}</td>
                                                    <td class="number">{number_of_cases_in_stock}</td>
                                                    <td>{typeof this.mutation().target_warehouse().name === 'function' ? this.mutation().target_warehouse().name() : ''}</td>
                                                    <td>
                                                        {inputs.number(part.target_item().number_of_cases, {
                                                            min: 1,
                                                            max: number_of_cases_in_stock,
                                                            required: true,
                                                        })}
                                                    </td>
                                                    <td>{number_of_cases_in_stock - part.target_item().number_of_cases()}</td>
                                                    <td class="actions">
                                                        <div class="actions-group">
                                                            <ButtonGroup>
                                                                <Button
                                                                    icon="trash"
                                                                    onclick={() => this.delete_part(part)}
                                                                    type="danger"
                                                                />
                                                            </ButtonGroup>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            ) : (
                                'No items added yet.'
                            )}
                        </div>
                    </div>

                    <Button
                        className="mb-2"
                        disabled={this.is_loading()}
                        onclick={() => this.create_or_update_warehouse_move()}
                        text={this.edit() ? 'Save Mutation' : 'Create Mutation'}
                        type="success"
                    />
                </form>

                <div class="c-filter-group">
                    {SearchInput.view(this.search_input_ctrl)}
                    {inputs.select(this.stock.mode, StockSearchMode.options, {onchange: this.toggle_mode, empty_option: false})}
                </div>

                {fixedTableHeader.without_buttons(
                    <table class="table search-table clickable">
                        <colgroup>
                            <col style="width: 4%" />
                            <col style="width: 5%" />
                            <col style="width: 6%" />
                            <col style="width: 9%" />
                            <col style="width: 2%" />
                            <col style="width: 10%" />
                            <col style="width: 3%" />
                            <col style="width: 4%" />
                            <col style="width: 4%" />
                            <col style="width: 3%" />
                            <col style="width: 5%" />
                            <col style="width: 5%" />
                            <col style="width: 3%" />
                            <col style="width: 3%" />
                            <col style="width: 3%" />
                            <col style="width: 3%" />
                            <col style="width: 3%" />
                        </colgroup>

                        <thead class="thead-default stock-table">
                            <tr>
                                <th onclick={() => this.stock.sort('purchase_order_reference')}>
                                    # {this.stock.sort_icon('purchase_order_reference')}
                                </th>
                                <th onclick={() => this.stock.sort('date')}>
                                    Intake {this.stock.sort_icon('date')}
                                </th>
                                <th onclick={() => this.stock.sort('warehouse_name')}>
                                    Warehouse {this.stock.sort_icon('warehouse_name')}
                                </th>
                                <th onclick={() => this.stock.sort('item_lot')}>
                                    Lot # {this.stock.sort_icon('item_lot')}
                                </th>
                                <th onclick={() => this.stock.sort('product_name')}>
                                    Product {this.stock.sort_icon('product_name')}
                                </th>
                                <th class="number" onclick={() => this.stock.sort('item_number_of_bottles_per_case')}>
                                    Btl / cs {this.stock.sort_icon('item_number_of_bottles_per_case')}
                                </th>
                                <th class="number" onclick={() => this.stock.sort('bottle_volume')}>
                                    Size {this.stock.sort_icon('bottle_volume')}
                                </th>
                                <th class="number" onclick={() => this.stock.sort('bottle_alcohol_percentage')}>
                                    Alc % {this.stock.sort_icon('bottle_alcohol_percentage')}
                                </th>
                                <th onclick={() => this.stock.sort('bottle_refill_status')}>
                                    Ref {this.stock.sort_icon('bottle_refill_status')}
                                </th>
                                <th onclick={() => this.stock.sort('case_gift_box_type')}>
                                    GB {this.stock.sort_icon('item_gift_box_type')}
                                </th>
                                <th onclick={() => this.stock.sort('case_tax_label')}>
                                    Tax label {this.stock.sort_icon('item_tax_label')}
                                </th>
                                <th class="number" onclick={() => this.stock.sort('item_number_of_cases_in_purchase')}>
                                    P.O. {this.stock.sort_icon('item_number_of_cases_in_purchase')}
                                </th>
                                <th class="number" onclick={() => this.stock.sort('item_number_of_cases_in_stock')}>
                                    Stock {this.stock.sort_icon('item_number_of_cases_in_stock')}
                                </th>
                                <th class="number" onclick={() => this.stock.sort('item_number_of_cases_in_sales')}>
                                    S.O. {this.stock.sort_icon('item_number_of_cases_in_sales')}
                                </th>
                                <th class="number" onclick={() => this.stock.sort('item_number_of_cases_available')}>
                                    Avail. {this.stock.sort_icon('item_number_of_cases_available')}
                                </th>
                                <th onclick={() => this.stock.sort('case_customs_status')}>
                                    Cus. {this.stock.sort_icon('case_customs_status')}
                                </th>
                                <th></th>
                            </tr>
                        </thead>

                        {this.stock.search_result().map((stock_record: any) => (
                            <tbody class="table-row">
                                <tr onclick={this.toggle_view_details}>
                                    <td>
                                        <m.route.Link
                                            target="_blank"
                                            href={`/purchase-orders/manage/${stock_record.purchase_order_artkey}`}
                                        >
                                            {stock_record.purchase_order_reference}
                                        </m.route.Link>
                                    </td>
                                    <td>{formatDate(stock_record.item_entry_date) || '-'}</td>
                                    <td class="ellipsis"><span>{stock_record.warehouse_name}</span></td>
                                    <td>
                                        {stock_record.item_lot ? (
                                            <samp>{stock_record.item_lot}</samp>
                                        ) : stock_record.item_is_target_in ? (
                                            <Popover
                                                title="Warehouse move"
                                                content={<div>Part of <a href={`#!/stock/mutations/manage/${stock_record.item_mutation_reference}`} target="_blank">mutation {stock_record.item_mutation_reference}</a></div>}
                                            >
                                                <span class="glyphicon glyphicon-plane"></span>
                                            </Popover>
                                        ) : (
                                            <Popover
                                                title="Purchase order"
                                                content="This is a purchase order item"
                                            >
                                                <span class="glyphicon glyphicon-shopping-cart"></span>
                                            </Popover>
                                        )}
                                    </td>
                                    <td class="ellipsis"><span>{stock_record.product_name}</span></td>
                                    <td class="number">{stock_record.item_number_of_bottles_per_case}</td>
                                    <td class="number">{(+stock_record.bottle_volume).toFixed(1)}cl</td>
                                    <td class="number">{(+stock_record.bottle_alcohol_percentage).toFixed(1)}%</td>
                                    <td>{stock_record.bottle_refill_status}</td>
                                    <td>{stock_record.case_gift_box_type}</td>
                                    <td>{stock_record.case_tax_label}</td>
                                    <td class="number">{stock_record.item_number_of_cases_in_purchase}</td>
                                    <td class="number">{stock_record.item_number_of_cases_in_stock || '-'}</td>
                                    <td class="number">{stock_record.item_number_of_cases_in_sales}</td>
                                    <td class="number">{stock_record.item_number_of_cases_available}</td>
                                    <td>{stock_record.case_customs_status}</td>
                                    <td class="actions">
                                        <div class="actions-group">
                                            <ButtonGroup>
                                                <Button
                                                    icon={this.in_items_to_move(stock_record.item_reference) ? 'save' : 'plus'}
                                                    tip="Add to 'Items to move list'"
                                                    type={this.in_items_to_move(stock_record.item_reference) ? 'info' : 'default'}
                                                    onclick={() => this.add_item(stock_record.item_reference)}
                                                />
                                                <Button
                                                    icon="search"
                                                    onclick={() => window.open(discoverLink.from_properties(
                                                        stock_record.product_name,
                                                        stock_record.bottle_volume,
                                                        stock_record.bottle_alcohol_percentage,
                                                    ))}
                                                    type="info"
                                                />
                                            </ButtonGroup>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>,
                )}

                {!this.stock.search_result().length && !this.stock.loading() && (
                    'No results found.'
                )}
                {this.stock.loading() ? (
                    <Spinner />
                ) : this.stock.can_show_more_items() && (
                    <Button
                        icon="chevronRightDouble"
                        onclick={() => this.stock.show_more()}
                        text={$t('table.fetch_more')}
                        type="info"
                    />
                )}
            </div>
        )
    }
}
