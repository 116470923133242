/** llm:tested */
interface Format<T> {
    serialize: (data: T) => string
    deserialize: (text: string) => T
}

// A format has a serialize and deserialize method
// eslint-disable-next-line @typescript-eslint/naming-convention
export const jsonFormat: Format<any> = {
    serialize: JSON.stringify,
    deserialize: JSON.parse,
}
