/** llm:tested */
import m from 'mithril'
import {orList} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {removeFromArray, addUniqueToArray} from '@/_utils'
import {button_with_icon} from '@/components/_buttons'
import inputs from '@/components/inputs'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import SearchInput from '@/components/collection/search_input'

interface Warehouse {
    artkey: string
    name: string
    street_address?: string
    zip_code?: string
    city?: string
    country_code?: string
    emailaddress?: string
}

export class CrmRelationsWarehousesSelect extends MithrilTsxComponent<any> {
    supplier_artkey: string
    warehouses: any
    search_input_ctrl: any
    supplier_warehouses: string[]

    constructor() {
        super()
        this.supplier_artkey = m.route.param('supplier_artkey')

        this.warehouses = new Collection({
            api_function_name: 'destinations.get_all_destinations',
            filter_function: this.is_match,
            sort_order: [
                {name: 'name', direction: 'asc'},
            ],
            default_sort_by: 'name',
            default_sort_order: 'asc',
        })

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.warehouses,
            placeholder: 'Search for warehouses',
        })

        this.supplier_warehouses = []
        api.call2('suppliers.get_all_supplier_warehouses',
            {supplier_artkey: this.supplier_artkey, third_party_only: true},
            this.set_warehouses.bind(this))
    }

    save = () => {
        const data = {
            supplier_artkey: this.supplier_artkey,
            warehouse_artkeys: this.supplier_warehouses,
        }
        api.call2('suppliers.activate_third_party_warehouses', data, () => {
            notifier.notify('Successfully updated the third party warehouses for this relation.', 'success')
            m.route.set(`/crm/relations/${this.supplier_artkey}/edit?tab=destinations`)
        })
    }

    set_warehouses = (resp: any) => {
        for (const warehouse of resp.result) {
            addUniqueToArray(this.supplier_warehouses, warehouse.artkey)
        }
    }

    warehouse_selected = (warehouse: Warehouse, value?: boolean) => {
        if (value !== undefined) {
            if (value) {
                addUniqueToArray(this.supplier_warehouses, warehouse.artkey)
            } else {
                removeFromArray(this.supplier_warehouses, warehouse.artkey)
            }
        }
        return this.supplier_warehouses.includes(warehouse.artkey)
    }

    oncreate() {
        this.warehouses.init()
    }

    is_match = (warehouse: Warehouse, term: string) => {
        const list = [
            warehouse.name.toLowerCase().indexOf(term) > -1,
        ]

        if (warehouse.street_address) {
            list.push(warehouse.street_address.toLowerCase().indexOf(term) > -1)
        }
        if (warehouse.zip_code) {
            list.push(warehouse.zip_code.toLowerCase().indexOf(term) > -1)
        }
        if (warehouse.city) {
            list.push(warehouse.city.toLowerCase().indexOf(term) > -1)
        }
        if (warehouse.emailaddress) {
            list.push(warehouse.emailaddress.toLowerCase().indexOf(term) > -1)
        }

        return orList(list)
    }

    view() {
        return (
            <div class="c-warehouse-select view">
                <div class="btn-toolbar">
                    <button class="btn btn-default" type="button"
                        onclick={() => m.route.set(`/crm/relations/${this.supplier_artkey}/edit?tab=destinations`)}>
                        <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                    </button>

                    {button_with_icon('Save selection', 'save', {
                        class: 'btn-success',
                        onclick: () => this.save(),
                    })}
                </div>

                <div class="c-filter-group">
                    {SearchInput.view(this.search_input_ctrl)}
                </div>

                <div class="mail recipients">
                    <CollectionTable
                        collection={this.warehouses}
                        options={{
                            search_table_style: true,
                            sticky_header: true,
                            with_buttons: true,
                            autoscale: true,
                            unique_name: 'supplier_warehouse_select',
                        }}
                        columns={[
                            {
                                width: 1,
                                name: 'Activate',
                                sort: false,
                                default_visible: true,
                                function: (record: Warehouse) => {
                                    const curried_warehouse_selected = this.warehouse_selected.bind(this, record)
                                    return inputs.checkbox(curried_warehouse_selected)
                                },
                            },
                            {
                                width: 3,
                                name: 'Name',
                                field: 'name',
                                sort: true,
                                ellipsis: true,
                                default_visible: true,
                            },
                            {
                                width: 1,
                                name: 'Street address',
                                field: 'street_address',
                                sort: true,
                                default_visible: true,
                            },
                            {
                                width: 3,
                                name: 'Zip code',
                                field: 'zip_code',
                                sort: true,
                                default_visible: true,
                            },
                            {
                                width: 1,
                                name: 'City',
                                field: 'city',
                                sort: true,
                                default_visible: true,
                            },
                            {
                                width: 1,
                                name: 'Country code',
                                field: 'country_code',
                                sort: true,
                                default_visible: true,
                            },
                        ]}
                    />
                </div>
            </div>
        )
    }
}
