/** llm:tested */
import m from 'mithril'
import {compact, map} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import {CollectionTable} from '@/components/collection_table'
import {matchTermIn} from '@/_utils'
import inputs from '@/components/inputs'

interface GraphProductTableAttrs {
    api_name: string
    on_update_graph_with_record_artkey_and_record: (artkey: string, record: any) => void
    artkeys_in_graph: () => string[]
}

interface ProductRecord {
    artkey: string
    product_name: string
    volume: string
    alcohol_percentage: string
    refill: string
    gift_box_type: string
    customs_status: string
    tax_label: string
    number_of_bottles_per_case: string
    is_in_graph?: () => boolean
}

type Prop<T> = {
    (): T
    (value: T): T
}

function create_prop<T>(initialValue: T): Prop<T> {
    let value = initialValue
    const prop = function(newValue?: T) {
        if (arguments.length) {
            value = newValue as T
            return value
        }
        return value
    } as Prop<T>
    return prop
}

export class GraphProductTable extends MithrilTsxComponent<any> {
    api_name: string
    on_update_graph_with_record_artkey_and_record: (artkey: string, record: any) => void
    artkeys_in_graph: () => string[]
    graph_product_items: Collection
    search_input_ctrl: any

    constructor(vnode: m.CVnode<GraphProductTableAttrs>) {
        super()
        this.api_name = vnode.attrs.api_name
        this.on_update_graph_with_record_artkey_and_record = vnode.attrs.on_update_graph_with_record_artkey_and_record
        this.artkeys_in_graph = vnode.attrs.artkeys_in_graph
        this.graph_product_items = new Collection({
            api_function_name: this.api_name,
            query_limit: 25,
            filter_function: this.is_match.bind(this),
            default_sort_by: 'timestamp',
            default_sort_order: 'desc',
            dont_reuse: true,
        })
        this.search_input_ctrl = new SearchInput.controller({
            collection: this.graph_product_items,
            placeholder: 'Search for product name...',
        })
    }

    is_match(batch: ProductRecord, term: string): boolean {
        return matchTermIn(term, map((x: string) => x.toLowerCase(), compact([
            batch.product_name,
        ])))
    }

    oncreate(): void {
        this.graph_product_items.requery()
    }

    view(vnode: m.Vnode<GraphProductTableAttrs>): m.Children {
        return [
            <div className="c-filter-group">
                {SearchInput.view(this.search_input_ctrl)}
            </div>,
            <CollectionTable
                collection={this.graph_product_items}
                options={{
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: true,
                    autoscale: true,
                    unique_name: `table${this.api_name}`,
                }}
                row_model={(record: ProductRecord) => {
                    const is_in_graph_initial = this.artkeys_in_graph().includes(record.artkey)
                    const is_in_graph = create_prop(is_in_graph_initial)
                    Object.assign(record, {is_in_graph})
                    return record
                }}
                columns={[
                    {
                        width: 0,
                        name: 'Include in graph',
                        sort: false,
                        function: (record: ProductRecord) => {
                            return inputs.checkbox(record.is_in_graph, {
                                disabled: false,
                                onchange: () => vnode.attrs.on_update_graph_with_record_artkey_and_record(
                                    record.artkey, record, record.is_in_graph(),
                                ),
                            })
                        },
                    },
                    {
                        width: 3,
                        name: 'Name',
                        field: 'product_name',
                        sort: false,
                        ellipsis: true,
                    },
                    {
                        width: 1,
                        name: 'Volume',
                        field: 'volume',
                        sort: false,
                        ellipsis: true,
                    },
                    {
                        width: 1,
                        name: 'Alcohol',
                        field: 'alcohol_percentage',
                        sort: false,
                        ellipsis: true,
                    },
                    {
                        width: 1,
                        name: 'Refill',
                        field: 'refill',
                        sort: false,
                        ellipsis: true,
                    },
                    {
                        width: 1,
                        name: 'Gift Box',
                        field: 'gift_box_type',
                        sort: false,
                        ellipsis: true,
                    },
                    {
                        width: 1,
                        name: 'Customs status',
                        field: 'customs_status',
                        sort: false,
                        ellipsis: true,
                    },
                    {
                        width: 1,
                        name: 'Tax label',
                        field: 'tax_label',
                        sort: false,
                        ellipsis: true,
                    },
                    {
                        width: 1,
                        name: 'Bottles per case',
                        field: 'number_of_bottles_per_case',
                        sort: false,
                        ellipsis: true,
                    },
                ]}
            />,
        ]
    }
}
