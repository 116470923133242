/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {PortalNewsItem} from '@/portal/models'
import {$m} from '@/app'

export class PortalNewsUpsert extends MithrilTsxComponent<any> {
    news_item: PortalNewsItem
    create: boolean

    constructor() {
        super()

        this.news_item = new PortalNewsItem()
        this.create = false

        const artkey = m.route.param('artkey')
        if (artkey) {
            this.query_news_item(artkey)
        } else {
            // we are creating a new one
            this.create = true
        }
    }

    query_news_item(artkey: string) {
        api.call('portal.news.get_single_news_item', {artkey: artkey}, this.set_news_item.bind(this))
    }

    set_news_item(resp: any) {
        if (resp.success) {
            try {
                this.news_item = new PortalNewsItem(resp.result)
            } catch (e) {
                $m.common.generic_error_handler(e)
            }
        } else {
            notifier.notify('Unknown news item.', 'danger')
            m.route.set('/portal/portal-news')
        }
    }

    save(e: Event) {
        e.preventDefault()

        const data = {
            artkey: this.news_item.artkey(),
            title: this.news_item.title(),
            content: this.news_item.content(),
        }
        api.call('portal.news.create_or_update_news_item', data, this.handle_save.bind(this))
    }

    handle_save(result: any) {
        if (!result.success) {
            notifier.notify(result.message, 'danger')
        } else {
            if (this.create) {
                notifier.notify(`Successfully created new portal news item '${this.news_item.title()}'.`, 'success')
                m.route.set('/portal/portal-news/' + result.artkey + '/edit')
            } else {
                notifier.notify('Successfully updated news item.', 'success')
            }
        }
    }

    view() {
        return (
            <div class="c-portal-news-edit view">
                <div class="btn-toolbar">
                    <button
                        class="btn btn-default"
                        type="button"
                        onclick={() => m.route.set('/portal/portal-news')}
                    >
                        <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                    </button>
                </div>

                <form class="flex-form" onsubmit={(e) => this.save(e)}>
                    <div class="fieldset">
                        <div class="field">
                            <label for="news_item_title">Title</label>
                            <input
                                class="form-control"
                                id="news_item_title"
                                type="text"
                                required={true}
                                placeholder="News item title"
                                value={this.news_item.title() || ''}
                                oninput={(ev: InputEvent) =>
                                    this.news_item.title((ev.target as HTMLInputElement).value)}
                            />
                        </div>
                        <div class="field">
                            <label for="news_item_content">Message</label>
                            <textarea
                                class="form-control"
                                id="news_item_content"
                                rows={15}
                                placeholder="News item message"
                                value={this.news_item.content() || ''}
                                oninput={(ev: InputEvent) =>
                                    this.news_item.content((ev.target as HTMLTextAreaElement).value)}
                            />
                        </div>
                    </div>
                    <button class="btn btn-success btn-submit">
                        {this.create ? 'Create News' : 'Update News'}
                    </button>
                </form>
            </div>
        )
    }
}
