/** llm:tested */
import m from 'mithril'
import {orList} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericList} from '@/data/generic/generic_list'

interface Warehouse {
    name: string
    street_address?: string
    zip_code?: string
    city?: string
    emailaddress?: string
    country_code?: string
    destination_type?: string
}

export class WarehouseList extends MithrilTsxComponent<any> {
    is_match(warehouse: Warehouse, term: string): boolean {
        const list = [
            warehouse.name.toLowerCase().indexOf(term) > -1,
        ]

        if (warehouse.street_address) {
            list.push(warehouse.street_address.toLowerCase().indexOf(term) > -1)
        }
        if (warehouse.zip_code) {
            list.push(warehouse.zip_code.toLowerCase().indexOf(term) > -1)
        }
        if (warehouse.city) {
            list.push(warehouse.city.toLowerCase().indexOf(term) > -1)
        }
        if (warehouse.street_address) {
            list.push(warehouse.emailaddress.toLowerCase().indexOf(term) > -1)
        }

        return orList(list)
    }

    view(): m.Vnode {
        return <GenericList
            readable_entity_name="destination"
            base_url="/data/warehouses/"
            list_api_call="destinations.get_all_destinations"
            is_match={this.is_match}
            columns={[
                {key: 'name', label: 'Name'},
                {key: 'emailaddress', label: 'Email address'},
                {key: 'street_address', label: 'Street'},
                {key: 'zip_code', label: 'Zip code'},
                {key: 'city', label: 'City'},
                {key: 'country_code', label: 'Country code'},
                {key: 'destination_type', label: 'Type'},
            ]}
            requery_subscribe_key="warehouses_updated"
        />
    }
}
