/** llm:tested */
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {a} from '@/_utils'
import {call} from '@/api'
import {$m} from '@/app'
import {PurchaseOrderDetails} from '@/purchase_orders/components/purchase_order_details'

interface PurchaseOrderCancelProps {
    date_ordered: () => string
    purchase_order: () => any
    purchase_order_artkey: number
    saving: () => boolean
}

export default class PurchaseOrderCancel extends MithrilTsxComponent<PurchaseOrderCancelProps> {
    date_ordered: () => string
    purchase_order: () => any
    purchase_order_artkey: number
    saving: (value?: boolean) => boolean

    constructor() {
        super()
        this.date_ordered = window.prop('')
        this.purchase_order = window.prop({})
        this.purchase_order_artkey = +m.route.param('artkey')
        this.load_purchase_order(this.purchase_order_artkey)
        this.saving = window.prop(false)
    }

    load_purchase_order(purchase_order_artkey: number): void {
        const data = {
            purchase_order_artkey: purchase_order_artkey,
        }
        call('purchase.core.get_purchase_orders_with_items', data, this.set_purchase_order)
    }

    set_purchase_order = (resp: any): void => {
        if (resp) {
            this.purchase_order($m.purchase_orders.create_purchase_order(resp.result[0]))
        }
    }

    submit = (event: Event): void => {
        event.preventDefault()
        this.saving(true)
        const data = {
            artkey: +this.purchase_order_artkey,
        }
        call('purchase.core.cancel_purchase_order', data, this.handle_cancel_response)
    }

    handle_cancel_response = (resp: any): void => {
        if (!resp.success) {
            notifier.notify(resp.message, 'danger')
            this.saving(false)
        } else {
            notifier.notify('This order was cancelled successfully.', 'success')
            m.route.set('/purchase-orders/manage')
        }
    }

    view(): m.Vnode {
        return m('', [
            m('.row#button-bar',
                m('.col-lg-12.btn-toolbar', {role: 'group'},
                    m('button.btn.btn-link', {
                        type: 'button',
                        onclick: () => m.route.set('/purchase-orders/manage/' + this.purchase_order().artkey()),
                    },
                    m('span.glyphicon.glyphicon-arrow-left'),
                    ' Back to order',
                    ),
                ),
            ),
            m('.row', m('.col-xs-12', m('h2', 'Cancelling purchase order'))),
            m('p', 'Are you sure you want to cancel this order? This cannot be undone!'),
            this.purchase_order().artkey ?
                m(PurchaseOrderDetails, {
                    purchase_order: this.purchase_order,
                }) : null,
            m('form', a(
                this.saving() ?
                    m('.alert.alert-danger.alert-dismissible', 'Cancelling the purchase order. This takes a while (*+/- 10 seconds per item).') :
                    m('button.btn.btn-danger', {
                        type: 'submit',
                        onclick: this.submit,
                    },
                    m('span.glyphicon.glyphicon-remove'),
                    ' Cancel order',
                    ),
            )),
        ])
    }
}
