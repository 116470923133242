/** llm:tested */
interface Record {
    artkey: string
    [key: string]: any
}

interface Dataset {
    record: Record
    [key: string]: any
}

class Color {
    background: string
    border: string

    constructor(red: number, green: number, blue: number) {
        this.background = `rgba(${red}, ${green}, ${blue}, 0.2)`
        this.border = `rgba(${red}, ${green}, ${blue}, 1)`
    }
}

export class GraphDataManager {
    private colors_given_away: { [key: string]: Color }
    private available_colors: Color[]
    private graph_data: { datasets: Dataset[] }

    constructor() {
        this.colors_given_away = {}
        this.available_colors = [
            new Color(255, 99, 132),
            new Color(54, 162, 235),
            new Color(255, 206, 86),
            new Color(75, 192, 192),
            new Color(153, 102, 255),
            new Color(239, 79, 117),
            new Color(255, 114, 166),
            new Color(128, 71, 80),
            new Color(215, 105, 82),
            new Color(255, 196, 56),
            new Color(104, 114, 79),
            new Color(141, 236, 120),
            new Color(2, 181, 160),
            new Color(1, 128, 181),
            new Color(133, 105, 128),
            new Color(118, 122, 121),
        ]
        this.graph_data = {
            datasets: [],
        }
    }

    add_dataset(dataset: Dataset): void {
        this.graph_data.datasets.push(dataset)
    }

    has_dataset_for_case_artkey(case_artkey: string): boolean {
        return (this.graph_data.datasets.map(dataset => dataset.record.artkey)).includes(case_artkey)
    }

    remove_dataset_for_case_artkey(case_artkey: string): void {
        this.graph_data.datasets = this.graph_data.datasets.filter(dataset => dataset.record.artkey !== case_artkey)
        this.available_colors.push(this.colors_given_away[case_artkey])
        delete this.colors_given_away[case_artkey]
    }

    case_artkeys_in_data(): string[] {
        return this.graph_data.datasets.map(dataset => dataset.record.artkey)
    }

    data(): { datasets: Dataset[] } {
        return this.graph_data
    }

    number_of_datasets(): number {
        return this.graph_data.datasets.length
    }

    fetch_available_color_for_graph(case_artkey: string): Color {
        // Pick a predefined color for the graph or generate a black color if nothing left.
        const color = this.available_colors.length > 0 ? this.available_colors[0] : new Color(12, 27, 29)
        this.available_colors.shift()
        this.colors_given_away[case_artkey] = color
        return color
    }
}
