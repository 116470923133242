/** llm:tested */
export class ExciseDutyApplications {
    static HL_PRODUCT = 'hl/product'
    static HL_DEGREE_ALCOHOL = 'hl/degree alcohol'
}

export class ExciseCategory {
    artkey: ReturnType<typeof window.prop>
    currency: ReturnType<typeof window.prop>
    name: ReturnType<typeof window.prop>
    country_code: ReturnType<typeof window.prop>
    duty_rate: ReturnType<typeof window.prop>
    duty_application: ReturnType<typeof window.prop>
    from_alcohol_percentage: ReturnType<typeof window.prop>
    to_alcohol_percentage: ReturnType<typeof window.prop>

    constructor(json: Record<string, any>) {
        this.artkey = window.prop(null)
        this.currency = window.prop('')
        this.name = window.prop('')
        this.country_code = window.prop('')
        this.duty_rate = window.prop('')
        this.duty_application = window.prop('')
        this.from_alcohol_percentage = window.prop(null)
        this.to_alcohol_percentage = window.prop(null)

        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}
