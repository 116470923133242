/** llm:tested*/
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {continents, countries, countries_per_continent} from '@bitstillery/common/lib/countries'
import {head, sortBy} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {MithrilProp} from '@/types'
import api_ls from '@/api'
import {CheckboxGroup} from '@/components/filter/checkboxgroup'
import * as inputs from '@/components/inputs'
import {languages} from '@/components/languages'
import {Modal} from '@/components/modal/modal'
import {UserCheckboxGroup} from '@/components/users'
import {FilterPresetHandler, FilterPresetType} from '@/filter_preset/filter_preset'
import {SaveFilterPresetAs} from '@/filter_preset/save_filter_preset_as'
import {$m, $s} from '@/app'

const CLIENT_STATUSES = [
    'Client',
    'Prospect',
    'Low Potential',
] as const

const COMPANY_TYPES = [
    'MSI',
    'Trader',
    'Retail',
    'Wholesale',
    'Travel Retail / Duty Free',
] as const

interface FilterSidebarAttrs {
    relations: any
    offer_relation_filters?: any
}

export class FilterSidebar extends MithrilTsxComponent<FilterSidebarAttrs> {
    relations: any
    offer_relation_filters: any
    show_save_modal = window.prop(false)
    loading_accounts = window.prop(true)
    loading_languages = window.prop(true)
    loading_countries = window.prop(true)
    loading_vouchers = window.prop(false)
    active_vouchers = window.prop([])
    language_options = window.prop([])
    countries_per_continent_options: Record<string, any>
    filter_presets = window.prop([])
    filter_preset_choices = window.prop([])
    selected_filter_preset_artkey = window.prop(null) as MithrilProp<number | null>
    loading_filter_presets = window.prop(true)
    filter_preset_type = FilterPresetType.SELECT_RELATION
    filter_preset_handler: FilterPresetHandler

    constructor(vnode: m.Vnode<FilterSidebarAttrs>) {
        super()
        this.relations = vnode.attrs.relations
        this.offer_relation_filters = vnode.attrs.offer_relation_filters || null

        this.countries_per_continent_options = {}
        for (const continent_code of Object.keys(continents)) {
            this.countries_per_continent_options[continent_code] = window.prop([])
        }

        this.filter_preset_handler = new FilterPresetHandler(
            this.filter_preset_type,
            this.filter_presets,
            this.filter_preset_choices,
            this.selected_filter_preset_artkey,
            this.loading_filter_presets,
        )
    }

    oncreate(): void {
        $m.common.observable.subscribe('accounts_loaded', this, () => this.loading_accounts(false))

        if ($m.accounts.accounts().length > 0) {
            this.loading_accounts(false)
        }

        this.loading_vouchers(true)
        api_ls.callAndThen('voucher.get_voucher_promotion_list', {}, {
            success: (resp: any) => {
                this.loading_vouchers(false)
                this.active_vouchers(resp.result)
            },
        })

        api_ls.callAndThen('suppliers.get_used_contact_languages', {}, {
            success: (resp: any) => {
                if (resp.result) {
                    this.language_options(
                        sortBy(
                            (option) => option[1],
                            resp.result.map((code: string) => [code, languages[code]]),
                        ),
                    )
                    this.loading_languages(false)
                }
            },
        })

        api_ls.callAndThen('suppliers.get_used_countries', {}, {
            success: (resp: any) => {
                if (resp.result) {
                    for (const continent_code of Object.keys(continents)) {
                        this.countries_per_continent_options[continent_code](
                            sortBy(
                                (option) => option[1],
                                resp.result
                                    .map((code: string) => [code, `${code} - ${countries[code]}`])
                                    .filter(([code]) => countries_per_continent[continent_code].includes(code)),
                            ),
                        )
                    }
                    this.loading_countries(false)
                }
            },
        })

        this.filter_preset_handler.get_filter_presets()

        if (this.offer_relation_filters) {
            for (const parameter_name of Object.keys(this.offer_relation_filters)) {
                if (parameter_name in Object.keys(this.relations.filters)) {
                    this.relations.filters[parameter_name](this.offer_relation_filters[parameter_name])
                } else {
                    notifier.notify(
                        `The offer's filter parameter with name ${parameter_name} is not recognized. Check your filter settings and save the relation selection again to fix this.`,
                        'warning',
                    )
                }
            }
            this.relations.requery()
        }
    }

    set_filter_preset(artkey: number): void {
        this.selected_filter_preset_artkey(+artkey)

        if (artkey) {
            const preset = head(this.filter_presets().filter((p: any) => p.artkey === this.selected_filter_preset_artkey()))

            if (preset) {
                const filter_keys = Object.keys(this.relations.filters)
                for (const parameter_name of Object.keys(preset.filters)) {
                    if (filter_keys.includes(parameter_name)) {
                        this.relations.filters[parameter_name](preset.filters[parameter_name])
                    } else {
                        notifier.notify(
                            `The preset's filter parameter with name ${parameter_name} is not recognized. Check your filter settings and save the preset again to fix this.`,
                            'warning',
                        )
                    }
                }
            }
            this.relations.requery()
        }
    }

    process_save_response(artkey: number): void {
        this.filter_preset_handler.get_filter_presets()
        this.selected_filter_preset_artkey(artkey)
        this.show_save_modal(false)
    }

    close_save_modal(): void {
        this.show_save_modal(false)
    }

    reset_all_filters(): void {
        window.location.reload()
    }

    view(): m.Children {
        if (typeof this.relations.filters === 'undefined') {
            return null
        }

        return <div class="c-filter-sidebar">
            <div class="row">
                <div class="col-xs-12">
                    <label class="control-label">Select a preset</label>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 mb-1">
                    {this.loading_filter_presets() ?
                        <Spinner /> :
                        <select
                            value={this.selected_filter_preset_artkey()}
                            onchange={(e: any) => this.set_filter_preset(e.target.value)}
                            disabled={this.filter_preset_choices().length < 2}
                        >
                            {this.filter_preset_choices().map((choice: any) =>
                                <option value={choice.value}>{choice.label}</option>,
                            )}
                        </select>
                    }
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <div class="btn-group">
                        <button
                            class="btn btn-success"
                            onclick={() => this.show_save_modal(true)}
                            disabled={this.loading_filter_presets()}
                            title="Save filter preset"
                        >
                            <i class="fa fa-save"></i> Save
                        </button>
                        <button
                            class="btn btn-danger"
                            onclick={() => this.filter_preset_handler.delete_filter_preset()}
                            disabled={this.loading_filter_presets() || this.selected_filter_preset_artkey() === null}
                            title="Delete filter preset"
                        >
                            <i class="fa fa-remove"></i> Delete
                        </button>
                    </div>
                </div>
            </div>

            <hr />

            <div class="btn-group">
                <button
                    class="btn btn-success"
                    onclick={() => this.relations.requery()}
                >
                    <i class="glyphicon glyphicon-filter"></i> Apply
                </button>
                <button
                    class="btn btn-default"
                    onclick={() => this.reset_all_filters()}
                >
                    <i class="fa fa-undo"></i> Reset
                </button>
            </div>

            <hr />

            <div class="btn-group">
                <button
                    class={`btn ${this.relations.filters.relation_filter() === 'mine_sales' ? 'btn-warning' : 'btn-default'}`}
                    onclick={() => this.relations.filters.relation_filter(
                        this.relations.filters.relation_filter() === 'mine_sales' ? 'all' : 'mine_sales',
                    )}
                >
                    <i class="glyphicon glyphicon-user"></i> Only my sales relations
                </button>
            </div>

            <hr />

            <div class="btn-group filter-radio mb-1">
                <label class="control-label">Buyers</label>
                {inputs.radio(this.relations.filters.buyers, [
                    {value: 'true', description: 'Yes'},
                    {value: 'false', description: 'No'},
                    {value: '', description: 'Both'},
                ])}
            </div>

            <div className="btn-group filter-radio mb-1">
                <label class="control-label">Suppliers</label>
                {inputs.radio(this.relations.filters.suppliers, [
                    {value: 'true', description: 'Yes'},
                    {value: 'false', description: 'No'},
                    {value: '', description: 'Both'},
                ])}
            </div>

            <hr />

            {this.loading_accounts() ? <Spinner /> :
                <CheckboxGroup
                    filter_function={this.relations.filters.sales_account}
                    filter_id="sales_account"
                    filter_name="Sales Account"
                    filter_options={$m.accounts.accounts().map(account => [account.slug(), account.name()])}
                />
            }

            {this.loading_languages() ? <Spinner /> :
                <CheckboxGroup
                    filter_function={this.relations.filters.language}
                    filter_id="language"
                    filter_name="Language"
                    filter_options={this.language_options()}
                    show_no_value_checkbox={true}
                />
            }

            {this.loading_countries() ? <Spinner /> :
                Object.keys(continents).map(continent_code =>
                    this.countries_per_continent_options[continent_code]().length > 0 &&
                    <CheckboxGroup
                        filter_function={this.relations.filters[`countries_per_continent_${continent_code}`]}
                        filter_id={`countries_${continent_code}`}
                        filter_name={`Countries in ${continents[continent_code]}`}
                        filter_options={this.countries_per_continent_options[continent_code]()}
                    />,
                )
            }

            <CheckboxGroup
                filter_function={this.relations.filters.company_type}
                filter_id="company_type"
                filter_name="Company type"
                filter_options={COMPANY_TYPES.map(status => [status, status])}
            />

            <CheckboxGroup
                filter_function={this.relations.filters.client_status}
                filter_id="client_status"
                filter_name="Client status"
                filter_options={CLIENT_STATUSES.map(status => [status, status])}
            />

            <UserCheckboxGroup
                filter_function={this.relations.filters.purchase_manager}
                filter_id="purchase_manager"
                filter_name="Purchase Manager"
            />

            <UserCheckboxGroup
                filter_function={this.relations.filters.sales_manager}
                filter_id="sales_manager"
                filter_name="Sales Manager"
            />

            <CheckboxGroup
                filter_function={this.relations.filters.price_list_frequency}
                filter_id="price_list_frequency"
                filter_name="Price list frequency"
                filter_options={Object.keys($m.data.price_list_frequencies).map(key => [
                    key,
                    $m.data.price_list_frequencies[key],
                ])}
                show_no_value_checkbox={true}
            />

            <CheckboxGroup
                filter_function={this.relations.filters.portal_level}
                filter_id="portal_level"
                filter_name="Portal level"
                filter_options={$m.data.supplier_portal_levels}
                show_no_value_checkbox={true}
            />

            <CheckboxGroup
                filter_function={this.relations.filters.customs_status_visibility}
                filter_id="customs_status_visibility"
                filter_name="Customs status visibility"
                filter_options={$m.data.supplier_portal_customs_visibility}
                show_no_value_checkbox={true}
            />

            <CheckboxGroup
                filter_function={this.relations.filters.currency}
                filter_id="currency"
                filter_name="Currency"
                filter_options={$s.currencies.all.map(currency => [currency, currency])}
            />

            <CheckboxGroup
                filter_function={this.relations.filters.active_sales_promotions}
                filter_id="sales_promotion_artkeys"
                filter_name="Sales Promotions"
                filter_options={$m.data.sales_promotions}
                show_no_value_checkbox={true}
            />

            {this.loading_vouchers() ? <Spinner /> :
                <CheckboxGroup
                    filter_function={this.relations.filters.active_vouchers_artkeys}
                    filter_id="active_vouchers"
                    filter_name="Voucher"
                    filter_options={this.active_vouchers().map(voucher => [voucher.artkey, voucher.name])}
                />
            }

            <hr />

            <div className="btn-group filter-radio">
                <label class="control-label">Operates Online</label>
                {inputs.radio(this.relations.filters.operates_online, [
                    {value: 'true', description: 'Yes'},
                    {value: 'false', description: 'No'},
                    {value: '', description: 'Both'},
                ])}
            </div>
            <div className="btn-group filter-radio">
                <label class="control-label">Receives offer mails</label>
                {inputs.radio(this.relations.filters.should_receive_offer_mails, [
                    {value: 'true', description: 'Yes'},
                    {value: 'false', description: 'No'},
                    {value: '', description: 'Both'},
                ])}
            </div>
            <div className="btn-group filter-radio">
                <label class="control-label">Receives purchase mails</label>
                {inputs.radio(this.relations.filters.should_receive_purchase_mails, [
                    {value: 'true', description: 'Yes'},
                    {value: 'false', description: 'No'},
                    {value: '', description: 'Both'},
                ])}
            </div>

            {this.show_save_modal() &&
                <Modal
                    title="Save filter preset as"
                    onclose={() => this.close_save_modal()}
                >
                    <SaveFilterPresetAs
                        filter_preset_data={this.filter_preset_handler.get_save_request_data(this.relations.filters)}
                        done={(artkey: number) => this.process_save_response(artkey)}
                        cancel={() => this.close_save_modal()}
                    />
                </Modal>
            }
        </div>
    }
}
