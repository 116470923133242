import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Tabs,
    Tab,
    TabPanel,
} from '@bitstillery/common/components'
import {watch} from '@bitstillery/common/lib/store'

import {ProductSuggestions} from './product_suggestions'
import {context_update} from './product_suggestions'

import {$s} from '@/app'
import {UpsertSpli} from '@/market/pricelists/components/upsert_spli'
import {collection as collection_unresolved} from '@/market/pricelists/components/spli_unresolved'

export class TabsResolve extends MithrilTsxComponent<any> {
    watchers = [] as any
    context_id = null as any
    data = proxy({
        tabs: {
            active: 'add_product',
        },
    })

    oninit() {
        this.watchers.push(watch($s.context, 'id', async() => {
            if (this.data.tabs.active === 'suggestions') {
                if (this.context_id !== $s.context.id) {
                    context_update()
                }
            }
        }))
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    view(_vnode:m.Vnode<any>) {
        return <div className="c-resolve-source-line">
            <Tabs
                data={this.data}
            >
                <Tab
                    icon="compare"
                    id="add_product"
                    text="From Product"
                >
                    <TabPanel className="content">
                        <UpsertSpli
                            collection={collection_unresolved}
                            source="sourceline"
                        />
                    </TabPanel>
                </Tab>
                <Tab
                    icon="auto_fix"
                    id="suggestions"
                    onactivate={() => {
                        if (this.context_id !== $s.context.id) {
                            context_update()
                        }
                    }}
                    text="From Suggestions"
                >
                    <TabPanel>
                        <ProductSuggestions collection={collection_unresolved} />
                    </TabPanel>
                </Tab>
            </Tabs>
        </div>
    }
}
