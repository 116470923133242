import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {FieldSelect} from '@bitstillery/common/components'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {BrandHolder, ProductManagementApi} from '@/factserver_api/product_management_api'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface BrandTransaction {
    brand_name: string
    purchase_reference: string
    purchasing_account: string
    supplier: string
    supplier_city: string
    supplier_country: string
    sales_reference: string
    selling_account: string
    client: string
    client_city: string
    client_country: string
    product_name: string
    alcohol: number
    volume: number
    refill_status: string
    lot: string
    entry_date: string
    customs_status_purchase: string
    origin_warehouse: string
    origin_warehouse_country: string
    customs_status_sales: string
    destination_warehouse: string
    destination_warehouse_country: string
}

export interface BrandTransactionsReportAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class BrandTransactionsReport
    extends MithrilTsxComponent<BrandTransactionsReportAttrs>
    implements RequeryListener
{
    reporting_fetcher = new PagedCollectionFetcher<BrandTransaction>(
        'reporting.brand_transactions_report.get_all',
        'entry_date',
    )

    product_management_api = new ProductManagementApi()

    brand_holders: BrandHolder[] = []
    selected_brand_holder = ''
    report_arguments: ReportArguments

    constructor(vnode: m.Vnode<BrandTransactionsReportAttrs>) {
        super()
        this.report_arguments = vnode.attrs.report_arguments
        this.fetch_brand_holders()
        vnode.attrs.add_requery_listener(this)
    }

    fetch_brand_holders(): void {
        this.product_management_api.get_all_brand_holders().subscribe((response: BrandHolder[]) => {
            this.brand_holders = response
            m.redraw()
        })
    }

    onremove(vnode: m.Vnode<BrandTransactionsReportAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        if (!report_arguments.additional_args.brand_holder_name) {
            return
        }
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.filters['brand_holder_name'] = report_arguments.additional_args.brand_holder_name
        this.reporting_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <div>
                <div className="row mt-1 mb-1">
                    <div className={'col-sm-2'}>
                        <FieldSelect
                            model={[this, 'selected_brand_holder']}
                            onchange={(selected: string) =>
                                (this.report_arguments.additional_args.brand_holder_name = selected)
                            }
                            options={this.brand_holders.map((brand_holder) => ({
                                label: brand_holder.name,
                                value: brand_holder.name,
                            }))}
                            placeholder="Select a brand holder..."
                        />
                    </div>
                </div>
                {this.reporting_fetcher.filters.brand_holder_name && (
                    <CollectionTable<BrandTransaction, void> collection_fetcher={this.reporting_fetcher}>
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Brand'}
                            sort_name={'brand_name'}
                            data_field={(row: BrandTransaction) => row.brand_name}
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Account'}
                            data_field={(row: BrandTransaction) => row.purchasing_account}
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Purchase ref'}
                            sort_name={'purchase_ref'}
                            data_field={(row: BrandTransaction) => (
                                <a href={`#!/purchase-orders/manage/${row.purchase_reference}`}>
                                    {row.purchase_reference}
                                </a>
                            )}
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Supplier'}
                            data_field={(row: BrandTransaction) =>
                                `${row.supplier} (${row.supplier_city} - ${row.supplier_country})`
                            }
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Product'}
                            sort_name={'product_name'}
                            data_field={(row: BrandTransaction) => row.product_name}
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Origin'}
                            sort_name={'origin_warehouse'}
                            data_field={(row: BrandTransaction) =>
                                row.origin_warehouse ? `${row.origin_warehouse} (${row.origin_warehouse_country})` : ''
                            }
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Customs status purchase'}
                            sort_name={'customs_status_purchase'}
                            data_field={(row: BrandTransaction) => row.customs_status_purchase}
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Lot'}
                            sort_name={'lot'}
                            data_field={(row: BrandTransaction) => row.lot}
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Entry date'}
                            sort_name={'entry_date'}
                            data_field={(row: BrandTransaction) => format_iso_to_date(row.entry_date)}
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Sales ref'}
                            sort_name={'sales_ref'}
                            data_field={(row: BrandTransaction) => (
                                <a href={`#!/sales-orders/manage/${row.sales_reference}`}>{row.sales_reference}</a>
                            )}
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Client'}
                            data_field={(row: BrandTransaction) =>
                                `${row.client} (${row.client_city} - ${row.client_country})`
                            }
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Destination'}
                            sort_name={'destination_warehouse'}
                            data_field={(row: BrandTransaction) =>
                                row.destination_warehouse ? `${row.destination_warehouse} (${row.destination_warehouse_country})` : ''
                            }
                        />
                        <CollectionTableColumn<BrandTransaction>
                            header_title={() => 'Customs status sales'}
                            sort_name={'customs_status_sales'}
                            data_field={(row: BrandTransaction) => row.customs_status_sales}
                        />
                    </CollectionTable>
                )}
            </div>
        )
    }
}
