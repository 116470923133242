import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import m from 'mithril'
import {Observable} from 'rxjs'
import {notifier} from '@bitstillery/common/app'
import {FieldText} from '@bitstillery/common/components'

import {Modal} from '@/components/modal/modal'
import {InputDate} from '@/components/html_components'
import {EditAssist, Field, HorizontalForm} from '@/components/form'
import {LoenderslootApi} from '@/factserver_api/loendersloot_api'
import {GetFastSalesOrderWithItemsResponse, is_exw_loendersloot} from '@/factserver_api/sales_api'
import {FactserverEmptyResponse} from '@/factserver_api/api'

export enum LoenderslootFlow {
    PRE_ADVICE,
    OUTBOUND
}

export interface SendToLoenderslootModalAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    on_close: () => unknown
    on_sent_to_loendersloot: () => unknown
    loendersloot_flow: LoenderslootFlow
}

export class SendToLoenderslootModal extends MithrilTsxComponent<SendToLoenderslootModalAttrs> {
    sales_order: GetFastSalesOrderWithItemsResponse
    is_loading = false
    loendersloot_api = new LoenderslootApi()
    edit_assist: EditAssist = new EditAssist(m.route)
    is_loendersloot_release = false
    loendersloot_flow: LoenderslootFlow
    modal_title: String

    constructor(vnode: m.Vnode<SendToLoenderslootModalAttrs>) {
        super()

        this.sales_order = vnode.attrs.sales_order
        const is_exw = this.sales_order.incoterm === 'EXW'
        this.is_loendersloot_release = is_exw_loendersloot(this.sales_order)
        this.loendersloot_flow = vnode.attrs.loendersloot_flow

        if (this.loendersloot_flow === LoenderslootFlow.PRE_ADVICE && is_exw) {
            this.modal_title = 'Confirm pre-advice at Loendersloot (with release when confirmed)'
        } else if (this.loendersloot_flow === LoenderslootFlow.PRE_ADVICE) {
            this.modal_title = 'Confirm pre-advice at Loendersloot (with pre-loading when confirmed)'
        } else if (this.is_loendersloot_release) {
            this.modal_title = 'Confirm release at Loendersloot'
        } else {
            this.modal_title = 'Confirm pre-loading (outbound) at Loendersloot'
        }

    }

    send_to_loendersloot(vnode: m.Vnode<SendToLoenderslootModalAttrs>): void {
        this.is_loading = true
        let api_call: Observable<FactserverEmptyResponse> | null = null
        if (this.loendersloot_flow === LoenderslootFlow.PRE_ADVICE) {
            api_call = this.loendersloot_api.send_pre_advice_to_loendersloot(
                this.sales_order.artkey,
                this.sales_order.estimated_loading_date || '', // dealt with in the form
                this.sales_order.warehouse_instruction,
                this.sales_order.freight_instruction,
                this.sales_order.frontoffice_instruction,
            )
        } else if (this.loendersloot_flow === LoenderslootFlow.OUTBOUND && this.is_loendersloot_release) {
            api_call = this.loendersloot_api.send_outbound_release_to_loendersloot(this.sales_order.artkey)
        } else if (this.loendersloot_flow === LoenderslootFlow.OUTBOUND && !this.is_loendersloot_release) {
            api_call = this.loendersloot_api.send_sales_order_to_loendersloot(
                this.sales_order.artkey,
                this.sales_order.estimated_loading_date || '', // dealt with in the form
                this.sales_order.warehouse_instruction,
                this.sales_order.freight_instruction,
                this.sales_order.frontoffice_instruction,
            )
        } else {
            throw Error('Unsupported loendersloot modus')
        }
        api_call.subscribe({
            next: () => {
                this.is_loading = false
                notifier.notify('Sent the sales order to Loendersloot.', 'success')
                if (this.loendersloot_flow === LoenderslootFlow.PRE_ADVICE) {
                    vnode.attrs.sales_order.sent_pre_advice_to_loendersloot_on = DateTime.now().toISO()
                } else {
                    vnode.attrs.sales_order.sent_to_loendersloot_on = DateTime.now().toISO()
                }
                vnode.attrs.on_sent_to_loendersloot()
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    view(vnode: m.Vnode<SendToLoenderslootModalAttrs>) : m.Children {
        const sales_order = vnode.attrs.sales_order
        const shows_input_fields = !this.is_loendersloot_release
        return (<Modal
            title={this.modal_title}
            onclose={vnode.attrs.on_close}
        >
            <div className={'row col-sm-12'}>
                <HorizontalForm
                    onsubmit={() => this.send_to_loendersloot(vnode)}
                    submit_title={'Send'}
                    submit_disabled={this.is_loading}
                    edit_assist={this.edit_assist}
                >
                    {shows_input_fields && <Field label={'Estimated loading date'}>
                        <InputDate
                            value={sales_order.estimated_loading_date || ''}
                            required={true}
                            onchange={(value: DateTime | null) =>
                                (sales_order.estimated_loading_date = value ? value.toISODate() : null)
                            }
                        />
                    </Field>}
                    {shows_input_fields && <FieldText
                        label="Frontoffice instruction"
                        model={[sales_order, 'frontoffice_instruction']}
                        placeholder={'Instructions for the Frontoffice team'}
                    />}
                    {shows_input_fields && <FieldText
                        label="Warehouse instruction"
                        model={[sales_order, 'warehouse_instruction']}
                        placeholder={'Instructions for the Warehouse team'}
                    />}
                    {shows_input_fields && <FieldText
                        label="Freight instruction"
                        model={[sales_order, 'freight_instruction']}
                        placeholder={'Instructions for the Freight team'}
                    />}
                    {!shows_input_fields && <div className={'help-block'}>
                                    Instructions are not required since goods are released at Loendersloot.
                    </div>}
                    {this.loendersloot_flow === LoenderslootFlow.PRE_ADVICE && <div>
                        The following actions will take place:
                        <ul>
                            <li>If the order contains any items that are 'resold' from another account,
                                a 'buy-from-account' Sales order and a 'buy-from-account' Purchase order will be created.
                            </li>
                            <li>A pre-advice will be created on this Sales Order.</li>
                            <li>A pre-advice will be created on the 'buy-from-account' Sales order.</li>
                            <li>Escrow will be set to {sales_order.escrow_loendersloot ? 'YES' : 'NO'}</li>
                            <li>After confirming the pre-advice, both the pre-advices will be released or outbound.
                                The 'buy-from-account' Sales order will be invoiced and the Purchase order stocked.
                            </li>
                            <li>After cancelling the pre-advice, both the pre-advices will be cancelled.
                                The 'buy-from-account' Sales order and Purchase order will be cancelled.
                            </li>
                            <li>If there are no 'resold' items there will be only one pre-advice, for this order.</li>
                        </ul>
                    </div>}
                </HorizontalForm>
            </div>
        </Modal>)
    }
}
