/** llm:tested */
import m from 'mithril'
import {map} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import api from '@/api'
import inputs from '@/components/inputs'
import {afterUpdate} from '@/_utils'
import {Box} from '@/components/adminlte/box'
import {create_time_line_graph} from '@/data/diana/components/graph_utils'
import {StatFunction} from '@/data/diana/models'
import {GraphProductTable} from '@/data/diana/components/graph_product_table'
import {GraphDataManager} from '@/data/diana/components/graph_data_manager'

interface Dataset {
    record: any
    label: string
    backgroundColor: string
    borderColor: string
    lineTension: number
    spanGaps: boolean
    data: Array<{
        x: string
        y: number
        predicted_sales: number
        predicted_stock: number
        actual_stock: number
    }>
}

interface Vnode {
    attrs: {
        canvas_id: string
    }
}

export class StockPositionGraph extends MithrilTsxComponent<any> {
    canvas_id: string
    accumulate_graph_data: any
    stat_function: any
    line_chart: any
    graph_data: any
    // LLM_NOTE: This function is not defined in the livescript file.
    private set_accumulate_graph_data: (value: boolean) => void = () => {
        // Implementation of set_accumulate_graph_data if needed
    }

    constructor(vnode: Vnode) {
        super()
        this.canvas_id = vnode.attrs.canvas_id
        // LLM_NOTE: This part seems a bit funky.
        this.accumulate_graph_data = afterUpdate((value: boolean) => {
            this.set_accumulate_graph_data(value)
        }, false)
        this.stat_function = window.prop(StatFunction.PREDICTED_STOCK)
        this.line_chart = window.prop(null)
        this.graph_data = new GraphDataManager()
    }

    set_aggregation_level() {
        // LLM_NOTE: This function is not defined in the livescript file.
        // this.aggregation_level(val)
        this.requery_datasets_in_graph()
    }

    set_graph_function(val: any) {
        this.stat_function(val)
        this.update_graph_data_with_graph_function()
    }

    update_graph_data_with_graph_function() {
        for (const dataset of this.graph_data.data().datasets) {
            for (const data of dataset.data) {
                if (this.stat_function() === StatFunction.PREDICTED_STOCK) {
                    data.y = data.predicted_stock
                } else if (this.stat_function() === StatFunction.PREDICTED_SALES) {
                    data.y = data.predicted_sales
                } else if (this.stat_function() === StatFunction.ACTUAL_STOCK) {
                    data.y = data.actual_stock
                }
            }
        }
        this.line_chart().update()
    }

    requery_datasets_in_graph() {
        const cases_to_recalculate = map((dataset: Dataset) => ({
            case_artkey: dataset.item_stocked_record.artkey,
            record: dataset.item_stocked_record,
        }), this.graph_data.data().datasets)

        this.graph_data = new GraphDataManager()
        for (const case_to_recalculate of cases_to_recalculate) {
            this.query_data_for_case(case_to_recalculate.case_artkey, case_to_recalculate.record)
        }
        this.line_chart().update()
    }

    on_update_is_in_graph(case_artkey: string, record: any, value: boolean) {
        if (value) {
            this.query_data_for_case(case_artkey, record)
        } else {
            this.graph_data.remove_dataset_for_case_artkey(case_artkey)
            this.line_chart().update()
        }
    }

    query_data_for_case(case_artkey: string, record: any) {
        const data = {
            case_artkey: case_artkey,
        }

        api.callAndThen('diana.stock_position.datapoints_in_period', data, {
            success: (resp: any) => {
                const color = this.graph_data.fetch_available_color_for_graph(case_artkey)
                const dataset = {
                    record: record,
                    label: `${record.product_name} ${record.volume} ${record.alcohol_percentage} ${record.number_of_bottles_per_case}/${record.customs_status}/${record.refill}/${record.gift_box_type}/${record.tax_label}`,
                    backgroundColor: color.background,
                    borderColor: color.border,
                    lineTension: 0,
                    spanGaps: true,
                    data: map((data_point: any) => ({
                        x: data_point.timestamp.split('T')[0],
                        y: data_point.cumulative,
                        predicted_sales: data_point.predicted_sales,
                        predicted_stock: data_point.predicted_stock,
                        actual_stock: data_point.actual_stock,
                    }), resp.result),
                }
                this.graph_data.add_dataset(dataset)
                this.update_graph_data_with_graph_function()
                this.line_chart().update()
            },
        })
    }

    oncreate_line_chart() {
        const target = document.getElementById(this.canvas_id)
        this.line_chart(create_time_line_graph(this.graph_data.data(), target))
        return this.line_chart()
    }

    view(_vnode:m.Vnode<any>) {
        return [
            <Box
                cls=".box-info.bg-gray-light"
                title="Stock position per case"
                body={() => (
                    <div>
                        <div className="row">
                            {this.graph_data.data !== null && (
                                <canvas
                                    id={this.canvas_id}
                                    height="50px"
                                    oncreate={() => this.oncreate_line_chart()}
                                />
                            )}
                        </div>
                    </div>
                )}
                loading={() => this.graph_data.data === null}
                collapsed={() => false}
            />,

            <div className="row mb-1">
                <label className="control-label col-sm-1">Graph function</label>
                <div className="col-sm-3">
                    {inputs.select(this.stat_function, StatFunction.choices, {
                        onchange: this.set_graph_function.bind(this),
                        empty_option: false,
                    })}
                </div>
            </div>,

            <GraphProductTable
                api_name="diana.stock_position.list_cases"
                on_update_graph_with_record_artkey_and_record={this.on_update_is_in_graph.bind(this)}
                artkeys_in_graph={this.graph_data.case_artkeys_in_data.bind(this.graph_data)}
            />,
        ]

    }
}
