/** llm:production */
import m from 'mithril'
import {Button, ButtonGroup} from '@bitstillery/common/components'
import {$t, api, notifier} from '@bitstillery/common/app'

import {formatDate} from '@/_utils'
import {items_table} from '@/stock/components/items_table'
import mutationPanel from '@/stock/components/mutation_panel'
import {SalesOrderItems} from '@/stock/components/sales_order_items'
import {PurchaseOrderItems} from '@/stock/components/purchase_order_items'
import {CreditItems} from '@/stock/components/credit_items'
import {Item, ItemMutation} from '@/models/stock'
import {create_download_for_blob} from '@/factserver_api/api'

export class StockItemDetails {
    loading: any
    loading_purchase: any
    loading_sales: any
    loading_credit: any
    item_reference: string | null = null
    item_artkey: string | null = null
    item: any
    items: any
    purchase_order_item: any
    purchase_order_items: any
    source_mutation: any
    target_mutation: any
    sales_order_items: any
    credit_items: any

    constructor() {
        this.loading = window.prop(false)
        this.loading_purchase = window.prop(false)
        this.loading_sales = window.prop(false)
        this.loading_credit = window.prop(false)
        const item_reference_or_artkey = m.route.param('reference')
        if (item_reference_or_artkey && item_reference_or_artkey.startsWith('I')) {
            this.item_reference = item_reference_or_artkey
        } else {
            this.item_artkey = item_reference_or_artkey
        }

        this.item = window.prop(new Item())
        this.items = window.prop([])
        this.purchase_order_item = window.prop(null)
        this.purchase_order_items = window.prop([])
        this.source_mutation = window.prop(new ItemMutation())
        this.target_mutation = window.prop(new ItemMutation())
        this.sales_order_items = window.prop([])
        this.credit_items = window.prop([])

        this.get_item()
    }

    add_source_mutation = (resp: any) => {
        if (resp.success) {
            this.source_mutation(new ItemMutation(resp.result))
        }
    }

    add_target_mutation = (resp: any) => {
        if (resp.success) {
            this.target_mutation(new ItemMutation(resp.result))
        }
    }

    download_transactions = async() => {
        const file_name = `Item ${this.item().lot()} transactions ${formatDate(new Date())}.xlsx`

        const {result: excel_blob} = await api.get(`discover/items/${this.item().artkey()}/sales-order-items/export`) as any
        create_download_for_blob(excel_blob, file_name)
    }

    async get_credit_items(item_artkey: string) {
        this.loading_credit(true)
        const {result, success} = await api.post('sales.credit.get_credit_items_by_item', {item_artkey: item_artkey}, false)
        this.loading_credit(false)
        if (success) {
            this.credit_items(result)
        }
    }

    async get_item() {
        this.loading_purchase(true)
        this.loading_sales(true)
        this.loading_credit(true)
        const data = {
            item_reference: this.item_reference,
            item_artkey: this.item_artkey,
        }
        const {result, success} = await api.post('stock.get_item', data, false) as any

        if (success) {
            this.item(new Item(result))
            this.items([this.item()])
            this.purchase_order_item(result.purchase_order_item)

            this.get_lots(result.purchase_order_item.artkey)
            this.get_sales_order_items(this.item().artkey())
            this.get_credit_items(this.item().artkey())

            if (this.item().is_target_in()) {
                await this.get_mutation(this.item().is_target_in().artkey(), this.add_target_mutation)
            }

            if (this.item().is_source_in()) {
                await this.get_mutation(this.item().is_source_in().artkey(), this.add_source_mutation)
            }
        }
    }

    async get_lots(purchase_order_item_artkey: string) {
        this.loading_purchase(true)
        const {result, success} = await api.post('stock.get_lots', {purchase_order_item_artkey: purchase_order_item_artkey}, false) as any

        this.loading_purchase(false)
        if (success) {
            // By now, the property @purchase_order_item has always been set.
            // Append the lots to it and put them in the @purchase_order_items
            // property, which is used for the purchase_order_items view.
            const purchase_order_item = this.purchase_order_item()
            purchase_order_item.lots = result.map((item: any) => item.lot)
            this.purchase_order_items([purchase_order_item])
        }
    }

    async get_mutation(item_mutation_artkey: string, callback: (resp: any) => void) {
        const data = {
            item_mutation_artkey: item_mutation_artkey,
        }
        const {result} = await api.post('stock.get_item_mutation', data, false)
        callback(result)
    }

    async get_sales_order_items(item_artkey: string) {
        this.loading_sales(true)
        const {result: sales_order_items} = await api.get(`discover/items/${item_artkey}/sales-order-items`)

        this.loading_sales(false)
        if (sales_order_items) {
            this.sales_order_items(sales_order_items)
            m.redraw()
        }
    }

    async recalculate_availabilities() {
        await api.post('stock.recalculate_availabilities', {item_artkey: this.item().artkey()}, false)
        notifier.notify('Updated cached availabilities.', 'success')
    }

    view() {
        return (
            <div className="c-view-item view">
                <div className="btn-toolbar">
                    <ButtonGroup>
                        <Button
                            disabled={!this.item().can_be_mutated()}
                            icon="edit"
                            onclick={() => m.route.set(`/stock/manage/${this.item().artkey()}/edit`)}
                            text={$t('stock.actions.item_edit')}
                            type="info"
                            variant="context"
                        />
                        <Button
                            disabled={!this.item().can_be_mutated()}
                            icon="wrench"
                            onclick={() => m.route.set(`/stock/manage/${this.item().reference()}/update-cases`)}
                            text={$t('stock.actions.update_cases')}
                            type="info"
                            variant="context"
                        />
                        <Button
                            disabled={!this.item().can_be_mutated()}
                            icon="wrench"
                            onclick={() => m.route.set(`/stock/manage/${this.item().reference()}/update-product`)}
                            text={$t('stock.actions.update_product')}
                            type="info"
                            variant="context"
                        />
                        <Button
                            disabled={!this.item().can_be_mutated()}
                            icon="wrench"
                            onclick={() => m.route.set(`/stock/manage/${this.item().reference()}/update-product/correct-bottles`)}
                            text={$t('stock.actions.update_bottles_per_case')}
                            type="info"
                            variant="context"
                        />
                    </ButtonGroup>

                    <ButtonGroup>
                        <Button
                            icon="cog"
                            disabled={!this.item().artkey()}
                            onclick={this.recalculate_availabilities.bind(this)}
                            text={$t('stock.actions.recalculate_availability')}
                            type="warning"
                            variant="context"
                        />
                    </ButtonGroup>

                    <Button
                        active={false}
                        className="btn-back"
                        icon="back"
                        onclick={() => m.route.set('/stock/manage')}
                        variant="toggle"
                    />
                </div>

                {this.source_mutation().artkey() && [
                    <h4>Replaced in:</h4>,
                    mutationPanel.mutation_panel(this.source_mutation),
                ]}

                <div className="panel panel-primary">
                    <div className="panel-heading">
                        <div className="panel-title">Stock</div>
                    </div>
                    <div className="panel-body">
                        {items_table(this.items, {
                            show_sales: true,
                            show_available: true,
                            include_prices: true,
                        })}
                    </div>
                </div>

                <PurchaseOrderItems
                    data={this.purchase_order_items}
                    title="Purchase order"
                    loading={this.loading_purchase}
                />

                {<SalesOrderItems
                    data={this.sales_order_items}
                    title="Transactions"
                    loading={this.loading_sales}
                    download_callback={this.download_transactions}
                />}

                <CreditItems
                    data={this.credit_items}
                    title="Credit order"
                    loading={this.loading_credit}
                />

                {this.target_mutation().artkey() && [
                    <h4>Created in:</h4>,
                    mutationPanel.mutation_panel(this.target_mutation),
                ]}
            </div>
        )
    }
}

export default StockItemDetails
