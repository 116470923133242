import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Observable} from 'rxjs'
import {FieldSelect} from '@bitstillery/common/components'
import {FieldSelectAttrs} from '@bitstillery/common/types/field'

import {MaybeObservable} from './relation'

interface InsuranceTypesDropDownAttrs extends FieldSelectAttrs {
    selected_insurance_type_artkey: string
    onchange: (insurance_type_artkey: string) => unknown
    disabled?: boolean
    required?: boolean
    get_all_for_drop_down_response$: Observable<any[]>
}

export class InsuranceTypesDropDown extends MithrilTsxComponent<InsuranceTypesDropDownAttrs> {
    insurance_types: any[] = []

    oncreate(vnode: m.Vnode<InsuranceTypesDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe((insurance_types) => (this.insurance_types = insurance_types))
    }

    view(vnode: m.Vnode<InsuranceTypesDropDownAttrs>): m.Children {
        return (
            <MaybeObservable observed={vnode.attrs.get_all_for_drop_down_response$}>
                <FieldSelect
                    disabled={vnode.attrs.disabled}
                    label={vnode.attrs.label}
                    model={vnode.attrs.model}
                    onchange={vnode.attrs.onchange}
                    options={this.insurance_types?.map((insurance_type) => ({
                        value: insurance_type.artkey,
                        label: insurance_type.name,
                    }))}
                    validation={vnode.attrs.validation}
                />
            </MaybeObservable>
        )
    }
}
