/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import api from '@/api'
import {button_with_icon} from '@/components/_buttons'
import {CollectionTable} from '@/components/collection_table'
import {EmailPreview} from '@/email/components/email_preview'
import {formatDateTime, download_binary_file_from_base64_str_with_type} from '@/_utils'

enum EmailRecipientType {
    TO = 'TO',
    FROM = 'FROM',
    BCC = 'BCC',
    CC = 'CC'
}

interface Attachment {
    s3_key: string
    file_name: string
    content_type: string
}

interface Recipient {
    type: EmailRecipientType
    emailaddress: string
}

interface EmailRecord {
    artkey: string
    body: string
    sent_on: string
    subject: string
    sent_state: string
    recipients: Recipient[]
    attachments: Attachment[]
}

interface EmailTableAttrs {
    email_collection: any
}

export class EmailTable extends MithrilTsxComponent<EmailTableAttrs> {
    private email_collection: any

    oninit(vnode: m.CVnode<EmailTableAttrs>) {
        this.email_collection = vnode.attrs.email_collection
    }

    export_attachment(artkey: string, s3_key: string) {
        const data = {
            artkey: artkey,
            s3_key: s3_key,
        }

        api.callAndThen('email.attachment.get', data, {
            success: (resp: { content: string; file_name: string; content_type: string }) => {
                download_binary_file_from_base64_str_with_type(
                    resp.content,
                    resp.file_name,
                    resp.content_type,
                )
            },
        })
    }

    view() {
        return <div class="row">
            <div class="col-sm-12">
                <CollectionTable
                    collection={this.email_collection}
                    options={{
                        search_table_style: true,
                        sticky_header: true,
                        with_buttons: true,
                        autoscale: true,
                        unique_name: 'email_batch_emails_sent',
                    }}
                    view_details={(record: EmailRecord) =>
                        <div class="col-sm-8 content-preview">
                            <EmailPreview email={() => record.body} />
                        </div>
                    }
                    columns={[
                        {
                            width: 2,
                            name: 'Sent on',
                            field: 'sent_on',
                            sort: true,
                            transform: formatDateTime,
                        },
                        {
                            width: 3,
                            name: 'Subject',
                            field: 'subject',
                            sort: true,
                            ellipsis: true,
                        },
                        {
                            width: 3,
                            name: 'From',
                            field: 'email_recipient_from',
                            sort: true,
                            ellipsis: true,
                            function: (record: EmailRecord) =>
                                record.recipients.filter(
                                    (r: Recipient) => r.type === EmailRecipientType.FROM,
                                ).map((record: Recipient) => <span>{record.emailaddress}</span>),
                        },
                        {
                            width: 3,
                            name: 'To',
                            field: 'email_recipient_to',
                            sort: true,
                            ellipsis: true,
                            function: (record: EmailRecord) =>
                                record.recipients.filter(
                                    (r: Recipient) => r.type === EmailRecipientType.TO,
                                ).map((record: Recipient) => <span>{record.emailaddress}</span>),
                        },
                        {
                            width: 3,
                            name: 'Cc',
                            sort: true,
                            ellipsis: true,
                            function: (record: EmailRecord) =>
                                record.recipients.filter(
                                    (r: Recipient) => r.type === EmailRecipientType.CC,
                                ).map((record: Recipient) => <span>{record.emailaddress}</span>),
                        },
                        {
                            width: 3,
                            name: 'Bcc',
                            sort: true,
                            ellipsis: true,
                            function: (record: EmailRecord) =>
                                record.recipients.filter(
                                    (r: Recipient) => r.type === EmailRecipientType.BCC,
                                ).map((record: Recipient) => <span>{record.emailaddress}</span>),
                        },
                        {
                            width: 3,
                            name: 'State',
                            field: 'sent_state',
                            sort: true,
                            ellipsis: true,
                        },
                        {
                            width: 3,
                            name: 'Attachments',
                            sort: false,
                            ellipsis: true,
                            function: (record: EmailRecord) => {
                                const email_artkey = record.artkey
                                return record.attachments.map((attachment: Attachment) =>
                                    button_with_icon('Download attachment', 'fa-download', {
                                        class: 'btn-default',
                                        onclick: (e: Event) => {
                                            e.stopPropagation()
                                            this.export_attachment(email_artkey, attachment.s3_key)
                                            return false
                                        },
                                    }))
                            },
                        },
                    ]}
                />
            </div>
        </div>
    }
}
