import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Amount, Tippy} from '@bitstillery/common/components'

import {formatDate} from '@/_utils'
import {pricelist_link} from '@/data/components/pricelist_link'
import {$m, $s} from '@/app'
import {DefaultButton, LinkButton} from '@/components/buttons'
import {Link} from '@/components/discover'
import {Decimal} from '@/components/html_components'
import {Bottle} from '@/factserver_api/item_model'
import {Collection} from '@/components/collection/collection'
import {convert_from_currency_to_euro} from '@/factserver_api/currencies'

/*
NOTE: This is an emergency copy from an old version of market/discover_table. We forgot to test this and this old
version is still used in offer_select_products.
 */
interface MarketItem {
    artkey: number
    aux_info: string
    availability_status: string
    currency: string
    customs_status: string
    gift_box_type: string
    incoterm: string
    number_of_bottles_per_case: number
    number_of_cases: number
    price_per_bottle: number
    price_per_case: number
    supplier: {
        company_type: string
        name: string
    }
    supplier_price_list: {
        artkey: number
        supplier_price_list_description: string
        start_date: Date
    }
    exists_in_offer?: number
}

interface DiscoverBottle extends Bottle {
    market: Array<MarketItem>
    excise_nl: number
}

interface BottlesCollection extends Collection {
    search_result: () => Array<DiscoverBottle>
}

interface DiscoverTableAttrs {
    bottles: BottlesCollection
    open_add_to_offer_modal: (market_item: unknown, bottle: unknown) => void
}

export class DiscoverTable extends MithrilTsxComponent<DiscoverTableAttrs> {
    bottles: BottlesCollection
    open_add_to_offer_modal: (market_item: unknown, bottle: unknown) => void

    constructor(vnode: m.Vnode<DiscoverTableAttrs>) {
        super()
        this.bottles = vnode.attrs.bottles
        this.open_add_to_offer_modal = vnode.attrs.open_add_to_offer_modal
    }

    /**
     * Compare function used for sorting the records in the market items table on price, cheapest first.
     *
     * @param x: The MarketItem to compare with y
     * @param y: The MarketItem to compare with x
     *
     * @return 1 to put y before x, -1 to put x before y, 0 to keep original order.
     */
    sort_market_item_on_price(x: MarketItem, y: MarketItem): number {
        if (
            convert_from_currency_to_euro(+x.price_per_bottle, x.currency) >
            convert_from_currency_to_euro(+y.price_per_bottle, y.currency)
        )
            return 1
        if (
            convert_from_currency_to_euro(+x.price_per_bottle, x.currency) <
            convert_from_currency_to_euro(+y.price_per_bottle, y.currency)
        )
            return -1
        return 0
    }

    /**
     * Render the market items table for a certain DiscoverBottle.
     *
     * @param bottle: the DiscoverBottle to render the market items table for.
     *
     * @return The table with market items.
     */
    market_items(bottle: DiscoverBottle): m.Children {
        return (
            <table className="table">
                <thead className="thead-default">
                    <tr>
                        <th />
                        <th>Relation</th>
                        <th>Quantity</th>
                        <th>Btl / cs</th>
                        <th>Price per case</th>
                        <th>€ per case</th>
                        <th>€ per bottle</th>
                        <th>Customs</th>
                        <th>Incoterm</th>
                        <th>Avail. st.</th>
                        <th>Gift box</th>
                        <th>Aux info</th>
                        <th>List start date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {bottle.market
                        .sort((x, y) => this.sort_market_item_on_price(x, y))
                        .map((market_item) => (
                            <tr
                                key={market_item.artkey}
                                className={$m.data.company_type_class(market_item.supplier.company_type)}
                            >
                                <td>
                                    <Link
                                        target="_blank"
                                        href={pricelist_link.from_bottle_json(
                                            market_item.supplier_price_list.artkey,
                                            bottle,
                                        )}
                                    >
                                        <span className={'glyphicon glyphicon-new-window'} />
                                    </Link>
                                </td>
                                <td>
                                    {market_item.supplier.name}
                                    &nbsp;
                                    {market_item.supplier_price_list.supplier_price_list_description && (
                                        <Tippy
                                            content={`<strong>Description</strong>
                                                      <div>${market_item.supplier_price_list.supplier_price_list_description}</div>`}
                                            allowHTML={true}
                                        >
                                            <span className={'glyphicon glyphicon-info-sign'} />
                                        </Tippy>
                                    )}
                                </td>
                                <td>{market_item.number_of_cases || '-'}</td>
                                <td>{market_item.number_of_bottles_per_case || '-'}</td>
                                <td>
                                    <Amount
                                        amount={market_item.price_per_case}
                                        currency={market_item.currency}
                                        excise={bottle.excise_nl * market_item.number_of_bottles_per_case}
                                    />
                                </td>
                                <td>
                                    <Amount
                                        amount={market_item.price_per_case}
                                        currency={market_item.currency}
                                        display_currency={$s.currencies.default}
                                        excise={bottle.excise_nl * market_item.number_of_bottles_per_case}
                                    />
                                </td>
                                <td>
                                    <Amount
                                        amount={market_item.price_per_bottle}
                                        currency={market_item.currency}
                                        display_currency={$s.currencies.default}
                                        excise={bottle.excise_nl}
                                    />
                                </td>
                                <td>{market_item.customs_status}</td>
                                <td>{market_item.incoterm}</td>
                                <td>{market_item.availability_status}</td>
                                <td>{market_item.gift_box_type}</td>
                                <td>{market_item.aux_info}</td>
                                <td>{formatDate(market_item.supplier_price_list.start_date)}</td>
                                <td>
                                    <DefaultButton
                                        disabled={
                                            market_item.supplier.company_type === 'Competitor' ||
                                            market_item.exists_in_offer
                                        }
                                        icon_class={'glyphicon glyphicon-duplicate'}
                                        onclick={() => this.open_add_to_offer_modal(market_item, bottle)}
                                        tooltip={
                                            market_item.exists_in_offer
                                                ? 'Item is already in this offer'
                                                : 'Add to offer'
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        )
    }

    view(): m.Children {
        return (
            <div>
                <div className="panel panel-primary">
                    <div className="panel-heading discover-heading no-select">
                        <div className="row">
                            <div className="col-xs-3" onclick={() => this.bottles.sort('product.name')}>
                                Product {this.bottles.sort_icon('product.name')}
                            </div>
                            <div className="col-xs-2 " onclick={() => this.bottles.sort('volume')}>
                                Size in cl {this.bottles.sort_icon('volume')}
                            </div>
                            <div className="col-xs-2" onclick={() => this.bottles.sort('alcohol_percentage')}>
                                Alcohol % {this.bottles.sort_icon('alcohol_percentage')}
                            </div>
                            <div className="col-xs-1" onclick={() => this.bottles.sort('refill_status')}>
                                Refill {this.bottles.sort_icon('refill_status')}
                            </div>
                            <div className="col-xs-2" onclick={() => this.bottles.sort('product.category')}>
                                Category {this.bottles.sort_icon('product.category')}
                            </div>
                            <div className="col-xs-1">Excise NL</div>
                            <div className="col-xs-1" />
                        </div>
                    </div>
                </div>
                {this.bottles.search_result().map((bottle) => (
                    <div className="panel panel-default">
                        <div className="panel-heading" style={{height: '40px'}}>
                            <div className="panel-title col-xs-3">
                                <a href={`#bottle-${bottle.artkey}`} data-toggle="collapse">
                                    <span className={'glyphicon glyphicon-chevron-right'} />
                                </a>
                                {bottle.product.name}
                            </div>
                            <div className="col-xs-2">
                                <Decimal value={bottle.volume} number_of_fraction_digits={1} />
                            </div>
                            <div className="col-xs-2">
                                <Decimal value={bottle.alcohol_percentage} number_of_fraction_digits={1} />
                            </div>
                            <div className="col-xs-1">{bottle.refill_status}</div>
                            <div className="col-xs-2 text-capitalize">{bottle.product.category}</div>
                            <div className="col-xs-1">
                                <Amount amount={bottle.excise_nl} currency={$s.currencies.default} />
                            </div>
                            <div className="col-xs-1">
                                <Link target="_blank" href={`/market/charts/?bottle=${bottle.artkey}`}>
                                    <span className={'glyphicon glyphicon-signal'} />
                                </Link>
                            </div>
                        </div>
                        <div className="panel-body collapse in" id={`bottle-${bottle.artkey}`}>
                            {bottle.market.length ? (
                                <div key={`${bottle.artkey}`}>{this.market_items(bottle)}</div>
                            ) : (
                                <p key={`${bottle.artkey}`}>Not on active price lists</p>
                            )}
                        </div>
                    </div>
                ))}
                {this.bottles.can_show_more_items() && (
                    <LinkButton onclick={() => this.bottles.show_more()}>Show more results</LinkButton>
                )}
            </div>
        )
    }
}
