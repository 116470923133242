/** llm:tested */
import {Account} from '@/models/accounts'
import {User} from '@/models/users'
import {$m} from '@/app'

export class LeadContactStatus {
    static NOT_YET_APPROACHED = 'NOT_YET_APPROACHED'
    static APPROACHED = 'APPROACHED'
    static FINALIZED = 'FINALIZED'

    static choices = [
        [LeadContactStatus.NOT_YET_APPROACHED, 'Not yet approached'],
        [LeadContactStatus.APPROACHED, 'Approached'],
        [LeadContactStatus.FINALIZED, 'Finalized'],
    ]
}

export class Lead {
    artkey: any
    name: any
    company_type: any
    operates_online: any
    url: any
    status: any
    created_on: any
    was_last_updated_on: any
    contact_status: any
    street_address: any
    zip_code: any
    city: any
    country_code: any
    emailaddress: any
    telephone_number: any
    manager_artkey: any
    manager: any
    vat_id: any
    memo: any
    message: any
    account_artkey: any
    account: any
    converted_to_relation_artkey: any
    contact_persons: any
    warehouses: any
    redeem_code: any
    redeem_code_valid_until: any

    constructor(json: any) {
        this.artkey = window.prop('')
        this.name = window.prop('')
        this.company_type = window.prop('')
        this.operates_online = window.prop(false)
        this.url = window.prop('')
        this.status = window.prop('')

        this.created_on = window.prop('')
        this.was_last_updated_on = window.prop('')

        this.contact_status = window.prop('')

        this.street_address = window.prop('')
        this.zip_code = window.prop('')
        this.city = window.prop('')
        this.country_code = window.prop('')
        this.emailaddress = window.prop('')
        this.telephone_number = window.prop('')

        this.manager_artkey = window.prop('')
        this.manager = window.prop(new User({}))

        this.vat_id = window.prop('')

        this.memo = window.prop('')
        this.message = window.prop('')

        this.account_artkey = window.prop('')
        this.account = window.prop(new Account({}))

        this.converted_to_relation_artkey = window.prop('')

        this.contact_persons = window.prop([])
        this.warehouses = window.prop([])

        this.redeem_code = window.prop('')
        this.redeem_code_valid_until = window.prop('')

        for (const prop in json) {
            if (prop === 'manager') {
                this.manager($m.users.create_user(json[prop]))
            } else if (prop === 'contact_persons') {
                for (const contact_person of json[prop]) {
                    this.contact_persons().push(new LeadContactPerson(contact_person))
                }
            } else if (prop === 'warehouses') {
                for (const warehouse of json[prop]) {
                    this.warehouses().push(new LeadWarehouse(warehouse))
                }
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export class LeadContactPerson {
    [prop: string ]: any

    constructor(json: any) {
        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}

export class LeadWarehouse {
    country_code: any
    [prop: string ]: any

    constructor(json: any) {
        this.country_code = window.prop('')

        for (const prop in json) {
            this[prop] = window.prop(json[prop])
        }
    }
}
