/** llm:tested */
import m from 'mithril'

import {CollectionTable} from '@/components/collection_table'
import {Collection} from '@/components/collection/collection'

interface EmailPlaceholder {
    artkey: string
    placeholder: string
    description: string
}

export class EmailPlaceholdersList {
    email_placeholders: any

    constructor() {
        this.email_placeholders = new Collection({
            api_function_name: 'email_i18n_placeholders.get_email_placeholders',
            sort_order: [
                {name: 'placeholder', direction: 'asc'},
            ],
            query_limit: 20,
        })
    }

    oncreate() {
        this.email_placeholders.requery()
    }

    view() {
        return (
            <div class="c-email-i18n view">
                <div class="btn-toolbar">
                    <button
                        class="btn btn-default"
                        type="button"
                        onclick={() => m.route.set('/data/email-placeholders/create')}
                    >
                        <span class="glyphicon glyphicon-plus"></span> Create Email Placeholder
                    </button>
                </div>

                <CollectionTable
                    collection={this.email_placeholders}
                    options={{
                        sticky_header: true,
                        unique_name: 'email_placeholders',
                        onclick: (email_placeholder: EmailPlaceholder) => {
                            return () => {
                                m.route.set(`/data/email-placeholders/${email_placeholder.artkey}/edit`)
                            }

                        },
                    }}
                    columns={[
                        {
                            name: 'Placeholder',
                            width: 4,
                            header: 'Placeholder',
                            field: 'placeholder',
                        },
                        {
                            name: 'Description',
                            width: 4,
                            header: 'Description',
                            field: 'description',
                        },
                        {
                            name: 'Column selector',
                            header: '',
                            value: ' ',
                            width: 1,
                        },
                    ]}
                />
            </div>
        )
    }
}
