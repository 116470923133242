import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {required, validation} from '@bitstillery/common/lib/validation'
import {classes} from '@bitstillery/common/lib/utils'
import {notifier} from '@bitstillery/common/app'
import {FieldSelect, FieldText, Spinner} from '@bitstillery/common/components'

import {BackToListButton} from '@/components/discover'
import {EditAssist, Form} from '@/components/form'
import {BrandHolder, ProductManagementApi, UpdateBrandRequest} from '@/factserver_api/product_management_api'
import {HiddenBrandHelper, HiddenInformation} from '@/purchase_orders/view_purchase_order/hidden_information'

type SelectableMainTab = 'edit-brand' | 'hidden-information'

interface EditBrandUrlParams {
    selected_tab?: SelectableMainTab
}

export default class BrandEdit extends MithrilTsxComponent<unknown> {
    api = new ProductManagementApi()
    update_brand_request: UpdateBrandRequest = {
        name: '',
        match_text: '',
        brand_holder_artkey: null,
        hide_from_pricelist_for_countries: [],
        hide_from_pricelist_for_suppliers: [],
    }

    brand_holders: BrandHolder[] = []
    edit_assist: EditAssist
    selected_tab: SelectableMainTab = 'edit-brand'

    is_loading = false
    $v = {
        match_text: validation([this.update_brand_request, 'match_text'], required()),
        name: validation([this.update_brand_request, 'name'], required()),
    }

    constructor() {
        super()

        this.edit_assist = new EditAssist(m.route)
    }

    oncreate(): void {
        this.fetch_all_brand_holders()
        if (!this.edit_assist.is_creating) {
            this.fetch_brand(this.edit_assist.artkey)
        }

        const params = m.route.param() as EditBrandUrlParams
        if (params.selected_tab) {
            this.selected_tab = params.selected_tab
        }
    }

    update_url_params() {
        let route = m.route.get()
        if (route.includes('?')) {
            route = route.split('?')[0]
        }

        const params: EditBrandUrlParams = {
            selected_tab: this.selected_tab,
        }
        m.route.set(route, params)
    }

    update_selected_tab(selected_tab: SelectableMainTab): void {
        this.selected_tab = selected_tab
        this.update_url_params()
    }

    fetch_all_brand_holders(): void {
        this.api.get_all_brand_holders().subscribe({
            next: (response) => {
                this.brand_holders = response
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    fetch_brand(artkey: number | null): void {
        if (!artkey) {
            return
        }
        this.is_loading = true
        this.api.get_brand(artkey).subscribe({
            next: (response) => {
                Object.assign(this.update_brand_request, {
                    ...response,
                    hide_from_pricelist_for_countries: response.hide_from_pricelist_for_countries.map(
                        (entry) => entry.country_code,
                    ),
                    hide_from_pricelist_for_suppliers: response.hide_from_pricelist_for_suppliers.map(
                        (entry) => `${entry.artkey}`,
                    ),
                })
                this.is_loading = false
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    update_brand(): boolean {
        this.api.update_or_create_brand(this.update_brand_request).subscribe({
            next: () => {
                notifier.notify('Brand updated', 'success')
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
        return false
    }

    view(): m.Children {
        if (this.is_loading) return <Spinner />
        return (
            <div className="c-brands-edit view">
                <div className="btn-toolbar">
                    <BackToListButton href={'/data/brands'} />
                </div>

                <div className="c-tabs">
                    <ul className="nav nav-tabs">
                        <li
                            className={classes('nav-link', {
                                active: this.selected_tab === 'edit-brand',
                            })}
                            onclick={() => this.update_selected_tab('edit-brand')}
                        >
                            <a>Edit brand</a>
                        </li>
                        <li
                            className={classes('nav-link', {
                                active: this.selected_tab === 'hidden-information',
                            })}
                            onclick={() => this.update_selected_tab('hidden-information')}
                        >
                            <a>Hidden information</a>
                        </li>
                    </ul>
                    {this.selected_tab === 'hidden-information' && this.edit_assist.artkey &&
                        <HiddenInformation hidden_information_helper={new HiddenBrandHelper(this.edit_assist.artkey)}/>
                    }
                    {this.selected_tab === 'edit-brand' && <Form
                        onSubmit={() => this.update_brand()}
                        submitText={this.edit_assist.is_creating ? 'Create Brand' : 'Update Brand'}
                        validations={this.$v}
                    >
                        <div className="fieldset">
                            <FieldText
                                label="Name"
                                placeholder={'Brand name'}
                                model={[this.update_brand_request, 'name']}
                                validation={this.$v.name}
                            />

                            <FieldText
                                label="Match text"
                                model={[this.update_brand_request, 'match_text']}
                                placeholder={'match text'}
                                validation={this.$v.match_text}
                            />

                            <FieldSelect
                                label='Brand holder'
                                model={[this.update_brand_request, 'brand_holder_artkey']}
                                options={this.brand_holders.map((i: BrandHolder) => ({value: i.artkey, label: i.name}))}
                                placeholder="Select Brand holder..."
                            />
                        </div>
                    </Form>}
                </div>
            </div>
        )
    }
}
