/** llm:tested */
import m from 'mithril'
import {AccountSlug} from '@bitstillery/common/account/account'
import {Amount, Link} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {config} from '@bitstillery/common/app'

import {accountIcon} from '@/accounts'
import {icon, icon_with_popover} from '@/components/icon'
import {formatDateTime, formatDate, multiline} from '@/_utils'
import {SalesOrderStatus} from '@/sales_orders/models'
import {$s} from '@/app'

export class SalesOrderDetails extends MithrilTsxComponent<unknown> {
    private sales_order: any
    private portal_link: string
    private memo_is_active: boolean

    constructor(vnode: m.Vnode<{ sales_order: any }>) {
        super()
        this.sales_order = vnode.attrs.sales_order
        const base_url = this.sales_order().account().slug() === AccountSlug.ETR ? config.a2bc_portal_base_url : config.portal_base_url
        this.portal_link = `${base_url}/#!/orders/${this.sales_order().artkey()}`
        this.memo_is_active = false
    }

    toggle_memo_class() {
        this.memo_is_active = !this.memo_is_active
    }

    view() {
        return <div class="details">
            <div class="row">
                <div class="col-sm-6">
                    <dl class="dl-horizontal">
                        <dt>Reference</dt>
                        <dd>{this.sales_order().reference()}</dd>
                        <dt>Created on</dt>
                        <dd>{formatDateTime(this.sales_order().date())}</dd>
                        <dt>Created by</dt>
                        <dd>{this.sales_order().was_handled_by().profile().name()}</dd>
                        <dt>Sales manager</dt>
                        <dd>{this.sales_order().supplier().sales_manager().profile().name()}</dd>
                        <dt>Status</dt>
                        <dd>{this.sales_order().combined_status()}</dd>
                        <dt>Relation</dt>
                        <dd>
                            <span class="mr-05">{accountIcon(this.sales_order().account())}</span>
                            <Link
                                target="_blank"
                                href={'/crm/relations/' + this.sales_order().supplier().artkey() + '/edit'}
                            >
                                {this.sales_order().supplier().name()}
                            </Link>
                        </dd>
                        {this.sales_order().sales_order_status() === SalesOrderStatus.INVOICED && <span>
                            <dt>Invoiced on</dt>
                            <dd>{formatDateTime(this.sales_order().is_invoiced_on())}</dd>
                            <dt>Invoice number</dt>
                            <dd>{this.sales_order().invoice_number()}</dd>
                            <dt>Sent to FIS date</dt>
                            <dd>{formatDate(this.sales_order().was_sent_to_financial_information_system_on())}</dd>
                        </span>}
                        <dt>Currency</dt>
                        <dd>{this.sales_order().was_sold_in()}</dd>
                        {this.sales_order().was_sold_in() !== $s.currencies.default && <span>
                            <dt>Exchange rate</dt>
                            <dd>{this.sales_order().sold_against_rate()}</dd>
                        </span>}
                        <dt>Origin</dt>
                        <dd>{this.sales_order().origin().name() || '-'}</dd>
                        <dt>Destination</dt>
                        <dd>{this.sales_order().destination_location() || this.sales_order().destination().name()}</dd>
                        <dt>Incoterm</dt>
                        <dd>{this.sales_order().incoterm() + ' - ' + this.sales_order().incoterm_location()}</dd>
                        <dt>Req. delivery date</dt>
                        <dd>
                            {this.sales_order().requested_delivery_date()
                                ? formatDate(this.sales_order().requested_delivery_date())
                                : 'As soon as possible'
                            }
                            {' '}
                            {icon_with_popover({
                                iconId: 'info-sign',
                                title: 'Requested delivery date',
                                content: 'This is the delivery date as requested by the client in the portal.',
                            })}
                        </dd>
                        <dt>Green Transport</dt>
                        <dd>
                            {!this.sales_order().delivery().external_reference()
                                ? '-'
                                : this.sales_order().delivery().status() === 'CREATED'
                                    ? this.sales_order().delivery().external_reference() + ' - Created'
                                    : this.sales_order().delivery().status() === 'CONFIRMED'
                                        ? this.sales_order().delivery().external_reference() + ' - Confirmed'
                                        : null
                            }
                        </dd>
                        <dt>Remarks</dt>
                        <dd>{multiline(this.sales_order().remark())}</dd>
                    </dl>
                </div>
                <div class="col-sm-6">
                    <dl class="dl-horizontal">
                        <dt>Insurance</dt>
                        <dd>{this.sales_order().insurance_type().name()}</dd>
                        <dt>Number of cases</dt>
                        <dd>{this.sales_order().number_of_cases()}</dd>
                        <dt>Total purchased value</dt>
                        <dd>
                            <Amount
                                amount={this.sales_order().euro_purchasing_value()}
                                key={this.sales_order().euro_purchasing_value()}
                                currency={$s.currencies.default}
                            />
                        </dd>
                        <dt>Turnover</dt>
                        <dd>
                            <Amount
                                amount={this.sales_order().was_sold_for_excl_excise()}
                                currency={this.sales_order().was_sold_in()}
                                key={this.sales_order().was_sold_for_excl_excise()}
                                rate={this.sales_order().sold_against_rate()}
                            />
                        </dd>
                        <dt>Total margin</dt>
                        <dd>
                            <Amount
                                amount={this.sales_order().margin()}
                                currency={$s.currencies.default}
                                key={this.sales_order().margin()}
                            />
                        </dd>
                        <dt>Margin (%)</dt>
                        <dd>
                            {this.sales_order().margin_percentage()
                                ? (+this.sales_order().margin_percentage()).toFixed(2) + '%'
                                : '-'
                            }
                        </dd>
                        <dt>Excise</dt>
                        <dd>
                            <Amount
                                amount={this.sales_order().excise_total()}
                                currency={this.sales_order().was_sold_in()}
                                rate={this.sales_order().sold_against_rate()}
                                key={this.sales_order().excise_total()}
                            />
                        </dd>
                        <dt>Turnover incl. Excise</dt>
                        <dd>
                            <Amount
                                amount={this.sales_order().was_sold_for()}
                                currency={this.sales_order().was_sold_in()}
                                rate={this.sales_order().sold_against_rate()}
                                key={this.sales_order().was_sold_for()}
                            />
                        </dd>
                        <dt>VAT</dt>
                        <dd>
                            <Amount
                                amount={this.sales_order().vat_total()}
                                currency={this.sales_order().was_sold_in()}
                                key={this.sales_order().vat_total()}
                            />
                        </dd>
                        <dt>Grand Total</dt>
                        <dd>
                            <Amount
                                amount={this.sales_order().was_sold_for_incl_vat()}
                                currency={this.sales_order().was_sold_in()}
                                rate={this.sales_order().sold_against_rate()}
                                key={this.sales_order().was_sold_for_incl_vat()}
                            />
                        </dd>
                        <dt>Portal link</dt>
                        <dd>
                            <a
                                id="portal-link"
                                target="_blank"
                                href={this.portal_link}
                            >
                                {this.portal_link}
                            </a>
                            <span> </span>
                            <span
                                class="copy-to-clipboard-icon"
                                onclick={() => navigator.clipboard.writeText(this.portal_link)
                                    .then(() => alert('Copied link to clipboard.'))}
                            >
                                {icon('fa-copy')}
                            </span>
                        </dd>
                        <dt>Loading info</dt>
                        <dd>
                            {this.sales_order().delivery().weight_in_kilos() &&
                                <span>{this.sales_order().delivery().weight_in_kilos()} kg</span>}
                            {this.sales_order().delivery().number_of_euro_pallets() &&
                                <span>{this.sales_order().delivery().number_of_euro_pallets()} euro pallet(s)</span>}
                            {this.sales_order().delivery().number_of_block_pallets() &&
                                <span>{this.sales_order().delivery().number_of_block_pallets()} block pallet(s)</span>}
                        </dd>
                    </dl>
                </div>
            </div>
            {this.sales_order().supplier().memo() &&
                <div class="row">
                    <div class="col-sm-12">
                        <div
                            class={`alert alert-info ${this.memo_is_active ? 'sales-order-memo--active' : 'sales-order-memo'}`}
                            onclick={() => this.toggle_memo_class()}
                        >
                            <span class="glyphicon glyphicon-info-sign"></span>
                            <span> Memo: </span>
                            {this.memo_is_active && <br />}
                            <span>{this.sales_order().supplier().memo()}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
}
