/** llm:tested */
import m from 'mithril'
import {andList} from 'prelude-ls'
import {european_union_countries} from '@bitstillery/common/lib/countries'
import {Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {SalesOrderStatus} from '../models'

import {call} from '@/api'
import {preventDefault} from '@/_utils'
import {button_with_icon} from '@/components/_buttons'
import {checkbox} from '@/components/inputs'
import {check_vat_id} from '@/data/vatcheck/actions'
import {auto_attach_buy_from_account_supplier_invoices} from '@/purchase_orders/auto_attach_bfa_attachments'

interface InvoiceOrderAttrs {
    sales_order: any
    close: () => void
}

export class InvoiceOrder extends MithrilTsxComponent<InvoiceOrderAttrs> {
    private sales_order: any
    private close: () => void
    private is_submitting: boolean
    private confirm_vat: any
    private confirm_origin: any
    private confirm_destination: any
    private confirm_incoterm: any
    private confirm_incoterm_location: any
    private confirm_insurance_type: any
    private confirmation_fields: string[]
    private confirmation_props: Array<() => boolean>
    private requires_vat_check: boolean
    private vat_check_loading?: () => boolean

    constructor(vnode: m.Vnode<InvoiceOrderAttrs>) {
        super()
        this.sales_order = vnode.attrs.sales_order
        this.close = vnode.attrs.close
        this.is_submitting = false

        this.confirm_vat = window.prop(false)
        this.confirm_origin = window.prop(false)
        this.confirm_destination = window.prop(false)
        this.confirm_incoterm = window.prop(false)
        this.confirm_incoterm_location = window.prop(false)
        this.confirm_insurance_type = window.prop(false)

        this.confirmation_fields = [
            'vat',
            'origin',
            'destination',
            'incoterm',
            'incoterm_location',
            'insurance_type',
        ]

        this.confirmation_props = [
            this.confirm_vat,
            this.confirm_origin,
            this.confirm_destination,
            this.confirm_incoterm,
            this.confirm_incoterm_location,
            this.confirm_insurance_type,
        ]

        const vat_country_code = this.sales_order().vat_country_code()
        this.requires_vat_check = european_union_countries.includes(vat_country_code)

        if (this.requires_vat_check) {
            this.vat_check_loading = window.prop(false)
            check_vat_id(null, this.vat_check_loading, this.confirm_vat, vat_country_code, this.sales_order().vat_id())
        } else {
            this.confirm_vat(true)
        }
    }

    can_invoice(): boolean {
        return andList(this.confirmation_props.map(prop => prop()))
    }

    get_class(field: string): string {
        const index = this.confirmation_fields.indexOf(field)
        if (this.confirmation_props[index]()) {
            return '.confirmed'
        } else if (index === 0 || this.confirmation_props[index - 1]()) {
            return '.confirming'
        } else {
            return '.unconfirmed'
        }
    }

    get_disabled(field: string): boolean {
        const index = this.confirmation_fields.indexOf(field)
        if (index === 0) {
            return this.confirmation_props[index]()
        } else {
            return this.confirmation_props[index]() || !this.confirmation_props[index - 1]()
        }
    }

    auto_attach_invoice_to_related_purchase_orders(): void {
        auto_attach_buy_from_account_supplier_invoices(this.sales_order().artkey())
    }

    set_sales_order_status(): void {
        this.is_submitting = true
        const data = {
            sales_order_artkey: this.sales_order().artkey(),
            status: SalesOrderStatus.INVOICED,
        }
        call('sales.core.set_sales_order_status', data, this.handle_set_sales_order_status.bind(this))
    }

    handle_set_sales_order_status(resp: any): void {
        this.is_submitting = false
        if (resp.success) {
            if (this.sales_order().is_buy_from_account_sales_order()) {
                this.auto_attach_invoice_to_related_purchase_orders()
            }
            m.route.set(`/sales-orders/manage/${this.sales_order().artkey()}/invoice`)
        } else {
            notifier.notify(resp.message, 'danger')
        }
    }

    view(): m.Children {
        return <div class="c-invoice-order">
            <form class="flex-form" onsubmit={preventDefault(this.set_sales_order_status.bind(this))}>
                <div class="fieldset">
                    {this.requires_vat_check &&
                        <div class="field-readonly">
                            <div class="key">
                                <div class={this.get_class('vat')}>
                                    {this.sales_order().is_vat_shifted
                                        ? 'Destination VAT ID (VAT shifted) '
                                        : 'Relation VAT ID '}
                                    {this.vat_check_loading?.()
                                        ? <Spinner />
                                        : this.confirm_vat()
                                            ? <i class="fa fa-check text-success text-xl" />
                                            : <i class="fa fa-times text-danger text-xl" />
                                    }
                                </div>
                            </div>
                            <div class="value">
                                {this.sales_order().vat_id() || '-'}
                            </div>
                        </div>
                    }
                    <div class="field-readonly">
                        <div class="key">
                            <div class={this.get_class('origin')}>Origin warehouse</div>
                        </div>
                        <div class="value">
                            {checkbox(this.confirm_origin, {
                                disabled: this.get_disabled('origin'),
                                label: this.sales_order().origin().name() || '-',
                            })}
                        </div>
                    </div>
                    <div class="field-readonly">
                        <div class="key">
                            <div class={this.get_class('destination')}>Destination</div>
                        </div>
                        <div class="value">
                            {checkbox(this.confirm_destination, {
                                disabled: this.get_disabled('destination'),
                                label: this.sales_order().destination_location() || this.sales_order().destination().name(),
                            })}
                        </div>
                    </div>
                    <div class="field-readonly">
                        <div class="key">
                            <div class={this.get_class('incoterm')}>Incoterm</div>
                        </div>
                        <div class="value">
                            {checkbox(this.confirm_incoterm, {
                                disabled: this.get_disabled('incoterm'),
                                label: this.sales_order().incoterm(),
                            })}
                        </div>
                    </div>
                    <div class="field-readonly">
                        <div class="key">
                            <div class={this.get_class('incoterm_location')}>Incoterm location</div>
                        </div>
                        <div class="value">
                            {checkbox(this.confirm_incoterm_location, {
                                disabled: this.get_disabled('incoterm_location'),
                                label: this.sales_order().incoterm_location(),
                            })}
                        </div>
                    </div>
                    <div class="field-readonly">
                        <div class="key">
                            <div class={this.get_class('insurance_type')}>Insurance type</div>
                        </div>
                        <div class="value">
                            {checkbox(this.confirm_insurance_type, {
                                disabled: this.get_disabled('insurance_type'),
                                label: this.sales_order().insurance_type().name(),
                            })}
                        </div>
                    </div>
                </div>
                <div class="btn-toolbar">
                    {button_with_icon('Cancel', 'ban-circle', {
                        class: 'btn-default',
                        type: 'button',
                        onclick: this.close,
                    })}
                    {button_with_icon('Edit order', 'edit', {
                        class: 'btn-default',
                        type: 'button',
                        onclick: () => m.route.set('/sales-orders/manage/' + this.sales_order().artkey() + '/edit'),
                    })}
                    {button_with_icon('Invoice Sales Order', 'book', {
                        class: 'btn-success',
                        disabled: !this.can_invoice() || this.is_submitting,
                        type: 'submit',
                    })}
                </div>
            </form>
        </div>
    }
}
