import {Spinner} from '@bitstillery/common/components'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {get_url_params, set_url_params} from '@bitstillery/common/url_utils'
import {api} from '@bitstillery/common/app.ts'

import {AttachmentHelper, AttachmentList, UploadAttachment} from '../components/attachment_list'
import {EdiMessages} from '../components/edi_messages'

import PurchaseOrderButtons from './view_purchase_order/buttons'
import {PurchaseOrderDetails} from './view_purchase_order/details'
import {SupplierMemo} from './view_purchase_order/supplier_memo'
import {LoenderslootConflictHelper, LoenderslootConflictList} from './components/loendersloot_conflict_list'
import {SignedPurchaseRFP} from './view_purchase_order/signed_purchase_rfp'
import {PurchaseOrderItems} from './view_purchase_order/purchase_order_items'
import {BuyFromAccountBanner} from './view_purchase_order/buy_from_account_banner'
import {HiddenInformation, HiddenRelationHelper} from './view_purchase_order/hidden_information'
import {PurchaseOrderCosts} from './components/purchase_order_costs'

import {PurchaseOrderEmailCorrespondence} from '@/purchase_orders/components/purchase_order_email_correspondence'
import {AddItem} from '@/purchase_orders/components/add_item'
import {PagedCollectionFetcherWithTotal} from '@/components/collection/collection_table'
import {AttachmentType} from '@/factserver_api/attachment_api'
import {
    GetPurchaseOrderItemsCollectionViewResponse,
    GetPurchaseOrderResponse,
    PurchaseOrderStatus,
} from '@/factserver_api/fact2server_api'
import {DefaultButton} from '@/components/buttons'

type purchase_order_tab = 'items' | 'hidden-information' | 'conflicts' | 'edi' | 'attachments' | 'emails' | 'costs'

interface PurchaseOrderParams {
    tab?: purchase_order_tab
}

export default class PurchaseOrder extends MithrilTsxComponent<unknown> {

    is_loading_purchase_order = false
    purchase_order_artkey: number
    purchase_order: GetPurchaseOrderResponse | null = null
    attachment_helper: AttachmentHelper
    loendersloot_conflict_helper: LoenderslootConflictHelper
    showing_add_product = false
    poi_fetcher: PagedCollectionFetcherWithTotal<GetPurchaseOrderItemsCollectionViewResponse>

    params: PurchaseOrderParams

    constructor() {
        super()
        const artkey = m.route.param('artkey')
        if (!artkey) {
            m.route.set('/')
            throw Error('No artkey found in url')
        }

        this.purchase_order_artkey = artkey.startsWith('P') ? +artkey.substring(1) : +artkey
        this.attachment_helper = new AttachmentHelper(this.purchase_order_artkey, AttachmentType.PURCHASE_ORDER)
        this.loendersloot_conflict_helper = new LoenderslootConflictHelper(this.purchase_order_artkey)

        this.poi_fetcher = new PagedCollectionFetcherWithTotal<GetPurchaseOrderItemsCollectionViewResponse>(
            `discover/purchase-orders/${this.purchase_order_artkey}/items/collection-view`,
            'product_name',
        )

        this.params = get_url_params<PurchaseOrderParams>()
        if (!this.params.tab) {
            this.params.tab = 'items'
        }
    }

    async oncreate() {
        await this.load_purchase_order()
    }

    set_tab(tab: purchase_order_tab): void {
        this.params.tab = tab
        set_url_params<PurchaseOrderParams>(this.params)
    }

    async load_purchase_order() {
        this.is_loading_purchase_order = true
        const {
            result,
            status_code,
        } = await api.get<GetPurchaseOrderResponse>(`discover/purchase-orders/${this.purchase_order_artkey}`)
        this.is_loading_purchase_order = false
        if (status_code > 299) {
            return
        }
        this.purchase_order = result
        m.redraw()
    }

    view(_: m.Vnode<unknown>): m.Children {
        const children: m.Children = []
        if (!this.purchase_order) {
            children.push(<Spinner/>)
        } else {
            children.push(
                <PurchaseOrderButtons
                    purchase_order={this.purchase_order}
                    reload_needed={async() => await this.load_purchase_order()}
                />)
            children.push(<BuyFromAccountBanner purchase_order={this.purchase_order}/>)
            children.push(<div className={'columns'}>
                <div className={'column is-four-fifths'}>
                    <PurchaseOrderDetails purchase_order={this.purchase_order}/>
                </div>
                <div className={'column'}>
                    <UploadAttachment attachment_helper={this.attachment_helper}/>
                </div>
            </div>)
            children.push(<SupplierMemo supplier_memo={this.purchase_order.supplier.memo}/>)
            children.push(<SignedPurchaseRFP purchase_order={this.purchase_order}/>)

            if ([PurchaseOrderStatus.Saved, PurchaseOrderStatus.Confirmed].includes(this.purchase_order.status)) {
                if (!this.showing_add_product && this.purchase_order) {
                    children.push(
                        <div>
                            <DefaultButton
                                title={'Add product to order'}
                                icon_class={'glyphicon glyphicon-plus'}
                                onclick={() => this.showing_add_product = !this.showing_add_product}
                            /></div>)
                }
                if (this.showing_add_product && this.purchase_order) {
                    children.push(<div>
                        <DefaultButton
                            title={'Add product to order'}
                            icon_class={'glyphicon glyphicon-minus'}
                            onclick={() => this.showing_add_product = !this.showing_add_product}
                        />
                        <AddItem
                            on_added_to_order={() => {
                                this.load_purchase_order()
                                this.poi_fetcher.reset_and_query()
                            }}
                            purchase_order={() => {
                                return {
                                    artkey: () => this.purchase_order?.artkey,
                                    was_bought_in: () => this.purchase_order?.was_bought_in,
                                    supplier: () => {
                                        return {
                                            artkey: () => this.purchase_order?.supplier.artkey,
                                        }
                                    },
                                }
                            }}
                        />
                    </div>)
                }

            }

            children.push(
                <div className={'tab-view'}>
                    <div className="c-tabs">
                        <ul className="nav nav-tabs">
                            <li
                                className={classes('nav-link', {
                                    active: this.params.tab === 'items',
                                })}
                                onclick={() => this.set_tab('items')}
                            >
                                <a>Items ({this.purchase_order.number_of_order_lines})</a>
                            </li>
                            <li
                                className={classes('nav-link', {
                                    active: this.params.tab === 'attachments',
                                })}
                                onclick={() => this.set_tab('attachments')}
                            >
                                <a>Attachments ({this.attachment_helper.attachments.length})</a>
                            </li>
                            <li
                                className={classes('nav-link', {
                                    active: this.params.tab === 'costs',
                                })}
                                onclick={() => this.set_tab('costs')}
                            >
                                <a>Costs</a>
                            </li>
                            <li
                                className={classes('nav-link', {
                                    active: this.params.tab === 'emails',
                                })}
                                onclick={() => this.set_tab('emails')}
                            >
                                <a>Emails</a>
                            </li>
                            <li
                                className={classes('nav-link', {
                                    active: this.params.tab === 'edi',
                                })}
                                onclick={() => this.set_tab('edi')}
                            >
                                <a>EDI ({this.purchase_order.number_of_edi_messages})</a>
                            </li>
                            <li
                                className={classes('nav-link', {
                                    active: this.params.tab === 'conflicts',
                                })}
                                onclick={() => this.set_tab('conflicts')}
                            >
                                <a>Conflicts</a>
                            </li>
                            <li
                                className={classes('nav-link', {
                                    active: this.params.tab === 'hidden-information',
                                })}
                                onclick={() => this.set_tab('hidden-information')}
                            >
                                <a>
                                    Hide information supplier
                                    ({this.purchase_order.supplier.number_of_countries_hidden}/{this.purchase_order.supplier.number_of_hide_from_pricelist_for_suppliers})
                                </a>
                            </li>
                        </ul>
                    </div>
                    {this.params.tab === 'items' && <PurchaseOrderItems
                        purchase_order={this.purchase_order}
                        poi_fetcher={this.poi_fetcher}
                    />}
                    {this.params.tab === 'hidden-information' && <HiddenInformation
                        hidden_information_helper={new HiddenRelationHelper(this.purchase_order.supplier.artkey)}/>}
                    {this.params.tab === 'attachments' && <AttachmentList attachment_helper={this.attachment_helper}/>}
                    {this.params.tab === 'edi' && <EdiMessages purchase_order_artkey={this.purchase_order.artkey}/>}
                    {this.params.tab === 'costs' &&
                        <PurchaseOrderCosts purchase_order_artkey={this.purchase_order.artkey}/>}
                    {this.params.tab === 'emails' && <PurchaseOrderEmailCorrespondence
                        purchase_order={() => {
                            return {
                                artkey: () => this.purchase_order_artkey,
                            }
                        }}
                    />}
                    {this.params.tab === 'conflicts' && <LoenderslootConflictList
                        purchase_order_artkey={this.purchase_order.artkey}
                        loendersloot_conflict_helper={this.loendersloot_conflict_helper}
                    />}
                </div>,
            )
        }
        return <div className="c-view-purchase-order view">{children}</div>
    }
}
