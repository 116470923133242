import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DataCard, Amount, AmountUnit, Spinner, ButtonGroup, Button, Lot} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {api} from '@bitstillery/common/app'

import {FilterType, SearchFilterJson} from './models'

import {BottleThroughputResponse} from '@/factserver_api/fact2server_api'
import {accountIconBySlug} from '@/accounts'
import {BottleThroughput} from '@/components/market_info/bottle_throughput'
import {Link} from '@/components/discover'
import {Pager} from '@/components/pager/pager.tsx'
import {Panel} from '@/components/panel.tsx'

interface ShowPurchaseHistoryAttrs {
    purchase_history: Array<any>
    purchase_history_total: number
    count: number
    limit: number
    fetch_page: (page_index: number) => void
    filter_type: FilterType
    show_specs: boolean
    loading: boolean
    title: string
    toolbar?: JSX.Element
    collapsible?: boolean
    collapsed?: boolean
    download_callback?: () => void
    unique_name: string
    header: () => string
    search_filters: Array<SearchFilterJson>
}

/**
 * Component to display purchase history in the market explore view.
 */
export class ShowPurchaseHistory extends MithrilTsxComponent<ShowPurchaseHistoryAttrs> {
    throughputs: Array<{
        search_filter: SearchFilterJson
        throughput: BottleThroughputResponse | null
    }>

    async oninit(vnode: m.Vnode<ShowPurchaseHistoryAttrs>) {
        await this._query_bottle_throughput(vnode.attrs)
    }

    async _query_bottle_throughput(attrs: ShowPurchaseHistoryAttrs) {
        this.throughputs = []
        for (const search_filter of attrs.search_filters) {
            const params = {
                product_artkey: search_filter.product_artkey,
                volume: search_filter.bottle_volume,
                alcohol_percentage: search_filter.bottle_alcohol_percentage,
            }
            if (search_filter.bottle_refill_status) {
                params['refill_status'] = search_filter.bottle_refill_status
            }
            if (!params.product_artkey) {
                // Not searching for a product.
                continue
            }
            if (!params.volume || !params.alcohol_percentage) {
                // If volume or alcohol percentage is not set, throughput cannot be calculated.
                const throughput_entry = {
                    search_filter: search_filter,
                    throughput: null,
                }
                this.throughputs.push(throughput_entry)
                continue
            }
            const quantity_bought_url = 'discover/bottles/quantity-bought'
            const response = await api.get(quantity_bought_url, params)
            const throughput = response.result as BottleThroughputResponse
            const throughput_entry = {
                search_filter: search_filter,
                throughput: throughput,
            }
            this.throughputs.push(throughput_entry)
        }
        m.redraw()
    }

    view(vnode: m.Vnode<ShowPurchaseHistoryAttrs>) {
        const {
            count,
            limit,
            fetch_page,
            filter_type,
            show_specs,
            loading,
            title,
            toolbar,
            collapsible,
            collapsed,
            download_callback,
            header,
            purchase_history,
            purchase_history_total,
        } = vnode.attrs

        // Show product column only if filter type is neither BOTH nor PRODUCT
        let show_product = filter_type !== FilterType.BOTH && filter_type !== FilterType.PRODUCT
        // Show relation column only if filter type is neither BOTH nor RELATION
        let show_relation = filter_type !== FilterType.BOTH && filter_type !== FilterType.RELATION
        let show_specs_flag = show_specs && show_product

        const purchase_history_toolbar = count > 0 ? (
            <Pager
                page_size={limit}
                count={count}
                fetch_page={fetch_page}
                display_page_count={false}
            />
        ) : undefined

        return (
            <div className="c-market-explore-purchase-history">
                <Panel
                    title={title}
                    toolbar={toolbar || purchase_history_toolbar}
                    collapsible={collapsible}
                    collapsed={collapsed}
                    download_callback={count > 0 ? download_callback : undefined}
                >
                    {loading ? (
                        <Spinner />
                    ) : purchase_history.length > 0 ? (
                        [
                            <div className={'flex c-data-card-container'}>
                                <DataCard
                                    model={{
                                        data: [
                                            {label: 'Purchase history for', value: header},
                                            {
                                                label: 'Total purchase value',
                                                value: (
                                                    <Amount
                                                        amount={+purchase_history_total}
                                                        currency={'EUR'}
                                                    />
                                                ),
                                            },
                                        ],
                                    }}
                                    type="dense"
                                />
                                {this.throughputs.map((throughput_entry, index) => (
                                    <DataCard
                                        key={index}
                                        model={{
                                            data: [
                                                {label: 'Throughput for', value: throughput_entry.search_filter.product_name},
                                                {
                                                    label: throughput_entry.throughput
                                                        ? <BottleThroughput prefix="Purchased" type="Long" throughput={throughput_entry.throughput} />
                                                        : 'Please select volume and alcohol percentage',
                                                    hide_value: true,
                                                },
                                            ],
                                        }}
                                        type="dense"
                                    />
                                ))}
                            </div>,
                            <PurchaseHistoryTable
                                purchase_history={purchase_history}
                                show_specs={show_specs_flag}
                                show_product={show_product}
                                show_relation={show_relation}
                            />,
                        ]
                    ) : (
                        'No results'
                    )}
                </Panel>
            </div>
        )
    }
}

interface PurchaseHistoryTableAttrs {
    purchase_history: Array<any>
    show_specs: boolean
    show_product: boolean
    show_relation: boolean
}

/**
 * Component to render the purchase history table with dynamic columns.
 */
class PurchaseHistoryTable extends MithrilTsxComponent<PurchaseHistoryTableAttrs> {
    view(vnode: m.Vnode<PurchaseHistoryTableAttrs>) {
        const {purchase_history, show_specs, show_product, show_relation} = vnode.attrs

        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Purchase Order</th>
                        <th>Status</th>
                        {show_relation && <th>Supplier</th>}
                        {show_product && <th>Product</th>}
                        <th>Cases</th>
                        <th>Purchase Price</th>
                        <th>Total Price</th>
                        <th>Warehouse</th>
                        <th className="actions">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {purchase_history.map(record => (
                        <tr key={record.purchase_order_reference}>
                            <td>
                                <div className="td-group">
                                    <span className="header">
                                        {accountIconBySlug(record.account_slug, record.account_name)}
                                        <Link
                                            target="_blank"
                                            href={`/purchase-orders/manage/${record.purchase_order_reference}?account=${record.account_slug}`}
                                        >
                                            {record.purchase_order_reference}
                                        </Link>
                                    </span>
                                    {show_specs && (
                                        <span className="details">
                                            {to_specs({
                                                bottle_volume: record.bottle_volume,
                                                bottle_alcohol_percentage: record.bottle_alcohol_percentage,
                                                bottle_refill_status: record.bottle_refill_status,
                                                case_customs_status: record.case_customs_status,
                                                case_gift_box_type: record.case_gift_box_type,
                                                case_number_of_bottles: record.case_number_of_bottles,
                                                case_tax_label: record.case_tax_label,
                                            })}
                                        </span>
                                    )}
                                    {record.aux_info && <span className="details">{record.aux_info}</span>}
                                </div>
                            </td>
                            <td>
                                <div className="td-group">
                                    <span>{record.purchase_order_status}</span>
                                    <span className="details">{record.purchase_order_latest_status_update}</span>
                                </div>
                            </td>
                            {show_relation && <td>{record.supplier_name}</td>}
                            {show_product && <td>{record.product_name}</td>}
                            <td className="center">{record.purchase_order_item_number_of_cases}</td>
                            <td>
                                <AmountUnit
                                    case_amount={+record.purchase_order_item_was_bought_for}
                                    case_number_of_bottles={record.case_number_of_bottles}
                                    currency={record.purchase_order_was_bought_in}
                                    display_currency={'EUR'}
                                    excise={+record.case_excise_nl}
                                    rate={record.purchase_order_bought_against_rate}
                                    origin={record.purchase_order_was_bought_in !== 'EUR' ? {
                                        case_amount: +record.purchase_order_item_was_bought_for,
                                        currency: record.purchase_order_was_bought_in,
                                    } : undefined}
                                />
                            </td>
                            <td>
                                <Amount
                                    amount={+record.purchase_order_item_total_was_bought_for}
                                    excise={+record.case_excise_nl * record.purchase_order_item_number_of_cases}
                                    currency={record.purchase_order_was_bought_in}
                                    rate={record.purchase_order_bought_against_rate}
                                />
                            </td>
                            <td>
                                {record.lots.sort().map(lot_number => (
                                    <Lot
                                        key={lot_number}
                                        lot_number={lot_number}
                                        warehouse_name={record.warehouse_name}
                                    />
                                ))}
                            </td>
                            <td className="actions">
                                <div className="actions-group no-click">
                                    <ButtonGroup>
                                        {record.references && record.references.sort().map(reference => (
                                            <Button
                                                key={reference}
                                                icon="stock"
                                                onclick={() => window.open(`#!/stock/manage/${reference}`)}
                                                tip={`Manage stock item ${reference}`}
                                            />
                                        ))}
                                    </ButtonGroup>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}
