/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

interface EmailPreviewAttrs {
    email: () => string
}

export class EmailPreview extends MithrilTsxComponent<EmailPreviewAttrs> {
    private email: () => string

    oninit(vnode: m.Vnode<EmailPreviewAttrs>) {
        this.email = vnode.attrs.email
    }

    view() {
        return <div class="email-preview">
            {this.email() && m.trust(this.email())}
        </div>
    }
}
