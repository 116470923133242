/** llm:production */
import m from 'mithril'
import {Button} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {items_table} from '@/stock/components/items_table'
import {ItemForm} from '@/stock/components/item_form'
import * as inputs from '@/components/inputs'
import {Item, ItemMutationReason} from '@/models/stock'
import {$m, $s} from '@/app'

export default class MutationCreateUpdateCases {
    item: any
    items: any
    base_item_json: any
    updated_item: any
    new_items: any
    _number_of_cases: any
    reason: any
    description: any

    constructor() {
        const item_reference = m.route.param('reference')
        this.item = window.prop(new Item())
        this.items = window.prop([])
        this.base_item_json = window.prop({})
        this.updated_item = window.prop(new Item())

        this.new_items = window.prop([])

        this._number_of_cases = window.prop('')
        this.reason = window.prop('')
        this.description = window.prop('')

        this.get_item(item_reference)
    }

    create_base_item(json: any) {
        this.base_item_json(json)
        this.base_item_json().lot = ''
        this.base_item_json().number_of_bottles_per_case = ''
        this.base_item_json().number_of_cases_in_stock = ''
    }

    get_item(item_reference: string) {
        const data = {
            item_reference: item_reference,
        }

        api.call2('stock.get_item', data, (resp: any) => {
            this.item(new Item(resp.result))
            this.items([this.item()])
            this.updated_item(new Item(resp.result))
            this.create_base_item(resp.result)
        })
    }

    number_of_cases(number_of_cases?: string) {
        if (number_of_cases !== undefined) {
            this._number_of_cases(number_of_cases)
            this.updated_item().number_of_cases_in_stock(+this._number_of_cases() + +this.item().number_of_cases_in_stock())
            this.updated_item().number_of_cases_available(+this._number_of_cases() + +this.item().number_of_cases_available())
        } else {
            return this._number_of_cases()
        }
    }

    create_mutation(e: Event) {
        e.preventDefault()

        const new_items = this.new_items().map((item: any) => item.toObject())

        const data = {
            user_artkey: $s.identity.artkey,
            item_mutation_reason: this.reason(),
            description: this.description(),
            item_artkey: +this.item().artkey(),
            number_of_cases: +this._number_of_cases(),
            new_items: new_items,
        }
        api.call('stock.create_item_mutation_update_cases', data, this.handle_create_mutation)
    }

    handle_create_mutation(resp: any) {
        if (resp.success) {
            $m.common.observable.broadcast('stock_updated', '')
            notifier.notify('Created mutation successfully', 'success')
            m.route.set(`/stock/mutations/manage/${resp.result}`)
        } else {
            notifier.notify(resp.message, 'danger')
        }
    }

    view() {
        return (
            <div class="c-create-mutation view">
                <div class="btn-toolbar">
                    <Button
                        active={false}
                        icon="back"
                        onclick={() => m.route.set(`/stock/manage/${this.item().reference()}`)}
                        variant="toggle"
                    />
                </div>

                <div class="panel panel-primary">
                    <div class="panel-heading">
                        <div class="panel-title">Current stock</div>
                    </div>
                    <div class="panel-body">
                        {items_table(this.items)}
                    </div>
                </div>

                <form class="flex-form" onsubmit={this.create_mutation.bind(this)}>
                    <ItemForm
                        item={this.updated_item}
                        new_items={this.new_items}
                        base_item_json={this.base_item_json}
                        fields={['lot', 'case', 'stock', 'number_of_bottles_per_case']}
                        reason={this.reason}
                    />

                    <div class="fieldset">
                        <div class="field">
                            <label>Difference in cases</label>
                            {inputs.number(this.number_of_cases.bind(this), {required: true})}
                            <div class="help">
                                New stock: <strong>{+this.number_of_cases() + +this.item().number_of_cases_in_stock()}</strong>
                            </div>
                        </div>

                        <div class="field">
                            <label>Reason</label>
                            {inputs.select(this.reason, ItemMutationReason.update_cases_options, {required: true})}
                        </div>
                        <div class="field">
                            <label>Description</label>
                            {inputs.textarea(this.description, {required: true})}
                        </div>
                    </div>
                    <div class="btn-toolbar">
                        <Button
                            class_name="btn-submit"
                            onclick={this.create_mutation.bind(this)}
                            text="Update Cases"
                            type="success"
                        />
                    </div>
                </form>
            </div>
        )
    }
}
