/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api_ls from '@/api'
import {number} from '@/components/inputs'
import {preventDefault} from '@/_utils'
import {$m} from '@/app'

interface CustomOfferItem {
    artkey: string
    name: string
    position: number
}

interface EditPositionAttrs {
    custom_offer_item: CustomOfferItem
    max: number
    onchangedsuccessfully: () => void
}

export class EditPosition extends MithrilTsxComponent<unknown> {
    private custom_offer_item: CustomOfferItem
    private max: number
    private onchangedsuccessfully: () => void
    private new_position: () => number | null
    private saving: any

    constructor(vnode: m.Vnode<EditPositionAttrs>) {
        super()
        this.custom_offer_item = vnode.attrs.custom_offer_item
        this.max = vnode.attrs.max
        this.onchangedsuccessfully = vnode.attrs.onchangedsuccessfully

        this.new_position = window.prop(null)
        this.saving = window.prop(false)
    }

    change_position = () => {
        this.saving(true)
        const data = {
            artkey: this.custom_offer_item.artkey,
            new_position: this.new_position(),
        }

        api_ls.callAndThen('offer.change_custom_offer_item_position', data, {
            success: () => {
                $m.common.observable.broadcast('custom_offer_item_updated')
                notifier.notify(`Changed position of ${this.custom_offer_item.name} from ${this.custom_offer_item.position} to ${this.new_position()}.`, 'success')
                this.onchangedsuccessfully()
            },
            failure: (resp: any) => {
                notifier.notify(resp.message, 'danger')
                this.saving(false)
            },
        })
    }

    view() {
        return (
            <form class="form-horizontal" onsubmit={preventDefault(this.change_position)}>
                <div class="field">
                    <label class="col-sm-2 control-label">Current position</label>
                    <div class="col-sm-4">
                        {number(window.prop(this.custom_offer_item.position), {
                            disabled: true,
                        })}
                    </div>
                </div>

                <div class="field">
                    <label class="col-sm-2 control-label">New position</label>
                    <div class="col-sm-4">
                        {number(this.new_position, {
                            focus: true,
                            min: 1,
                            max: this.max,
                            required: true,
                        })}
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-sm-offset-2">
                        <button
                            class="btn btn-primary"
                            type="submit"
                            disabled={this.saving()}
                        >
                            Save new position
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}
