/** llm:tested */
// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {DateTime} from 'luxon'
import {excise_countries} from '@bitstillery/common/excise'
import {FieldMoney, Tippy} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {
    download_binary_file_from_base64_str,
    formatDateTime,
    formatDate,
    formatPercentage,
} from '@/_utils'
import {button_with_icon} from '@/components/_buttons'
import {CollectionTable} from '@/components/collection_table'
import {Collection} from '@/components/collection/collection'
import inputs from '@/components/inputs'
import {PercentInput} from '@/components/decimal_input'
import {UserDropDown} from '@/components/users'
import panel from '@/components/_panel'
import {$m, $s} from '@/app'

interface PortalDemoUser {
    artkey: number
    currency_code: string
    include_ukds_in_price: boolean
    country_code: string
    show_excise_in_portal: boolean
    include_excise_in_price: boolean
    name: string
    portal_customs_visibility: string
    price_preference: string
    transport_costs_per_case: string
    minimum_order_amount: string
    price_markup_percentage: string
    sales_manager_artkey: number
    expires_on: string
    created_on: string
    sales_manager_profile_name: string
    invite_code_used: boolean
    invite_url?: string
}

export class PortalDemoUsers extends MithrilTsxComponent<any> {
    portal_demo_users: any
    is_collapsed: any
    sales_manager_artkey: any
    currency_code: any
    include_ukds_in_price: any
    country_code: any
    show_excise_in_portal: any
    include_excise_in_price: any
    number_of_accounts: any
    expires_on: any
    name: any
    portal_customs_visibility: any
    price_preference: any
    transport_costs_per_case: any
    minimum_order_amount: any
    price_markup_percentage: any

    constructor() {
        super()
        this.portal_demo_users = new Collection({
            api_function_name: 'portal.demo_user.get_all',
            query_limit: 25,
            sort_order: [
                {name: 'artkey', direction: 'desc'},
            ],
            default_sort_by: 'artkey',
            default_sort_order: 'desc',
            filter_serverside: true,
            paged: false,
            additional_params: () => ({}),
        })

        this.is_collapsed = window.prop(true)
        this.sales_manager_artkey = window.prop('')
        if (!this.sales_manager_artkey) {
            this.sales_manager_artkey = $m.users.legacy_user.make_setting_prop(
                ['demo_portal_user', 'show-user'],
                $s.identity.artkey,
            )
        }

        this.currency_code = window.prop('')
        this.include_ukds_in_price = window.prop(false)
        this.country_code = window.prop('')
        this.show_excise_in_portal = window.prop(false)
        this.include_excise_in_price = window.prop(false)
        this.number_of_accounts = window.prop('')
        this.expires_on = window.prop('')
        this.name = window.prop('')
        this.portal_customs_visibility = window.prop('both')
        this.price_preference = window.prop('case')
        this.transport_costs_per_case = window.prop('')
        this.minimum_order_amount = window.prop('')
        this.price_markup_percentage = window.prop('')
    }

    oncreate() {
        this.portal_demo_users.init()
        this.input_fields_to_default()
    }

    deactivate_account(artkey: number) {
        const data = {
            artkey: artkey,
        }
        api.callAndThen('portal.demo_user.delete_account', data, {
            success: () => {
                this.portal_demo_users.requery()
            },
        })
    }

    cleanup_old_accounts() {
        api.callAndThen('portal.demo_user.clean_up_expired_accounts', {}, {
            success: (resp: { number_of_deleted_accounts: number }) => {
                notifier.notify(`A total of ${resp.number_of_deleted_accounts} demo accounts were deleted`, 'info')
                this.portal_demo_users.requery()
            },
        })
    }

    input_fields_to_default() {
        this.currency_code('EUR')
        this.include_ukds_in_price(false)
        this.country_code('NL')
        this.show_excise_in_portal(false)
        this.include_excise_in_price(false)
        this.number_of_accounts(1)
        this.expires_on(DateTime.local().plus({days: 30}))
        this.name('')
        this.portal_customs_visibility('both')
        this.price_preference('case')
        this.transport_costs_per_case('')
        this.minimum_order_amount('')
        this.price_markup_percentage('')
    }

    fill_create_fields_with(record: PortalDemoUser) {
        this.currency_code(record.currency_code)
        this.include_ukds_in_price(record.include_ukds_in_price)
        this.country_code(record.country_code)
        this.show_excise_in_portal(record.show_excise_in_portal)
        this.include_excise_in_price(record.include_excise_in_price)
        this.number_of_accounts(1)
        this.expires_on('')
        this.name(record.name)
        this.portal_customs_visibility(record.portal_customs_visibility)
        this.price_preference(record.price_preference)
        this.transport_costs_per_case(record.transport_costs_per_case)
        this.minimum_order_amount(record.minimum_order_amount)
        this.price_markup_percentage(record.price_markup_percentage)
        this.sales_manager_artkey(record.sales_manager_artkey)
        this.expires_on(record.expires_on)
        this.is_collapsed(false)
    }

    save() {
        const data = {
            currency_code: this.currency_code(),
            include_ukds_in_price: this.include_ukds_in_price(),
            include_excise_in_price: this.include_excise_in_price(),
            show_excise_in_portal: this.show_excise_in_portal(),
            number_of_accounts: this.number_of_accounts(),
            name: this.name(),
            portal_customs_visibility: this.portal_customs_visibility(),
            price_preference: this.price_preference(),
            country_code: this.country_code(),
            transport_costs_per_case: this.transport_costs_per_case(),
            minimum_order_amount: this.minimum_order_amount(),
            price_markup_percentage: this.price_markup_percentage(),
            expires_on: this.expires_on(),
            sales_manager_artkey: +this.sales_manager_artkey(),
        }

        api.callAndThen('portal.demo_user.create_accounts', data, {
            success: (resp: { result: string }) => {
                this.portal_demo_users.requery()
                this.input_fields_to_default()
                download_binary_file_from_base64_str(resp.result, 'invite-urls.xlsx')
            },
        })
    }

    view() {
        return <div class="c-portal-demo-users view">
            <div class="btn-toolbar">
                {button_with_icon('Cleanup old accounts', 'remove', {
                    class: 'btn-danger pull-right',
                    onclick: () => this.cleanup_old_accounts(),
                })}
            </div>

            {panel('Generate invite codes',
                <form class="flex-form" onsubmit={(e) => {
                    e.preventDefault()
                    this.save()
                }}>
                    <div class="fieldset">
                        {inputs.text(this.name, {
                            label: 'Name',
                            required: true,
                        })}

                        <UserDropDown
                            label="Sales manager"
                            model={[this, 'sales_manager_artkey']}
                        />

                        <div class="field-group">
                            {inputs.number(this.number_of_accounts, {
                                label: 'Number of accounts',
                                required: true,
                            })}
                            {inputs.date(this.expires_on, {
                                label: 'Expires on',
                                required: true,
                            })}
                        </div>

                        {inputs.select(this.price_preference, ['Case', 'Bottle'], {
                            empty_option: false,
                            label: 'Price unit',
                            required: true,
                        })}

                        {inputs.select(this.portal_customs_visibility, ['T1', 'T2', 'both'], {
                            empty_option: false,
                            label: 'Customs Status visibility',
                            required: true,
                        })}

                        {inputs.text(this.country_code, {
                            label: 'Country code',
                            required: true,
                            after_update: (ev: any) => {
                                if (ev.target.value !== 'NL') {
                                    this.include_excise_in_price(false)
                                }
                            },
                        })}

                        {inputs.checkbox(this.include_excise_in_price, {
                            disabled: !excise_countries.includes(this.country_code()),
                            help: `${excise_countries.join(', ')} only`,
                            label: 'Include excise in price',
                        })}

                        {inputs.select(this.currency_code, $s.currencies.all, {
                            empty_option: false,
                            label: 'Currency',
                        })}

                        {this.currency_code() === 'GBP' &&
                            inputs.checkbox(this.include_ukds_in_price, {
                                disabled: this.currency_code() !== 'GBP',
                                label: 'Include UKDS in price',
                            })
                        }

                        {inputs.checkbox(this.show_excise_in_portal, {
                            label: 'Show excise in portal',
                        })}

                        <FieldMoney
                            currency={[this, 'currency_code']}
                            label="Transports costs per case"
                            model={[this, 'transport_costs_per_case']}
                        />

                        <FieldMoney
                            currency={[this, 'currency_code']}
                            label="Minimum order quantity"
                            model={[this, 'minimum_order_amount']}
                        />

                        <PercentInput
                            label="Price markup percentage"
                            value={this.price_markup_percentage()}
                            on_value={(value: string) => this.price_markup_percentage(value)}
                        />
                    </div>

                    <button class="btn btn-success btn-submit">Create Demo Users</button>
                </form>
                , null, {
                    collapsible: true,
                    collapsed: this.is_collapsed(),
                    unique_name: 'i-am-unique',
                })}

            <CollectionTable
                collection={this.portal_demo_users}
                options={{
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: true,
                    autoscale: true,
                    unique_name: 'portal_demo_users',
                }}
                columns={[
                    {
                        width: 1,
                        name: 'artkey',
                        sort: true,
                        field: 'artkey',
                    },
                    {
                        width: 2,
                        name: 'Created on',
                        field: 'created_on',
                        sort: true,
                        transform: formatDate,
                    },
                    {
                        width: 2,
                        name: 'Name',
                        field: 'name',
                    },
                    {
                        width: 2,
                        name: 'Sales manager',
                        field: 'sales_manager_profile_name',
                    },
                    {
                        width: 2,
                        name: 'Invite code used',
                        function: (record: PortalDemoUser) =>
                            record.invite_code_used && <i class="glyphicon glyphicon-check"/>,
                    },
                    {
                        width: 3,
                        name: 'Summary',
                        function: (record: PortalDemoUser) => {
                            const excise = record.show_excise_in_portal ? 'Yes' : 'No'
                            const include_excise = record.include_excise_in_price ? 'Yes' : 'No'
                            const show_ukds = record.include_ukds_in_price ? 'Yes' : 'No'

                            return <span>
                                {`Currency: ${record.currency_code}, `}
                                {`Country: ${record.country_code}, `}
                                {`Show excise: ${excise}, Include excise in price: ${include_excise}, `}
                                {`Include UKDS: ${show_ukds}, `}
                                {`Minimum order amount: ${record.minimum_order_amount ? (+record.minimum_order_amount) : 0}, `}
                                {`Transport costs: ${record.transport_costs_per_case ? (+record.transport_costs_per_case) : 0}, `}
                                {`Price markup: ${formatPercentage(record.price_markup_percentage)}`}
                            </span>
                        },
                    },
                    {
                        width: 2,
                        name: 'Expires on',
                        field: 'expires_on',
                        sort: true,
                        transform: formatDateTime,
                    },
                    {
                        width: 2,
                        name: 'Actions',
                        sort: false,
                        function: (record: PortalDemoUser) => [
                            button_with_icon('', 'fa-trash', {
                                class: 'btn-default',
                                onclick: (e: Event) => {
                                    e.stopPropagation()
                                    this.deactivate_account(record.artkey)
                                    return false
                                },
                            }),
                            record.invite_url &&
                                <Tippy content="Copy invite URL to clipboard">
                                    {button_with_icon('', 'fa-copy', {
                                        class: 'btn-default',
                                        onclick: (e: Event) => {
                                            e.stopPropagation()
                                            navigator.clipboard.writeText(record.invite_url!).then(() => {
                                                notifier.notify('Invite URL copied to clipboard', 'info')
                                            }, () => {
                                                notifier.notify('Cannot copy to the clipboard.', 'danger')
                                            })
                                            return false
                                        },
                                    })}
                                </Tippy>,
                            <Tippy content="Regenerate invite code">
                                {button_with_icon('', 'glyphicon-refresh', {
                                    class: 'btn-default',
                                    onclick: (e: Event) => {
                                        e.stopPropagation()
                                        const data = {
                                            artkey: record.artkey,
                                        }
                                        api.callAndThen('portal.demo_user.generate_invite', data, {
                                            success: () => {
                                                this.portal_demo_users.requery()
                                            },
                                        })
                                        return false
                                    },
                                })}
                            </Tippy>,
                            <Tippy content="Register as in use">
                                {button_with_icon('', 'glyphicon-check', {
                                    class: 'btn-default',
                                    onclick: (e: Event) => {
                                        e.stopPropagation()
                                        const data = {
                                            artkey: record.artkey,
                                        }
                                        api.callAndThen('portal.demo_user.register_invite_code_as_used', data, {
                                            success: () => this.portal_demo_users.requery(),
                                        })
                                        return false
                                    },
                                })}
                            </Tippy>,
                            <Tippy content="Use these values in the create new">
                                {button_with_icon('', 'glyphicon-pencil', {
                                    class: 'btn-default',
                                    onclick: (e: Event) => {
                                        e.stopPropagation()
                                        this.fill_create_fields_with(record)
                                        return false
                                    },
                                })}
                            </Tippy>,
                        ],
                    },
                ]}
            />
        </div>
    }
}
