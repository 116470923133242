/** llm:production */
import m from 'mithril'
import $ from 'jquery'
import {union, slice, sortWith, minimum, find} from 'prelude-ls'
import {debounce} from '@bitstillery/common/ts_utils'

import api from '@/api'
import {copy, maybeMap} from '@/_utils'
import utils from '@/_utils'
import {pager} from '@/components/pager/_pager'
import {$m} from '@/app'

export class Collection {
    constructor(options: any) {
        if (!options.dont_reuse && m.route.get() in $m.data.collection_states) {
            const cached_collection = $m.data.collection_states[m.route.get()]
            cached_collection.additional_params = options.additional_params || window.prop({})
            cached_collection.filter_function = options.filter_function || window.prop(true)
            return cached_collection
        }

        this.limit = options.query_limit
        this.api_function_name = options.api_function_name

        this.proxy_collection = options.proxy_collection
        this.on_query_start = options.on_query_start
        this.on_query_end = options.on_query_end
        this.filter_function = options.filter_function || window.prop(true)
        this.filter_serverside = window.prop(options.filter_serverside || false)
        this.additional_params = options.additional_params || window.prop({})
        this.search_term = options.search_term || window.prop('')
        this.default_sort_order = options.sort_order || []
        this.sort_order = window.prop(this.default_sort_order)
        this.sort_by = window.prop(options.default_sort_by || '')
        this.ascending = window.prop(options.default_sort_order === 'asc')
        this.paged = options.paged || false
        this.hide_show_more_items = options.hide_show_more_items || false
        this.only_query_on_search = options.only_query_on_search || false
        this.data_key = options.data_key || null

        this.items = window.prop([])
        this.search_result = window.prop([])
        this.can_show_more_items = window.prop(false)
        this.loading = window.prop(false)
        this.queried = window.prop(false)
        this.total_number_of_items = window.prop(null)
        this.search_terms = window.prop([])
        this.query_data = window.prop({})

        this.aggs = {}

        this.offset = 0
        this.show_count = this.limit
        this._sort_order = window.prop([])
        this.route = m.route.get()

        if (!this.paged) {
            this.scroll_handler = debounce(150, this.onscroll.bind(this))
            $(window).on('scroll', this.scroll_handler)
        }

        if (!options.dont_reuse) {
            $m.data.collection_states[m.route.get()] = this
        }
    }

    onremove() {
        if (!this.paged) {
            $(window).off('scroll', this.scroll_handler)
        }
    }

    onscroll() {
        if (this.route !== m.route.get()) {
            return
        }
        if (m.route.get() in $m.data.scroll_states) {
            $m.data.scroll_states[m.route.get()] = $(window).scrollTop()
            if (!this.loading() && $(window).scrollTop() >= $(document).height() - $(window).height() - 1000) {
                if (this.can_show_more_items()) {
                    this.show_more()
                }
            }
        } else {
            $m.data.scroll_states[m.route.get()] = 0
        }
    }

    init() {
        const page = parseInt(m.route.param('page'))
        if (page) {
            this.init_page(page)
        } else {
            this.query()
        }
    }

    onpagechange(page: number) {
        const new_offset = (page - 1) * this.limit
        this.load_until(new_offset + this.limit)
        this.offset = new_offset
    }

    init_page(page: number) {
        this.show_count = page * this.limit
        this.offset = 0
        this.query({}, this.offset, this.show_count)
        this.offset = this.show_count - this.limit
    }

    update() {
        this.query()
    }

    requery() {
        this.items([])
        if (this.filter_serverside()) {
            this.search_result([])
        }
        if (this.show_count < this.limit) {
            this.show_count = this.limit
        }
        this.query({}, 0, this.show_count + 1)
    }

    requery_condition() {
        return true
    }

    query(params?: any, offset?: number, limit?: number) {
        if (this.only_query_on_search && !this.search_terms().length) {
            this.items([])
            this.search_result([])
        } else {
            if (this.on_query_start) {
                this.on_query_start()
            }
            this.queried(true)
            this.loading(true)
            m.redraw()

            const data: any = {
                offset: offset !== undefined ? offset : this.offset,
                limit: limit !== undefined ? limit + 1 : this.limit + 1,
                search_terms: this.search_terms(),
                sort_order: this.sort_order(),
                sort_by: this.sort_by(),
                ascending: this.ascending(),
            }
            for (const [key, value] of Object.entries(this.additional_params())) {
                data[key] = value
            }

            this.query_data(data)

            $m.common.observable.broadcast(`collection.${this.api_function_name}.before-call`)

            api.call(this.api_function_name, data, this.handle_query.bind(this))
        }
    }

    handle_query(resp: any) {
        if (!resp.success) {
            $m.common.generic_error_handler('Query was not successful')
            return
        }

        if (!resp.result) {
            $m.common.generic_error_handler('Provide the result of the query as \'{\'result\': [...]}\'')
            return
        }

        const result = resp.result

        const data = this.data_key ? result[this.data_key] : result

        const updated_artkeys = data.map((r: any) => r.artkey)
        this.items(union(this.items().filter((item: any) => !updated_artkeys.includes(item.artkey)), data))

        if ('total' in resp || 'total_count' in resp || 'total' in result || 'total_count' in result) {
            this.total_number_of_items(resp.total || resp.total_count || result.total || result.total_count || 0)
            this.show_count = this.limit_show_count(this.show_count)

            if (this.proxy_collection) {
                this.proxy_collection.total = this.total_number_of_items()
            }
        }

        if ('aggs' in resp && Object.keys(resp.aggs).length) {
            for (const [k, v] of Object.entries(resp.aggs)) {
                if (k in this.aggs) {
                    this.aggs[k](v)
                } else {
                    this.aggs[k] = window.prop(v)
                }
            }
        } else {
            this.aggs = {}
        }

        if (this.proxy_collection) {
            this.proxy_collection.items.splice(0, this.proxy_collection.items.length, ...result)
        }

        this.filter_items()
        if (this.on_query_end) {
            this.on_query_end()
        }
    }

    sort_func(x: any, y: any) {
        for (const sort of this._sort_order()) {
            const x_prop = get_prop_or_string(x, sort.name)
            const y_prop = get_prop_or_string(y, sort.name)
            if (x_prop > y_prop) {
                return sort.direction === 'asc' ? 1 : -1
            } else if (x_prop < y_prop) {
                return sort.direction === 'asc' ? -1 : 1
            }
        }
        return 0
    }

    filter_items() {
        let sorted
        if (!this.filter_serverside()) {
            const filtered = this.items().filter(this.is_search_match.bind(this))
            if (this.sort_by()) {
                this._sort_order(this.sort_order().filter((a: any) => a.name !== this.sort_by()))
                this._sort_order().unshift({name: this.sort_by(), direction: this.ascending() ? 'asc' : 'desc'})
            } else {
                this._sort_order(this.sort_order())
            }

            if (this._sort_order().length) {
                sorted = sortWith(this.sort_func.bind(this), filtered)
            } else {
                sorted = filtered
            }
        } else {
            sorted = this.items()
        }

        this.can_show_more_items(this.show_count < this.total_number_of_items())

        this.search_result(slice(0, this.show_count, sorted))

        this.loading(false)

        $m.common.observable.broadcast(`collection.${this.api_function_name}.after-call`, {})
    }

    is_search_match(item: any) {
        for (let term of union(this.search_terms().filter((search_term: string) => search_term !== '\\'), [''])) {
            let keyword = null
            if (term.indexOf(':') > 0) {
                const components = term.split(':')
                keyword = components.shift()
                term = components.join(':')
            }
            if (!this.filter_function(item, term, this.additional_params(), keyword)) {
                return false
            }
        }
        return true
    }

    update_search_term(value: string) {
        this.search_term(value)
        this.search_terms(this.search_term().length > 0
            ? this.search_term().toLowerCase().split(' ').map((token: string) => token.trim())
            : [])
    }

    submit_search_event(e: KeyboardEvent) {
        if (e.keyCode === 13) { // enter
            this.submit_search()
        }
    }

    submit_search() {
        this.show_count = this.limit
        this.offset = 0
        if (this.filter_serverside()) {
            this.requery()
        } else {
            this.filter_items()
            this.query()
        }
    }

    reset_search() {
        this.show_count = this.limit
        this.offset = 0
        this.queried(false)
        this.update_search_term('')
        this.requery()
    }

    sort(column: string, ascending: boolean = true, sort_order: any[] = []) {
        if (typeof ascending === 'object') {
            ascending = ascending['ascending'] || true
        }

        if (this.sort_by() !== column) {
            this.ascending(ascending)
        } else {
            this.ascending(!this.ascending())
        }

        if (ascending !== this.ascending()) {
            const flip_direction = ({name, direction}: {name: string; direction: string}) => ({
                name,
                direction: direction === 'asc' ? 'desc' : 'asc',
            })

            sort_order = sort_order.map(copy).map(flip_direction)
        }

        this.sort_by(column)
        this.sort_order(sort_order.concat(this.default_sort_order))
        this.requery()
    }

    sort_icon(column: string) {
        if (column === this.sort_by()) {
            return <span class={`glyphicon ${this.ascending() ? 'glyphicon-triangle-top' : 'glyphicon-triangle-bottom'}`} />
        }
    }

    show_more() {
        this.show_count = this.limit_show_count(this.limit + this.show_count)
        this.offset += this.limit
        this.query()
    }

    load_until(count: number) {
        const new_show_count = this.limit_show_count(count)
        const num_to_load = new_show_count - this.show_count
        if (num_to_load > 0) {
            this.offset = this.show_count
            this.show_count = new_show_count
            this.query(null, this.offset, num_to_load)
        }
    }

    showing_all() {
        return this.show_count >= this.total_number_of_items()
    }

    limit_show_count(count: number) {
        return this.total_number_of_items() !== null && count > this.total_number_of_items()
            ? this.total_number_of_items()
            : count
    }

    show_all() {
        this.show_count = this.total_number_of_items()
        this.query(null, 0, this.total_number_of_items())
    }

    show_counter() {
        const show_count = minimum([this.show_count, this.total_number_of_items()])
        return <div class="collection-counter">
            {!this.loading() && [
                'Showing ', show_count, ' of ', this.total_number_of_items(), ' results ',
            ]}
        </div>
    }

    pager() {
        return pager({
            page_size: this.limit,
            count: this.total_number_of_items,
            offset: this.offset,
            loading: this.loading,
            onpagechange: this.onpagechange.bind(this),
        })
    }

    no_results() {
        return this.queried() && !this.search_result().length && !this.loading()
    }

    soft_delete(artkey: string) {
        this.items(this.items().filter((item: any) => item.artkey !== artkey))
        this.filter_items()
    }

    update_item_property(artkey: string, property_name: string, value: any) {
        this.update_item(artkey, (item: any) => item[property_name] = value)
    }

    update_item(artkey: string, fn: (item: any) => void) {
        maybeMap(fn, find((item: any) => item.artkey === artkey, this.items()))
    }

    requery_filter(property: any) {
        return (value: any) => {
            if (value !== undefined) {
                property(value)
                if (this.requery_condition()) {
                    this.requery()
                }
            } else {
                return property()
            }
        }
    }
}

function get_prop_or_string(obj: any, desc: string) {
    let descendant_prop = utils.get_descendant_prop(obj, desc)
    if (descendant_prop === undefined) {
        descendant_prop = ''
    }

    const number = +descendant_prop
    if (number) {
        return number
    }

    if (typeof descendant_prop === 'string') {
        descendant_prop = descendant_prop.toLowerCase().replace(/[ .*+?^${}()}|[\]\\]/g, '')
    }
    return descendant_prop
}
