/**
 * Contains components relating to money and currency.
 */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'

import {$s} from '@/app'

interface DecimalInputAttrs {
    label?: string
    required?: boolean
    disabled?: boolean
    minimum?: number
    placeholder?: string
    additional_class?: string
    show_addon?: boolean /* default true */
    number_of_fraction_digits?: number

    /* Current value of the component. */
    value: number | null
    /* The value, if empty, value will be 0 if empty with is_empty true. */
    on_value: (value: number, is_empty: boolean) => unknown
    /* default false - the currency string should be wrapped in an object if you want to change the value in the parent component*/
    show_currency_edit?: boolean
}

class DecimalInputHelper {
    input_value = 0 // cached value as a number
    display_input_value = '' // cached value as a string
    show_addon = true
    show_currency_edit = false

    allowed_input: string[] = []

    minimum_value: number
    decimal_separator: string
    number_of_fraction_digits: number

    /* The percentage, if empty, price will be 0 if empty with is_empty true. */
    on_value: (value: number, is_empty: boolean) => unknown

    constructor(vnode: m.Vnode<DecimalInputAttrs>) {
        this.decimal_separator = $s.identity.user.decimal_locale === 'en' ? '.' : ','
        this.minimum_value = vnode.attrs.minimum || 0
        this.allowed_input = [this.decimal_separator, '-']

        this.number_of_fraction_digits = 2
        if (vnode.attrs.number_of_fraction_digits !== undefined) {
            this.number_of_fraction_digits = vnode.attrs.number_of_fraction_digits
        }

        this.on_value = vnode.attrs.on_value
        this.input_value = vnode.attrs.value || 0
        this.display_input_value = this.format_display_value(this.input_value)

        if (vnode.attrs.show_addon !== undefined) {
            this.show_addon = vnode.attrs.show_addon
        }

        if (vnode.attrs.show_currency_edit !== undefined) {
            this.show_currency_edit = vnode.attrs.show_currency_edit
        }
    }

    /** Format the number value to a displayable value (2 digits, "" if null) */
    format_display_value(value: number | null): string {
        if (!value) {
            return ''
        }
        return (+value).toFixed(this.number_of_fraction_digits).replace('.', this.decimal_separator)
    }

    /** Transform a string (display value) to a number, taking decimal separator into account. */
    transform_display_value_to_number(value: string): number {
        if (!value) {
            return 0
        }
        /* -0 will render as '-' */
        if (value === '-') {
            return -0
        }
        return +value.replaceAll(this.decimal_separator, '.')
    }

    /** Updates the string representation and the number value AND signals the change to the parent. */
    update_cached_values(value: string): void {
        this.display_input_value = value
        this.input_value = this.transform_display_value_to_number(value)

        this.on_value(this.input_value, this.display_input_value === '')
    }

    /** Set parent number value, and set internal store as-is in the input. */
    onupdate(vnode: m.Vnode<any>): void {
        if (vnode.attrs.value !== this.input_value) {
            this.input_value = vnode.attrs.value || 0
            this.display_input_value = this.format_display_value(vnode.attrs.value)
        }
    }

    oninput(input_event: InputEvent): void {
        const target = input_event.target as HTMLInputElement
        const value = target.value

        this.validate(value, target)
        this.update_cached_values(value)
    }

    /** Return true if valid input, false otherwise. */
    validate(value: string, target: HTMLInputElement): boolean {
        target.setCustomValidity('')
        // validate the input values
        if (this.minimum_value !== undefined && +value < this.minimum_value) {
            target.setCustomValidity(`Minimum value is ${this.minimum_value}`)
        }
        return target.checkValidity()
    }

    keypress(keyboard_event: KeyboardEvent): boolean {
        const key_value = keyboard_event.key
        const input_already_contains_decimal_separator = this.display_input_value.includes(this.decimal_separator)
        if (input_already_contains_decimal_separator && key_value === this.decimal_separator) {
            return false
        }
        if (isNaN(+key_value) && !this.allowed_input.includes(key_value)) {
            return false
        }
        return true
    }

    /** Set parent number value, and set internal store to the formatted representation. */
    onchange(input_event: InputEvent): void {
        const value = (input_event.target as HTMLInputElement).value
        this.update_cached_values(value)
    }
}

type PercentInputAttrs = DecimalInputAttrs

/**
 * Input a percentage, while dealing with the users decimal separator setting.
 */
export class PercentInput extends MithrilTsxComponent<PercentInputAttrs> {
    decimal_input_helper: DecimalInputHelper

    constructor(vnode: m.Vnode<PercentInputAttrs>) {
        super()

        this.decimal_input_helper = new DecimalInputHelper(vnode)
    }

    onupdate(vnode: m.Vnode<any>): void {
        this.decimal_input_helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<PercentInputAttrs>): m.Children {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        return (
            <div class={classes('c-field-percent field', vnode.attrs.additional_class, {
                invalid: validation && invalid && validation.dirty,
                valid: validation && !invalid && validation.dirty,
            })}>
                {vnode.attrs.label && <label>{vnode.attrs.label}</label>}
                <div className="control">
                    <input
                        required={vnode.attrs.required}
                        disabled={vnode.attrs.disabled}
                        type="text"
                        placeholder={vnode.attrs.placeholder}
                        value={this.decimal_input_helper.display_input_value}
                        onkeypress={(keyboard_event: KeyboardEvent) => this.decimal_input_helper.keypress(keyboard_event)}
                        oninput={(input_event: InputEvent) => {
                            if (vnode.attrs.validation) {
                                vnode.attrs.validation.dirty = true
                            }

                            return this.decimal_input_helper.oninput(input_event)
                        }}
                        onchange={(input_event: InputEvent) => this.decimal_input_helper.onchange(input_event)}
                    />
                    {this.decimal_input_helper.show_addon && <span class="control-addon">%</span>}
                </div>
                {(() => {
                    if (invalid && validation.dirty) return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                    else if (vnode.attrs.help) return <div className="help">{vnode.attrs.help}</div>
                })()}
            </div>
        )
    }
}

/**
 * Input a decimal, while dealing with the users decimal separator setting.
 */
export class DecimalInput extends MithrilTsxComponent<DecimalInputAttrs> {
    decimal_input_helper: DecimalInputHelper

    constructor(vnode: m.Vnode<DecimalInputAttrs>) {
        super()

        this.decimal_input_helper = new DecimalInputHelper(vnode)
    }

    onupdate(vnode: m.Vnode<DecimalInputAttrs>): void {
        this.decimal_input_helper.onupdate(vnode)
    }

    view(vnode: m.Vnode<DecimalInputAttrs>): m.Children {
        const element = (
            <div class="input-group no-click">
                <input
                    required={vnode.attrs.required}
                    disabled={vnode.attrs.disabled}
                    type="text"
                    placeholder={vnode.attrs.placeholder}
                    value={this.decimal_input_helper.display_input_value}
                    onkeypress={(keyboard_event: KeyboardEvent) => this.decimal_input_helper.keypress(keyboard_event)}
                    oninput={(input_event: InputEvent) => this.decimal_input_helper.oninput(input_event)}
                    onchange={(input_event: InputEvent) => this.decimal_input_helper.onchange(input_event)}
                    class={`number-input ${vnode.attrs.additional_class || ''}`}
                />
            </div>
        )

        if (!vnode.attrs.label) {
            return element
        }

        return (
            <div className={classes('c-decimal-input field', vnode.attrs.className)}>
                {!!vnode.attrs.label && <label>{vnode.attrs.label}</label>}
                {element}
                {!!vnode.attrs.help && <div className="help">{vnode.attrs.help}</div>}
            </div>
        )
    }
}
