import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/models/item'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {DateTime} from 'luxon'
import {notifier} from '@bitstillery/common/app'
import {Amount, FieldMoney, Spinner, Tippy} from '@bitstillery/common/components'

import {Link} from '../components/discover'
import {NumberInput} from '../components/input_numbers'
import {
    DamagesDropDown,
    GeneralDropDown,
    GiftBoxTypeDropDown,
    PackagingLabelDropDown,
    PackSizeDropDown,
    TaxLabelDropDown,
} from '../components/case_inputs'
import {CaseLabels} from '../components/labels'
import {ProductPhotoList} from '../components/product_photos/product_photo_list'
import {CaseInfoPanel} from '../components/market_info/case_info_panel'

import {$s} from '@/app'
import {ProductPhotoApi} from '@/factserver_api/product_photos'
import {GetTBOToApproveResponse, PricelistApi} from '@/factserver_api/pricelist_api'
import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {GetProductPhotoResponse} from '@/factserver_api/fact2server_api'
import {DangerButton, DefaultButton} from '@/components/buttons'
import {CheckBox, InputDate} from '@/components/html_components'
import {convert_from_currency_to_euro, CurrenciesApi} from '@/factserver_api/currencies'
import {TagModel} from '@/factserver_api/item_tags'

interface TBOToApprove extends GetTBOToApproveResponse {
    show_spli_information: boolean
    is_editing: boolean
    is_showing_details: boolean
    is_calling_api: boolean
}

export default class TBOToApproveList extends MithrilTsxComponent<unknown> {
    approval_fetcher = new PagedCollectionFetcher<TBOToApprove>('pricelist.get_tbo_to_approve', 'name')
    currency = 'EUR'
    currencies_api = new CurrenciesApi()
    pricelist_api = new PricelistApi()

    tag_model:any

    async oninit() {
        await this.currencies_api.get_all_currencies()
        this.tag_model = new TagModel()
        await this.tag_model.load_data()
    }

    can_approve(row: TBOToApprove): boolean {
        const spli_price_per_case = row.originating_spli.price_per_case
        const spli_currency = row.originating_spli.currency
        const spli_price_per_case_in_eur = convert_from_currency_to_euro(
            spli_price_per_case || 0,
            spli_currency)

        return (row.maximum_quantity &&
            row.maximum_quantity !== 0 &&
            row.maximum_quantity > (row.minimum_quantity || 0) &&
            row.price_per_case &&
            row.price_per_case > spli_price_per_case_in_eur &&
            row.price_per_case > 0) as boolean
    }

    start_edit(row: TBOToApprove): void {
        const tag_ids = row.case.serialized_item_tags.split(',').filter((i) => i).map((i) => Number(i)) as any
        this.tag_model.set_selection(tag_ids)
        row.is_editing = true
    }

    cancel_edit(row: TBOToApprove): void {
        row.is_editing = false
    }

    approve(row: TBOToApprove): void {
        row.is_calling_api = true
        this.pricelist_api.approve_tbo_offer_item(row.artkey).subscribe({
            next: () => {
                notifier.notify('Successfully approved tbo item', 'success')
                row.is_calling_api = false
                this.approval_fetcher.reset_and_query()
            },
            error: () => {
                row.is_calling_api = false
            },
        })
    }

    delete_offer_item(row: TBOToApprove): void {
        this.pricelist_api.delete_tbo_from_pricelist(row.artkey).subscribe({
            next: () => {
                row.is_calling_api = false
                notifier.notify('Successfully removed tbo item from this list', 'success')
                this.approval_fetcher.reset_and_query()
            },
            error: () => {
                row.is_calling_api = false
            },
        })
    }

    save(row: TBOToApprove): void {
        row.is_calling_api = true
        this.pricelist_api
            .update_tbo_offer_item({
                artkey: row.artkey,
                case_artkey: row.case.artkey,
                number_of_bottles: row.case.number_of_bottles,
                gift_box_type: row.case.gift_box_type,
                tax_label: row.case.tax_label,
                customs_status: row.case.customs_status,
                best_before_date: row.case.best_before_date || '',
                item_tags: this.tag_model.serialize_selection(),
                spli_artkey: row.originating_spli.artkey,
                minimum_quantity: row.minimum_quantity,
                quantity: row.maximum_quantity,
                delivery_period: row.delivery_period,
                price_per_case: row.price_per_case,
            })
            .subscribe({
                next: () => {
                    row.is_editing = false
                    row.is_calling_api = false
                    notifier.notify('TBO offer item updated', 'success')
                    m.redraw()
                },
                error: () => {
                    row.is_calling_api = false
                    m.redraw()
                },
            })
    }

    is_price_same_as_spli(row: GetTBOToApproveResponse): boolean {
        const price_per_case = row.price_per_case
        const spli_price_per_case =
            row.originating_spli.price_per_case ||
            row.originating_spli.price_per_bottle * row.originating_spli.number_of_bottles_per_case

        return price_per_case === spli_price_per_case
    }

    view(): m.Children {
        return (
            <div className="c-pricelist-work view">
                <CollectionTable<TBOToApprove, unknown>
                    collection_fetcher={this.approval_fetcher}
                    on_row_click_component={TBODetailView}
                    on_row_click={(row: TBOToApprove) => (row.is_showing_details = !row.is_showing_details)}
                    additional_tr_classname={'no-click'}
                >
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Product name'}
                        sort_name={'name'}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {row.case.bottle.product.name}
                                {row.show_spli_information && (
                                    <div>
                                        <Link
                                            href={`market/pricelists/${row.originating_spli.supplier_price_list.artkey}`}
                                        >
                                            Pricelist{' '}
                                            {format_iso_to_date(row.originating_spli.supplier_price_list.start_date)}
                                            {row.originating_spli.supplier_price_list.end_date && (
                                                <span>
                                                    {' '}
                                                    -{' '}
                                                    {format_iso_to_date(
                                                        row.originating_spli.supplier_price_list.end_date,
                                                    )}
                                                </span>
                                            )}
                                            <span className={'help-block'}>
                                                {row.originating_spli.supplier_price_list.status}
                                            </span>
                                            <span className={'help-block'}>
                                                {
                                                    row.originating_spli.supplier_price_list
                                                        .supplier_price_list_description
                                                }
                                            </span>
                                        </Link>
                                    </div>
                                )}
                            </span>
                        )}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Supplier'}
                        sort_name={'supplier_name'}
                        data_field={(row: TBOToApprove) => (
                            <Link href={`/crm/relations/${row.supplier.artkey}/edit`}>{row.supplier.name}</Link>
                        )}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Specs'}
                        data_field={(row: TBOToApprove) => to_specs(row.case.bottle, $s.identity.user.decimal_locale)}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Delivery period'}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {!row.is_editing && <span>{row.delivery_period}</span>}
                                {row.is_editing && (
                                    <NumberInput
                                        value={row.delivery_period}
                                        minimum={0}
                                        oninput={(value: number) => (row.delivery_period = value)}
                                    />
                                )}
                            </span>
                        )}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Minimum quantity'}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {!row.is_editing && <span>{row.minimum_quantity}</span>}
                                {row.is_editing && (
                                    <NumberInput
                                        value={row.minimum_quantity}
                                        minimum={0}
                                        oninput={(value: number) => (row.minimum_quantity = value)}
                                    />
                                )}
                            </span>
                        )}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Maximum quantity'}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {!row.is_editing && <span>{row.maximum_quantity}</span>}
                                {row.is_editing && (
                                    <NumberInput
                                        value={row.maximum_quantity}
                                        minimum={0}
                                        oninput={(value: number) => (row.maximum_quantity = value)}
                                    />
                                )}
                                {row.show_spli_information && row.originating_spli.number_of_cases && (
                                    <span className={'help-block'}>
                                        {row.originating_spli.number_of_cases} cs available
                                    </span>
                                )}
                                {row.show_spli_information && row.originating_spli.number_of_bottles && (
                                    <span className={'help-block'}>
                                        {row.originating_spli.number_of_bottles} btls available
                                    </span>
                                )}
                            </span>
                        )}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Btls / case'}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {!row.is_editing && <span>{row.case.number_of_bottles}</span>}
                                {row.is_editing && (
                                    <NumberInput
                                        value={row.case.number_of_bottles}
                                        minimum={0}
                                        oninput={(value: number) => (row.case.number_of_bottles = value)}
                                    />
                                )}
                                {row.show_spli_information && (
                                    <span>
                                        <span className={'help-block'}>
                                            {row.originating_spli.number_of_bottles_per_case}
                                        </span>
                                    </span>
                                )}
                            </span>
                        )}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'GB'}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {!row.is_editing && <span>{row.case.gift_box_type}</span>}
                                {row.is_editing && (
                                    <GiftBoxTypeDropDown model={[row.case, 'gift_box_type']} />
                                )}
                                {row.show_spli_information && (
                                    <span>
                                        <span className={'help-block'}>{row.originating_spli.gift_box_type}</span>
                                    </span>
                                )}
                            </span>
                        )}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Tags and features'}
                        data_field={(row: TBOToApprove) => {
                            return <span className="no-click">
                                {!row.is_editing && (
                                    <span>
                                        <CaseLabels case={row.case} show_as_icons={true} />
                                    </span>
                                )}
                                {row.is_editing && (
                                    <span>
                                        <TaxLabelDropDown model={[row.case, 'tax_label']} />
                                        <PackagingLabelDropDown
                                            model={[this.tag_model.data['Packaging'], 'selection']}
                                            options={this.tag_model.data['Packaging'].options}
                                        />
                                        <PackSizeDropDown
                                            model={[this.tag_model.data['Pack Size'], 'selection']}
                                            options={this.tag_model.data['Pack Size'].options}
                                        />
                                        <DamagesDropDown
                                            label=""
                                            model={this.tag_model.data['Damages'].selection}
                                            options={this.tag_model.data['Damages'].options}
                                        />
                                        <GeneralDropDown
                                            model={this.tag_model.data['General'].selection}
                                            options={this.tag_model.data['General'].options}
                                        />
                                        <InputDate
                                            value={row.case.best_before_date}
                                            onchange={(value: DateTime | null) =>
                                                (row.case.best_before_date = value?.toISODate() || '')
                                            }
                                        />
                                    </span>
                                )}
                            </span>
                        }}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Customs'}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {row.case.customs_status}
                                {row.show_spli_information && (
                                    <span>
                                        <span className={'help-block'}>{row.originating_spli.customs_status}</span>
                                    </span>
                                )}
                            </span>
                        )}
                    />

                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => 'Price per case'}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {this.is_price_same_as_spli(row) && <span />}
                                {!row.is_editing && !this.is_price_same_as_spli(row) && (
                                    <Amount amount={row.price_per_case} currency={'EUR'} />
                                )}
                                {row.is_editing && (
                                    // TEST: CURRENCY & MODEL
                                    <FieldMoney
                                        currency={[this, 'currency']}
                                        model={this.is_price_same_as_spli(row) ? 0 : [row, 'price_per_case']}
                                    />
                                )}
                                {row.show_spli_information && (
                                    <span>
                                        {row.originating_spli.price_per_case && (
                                            <span className={'help-block'}>
                                                Case:{' '}
                                                <Amount
                                                    amount={row.originating_spli.price_per_case || 0}
                                                    currency={row.originating_spli.currency}
                                                />
                                            </span>
                                        )}
                                        {row.originating_spli.price_per_bottle && (
                                            <span className={'help-block'}>
                                                Bottle:
                                                <Amount
                                                    amount={row.originating_spli.price_per_bottle}
                                                    currency={row.originating_spli.currency}
                                                />
                                            </span>
                                        )}
                                    </span>
                                )}
                            </span>
                        )}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => ''}
                        data_field={(row: TBOToApprove) => {
                            return row.is_calling_api ? (
                                <Spinner />
                            ) : (
                                <CheckBox
                                    className="no-click"
                                    label="Show spli"
                                    id={`show-spli-${row.artkey}`}
                                    checked={row.show_spli_information}
                                    onchange={() => (row.show_spli_information = !row.show_spli_information)}
                                />
                            )
                        }}
                    />
                    <CollectionTableColumn<TBOToApprove>
                        header_title={() => ''}
                        data_field={(row: TBOToApprove) => (
                            <span>
                                {row.is_calling_api && <Spinner />}
                                {!row.is_calling_api && (
                                    <span className={'pull-right no-click'}>
                                        {!row.is_editing && (
                                            <span>
                                                <DefaultButton
                                                    icon_class={'glyphicon glyphicon-pencil'}
                                                    onclick={() => this.start_edit(row)}
                                                />
                                                <Tippy
                                                    content={
                                                        '' +
                                                        '<strong>Approve</strong><hr />Can approve if: <ul>' +
                                                        '<li>Price per case > spli price</li>' +
                                                        '<li>Maximum quantity > minimum quantity</li>' +
                                                        '</ul>'
                                                    }
                                                >
                                                    <DefaultButton
                                                        additional_class={'btn-success'}
                                                        disabled={!this.can_approve(row)}
                                                        onclick={() => this.approve(row)}
                                                        icon_class={'glyphicon glyphicon-thumbs-up'}
                                                    />
                                                </Tippy>
                                                <DangerButton
                                                    icon_class={'glyphicon glyphicon-trash'}
                                                    onclick={() => this.delete_offer_item(row)}
                                                />
                                            </span>
                                        )}
                                        {row.is_editing && (
                                            <span>
                                                <DefaultButton
                                                    additional_class={'btn-info'}
                                                    onclick={() => this.cancel_edit(row)}
                                                    icon_class={'glyphicon glyphicon-ban-circle'}
                                                />
                                                <DefaultButton
                                                    additional_class={'btn-success'}
                                                    onclick={() => this.save(row)}
                                                    icon_class={'glyphicon glyphicon-ok'}
                                                />
                                            </span>
                                        )}
                                    </span>
                                )}
                            </span>
                        )}
                    />
                </CollectionTable>
            </div>
        )
    }
}

class TBODetailView extends MithrilTsxComponent<CollectionTableRowComponentProps<TBOToApprove, unknown>> {
    view(vnode: m.Vnode<CollectionTableRowComponentProps<TBOToApprove, unknown>>): m.Children | null {
        if (!vnode.attrs.row.is_showing_details) {
            return <span />
        }
        const _case = vnode.attrs.row.case
        return (
            <tr className={'well'}>
                <td colspan={'100%'}>
                    <div className={'row'}>
                        <div className={'col-sm-8'}>
                            <CaseInfoPanel
                                bottle_artkey={_case.bottle.artkey}
                                case_artkey={_case.artkey}
                                customs_status={_case.customs_status}
                                best_before_date={_case.best_before_date}
                                serialized_item_tags={_case.serialized_item_tags}
                            />
                        </div>
                        <div className={'col-sm-4'}>
                            <TBOItemPhotoList offer_item_artkey={vnode.attrs.row.artkey} />
                        </div>
                    </div>
                </td>
            </tr>
        )
    }
}

interface TBOItemPhotoListAttrs {
    offer_item_artkey: number
}

class TBOItemPhotoList extends MithrilTsxComponent<TBOItemPhotoListAttrs> {
    product_photo_api = new ProductPhotoApi()

    product_photos: GetProductPhotoResponse[] = []
    is_fetching = false

    oncreate(vnode: m.Vnode<TBOItemPhotoListAttrs>): void {
        this.fetch_product_photos(vnode)
    }

    download_all_files(vnode: m.Vnode<TBOItemPhotoListAttrs>, include_internal: boolean): void {
        this.product_photo_api.download_all_for_offer_item(
            vnode.attrs.offer_item_artkey, include_internal, `photos-tbo-${vnode.attrs.offer_item_artkey}.zip`,
        )
    }

    fetch_product_photos(vnode: m.Vnode<TBOItemPhotoListAttrs>): void {
        this.is_fetching = true
        this.product_photo_api.get_all_photos_for_tbo_offer_item(vnode.attrs.offer_item_artkey).subscribe({
            next: (result) => {
                this.is_fetching = false
                this.product_photos = result
                m.redraw()
            },
            error: () => {
                this.is_fetching = false
            },
        })
    }

    view(vnode: m.Vnode<TBOItemPhotoListAttrs, this>): m.Children {
        if (this.is_fetching) return <Spinner />
        return (
            <ProductPhotoList
                product_photos={this.product_photos}
                offer_item_artkey={vnode.attrs.offer_item_artkey}
                download_all_photos={(include_internal: boolean) =>
                    this.download_all_files(vnode, include_internal)
                }
                on_product_photos_changed={() => this.fetch_product_photos(vnode)}
                on_sort_product_photo_items={(artkeys: number[]) => {
                    artkeys.forEach((artkey, index) => {
                        const pp = this.product_photos.find((pp) => pp.artkey === artkey)
                        if (pp) {
                            pp.rank = index + 1
                        }
                    })
                }}
            />
        )
    }
}
