/** llm:tested */
import m from 'mithril'
import {filter, head, reject, sortWith} from 'prelude-ls'
import {countries} from '@bitstillery/common/lib/countries'
import {proxy} from '@bitstillery/common/lib/proxy'
import {classes} from '@bitstillery/common/lib/utils'
import {api, notifier} from '@bitstillery/common/app'

import api_ls from '@/api'
import inputs from '@/components/inputs'
import {BottleOnlyForm} from '@/data/products/bottle_only_form'
import {CountriesSelect} from '@/components/html_components'
import {ProductBottleType} from '@/models/products'
import {HiddenInformation, HiddenProductHelper} from '@/purchase_orders/view_purchase_order/hidden_information'
import {$m} from '@/app'

interface Bottle {
    artkey: () => number
    volume: () => number
    alcohol_percentage: () => number
    refill_status: () => string
    dirty: () => boolean
    apply_update: () => void
}

interface Product {
    artkey: () => number
    name: () => string
    default_country_code: () => string
    product_bottle_type: () => string
    requires_import_statement: () => boolean
    product_category: () => { artkey: () => number }
    brand: () => { artkey: () => number }
    bottles: () => Bottle[]
    hide_from_pricelist_for_countries: () => any[]
    hide_from_pricelist_for_suppliers: () => any[]
}

export class ProductEdit {
    product: () => Product
    checked_bottles: () => Bottle[]
    saving: () => boolean
    error_messages: () => string[]
    merged: () => boolean
    product_categories: () => [string, string][]
    brands: () => [string, string][]
    product_bottle_types: () => any[]
    selected_category_artkey: () => string
    countries: () => typeof countries
    data: any

    constructor() {
        this.product = window.prop(null)
        this.checked_bottles = window.prop([])
        this.saving = window.prop(false)
        this.error_messages = window.prop([])
        this.merged = window.prop(false)
        this.query_product(m.route.param('artkey'))
        this.product_categories = window.prop([])
        this.brands = window.prop([])
        this.product_bottle_types = window.prop([])
        this.selected_category_artkey = window.prop('')
        this.countries = window.prop(countries)

        const pathname = m.parsePathname(m.route.get())
        const params = Object.fromEntries(new URLSearchParams(pathname.params))
        this.data = proxy({
            tab: params.tab || 'edit-product',
        })

        if (m.route.get().endsWith('merged')) {
            this.merged(true)
        }
    }

    oncreate() {
        this.get_categories()
        this.get_brands()
    }

    async get_categories() {
        const {result} = await api.get('discover/product-categories/select')
        this.product_categories(result.map((it: any) => [it.artkey, it.name]))
    }

    get_brands() {
        api_ls.callAndThen('brands.get_all', {}, {
            success: (resp: any) => {
                this.brands(resp.result.map(({artkey, name}: any) => [artkey, name]))
            },
        })
    }

    check_bottle(event: Event) {
        const artkey = +(event.target as HTMLInputElement).value
        if ((event.target as HTMLInputElement).checked) {
            const bottle = head(this.product().bottles().filter(b => b.artkey() === artkey))
            this.checked_bottles().push(bottle)
        } else {
            this.checked_bottles(reject(b => b.artkey() === artkey, this.checked_bottles()))
        }
    }

    query_product(product_artkey: string) {
        const data = {
            product_artkey: product_artkey,
        }
        api_ls.callAndThen('product_management.get_product_and_bottles', data, {
            success: (result: any) => {
                this.product($m.products.create_product(result.product))
                this.product().hide_from_pricelist_for_countries(
                    this.product().hide_from_pricelist_for_countries().map((cc: any) => cc.country_code),
                )
                this.product().hide_from_pricelist_for_suppliers(
                    this.product().hide_from_pricelist_for_suppliers().map((sup: any) => `${sup.artkey}`),
                )
            },
        })
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    setTab(tabName: string) {
        this.data.tab = tabName
        m.route.set(`/data/products/${this.product().artkey()}?tab=${tabName}`)
        m.redraw()
    }

    save() {
        this.saving(true)
        this.merged(false)

        if (!this.check_can_save()) {
            this.saving(false)
            return
        }

        const bottles = this.product().bottles()
            .filter(bottle => bottle.dirty())
            .map(bottle => ({
                artkey: +bottle.artkey(),
                volume: +bottle.volume(),
                alcohol_percentage: +bottle.alcohol_percentage(),
                refill_status: bottle.refill_status(),
            }))

        const product_data = {
            artkey: this.product().artkey(),
            name: this.product().name(),
            default_country_code: this.product().default_country_code(),
            product_bottle_type: this.product().product_bottle_type(),
            requires_import_statement: this.product().requires_import_statement(),
            category_artkey: this.product().product_category().artkey(),
            brand_artkey: this.product().brand().artkey(),
        }

        const data = {
            bottles,
            product: product_data,
        }

        api_ls.callAndThen('product_management.update_product_and_bottles', data, {
            success: (result) => this.handle_save(result),
        })
    }

    check_can_save() {
        this.error_messages([])
        if (!this.product().name()) {
            this.error_messages().push('Please enter a product name.')
        }
        if (filter(b => b.alcohol_percentage() < 0, this.product().bottles()).length > 0) {
            this.error_messages().push('Please enter an alcohol percentage for each row.')
        }
        if (filter(b => b.volume() <= 0, this.product().bottles()).length > 0) {
            this.error_messages().push('Please enter a volume for each row.')
        }
        return this.error_messages().length === 0
    }

    reset_alerts() {
        this.error_messages([])
        m.redraw()
    }

    handle_save(result: any) {
        this.saving(false)
        if (result.success) {
            $m.data.update_data_products(true)
            notifier.notify('Product saved successfully.', 'success')
            for (const bottle of this.product().bottles()) {
                bottle.apply_update()
            }
        } else {
            this.error_messages().push(result.message)
        }
        setTimeout(this.reset_alerts.bind(this), 5000)
    }

    merge_bottles() {
        this.saving(true)
        const data = {
            bottle_artkeys: this.checked_bottles().map(bottle => bottle.artkey()),
        }
        api_ls.callAndThen('product_management.merge_bottles', data, {
            success: (result) => this.handle_merge_result(result),
        })
    }

    handle_merge_result(result: any) {
        if (result.success) {
            try {
                $m.data.update_data_products(true)
                $m.common.observable.broadcast('bottles_updated')
                const merged_bottle_artkeys = this.checked_bottles()
                    .filter(b => b.artkey() !== result.bottle_artkey)
                    .map(b => b.artkey())
                this.product().bottles(
                    reject(b => merged_bottle_artkeys.includes(b.artkey()), this.product().bottles()),
                )
                const final_bottle = head(
                    this.product().bottles().filter(b => b.artkey() === result.bottle_artkey),
                )
                this.checked_bottles([final_bottle])
                this.saving(false)
                notifier.notify('Bottles merged successfully.', 'success')
            } catch (e: any) {
                $m.common.generic_error_handler(e.message)
            }
        } else {
            $m.common.generic_error_handler(result.message)
            this.saving(false)
        }
        setTimeout(this.reset_alerts.bind(this), 5000)
    }

    view() {
        return <div class="c-product-edit view">
            <div class="btn-toolbar">
                <button class="btn btn-default" type="button" onclick={() => m.route.set('/data/products')}>
                    <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                </button>
                <button
                    class="btn btn-default"
                    disabled={this.checked_bottles().length < 2}
                    type="button"
                    onclick={() => this.merge_bottles()}
                >
                    <span class="glyphicon glyphicon-resize-small"></span> Merge bottles
                </button>
            </div>

            {this.merged() &&
                <div class="alert alert-success alert-dismissable">
                    <button class="close" data-dismiss="alert" onclick={() => this.merged(false)}>
                        <span>×</span>
                    </button>
                    <strong>Success!</strong> The products have been merged, this is the resulting product.
                </div>
            }

            {this.product() !== null && [
                <div class="c-tabs">
                    <ul class="nav nav-tabs">
                        <li class={classes('nav-link', {active: this.data.tab === 'edit-product'})}
                            onclick={() => this.setTab('edit-product')}>
                            <a>Edit product</a>
                        </li>
                        <li class={classes('nav-link', {active: this.data.tab === 'hidden-information'})}
                            onclick={() => this.setTab('hidden-information')}>
                            <a>Hidden information</a>
                        </li>
                    </ul>
                </div>,

                this.data.tab === 'hidden-information' &&
                    <div class={classes('c-tab', {active: this.data.tab === 'hidden-information'})}>
                        <HiddenInformation
                            hidden_information_helper={new HiddenProductHelper(this.product().artkey())}
                        />
                    </div>,

                this.data.tab === 'edit-product' &&
                    <div class={classes('c-tab', {active: this.data.tab === 'edit-product'})}>
                        <div class="fieldset-group">
                            <div class="fieldset">
                                <div class="field">
                                    <label for="name">Name *</label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        required={true}
                                        name="name"
                                        value={this.product().name()}
                                        oninput={(ev: Event) =>
                                            this.product().name((ev.target as HTMLInputElement).value)}
                                    />
                                </div>

                                <div class="field">
                                    <label for="brand">Brand</label>
                                    {inputs.select(this.product().brand().artkey, this.brands(), {
                                        required: false,
                                        empty_option: true,
                                    })}
                                </div>

                                <div class="field">
                                    <label for="category">Category *</label>
                                    {inputs.select(this.product().product_category().artkey, this.product_categories(), {required: true})}
                                </div>
                            </div>

                            <div class="fieldset">
                                <div class="field">
                                    <label for="product_bottle_type">Product bottle type *</label>
                                    {inputs.select(this.product().product_bottle_type, ProductBottleType, {
                                        required: true,
                                    })}
                                </div>
                                <div class="field">
                                    <label for="default_country_code">Default country</label>
                                    {this.product().default_country_code() !== undefined &&
                                        <CountriesSelect
                                            model={[this.product(), 'default_country_code']}
                                            placeholder="-"
                                        />
                                    }
                                </div>

                                {inputs.checkbox(this.product().requires_import_statement, {
                                    label: 'Requires supplier import statement',
                                })}
                            </div>
                        </div>

                        {this.product().bottles().length > 0 ?
                            sortWith(sort_bottles, this.product().bottles()).map((bottle) => {
                                return <BottleOnlyForm
                                    bottle={bottle}
                                    on_check={this.check_bottle.bind(this)}
                                    key={bottle.artkey()}
                                />
                            }) :
                            <p>No specs</p>
                        }

                        <button
                            class="btn btn-success btn-submit"
                            onclick={() => this.save()}
                            disabled={this.saving()}
                        >
                            Update Product
                        </button>
                    </div>,
            ]}

            {this.error_messages().length > 0 &&
                <div class="error-messages alert alert-danger animated fadeInUp">
                    <ul>
                        {this.error_messages().map(message =>
                            <li>{message}</li>,
                        )}
                    </ul>
                </div>
            }
        </div>
    }
}

function sort_bottles(x: Bottle, y: Bottle): number {
    if (+x.volume() > +y.volume()) return 1
    if (+x.volume() < +y.volume()) return -1
    if (+x.alcohol_percentage() > +y.alcohol_percentage()) return 1
    if (+x.alcohol_percentage() < +y.alcohol_percentage()) return -1
    if (x.refill_status().toLowerCase() === 'nonref') return 1
    return 0
}
