/** llm:tested */
import m from 'mithril'
import {map, compact} from 'prelude-ls'
import {notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {matchTermIn} from '@/_utils'
import api from '@/api'
import {icon_button, button_with_icon} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import {CollectionTable} from '@/components/collection_table'
import {$m} from '@/app'

interface PortalTip {
    artkey: string
    title: string
    content: string
    creator: {
        profile: {
            name: string
        }
    }
}

export class PortalTipsList extends MithrilTsxComponent<any> {
    entities: Collection
    search_input_ctrl: any

    constructor() {
        super()
        this.entities = new Collection({
            api_function_name: 'portal.tip.get_tips',
            filter_function: this.is_match,
            query_limit: 25,
        })

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.entities,
            placeholder: 'Search for portal tip...',
        })
    }

    oncreate() {
        this.entities.init()
    }

    is_match(tip: PortalTip, term: string) {
        return matchTermIn(term, map((x: string) => x.toLowerCase(), compact([
            tip.title,
            tip.content,
        ])))
    }

    onremove() {
        this.search_input_ctrl.onremove()
    }

    mark_deleted(artkey: string) {
        api.call('portal.tip.mark_deleted', {artkey}, (resp: any) => {
            if (resp.success) {
                m.redraw()
                notifier.notify('Successfully deleted tip', 'success')
                this.entities.soft_delete(artkey)
            } else {
                $m.common.generic_error_handler()
            }
        })
    }

    view() {
        return (
            <div class="c-portal-tips view">
                <div class="btn-toolbar">
                    {button_with_icon('Create Portal Tip', 'plus', {
                        class: 'btn-default',
                        onclick: () => m.route.set('/portal/portal-tips/create'),
                    })}
                </div>

                <div class="c-filter-group">
                    {SearchInput.view(this.search_input_ctrl)}
                </div>

                {m(CollectionTable, {
                    collection: this.entities,
                    options: {
                        search_table_style: true,
                        sticky_header: true,
                        with_buttons: true,
                        autoscale: true,
                        unique_name: 'portal_tips',
                        onclick: (record: PortalTip) => {
                            return () => {
                                return m.route.set(`/portal/portal-tips/${record.artkey}/edit`)
                            }
                        },
                    },
                    columns: [
                        {
                            width: 3,
                            name: 'Title',
                            field: 'title',
                            sort: true,
                        },
                        {
                            width: 10,
                            name: 'Message',
                            field: 'content',
                            sort: true,
                            ellipsis: true,
                        },
                        {
                            width: 2,
                            name: 'Author',
                            field: 'creator.profile.name',
                            sort: true,
                        },
                        {
                            width: 1,
                            header: '',
                            name: 'Actions',
                            function: (record: PortalTip) => (
                                <div class="btn-toolbar no-click">
                                    <div class="btn-group">
                                        {icon_button('pencil', {
                                            class: 'btn-default no-click',
                                            onclick: () => m.route.set(`/portal/portal-tips/${record.artkey}/edit`),
                                        })}
                                        {icon_button('remove', {
                                            class: 'btn-danger no-click',
                                            onclick: () => this.mark_deleted(record.artkey),
                                        })}
                                    </div>
                                </div>
                            ),
                        },
                    ],
                })}
            </div>
        )
    }
}
