import {Observable, of, Subject} from 'rxjs'

import {Api} from './api'
import {GetIncotermsResponse} from './fact2server_api'

export class IncotermsDropDownData {
    api = new Api()

    /** Singleton instance of this class. */
    private static _instance: IncotermsDropDownData | null = null
    /** Cached results, when available */
    private result: GetIncotermsResponse[] = []

    /** Subject used when data is not yet available. */
    private drop_down_data$: Subject<GetIncotermsResponse[]> = new Subject<GetIncotermsResponse[]>()

    private constructor() {
        this.api.get('discover/data/incoterms')
            .subscribe({
                next: (response: GetIncotermsResponse[]) => {
                    this.drop_down_data$.next(response)
                    if (response) {
                        this.result = response
                    }
                },
                error: (v) => this.drop_down_data$.error(v),
                complete: () => this.drop_down_data$.complete(),
            })
    }

    private static instance(): IncotermsDropDownData {
        if (IncotermsDropDownData._instance === null) {
            IncotermsDropDownData._instance = new IncotermsDropDownData()
        }

        return IncotermsDropDownData._instance
    }

    /** Return or the cached results or the pending fetch of the data. */
    public static incoterms(): Observable<GetIncotermsResponse[]> {
        if (IncotermsDropDownData.instance().drop_down_data$.isStopped) {
            return of(IncotermsDropDownData.instance().result)
        }

        return IncotermsDropDownData.instance().drop_down_data$
    }
}
