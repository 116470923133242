/** llm:tested */
import m from 'mithril'
import {map} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {AgeInDays} from './models'
import {GraphDataManager} from './components/graph_data_manager'

import api from '@/api'
import inputs from '@/components/inputs'
import {Box} from '@/components/adminlte/box'
import {Chart} from '@/lib/chart'

interface ZeroResultHit {
    search_term: string
    count: number
}

interface ChartDataset {
    label: string
    data: number[]
    backgroundColor: string[]
    borderColor: string[]
    borderWidth: number
}

export class ZeroResultsGraph extends MithrilTsxComponent<any> {
    private canvas_id: string
    private age_in_days: any
    private zero_result_hits: any
    private x_axis: any
    private y_axis: any
    private bar_chart: any
    private background_colors: string[]
    private border_colors: string[]

    constructor(vnode: m.Vnode<{ canvas_id: string }>) {
        super()
        this.canvas_id = vnode.attrs.canvas_id

        this.age_in_days = window.prop(AgeInDays.MONTH)
        this.zero_result_hits = window.prop(null)
        this.x_axis = window.prop([])
        this.y_axis = window.prop([])

        this.bar_chart = window.prop(null)
        const graph_manager = new GraphDataManager()
        this.background_colors = map((color) => color.background, graph_manager.available_colors)
        this.border_colors = map((color) => color.border, graph_manager.available_colors)
    }

    oncreate() {
        this.fetch_missed_hits()
    }

    onupdate() {
        if (this.bar_chart()) {
            this.bar_chart().data.labels = this.x_axis()
            this.bar_chart().data.datasets[0].data = this.y_axis()
            this.bar_chart().update()
        }
    }

    fetch_missed_hits() {
        const age_in_days = AgeInDays.as_days[this.age_in_days()]
        const data = {
            age_in_days: age_in_days,
            number_of_hits: 15,
        }
        api.callAndThen('diana.missed_hits_per_period', data, {
            success: (resp) => {
                this.zero_result_hits(resp.result)
                this.x_axis(map((term: ZeroResultHit) => term.search_term, this.zero_result_hits()))
                this.y_axis(map((term: ZeroResultHit) => term.count, this.zero_result_hits()))
            },
        })
    }

    set_age_in_days(val: any) {
        this.age_in_days(val)
        this.fetch_missed_hits()
    }

    oncreate_barchart() {
        const target = document.getElementById(this.canvas_id)

        const dataset_1: ChartDataset = {
            label: '# of searches for term',
            data: this.y_axis(),
            backgroundColor: this.background_colors,
            borderColor: this.border_colors,
            borderWidth: 1,
        }

        this.bar_chart(new Chart(target, {
            type: 'bar',
            data: {
                labels: this.x_axis(),
                datasets: [dataset_1],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        }))
        return this.bar_chart()
    }

    view() {
        return <Box
            cls=".box-info.bg-gray-light"
            title="Top searches without a hit"
            body={() => (
                [
                    <div className="col-sm-3">
                        {inputs.select(this.age_in_days, AgeInDays.choices, {
                            onchange: (val) => this.set_age_in_days(val),
                            empty_option: false,
                        })}
                    </div>,
                    <div className="row">
                        {this.zero_result_hits() !== null ? (
                            <canvas
                                id={this.canvas_id}
                                height="130px"
                                oncreate={() => this.oncreate_barchart()}
                            />
                        ) : null}
                    </div>,
                ]
            )}
            loading={() => this.zero_result_hits() === null}
            collapsed={() => false}
        />
    }
}
