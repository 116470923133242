import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/models/item'
import {Button} from '@bitstillery/common/components'
import {filter, mergeAll, toArray} from 'rxjs/operators'
import {notifier} from '@bitstillery/common/app'
import {FieldSelect, Spinner} from '@bitstillery/common/components'

import {Link} from '../components/discover'
import {NumberInput} from '../components/input_numbers'
import {
    SelectionController,
    SelectionEnabledComponent,
    SelectionEnabledComponentAttrs,
} from '../components/selection_controller'

import {accountIcon} from '@/accounts'
import {$s} from '@/app'
import {CancelButton, DefaultButton} from '@/components/buttons'
import {
    GetFastSalesOrderWithItemsResponse,
    GetSalesOrderForRelationResponse,
    SalesApi,
    SalesOrderItem,
    SalesOrderStatus,
    SalesOrderTBOItem,
} from '@/factserver_api/sales_api'

function potential_sales_orders_as_drop_down_options(sales_orders: GetSalesOrderForRelationResponse[]) {
    return sales_orders.map((i) => ({
        value: i.artkey,
        label: `${i.reference} ${i.combined_status} ${i?.destination_location || i?.destination?.name || ''} [${i.number_of_cases}cs`,
    }))
}

export default class MoveSalesOrderItems extends MithrilTsxComponent<unknown> {
    sales_order_response: GetFastSalesOrderWithItemsResponse | null = null
    sales_order_artkey: number
    sales_api = new SalesApi()
    number_of_cases = 0

    potential_target_sales_orders: GetSalesOrderForRelationResponse[] = []
    batch_target_sales_order: GetSalesOrderForRelationResponse | null = null

    is_loading = false
    is_selecting = false

    selection_controller: SelectionController

    constructor() {
        super()
        this.sales_order_artkey = +m.route.param('artkey')
        this.selection_controller = new SelectionController(['soi', 'tbo'])
    }

    oncreate(): void {
        this._fetch_sales_order()
    }

    _fetch_sales_order(): void {
        this.is_loading = true
        this.sales_api.get_fast_sales_order_with_items(this.sales_order_artkey).subscribe({
            next: (response: GetFastSalesOrderWithItemsResponse) => {
                this.sales_order_response = response
                this._fetch_potential_target_sales_orders(this.sales_order_response)
            },
        })
    }

    _fetch_potential_target_sales_orders(sales_order: GetFastSalesOrderWithItemsResponse): void {
        this.is_loading = true
        this.sales_api
            .get_sales_orders_for_relation([SalesOrderStatus.SAVED], sales_order.supplier.artkey)
            .pipe(
                mergeAll(),
                filter((response: GetSalesOrderForRelationResponse) => Number(response.artkey) !== this.sales_order_artkey),
                filter(
                    (response: GetSalesOrderForRelationResponse) => response.was_sold_in === sales_order.was_sold_in,
                ),
                toArray(),
            )
            .subscribe({
                next: (response) => {
                    this.is_loading = false
                    this.potential_target_sales_orders = response
                    m.redraw()
                },
            })
    }

    move_cases_to = (
        number_of_cases_to_move: number,
        target_sales_order: GetSalesOrderForRelationResponse | null,
        soi?: SalesOrderItem,
        tbo?: SalesOrderTBOItem,
    ) => {
        this.is_loading = true
        this.sales_api
            .move_item_to_sales_order(
                number_of_cases_to_move,
                this.sales_order_artkey,
                target_sales_order?.artkey,
                soi?.artkey,
                tbo?.artkey,
            )
            .subscribe({
                next: () => {
                    this.is_loading = false
                    this._fetch_sales_order()
                },
                error: () => {
                    this.is_loading = false
                    m.redraw()
                },
            })
    }

    move_batch_to_target(): void {
        this.is_loading = true
        this.sales_api
            .batch_move_item_to_sales_order(
                this.selection_controller.selected_items('soi'),
                this.selection_controller.selected_items('tbo'),
                this.sales_order_artkey,
                this.batch_target_sales_order?.artkey,
            )
            .subscribe({
                next: () => {
                    notifier.notify(`Items successfully moved to ${this.batch_target_sales_order?.reference || ''}`, 'info')
                    this.selection_controller.remove_all('soi')
                    this.selection_controller.remove_all('tbo')
                    this._fetch_sales_order()
                },
                error: () => {
                    this.is_loading = false
                    m.redraw()
                },
            })
    }

    toggle_batch_mode(): void {
        this.is_selecting = !this.is_selecting
        if (this.is_selecting) {
            this.selection_controller.start_selecting()
        } else {
            this.selection_controller.end_selecting()
            this.selection_controller.remove_all('soi')
            this.selection_controller.remove_all('tbo')
        }
    }

    update_batch_target_sales_order(artkey: string): void {
        if (artkey) {
            this.batch_target_sales_order =
                this.potential_target_sales_orders.find((so) => String(so.artkey) === String(+artkey)) || null
        } else {
            this.batch_target_sales_order = null
        }
    }

    view(): m.Children {
        const sois = this.sales_order_response?.sales_order_items || []
        const tbos = this.sales_order_response?.sales_order_tbo_items || []

        if (!this.sales_order_response) return <Spinner/>
        return (
            <div className="c-move-sales-order-items view">
                <div className="btn-toolbar">
                    <Button
                        className="btn-back"
                        onclick={() => m.route.set(`/sales-orders/manage/${this.sales_order_response?.artkey || ''}`)}
                        icon="back"
                        variant="toggle"
                    />
                </div>

                <h2>Move sales order items of {this.sales_order_response.reference}</h2>
                <div className="btn-toolbar">
                    {!this.is_loading && <span>
                        {!this.is_selecting && (
                            <DefaultButton
                                icon_class={'glyphicon glyphicon-th-list'}
                                onclick={() => this.toggle_batch_mode()}
                                title={' Select items'}
                            />
                        )}
                        {this.is_selecting && (
                            <div style={'display: flex'}>
                                {<FieldSelect
                                    // Hack to make sure the field is not empty when the target sales order is null.
                                    model={this.batch_target_sales_order ? [this.batch_target_sales_order, 'artkey'] : [this, '_batch_target_sales_order_artkey']}
                                    onchange={(value: string) =>
                                        this.update_batch_target_sales_order(value)
                                    }
                                    options={potential_sales_orders_as_drop_down_options(this.potential_target_sales_orders)}
                                    placeholder="Move to new order"
                                />}
                                <DefaultButton
                                    icon_class={'glyphicon glyphicon-arrow-right'}
                                    onclick={() => this.move_batch_to_target()}
                                    disabled={
                                        this.selection_controller.length_for('soi') +
                                        this.selection_controller.length_for('tbo') ===
                                        0
                                    }
                                    title={
                                        this.batch_target_sales_order
                                            ? ` Move ${
                                                this.selection_controller.length_for('soi') +
                                                    this.selection_controller.length_for('tbo')
                                            } item(s) to ${this.batch_target_sales_order.reference}`
                                            : ` Move ${
                                                this.selection_controller.length_for('soi') +
                                                    this.selection_controller.length_for('tbo')
                                            } item(s) to new order`
                                    }
                                />
                                <CancelButton onclick={() => this.toggle_batch_mode()} />
                            </div>
                        )}
                    </span>}
                </div>

                <div className={'row'}>
                    <div className={'col-sm-12'}>
                        {this.sales_order_response.sales_order_items.length > 0 && (
                            <table className={'table search-table clickable'}>
                                <thead className={'thead-default'}>
                                    <tr>
                                        <th>
                                            {this.is_selecting && (
                                                <span>
                                                    <input
                                                        type={'checkbox'}
                                                        id={`select-${this.sales_order_artkey}`}
                                                        oninput={() => {
                                                            if (
                                                                this.selection_controller.are_in_selection(
                                                                    'soi',
                                                                    sois.map((soi) => soi.artkey),
                                                                )
                                                            ) {
                                                                this.selection_controller.remove_all('soi')
                                                            } else {
                                                                this.selection_controller.set_all(
                                                                    'soi',
                                                                    sois.map((soi) => soi.artkey),
                                                                )
                                                            }
                                                        }}
                                                        checked={this.selection_controller.are_in_selection(
                                                            'soi',
                                                            sois.map((soi) => soi.artkey),
                                                        )}
                                                    />
                                                    <label htmlFor={`select-${this.sales_order_artkey}`}/>
                                                </span>
                                            )}
                                        </th>
                                        <th>Product</th>
                                        <th>Btl / cs</th>
                                        <th>Specs</th>
                                        <th>GB</th>
                                        <th>Cus.</th>
                                        <th>Lot</th>
                                        <th>Number of cases</th>
                                        <th>Number of cases to move</th>
                                        <th/>
                                    </tr>
                                </thead>
                                {sois.map((soi) => (
                                    <MoveSalesOrderItem
                                        key={`soi-${soi.artkey}`}
                                        potential_sales_orders={this.potential_target_sales_orders}
                                        is_loading={this.is_loading}
                                        move_operation={this.move_cases_to}
                                        soi={soi}
                                        register_selection_enabled_component={(
                                            component: SelectionEnabledComponent,
                                        ) =>
                                            this.selection_controller.register_selection_enabled_component(
                                                component,
                                            )
                                        }
                                    />
                                ))}
                            </table>
                        )}

                        {/* -- Table with TBO Items -- */}
                        {this.sales_order_response.sales_order_tbo_items.length > 0 && (
                            <table className={'table search-table clickable'}>
                                <thead className={'thead-default'}>
                                    <tr>
                                        <th>
                                            {this.is_selecting && (
                                                <span>
                                                    <input
                                                        type={'checkbox'}
                                                        id={`select-tbo-${this.sales_order_artkey}`}
                                                        oninput={() => {
                                                            if (
                                                                this.selection_controller.are_in_selection(
                                                                    'tbo',
                                                                    tbos.map((tbo) => tbo.artkey),
                                                                )
                                                            ) {
                                                                this.selection_controller.remove_all('tbo')
                                                            } else {
                                                                this.selection_controller.set_all(
                                                                    'tbo',
                                                                    tbos.map((tbo) => tbo.artkey),
                                                                )
                                                            }
                                                        }}
                                                        checked={this.selection_controller.are_in_selection(
                                                            'tbo',
                                                            tbos.map((tbo) => tbo.artkey),
                                                        )}
                                                    />
                                                    <label htmlFor={`select-tbo-${this.sales_order_artkey}`}/>
                                                </span>
                                            )}
                                        </th>
                                        <th>Product</th>
                                        <th>Btl / cs</th>
                                        <th>Specs</th>
                                        <th>GB</th>
                                        <th>Cus.</th>
                                        <th>Number of cases</th>
                                        <th>Number of cases to move</th>
                                        <th/>
                                    </tr>
                                </thead>

                                {tbos.map((tbo) => (
                                    <MoveSalesOrderTBOItem
                                        key={`tbo-${tbo.artkey}`}
                                        potential_sales_orders={this.potential_target_sales_orders}
                                        is_loading={this.is_loading}
                                        move_operation={this.move_cases_to}
                                        tbo={tbo}
                                        register_selection_enabled_component={(
                                            component: SelectionEnabledComponent,
                                        ) =>
                                            this.selection_controller.register_selection_enabled_component(
                                                component,
                                            )
                                        }
                                    />
                                ))}
                            </table>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

interface MoveSalesOrderItemAttrs extends SelectionEnabledComponentAttrs {
    soi: SalesOrderItem
    potential_sales_orders: GetSalesOrderForRelationResponse[]
    is_loading: boolean
    move_operation: (
        number_of_cases_to_move: number,
        target_sales_order: GetSalesOrderForRelationResponse | null,
        soi?: SalesOrderItem,
        tbo?: SalesOrderTBOItem
    ) => void
}

class MoveSalesOrderItem extends MithrilTsxComponent<MoveSalesOrderItemAttrs> implements SelectionEnabledComponent {
    number_of_cases = 0
    target_sales_order: GetSalesOrderForRelationResponse | null = null
    is_selecting = false
    selection_controller: SelectionController | null = null

    original_number_of_cases: number

    constructor(vnode: m.Vnode<MoveSalesOrderItemAttrs>) {
        super()

        vnode.attrs.register_selection_enabled_component(this)
        this.original_number_of_cases = vnode.attrs.soi.number_of_cases
    }

    end_selection(): void {
        this.is_selecting = false
        this.selection_controller = null
        this.number_of_cases = 0
    }

    start_selection(selection_controller: SelectionController): void {
        this.is_selecting = true
        this.selection_controller = selection_controller
        this.number_of_cases = this.original_number_of_cases
    }

    update_target_sales_order(vnode: m.Vnode<MoveSalesOrderItemAttrs>, artkey: string) {
        if (artkey) {
            this.target_sales_order = vnode.attrs.potential_sales_orders.find((so) => String(so.artkey) === String(+artkey)) || null
        } else {
            this.target_sales_order = null
        }
    }

    start_move(vnode: m.Vnode<MoveSalesOrderItemAttrs>) {
        if (this.number_of_cases > vnode.attrs.soi.number_of_cases) {
            notifier.notify('Number of cases too high', 'warning')
            return
        }

        vnode.attrs.move_operation(this.number_of_cases, this.target_sales_order, vnode.attrs.soi)
        this.number_of_cases = 0
    }

    view(vnode: m.Vnode<MoveSalesOrderItemAttrs>): m.Children {
        const soi = vnode.attrs.soi
        return (
            <tbody className={'table-row'} key={`${soi.artkey}`}>
                <tr>
                    <td>
                        {this.is_selecting && (
                            <span>
                                <input
                                    type={'checkbox'}
                                    id={`select-${soi.artkey}`}
                                    oninput={() =>
                                        this.selection_controller?.is_in_selection('soi', soi.artkey)
                                            ? this.selection_controller?.remove_from_selection('soi', soi.artkey)
                                            : this.selection_controller?.add_to_selection('soi', soi.artkey)
                                    }
                                    checked={this.selection_controller?.is_in_selection('soi', soi.artkey)}
                                />
                                <label htmlFor={`select-${soi.artkey}`}/>
                            </span>
                        )}
                    </td>
                    <td className={'col-sm-2'}>{soi.item.case.bottle.product.name}</td>
                    <td className={'col-sm-1 number'}>{soi.item.case.number_of_bottles}</td>
                    <td className={'col-sm-1'}>{to_specs(soi.item.case.bottle, $s.identity.user.decimal_locale)}</td>
                    <td className={'col-sm-1'}>{soi.item.case.gift_box_type}</td>
                    <td className={'col-sm-1'}>{soi.item.case.customs_status}</td>
                    <td className={'col-sm-1'}>
                        <Link href={`/stock/manage?q=${soi.item.lot}&available_only=false`}>
                            {soi.item.lot}{' '}
                            {soi.item.lot &&
                            accountIcon({
                                slug: soi.item.account.slug,
                                name: soi.item.account.name,
                            })}
                        </Link>
                    </td>
                    <td className={'col-sm-1 number'}>{soi.number_of_cases}</td>
                    <td className={'col-sm-1'}>
                        {!vnode.attrs.is_loading && (
                            <NumberInput
                                oninput={(value: number) => (this.number_of_cases = value || 0)}
                                minimum={0}
                                disabled={this.is_selecting}
                                maximum={soi.number_of_cases}
                                value={this.number_of_cases}
                            />)}
                    </td>
                    <td className={'col-sm-6'}>
                        {vnode.attrs.is_loading && <Spinner/>}
                        {!vnode.attrs.is_loading && (
                            <div>
                                <div>
                                    {<FieldSelect
                                        disabled={this.number_of_cases === 0 || this.is_selecting}
                                        // Hack to make sure the field is not empty when the target sales order is null.
                                        model={this.target_sales_order ? [this.target_sales_order, 'artkey'] : [this, '_target_sales_order_artkey']}
                                        onchange={(value: string) => this.update_target_sales_order(vnode, value)}
                                        options={potential_sales_orders_as_drop_down_options(vnode.attrs.potential_sales_orders)}
                                        placeholder="Select existing"
                                    />}
                                </div>
                                <DefaultButton
                                    icon_class={'glyphicon glyphicon-arrow-right'}
                                    disabled={this.number_of_cases === 0 || this.is_selecting}
                                    onclick={() => this.start_move(vnode)}
                                    title={
                                        this.target_sales_order
                                            ? ` Move to ${this.target_sales_order.reference}`
                                            : ' Move to new order'
                                    }
                                />
                            </div>
                        )}
                    </td>
                </tr>
            </tbody>
        )
    }
}

interface MoveSalesOrderTBOItemAttrs extends SelectionEnabledComponentAttrs {
    tbo: SalesOrderTBOItem
    potential_sales_orders: GetSalesOrderForRelationResponse[]
    is_loading: boolean
    move_operation: (
        number_of_cases_to_move: number,
        target_sales_order: GetSalesOrderForRelationResponse | null,
        soi?: SalesOrderItem,
        tbo?: SalesOrderTBOItem
    ) => void
}

class MoveSalesOrderTBOItem
    extends MithrilTsxComponent<MoveSalesOrderTBOItemAttrs>
    implements SelectionEnabledComponent {
    number_of_cases = 0
    target_sales_order: GetSalesOrderForRelationResponse | null = null
    is_selecting = false
    selection_controller: SelectionController | null = null
    original_number_of_cases: number

    constructor(vnode: m.Vnode<MoveSalesOrderTBOItemAttrs>) {
        super()

        vnode.attrs.register_selection_enabled_component(this)
        this.original_number_of_cases = vnode.attrs.tbo.number_of_cases
    }

    end_selection(): void {
        this.is_selecting = false
        this.selection_controller = null
        this.number_of_cases = 0
    }

    start_selection(selection_controller: SelectionController): void {
        this.is_selecting = true
        this.selection_controller = selection_controller
        this.number_of_cases = this.original_number_of_cases
    }

    update_target_sales_order(vnode: m.Vnode<MoveSalesOrderTBOItemAttrs>, artkey: string) {
        if (artkey) {
            this.target_sales_order = vnode.attrs.potential_sales_orders.find((so) => String(so.artkey) === String(+artkey)) || null
        } else {
            this.target_sales_order = null
        }
    }

    start_move(vnode: m.Vnode<MoveSalesOrderTBOItemAttrs>) {
        if (this.number_of_cases > vnode.attrs.tbo.number_of_cases) {
            notifier.notify('Number of cases too high', 'warning')
        }
        vnode.attrs.move_operation(this.number_of_cases, this.target_sales_order, undefined, vnode.attrs.tbo)
        this.number_of_cases = 0
    }

    view(vnode: m.Vnode<MoveSalesOrderTBOItemAttrs>): m.Children {
        const tbo = vnode.attrs.tbo
        return (
            <tbody className={'table-row'} key={`${tbo.artkey}`}>
                <tr>
                    <td>
                        {this.is_selecting && (
                            <span>
                                <input
                                    type={'checkbox'}
                                    id={`select-${tbo.artkey}`}
                                    oninput={() =>
                                        this.selection_controller?.is_in_selection('tbo', tbo.artkey)
                                            ? this.selection_controller?.remove_from_selection('tbo', tbo.artkey)
                                            : this.selection_controller?.add_to_selection('tbo', tbo.artkey)
                                    }
                                    checked={this.selection_controller?.is_in_selection('tbo', tbo.artkey)}
                                />
                                <label htmlFor={`select-${tbo.artkey}`}/>
                            </span>
                        )}
                    </td>
                    <td className={'col-sm-2'}>{tbo.case.bottle.product.name}</td>
                    <td className={'col-sm-1 number'}>{tbo.case.number_of_bottles}</td>
                    <td className={'col-sm-1'}>{to_specs(tbo.case.bottle, $s.identity.user.decimal_locale)}</td>
                    <td className={'col-sm-1'}>{tbo.case.gift_box_type}</td>
                    <td className={'col-sm-1'}>{tbo.case.customs_status}</td>
                    <td className={'col-sm-1 number'}>{tbo.number_of_cases}</td>
                    <td className={'col-sm-1'}>
                        {!vnode.attrs.is_loading && (
                            <NumberInput
                                oninput={(value: number | null) => (this.number_of_cases = value || 0)}
                                minimum={0}
                                disabled={this.is_selecting}
                                maximum={tbo.number_of_cases}
                                value={this.number_of_cases}
                            />)}
                    </td>

                    <td className={'col-sm-6'}>
                        {vnode.attrs.is_loading && <Spinner/>}
                        {!vnode.attrs.is_loading && (
                            <div>
                                <div>
                                    {<FieldSelect
                                        disabled={this.number_of_cases === 0 || this.is_selecting}
                                        // Hack to make sure the field is not empty when the target sales order is null.
                                        model={this.target_sales_order ? [this.target_sales_order, 'artkey'] : [this, '_target_sales_order_artkey']}
                                        onchange={(value: string) => this.update_target_sales_order(vnode, value)}
                                        options={potential_sales_orders_as_drop_down_options(vnode.attrs.potential_sales_orders)}
                                        placeholder="Select existing"
                                    />}
                                </div>
                                <DefaultButton
                                    icon_class={'glyphicon glyphicon-arrow-right'}
                                    disabled={this.number_of_cases === 0 || this.is_selecting}
                                    onclick={() => this.start_move(vnode)}
                                    title={
                                        this.target_sales_order
                                            ? ` Move to ${this.target_sales_order.reference}`
                                            : ' Move to new order'
                                    }
                                />
                            </div>
                        )}
                    </td>
                </tr>
            </tbody>
        )
    }
}
