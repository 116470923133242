/** llm:tested */
import m from 'mithril'
import {Amount, Link, SubAmount} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {capitalize, formatDate, pluralize} from '@/_utils'
import {accountIconBySlug} from '@/accounts'
import {button_with_icon, icon_button, text_button} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import {icon, icon_with_popover} from '@/components/icon'
import {blank_link} from '@/components/link'
import {Modal} from '@/components/modal/modal'
import {pricelist_link} from '@/data/components/pricelist_link'
import {AddToSalesOrder} from '@/offer/components/add_to_sales_order'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {OfferPrice} from '@/offer/components/offer_price'
import {PurchaseOrderStatus} from '@/models/purchase_orders'
import {SearchBar} from '@/components/collection/search_bar'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {$m, $s} from '@/app'

interface SupplierAttr {
    artkey: () => number
    name: () => string
    portal_customs_visibility: () => string
    currency: () => string
}

export interface SalesOrderAttr {
    artkey: () => number
    supplier: () => SupplierAttr
    was_sold_in: () => string
    includes_excise: () => boolean
}

class ItemSearchMode {
    static OFFER = 'offer'
    static PURCHASE = 'purchase'
    static MARKET = 'market'
}

const BASE_COLLECTION_OPTIONS = {
    query_limit: 8,
    filter_serverside: true,
    paged: true,
    dont_reuse: true,
    only_query_on_search: true,
}

export class AddItemToSalesOrder extends MithrilTsxComponent<any> {
    private sales_order: any
    private sales_order_item_added: any
    private record_to_add: any
    private search_mode: string
    private items: any
    private search_bar_controller: any
    private product_management_api: ProductManagementApi

    constructor(vnode: m.Vnode) {
        super()
        this.sales_order = vnode.attrs.sales_order
        this.sales_order_item_added = vnode.attrs.sales_order_item_added
        this.record_to_add = null
        this.search_mode = ItemSearchMode.OFFER
        this.offer_search_mode()
        this.search_bar_controller = null
        this.product_management_api = new ProductManagementApi()
    }

    submit_search(text: string) {
        this.items.update_search_term(text)
        this.items.submit_search()
    }

    oncreate() {
        this.init()
    }

    init() {
        this.items.init()
    }

    offer_search_mode() {
        this.search_mode = ItemSearchMode.OFFER
        this.items = new Collection(Object.assign({}, BASE_COLLECTION_OPTIONS, {
            api_function_name: 'pricelist.get_offer_item_records',
            sort_order: [
                {name: 'case_number_of_bottles', direction: 'asc'},
                {name: 'bottle_volume', direction: 'asc'},
                {name: 'bottle_alcohol_percentage', direction: 'asc'},
                {name: 'bottle_refill_status', direction: 'desc'},
            ],
            default_sort_by: 'stock_age',
            default_sort_order: 'desc',
            additional_params: window.prop({
                supplier_artkey: this.sales_order().supplier().artkey(),
                only_show_type: ['purchase', 'stock', 'tbo'],
                hidden_selection: 'visible_only',
                customs_status_selection: this.sales_order().supplier().portal_customs_visibility(),
            }),
        }))

        this.items.init()
        this.items.requery()
    }

    purchase_search_mode() {
        this.search_mode = ItemSearchMode.PURCHASE
        const search_term = this.items ? this.items.search_term() : null

        this.items = new Collection(Object.assign({}, BASE_COLLECTION_OPTIONS, {
            api_function_name: 'purchase.core.search_purchase_order_items',
            sort_order: [
                {name: 'number_of_cases_available', direction: 'desc'},
                {name: 'purchase_order_date', direction: 'desc'},
                {name: 'purchase_order_reference', direction: 'desc'},
                {name: 'product_name', direction: 'asc'},
                {name: 'bottle_volume', direction: 'asc'},
                {name: 'bottle_alcohol_percentage', direction: 'asc'},
                {name: 'bottle_refill_status', direction: 'desc'},
            ],
            default_sort_by: 'number_of_cases_available',
            default_sort_order: 'desc',
            additional_params: window.prop({
                status: [PurchaseOrderStatus.SAVED, PurchaseOrderStatus.CONFIRMED, PurchaseOrderStatus.READY_FOR_FIS, PurchaseOrderStatus.STOCKED_AND_BOOKED],
                customs_status_selection: this.sales_order().supplier().portal_customs_visibility(),
            }),
        }))

        this.items.update_search_term(search_term)
        this.init()
    }

    market_search_mode() {
        this.search_mode = ItemSearchMode.MARKET
        const search_term = this.items ? this.items.search_term() : null

        this.items = new Collection(Object.assign({}, BASE_COLLECTION_OPTIONS, {
            api_function_name: 'marketanalysis.get_supplier_price_list_items',
            sort_order: [
                {name: 'artkey', direction: 'asc'},
            ],
            default_sort_by: 'score',
            default_sort_order: 'asc',
            additional_params: window.prop({
                customs_status_selection: this.sales_order().supplier().portal_customs_visibility(),
            }),
        }))

        this.items.update_search_term(search_term)
        this.init()
    }

    on_item_add() {
        this.record_to_add = null
        $m.common.observable.broadcast('sales_order_updated_' + this.sales_order().artkey())
        $('input#search_add').focus()
        this.items.update_search_term('')
        if (this.sales_order_item_added) {
            this.sales_order_item_added()
        }
        this.init()
    }

    color_class(value: number) {
        if (value > 8.8) {
            return '.analysis-good-color'
        } else if (value < 0) {
            return '.analysis-bad-color'
        } else {
            return ''
        }
    }

    view() {
        return <div class="mwrap">
            {button_with_icon('Add product to order', 'plus', {
                class: 'btn btn-default mb-2',
                'data-target': '#add_item',
                'data-toggle': 'collapse',
            })}

            {this.record_to_add && (() => {
                const product_name = this.search_mode === ItemSearchMode.MARKET
                    ? this.record_to_add.bottle.product.name
                    : this.record_to_add.product_name

                const modal_attrs = {
                    title: `Add ${product_name} to sales order`,
                    onclose: () => this.record_to_add = null,
                }

                return <Modal {...modal_attrs}>
                    {this.search_mode === ItemSearchMode.OFFER &&
                        <AddToSalesOrder
                            offer_item={this.record_to_add}
                            on_added_item={() => this.on_item_add()}
                            supplier_artkey={this.sales_order().supplier().artkey()}
                            supplier_currency={this.sales_order().supplier().currency()}
                            sales_order_artkey={this.sales_order().artkey()}
                            sales_order_currency={this.sales_order().was_sold_in()}
                        />
                    }
                    {this.search_mode === ItemSearchMode.PURCHASE &&
                        <AddToSalesOrder
                            purchase_order_item={this.record_to_add}
                            on_added_item={() => this.on_item_add()}
                            supplier_artkey={this.sales_order().supplier().artkey()}
                            supplier_currency={this.sales_order().supplier().currency()}
                            sales_order_artkey={this.sales_order().artkey()}
                            sales_order_currency={this.sales_order().was_sold_in()}
                        />
                    }
                    {this.search_mode === ItemSearchMode.MARKET &&
                        <AddToSalesOrder
                            spli={this.record_to_add}
                            on_added_item={() => this.on_item_add()}
                            supplier_artkey={this.sales_order().supplier().artkey()}
                            supplier_currency={this.sales_order().supplier().currency()}
                            sales_order_artkey={this.sales_order().artkey()}
                            sales_order_currency={this.sales_order().was_sold_in()}
                        />
                    }
                </Modal>
            })()}

            <div class="collapse" id="add_item">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="panel-title">Find products to add</div>
                    </div>
                    <div class="panel-body">
                        <div key={this.search_mode}>
                            <SearchBar
                                placeholder="Search for products, specs, TBO supplier, tax label or other item tags..."
                                on_submit={(e) => this.submit_search(e)}
                                default_search_text={this.items.search_term()}
                                search_bar_controller={(search_bar_controller) => this.search_bar_controller = search_bar_controller}
                                on_get_suggestions$={(filter_text) => this.product_management_api.get_simple_product_names(filter_text)}
                            />

                            <div class="btn-group">
                                {text_button('Offer items', {
                                    class: this.search_mode === ItemSearchMode.OFFER ? 'btn-primary' : 'btn-default',
                                    onclick: this.search_mode !== ItemSearchMode.OFFER ? () => this.offer_search_mode() : undefined,
                                })}
                                {text_button('Stock and Purchase', {
                                    class: this.search_mode === ItemSearchMode.PURCHASE ? 'btn-primary' : 'btn-default',
                                    onclick: this.search_mode !== ItemSearchMode.PURCHASE ? () => this.purchase_search_mode() : undefined,
                                })}
                                {text_button('Market', {
                                    class: this.search_mode === ItemSearchMode.MARKET ? 'btn-primary' : 'btn-default',
                                    onclick: this.search_mode !== ItemSearchMode.MARKET ? () => this.market_search_mode() : undefined,
                                })}
                            </div>

                            {/* Collection tables for each mode */}
                            {this.search_mode === ItemSearchMode.OFFER &&
                                <CollectionTable
                                    collection={this.items}
                                    options={{
                                        search_table_style: false,
                                        sticky_header: false,
                                        with_buttons: true,
                                        autoscale: true,
                                        unique_name: 'offer_item_list',
                                        row_classes: (record) => record.is_hidden ? ['offer-item__is-hidden', 'clickable'] : ['clickable'],
                                    }}
                                    view_details={(record) =>
                                        <CaseInfoPanel bottle_artkey={record.bottle_artkey} />
                                    }
                                    columns={[
                                        {
                                            width: 4,
                                            name: 'Article code',
                                            field: 'case_article_code',
                                            sort: true,
                                        },
                                        {
                                            width: 12,
                                            name: 'Product',
                                            sort: true,
                                            field: 'product_name',
                                            ellipsis: true,
                                        },
                                        {
                                            width: 5,
                                            name: 'Category',
                                            sort: true,
                                            field: 'product_category',
                                            ellipsis: true,
                                            transform: capitalize,
                                        },
                                        {
                                            width: 5,
                                            name: 'Supplier',
                                            sort: true,
                                            field: 'supplier_name',
                                            ellipsis: true,
                                        },
                                        {
                                            width: 10,
                                            name: 'Specs',
                                            function: (record) => {
                                                const specs = []
                                                specs.push(record.case_number_of_bottles)
                                                specs.push((+record.bottle_volume).toFixed(1))
                                                specs.push((+record.bottle_alcohol_percentage).toFixed(1))
                                                specs.push(record.bottle_refill_status)
                                                return specs.join(' / ')
                                            },
                                        },
                                        {
                                            width: 8,
                                            name: 'Features',
                                            function: (record) => {
                                                const features = []
                                                if (record.case_gift_box_type) {
                                                    features.push(record.case_gift_box_type)
                                                }
                                                if (record.case_tax_label) {
                                                    features.push(record.case_tax_label)
                                                }
                                                if (record.item_best_before_date) {
                                                    features.push(`BBD: ${formatDate(record.item_best_before_date)}`)
                                                }
                                                if (record.item_damages) {
                                                    features.push(record.item_damages.replace(', ', ' / '))
                                                }
                                                if (record.item_general_tags) {
                                                    features.push(record.item_general_tags.replace(', ', ' / '))
                                                }
                                                if (record.item_pack_size) {
                                                    features.push(record.item_pack_size)
                                                }
                                                if (record.item_packaging) {
                                                    features.push(record.item_packaging)
                                                }
                                                return features.join(' / ')
                                            },
                                        },
                                        {
                                            width: 3,
                                            header: 'Cus.',
                                            name: 'Customs status',
                                            sort: true,
                                            field: 'case_customs_status',
                                        },
                                        {
                                            width: 1,
                                            header: icon_with_popover({
                                                iconId: 'calendar',
                                                content: 'Stock age in days',
                                            }),
                                            name: 'Stock age in days',
                                            sort: true,
                                            field: 'stock_age',
                                        },
                                        {
                                            width: 6,
                                            name: 'Stock value',
                                            sort: true,
                                            field: 'euro_total_stock_value',
                                            classes: ['price'],
                                            function: (record) => {
                                                if (record.offer_item_type !== 'tbo') {
                                                    return <Amount
                                                        amount={+record.euro_total_stock_value}
                                                        currency={$s.currencies.default}
                                                    />
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases in stock',
                                            header: icon_with_popover({
                                                iconId: 'home',
                                                content: 'Number of cases in stock',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_in_stock',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases in purchase',
                                            header: icon_with_popover({
                                                iconId: 'shopping-cart',
                                                content: 'Number of cases in purchase',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_in_purchase',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases in sales',
                                            header: icon_with_popover({
                                                iconId: 'screenshot',
                                                content: 'Number of cases in sales',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_in_sales',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases available',
                                            header: icon_with_popover({
                                                iconId: 'fa-shield-alt',
                                                content: 'Number of cases available',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_available',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 7,
                                            header: 'Max Qty',
                                            name: 'Maximum Quantity',
                                            field: 'maximum_quantity',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 7,
                                            header: 'MOQ',
                                            name: 'Minimum Order Quantity',
                                            field: 'minimum_quantity',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 5,
                                            header: 'List Qty',
                                            name: 'List Quantity',
                                            field: 'list_quantity',
                                            classes: ['number'],
                                            function: (record) => {
                                                if (record.maximum_quantity) {
                                                    return Math.min(record.maximum_quantity, record.number_of_cases_available)
                                                } else {
                                                    return record.number_of_cases_available
                                                }
                                            },
                                        },
                                        {
                                            width: 11,
                                            name: 'Avg purchase / cs',
                                            sort: true,
                                            field: 'avg_purchase_price',
                                            classes: ['price'],
                                            function: (record) => {
                                                if (record.avg_purchase_price) {
                                                    if ($s.include_excise) {
                                                        return [
                                                            <Amount
                                                                amount={+record.avg_purchase_price + +record.case_excise_nl}
                                                                currency={$s.currencies.default}
                                                            />,
                                                            <SubAmount
                                                                label="Ex Duty"
                                                                amount={record.avg_purchase_price}
                                                                currency={$s.currencies.default}
                                                            />,
                                                        ]
                                                    } else {
                                                        return <Amount
                                                            amount={record.avg_purchase_price}
                                                            currency={$s.currencies.default}
                                                        />
                                                    }
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 12,
                                            name: 'Base sales / cs',
                                            sort: true,
                                            classes: ['price'],
                                            field: 'list_price',
                                            function: (record) => {
                                                return <OfferPrice
                                                    list_price={record.list_price}
                                                    original_price_per_case={record.original_price_per_case}
                                                    offer_type={record.offer_type}
                                                    currency={record.currency}
                                                    includes_excise_in_price={record.includes_excise_in_price}
                                                    excise_per_case={record.excise_per_case}
                                                />
                                            },
                                        },
                                        {
                                            width: 12,
                                            name: 'Relation sales / cs',
                                            sort: true,
                                            field: 'supplier_list_price',
                                            classes: ['price'],
                                            function: (record) =>
                                                <Amount
                                                    amount={+record.supplier_list_price}
                                                    currency={$s.currencies.default}
                                                />,
                                        },
                                        {
                                            width: 6,
                                            name: 'Margin %',
                                            sort: true,
                                            field: 'margin_percentage',
                                            classes: ['price'],
                                            function: (record) => {
                                                if (record.margin_percentage) {
                                                    const margin_percentage = +record.margin_percentage
                                                    return <span class={this.color_class(margin_percentage)}>
                                                        {margin_percentage.toFixed(2)}%
                                                    </span>
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 4,
                                            name: 'Delivery period',
                                            sort: true,
                                            field: 'delivery_period',
                                            classes: ['number'],
                                            transform: (delivery_period) => {
                                                if (delivery_period) {
                                                    return `${delivery_period} ${pluralize(delivery_period, 'week', 'weeks')}`
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 3,
                                            name: 'Item type',
                                            field: 'offer_item_type',
                                            transform: (type) => {
                                                if (type === 'tbo') {
                                                    return 'TBO'
                                                } else {
                                                    return type.charAt(0).toUpperCase() + type.slice(1)
                                                }
                                            },
                                        },
                                        {
                                            width: 16,
                                            header: '',
                                            name: 'Actions',
                                            function: (record) =>
                                                <div class="btn-toolbar no-click">
                                                    <div class="btn-group">
                                                        {icon_button('screenshot', {
                                                            class: 'btn-default',
                                                            onclick: () => this.record_to_add = record,
                                                        })}
                                                    </div>
                                                </div>,
                                        },
                                    ]}
                                />
                            }

                            {this.search_mode === ItemSearchMode.PURCHASE &&
                                <CollectionTable
                                    collection={this.items}
                                    options={{
                                        search_table_style: false,
                                        sticky_header: false,
                                        with_buttons: true,
                                        autoscale: true,
                                        unique_name: 'offer_item_list',
                                        row_classes: () => ['clickable'],
                                    }}
                                    view_details={(record) =>
                                        <CaseInfoPanel bottle_artkey={record.bottle_artkey} />
                                    }
                                    columns={[
                                        {
                                            width: 4,
                                            name: 'Article code',
                                            field: 'case_article_code',
                                            sort: true,
                                        },
                                        {
                                            width: 8,
                                            name: 'Product',
                                            sort: true,
                                            field: 'product_name',
                                            ellipsis: true,
                                        },
                                        {
                                            width: 5,
                                            name: 'Category',
                                            sort: true,
                                            field: 'product_category',
                                            ellipsis: true,
                                            transform: capitalize,
                                        },
                                        {
                                            width: 7,
                                            name: 'Supplier',
                                            sort: true,
                                            field: 'supplier_name',
                                            ellipsis: true,
                                        },
                                        {
                                            width: 5,
                                            name: 'P.O. #',
                                            sort: true,
                                            field: 'purchase_order_reference',
                                            ellipsis: true,
                                            transform: (purchase_order_reference) =>
                                                <Link
                                                    target="_blank"
                                                    href={`/purchase-orders/manage/${purchase_order_reference}`}
                                                >
                                                    {purchase_order_reference}
                                                </Link>,
                                        },
                                        {
                                            width: 10,
                                            name: 'Specs',
                                            function: (record) => {
                                                const specs = []
                                                specs.push(record.case_number_of_bottles)
                                                specs.push((+record.bottle_volume).toFixed(1))
                                                specs.push((+record.bottle_alcohol_percentage).toFixed(1))
                                                specs.push(record.bottle_refill_status)
                                                return specs.join(' / ')
                                            },
                                        },
                                        {
                                            width: 8,
                                            name: 'Features',
                                            function: (record) => {
                                                const features = []
                                                if (record.case_gift_box_type) {
                                                    features.push(record.case_gift_box_type)
                                                }
                                                if (record.case_tax_label) {
                                                    features.push(record.case_tax_label)
                                                }
                                                if (record.cases_per_pallet) {
                                                    features.push(`CPP: ${record.cases_per_pallet}`)
                                                }
                                                if (record.item_best_before_date) {
                                                    features.push(`BBD: ${formatDate(record.item_best_before_date)}`)
                                                }
                                                if (record.item_damages) {
                                                    features.push(record.item_damages.replace(', ', ' / '))
                                                }
                                                if (record.item_general_tags) {
                                                    features.push(record.item_general_tags.replace(', ', ' / '))
                                                }
                                                if (record.item_pack_size) {
                                                    features.push(record.item_pack_size)
                                                }
                                                if (record.item_packaging) {
                                                    features.push(record.item_packaging)
                                                }
                                                return features.join(' / ')
                                            },
                                        },
                                        {
                                            width: 3,
                                            header: 'Cus.',
                                            name: 'Customs status',
                                            sort: true,
                                            field: 'case_customs_status',
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases in purchase',
                                            header: icon_with_popover({
                                                iconId: 'shopping-cart',
                                                content: 'Number of cases in purchase',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 2,
                                            name: 'Number of cases available',
                                            header: icon_with_popover({
                                                iconId: 'fa-shield-alt',
                                                content: 'Number of cases available',
                                            }),
                                            sort: true,
                                            field: 'number_of_cases_available',
                                            classes: ['number'],
                                        },
                                        {
                                            width: 9,
                                            name: 'Purchase / cs',
                                            sort: true,
                                            field: 'was_bought_for',
                                            classes: ['price'],
                                            function: (record) => {
                                                return <Amount
                                                    amount={+record.was_bought_for}
                                                    currency={record.was_bought_in}
                                                />
                                            },
                                        },
                                        {
                                            width: 9,
                                            name: 'Sales / cs',
                                            sort: true,
                                            field: 'suggested_price_per_case',
                                            classes: ['price'],
                                            function: (record) => {
                                                if (record.suggested_price_per_case) {
                                                    return <Amount
                                                        amount={+record.suggested_price_per_case}
                                                        currency={this.sales_order().supplier().currency()}
                                                        rate={$s.currencies.exchange_rates[this.sales_order().supplier().currency()].portal_rate}
                                                    />
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 6,
                                            name: 'Margin %',
                                            sort: true,
                                            field: 'margin_percentage',
                                            classes: ['price'],
                                            function: (record) => {
                                                if (record.suggested_price_per_case) {
                                                    const margin = +record.suggested_price_per_case - +record.was_bought_for
                                                    const margin_percentage = margin / +record.was_bought_for * 100
                                                    return <span class={this.color_class(margin_percentage)}>
                                                        {margin_percentage.toFixed(2)}%
                                                    </span>
                                                } else {
                                                    return '-'
                                                }
                                            },
                                        },
                                        {
                                            width: 6,
                                            name: 'ETA',
                                            sort: true,
                                            field: 'purchase_order_expected_delivery_date',
                                            transform: formatDate,
                                        },
                                        {
                                            width: 4,
                                            name: 'Purchase status',
                                            field: 'purchase_order_status',
                                            function: (record) =>
                                                <m.route.Link
                                                    href={`/purchase-orders/manage/${record.purchase_order_reference}?account=${record.purchase_order_account_slug}`}
                                                    target="_blank"
                                                >
                                                    {accountIconBySlug(record.purchase_order_account_slug, '')}
                                                    {' '}
                                                    {record.purchase_order_status}
                                                </m.route.Link>,
                                        },
                                        {
                                            width: 16,
                                            header: '',
                                            name: 'Actions',
                                            function: (record) =>
                                                <div class="btn-toolbar no-click">
                                                    <div class="btn-group">
                                                        {icon_button('screenshot', {
                                                            class: 'btn-default',
                                                            onclick: () => this.record_to_add = record,
                                                        })}
                                                    </div>
                                                </div>,
                                        },
                                    ]}/>
                            }

                            {this.search_mode === ItemSearchMode.MARKET &&
                                    <CollectionTable
                                        collection={this.items}
                                        options={{
                                            search_table_style: false,
                                            sticky_header: false,
                                            with_buttons: true,
                                            autoscale: true,
                                            unique_name: 'offer_item_list',
                                            row_classes: () => ['clickable'],
                                        }}
                                        view_details={(record) =>
                                            <CaseInfoPanel bottle_artkey={record.bottle.artkey} />
                                        }
                                        columns={[
                                            {
                                                width: 3,
                                                sort: true,
                                                field: 'score',
                                                name: 'Rank',
                                                function: (spli) => `${spli.rank} / ${spli.total}`,
                                            },
                                            {
                                                width: 12,
                                                name: 'Product',
                                                sort: true,
                                                field: 'bottle.product.name',
                                                ellipsis: true,
                                            },
                                            {
                                                width: 6,
                                                sort: true,
                                                field: 'bottle.product.category',
                                                name: 'Category',
                                                ellipsis: true,
                                                transform: capitalize,
                                            },
                                            {
                                                width: 14,
                                                name: 'Supplier',
                                                sort: true,
                                                field: 'supplier_price_list.supplier',
                                                ellipsis: true,
                                                function: (record) =>
                                                    <span>
                                                        {blank_link(pricelist_link.from_bottle_json(
                                                            record.supplier_price_list.artkey,
                                                            record.bottle,
                                                        ), icon('new-window'))}
                                                        {' '}
                                                        {record.supplier_price_list.supplier.name}
                                                    </span>,
                                            },
                                            {
                                                width: 10,
                                                name: 'Incoterm',
                                                field: 'incoterm',
                                                ellipsis: true,
                                            },
                                            {
                                                width: 10,
                                                name: 'Specs',
                                                function: (record) => {
                                                    const specs = []
                                                    specs.push(record.number_of_bottles_per_case || '-')
                                                    specs.push((+record.bottle.volume).toFixed(1))
                                                    specs.push((+record.bottle.alcohol_percentage).toFixed(1))
                                                    specs.push(record.bottle.refill_status)
                                                    return specs.join(' / ')
                                                },
                                            },
                                            {
                                                width: 6,
                                                name: 'GB',
                                                function: (record) => {
                                                    const features = []
                                                    if (record.case_gift_box_type) {
                                                        features.push(record.gift_box_type)
                                                    }
                                                    return features.join(' / ')
                                                },
                                            },
                                            {
                                                width: 10,
                                                name: 'Aux.',
                                                field: 'aux_info',
                                            },
                                            {
                                                width: 3,
                                                header: 'Cus.',
                                                name: 'Customs status',
                                                sort: true,
                                                field: 'customs_status',
                                            },
                                            {
                                                width: 2,
                                                name: 'Number of bottles available',
                                                header: icon_with_popover({
                                                    iconId: 'fa-wine-bottle',
                                                    content: 'Number of bottles available',
                                                }),
                                                sort: true,
                                                field: 'number_of_cases',
                                                classes: ['number'],
                                            },
                                            {
                                                width: 2,
                                                name: 'Number of cases available',
                                                header: icon_with_popover({
                                                    iconId: 'fa-shield-alt',
                                                    content: 'Number of cases available',
                                                }),
                                                sort: true,
                                                field: 'number_of_cases',
                                                classes: ['number'],
                                            },
                                            {
                                                width: 6,
                                                name: 'Price / btl',
                                                sort: true,
                                                field: 'price_per_bottle',
                                                classes: ['price'],
                                                function: (record) =>
                                                    <Amount
                                                        amount={+record.price_per_bottle}
                                                        currency={record.currency}
                                                    />,
                                            },
                                            {
                                                width: 6,
                                                name: 'Price / cs',
                                                sort: true,
                                                field: 'price_per_case',
                                                classes: ['price'],
                                                function: (record) => {
                                                    if (record.price_per_case) {
                                                        return <Amount
                                                            amount={+record.price_per_case}
                                                            currency={record.currency}
                                                        />
                                                    } else {
                                                        return '-'
                                                    }
                                                },
                                            },
                                            {
                                                width: 6,
                                                name: 'Availability',
                                                sort: true,
                                                field: 'availability_date',
                                                transform: formatDate,
                                            },
                                            {
                                                width: 12,
                                                name: 'Avail. status',
                                                sort: true,
                                                field: 'availability_status',
                                            },
                                            {
                                                width: 16,
                                                header: '',
                                                name: 'Actions',
                                                function: (record) =>
                                                    <div class="btn-toolbar no-click">
                                                        <div class="btn-group">
                                                            {icon_button('screenshot', {
                                                                class: 'btn-default',
                                                                onclick: () => this.record_to_add = record,
                                                            })}
                                                        </div>
                                                    </div>,
                                            },
                                        ]}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
