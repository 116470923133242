/** llm:tested */
import m from 'mithril'
import {DateTime} from 'luxon'
import {Button, Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import api from '@/api'
import {button_with_icon} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import {contact_person_link, relation_link} from '@/components/entity_links'
import inputs from '@/components/inputs'
import {PortalUser} from '@/portal/models'
import {formatDateTime} from '@/_utils'

interface PortalUserSession {
    artkey: string
    remote_ip_address: string
    country_name: string
    region_name: string
    longitude: number
    latitude: number
    created_on: string
    was_last_updated_on: string
    duration: number
    number_of_activities: number
}

interface Activity {
    created_on: string
    page: string
}

export const PortalUserActivity = (): m.Component => {
    const user = new PortalUser()
    const to_date = window.prop(DateTime.local().toISODate())
    const from_date = window.prop(DateTime.local().minus({days: 7}).toISODate())

    const artkey = m.route.param('artkey')
    api.callAndThen('portal.user_management.get_user', {artkey: artkey}, {
        success: (resp) => {
            user.load(resp.result)
        },
        failure: () => {
            notifier.notify('Unknown portal user.', 'danger')
            m.route.set('/portal/portal-users')
        },
    })

    const params = () => ({
        artkey: artkey,
        from_date: from_date(),
        to_date: to_date(),
    })

    const sessions = new Collection({
        api_function_name: 'portal.user_management.get_user_sessions',
        query_limit: 25,
        sort_order: [
            {name: 'created_on', direction: 'desc'},
            {name: 'was_last_updated_on', direction: 'desc'},
            {name: 'duration', direction: 'desc'},
            {name: 'number_of_activities', direction: 'desc'},
        ],
        default_sort_by: 'created_on',
        default_sort_order: 'desc',
        filter_serverside: true,
        paged: true,
        additional_params: params,
    })

    return {
        oncreate: () => {
            sessions.init()
        },

        view: () => <div className="c-portal-user-activity view">
            <div class="btn-toolbar">
                {button_with_icon('Back to list', 'arrow-left', {
                    class: 'btn-default',
                    onclick: () => m.route.set('/portal/portal-users'),
                })}
            </div>

            <h2>Portal user activity</h2>

            <dl class="dl-horizontal">
                <dt>Username</dt>
                <dd>{user.name()}</dd>
                <dt>Contact person</dt>
                <dd>{contact_person_link(user.contact_person())}</dd>
                <dt>Relation</dt>
                <dd>{relation_link(user.contact_person().supplier())}</dd>
                <dt>Last seen</dt>
                <dd>{formatDateTime(user.last_seen_timestamp())}</dd>
                <dt>Times seen</dt>
                <dd>{user.number_of_sessions()}</dd>
            </dl>

            <div class="c-filter-group">
                {inputs.date(from_date, {
                    label: 'From',
                    required: true,
                })}
                {inputs.date(to_date, {
                    label: 'To',
                    required: true,
                })}
            </div>

            <Button
                className="mb-2"
                onclick={() => {
                    sessions.requery()
                }}
                text="Lookup Activity"
                type="success"
            />

            <CollectionTable
                collection={sessions}
                options={{
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: true,
                    autoscale: true,
                }}
                view_details={(record: PortalUserSession) =>
                    m(PortalUserSessionActivity, {portal_user_session: record})
                }
                columns={[
                    {
                        width: 4,
                        name: 'Started',
                        field: 'created_on',
                        sort: true,
                        descending: true,
                        transform: formatDateTime,
                    },
                    {
                        width: 4,
                        name: 'Ended',
                        field: 'was_last_updated_on',
                        sort: true,
                        descending: true,
                        transform: formatDateTime,
                    },
                    {
                        width: 4,
                        name: 'Duration (minutes)',
                        field: 'duration',
                        sort: true,
                        descending: true,
                    },
                    {
                        width: 4,
                        name: 'Page views',
                        field: 'number_of_activities',
                        sort: true,
                        descending: true,
                    },
                ]}
            />
        </div>,
    }
}

const PortalUserSessionActivity = (initial_vnode: m.Vnode<{portal_user_session: PortalUserSession}>): any => {
    const portal_user_session = initial_vnode.attrs.portal_user_session
    const activities = window.prop<Activity[]>([])
    const loading = window.prop(true)

    return {
        oncreate: () => {
            api.callAndThen('portal.user_management.get_session_activity', {artkey: portal_user_session.artkey}, {
                success: (resp) => {
                    activities(resp.result)
                    loading(false)
                },
            })
        },

        view: () => <span>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-title">Session data</div>
                </div>
                <div class="panel-body">
                    {loading() ? <Spinner /> :
                        <dl class="dl-horizontal">
                            <dt>IP address</dt>
                            <dd>{portal_user_session.remote_ip_address}</dd>
                            <dt>Country</dt>
                            <dd>{portal_user_session.country_name}</dd>
                            <dt>Region</dt>
                            <dd>{portal_user_session.region_name}</dd>
                            <dt>Location</dt>
                            <dd>
                                {portal_user_session.longitude && portal_user_session.latitude && [
                                    portal_user_session.latitude,
                                    ', ',
                                    portal_user_session.longitude,
                                ]}
                            </dd>
                        </dl>
                    }
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-title">Page views</div>
                </div>
                <div class="panel-body">
                    {loading() ? <Spinner /> :
                        <table class="table table-condensed">
                            <thead class="thead-default">
                                <tr>
                                    <th>Moment</th>
                                    <th>Page</th>
                                </tr>
                            </thead>
                            <tbody>
                                {activities().map((activity) =>
                                    <tr>
                                        <td>{formatDateTime(activity.created_on)}</td>
                                        <td>{activity.page}</td>
                                    </tr>,
                                )}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </span>,
    }
}
