import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    CollectionHeader,
    CollectionItems,
    CollectionView,
    PanelFilters,
    RowActionDelete,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api, notifier} from '@bitstillery/common/app'

import {SuppressionListResponse} from '@/factserver_api/fact2server_api'

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Key',
        render: (row: SuppressionListResponse) => row.key,
    },
]

export class ProductListSuppressed extends MithrilTsxComponent<unknown> {

    oninit() {
        collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: 'discover/suppressed-product-keys/collection-view',
            },
        })
    }

    view(_vnode:m.Vnode<any>) {
        return <div className="c-suppressed-product-list view-container">
            <PanelFilters collection={collection} />
            <CollectionView
                mode="table">
                <CollectionHeader collection={collection} columns={columns} />
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row: SuppressionListResponse) => {
                        return [
                            <RowActionDelete
                                tip={'Remove this entry from the suppression list'}
                                row={row}
                                row_delete={async() => {
                                    const {
                                        status_code,
                                        result,
                                    } = await api.delete(`/discover/suppressed-product-keys/${row.artkey}`)
                                    if (status_code > 299) {
                                        notifier.notify(`Cannot delete the suppressed item: ${result.detail}`)
                                        return
                                    }
                                    notifier.notify(`${row.key} is removed from the suppression list`)
                                    collection.soft_delete(row.artkey)
                                }}
                                variant="activate"
                            />,

                        ]
                    }}
                />
            </CollectionView>
        </div>
    }
}
