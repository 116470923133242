/** llm:tested */
import m from 'mithril'
import {orList} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericList} from '@/data/generic/generic_list'

interface VatType {
    vat_code: string
    description: string
    percentage: number
}

export class VatTypeList extends MithrilTsxComponent<any> {
    private is_match(vat_type: VatType, term: string): boolean {
        return orList([
            vat_type.vat_code.toLowerCase().indexOf(term) > -1,
        ])
    }

    view(_vnode: m.Vnode<any>) {
        return <GenericList
            readable_entity_name="VAT type"
            base_url="/data/vattypes"
            list_api_call="vattypes.get_all"
            is_match={this.is_match}
            columns={[
                {key: 'description', label: 'Description'},
                {key: 'percentage', label: 'Percentage'},
                {key: 'vat_code', label: 'VAT code'},
            ]}
            requery_subscribe_key="vattypes_updated"
        />
    }
}
