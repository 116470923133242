import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {api, events} from '@bitstillery/common/app'
import {Tab, TabPanel, Tabs} from '@bitstillery/common/components'
import {get_route} from '@bitstillery/common/lib/utils'

import {SpliResolved} from './components/spli_resolved'
import {SpliUnresolved} from './components/spli_unresolved'

import {$s} from '@/app'
import {SupplierPriceListResponse} from '@/factserver_api/fact2server_api'

interface SupplierPricelistModel {
    loading: {
        export: boolean
        page: boolean
    }
    product_categories: any[]
    supplier_pricelist: SupplierPriceListResponse
}

export const model: SupplierPricelistModel = proxy({
    loading: {
        export: false,
        page: true,
    },
    product_categories: [],
    supplier_pricelist: null,
}) as any

export class MarketListPricelistItems extends MithrilTsxComponent<any> {

    pricelist_artkey = Number(m.route.param('artkey'))
    refetch: () => void
    data = proxy({
        tabs: {
            active: m.route.param('tabId') ? m.route.param('tabId') : 'resolved',
        },
    })

    async oninit() {
        Object.assign($s.context, {data: null, id: null, name: null})
        model.loading.page = true
        const [{result: product_categories}, {result: supplier_pricelist}] = await Promise.all([
            api.get('discover/product-categories/select'),
            api.get(`discover/supplier-price-lists/${this.pricelist_artkey}`),
        ])

        Object.assign(model, {product_categories, supplier_pricelist})
        model.loading.page = false
        // This probably needs to be a dependency of spli/sourceline collections;
        // when those collections change, this should be called. Postpone design
        // decision until we see this happening a third time. Until then, use
        // events to signal changes.
        this.refetch = async() => {
            ({result: model.supplier_pricelist} = await api.get<SupplierPriceListResponse>(`discover/supplier-price-lists/${this.pricelist_artkey}`))
        }
        events.on('spl:refetch', this.refetch)
    }

    onremove() {
        events.off('spl:refetch', this.refetch)
    }

    tabs_context(on_init) {
        if (on_init || $s.context.name === 'data_card') return
        Object.assign($s.context, {id: null, name: null})
    }

    view(): m.Children {
        if (!model.supplier_pricelist) {
            return null
        }

        // Resolved & unresolved are separate tabs with their own CollectionItems for now.
        // It has yet to be decided whether we can use the same endpoint, filters and
        // collection logic for SpliResolved & SpliUnresolved.
        return <Tabs
            className="c-market-list-pricelist-items"
            data={this.data}
            header={{
                icon: 'pricelist',
                onclick: () => {
                    const route = get_route(`/market/pricelists/${model.supplier_pricelist.artkey}`, {meta: true})
                    m.route.set(route)
                },
                title: model.supplier_pricelist.supplier.name,
            }}
            type="nested-view"
        >
            <Tab
                icon="checked"
                id="resolved"
                link={`/market/pricelists/${this.pricelist_artkey}?tabId=resolved`}
                onactivate={(_tab, on_init) => this.tabs_context(on_init)}
                text={`Resolved${model.supplier_pricelist.supplier_price_list_items_count ? ` (${model.supplier_pricelist.supplier_price_list_items_count})` : ''}`}
            >
                <TabPanel>
                    <SpliResolved/>
                </TabPanel>
            </Tab>

            <Tab
                disabled={!model.supplier_pricelist.unresolved_source_lines_count}
                icon="question"
                id="unresolved"
                link={`/market/pricelists/${this.pricelist_artkey}?tabId=unresolved`}
                onactivate={(_tab, on_init) => this.tabs_context(on_init)}
                text={`Unresolved${model.supplier_pricelist.unresolved_source_lines_count ? ` (${model.supplier_pricelist.unresolved_source_lines_count})` : ''}`}
            >
                <TabPanel>
                    <SpliUnresolved/>
                </TabPanel>
            </Tab>
        </Tabs>
    }
}
