/** llm:tested */
export function validate_bottle_gtin(gtin: string): string | undefined {
    if (gtin.match(/^[0-9]+$/) === null) {
        return 'Please enter a bottle GTIN that does not contain none numeric characters'
    }

    if (![12, 13].includes(gtin.length)) {
        return 'Please enter a bottle GTIN that contains either 12 or 13 digits'
    }

    if (!validate_check_digit(gtin)) {
        return 'Please check if you have entered the bottle GTIN correctly as it does not seem to be valid'
    }
}

export function validate_check_digit(gtin: string): boolean {
    const gtin_min_check = gtin.substring(0, gtin.length - 1).split('').map(Number).reverse()

    let sum = 0
    let i = 1
    for (const digit of gtin_min_check) {
        if (i % 2 === 0) {
            sum += digit
        } else {
            sum += digit * 3
        }
        i++
    }

    const check_digit = (10 - (sum) % 10) % 10
    const same_digit_bool = check_digit.toString() === gtin.charAt(gtin.length - 1)
    return same_digit_bool
}
