/** llm:tested */
import m from 'mithril'
import {id, sum, map, compact} from 'prelude-ls'
import {Spinner} from '@bitstillery/common/components'

import utils from '@/_utils'
import fixedTableHeader from '@/components/table/fixed_header'

interface Column {
    width?: number
    name: string
    field?: string
    classes?: string
    sort?: boolean
    ellipsis?: boolean
    function?: (item: any) => any
}

interface TableOptions {
    sticky_header?: boolean
    with_buttons?: boolean
    search_table_style?: boolean
    autoscale?: boolean
    onclick?: (item: any) => void
    row_class?: (item: any) => string
    details?: (item: any) => any
}

interface Collection {
    sort: (field: string) => void
    sort_icon: (field: string) => any
    search_result: () => any[]
    loading: () => boolean
    can_show_more_items: () => boolean
    show_more: () => void
}

export const sum_column_widths = (columns: Column[]): number => {
    const get_width = (col: Column | null) => col ? col.width || 0 : 0
    return sum(map(get_width, columns))
}

export const autoscaled_columns = (columns: Column[], options: TableOptions): Column[] => {
    const total_width = sum_column_widths(columns)
    if (total_width !== 100 && options.autoscale) {
        const factor = 100 / total_width
        return map((col: Column | null) => {
            if (col) {
                return {...col, width: (col.width || 0) * factor}
            }
            return col
        }, columns)
    }
    return columns
}

export const table = {
    table: (items: () => any[], columns: Column[], options: TableOptions = {}) => {
        const wrapper = options.sticky_header
            ? (options.with_buttons ? fixedTableHeader.with_buttons : fixedTableHeader.without_buttons)
            : id

        const classes = ['table']
        if (!options.search_table_style?.toString() || options.search_table_style) {
            classes.push('search-table')
            classes.push('clickable')
        } else {
            classes.push('table-hover')
        }

        return m('.table-container',
            wrapper(
                m('table', {class: classes.join(' ')}, [
                    table.colgroup(columns, options),
                    table.header(columns, options),
                    table.body(items, columns, options),
                ]),
            ),
        )
    },

    collection_table: (collection: Collection, columns: Column[], options: TableOptions = {}) => {
        const wrapper = options.with_buttons ? fixedTableHeader.with_buttons : fixedTableHeader.without_buttons

        return m('.table-container',
            wrapper(
                m('table.table.search-table.clickable', [
                    table.colgroup(columns, options),
                    table.collection_header(collection, columns, options),
                    table.body(collection.search_result, columns, options),
                ]),
            ),
            table.show_more(collection),
        )
    },

    colgroup: (columns: Column[]) => {
        return m('colgroup',
            columns.filter(Boolean).map(column =>
                column.width
                    ? m('col', {style: {width: column.width.toFixed(1) + '%'}})
                    : m('col'),
            ),
        )
    },

    header: (columns: Column[], options: TableOptions) => {
        if (!options.autoscale) {
            const total_widths = sum_column_widths(columns)
            if (total_widths !== 100) {
                // eslint-disable-next-line no-console
                console.debug(`Sum of table columns widths != 100: ${total_widths}`)
            }
        }

        return m('thead.thead-default',
            m('tr',
                columns.filter(Boolean).map(column => {
                    const props: any = {}
                    if (column.classes) {
                        props.class = column.classes
                    }
                    return m('th', props, column.name)
                }),
            ),
        )
    },

    collection_header: (collection: Collection, columns: Column[], options: TableOptions) => {
        if (!options.autoscale) {
            const total_widths = sum_column_widths(columns)
            if (total_widths !== 100) {
                // eslint-disable-next-line no-console
                console.debug(`Sum of table columns widths != 100: ${total_widths}`)
            }
        }

        return m('thead.thead-default',
            m('tr',
                columns.filter(Boolean).map(column => {
                    const props: any = {}
                    if (column.classes) {
                        props.class = column.classes
                    }
                    if (column.sort) {
                        props.onclick = () => collection.sort(column.field!)
                        return m('th', props, [
                            column.name,
                            ' ',
                            collection.sort_icon(column.field!),
                        ])
                    }
                    return m('th', props, column.name)
                }),
            ),
        )
    },

    body: (items: () => any[], columns: Column[], options: TableOptions = {}) => {
        return items().map((item) => {
            const attrs: any = {}
            if (options.onclick) {
                attrs.onclick = options.onclick(item)
            }
            if (options.row_class) {
                attrs.class = options.row_class(item)
            }

            return m('tbody.table-row', {key: item.artkey},
                compact([
                    m('tr', attrs,
                        columns.filter(Boolean).map(column => {
                            const props: any = {}
                            let classes = column.classes
                            if (column.ellipsis) {
                                classes = ['ellipsis', classes].join(' ')
                            }
                            if (classes) {
                                props.class = classes
                            }

                            const content = column.function
                                ? column.function(item)
                                : utils.get_descendant_prop(item, column.field!)

                            return m('td', props,
                                column.ellipsis
                                    ? m('span', content)
                                    : content,
                            )
                        }),
                    ),
                    options.details && (() => {
                        const details = options.details(item)
                        if (details) {
                            return utils.toList(details).map(row =>
                                m('tr', m('td', {colspan: '100%'}, row)),
                            )
                        }
                        return null
                    })(),
                ]),
            )
        })
    },

    show_more: (collection: Collection) => {
        return m('.table-show-more',
            !collection.search_result().length && !collection.loading()
                ? m('p', 'No results found. Maybe the filters are too strict?')
                : collection.loading()
                    ? m(Spinner, {className: 'table-spinner'})
                    : collection.can_show_more_items()
                        ? m('button.btn.btn-info', {onclick: () => collection.show_more()}, 'Show more results')
                        : m(''),
        )
    },
}

export default table
