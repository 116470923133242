/** llm:tested */
import m from 'mithril'
import {FieldMoney, Spinner} from '@bitstillery/common/components'
import {Badge} from '@bitstillery/common/badge'
import {api} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {ExciseCategory, ExciseDutyApplications} from './models'

import api_ls from '@/api'
import {$m} from '@/app'
import {capitalize} from '@/_utils'
import {text_button, icon_button} from '@/components/_buttons'
import inputs from '@/components/inputs'
import {PercentInput} from '@/components/decimal_input'
import {CountriesSelect} from '@/components/html_components'

export class ExciseCategoriesUpsert extends MithrilTsxComponent<any> {
    excise: any
    create: any
    artkey: string
    product_categories: any
    excise_product_categories: string[]
    loading: boolean
    application_types: any[]
    currency: string

    constructor() {
        super()
        this.excise = window.prop(new ExciseCategory())
        this.create = window.prop(true)
        this.artkey = m.route.param('artkey')
        this.product_categories = window.prop([])
        this.excise_product_categories = []
        this.loading = true
        this.application_types = [
            ExciseDutyApplications.HL_PRODUCT,
            ExciseDutyApplications.HL_DEGREE_ALCOHOL,
        ]
    }

    oncreate() {
        if (this.artkey) {
            this.create(false)
        }
        this.query_all_product_categories()
    }

    query_excise(artkey: string) {
        api_ls.callAndThen('excise.get_excise_category', {artkey: artkey}, {
            success: (resp: any) => {
                this.excise(new ExciseCategory(resp.result))
                this.loading = false

                for (const category of this.excise().product_categories()) {
                    this.excise_product_categories.push(category.name)
                    const index = this.product_categories().indexOf(category.name)
                    this.product_categories().splice(index, 1)
                }
            },
            failure: () => {
                this.loading = false
            },
        })
    }

    async query_all_product_categories() {
        const {result} = await api.get('discover/product-categories/select')
        this.product_categories(result.map((it: any) => it.name))

        if (this.artkey) {
            this.query_excise(this.artkey)
        } else {
            this.loading = false
        }
    }

    save(e: Event) {
        e.preventDefault()

        if (+this.excise().from_alcohol_percentage() < +this.excise().to_alcohol_percentage()) {
            const data = {
                artkey: this.excise().artkey(),
                name: this.excise().name(),
                country_code: this.excise().country_code(),
                duty_rate: this.excise().duty_rate(),
                duty_application: this.excise().duty_application(),
                from_alcohol_percentage: this.excise().from_alcohol_percentage(),
                to_alcohol_percentage: this.excise().to_alcohol_percentage(),
                product_categories: this.excise_product_categories,
            }

            api_ls.callAndThen('excise.create_or_update_excise_category', data, {
                success: () => {
                    if (this.create()) {
                        notifier.notify(`Successfully created new excise category "${this.excise().name()}"`, 'success')
                        m.route.set('/data/excise-categories')
                    } else {
                        notifier.notify('Successfully updated excise category.', 'success')
                        m.route.set('/data/excise-categories')
                    }
                    $m.common.observable.broadcast('refresh_excise_list')
                },
                failure: (resp: any) => {
                    notifier.notify(resp.message, 'danger')
                },
            })
        } else {
            notifier.notify('Lower limit can\'t be set to the same or a higher percentage than upper limit.', 'danger')
        }
    }

    delete(artkey: string) {
        api_ls.callAndThen('excise.delete_excise_category', {artkey: artkey}, {
            success: () => {
                notifier.notify(`Successfully deleted excise category "${this.excise().name()}"`, 'success')
                $m.common.observable.broadcast('refresh_excise_list')
                m.route.set('/data/excise-categories')
            },
        })
    }

    view() {
        if (this.loading) {
            return <Spinner />
        }

        this.currency = 'EUR'
        if (this.excise().country_code() === 'GB') {
            this.currency = 'GBP'
        }

        return <div class="c-excise-category-edit view">
            <div class="btn-toolbar">
                <button class="btn btn-default" type="button" onclick={() => m.route.set('/data/excise-categories')}>
                    <span class="glyphicon glyphicon-arrow-left" /> Back to list
                </button>
                {!this.create() &&
                    <button class="btn btn-danger" type="button" onclick={() => this.delete(this.artkey)}>
                        <span class="glyphicon glyphicon-trash" />Delete
                    </button>
                }
            </div>

            <form class="columns" onsubmit={(e) => this.save(e)}>
                <div class="column">
                    <div class="fieldset">
                        {inputs.text(this.excise().name, {
                            label: 'Excise category name',
                            required: true,
                            placeholder: 'Excise category name',
                            oninput: (ev: any) => this.excise().name(ev.target.value),
                        })}

                        <CountriesSelect
                            disabled={!this.create()}
                            label="Country"
                            model={[this.excise(), 'country_code']}
                        />

                        {inputs.select(this.excise().duty_application, this.application_types, {
                            help: 'The unit to which the duty should be applied',
                            label: 'Duty Application',
                            required: true,
                            empty_option: false,
                        })}

                        <FieldMoney
                            currency={[this, 'currency']}
                            label="Duty rate"
                            model={[this.excise(), 'duty_rate']}
                        />

                        <div class="field-group">
                            <PercentInput
                                help="From, excluding (>)"
                                label="Lower limit"
                                required={true}
                                placeholder="From"
                                value={this.excise().from_alcohol_percentage()}
                                on_value={(value: any) => this.excise().from_alcohol_percentage(value)}
                            />

                            <PercentInput
                                help="Up to and including (<=)."
                                label="Upper limit"
                                required={true}
                                placeholder="To"
                                value={this.excise().to_alcohol_percentage()}
                                on_value={(value: any) => this.excise().to_alcohol_percentage(value)}
                            />
                        </div>
                    </div>

                    {text_button(`${this.create() ? 'Create Excise Category' : 'Update Excise Category'}`, {
                        class: 'btn-success',
                    })}
                </div>

                <div class="column">
                    <div class="field">
                        <label for="product_categories">Product categories</label>
                        <div class="product-categories">
                            {this.product_categories().sort().map((col: string) =>
                                <Badge
                                    type="primary"
                                    additional_classes="mr-05 clickable"
                                    onclick={(e: any) => {
                                        const value = e.target.innerText
                                        this.excise_product_categories.push(value)
                                        const index = this.product_categories().indexOf(value)
                                        this.product_categories().splice(index, 1)
                                    }}
                                >
                                    {col}
                                </Badge>,
                            )}
                        </div>

                        <table class="table search-table product-category-table">
                            <thead class="thead-default">
                                <tr>
                                    <th>Product category</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {this.excise_product_categories.sort().map((category: string, index: number) =>
                                <tbody class="table-row">
                                    <tr>
                                        <td>{capitalize(category)}</td>
                                        <td>
                                            {icon_button('trash', {
                                                class: 'btn-danger float-right',
                                                onclick: (e: Event) => {
                                                    e.preventDefault()
                                                    this.product_categories().push(capitalize(category))
                                                    this.excise_product_categories.splice(index, 1)
                                                },
                                            })}
                                        </td>
                                    </tr>
                                </tbody>,
                            )}
                        </table>
                    </div>
                </div>
            </form>
        </div>
    }
}
