/** llm:tested */
import m from 'mithril'
import {AmountUnit, Button, ButtonGroup, DataCard, Icon, Spinner, CollectionSorter} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {FilterType} from './models'

import {Modal} from '@/components/modal/modal'
import {Pager} from '@/components/pager/pager'
import {Panel} from '@/components/panel'
import table from '@/components/table'
import {pricelist_link} from '@/data/components/pricelist_link'
import utils from '@/_utils'
import {AddToOffer} from '@/market/pricelists/components/add_to_offer'
import {SupplierPriceListItem} from '@/models/suppliers'
import {$m, $s} from '@/app'

interface Attrs {
    supplier_price_list_items: any
    supplier_name: any
    product_name: any
    bottle_filters: any
    sort_collection: any
    count: () => number
    limit: () => number
    fetch_page: any
    filter_type: () => FilterType
    show_specs: boolean
    loading: () => boolean
    unique_name: string
    title: string
    description: string
    collapsible: boolean
    collapsed: boolean
    footer: any
    header: () => string
}

export class ShowSupplierPriceListItems extends MithrilTsxComponent<any> {
    private supplier_price_list_items: any
    private supplier_name: any
    private product_name: any
    private bottle_filters: any
    private custom_offer_spli: any
    private collection: any

    constructor(vnode: m.Vnode<Attrs>) {
        super()
        this.supplier_price_list_items = vnode.attrs.supplier_price_list_items
        this.supplier_name = vnode.attrs.supplier_name
        this.product_name = vnode.attrs.product_name
        this.bottle_filters = vnode.attrs.bottle_filters
        this.custom_offer_spli = window.prop(null)
        this.collection = vnode.attrs.sort_collection
    }

    discover_link(product_name: string | null, volume: number | null, alcohol_percentage: number | null, supplier_name: string | null): string {
        let querystring = ''
        if (product_name) {
            querystring = querystring + `${product_name} `
        }
        if (volume) {
            querystring = querystring + `${(+volume).toFixed(1)} `
        }
        if (alcohol_percentage) {
            querystring = querystring + `${(+alcohol_percentage).toFixed(1)} `
        }
        if (supplier_name) {
            querystring = querystring + `${supplier_name} `
        }
        return `/market/discover?q=${querystring}`
    }

    open_custom_offer_modal(spli: any) {
        // Clone spli so we don't change the original.
        const spli_data = Object.assign({}, spli)

        // Build a spli instance out of the spli_data.
        spli_data.bottle = {
            artkey: spli_data.bottle_artkey,
            product: {
                artkey: spli_data.product_artkey,
                name: spli_data.product_name,
                category: spli_data.product_category,
            },
            alcohol_percentage: spli_data.bottle_alcohol_percentage,
            refill_status: spli_data.bottle_refill_status,
            volume: spli_data.bottle_volume,
        }
        spli_data.supplier_price_list = {
            artkey: spli_data.supplier_price_list_artkey,
            supplier: {
                artkey: spli_data.supplier_artkey,
                name: spli_data.supplier_name,
            },
        }
        spli_data.number_of_bottles_per_case = spli_data.case_number_of_bottles

        this.custom_offer_spli(new SupplierPriceListItem(spli_data))
    }

    close_custom_offer_modal() {
        this.custom_offer_spli(null)
    }

    view(vnode: m.Vnode<Attrs>) {
        if (vnode.attrs.count() > 0) {
            vnode.attrs.toolbar = m(Pager, {
                page_size: vnode.attrs.limit(),
                count: vnode.attrs.count(),
                fetch_page: vnode.attrs.fetch_page,
                display_page_count: false,
            })
        }

        const show_product = !(vnode.attrs.filter_type() in [FilterType.BOTH, FilterType.PRODUCT])
        const show_specs = vnode.attrs.show_specs && !show_product

        let go_to_discover
        if (vnode.attrs.filter_type() === FilterType.BOTH) {
            go_to_discover = [
                m(m.route.Link, {
                    target: '_blank',
                    href: this.discover_link(
                        this.product_name(),
                        this.bottle_filters().volume(),
                        this.bottle_filters().alcohol_percentage(),
                        this.supplier_name(),
                    ),
                }, [
                    m('span.glyphicon.glyphicon-search'),
                    m('span', ` For ${this.supplier_name()}`),
                ]),

                m(m.route.Link, {
                    target: '_blank',
                    href: this.discover_link(
                        this.product_name(),
                        this.bottle_filters().volume(),
                        this.bottle_filters().alcohol_percentage(),
                        null,
                    ),
                }, [
                    m('span.glyphicon.glyphicon-search'),
                    m('span', ' For all suppliers'),
                ]),
            ]
        } else if (vnode.attrs.filter_type() === FilterType.RELATION) {
            go_to_discover = [
                m(m.route.Link, {
                    target: '_blank',
                    href: this.discover_link(
                        null,
                        null,
                        null,
                        this.supplier_name(),
                    ),
                }, [
                    m('span.glyphicon.glyphicon-search'),
                    m('span', ` For ${this.supplier_name()}`),
                ]),
            ]
        } else {
            go_to_discover = m(m.route.Link, {
                target: '_blank',
                href: this.discover_link(
                    this.product_name(),
                    this.bottle_filters().volume(),
                    this.bottle_filters().alcohol_percentage(),
                    null,
                ),
            }, [
                m('span.glyphicon.glyphicon-search'),
                m('span', ' For all suppliers'),
            ])
        }

        return m('.c-market-explore-spli', {key: vnode.attrs.unique_name}, [
            m(Panel, {
                title: vnode.attrs.title,
                description: vnode.attrs.description,
                toolbar: vnode.attrs.toolbar,
                collapsible: vnode.attrs.collapsible,
                collapsed: vnode.attrs.collapsed,
                footer: vnode.attrs.footer,
            }, [
                vnode.attrs.loading() ?
                    m(Spinner) :
                    this.supplier_price_list_items().length ? [
                        m('.c-panel-body-header', [
                            m(DataCard, {
                                model: {
                                    data: [
                                        {label: 'Showing records for', value: vnode.attrs.header()},
                                        {label: 'Go to Discover', value: go_to_discover},
                                    ],
                                },
                                type: 'dense',
                            }),
                            this.collection && this.collection.state && this.collection.state.ready ?
                                m(CollectionSorter, {
                                    collection: this.collection,
                                }) : null,
                        ]),
                        table.table(this.supplier_price_list_items, [
                            {
                                name: 'Relation',
                                function: (record: any) => m('.td-group', [
                                    m('span.header',
                                        record.is_available === false ?
                                            m('s', {
                                                class: 'analysis-bad-color',
                                            }, record.supplier_name + ' ') :
                                            record.supplier_name + ' ',
                                    ),
                                    show_specs ?
                                        m('span.details',
                                            to_specs({
                                                bottle_volume: record.bottle_volume,
                                                bottle_alcohol_percentage: record.bottle_alcohol_percentage,
                                                bottle_refill_status: record.bottle_refill_status,
                                                case_customs_status: record.customs_status,
                                                case_gift_box_type: record.case_gift_box_type,
                                                case_number_of_bottles: record.case_number_of_bottles,
                                                case_tax_label: record.case_tax_label,
                                            }),
                                        ) : null,
                                    record.aux_info ?
                                        m('span.details', record.aux_info) : null,
                                ]),
                                width: 13,
                            },
                            show_product ? {
                                name: 'Product',
                                ellipsis: true,
                                function: (record: any) => record.product_name,
                            } : null,
                            {
                                classes: ['center'],
                                name: 'Cases',
                                function: (spli: any) => spli.number_of_cases ? spli.number_of_cases : '-',
                            },
                            {
                                name: 'Price',
                                function: (spli: any) => {
                                    if (!spli.case_number_of_bottles || !parseInt(spli.price_per_case)) {
                                        return '-'
                                    }

                                    return m(AmountUnit, {
                                        case_amount: +spli.euro_price_per_case,
                                        case_number_of_bottles: spli.case_number_of_bottles,
                                        currency: $s.currencies.default,
                                        excise: +spli.case_excise_nl,
                                        origin: $s.currencies.default !== spli.currency ? {
                                            case_amount: +spli.price_per_case,
                                            currency: spli.currency,
                                        } : undefined,
                                    })
                                },
                            },
                            {
                                name: 'Availability',
                                function: (spli: any) => !spli.availability_status ? '-' : spli.availability_status,
                            },
                            {
                                field: 'incoterm',
                                name: 'Incoterm',
                            },
                            {
                                name: 'Start date',
                                function: (spli: any) => utils.formatDate(spli.supplier_price_list_start_date),
                            },
                            {
                                name: 'End date',
                                function: (spli: any) => utils.formatDate(spli.supplier_price_list_end_date),
                            },
                            {
                                classes: 'actions',
                                function: (spli: any) => m('.actions-group.no-click', [
                                    m('.action-toggles',
                                        spli.supplier_price_list_supplier_price_list_description ?
                                            m(Icon, {
                                                name: 'info',
                                                tip: spli.supplier_price_list_supplier_price_list_description,
                                                type: 'info',
                                            }) : null,
                                    ),
                                    m(ButtonGroup, [
                                        m(Button, {
                                            disabled: spli.supplier_company_type === 'Competitor' || spli.is_available === false,
                                            icon: 'copy',
                                            tip: () => {
                                                if (spli.is_available === false) {
                                                    return 'This pricelist item is not available'
                                                } else if (spli.supplier_company_type === 'Competitor') {
                                                    return 'This is a competitor'
                                                } else {
                                                    return 'Add to custom offer'
                                                }
                                            },
                                            onclick: () => this.open_custom_offer_modal(spli),
                                        }),
                                        m(Button, {
                                            onclick: () => {
                                                const spli_link = '#!' + pricelist_link.from_bottle_json(
                                                    spli.supplier_price_list_artkey,
                                                    {
                                                        volume: spli.bottle_volume,
                                                        alcohol_percentage: spli.bottle_alcohol_percentage,
                                                        product: {name: spli.product_name},
                                                    },
                                                )
                                                window.open(spli_link)
                                            },
                                            icon: 'search',
                                            tip: `Search Pricelist ${spli.supplier_price_list_artkey} for ${spli.product_name}/${spli.bottle_volume}/${spli.bottle_alcohol_percentage}`,
                                            type: 'info',
                                        }),
                                    ]),
                                ]),
                            },
                        ], {
                            search_table_style: false,
                            sticky_header: false,
                            row_class: (spli: any) => $m.data.company_type_class(spli.supplier_company_type),
                        }),
                        this.custom_offer_spli() ?
                            m(Modal, {
                                title: 'Add to custom offer',
                                onclose: () => this.close_custom_offer_modal(),
                            }, m(AddToOffer, {
                                spli: this.custom_offer_spli,
                                done: () => {
                                    this.close_custom_offer_modal()
                                    notifier.notify('Successfully added to custom offer.', 'success')
                                },
                                oncancel: () => this.close_custom_offer_modal(),
                                custom_offer: true,
                            })) : null,
                    ] :
                        vnode.attrs.filter_type() in [FilterType.PRODUCT, FilterType.BOTH] ?
                            [
                                m('span', 'No results.'),
                                m('hr'),
                                m(m.route.Link, {
                                    target: '_blank',
                                    href: this.discover_link(
                                        this.product_name(),
                                        this.bottle_filters().volume(),
                                        this.bottle_filters().alcohol_percentage(),
                                        null,
                                    ),
                                }, [
                                    m('span.glyphicon.glyphicon-search'),
                                    m('span', ' Go to discover to see prices for all suppliers.'),
                                ]),
                            ] :
                            'No results.',
            ]),
        ])
    }
}
