/** llm:tested */
import m from 'mithril'
import {
    compact, filter, sum, map, join, pairsToObj,
    Obj, reject, id,
} from 'prelude-ls'
import {deref} from '@bitstillery/common/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {get_descendant_prop, stopPropagation} from '@/_utils'
import {jsonFormat} from '@/formats'
import inputs from '@/components/inputs'
import dialog from '@/components/dialog'
import tableBase from '@/components/table/base'
import fixedTableHeader from '@/components/table/fixed_header'
import {legacy_user} from '@/models/users'

export interface Column {
    name: string
    default_visible?: boolean
    width?: number
    value?: any
    function?: (record: any) => any
    show_field?: string
    field?: string
    transform?: (value: any) => any
    classes?: string
    ellipsis?: boolean
    header?: string
    sort?: boolean
    sort_function?: Function
    sort_field?: string
    descending?: boolean
    sort_order?: any[]
}

interface TableSettings {
    visible_columns: { [key: string]: boolean }
}

function is_default_visible(column: Column): boolean {
    return column.default_visible !== undefined ? column.default_visible : true
}

class ViewModel {
    options: any
    view_details: any
    row_model: any
    columns: () => Column[]
    items: () => any[]
    columns_visibility: () => { [key: string]: () => boolean }

    constructor(args: any) {
        this.options = args.options || {}
        this.view_details = args.view_details
        this.row_model = args.row_model
        this.columns = () => compact(deref(args.columns))
        this.items = args.items || window.prop([])

        this.columns_visibility = window.prop(this.get_default_columns_visibility())

        const table_settings_json = legacy_user.get_setting(['table-settings', this.options.unique_name])
        if (table_settings_json) {
            try {
                const table_settings = JSON.parse(table_settings_json)
                this.load_table_settings(table_settings)
            } catch {
                // eslint-disable-next-line no-console
                console.error(`User has invalid settings for table ${this.options.unique_name}`)
            }
        }
    }

    load_table_settings(table_settings: TableSettings): void {
        const visible_columns = table_settings.visible_columns
        if (typeof visible_columns === 'object') {
            for (const key in visible_columns) {
                let dyna_prop = this.columns_visibility()[key]
                if (!dyna_prop) {
                    dyna_prop = window.prop(false)
                    this.columns_visibility()[key] = dyna_prop
                }
                dyna_prop(visible_columns[key] === true)
            }
        }
    }

    save_table_settings() {
        const table_settings = {
            visible_columns: Obj.map((x: () => boolean) => x(), this.columns_visibility()),
        }

        const table_settings_json = JSON.stringify(table_settings)
        legacy_user.set_setting(['table-settings', this.options.unique_name], table_settings_json)
    }

    get_default_columns_visibility(): { [key: string]: () => boolean } {
        return pairsToObj(
            map((column: Column) => [column.name, window.prop(is_default_visible(column))],
                this.columns()),
        )
    }

    visible_columns(): Column[] {
        const is_visible = (column: Column) => {
            let dyna_prop = this.columns_visibility()[column.name]
            if (!dyna_prop) {
                dyna_prop = window.prop(is_default_visible(column))
                this.columns_visibility()[column.name] = dyna_prop
            }
            return dyna_prop()
        }

        return filter(is_visible, this.columns())
    }

    total_columns_width(): number {
        return sum(map((col: Column) => col.width || 0, this.visible_columns()))
    }

    autoscaled_columns(): Column[] {
        return tableBase.auto_scale_columns(this.visible_columns())
    }
}

interface TableRowAttrs {
    table: ViewModel
    record: any
}

class TableRow extends MithrilTsxComponent<TableRowAttrs> {
    table: ViewModel
    record: any

    constructor(vnode: m.Vnode) {
        super()
        this.table = vnode.attrs.table
        this.record = {...vnode.attrs.record, details_expanded: window.prop(false)}

        const transform = this.table.row_model || id
        this.record = transform(this.record)
    }

    toggle_expand_details = (e: Event): void => {
        if (($(e.target as HTMLElement).closest('.no-click').length) === 0) {
            this.record.details_expanded(!this.record.details_expanded())
        }
    }

    view(): m.Children {
        const {onclick, row_classes} = this.table.options
        let click_handler

        if (onclick) {
            const original_onclick = onclick
            click_handler = (record: any) => (e: Event) => {
                if (($(e.target as HTMLElement).closest('.no-click').length) === 0) {
                    original_onclick(record)
                }
            }
        }

        let class_names: string[] = []
        if (row_classes) {
            class_names = row_classes(this.record)
        }

        if (this.record.details_expanded()) {
            class_names.push('selected')
        }

        const row_attrs = {
            onclick: onclick
                ? click_handler(this.record)
                : this.table.view_details
                    ? this.toggle_expand_details
                    : undefined,
        }

        const value_for_column = (column: Column) => {
            if (column.value) {
                return column.value
            } else if (column.function) {
                return column.function(this.record)
            } else {
                const field = column.show_field || column.field
                const value = get_descendant_prop(this.record, field)
                return column.transform ? column.transform(value) : value
            }
        }

        return <tbody className={`table-row ${class_names.join(' ')}`}>
            <tr {...row_attrs}>
                {this.table.visible_columns().map((column: Column) => {
                    const props: any = {}
                    let classes = column.classes
                    if (column.ellipsis) {
                        classes = ['ellipsis', classes].join(' ')
                    }
                    if (classes) {
                        props.class = classes
                    }

                    return <td {...props}>
                        {column.ellipsis
                            ? <span>{value_for_column(column)}</span>
                            : value_for_column(column)}
                    </td>
                })}
            </tr>
            {this.record.details_expanded() && this.table.view_details(this.record) &&
                <tr className="well selected">
                    <td colspan="100%">
                        <div className="well-container">
                            {this.table.view_details(this.record)}
                        </div>
                    </td>
                </tr>
            }
        </tbody>
    }
}

interface TableAttrs {
    options: any
    columns: Column[]
    items: any[]
    view_details: any
    row_model: any
    hide_column_selector?: boolean
}

export class Table extends MithrilTsxComponent<TableAttrs> {
    vm: ViewModel

    constructor(vnode: m.Vnode) {
        super()
        this.vm = new ViewModel(vnode.attrs)
    }

    show_column_selector_dialog = (): void => {
        const edit_columns_visibility = Obj.map(
            (prop: () => boolean) => window.prop(prop()),
            this.vm.columns_visibility(),
        )

        dialog.show({
            unique_name: `${this.vm.options.unique_name}_column_selector_dialog`,
            title: 'Select visible columns:',
            body: <div>
                {this.vm.columns().map((column: Column, index: number) =>
                    <div>
                        {inputs.checkbox(
                            edit_columns_visibility[column.name],
                            {disabled: (index === this.vm.columns().length - 1), label: column.name},
                        )}
                    </div>,
                )}
            </div>,
            extra_footer_buttons: [{
                label: 'Reset',
                onclick: () => {
                    legacy_user.remove_setting(['table-settings', this.vm.options.unique_name])
                    window.location.reload(true)
                },
            }],
            onclose: () => {
                for (const name in edit_columns_visibility) {
                    if (this.vm.columns_visibility()[name]() !== edit_columns_visibility[name]()) {
                        window.location.reload(true)
                        break
                    }
                }
            },
            onsubmit: () => {
                for (const name in edit_columns_visibility) {
                    this.vm.columns_visibility()[name](edit_columns_visibility[name]())
                }
                this.vm.save_table_settings()
            },
        })
    }

    get_table_wrapper(): Function {
        if (this.vm.options.sticky_header) {
            return this.vm.options.with_buttons
                ? fixedTableHeader.with_buttons
                : fixedTableHeader.without_buttons
        }
        return id
    }

    get_table_classes(): string[] {
        return ['c-table', 'table'].concat(
            !this.vm.options.search_table_style || this.vm.options.search_table_style
                ? ['search-table', 'clickable']
                : ['table-hover'],
        )
    }

    view_column_header(column: Column): string | m.Children {
        return column.header !== undefined ? column.header : column.name
    }

    get_body_items(): any[] {
        return this.vm.items()
    }

    // eslint-disable-next-line no-unused-vars
    sort_column(field: string = '', ascending = true, sort_order: any[] = []): void {
        // eslint-disable-next-line no-console
        console.error('sort not implemented')
    }

    view_footer(): m.Children {
        return null
    }

    view(vnode: m.Vnode<TableAttrs>): m.Children {
        const wrapper = this.get_table_wrapper()
        const classes = join(' ', this.get_table_classes())

        const table_attrs = {
            class: classes,
        }

        return <div className="c-collection-table table-container">

            {wrapper(
                <table {...table_attrs}>
                    {this.vm.options.autoscale
                        ? tableBase.colgroup(this.vm.autoscaled_columns())
                        : tableBase.colgroup(this.vm.visible_columns())}
                    {tableBase.header(this.vm.visible_columns(), (cell: any, column: Column, index: number) => {
                        const attrs = {
                            class: column.classes
                                ? reject((x: string) => x === 'ellipsis', column.classes)
                                : '',
                            onclick: column.sort_function || column.sort
                                ? column.sort_function
                                    ? column.sort_function
                                    : this.sort_column.bind(
                                        this,
                                        column.sort_field || column.field,
                                        {ascending: !column.descending},
                                        column.sort_order || [],
                                    )
                                : undefined,
                        }

                        return cell(
                            attrs,
                            [
                                this.view_column_header(column),
                                !vnode.attrs.hide_column_selector && index === this.vm.visible_columns().length - 1 &&
                                    <span
                                        className="glyphicon glyphicon-th-list fa-fw"
                                        onclick={stopPropagation(this.show_column_selector_dialog)}
                                    />
                                ,
                            ],
                        )
                    })}
                    {this.get_body_items().map((record: any) =>
                        <TableRow
                            key={jsonFormat.serialize(record)}
                            table={this.vm}
                            record={record}
                        />,
                    )}
                    {this.view_footer()}
                </table>,
            )}
        </div>
    }
}
