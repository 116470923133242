import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Amount, Button, ButtonGroup, FieldCheckbox, FieldMoney, Spinner} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy.ts'
import {$s} from '@bitstillery/common/app.ts'
import {format_money, format_percentage} from '@bitstillery/common/ts_utils.ts'
import {classes, merge_deep} from '@bitstillery/common/lib/utils.ts'
import {api} from '@bitstillery/common/app'

import {
    CostType,
    GetPurchaseOrderCostResponse,
    UpdatePurchaseOrderCostsRequest,
} from '@/factserver_api/fact2server_api.ts'
import {PercentInput} from '@/components/decimal_input.tsx'

export interface CostsLineAttrs {
    description?: string
    label: string
    amount: Element
    classes: any
}

export class CostsLine extends MithrilTsxComponent<CostsLineAttrs> {

    view(vnode: m.Vnode<CostsLineAttrs>): m.Children {
        if (!vnode.attrs.amount && !vnode.attrs.description) {
            return
        }
        return <tr>
            <td className={classes('cost-label', vnode.attrs.classes)}>{vnode.attrs.label}</td>
            <td className={classes('description')}>{vnode.attrs.description}</td>
            <td className={classes('amount', vnode.attrs.classes)}>{vnode.attrs.amount}</td>
        </tr>
    }
}

export interface PurchaseOrderCostsAttrs {
    purchase_order_artkey: number | null
}

export class PurchaseOrderCosts extends MithrilTsxComponent<PurchaseOrderCostsAttrs> {

    currency = 'EUR'

    data = proxy({
        purchase_order_costs: null as GetPurchaseOrderCostResponse | null,
        update_request: {
            loendersloot_costs: {},
        } as UpdatePurchaseOrderCostsRequest,
    })

    async oncreate(vnode: m.Vnode<PurchaseOrderCostsAttrs>) {
        await this.fetch_costs(vnode)
    }

    async fetch_costs(vnode: m.Vnode<PurchaseOrderCostsAttrs>) {
        const {
            result,
            status_code,
        } = await api.get<GetPurchaseOrderCostResponse>(`discover/purchase-orders/${vnode.attrs.purchase_order_artkey}/costs`)
        if (status_code > 299) {
            return
        }
        this.data.purchase_order_costs = result
        const calculation_details = result.calculation_details
        this.data.update_request.diesel_surcharge_percentage = calculation_details.diesel_surcharge_percentage
        this.data.update_request.other_costs = this.data.purchase_order_costs.other_costs
        this.data.update_request.transport_costs = this.data.purchase_order_costs.transport_costs
        this.data.update_request.pallet_costs = this.data.purchase_order_costs.pallet_costs
        this.data.update_request.warehouse_base_costs = this.data.purchase_order_costs.warehouse_base_costs
        this.data.update_request.warehouse_costs_per_case = calculation_details.warehouse_costs_per_case
        this.data.update_request.waste_fund_costs = this.data.purchase_order_costs.waste_fund_costs
        merge_deep(this.data.update_request.loendersloot_costs, calculation_details)
    }

    async switch_to_loendersloot_costs(vnode: m.Vnode<PurchaseOrderCostsAttrs>) {
        await api.post(`discover/purchase-orders/${vnode.attrs.purchase_order_artkey}/costs`, {
            cost_type: CostType.LOENDERSLOOT,
        }, true)
        await this.fetch_costs(vnode)
    }

    async switch_to_warehouse_base_costs(vnode: m.Vnode<PurchaseOrderCostsAttrs>) {
        await api.post(`discover/purchase-orders/${vnode.attrs.purchase_order_artkey}/costs`, {
            cost_type: CostType.WAREHOUSE_BASE_COSTS,
        }, true)
        await this.fetch_costs(vnode)
    }

    async update_costs(vnode: m.Vnode<PurchaseOrderCostsAttrs>) {
        if (!this.data.purchase_order_costs?.calculation_details.is_loendersloot_calculation) {
            this.data.update_request.loendersloot_costs = null
        }
        const {status_code} = await api.put(`discover/purchase-orders/${vnode.attrs.purchase_order_artkey}/costs`, this.data.update_request)
        if (status_code >= 200) {
            await this.fetch_costs(vnode)
        }
    }

    view(vnode: m.Vnode<PurchaseOrderCostsAttrs>): m.Children {
        const decimal_locale = $s.identity.user.decimal_locale

        if (!this.data.purchase_order_costs) {
            return <Spinner/>
        }
        const update_request = this.data.update_request
        const calculation_details = this.data.purchase_order_costs?.calculation_details
        const costs = this.data.purchase_order_costs
        const is_exw = this.data.purchase_order_costs.calculation_details.incoterm === 'EXW'
        const target_is_loendersloot = this.data.purchase_order_costs.calculation_details.target_is_loendersloot
        const is_loendersloot_calculation = this.data.purchase_order_costs.calculation_details.is_loendersloot_calculation

        return <div className={'c-purchase-order-costs'}>

            <div className={'columns'}>
                <div className={'column'}>
                    <div className={'cost-form'}>
                        <div className={'cost-label'}>Pallet costs</div>
                        <div className={'cost-value'}>
                            <FieldMoney
                                currency={[this, 'currency']}
                                model={[update_request, 'pallet_costs']}
                            />
                        </div>
                        <div className={'cost-label'}>Transport costs</div>
                        <div className={'cost-value'}>
                            <FieldMoney
                                currency={[this, 'currency']}
                                model={[update_request, 'transport_costs']}
                            />
                        </div>
                        <div className={'cost-label'}>Diesel surcharge percentage</div>
                        <div className={'cost-value'}>
                            <PercentInput
                                value={update_request.diesel_surcharge_percentage}
                                on_value={(value) => update_request.diesel_surcharge_percentage = value}
                            />
                        </div>
                        {calculation_details.has_waste_fund_costs_nl && [
                            <div className={'cost-label'}>Wast fund (NL)</div>,
                            <div className={'cost-value'}>
                                <FieldMoney
                                    currency={[this, 'currency']}
                                    model={[update_request, 'waste_fund_costs']}
                                />
                            </div>,
                        ]}
                        <div className={'cost-label'}>Other costs</div>
                        <div className={'cost-value'}>
                            <FieldMoney
                                currency={[this, 'currency']}
                                model={[update_request, 'other_costs']}
                            />
                        </div>
                        {!is_loendersloot_calculation && [
                            <div className={'cost-label'}>Warehouse base costs</div>,
                            <div className={'cost-value'}>
                                <FieldMoney
                                    currency={[this, 'currency']}
                                    model={[update_request, 'warehouse_base_costs']}
                                />
                            </div>,
                            <div className={'cost-label'}>Warehouse costs per case</div>,
                            <div className={'cost-value'}>
                                <FieldMoney
                                    currency={[this, 'currency']}
                                    model={[this.data.update_request, 'warehouse_costs_per_case']}
                                />
                            </div>,
                        ]}
                        {is_loendersloot_calculation && !is_exw && [
                            <div className={'cost-value'}>
                                <FieldCheckbox
                                    label="Use duty drawback"
                                    model={[update_request.loendersloot_costs, 'has_duty_drawback']}
                                />
                            </div>,
                        ]}
                        <div className={'cost-label'}>
                            <ButtonGroup>
                                {target_is_loendersloot && (
                                    <Button
                                        icon="warehouse"
                                        onclick={
                                            async() => {
                                                is_loendersloot_calculation ?
                                                    await this.switch_to_warehouse_base_costs(vnode) :
                                                    await this.switch_to_loendersloot_costs(vnode)
                                            }}
                                        text={`Use ${is_loendersloot_calculation ? 'warehouse' : 'Loendersloot'} costs`}
                                    />
                                )}
                                <Button
                                    icon="save"
                                    onclick={async() => await this.update_costs(vnode)}
                                    text={'Update costs'}
                                    type="success"
                                />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>

                <div className={'column'}>
                    <table>
                        <tbody>
                            <CostsLine label={'Incoterm'} description={calculation_details.incoterm}/>
                            <CostsLine label={'Number of cases'} description={calculation_details.number_of_cases}/>
                            <CostsLine label={'Number of lines'} description={calculation_details.number_of_lines}/>
                            <CostsLine label={'Was bought for'}
                                description={format_money(+calculation_details.euro_was_bought_for, 'EUR', decimal_locale)}/>
                            <CostsLine
                                label={'Loendersloot calculation'}
                                description={calculation_details.is_loendersloot_calculation ? 'YES' : 'No'}/>

                            {!is_loendersloot_calculation && [
                                <CostsLine label={'Warehouse base costs'}
                                    amount={costs.warehouse_base_costs}
                                    classes={'first-row'}/>,
                                <CostsLine label={'Additional costs'}
                                    description={[
                                        `${calculation_details.number_of_cases} * `,
                                        <Amount amount={calculation_details?.warehouse_costs_per_case}
                                            currency={'EUR'}/>,
                                    ]}
                                    amount={costs.additional_costs}/>,
                            ]}
                            {is_loendersloot_calculation && !is_exw && [
                                <CostsLine label={'Additional costs'}
                                    description={[
                                        `${calculation_details.number_of_cases}cs * `,
                                        <Amount amount={calculation_details?.additional_per_case} currency={'EUR'}/>,
                                    ]}
                                    amount={costs.additional_costs}/>,
                                <CostsLine label={'Inspection costs'}
                                    description={[
                                        <Amount amount={calculation_details?.inspection_costs_first_item}
                                            currency={'EUR'}/>,
                                        ` + ${calculation_details.number_of_lines - 1} lines * `,
                                        <Amount amount={calculation_details?.inspection_costs_per_item}
                                            currency={'EUR'}/>,
                                    ]}
                                    amount={costs.inspection_costs}/>,
                            ]}
                            {costs.spirits_costs && is_loendersloot_calculation && !is_exw && <CostsLine label={'Spirits costs'}
                                description={[
                                    `${calculation_details.number_of_cases}cs * `,
                                    <Amount amount={calculation_details?.spirits_costs_per_case}
                                        currency={'EUR'}/>,
                                ]
                                }
                                amount={costs.spirits_costs}/>
                            }
                            {is_loendersloot_calculation && !is_exw &&
                                <CostsLine label={'Non spirits costs'} amount={costs.non_spirits_costs}/>}
                            {costs.duty_drawback && <CostsLine
                                label={'Duty drawback'}
                                description={[
                                    <Amount amount={calculation_details?.duty_drawback}
                                        currency={'EUR'}/>,
                                    ` + ${calculation_details?.number_of_cases}cs * `,
                                    <Amount amount={calculation_details?.duty_drawback_per_case}
                                        currency={'EUR'}/>,
                                ]}
                                amount={costs.duty_drawback}/>}
                            {is_loendersloot_calculation && is_exw && [
                                <CostsLine label={'Additional costs'}
                                    description={[
                                        `${calculation_details.number_of_cases}cs * `,
                                        <Amount amount={calculation_details?.additional_per_case} currency={'EUR'}/>,
                                    ]}
                                    amount={costs.additional_costs}/>,
                                <CostsLine label={'Handover costs'} amount={costs.handover_costs}/>,
                            ]}
                            <CostsLine label={'Pallet costs'} amount={costs.pallet_costs}/>
                            <CostsLine label={'Transport costs'} amount={costs.transport_costs}/>
                            <CostsLine
                                label={'Diesel surcharge'}
                                description={[
                                    `${format_percentage(+calculation_details.diesel_surcharge_percentage / 100, decimal_locale)} * `,
                                    <Amount amount={costs.transport_costs} currency={'EUR'}/>,
                                ]}
                                amount={costs.diesel_surcharge_amount}
                            />
                            <CostsLine label={'Waste fund costs (NL)'} amount={costs.waste_fund_costs}/>
                            <CostsLine label={'Other costs'} amount={costs.other_costs} classes={'last-amount'}/>
                            <CostsLine label={'Total'}
                                amount={format_money(+costs.total_costs, 'EUR', decimal_locale)}/>
                            <CostsLine label={'Total costs'}
                                description={format_money(+costs.total_costs, 'EUR', decimal_locale)}/>
                            <CostsLine label={'Total costs per case'}
                                description={format_money(+costs.total_costs_per_case, 'EUR', decimal_locale)}/>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    }
}
