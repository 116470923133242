import {Observable} from 'rxjs'

import {Api, FactserverEmptyResponse} from './api'
import {GetOTPSecretResponse, GetUserDownloadLinkResponse} from './fact2server_api'

export interface User {
    artkey: number
    name: string
    trading_countries: TradingCountry[]
    user_accounts: UserAccount[]
    user_logs: UserLog[]
    is_trader: boolean
    is_purchaser: boolean
    is_crm_manager: boolean
    is_stock_manager: boolean
    is_superuser: boolean
    is_deleted: boolean
    is_system_account: boolean
    is_compensable: boolean
    get_bank_mail_msi: boolean
    get_bank_mail_a2bc: boolean
    decimal_locale: string
    profile: Profile

    schedule_monday: string | null
    schedule_tuesday: string | null
    schedule_wednesday: string | null
    schedule_thursday: string | null
    schedule_friday: string | null

    trading_countries_string?: string
    last_log_today?: string

    daily_travel_distance: number
    otp_validated: boolean
}

export interface Profile {
    name: string
    emailaddress: string
    a2bc_emailaddress: string | null
    address: string
    telephone_number: string
    gitlab_username: string | null
    mobile_telephone_number: string
}

export interface TradingCountry {
    artkey: number
    country_code: string
}

export interface UserAccount {
    account: { slug: string }
    account_artkey: number
}

export interface UserLog {
    user_artkey: number
    status_log: string
    timestamp: string
}

export interface OTPVerifyResp {
    token: string
}

export interface UpdateUserRequest extends Pick<User, 'decimal_locale'> {
    artkey?: number
    name: string
    is_deleted: boolean
    is_trader: boolean
    is_purchaser: boolean
    is_crm_manager: boolean
    is_stock_manager: boolean
    is_superuser: boolean
    is_compensable: boolean
    get_bank_mail_msi: boolean
    get_bank_mail_a2bc: boolean
    trading_countries: string[]
    user_accounts: string[]
    profile: Profile
    schedule_monday: string | null
    schedule_tuesday: string | null
    schedule_wednesday: string | null
    schedule_thursday: string | null
    schedule_friday: string | null
    daily_travel_distance: number
    otp_validated: boolean
}

export interface ScheduleRangeRequest {
    user_artkey: number
    start_date: string
    end_date?: string | null
}

export interface DeleteScheduledRangeRequest {
    artkey: number
    user_artkey: number
}

export interface UpdateWeekRequest {
    user_artkey: number
    schedule_monday: string | null
    schedule_tuesday: string | null
    schedule_wednesday: string | null
    schedule_thursday: string | null
    schedule_friday: string | null
}

export interface UpdatedWeekResponse {
    artkey: number
}

export interface StatusLogResponse {
    artkey: number
    status: string
}

export interface ManualStatusLogRequest {
    user_artkey: number
    status: string
}

export interface UpdateCreateStatusLogRequest {
    artkey?: number
    user_artkey: number
    status: string
    date: string
}

export interface GetStatusLogsResponse {
    status_logs: { [user_artkey: number]: { [date: string]: { status: string; artkey: number} } }
}

export interface StandardUserSchedulesResponse {
    user_schedules: { [user_artkey: number]: { name: string; date_settings: StandardUserWeek } }
}

export interface StandardUserWeek {
    schedule_monday: string | null
    schedule_tuesday: string | null
    schedule_wednesday: string | null
    schedule_thursday: string | null
    schedule_friday: string | null
}

export interface UserStatusTodayResponse {
    status: string
    standard_schedule: boolean
}

export interface ScheduledRange {
    artkey: number
    start_date: string
    end_date: string
}

export interface RangeRequest {
    start_date: string
    end_date: string
}

export interface GetStatusLogsRequest {
    start_date: string
    end_date: string
}

export interface GetRangeResponse {
    ranges: { user_artkey: number; start_date: string; end_date: string }[]
}

export interface ScheduledRangesResponse {
    ranges: ScheduledRange[]
}

export interface ChangePasswordRequest {
    current_password: string
    new_password: string
}

export interface DownloadUserSchedulesExtractResponse {
    file_base64_encoded: string
}

export interface GetDutchHolidaysResponse {
    holidays: {
        [date: string]: string
    }
}

export const user_schedule_options = {
    OFF: {title: 'Off'},
    HOME: {title: 'Home'},
    OFFICE: {title: 'Office'},
    SICK: {title: 'Sick'},
    VACATION: {title: 'Vacation'},
    ABROAD: {title: 'Abroad'},
}

export type UserResponse = User

export class UserManagementApi {
    api = new Api()

    request_change_password_email(portal_user_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_fact2server_request(`discover/portal-user/${portal_user_artkey}/start-reset-password`, {})
    }

    get_user(user_artkey: number): Observable<UserResponse> {
        return this.api.post_request('users.get_user', {
            artkey: user_artkey,
        })
    }

    update_or_create_user(update_user_request: UpdateUserRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('users.update_or_create', {
            ...update_user_request,
        })
    }

    change_password(change_password_request: ChangePasswordRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('auth.change_password', {
            ...change_password_request,
        })
    }

    delete_user(artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_request('users.delete', {
            artkey: artkey,
        })
    }

    // =================================================OTP processes=================================================//
    fetch_otp_secret_url(pre_auth_token: string): Observable<GetOTPSecretResponse> {
        return this.api.post_fact2server_request('discover/data/users/otp-secret-url', {
            pre_auth_token: pre_auth_token,
        })
    }

    verify_otp(otp: string, pre_auth_token: string): Observable<OTPVerifyResp> {
        return this.api.post_fact2server_request('discover/data/users/otp', {
            otp: otp,
            pre_auth_token: pre_auth_token,
        })
    }

    reset_user_otp(user_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.post_fact2server_request('discover/data/users/reset-user-otp', {
            user_artkey: user_artkey,
        })
    }

    // ===========================================User-schedules processes===========================================//

    get_standard_user_schedules(): Observable<StandardUserSchedulesResponse> {
        return this.api.post_request('user_schedules.get_standard_user_schedules', {})
    }

    get_schedule_status_logs_range(get_status_logs_request: GetStatusLogsRequest): Observable<GetStatusLogsResponse> {
        return this.api.post_request('user_schedules.get_schedule_status_logs_range', {
            ...get_status_logs_request,
        })
    }

    get_vacations_in_range(get_vacations_range_request: RangeRequest): Observable<GetRangeResponse> {
        return this.api.post_request('user_schedules.get_vacations_range', {
            ...get_vacations_range_request,
        })
    }

    get_business_trips_in_range(get_trips_range_request: RangeRequest): Observable<GetRangeResponse> {
        return this.api.post_request('user_schedules.get_business_trips_range', {
            ...get_trips_range_request,
        })
    }

    get_user_status_today(user_artkey: number): Observable<UserStatusTodayResponse> {
        return this.api.post_request('user_schedules.get_user_status_today', {user_artkey: user_artkey})
    }

    get_scheduled_vacations(user_artkey: number): Observable<ScheduledRangesResponse> {
        return this.api.post_request('user_schedules.get_scheduled_vacations', {user_artkey: user_artkey})
    }

    get_scheduled_business_trips(user_artkey: number): Observable<ScheduledRangesResponse> {
        return this.api.post_request('user_schedules.get_scheduled_business_trips', {user_artkey: user_artkey})
    }

    update_standard_week_schedule(update_week_request: UpdateWeekRequest): Observable<UpdatedWeekResponse> {
        return this.api.post_request('user_schedules.update_standard_user_schedule', {
            ...update_week_request,
        })
    }

    update_or_create_status_log(
        update_create_status_request: UpdateCreateStatusLogRequest,
    ): Observable<FactserverEmptyResponse> {
        return this.api.post_request('user_schedules.update_or_create_status_log', {
            ...update_create_status_request,
        })
    }

    manual_schedule_status_log(manual_status_log: ManualStatusLogRequest): Observable<StatusLogResponse> {
        return this.api.post_request('user_schedules.manual_schedule_status_log', {
            ...manual_status_log,
        })
    }

    schedule_vacation(schedule_vacation_request: ScheduleRangeRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('user_schedules.schedule_vacation', {
            ...schedule_vacation_request,
        })
    }

    schedule_business_trip(schedule_trip_request: ScheduleRangeRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('user_schedules.schedule_business_trip', {
            ...schedule_trip_request,
        })
    }

    delete_scheduled_vacation(delete_vacation_req: DeleteScheduledRangeRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('user_schedules.delete_scheduled_vacation', {
            ...delete_vacation_req,
        })
    }

    delete_scheduled_business_trip(delete_trip_req: DeleteScheduledRangeRequest): Observable<FactserverEmptyResponse> {
        return this.api.post_request('user_schedules.delete_scheduled_business_trip', {
            ...delete_trip_req,
        })
    }

    download_user_schedules_extract(extract_range: RangeRequest): Observable<DownloadUserSchedulesExtractResponse> {
        return this.api.post_request('user_schedules.download_user_schedules_extract', {
            ...extract_range,
        })
    }

    get_dutch_holidays(date: string): Observable<GetDutchHolidaysResponse> {
        return this.api.post_request('user_schedules.get_dutch_holidays', {date: date})
    }

    delete_download(user_download_artkey: number): Observable<FactserverEmptyResponse> {
        return this.api.delete(`discover/users/downloads/${user_download_artkey}`)
    }

    request_download_link(user_download_artkey: number): Observable<GetUserDownloadLinkResponse> {
        return this.api.get(`discover/users/downloads/${user_download_artkey}/download-link`)
    }
}
