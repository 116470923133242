/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {Collection} from '@/components/collection/collection'
import {EmailTable} from '@/email/components/email_table'

interface SalesOrder {
    artkey(): string
}

interface Vnode {
    attrs: {
        sales_order: () => SalesOrder
    }
}

export class SalesOrderEmailCorrespondence extends MithrilTsxComponent<unknown> {
    sales_order: () => SalesOrder
    artkey: () => string
    emails: Collection

    constructor(vnode: Vnode) {
        super()
        this.sales_order = vnode.attrs.sales_order
        this.artkey = window.prop(this.sales_order().artkey())
        this.emails = new Collection({
            api_function_name: 'email.email.get',
            additional_params: () => ({sales_order_artkey: this.artkey()}),
            query_limit: 25,
            default_sort_by: 'created_on',
            default_sort_order: 'desc',
        })
        this.emails.query()
    }

    oncreate() {
        this.emails.requery()
        m.redraw()
    }

    view() {
        return <EmailTable email_collection={this.emails} />
    }
}
