/** llm:tested */
import m from 'mithril'
import {orList} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericList} from '@/data/generic/generic_list'

interface Ledger {
    ledger_code: string
    ledger_type: string
    description: string
}

export class LedgerList extends MithrilTsxComponent {
    is_match(ledger: Ledger, term: string): boolean {
        return orList([
            ledger.ledger_code.toLowerCase().indexOf(term) > -1,
        ])
    }

    view(_vnode: m.Vnode<any>) {
        return <GenericList
            readable_entity_name="ledger"
            base_url="/data/ledgers/"
            list_api_call="ledgers.get_all"
            is_match={this.is_match}
            columns={[
                {key: 'ledger_type', label: 'Type'},
                {key: 'description', label: 'Description'},
                {key: 'ledger_code', label: 'Ledger code'},
            ]}
            requery_subscribe_key="ledgers_updated"
        />
    }
}
