/** llm:tested */
import {Account} from '@/models/accounts'
import {Delivery, InsuranceType, Warehouse} from '@/models/data'
import {Case, Item} from '@/models/stock'
import {OfferItem} from '@/models/pricelists'
import {User} from '@/models/users'
import {Supplier} from '@/models/suppliers'
import {convert_from_currency_to_euro} from '@/factserver_api/currencies'
import {$m} from '@/app'

export class ItemTypes {
    static PURCHASE_ORDER_ITEM = 'purchase_order_item'
    static ITEM = 'item'
}

export class PortalOrderStatus {
    static PENDING = 'Pending'
    static FINALIZED = 'Finalized'
}

export class SalesOrderStatus {
    static SAVED = 'Saved'
    static CONFIRMED = 'Confirmed'
    static SAVED_OR_CONFIRMED = 'Saved or confirmed'
    static INVOICED = 'Invoiced'
    static CANCELLED = 'Cancelled'

    static all = [this.SAVED, this.CONFIRMED, this.SAVED_OR_CONFIRMED, this.INVOICED, this.CANCELLED]
    static choices: { [key: string]: string } = {}
}

// Initialize choices
for (const status of SalesOrderStatus.all) {
    SalesOrderStatus.choices[status] = status
}

export class AdditionalType {
    static REVENUE = 'revenue'
    static DISCOUNT = 'discount'
    static VOUCHER = 'voucher'
    static TRANSPORT = 'transport'
    static WASTE_FUND = 'waste_fund'
    static PALLET = 'pallet'
    static OTHER = 'other'

    static CHOICES: { [key: string]: string } = {
        [this.REVENUE]: 'Revenue',
        [this.DISCOUNT]: 'Discount',
        [this.VOUCHER]: 'Voucher',
        [this.TRANSPORT]: 'Transport',
        [this.WASTE_FUND]: 'Waste fund (afvalfonds)',
        [this.PALLET]: 'Pallet costs',
        [this.OTHER]: 'Other',
    }
}

export class DestinationType {
    static WAREHOUSE = 'warehouse'
    static SEAPORT = 'seaport'
    static AIRPORT = 'airport'
    static OTHER = 'other'
}

export class RevenueType {
    static DIRECT_SALES = 'direct sales'
    static TO_RESELLERS = 'to resellers'
}

export class SalesOrder {
    artkey: any
    account: any
    account_artkey: any
    supplier: any
    supplier_artkey: any
    date: any
    created_on: any
    incoterm: any
    incoterm_location: any
    destination: any
    origin: any
    destination_location: any
    reference: any
    was_handled_by_artkey: any
    was_handled_by: any
    was_sold_in: any
    was_sold_for: any
    euro_was_sold_for: any
    euro_turnover: any
    sales_order_status: any
    sales_order_items: any
    sales_order_additionals: any
    sales_order_credit_items: any
    sales_order_tbo_items: any
    basket_items: any
    sold_against_rate: any
    number_of_cases: any
    insurance_type_artkey: any
    insurance_type: any
    was_sent_to_financial_information_system_on: any
    is_invoiced_on: any
    invoice_number: any
    remark: any
    includes_excise: any
    requested_delivery_date: any
    warehouse_instruction: any
    freight_instruction: any
    delivery: any
    euro_purchasing_value: any
    margin: any
    margin_percentage: any
    is_complete: any
    from_portal: any
    portal_status: any
    combined_status: any
    has_attachments: any
    estimated_loading_date: any
    sent_to_loendersloot_on: any

    constructor(json?: any) {
        this.artkey = window.prop('')
        this.account = window.prop(new Account())
        this.account_artkey = window.prop('')
        this.supplier = window.prop(new Supplier())
        this.supplier_artkey = window.prop('')
        this.date = window.prop('')
        this.created_on = window.prop('')
        this.incoterm = window.prop('')
        this.incoterm_location = window.prop('')
        this.destination = window.prop(new Warehouse())
        this.origin = window.prop(new Warehouse())
        this.destination_location = window.prop('')
        this.reference = window.prop('')
        this.was_handled_by_artkey = window.prop('')
        this.was_handled_by = window.prop(new User())
        this.was_sold_in = window.prop('')
        this.was_sold_for = window.prop('')
        this.euro_was_sold_for = window.prop('')
        this.euro_turnover = window.prop('')
        this.sales_order_status = window.prop('')
        this.sales_order_items = window.prop([])
        this.sales_order_additionals = window.prop([])
        this.sales_order_credit_items = window.prop([])
        this.sales_order_tbo_items = window.prop([])
        this.basket_items = window.prop([])
        this.sold_against_rate = window.prop('')
        this.number_of_cases = window.prop('')
        this.insurance_type_artkey = window.prop('')
        this.insurance_type = window.prop(new InsuranceType())
        this.was_sent_to_financial_information_system_on = window.prop('')
        this.is_invoiced_on = window.prop('')
        this.invoice_number = window.prop('')
        this.remark = window.prop('')
        this.includes_excise = window.prop(false)
        this.requested_delivery_date = window.prop('')
        this.warehouse_instruction = window.prop('')
        this.freight_instruction = window.prop('')
        this.delivery = window.prop(new Delivery())
        this.euro_purchasing_value = window.prop('')
        this.margin = window.prop('')
        this.margin_percentage = window.prop('')
        this.is_complete = window.prop(false)
        this.from_portal = window.prop(false)
        this.portal_status = window.prop('')
        this.combined_status = window.prop('')
        this.has_attachments = window.prop(false)
        this.estimated_loading_date = window.prop('')
        this.sent_to_loendersloot_on = window.prop('')

        if (json) {
            for (const prop in json) {
                if (prop === 'invoice_number') {
                    // Invoice number 12663 has been given out twice, to both sales order
                    // S25144 and to sales order S25303. We postfix one with an A, and
                    // the other with a B.
                    if (json['artkey'] === 25144) {
                        this[prop](this[prop]() + 'A')
                    } else if (json['artkey'] === 25303) {
                        this[prop](this[prop]() + 'B')
                    }
                } else if (prop === 'supplier') {
                    this.supplier(new Supplier(json[prop]))
                    this.supplier_artkey(json[prop].artkey)
                } else if (prop === 'account') {
                    this.account_artkey(json[prop].artkey)
                    this.account(new Account(json[prop]))
                } else if (prop === 'was_handled_by') {
                    this.was_handled_by($m.users.create_user(json[prop]))
                    this.was_handled_by_artkey(this.was_handled_by().artkey())
                } else if (prop === 'delivery') {
                    this.delivery(new Delivery(json[prop]))
                } else if (prop === 'destination') {
                    this.destination($m.data.warehouses.create_warehouse(json[prop]))
                } else if (prop === 'origin') {
                    this.origin($m.data.warehouses.create_warehouse(json[prop]))
                } else if (prop === 'sales_order_additionals') {
                    this.sales_order_additionals(json[prop].map((additional: any) => new SalesOrderAdditional(additional)))
                } else if (prop === 'sales_order_credit_items') {
                    this.sales_order_credit_items(json[prop].map((credit_item: any) => new SalesOrderCreditItem(credit_item, this)))
                } else if (prop === 'sales_order_tbo_items') {
                    this.sales_order_tbo_items(json[prop].map((tbo_item: any) => new SalesOrderTBOItem(tbo_item, this)))
                } else if (prop === 'basket_items') {
                    this.basket_items(json[prop].map((basket_item: any) => new SalesOrderBasketItem(basket_item, this)))
                } else if (prop === 'insurance_type') {
                    this.insurance_type = window.prop(new InsuranceType(json[prop]))
                } else {
                    this[prop] = window.prop(json[prop])
                }
            }
        }
    }

    credit_items_in_stock() {
        return this.sales_order_credit_items().every((credit_item: any) => credit_item.item().lot() && credit_item.item().lot() !== '')
    }
}

export class SalesOrderItem {
    artkey: any
    line_number: any
    price_per_case: any
    price_per_case_excl_excise: any
    excise_per_case: any
    number_of_cases: any
    item_artkey: any
    item: any
    description: any
    portal_comment: any
    created_from_portal_timestamp: any
    sales_order: any
    sales_order_artkey: any
    credited_in_sales_orders: any
    was_last_updated_by_discover_user: any
    was_last_updated_by_portal_user: any

    constructor(json: any, sales_order?: any) {
        this.artkey = window.prop('')
        this.line_number = window.prop('')
        this.price_per_case = window.prop('')
        this.price_per_case_excl_excise = window.prop('')
        this.excise_per_case = window.prop('')
        this.number_of_cases = window.prop('')
        this.item_artkey = window.prop('')
        this.item = window.prop(new Item())
        this.description = window.prop('')
        this.portal_comment = window.prop('')
        this.created_from_portal_timestamp = window.prop('')
        this.sales_order = window.prop(new SalesOrder())
        this.sales_order_artkey = window.prop('')
        this.credited_in_sales_orders = window.prop([])
        this.was_last_updated_by_discover_user = window.prop({})
        this.was_last_updated_by_portal_user = window.prop({})

        for (const prop in json) {
            if (prop === 'item' && json[prop]) {
                this.item(new Item(json[prop]))
                this.item_artkey(this.item().artkey())
            } else if (prop === 'sales_order' && json[prop]) {
                this.sales_order(new SalesOrder(json[prop]))
            } else if (prop === 'was_last_updated_by_discover_user') {
                this.was_last_updated_by_discover_user($m.users.create_user(json[prop]))
            } else if (prop === 'was_last_updated_by_portal_user') {
                this.was_last_updated_by_portal_user($m.users.create_user(json[prop]))
            } else if (prop === 'credited_in_sales_orders') {
                // If the item has is not credited, this property is null.
                // Correct that by defaulting to an empty list.
                this.credited_in_sales_orders(json[prop] || [])
            } else {
                this[prop] = window.prop(json[prop])
            }
        }

        if (sales_order) {
            this.sales_order(sales_order)
            this.sales_order_artkey(this.sales_order().artkey())
        }
    }

    euro_was_bought_for() {
        return this.item().euro_was_bought_for()
    }

    dynamic_euro_was_sold_for() {
        return convert_from_currency_to_euro(
            this.price_per_case() - this.excise_per_case(),
            this.sales_order().was_sold_in(),
            this.sales_order().sold_against_rate(),
        )
    }

    total_value() {
        return this.price_per_case() * this.number_of_cases()
    }

    total_excl_excise() {
        return this.number_of_cases() * this.price_per_case_excl_excise()
    }

    margin_per_case() {
        return this.dynamic_euro_was_sold_for() - this.euro_was_bought_for()
    }

    total_margin() {
        return this.margin_per_case() * this.number_of_cases()
    }

    margin_percent() {
        let percentage = (this.margin_per_case() / this.dynamic_euro_was_sold_for()) * 100
        if (this.total_margin() < 0 && percentage > 0) {
            // This has to be done for credit items that were migrated from Fiton.
            percentage = percentage * -1
        }
        return percentage
    }
}

export class SalesOrderItemsDataModel {
    create_sales_order_items(sales_order_items: any, sales_order: any) {
        return sales_order_items.map((soi: any) =>
            this.create_sales_order_item(soi, sales_order))
    }

    create_sales_order_item(sales_order_item: any, sales_order: any) {
        return new SalesOrderItem(sales_order_item, sales_order)
    }
}

export const sales_order_items_dm = new SalesOrderItemsDataModel()

export class SalesOrderDataModel {
    create_sales_order(sales_order: any) {
        const sales_order_object = new SalesOrder(sales_order)
        if ('sales_order_items' in sales_order) {
            sales_order_object.sales_order_items(
                sales_order_items_dm.create_sales_order_items(
                    sales_order.sales_order_items,
                    sales_order_object,
                ),
            )
        }
        return sales_order_object
    }
}

export const sales_order_dm = new SalesOrderDataModel()

export class SalesOrderAdditional {
    artkey: any
    description: any
    sales_order_additional_type: any
    price_per_unit: any
    value_type: any
    value_per_quantity: any
    quantity: any
    sales_order: any
    sales_order_artkey: any

    constructor(json: any) {
        this.artkey = window.prop('')
        this.description = window.prop('')
        this.sales_order_additional_type = window.prop('')
        this.price_per_unit = window.prop('')
        this.value_type = window.prop('')
        this.value_per_quantity = window.prop('')
        this.quantity = window.prop('')
        this.sales_order = window.prop(new SalesOrder())
        this.sales_order_artkey = window.prop('')

        for (const prop in json) {
            if (prop === 'sales_order' && json[prop]) {
                this.sales_order(new SalesOrder(json[prop]))
                this.sales_order_artkey(this.sales_order().artkey())
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export class SalesOrderCreditItem {
    artkey: any
    sales_order_artkey: any
    sales_order: any
    sales_order_item_artkey: any
    sales_order_item: any
    number_of_cases: any
    item: any

    constructor(json: any, sales_order?: any) {
        this.artkey = window.prop('')
        this.sales_order_artkey = window.prop('')
        this.sales_order = window.prop(sales_order ? sales_order : new SalesOrder())
        this.sales_order_item_artkey = window.prop('')
        this.sales_order_item = window.prop(new SalesOrderItem())
        this.number_of_cases = window.prop('')
        this.item = window.prop(new Item())

        for (const prop in json) {
            if (prop === 'sales_order_item' && json[prop]) {
                this.sales_order_item(new SalesOrderItem(json[prop]))
                this.sales_order_item_artkey(this.sales_order_item().artkey())
            } else if (prop === 'sales_order' && json[prop] && !sales_order) {
                this.sales_order(sales_order_dm.create_sales_order(json[prop]))
                this.sales_order_artkey(this.sales_order().artkey())
            } else if (prop === 'item' && json[prop]) {
                this.item(new Item(json[prop]))
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }

    total_value() {
        return this.number_of_cases() * -this.sales_order_item().price_per_case()
    }

    margin_per_case() {
        return this.sales_order_item().euro_was_bought_for() - this.sales_order_item().euro_was_sold_for()
    }

    total_margin() {
        if (!this.sales_order_item().euro_was_sold_for) {
            return null
        }
        return this.margin_per_case() * this.number_of_cases()
    }

    margin_percent() {
        if (!this.sales_order_item().euro_was_sold_for) {
            return null
        }
        let percentage = (this.margin_per_case() / this.sales_order_item().euro_was_sold_for()) * 100
        if (this.total_margin() < 0 && percentage > 0) {
            // This has to be done for credit items that were migrated from Fiton.
            percentage = percentage * -1
        }
        return percentage
    }
}

export class TboStatus {
    static NOT_YET_APPROVED = 'Not yet approved'
    static OPEN = 'Open'
    static ORDERED = 'Ordered'
    static CONFIRMED = 'Confirmed'
    static IN_PROGRESS = 'In Progress'
    static ALL = 'All'
}

export class SalesOrderTBOItem {
    artkey: any
    sales_order_artkey: any
    sales_order: any
    sales_order_item_artkey: any
    sales_order_item: any
    offer_item_artkey: any
    offer_item: any
    supplier_artkey: any
    supplier: any
    case_artkey: any
    case: any
    number_of_cases: any
    price_per_case: any
    price_per_case_excl_excise: any
    excise_per_case: any
    created_from_portal_timestamp: any
    delivery_period: any
    purchase_order_account_slug: any

    constructor(json: any, sales_order?: any) {
        this.artkey = window.prop('')
        this.sales_order_artkey = window.prop('')
        this.sales_order = window.prop(sales_order ? sales_order : new SalesOrder())
        this.sales_order_item_artkey = window.prop('')
        this.sales_order_item = window.prop(new SalesOrderItem())
        this.offer_item_artkey = window.prop('')
        this.offer_item = window.prop(new OfferItem())
        this.supplier_artkey = window.prop('')
        this.supplier = window.prop(new Supplier())
        this.case_artkey = window.prop('')
        this.case = window.prop(new Case())
        this.number_of_cases = window.prop('')
        this.price_per_case = window.prop('')
        this.price_per_case_excl_excise = window.prop('')
        this.excise_per_case = window.prop('')
        this.created_from_portal_timestamp = window.prop('')
        this.delivery_period = window.prop('')
        this.purchase_order_account_slug = window.prop('')

        for (const prop in json) {
            if (prop === 'offer_item' && json[prop]) {
                this.offer_item(new OfferItem(json[prop]))
                this.offer_item_artkey(this.offer_item().artkey())
            } else if (prop === 'supplier' && json[prop]) {
                this.supplier(new Supplier(json[prop]))
                this.supplier_artkey(this.supplier().artkey())
            } else if (prop === 'case' && json[prop]) {
                this.case(new Case(json[prop]))
                this.case_artkey(this.case().artkey())
            } else if (prop === 'sales_order_item' && json[prop]) {
                this.sales_order_item(new SalesOrderItem(json[prop]))
                this.sales_order_item_artkey(this.sales_order_item().artkey())
            } else if (prop === 'sales_order' && json[prop] && !sales_order) {
                this.sales_order(sales_order_dm.create_sales_order(json[prop]))
                this.sales_order_artkey(this.sales_order().artkey())
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }

    total_value() {
        return this.number_of_cases() * this.price_per_case()
    }

    total_excl_excise() {
        return this.number_of_cases() * this.price_per_case_excl_excise()
    }
}

export class SalesOrderBasketItem {
    artkey: any
    sales_order_artkey: any
    sales_order: any
    price_per_case: any
    excise_per_case: any
    number_of_cases: any
    offer_item: any
    updated_timestamp: any

    constructor(json: any, sales_order?: any) {
        this.artkey = window.prop('')
        this.sales_order_artkey = window.prop('')
        this.sales_order = window.prop(sales_order ? sales_order : new SalesOrder())
        this.price_per_case = window.prop('')
        this.excise_per_case = window.prop('')
        this.number_of_cases = window.prop('')
        this.offer_item = window.prop(new OfferItem())
        this.updated_timestamp = window.prop('')

        for (const prop in json) {
            if (prop === 'offer_item' && json[prop]) {
                this.offer_item(new OfferItem(json[prop]))
            } else if (prop === 'sales_order' && json[prop] && !sales_order) {
                this.sales_order(sales_order_dm.create_sales_order(json[prop]))
                this.sales_order_artkey(this.sales_order().artkey())
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}
