import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Amount} from '@bitstillery/common/components'

import {Popover} from '@/components/popover/popover'
import {icon} from '@/components/icon'
import {PurchaseApi} from '@/factserver_api/purchase_api'

interface PurchaseKeyFiguresResponse {
    amount_of_saved_and_confirmed_purchase_orders: number
    number_of_cases_overdue: number
    number_of_cases_this_month: number
    number_of_cases_this_week: number
    number_of_confirmed_purchase_orders: number
    number_of_saved_purchase_orders: number
    number_of_open_tbos: number
}

export class KeyFigures extends MithrilTsxComponent<unknown> {
    is_loading = false
    purchase_api = new PurchaseApi()
    keyFigures: PurchaseKeyFiguresResponse | undefined

    fetch_key_figures(): void {
        this.is_loading = true
        this.purchase_api.key_figures().subscribe((response) => {
            if (response) {
                this.keyFigures = response
            }
            this.is_loading = false
            m.redraw()
        })
    }

    oncreate(): void {
        this.fetch_key_figures()
    }

    view(): m.Children {
        return (
            <div className="c-purchase-key-figures stats-group">
                <Popover
                    content="Cases overdue"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-danger">
                        {icon('fa-bell')} {this.keyFigures?.number_of_cases_overdue}
                    </div>
                </Popover>
                <Popover
                    content="ETA this week"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-info">
                        {icon('fa-calendar-check')} {this.keyFigures?.number_of_cases_this_week}
                    </div>
                </Popover>
                <Popover
                    content="ETA this month"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-info">
                        {icon('fa-calendar-check')} {this.keyFigures?.number_of_cases_this_month}
                    </div>
                </Popover>

                <Popover
                    content="Cases in TBO"
                    placement="bottom"
                >
                    <div className="bs-callout bs-callout-info">
                        {icon('fa-box-open')} {this.keyFigures?.number_of_open_tbos}
                    </div>
                </Popover>

                <Popover content="Saved & Confirmed Value" placement="bottom">
                    <div className="bs-callout bs-callout-info">
                        {icon('fa-euro-sign')}
                        <Amount
                            amount={this.keyFigures?.amount_of_saved_and_confirmed_purchase_orders}
                            currency={'EUR'}
                        />
                    </div>
                </Popover>

                <Popover content="Saved Orders" placement="bottom">
                    <div className="bs-callout bs-callout-warning">
                        {icon('fa-download')}
                        {this.keyFigures?.number_of_saved_purchase_orders}
                    </div>
                </Popover>

                <Popover content="Confirmed Orders" placement="bottom">
                    <div className="bs-callout bs-callout-success">
                        {icon('fa-download')}
                        {this.keyFigures?.number_of_confirmed_purchase_orders}
                    </div>
                </Popover>
            </div>
        )
    }
}
