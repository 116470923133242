import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, FieldSelect} from '@bitstillery/common/components'

import {Link} from '../components/discover'
import {ItemSalesOrders} from '../components/market_info/item_sales_orders'
import {BottlePurchaseOrders} from '../components/market_info/bottle_purchase_orders'

import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcherWithGET,
} from '@/components/collection/collection_table'
import {GetLoenderslootStockReconResultResponse, StockReconciliationResult} from '@/factserver_api/fact2server_api'

interface LoenderslootStockReconResultView extends GetLoenderslootStockReconResultResponse {
    is_showing_details: boolean
}

export default class LoenderslootStockReconList extends MithrilTsxComponent<unknown> {
    recon_fetcher:PagedCollectionFetcherWithGET<GetLoenderslootStockReconResultResponse>

    constructor() {
        super()

        const recon_artkey = m.route.param('artkey')
        if (!recon_artkey) {
            m.route.set('/')
        }

        this.recon_fetcher = new PagedCollectionFetcherWithGET<GetLoenderslootStockReconResultResponse>(
            `discover/loendersloot-stock/recons/${recon_artkey}/collection-view`,
            'lot',
            undefined,
            30,
            false,
        )
        this.recon_fetcher.filters['result'] = ''
    }

    set_result_filter(new_filter: any) {
        if (new_filter) {
            this.recon_fetcher.filters['result'] = new_filter
        } else {
            this.recon_fetcher.filters['result'] = ''
        }
        this.recon_fetcher.reset_and_query()
    }

    view(): m.Children {
        return <div className="c-loendersloot-recon-results view">
            <div className="btn-toolbar">
                <Button
                    active={false}
                    className="btn-back"
                    link="/stock/recon"
                    icon="back"
                    variant="toggle"
                />
            </div>
            <div className="c-filter-group">
                <div className="filter-field vertical">
                    <FieldSelect
                        label="Filter on result"
                        model={[this.recon_fetcher.filters, 'result']}
                        onchange={(value) => this.set_result_filter(value)}
                        options={Object.values(StockReconciliationResult).map((i) => ({label: i, value: i}))}
                        placeholder="Select result..."
                    />
                </div>
            </div>
            <CollectionTable<LoenderslootStockReconResultView, void>
                collection_fetcher={this.recon_fetcher}
                on_row_click={(row: LoenderslootStockReconResultView) =>
                    (row.is_showing_details = !row.is_showing_details)
                }
                on_row_click_component={LoenderslootStockReconResultDetails}
            >
                <CollectionTableColumn<LoenderslootStockReconResultView>
                    header_title={() => 'Result'}
                    sort_name={'result'}
                    td_class_name="size-2"
                    data_field={(row: LoenderslootStockReconResultView) => row.result}
                />
                <CollectionTableColumn<GetLoenderslootStockReconResultResponse>
                    header_title={() => 'Memo'}
                    td_class_name="size-3"
                    data_field={(row: GetLoenderslootStockReconResultResponse) => row.result_text}
                />
                <CollectionTableColumn<GetLoenderslootStockReconResultResponse>
                    header_title={() => 'Lot'}
                    td_class_name="size-2"
                    sort_name={'lot'}
                    data_field={(row: GetLoenderslootStockReconResultResponse) => row.lot}
                />
                <CollectionTableColumn<GetLoenderslootStockReconResultResponse>
                    header_title={() => 'Related item'}
                    td_class_name="size-2"
                    data_field={(row: GetLoenderslootStockReconResultResponse) => (<span>
                        {row.item_artkey && <Link href={`/stock/manage/${row.item_reference}`}>
                            <span className={'fas fa-expand-arrows-alt'}/>
                            {' '}
                            {row.item_reference}
                        </Link>}
                        {!row.item_artkey && 'Not found'}
                    </span>)
                    }
                />
                <CollectionTableColumn<GetLoenderslootStockReconResultResponse>
                    header_title={() => 'Item'}
                    td_class_name="size-4"
                    data_field={(row: GetLoenderslootStockReconResultResponse) => (<span>
                        {row.item_artkey && `${row.product_name} ${row.bottle_specs}`}
                    </span>)
                    }
                />
            </CollectionTable>
        </div>
    }
}

class LoenderslootStockReconResultDetails extends MithrilTsxComponent<
    CollectionTableRowComponentProps<LoenderslootStockReconResultView, void>
> {
    view(vnode: m.Vnode<CollectionTableRowComponentProps<LoenderslootStockReconResultView, void>>): m.Children {

        if (!vnode.attrs.row.is_showing_details) {
            return <span />
        }
        const row = vnode.attrs.row
        return (
            <tr className={'well'}>
                <td colspan={'100%'}>
                    <div className={'row'}>
                        <div className={'col-sm-12'}>
                            <h3>Details for {row.lot}</h3>
                        </div>
                    </div>
                    {row.item_artkey && <div className={'row'}>
                        <dl className={'dl-horizontal col-sm-12'}>
                            <dt>Item</dt>
                            <dd>
                                <Link href={`/stock/manage/${row.item_reference}`}>
                                    {row.item_reference}
                                </Link>
                                {` - ${row.product_name} ${row.bottle_specs}`}
                            </dd>
                            <dt>Stock - Shipped - In-Sales</dt>
                            <dd>
                                {row.number_of_cases} - {row.number_of_shipped_cases} - {row.number_of_cases_in_sales}
                                {' = '}
                                {(row.number_of_cases || 0) - (row.number_of_shipped_cases || 0) - (row.number_of_cases_in_sales || 0)}
                            </dd>
                            <dt>Purchase order</dt>
                            <dd>
                                <Link href={`/purchase-orders/manage/${row.purchase_order_reference}`}>
                                    {row.purchase_order_reference}
                                </Link>
                            </dd>
                        </dl>
                        <div className={'col-sm-6'}>
                            <ItemSalesOrders item_artkey={row.item_artkey}/>
                        </div>
                        <div className={'col-sm-6'}>
                            <BottlePurchaseOrders
                                bottle_artkey={row.bottle_artkey}
                                case_artkey={row.case_artkey}/>
                        </div>
                    </div>}
                    <div className={'col-sm-12'}>
                        <h4>Loendersloot information</h4>
                    </div>
                    <div className={'col-sm-12'}>
                        <code>{row.line}</code>
                    </div>
                </td></tr>
        )
    }
}
