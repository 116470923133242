/** llm:tested */
import m from 'mithril'

import {GenericEdit} from '@/data/generic/generic_edit'
import {Field} from '@/data/generic/generic_field'
import {$m} from '@/app'

export class PaymentTermUpsert {
    fields: Field[]

    constructor() {
        this.fields = [
            new Field('Description', 'description', 'input', 'text'),
            new Field(
                'Exact code',
                'exact_payment_term_code',
                'input',
                'text',
                {},
                'The Exact code should match the code for \'Betalingscondities\' in Exact.',
            ),
        ]
    }

    view(): m.Vnode {
        return <GenericEdit
            readable_entity_name="Payment term"
            base_url="/data/paymentterms"
            query_api_call="paymentterms.get_paymentterm"
            update_or_create_api_call="paymentterms.update_or_create_paymentterm"
            fields={this.fields}
            create_entity_func={$m.data.payment_term.create_payment_term}
            broadcast_on_update="paymentterms_updated"
        />
    }
}
