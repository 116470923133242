import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Observable} from 'rxjs'
import {FieldSelect} from '@bitstillery/common/components'

import {MaybeObservable} from './relation'

interface WarehousesDropDownAttrs {
    onchange: (incoterm_artkey: string) => unknown
    disabled?: boolean
    /** The data source for the drop down options */
    get_all_for_drop_down_response$: Observable<[]>
    label?: string
    model: []
    validation: any
}

export class DestinationsDropDown extends MithrilTsxComponent<WarehousesDropDownAttrs> {
    options = []

    oncreate(vnode: m.Vnode<WarehousesDropDownAttrs>): void {
        vnode.attrs.get_all_for_drop_down_response$.subscribe((options) => {
            this.options = options
        })
    }

    view(vnode: m.Vnode<WarehousesDropDownAttrs>): m.Children {
        return <MaybeObservable observed={vnode.attrs.get_all_for_drop_down_response$}>
            <FieldSelect
                label={vnode.attrs.label}
                disabled={vnode.attrs.disabled}
                model={vnode.attrs.model}
                onchange={vnode.attrs.onchange}
                options={this.options.map((destination:any) => ({
                    label: `${destination.name} - ${destination.city} (${destination.country_code})`,
                    value: `${destination.artkey}`,
                }))}
                placeholder="..."
                validation={vnode.attrs.validation}
            />
        </MaybeObservable>
    }
}

export function icon_for_destination_type(destination_type: string): m.Children {
    if (destination_type === 'warehouse') {
        return <span className={'fas fa-home'} />
    } else if (destination_type === 'airport') {
        return <span className={'fas fa-plane'} />
    } else if (destination_type === 'seaport') {
        return <span className={'fas fa-anchor'} />
    }

}
