/** llm:tested */
import m from 'mithril'
import {orList} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericList} from '@/data/generic/generic_list'

interface VatType {
    code: string
    vat_code: string
    description: string
}

interface VatRate {
    country_code: string
    percentage: number
    vat_type: VatType
}

export class VatRateList extends MithrilTsxComponent<any> {
    private is_match(vat_rate: VatRate, term: string) {
        return orList([
            vat_rate.country_code.toLowerCase().indexOf(term.toLowerCase()) > -1,
        ])
    }

    view(_vnode: m.Vnode<any>) {
        return <GenericList
            readable_entity_name="VAT rate"
            base_url="/data/vatrates/"
            list_api_call="vatrates.get_all"
            is_match={this.is_match}
            columns={[
                {key: 'country_code', label: 'Country'},
                {key: 'percentage', label: 'Percentage'},
                {
                    key: 'vat_type.code',
                    label: 'VAT Type',
                    function: (vat_rate: VatRate) => `${vat_rate.vat_type.vat_code} - ${vat_rate.vat_type.description}`,
                },
            ]}
            requery_subscribe_key="vatrates_updated"
        />
    }
}
