/** llm:production */
import m from 'mithril'
import {format_date} from '@bitstillery/common/lib/format'

import {items_table} from '@/stock/components/items_table'
import {ItemMutationReason} from '@/models/stock'

export const mutation_panel = (mutation: any, body: any) => {

    return (
        <div className="panel panel-info">
            <div className="panel-heading">
                <div className="panel-title">
                    Mutation
                    <a onclick={() => m.route.set(`/stock/mutations/manage/${mutation().reference()}`)}>
                        {mutation().reference()}
                    </a>
                </div>
            </div>
            <div className="panel-body">
                <div className="col-sm-12 col-lg-6">
                    <dl className="dl-horizontal">
                        <dt>Creation Date</dt>
                        <dd>{format_date(mutation().created_on())}</dd>
                        <dt>Reason</dt>
                        <dd>{mutation().item_mutation_reason()}</dd>
                        <dt>Description</dt>
                        <dd>{mutation().description()}</dd>
                        <dt>User</dt>
                        <dd>{mutation().user().profile().name()}</dd>
                        {mutation().item_mutation_reason() !== ItemMutationReason.MOVE_TO_WAREHOUSE && mutation().stock_value_difference() && [
                            <dt>Stock value difference</dt>,
                            <dd>
                                <span className="colored-number" data-value={`${(+mutation().stock_value_difference()).format_money()} EUR`}></span>
                            </dd>,
                        ]}
                    </dl>
                </div>
                {mutation().target_warehouse_artkey() && (
                    <div className="col-sm-12 col-lg-6">
                        <dl className="dl-horizontal">
                            <dt>Target Warehouse</dt>
                            <dd>{mutation().target_warehouse().name}</dd>
                            <dt>Expected Delivery Date</dt>
                            <dd>{format_date(mutation().expected_delivery_date())}</dd>
                            <dt>Status</dt>
                            <dd>{mutation().status()}</dd>
                        </dl>
                    </div>
                )}
                {body ? (
                    body
                ) : [
                    <div className="col-sm-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className="panel-title">After mutation</div>
                            </div>
                            <div className="panel-body">
                                {items_table(mutation().targets())}
                            </div>
                        </div>
                    </div>,
                    <div className="col-sm-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className="panel-title">Before mutation</div>
                            </div>
                            <div className="panel-body">
                                {items_table(mutation().sources())}
                            </div>
                        </div>
                    </div>,
                ] }
            </div>
        </div>
    )
}

export default mutation_panel
