/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {Collection} from '@/components/collection/collection'
import {EmailTable} from '@/email/components/email_table'

interface EmailBatch {
    artkey: string
}

interface EmailBatchSentEmailsAttrs {
    email_batch: () => EmailBatch
}

export class EmailBatchSentEmails extends MithrilTsxComponent<EmailBatchSentEmailsAttrs> {
    email_batch: () => EmailBatch
    emails: Collection

    oninit(vnode: m.Vnode<EmailBatchSentEmailsAttrs>) {
        this.email_batch = vnode.attrs.email_batch
        this.emails = new Collection({
            api_function_name: 'email.email.get',
            additional_params: () => ({email_batch_artkey: this.email_batch().artkey}),
            query_limit: 25,
            dont_reuse: true,
            default_sort_by: 'created_on',
            default_sort_order: 'desc',
        })
    }

    oncreate() {
        this.emails.requery()
    }

    view() {
        return [
            <div class="row">
                <div class="col-lg-4">
                    <h4>Emails sent</h4>
                </div>
            </div>,
            <EmailTable email_collection={this.emails} />,
        ]
    }
}
