/** llm:tested */
import {Offer} from '@/models/offers'
import {User} from '@/models/users'

export class EmailBatchStatus {
    static UNDER_CONSTRUCTION = 'under_construction'
    static PREPARING = 'preparing'
    static SENDING = 'sending'
    static SENT = 'sent'
    static IN_PROGRESS = 'in_progress'
    static WAITING_FOR_NEXT_RUN = 'waiting_for_next_run'

    static choices = {
        under_construction: 'Under construction',
        preparing: 'Preparing',
        sending: 'Sending',
        sent: 'Sent',
        in_progress: 'In Progress',
        waiting_for_next_run: 'Waiting for next run',
    }
}

export class EmailBatchType {
    static ENTIRE_PRICELIST = 'ENTIRE_PRICELIST'
    static SPECIAL_ONLY = 'SPECIAL_ONLY'
    static CUSTOM_OFFER = 'CUSTOM_OFFER'
    static NOTHING = 'NOTHING'
    static PURCHASE_ENQUIRY = 'PURCHASE_ENQUIRY'

    static choices = {
        ENTIRE_PRICELIST: 'Entire pricelist',
        SPECIAL_ONLY: 'Special offer',
        CUSTOM_OFFER: 'Custom offer',
        NOTHING: 'No pricelist',
        PURCHASE_ENQUIRY: 'Purchase Enquiry',
    }
}

export class EmailProcessFlow {
    static MAILS = 'mails'
    static OFFERS = 'offers'
}

export class EmailBatch {
    artkey: any
    created_on: any
    subject: any
    pricelist_type: any
    body: any
    dispatched_on: any
    created_by: any
    created_by_artkey: any
    offer_artkey: any
    batch_type: any
    cron_expression: any
    use_sent_from_user_artkey: any
    offer?: any
    next_run?: any

    constructor(json?: any) {
        this.artkey = window.prop('')
        this.created_on = window.prop('')
        this.subject = window.prop('')
        this.pricelist_type = window.prop(EmailBatchType.NOTHING)
        this.body = window.prop('')
        this.dispatched_on = window.prop('')
        this.created_by = window.prop(new User())
        this.created_by_artkey = window.prop('')
        this.offer_artkey = window.prop('')
        this.batch_type = window.prop('')
        this.cron_expression = window.prop(null)
        this.use_sent_from_user_artkey = window.prop(null)
        this.next_run = window.prop(null)

        if (json) {
            this.from_js(json)
        }
    }

    from_js(json: any): void {
        for (const prop in json) {
            if (prop === 'created_by') {
                this.created_by_artkey(json[prop].artkey)
                this.created_by(json[prop])
            } else if (prop === 'offer') {
                this.offer = window.prop(new Offer(json[prop]))
                this.offer_artkey(this.offer().artkey())
            } else if (prop in this) {
                (this as any)[prop](json[prop])
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }
    }
}
