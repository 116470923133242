/** llm:production */
import m from 'mithril'
import {sortBy, objToPairs} from 'prelude-ls'
import {classes, hash} from '@bitstillery/common/lib/utils'
import {next_tick} from '@bitstillery/common/lib/proxy'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {languages} from '@/components/languages'
import {icon} from '@/components/icon'
import {toString, format_date_html5, randomString, stopPropagation} from '@/_utils'
import {Bottle} from '@/models/bottles'
import {$m, $s} from '@/app'

export const field = (label: string, input: any, _label_width = 2, _input_width = 10, help?: string) => {
    return (
        <div class="field">
            <label>{label}</label>
            {input}
            {help ? <div class="help">{help}</div> : null}
        </div>
    )
}

export const gift_box_type = (gift_box_type: any, options: any = {}) => {
    const update_gb = (value: string) => {
        gift_box_type(value)
        if (options.after_update) {
            options.after_update()
        }
    }

    return (
        <div class="c-field-gift-box field">
            {options.label ? <label>{options.label}</label> : null}
            <select
                onchange={(ev: Event) => update_gb((ev.target as HTMLSelectElement).value)}
                required={options.required}
                disabled={options.disabled}
            >
                <option value="">Select Gift box type...</option>
                {options.include_no_gift_box_type ?
                    <option value="no_gift_box_type" selected={gift_box_type() === 'no_gift_box_type'}>No gift box</option> : null}
                {$m.data.gift_box_types.map((gbt: string) =>
                    <option value={gbt} selected={gift_box_type() === gbt}>{gbt}</option>,
                )}
            </select>
        </div>
    )
}

export const customs_status = (customs_status: any, options: any = {}) => {
    const element = (
        <select
            onchange={(ev: Event) => {
                if (options.onchange) {
                    options.onchange((ev.target as HTMLSelectElement).value)
                } else if (customs_status) {
                    customs_status((ev.target as HTMLSelectElement).value)
                }
            }}
            required={options.required}
            disabled={options.disabled}
        >
            <option value="" selected={true}>-</option>
            {$m.data.customs_status.map((cs: string) =>
                <option value={cs} selected={cs === customs_status()}>{cs}</option>,
            )}
        </select>
    )

    if (!options.label) {
        return element
    }

    const validation = options.validation
    const invalid = validation ? validation._invalid : false

    if (validation && options.label) {
        validation.description = options.label
    }

    return (
        <div class={classes('c-field-customs-status', 'field', {
            disabled: options.disabled,
            invalid: validation && invalid && validation.dirty,
            valid: !validation || !invalid,
        })}>
            <label>
                {options.label}
                {validation ? <span class="validation">{validation.label}</span> : null}
            </label>
            {element}
            {invalid && validation.dirty ?
                <div class="help validation">{invalid.message}</div> :
                options.help ? <div class="help">{options.help}</div> : null}
        </div>
    )
}

export const refill_status = (refill_status: any, options: any = {}) => {
    return (
        <select
            onchange={(ev: Event) => refill_status((ev.target as HTMLSelectElement).value)}
            required={options.required}
            disabled={options.disabled}
        >
            {$m.data.bottle_refill_statusses.map((rs: string) =>
                <option value={rs} selected={rs === refill_status()}>{rs}</option>,
            )}
        </select>
    )
}

export const text = (text: any, options: any = {}) => {
    const type = options.type || 'text'

    let disabled = options.disabled
    const superuser_override_active = options.allow_superuser_override && $s.identity.user.name.is_superuser
    if (superuser_override_active) {
        disabled = false
    }

    const validation = options.validation
    const invalid = validation ? validation._invalid : false

    const attrs: any = {
        type: type,
        value: text(),
        oninput: (ev: Event) => {
            if (options.validation) {
                options.validation.dirty = true
            }
            text((ev.target as HTMLInputElement).value)
        },
        onblur: (ev: Event) => {
            if (options.onblur) {
                options.onblur((ev.target as HTMLInputElement).value)
            }
        },
        required: options.required,
        disabled: disabled,
        placeholder: options.placeholder || '',
        pattern: options.pattern,
        valid: [false, true].includes(options.valid) ? options.valid : true,
    }

    if (options.after_update) {
        attrs.onchange = options.after_update
    }
    if (options.id) {
        attrs.id = options.id
    }
    if (options.autocomplete) {
        attrs.autocomplete = options.autocomplete
    }

    if (!options.label) {
        return (
            <div class="input-group">
                <input class="form-control" {...attrs} />
                {options.hint ? <span class="hint-text">{options.hint}</span> : ''}
            </div>
        )
    }

    if (validation && options.label) {
        validation.description = options.label
    }

    return (
        <div class={classes('c-field-text', 'field', {
            disabled: disabled,
            invalid: validation && invalid && validation.dirty,
            valid: !validation || !invalid,
        })}>
            <label>
                {options.icon ? [
                    icon(options.icon),
                    m.trust('&nbsp;'),
                ] : null}
                {options.label}
                {validation ? <span class="validation">{validation.label}</span> : null}
            </label>
            {options.addon ? (
                <div class="control">
                    <input {...attrs} />
                    {options.addon}
                </div>
            ) : (
                <input {...attrs} />
            )}
            {invalid && validation.dirty ?
                <div class="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div> :
                options.help ? <div class="help">{options.help}</div> : null}
        </div>
    )
}

export const email = (value: any, options: any = {}) => {
    options.type = 'email'

    return text(value, options)
}

export const password = (text: any, options: any = {}) => {
    options.type = 'password'

    const validation = options.validation
    if (validation && options.label) {
        validation.description = options.label
    }

    options.pattern = options.pattern || '(?=^.{8,}$)(?=.*[A-Z])(?=.*[a-z]).*$'
    return text(text, options)
}

export const date = (date: any, options: any = {}) => {
    const element = (
        <input
            required={options.required}
            disabled={options.disabled}
            type="date"
            value={date() ? format_date_html5(date()) : ''}
            oninput={(ev: Event) => date((ev.target as HTMLInputElement).value)}
            min={options.min ? format_date_html5(options.min) : '2000-01-01'}
            max={options.max ? format_date_html5(options.max) : '3000-01-01'}
        />
    )

    if (!options.label) {
        return element
    }

    const validation = options.validation
    const invalid = validation ? validation._invalid : false

    return (
        <div className={classes('c-field-date', 'field', {
            disabled: options.disabled,
            invalid: validation && invalid && validation.dirty,
            valid: !validation || !invalid,
        })}>
            <label>{options.label}</label>
            {element}
            {options.help ? <div class="help">{options.help}</div> : null}
        </div>
    )
}

export const time = (time: any, options: any = {}) => {
    if (!options.label) {
        return (
            <input
                class="form-control"
                required={options.required}
                disabled={options.disabled}
                value={time()}
                type="time"
                oninput={(ev: Event) => time((ev.target as HTMLInputElement).value)}
            />
        )
    }

    return (
        <div class="c-field-time field">
            <label>{options.label}</label>
            <input
                required={options.required}
                disabled={options.disabled}
                value={time()}
                type="time"
                oninput={(ev: Event) => time((ev.target as HTMLInputElement).value)}
            />
        </div>
    )
}

export const date_week = (date: any, options: any = {}) => {
    return (
        <input
            class="form-control"
            required={options.required}
            disabled={options.disabled}
            type="week"
            value={date() ? date() : ''}
            oninput={(ev: Event) => date((ev.target as HTMLInputElement).valueAsDate)}
        />
    )
}

export const date_month = (date: any, options: any = {}) => {
    return (
        <input
            class="form-control"
            required={options.required}
            disabled={options.disabled}
            type="month"
            value={date() ? date() : ''}
            oninput={(ev: Event) => date((ev.target as HTMLInputElement).valueAsDate)}
        />
    )
}

export const textarea = (textarea: any, options: any = {}) => {
    const element = (
        <textarea
            required={options.required}
            disabled={options.disabled}
            rows={(options.rows || 4)}
            value={textarea()}
            oninput={(ev: Event) => {
                if (options.validation) {
                    options.validation.dirty = true
                }
                if (options.oninput) {
                    options.oninput(ev)
                }
                textarea((ev.target as HTMLTextAreaElement).value)
            }}
        />
    )

    if (!options.label) {
        return element
    }

    const validation = options.validation
    const invalid = validation ? validation._invalid : false
    if (validation) {
        validation.description = options.label
    }

    return (
        <div class={classes('c-field-textarea', 'field', {
            invalid: validation && invalid && validation.dirty,
            valid: !validation || !invalid,
        })}>
            <label>
                {options.icon ? ([icon(options.icon), m.trust('&nbsp;')]) : null}
                {options.label}
                {validation ? <span class="validation">{validation.label}</span> : null}
            </label>
            {element}
            {invalid && validation.dirty ?
                <div class="help validation">{invalid.message}</div> :
                options.help ? <div class="help">{options.help}</div> : null}
        </div>
    )
}

export const tax_label = (tax_label: any, options: any = {}) => {
    if ($m.data.item_tag.length === 0) {
        return
    }
    let choices = []
    if ($m.data.item_tag_category.categories().length > 0) {
        const tax_label_category = $m.data.item_tag_category.tax_label_category()
        choices = $m.data.item_tag.get_all_from_category(tax_label_category)
            .map(item => item.name())
            .concat(options.no_option ? [options.no_option] : [])
    }

    options.empty_option = true
    return select(tax_label, choices, options)
}

export const vat_rates = (vat_rate: any, country_code: string, options: any = {}) => {
    const choices = $m.data.vat_rate.get_vat_rates_by_country(country_code)
        .map((vat_rate: any) => [vat_rate.artkey(), vat_rate.percentage()])
    if (options.no_option) {
        choices.unshift(options.no_option)
    }

    options.empty_option = true
    return select(vat_rate, choices, options)
}

interface TexteditorAttrs {
    text: () => string
    required: boolean
    after_update: Function
}
export class Texteditor extends MithrilTsxComponent<TexteditorAttrs> {
    text: any
    required: boolean
    after_update: Function
    old_text: string
    editor: any

    oninit(vnode: m.Vnode<TexteditorAttrs>) {
        this.text = vnode.attrs.text
        this.required = vnode.attrs.required
        this.after_update = vnode.attrs.after_update
        this.old_text = this.text()
    }

    check_if_text_changed = () => {
        if (this.text() !== this.old_text) {
            this.editor.summernote('code', this.text())
            this.set_text(this.text())
        }
    }

    set_text = (value: string) => {
        this.old_text = value
        this.text(value)
        if (this.after_update) {
            this.after_update(value)
        }
    }

    onchange = (value: string) => {
        this.set_text(value)
    }

    oncreate = () => {
        this.editor = $('#summernote')
        this.editor.summernote({
            placeholder: 'Email content',
            height: 340,
            popover: {
                container: 'body', // Forces tooltips to be appended to body
            },
            tooltip: {
                container: 'body', // Forces tooltips to be appended to body
                position: 'bottom', // You can try 'top' or 'bottom'
            },
            fontNames: ['Helvetica', 'Arial', 'Verdana', 'Courier New', 'Calibri', 'Times New Roman'],
            fontNamesIgnoreCheck: ['Helvetica', 'Arial', 'Verdana', 'Courier New', 'Calibri', 'Times New Roman'],
            toolbar: [
                ['style', ['bold', 'italic', 'clear']],
                ['font', ['strikethrough']],
                ['fontname', ['fontname']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol']],
                ['insert', ['link', 'table', 'picture']],
                ['misc', ['undo', 'redo']],
                ['view', ['codeview']],
            ],
            callbacks: {
                onInit: () => {
                    $('#summernote').summernote('insertText', '')
                },
                onChange: (contents: string) => {
                    this.onchange(contents)
                },
                onPaste: (e: ClipboardEvent) => {
                    const buffer_text = ((e.originalEvent || e).clipboardData || (window as any).clipboardData).getData('Text')
                    e.preventDefault()
                    document.execCommand('insertText', false, buffer_text)
                },
            },
        })
    }

    view() {
        this.check_if_text_changed()
        return m('.texteditor',
            m('textarea#summernote', {
                required: this.required,
                value: this.text(),
            }),
        )
    }
}

export class Quantity {
    quantity: any
    after_update: Function

    constructor(vnode: m.Vnode) {
        const attrs = vnode.attrs as any
        this.quantity = attrs.quantity
        this.after_update = attrs.after_update
    }

    parse_quantity = (value: string) => {
        if (value === '') {
            return '0'
        } else {
            return +value
        }
    }

    oninput = (value: string) => {
        this.quantity(this.parse_quantity(value))
        if (this.after_update) {
            this.after_update(value)
        }
    }

    view() {
        return m('input.form-control.number-input', {
            type: 'number',
            oninput: (ev: Event) => this.oninput((ev.target as HTMLInputElement).value),
            min: 0,
            value: this.quantity(),
            oncreate: (vnode: m.VnodeDOM) => {
                $(vnode.dom).on('click', stopPropagation())
                if ((vnode.attrs as any).focus) {
                    (vnode.dom as HTMLElement).focus()
                }
            },
        })
    }
}

export const number = (number: any, options: any = {}) => {
    const update_number = (value: string) => {
        const previous_value = number()
        if (value.length) {
            number(+value)
        } else {
            number(null)
        }
        if (options.after_update) {
            options.after_update(+value, +previous_value)
        }
    }

    const element = m('input', {
        required: options.required,
        disabled: options.disabled,
        value: isNaN(parseFloat(number())) ? '' : +number(),
        type: 'number',
        min: options.min !== undefined ? options.min : '',
        max: options.max !== undefined ? options.max : '',
        step: options.step !== undefined ? options.step : 1,
        oninput: (ev: Event) => update_number((ev.target as HTMLInputElement).value),
        oncreate: (vnode: m.VnodeDOM) => {
            $(vnode.dom).on('click', stopPropagation())
            if (options.focus) {
                $(vnode.dom).focus()
            }
        },
    })

    if (!options.label) {
        return element
    }
    return (
        <div className={classes('c-field-number', 'field', options.classNames)}>
            <label>{options.label}</label>
            {element}
            {options.help && <div class="help">{options.help}</div>}
        </div>
    )
}

export const checkbox = (prop: any, options: any = {}) => {
    let id = null

    if (options.id) {
        id = `_${hash(options.id)}`
    } else if (options.label) {
        id = `_${hash(options.label)}`
    }

    const validation = options.validation
    const invalid = validation ? validation._invalid : false

    const attrs: any = {
        checked: typeof prop === 'boolean' ? prop : prop() ? true : false,
        disabled: options.disabled,
        onchange: () => {
            prop(!prop())
            if (options.onchange) {
                options.onchange()
            }
        },
        type: 'checkbox',
    }

    if (id) {
        Object.assign(attrs, {id: id, name: id})
    }

    return (
        <div className={classes('c-field-checkbox', 'field', options.className, {
            disabled: options.disabled,
            invalid: validation && invalid && validation.dirty,
            valid: !validation || !invalid,
        })}>
            <div class="control">
                <input {...attrs} />
                {options.label ? <label for={id}>{options.label}</label> : null}
            </div>
            {options.help ? <div class="help">{options.help}</div> : null}
        </div>
    )
}

export const account = (account_artkey: any, options: any) => {
    return select(account_artkey,
        $m.accounts.accounts().map((account: any) => [account.artkey(), account.name()]),
        options,
    )
}

export const language = (language_code: any, options: any = {}) => {

    const element = m('select', {
        required: options.required,
        onchange: (ev: Event) => language_code((ev.target as HTMLSelectElement).value),
    }, [
        m('option', {value: ''}),
        Object.entries(languages).map(([code, name]) =>
            m('option', {value: code, selected: code === language_code()}, name),
        ),
    ])

    if (!options.label) {
        return element
    }

    return (
        <div className="c-field-language field">
            <label>{options.label}</label>
            {element}
            {options.help && <div className="help">{options.help}</div>}
        </div>
    )
}

export const specs = (bottles: any, bottle: any, options: any = {}) => {
    const set_bottle = (bottle_artkey: string) => {
        if (bottle_artkey && bottle_artkey !== '') {
            bottle(bottles().find((b: any) => b.artkey() === +bottle_artkey))
        } else {
            bottle(new Bottle())
        }
        if (options.after_update) {
            options.after_update(bottle_artkey ? bottle_artkey : undefined)
        }
    }

    return (
        <div className="c-field-specs field">
            {options.label && <label>{options.label}</label>}
            <select
                required={options.required}
                onchange={(ev: Event) => set_bottle((ev.target as HTMLSelectElement).value)}
            >
                <option value="">-</option>
                {bottles().length > 0 &&
                    sortBy((obj: any) => +(obj.volume()), bottles()).map((b: any) => (
                        <option
                            value={b.artkey()}
                            selected={bottle() ? b.artkey() === bottle().artkey() : false}
                        >
                            {b.to_specs()}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}

export const radio = (value: any, choices: any[], options: any = {}) => {
    return (
        <div className="btn-group" data-toggle="buttons">
            {choices.map(choice => {
                const bound_value = async(choice: any) => {
                    value(choice.value)
                    await next_tick()
                    await next_tick()
                    m.redraw()
                }

                const name = options.name || randomString(8)
                return (
                    <label
                        className={`btn ${value() === choice.value ? 'btn-info' : 'btn-default'}`}
                        onclick={bound_value.bind(null, choice)}
                        title={choice.title}
                    >
                        <input
                            type="radio"
                            name={name}
                            autoComplete="off"
                        />
                        {choice.description}
                    </label>
                )
            })}
        </div>
    )
}

export const select = (value: any, choices: any, options: any = {}) => {
    const attrs: any = {
        class: options.class,
        required: options.required,
        disabled: options.disabled,
        tabindex: options.tabindex,
        onchange: (ev: Event) => {
            if (options.validation) {
                options.validation.dirty = true
            }

            if (options.onchange) {
                options.onchange((ev.target as HTMLSelectElement).value)
            } else if (update_select) {
                update_select((ev.target as HTMLSelectElement).value)
            }
        },
    }
    if (options.id) {
        attrs.id = options.id
    }

    let list_of_choices: any[]
    switch (typeof choices) {
    case 'object':
        if (Array.isArray(choices)) {
            list_of_choices = choices
        } else {
            list_of_choices = objToPairs(choices)
        }
        break
    default:
        // eslint-disable-next-line no-console
        console.warn('Invalid choices for inputs.select: ', choices)
        list_of_choices = []
    }

    const update_select = (val: any) => {
        value(val)
        if (options.after_update) {
            options.after_update()
        }
    }

    const element = m('select', attrs, [
        options.empty_option ?
            m('option', {value: '', selected: true}, '-') :
            (!value() ? (() => {
                const first_key = Array.isArray(list_of_choices[0]) ? list_of_choices[0][0] : list_of_choices[0]
                value(first_key)
                return null
            })() : null),
        list_of_choices.map(entry => {
            const [key, title] = Array.isArray(entry) ? entry : [entry, entry]
            return m('option', {
                value: key,
                selected: toString(key).toUpperCase() === toString(value()).toUpperCase(),
            }, title)
        }),
    ])

    if (!options.label) {
        return element
    }

    const validation = options.validation
    const invalid = validation ? validation._invalid : false

    if (validation && options.label) {
        validation.description = options.label
    }

    return (
        <div className={classes('c-field-select', 'field', {
            disabled: options.disabled,
            invalid: validation && invalid && validation.dirty,
            valid: !validation || !invalid,
        })}>
            <label>
                {options.icon && [icon(options.icon), '&nbsp;']}
                {options.label}
                {validation && <span className="validation">{validation.label}</span>}
            </label>
            {element}
            {invalid && validation.dirty ? (
                <div className="help validation">{invalid.message}</div>
            ) : options.help ? (
                <div className="help">{options.help}</div>
            ) : null}
        </div>
    )
}

export const offer = (offers: any, offer_artkey: any, options: any = {}) => {
    const offer_name = (offer: any) => {
        if (offer.remark) {
            return `${offer.title} - ${offer.remark}`
        } else {
            return offer.title
        }
    }

    return <div className="c-field-offer field">
        <label>{options.label}</label>
        <select
            required={options.required}
            onchange={(ev: Event) => offer_artkey((ev.target as HTMLSelectElement).value)}
        >
            <option value=""></option>
            {sortBy((offer: any) => offer.title.toLowerCase(), offers()).map((offer: any) => (
                <option
                    value={offer.artkey}
                    selected={+offer.artkey === +offer_artkey()}
                >
                    {offer_name(offer)}
                </option>
            ))}
        </select>
    </div>

}

export const help = (text: string) => {
    return m('span.help-block', text)
}

export default {
    checkbox,
    customs_status,
    date,
    date_month,
    email,
    field,
    gift_box_type,
    language,
    password,
    number,
    radio,
    select,
    tax_label,
    text,
    textarea,
}
