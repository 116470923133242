import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/ts_utils'
import {Country, FieldSelect, Tabs, Tab, TabPanel, Tippy} from '@bitstillery/common/components'
import {DateTime} from 'luxon'
import {proxy} from '@bitstillery/common/lib/proxy'
import {notifier} from '@bitstillery/common/app'
import {CheckboxGroup as BsCheckboxGroup} from '@bitstillery/common/components'
import {watch} from '@bitstillery/common/lib/store.ts'
import {api} from '@bitstillery/common/app'

import {LeadDataFileList} from '@/crm/upload/upload_crm_file_list'
import {CheckBox} from '@/components/html_components'
import {
    CollectionFetcher,
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {
    ApplyFilterButton,
    ArrayFilterHelper,
    CheckboxGroup,
    CountryCodeFilterValues,
    FilterCheckboxGroupElement,
    FilterHelper,
    FilterRadioButtonGroup,
    FilterValue,
    OnlyMineFilterValue,
    SelectAllCheckboxGroupElement,
    SidebarLocalStorage,
    SingleValueFilterHelper,
    YesNoAllFilterValues,
} from '@/components/collection/side_bar'
import {ButtonWithLink, DangerButton, DefaultButton} from '@/components/buttons'
import {SelectionController} from '@/components/selection_controller'
import {CrmApi, GetAllLeadsResponse, LeadDataFileStatus, LeadStatus} from '@/factserver_api/crm_api'
import {GetAllUsersResponse, LeadContactStatus} from '@/factserver_api/fact2server_api'

export default class LeadList extends MithrilTsxComponent<unknown> {
    leads_fetcher = new PagedCollectionFetcher<GetAllLeadsResponse>('crm.leads.get_all', 'created_on', undefined)
    crm_api = new CrmApi()
    export_running = false

    is_selecting = false
    selection_controller: SelectionController
    selected_contact_status = ''
    all_traders: GetAllUsersResponse[] = []

    data = (() => {
        return proxy({
            tabs: {
                active: m.route.param('tabId') ? m.route.param('tabId') : 'overview',
            },
        })
    })()

    constructor() {
        super()

        this.selection_controller = new SelectionController(['lead'])
    }

    async oncreate() {
        const query_param = '?only_active=true&only_traders=true'
        const {status_code, result} = await api.get<GetAllUsersResponse[]>(`discover/users${query_param}`)
        if (status_code > 299) {
            return
        }
        this.all_traders = result
    }

    private set_status(row: GetAllLeadsResponse, new_status: LeadStatus): void {
        this.crm_api.set_lead_status(row.artkey, new_status).subscribe({
            next: () => {
                row.status = new_status
                this.leads_fetcher.is_dirty = true
                notifier.notify(`Successfully set status to: ${new_status}`, 'success')
                m.redraw()
            },
        })
    }

    unarchive(row: GetAllLeadsResponse): void {
        this.set_status(row, LeadStatus.REGISTERED)
    }

    archive(row: GetAllLeadsResponse): void {
        this.set_status(row, LeadStatus.ARCHIVED)
    }

    update_manager(row: GetAllLeadsResponse, user_artkey: string): void {
        const to_artkey: number | null = user_artkey ? +user_artkey : null

        // Update the data immediately, otherwise the dropdown will flicker. But remember current value to rollback.
        this.crm_api.quick_update_manager(row.artkey, to_artkey, row.memo, row.contact_status).subscribe({
            next: () => {
                this.leads_fetcher.is_dirty = true
                notifier.notify(`Successfully updated manager for ${row.name}`, 'success')
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    delete(row: GetAllLeadsResponse): void {
        if (row.converted_to_relation_artkey) {
            notifier.notify('Cannot delete this lead, it is already converted to a relation', 'warning')
        }

        this.crm_api.delete_lead(row.artkey).subscribe({
            next: () => {
                this.leads_fetcher.fetched_rows = this.leads_fetcher.fetched_rows.filter(
                    (fetched_row: GetAllLeadsResponse) => String(fetched_row.artkey) !== String(row.artkey),
                )
                this.leads_fetcher.is_dirty = true
                notifier.notify('Successfully deleted lead', 'success')
                m.redraw()
            },
        })
    }

    end_selection(): void {
        this.is_selecting = false
        this.selection_controller.end_selecting()
        this.selection_controller.remove_all('lead')
    }

    start_selection(): void {
        this.is_selecting = true
        this.selection_controller.start_selecting()
    }

    export_to_excel(): void {
        const file_name = `Lead export ${format_iso_to_date(DateTime.now().toISO())}.xlsx`
        const lead_artkeys = this.selection_controller.selected_items('lead')

        this.export_running = true
        this.crm_api.export_leads_by_artkeys(lead_artkeys, file_name).subscribe({
            next: () => {
                this.export_running = false
                m.redraw()
            },
        },
        )
    }

    generate_redeem_codes(): void {
        const lead_artkeys = this.selection_controller.selected_items('lead')
        this.crm_api.generate_redeem_codes_by_artkeys(lead_artkeys).subscribe({
            next: (response) => {
                let existing_code = false
                this.leads_fetcher.fetched_rows
                    .filter((row) => lead_artkeys.includes(row.artkey))
                    .forEach((row) => {
                        const artkey = row.artkey
                        const redeem_code = response.find(
                            (redeem_code_response) => redeem_code_response.lead_artkey === artkey)
                        if (row.redeem_code) {
                            existing_code = true
                        } else if (redeem_code) {
                            row.redeem_code = redeem_code.redeem_code
                            row.redeem_code_valid_until = redeem_code.redeem_code_valid_until
                        }
                    })

                if (existing_code) {
                    notifier.notify('Redeem code(s) were generated, but existing redeem codes in selection were not overwritten.', 'warning')
                } else {
                    notifier.notify('Redeem code(s) were generated.', 'info')
                }
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        },
        )
    }

    remove_redeem_code(row: GetAllLeadsResponse): void {
        this.crm_api.remove_redeem_code(row.artkey).subscribe({
            next: () => {
                notifier.notify(`Redeem code ${row.redeem_code} was removed`, 'info')
                row.redeem_code = null
                row.redeem_code_valid_until = null
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }

    selected_leads_to_contact_status(new_contact_status: LeadContactStatus) {
        const lead_artkeys = this.selection_controller.selected_items('lead')
        this.selected_contact_status = new_contact_status
        this.crm_api.set_leads_status(lead_artkeys, new_contact_status).subscribe({
            next: () => {
                notifier.notify(`Selected leads were updated to ${new_contact_status}`, 'info')
                lead_artkeys
                    .map((lead_artkey) => this.leads_fetcher.fetched_rows.find((row) => row.artkey === lead_artkey))
                    .filter((lead) => lead !== undefined)
                    .forEach((lead: GetAllLeadsResponse) => lead.contact_status = new_contact_status)
            },
            error: () => {
                m.redraw()
            },
        })
    }

    view(): m.Children {
        let all_selected_in_filtered = false
        if (this.is_selecting) {
            const number_of_selected_in_filtered = this.leads_fetcher.fetched_rows.filter((row) => {
                if (this.selection_controller.selected_items('lead').includes(row.artkey)) {
                    return true
                }
            }).length
            all_selected_in_filtered = this.leads_fetcher.count_fetched_rows() === number_of_selected_in_filtered
        }
        return <Tabs data={this.data} type="nested-view">
            <Tab
                icon="list"
                id="overview"
                link={'/crm/leads?tabId=overview'}
                text="Overview"
            >
                <TabPanel className="view">
                    <div className="c-crm-leads filter-sidebar-wrapper">
                        <div className={'c-filter-sidebar'}>
                            <LeadFilter collection_fetcher={this.leads_fetcher}/>
                        </div>
                        <div className={'filter-result' + (this.is_selecting ? ' no-click' : '')}>
                            <div className={'btn-toolbar .mb-2'}>
                                {!this.is_selecting && (
                                    <DefaultButton
                                        title={' Make selection'}
                                        icon_class={'fas fa-dog'}
                                        onclick={() => this.start_selection()}
                                    />
                                )}
                                {this.is_selecting && [
                                    <DefaultButton
                                        title={' Generate redeem codes'}
                                        icon_class={'fas fa-code'}
                                        disabled={this.selection_controller._list_for_key('lead').length === 0 || this.export_running}
                                        onclick={() => this.generate_redeem_codes()}
                                    />,
                                    <DefaultButton
                                        title={' Export leads'}
                                        icon_class={'fas fa-file-excel'}
                                        disabled={this.selection_controller._list_for_key('lead').length === 0 || this.export_running}
                                        onclick={() => this.export_to_excel()}
                                    />,
                                    <FieldSelect
                                        disabled={this.selection_controller._list_for_key('lead').length === 0 || this.export_running}
                                        label="Contact status"
                                        model={[this, 'selected_contact_status']}
                                        onchange={(value: string) => this.selected_leads_to_contact_status(LeadContactStatus[value])}
                                        options={LeadContactFilterValues.map((value) => ({value: value.value, label: value.title}))}
                                        placeholder="Select a new contact status"
                                    />,
                                    <DefaultButton
                                        title={' Cancel select'}
                                        icon_class={'fas fa-ban'}
                                        additional_class={'btn-danger'}
                                        onclick={() => this.end_selection()}
                                    />,
                                ]}
                            </div>
                            <CollectionTable<GetAllLeadsResponse, void>
                                on_row_click={(row: GetAllLeadsResponse) => m.route.set(`/crm/leads/${row.artkey}`)}
                                collection_fetcher={this.leads_fetcher}
                            >
                                {this.is_selecting && (
                                    <CollectionTableColumn<GetAllLeadsResponse>
                                        header_title={() => {
                                            return (
                                                <Tippy content={'Select all fetched rows'}>
                                                    <CheckBox
                                                        checked={all_selected_in_filtered}
                                                        onchange={
                                                            () => {
                                                                if (all_selected_in_filtered) {
                                                                    this.leads_fetcher.fetched_rows.forEach((row) => {
                                                                        this.selection_controller.remove_from_selection('lead', row.artkey)
                                                                    })
                                                                } else {
                                                                    this.leads_fetcher.fetched_rows.forEach((row) => {
                                                                        this.selection_controller.add_to_selection('lead', row.artkey)
                                                                    })
                                                                }
                                                            }
                                                        }
                                                    />
                                                </Tippy>
                                            )
                                        }}
                                        data_field={(row: GetAllLeadsResponse) => {
                                            return (
                                                <CheckBox
                                                    checked={this.selection_controller.is_in_selection('lead', row.artkey)}
                                                    onchange={
                                                        () => {
                                                            if (this.selection_controller.is_in_selection('lead', row.artkey)) {
                                                                this.selection_controller.remove_from_selection('lead', row.artkey)
                                                            } else {
                                                                this.selection_controller.add_to_selection('lead', row.artkey)
                                                            }
                                                        }
                                                    }
                                                />
                                            )
                                        }}
                                    />
                                )}
                                <CollectionTableColumn<GetAllLeadsResponse>
                                    header_title={() => 'Name'}
                                    sort_name={'name'}
                                    data_field={(row: GetAllLeadsResponse) => row.name}
                                />
                                <CollectionTableColumn<GetAllLeadsResponse>
                                    header_title={() => 'Created'}
                                    sort_name={'created_on'}
                                    data_field={(row: GetAllLeadsResponse) => format_iso_to_date(row.created_on)}
                                />
                                <CollectionTableColumn<GetAllLeadsResponse>
                                    header_title={() => 'Status'}
                                    data_field={(row: GetAllLeadsResponse) => row.status}
                                />
                                <CollectionTableColumn<GetAllLeadsResponse>
                                    header_title={() => 'Contact status'}
                                    data_field={(row: GetAllLeadsResponse) => {
                                        if (row.contact_status === LeadContactStatus.NOT_YET_APPROACHED) {
                                            return (
                                                <span>
                                                    <span className={'glyphicon glyphicon-eye-close'}/> Not yet approached
                                                </span>
                                            )
                                        } else if (row.contact_status === LeadContactStatus.FINALIZED) {
                                            return (
                                                <span>
                                                    <span className={'glyphicon glyphicon-check'}/> Finalized
                                                </span>
                                            )
                                        } else if (row.contact_status === LeadContactStatus.APPROACHED) {
                                            return (
                                                <span>
                                                    <span className={'glyphicon glyphicon-eye-open'}/> Approached
                                                </span>
                                            )
                                        } else {
                                            return (
                                                <span>
                                                    <span className={'fas fa-question'}/> Website
                                                </span>
                                            )
                                        }
                                    }}
                                />
                                <CollectionTableColumn<GetAllLeadsResponse>
                                    header_title={() => 'Manager'}
                                    sort_name={'manager_name'}
                                    data_field={(row: GetAllLeadsResponse) => {
                                        return <FieldSelect
                                            className={'no-click'}
                                            model={[row, 'manager_user_artkey']}
                                            onchange={(event) => {
                                                this.update_manager(row, event)
                                            }}
                                            options={this.all_traders.map((it) => ({value: it.artkey, label: it.name}))}
                                            placeholder={'Select a manager...'}
                                        />
                                    }}
                                />
                                <CollectionTableColumn<GetAllLeadsResponse>
                                    header_title={() => 'Country'}
                                    data_field={(row: GetAllLeadsResponse) => <Country country_code={row.country_code} type={'flag_with_country'}/>}
                                />
                                <CollectionTableColumn<GetAllLeadsResponse>
                                    header_title={() => 'Redeem code'}
                                    data_field={(row: GetAllLeadsResponse) =>
                                        row.redeem_code && <Tippy content={`
                                            <div>Redeem code: ${row.redeem_code}</div>
                                            <div>Valid until: ${format_iso_to_date(row.redeem_code_valid_until)}</div>
                                        `}>
                                            {row.redeem_code}
                                        </Tippy>
                                    }
                                />
                                {!this.is_selecting && (
                                    <CollectionTableColumn<GetAllLeadsResponse>
                                        header_title={() => ''}
                                        data_field={(row: GetAllLeadsResponse) => {
                                            if (row.status === LeadStatus.CONVERTED) {
                                                return (
                                                    <div key={'converted'} className="row-actions">
                                                        <Tippy content={'<div>Edit the converted relation</div>'}>
                                                            <ButtonWithLink
                                                                icon_class={'glyphicon glyphicon-briefcase'}
                                                                href={`/crm/relations/${
                                                                    row.converted_to_relation_artkey || ''
                                                                }/edit`}
                                                            />
                                                        </Tippy>
                                                    </div>
                                                )
                                            } if (row.status === LeadStatus.ARCHIVED) {
                                                return (
                                                    <div key={'archived'} className="row-actions">
                                                        <Tippy content={'<div>Unarchive this lead</div>'}>
                                                            <DefaultButton
                                                                icon_class={'fa fa-eye-slash'}
                                                                onclick={() => this.unarchive(row)}
                                                            />
                                                        </Tippy>
                                                        <Tippy content={'<div>Delete this entry</div>'}>
                                                            <DangerButton
                                                                icon_class={'fa fa-trash'}
                                                                onclick={() => this.delete(row)}
                                                            />
                                                        </Tippy>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div key={'processing'} className="row-actions">
                                                    {row.redeem_code && <Tippy content={'<div>Remove the redeem code</div>'}>
                                                        <DefaultButton
                                                            icon_class={'fas fa-code'}
                                                            onclick={() => this.remove_redeem_code(row)}
                                                        />
                                                    </Tippy>}
                                                    <Tippy content={'<div>Edit this lead</div>'}>
                                                        <ButtonWithLink
                                                            icon_class={'glyphicon glyphicon-edit'}
                                                            href={`/crm/leads/${row.artkey}/edit`}
                                                        />
                                                    </Tippy>
                                                    <Tippy content={'<div>Archive this lead</div>'}>
                                                        <DefaultButton
                                                            icon_class={'fa fa-eye'}
                                                            onclick={() => this.archive(row)}
                                                        />
                                                    </Tippy>
                                                    <Tippy content={'<div>Convert to relation</div>'}>
                                                        <ButtonWithLink
                                                            icon_class={'fa fa-folder-plus'}
                                                            href={`/crm/relations/create/${row.artkey}`}
                                                        />
                                                    </Tippy>
                                                </div>
                                            )
                                        }}
                                    />
                                )}
                            </CollectionTable>
                        </div>
                    </div>
                </TabPanel>
            </Tab>
            <Tab
                icon="import"
                id="import"
                link={'/crm/leads?tabId=import'}
                text="Import"
            >
                <TabPanel className="c-crm-data-files view">
                    <LeadDataFileList/>
                </TabPanel>

            </Tab>
        </Tabs>
    }
}

const LeadStatusFilterValues: FilterValue[] = [
    {value: LeadStatus.UPLOADED, title: 'Uploaded'},
    {value: LeadStatus.REGISTERED, title: 'Registered'},
    {value: LeadStatus.ARCHIVED, title: 'Archived'},
    {value: LeadStatus.CONVERTED, title: 'Converted'},
]

const LeadContactFilterValues: FilterValue[] = [
    {title: 'Not yet approached', value: LeadContactStatus.NOT_YET_APPROACHED},
    {title: 'Approached', value: LeadContactStatus.APPROACHED},
    {title: 'Finalized', value: LeadContactStatus.FINALIZED},
]

const RedeemCodeFilterValues: FilterValue[] = [
    {value: 'NO_REDEEM_CODE', title: 'No redeem code'},
    {value: 'REDEEM_CODE', title: 'Has redeem code'},
]

interface LeadFiltersAttrs {
    collection_fetcher: CollectionFetcher<GetAllLeadsResponse>
}

class LeadFilter extends MithrilTsxComponent<LeadFiltersAttrs> {
    crm_api = new CrmApi()
    search_bar_controller: SearchBarControl | null = null
    fetcher: CollectionFetcher<GetAllLeadsResponse>
    status_filter_helper: ArrayFilterHelper
    contact_status_filter_helper: ArrayFilterHelper
    operates_online_filter_helper: SingleValueFilterHelper
    only_mine_filter_helper: SingleValueFilterHelper
    country_code_filter_helper: ArrayFilterHelper
    turnover_group_filter_values: FilterValue[]
    turnover_group_filter_helper: ArrayFilterHelper
    side_bar_storage: SidebarLocalStorage
    all_filter_helpers: FilterHelper[]
    redeem_code_filter_helper: ArrayFilterHelper
    data_file_name_filter_values: FilterValue[]
    data_file_name_filter_helper: ArrayFilterHelper
    sales_manager_filter = proxy({
        options: [] as [][],
        selection:[],
    })

    watchers = [] as any

    constructor(vnode: m.Vnode<LeadFiltersAttrs>) {
        super()
        this.fetcher = vnode.attrs.collection_fetcher
        this.operates_online_filter_helper = new SingleValueFilterHelper(this.fetcher, 'operates_online', '')
        this.status_filter_helper = new ArrayFilterHelper(this.fetcher, 'status', LeadStatusFilterValues, [
            LeadStatus.UPLOADED,
        ])
        this.contact_status_filter_helper = new ArrayFilterHelper(
            this.fetcher,
            'contact_status',
            LeadContactFilterValues,
            [],
        )
        this.only_mine_filter_helper = new SingleValueFilterHelper(this.fetcher, 'relation_filter', '')
        this.country_code_filter_helper = new ArrayFilterHelper(
            this.fetcher,
            'country_code',
            CountryCodeFilterValues,
            [],
        )
        this.redeem_code_filter_helper = new ArrayFilterHelper(
            this.fetcher,
            'redeem_code',
            RedeemCodeFilterValues,
            [],
        )
        this.crm_api.get_all_data_files({status: LeadDataFileStatus.PROCESSED}).subscribe({
            next: (resp) => {
                this.data_file_name_filter_values = resp.map((data_file) => ({
                    value: data_file.artkey.toString(),
                    title: data_file.file_name,
                }))
                m.redraw()
            },
        })
        this.data_file_name_filter_helper = new ArrayFilterHelper(
            this.fetcher,
            'data_file_artkeys',
            [],
            [],
        )
        this.crm_api.get_turnover_groups().subscribe({
            next: (resp) => {
                this.turnover_group_filter_values = resp.turnover_groups.map((turnover_group) => ({
                    value: turnover_group,
                    title: turnover_group ? `${turnover_group}` : 'No turnover group',
                }))

                m.redraw()
            },
        })
        this.turnover_group_filter_helper = new ArrayFilterHelper(
            this.fetcher,
            'turnover_groups',
            [],
            [],
        )
        this.all_filter_helpers = [
            this.operates_online_filter_helper,
            this.only_mine_filter_helper,
            this.status_filter_helper,
            this.contact_status_filter_helper,
            this.country_code_filter_helper,
            this.redeem_code_filter_helper,
            this.data_file_name_filter_helper,
            this.turnover_group_filter_helper,
        ]
        this.side_bar_storage = new SidebarLocalStorage('crm-sidebar', this.all_filter_helpers)
    }

    async oncreate() {
        this.side_bar_storage.load()
        const query_param = '?only_active=true&only_traders=true'
        const {status_code, result} = await api.get<GetAllUsersResponse[]>(`discover/users${query_param}`)
        if (status_code > 299) {
            return
        }

        this.watchers.push(watch(this.sales_manager_filter.selection, () => {
            this.fetcher.filters['manager'] = this.sales_manager_filter.selection
            this.fetcher.reset_and_query()
        }))

        result
            .map((usr) => [usr.artkey, usr.name])
            .forEach((usr) => this.sales_manager_filter.options.push(usr))
    }

    onremove() {
        this.side_bar_storage.save()
        this.watchers.forEach((unwatch) => unwatch())
    }

    search_for_search_text(search_text: string) {
        this.fetcher.set_search_terms(search_text)
    }

    view(): m.Children {
        return <div>
            <SearchBar
                className="mb-2"
                placeholder={'Search for leads...'}
                on_submit={(search_text: string) => this.search_for_search_text(search_text)}
                default_search_text={''}
                search_bar_controller={(controller: SearchBarControl) =>
                    (this.search_bar_controller = controller)
                }
            />

            <ApplyFilterButton
                fetcher={this.fetcher}
                search_bar_control={this.search_bar_controller}
                filter_helpers={this.all_filter_helpers}
                side_bar_storage={this.side_bar_storage}
            />

            <FilterRadioButtonGroup
                title={''}
                helper={this.only_mine_filter_helper}
                onchange={() => this.fetcher.reset_and_query()}
                filter_values={OnlyMineFilterValue}
            />

            <CheckboxGroup title={'Status'} helper={this.status_filter_helper}>
                <SelectAllCheckboxGroupElement helper={this.status_filter_helper}/>
                <FilterCheckboxGroupElement
                    filter_values={LeadStatusFilterValues}
                    helper={this.status_filter_helper}
                />
            </CheckboxGroup>

            <CheckboxGroup title={'Contact status'} collapsed={true} helper={this.contact_status_filter_helper}>
                <SelectAllCheckboxGroupElement helper={this.contact_status_filter_helper}/>
                <FilterCheckboxGroupElement
                    filter_values={LeadContactFilterValues}
                    helper={this.contact_status_filter_helper}
                />
            </CheckboxGroup>
            <div className="c-checkbox-filter sales-manager">
                <a className={'title'}>Manager</a>
                <BsCheckboxGroup
                    options={this.sales_manager_filter.options}
                    selection={this.sales_manager_filter.selection}
                />
            </div>

            <CheckboxGroup title={'Redeem code'} collapsed={true} helper={this.redeem_code_filter_helper}>
                <SelectAllCheckboxGroupElement helper={this.redeem_code_filter_helper} />
                <FilterCheckboxGroupElement
                    filter_values={RedeemCodeFilterValues}
                    helper={this.redeem_code_filter_helper}
                />
            </CheckboxGroup>

            <CheckboxGroup title={'Country'} collapsed={true} helper={this.country_code_filter_helper}>
                <SelectAllCheckboxGroupElement helper={this.country_code_filter_helper}/>
                <FilterCheckboxGroupElement
                    filter_values={CountryCodeFilterValues}
                    helper={this.country_code_filter_helper}
                />
            </CheckboxGroup>

            {this.data_file_name_filter_values &&
                <CheckboxGroup title={'Data file'} collapsed={true} helper={this.data_file_name_filter_helper}>
                    <SelectAllCheckboxGroupElement helper={this.data_file_name_filter_helper}/>
                    <FilterCheckboxGroupElement
                        filter_values={this.data_file_name_filter_values}
                        helper={this.data_file_name_filter_helper}
                    />
                </CheckboxGroup>}

            {this.turnover_group_filter_values &&
                <CheckboxGroup title={'Turnover group'} collapsed={true} helper={this.turnover_group_filter_helper}>
                    <SelectAllCheckboxGroupElement helper={this.turnover_group_filter_helper}/>
                    <FilterCheckboxGroupElement
                        filter_values={this.turnover_group_filter_values}
                        helper={this.turnover_group_filter_helper}
                    />
                </CheckboxGroup>}

            <FilterRadioButtonGroup
                title={'Operates online'}
                helper={this.operates_online_filter_helper}
                filter_values={YesNoAllFilterValues}
            />
        </div>
    }
}
