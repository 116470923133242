/** llm:tested */
import jwtDecode from 'jwt-decode'
import {join} from 'prelude-ls'
import {events, telemetry} from '@bitstillery/common/app'

import {jsonFormat} from '@/formats'
import api from '@/api'
import utils from '@/_utils'
import {$m, $s} from '@/app'

interface ProfileData {
    artkey?: string
    name?: string
    emailaddress?: string
    address?: string
    telephone_number?: string
}

interface UserData {
    name?: string
    artkey?: string
    profile?: ProfileData
    is_superuser?: boolean
    is_sales_target_manager?: boolean
    is_trader?: boolean
    decimal_locale?: string
}

export class Profile {
    artkey: any
    name: any
    emailaddress: any
    address: any
    telephone_number: any

    constructor(profile_json: ProfileData = {}) {
        this.artkey = window.prop(profile_json['artkey'])
        this.name = window.prop(profile_json['name'])
        this.emailaddress = window.prop(profile_json['emailaddress'])
        this.address = window.prop(profile_json['address'])
        this.telephone_number = window.prop(profile_json['telephone_number'])
    }
}

export class User {
    name: any
    artkey: any
    profile: any
    is_superuser: any
    is_sales_target_manager: any
    is_trader: any
    is_purchaser: any
    has_userdata_loaded: boolean
    decimal_locale: any

    constructor(user_json: UserData = {}) {
        user_json = user_json || {}
        this.name = window.prop(user_json['name'])
        this.artkey = window.prop(user_json['artkey'])
        this.profile = window.prop(new Profile(user_json['profile']))
        this.is_superuser = window.prop(user_json['is_superuser'])
        this.is_sales_target_manager = window.prop(user_json['is_sales_target_manager'])
        this.is_trader = window.prop(user_json['is_trader'])
        this.is_purchaser = window.prop(user_json['is_trader'])
        this.has_userdata_loaded = false
        this.decimal_locale = window.prop(user_json['decimal_locale'])
    }

    token(value?: string): string | undefined {
        if (arguments.length && value) {
            $s.identity.token = value
        }

        const token = $s.identity.token
        if (!token) {
            events.emit('identity.logout')
            return
        }

        try {
            const data = jwtDecode(token) as any
            this.artkey(data.user_artkey)
            this.name(data.name)

            if (!this.has_userdata_loaded) {
                const api_data = {
                    artkey: this.artkey(),
                }
                this.has_userdata_loaded = true
                api.callAndThen('users.get_user', api_data, {
                    success: (resp: any) => {
                        events.emit('identity.login', resp.result)
                        this.is_superuser(resp.result.is_superuser)
                        this.is_sales_target_manager(resp.result.is_sales_target_manager)
                        this.decimal_locale(resp.result.decimal_locale)
                        this.profile(new Profile(resp.result.profile))
                    },
                })
            }

            return token
        } catch (e) {
            localStorage.removeItem('token')
            if (telemetry.enabled) {
                telemetry.set_user(undefined)
            }
            return
        }
    }

    get_setting_path(key: string[]): string {
        return join('_', [this.name()].concat(key))
    }

    get_setting(key: string[], default_value: any): string | null {
        const value = localStorage.getItem(this.get_setting_path(key))
        return value !== null ? value : default_value
    }

    remove_setting(key: string[]): void {
        localStorage.removeItem(this.get_setting_path(key))
    }

    set_setting(key: string[], value: string): void {
        localStorage.setItem(this.get_setting_path(key), value)
    }

    make_setting_prop(key: string[], default_value: any, format = jsonFormat): any {
        const result = window.prop(format.deserialize(this.get_setting(key, default_value)))
        utils.onSet(result, (value: any) => {
            this.set_setting(key, format.serialize(value))
        })
        return result
    }
}

export class UsersDataModel {
    create_users(users: UserData[]): User[] {
        return users.map(user => this.create_user(user))
    }

    create_user(user: UserData): User {
        return new User(user)
    }
}

// The old global.user; here because of 'make_setting_prop'
// Fine to initialize on root scope; as long it doesn't do
// an api call.
export let legacy_user = new User()

export function bind() {
    events.on('identity.login', async() => {
        $m.users = new UsersDataModel()
    })
}
