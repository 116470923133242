import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {format_money_with_symbol} from '@bitstillery/common/ts_utils'
import {watch} from '@bitstillery/common/lib/store'
import {api} from '@bitstillery/common/app'

import {SalesOrderTurnoverResponse, TurnoverFigures} from '@/factserver_api/fact2server_api.ts'
import {DashboardApi} from '@/factserver_api/dashboard_api.ts'
import {Percent} from '@/components/html_components'
import {UserStatsModel} from '@/dashboard/figures_dashboard'

export class SalesOrderTurnoverWidget extends MithrilTsxComponent<UserStatsModel> {
    dashboard_api = new DashboardApi()
    sales_order_turnover_data: SalesOrderTurnoverResponse
    loading_figures = true
    user_artkey: number | null | undefined
    row_count = 0
    watchers = []

    oninit(vnode: m.Vnode<UserStatsModel>) {
        this.watchers.push(watch(vnode.attrs.model, 'user_artkey', (user_artkey) => {
            this.fetch_data(user_artkey)
        }))
        this.fetch_data(vnode.attrs.user_artkey)
    }

    async fetch_data(user_artkey?: number | null) {
        this.user_artkey = user_artkey
        this.loading_figures = true
        const {result, status_code} = await api.get<SalesOrderTurnoverResponse>(`discover/dashboard/sales-orders/turnover${user_artkey ? `?sales_manager_artkey=${user_artkey}` : ''}`)
        this.loading_figures = false
        if (status_code > 299) {
            return
        }
        this.sales_order_turnover_data = result
    }

    sales_order_turnover_row(
        title: string,
        data: TurnoverFigures,
        filters: object,
    ): m.Children {
        this.row_count += 1

        if (this.user_artkey) {
            filters['sales_manager_artkey'] = this.user_artkey.toString()
        }

        const filter_params = new URLSearchParams(filters as any).toString()
        return (
            <tr
                className={this.row_count % 2 === 0 ? 'even onclick-row' : 'odd onclick-row'}
                onclick={() => {
                    window.open('#!/sales-orders/manage?' + filter_params.toString(), '_blank')
                }}
            >
                <td>{title}</td>
                <td>{data.number_of_orders}</td>
                <td>{data.number_of_cases}</td>
                <td>{format_money_with_symbol(data.total_turnover, 'EUR')}</td>
                <td><Percent value={+data.avg_margin_percentage / 100}/></td>
            </tr>
        )
    }

    view(): m.Children {
        return (
            <div className="c-widget-individual-sales dashboard-widget">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Number of orders</th>
                            <th>Number of cases</th>
                            <th>Total turnover</th>
                            <th>Avg Margin</th>
                        </tr>
                    </thead>
                    {this.loading_figures && <Spinner className="table-spinner"/>}
                    {!this.loading_figures && (
                        <tbody>
                            {this.sales_order_turnover_row(
                                'Saved (New)',
                                this.sales_order_turnover_data.saved_turnover_figures,
                                {status: 'Saved', overdue_orders: false},
                            )}
                            {this.sales_order_turnover_row(
                                'Saved (overdue)',
                                this.sales_order_turnover_data.overdue_turnover_figures,
                                {status: 'Saved', overdue_orders: true},
                            )}
                            {this.sales_order_turnover_row(
                                'Confirmed',
                                this.sales_order_turnover_data.confirmed_turnover_figures,
                                {status: 'Confirmed', in_progress_orders: false},
                            )}
                            {this.sales_order_turnover_row(
                                'In progress',
                                this.sales_order_turnover_data.in_progress_turnover_figures,
                                {status: 'Confirmed', in_progress_orders: true},
                            )}
                            {this.sales_order_turnover_row(
                                'Total',
                                this.sales_order_turnover_data.total_turnover_figures,
                                {status: 'Saved,Confirmed'},
                            )}
                        </tbody>
                    )}
                </table>
            </div>
        )
    }
}
