import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Badge} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {InfoButton} from '@/components/buttons'
import {CheckBox} from '@/components/html_components'
import {CrmApi, UploadDataFileRequest, LeadDataFile} from '@/factserver_api/crm_api'

enum UploadFlowStep {
    UPLOAD = 'UPLOAD',
    SELECT_COLUMNS = 'SELECT_COLUMNS'
}

interface UploadCrmFileState {
    saving: boolean
    base64_file?: string
    column_mapping?: Record<string, string>
    description?: string
    file_name?: string
    generate_redeem_codes: boolean
    header_row?: string[]
    column_types?: { name: string; required: boolean }[]
    sample_rows?: unknown[]
    header_match?: Record<number, string>
}

export default class UploadCrmFile extends MithrilTsxComponent<unknown> {
    crm_api = new CrmApi()
    state: UploadCrmFileState
    crm_data_file: LeadDataFile
    step: UploadFlowStep
    header: any[]
    selected_columns_mapping: Record<number, string>
    error_messages: string[]
    override_header_row: boolean
    overridden_header_idx: number | null

    constructor() {
        super()
        this.crm_data_file = new LeadDataFile()
        this.state = {saving: false, generate_redeem_codes: false}
        this.step = UploadFlowStep.UPLOAD

        this.header = []
        this.selected_columns_mapping = {}

        this.error_messages = []

        // Enable overriding the header row.
        this.override_header_row = false
        this.overridden_header_idx = null
    }

    on_step_complete = () => {
        if (this.step === UploadFlowStep.UPLOAD) {
            this.step = UploadFlowStep.SELECT_COLUMNS
        } else {
            // We are done, submit the data.
            this.upload()
        }
    }

    upload(): void {
        if (this.state.base64_file && this.state.column_mapping && this.state.description && this.state.file_name) {
            this.state.saving = true
            const data: UploadDataFileRequest = {
                base64_file: this.state.base64_file,
                column_mapping: this.state.column_mapping,
                description: this.state.description,
                generate_redeem_codes: this.state.generate_redeem_codes,
                file_name: this.state.file_name,
            }

            this.crm_api.upload_data_file(data).subscribe({
                next: () => {
                    notifier.notify('File upload started successfully. You will receive a notification when it is complete.', 'success')
                    m.route.set('/crm/uploads')
                },
                error: () => {
                    this.state.saving = false
                    m.redraw()
                },
            })
        }
    }

    view(): m.Children {
        return <div className="c-crm-upload-file view">
            {this.error_messages.length > 0 && (
                <div className="error-messages alert alert-danger animated fadeInUp col-md-12">
                    <ul>
                        {this.error_messages.map((message: string) => (
                            <li>{message}</li>
                        ))}
                    </ul>
                </div>
            )}

            {this.step === UploadFlowStep.UPLOAD && (
                <CrmFileSelectionStep
                    crm_api={this.crm_api}
                    on_step_complete={this.on_step_complete}
                    error_messages={this.error_messages}
                    state={this.state}
                />
            )}

            {this.step === UploadFlowStep.SELECT_COLUMNS && (
                <SelectColumnsStep
                    on_step_complete={this.on_step_complete}
                    error_messages={this.error_messages}
                    state={this.state}
                />
            )}
        </div>
    }
}

interface SelectColumnsStepAttrs {
    on_step_complete: () => void
    state: {
        saving: boolean
        column_types: { name: string; required: boolean }[]
        header_row: string[]
        sample_rows: string[][]
        header_match: Record<number, string>
        column_mapping: Record<number, string>
    }
    error_messages: string[]
}

class SelectColumnsStep extends MithrilTsxComponent<SelectColumnsStepAttrs> {
    attrs: SelectColumnsStepAttrs
    mandatory_column_names: string[]
    column_mapping: Record<number, string>
    column_type_names: string[]

    constructor(vnode: m.Vnode<SelectColumnsStepAttrs>) {
        super()
        this.attrs = vnode.attrs
        const state = this.attrs.state

        const column_types = state.column_types
        this.column_type_names = column_types.map((ct) => ct.name)
        this.mandatory_column_names = column_types
            .filter((ct) => ct.required)
            .map((ct) => ct.name)

        // The product of this step, a mapping from index to target column.
        this.column_mapping = state.header_match
    }

    get state() {
        return this.attrs.state
    }

    select_column(index: number, value: string): void {
        if (value !== '') {
            this.column_mapping![index] = value
        } else {
            delete this.column_mapping![index]
        }
    }

    submit(): void {
        this.attrs.error_messages = []
        for (const mandatory_column_name of this.mandatory_column_names) {
            if (!Object.values(this.column_mapping!).includes(mandatory_column_name)) {
                this.attrs.error_messages.push(
                    `Column ${mandatory_column_name} is not selected but it is mandatory.`,
                )
            }
        }
        this.state.column_mapping = this.column_mapping
        this.attrs.on_step_complete()
    }

    view(): m.Children {
        return (
            <div>
                <p>Please select which data we should get out of which column. If multiple columns are set to the same type, the values will be added together.</p>

                <div class="row">
                    <div class="field col-md-12">
                        <label class="control-label col-md-2">Accepted columns</label>
                        <div class="col-md-10">
                            {this.column_type_names.map((name) => (
                                <Badge type="info">{name}</Badge>
                            ))}
                        </div>

                        <label class="control-label col-md-2">Required columns</label>
                        <div class="col-md-10">
                            {this.mandatory_column_names.map((name) => (
                                <Badge type="info">{name}</Badge>
                            ))}
                        </div>
                    </div>
                </div>

                <table class="table search-table">
                    <thead>
                        <tr>
                            {this.state.header_row.map((col_name) => (
                                <th>{col_name}</th>
                            ))}
                        </tr>
                    </thead>

                    <thead>
                        <tr>
                            {this.state.header_row.map((col_name, index) => (
                                <th>
                                    <select class="column-mapping" oninput={(ev) => this.select_column(index, ev.target.value)}>
                                        <option value=""></option>
                                        {this.column_type_names.map((column_type_name) => (
                                            <option value={column_type_name} selected={column_type_name === this.column_mapping[index]}>{column_type_name}</option>
                                        ))}
                                    </select>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.sample_rows.map((row, index) => (
                            <tr class={index % 2 === 0 ? 'even' : 'odd'}>
                                {row.map((column) => (
                                    <td class="ellipsis">{column}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>

                <InfoButton onclick={() => this.submit()} disabled={this.state.saving} title={' Upload datafile'}/>
            </div>
        )
    }
}

interface CrmFileSelectionStepAttrs {
    crm_api: CrmApi
    on_step_complete: () => void
    state: UploadCrmFileState
    error_messages: string[]
}

interface CrmFileSelectionStepState {
    description: string
    file: string
    file_name: string
    generate_redeem_codes: boolean
    saving: boolean
}

class CrmFileSelectionStep extends MithrilTsxComponent<CrmFileSelectionStepAttrs> {
    crm_api: CrmApi
    attrs: CrmFileSelectionStepAttrs

    private state: CrmFileSelectionStepState = {
        description: '',
        file: '',
        file_name: '',
        generate_redeem_codes: false,
        saving: false,
    }

    constructor(vnode: m.Vnode<CrmFileSelectionStepAttrs>) {
        super()
        this.crm_api = vnode.attrs.crm_api
        this.attrs = vnode.attrs
    }

    set_state(state: Partial<CrmFileSelectionStepState>) {
        this.state = {...this.state, ...state}
        m.redraw()
    }

    parse_file(event: Event) {
        const target = event.target as HTMLInputElement
        const file_reader = new FileReader()
        file_reader.onload = (event) => {
            // @ts-ignore
            this.set_state({file: event.target?.result?.split(',')[1] ?? ''})
        }
        file_reader.readAsDataURL(target.files![0])
        this.set_state({file_name: target.files![0].name})
    }

    submit() {
        const data = {
            base64_file: this.state.file,
            description: this.state.description,
        }

        this.set_state({saving: true})

        this.crm_api.preprocess_data_file(data).subscribe({
            next: (resp) => {
                this.attrs.state.base64_file = this.state.file
                this.attrs.state.file_name = this.state.file_name
                this.attrs.state.description = this.state.description
                this.attrs.state.generate_redeem_codes = this.state.generate_redeem_codes
                this.attrs.state.header_row = resp.header_row
                this.attrs.state.column_types = resp.column_types
                this.attrs.state.sample_rows = resp.sample_rows
                this.attrs.state.header_match = resp.header_match
                this.set_state({saving: false})
                this.attrs.on_step_complete()
            },
            error: () => {
                this.set_state({saving: false})
            },
        })
    }

    view(): m.Children {
        return (
            <form class="c-lead-import">
                <div class="fieldset">
                    <p>Please select the data file to upload. Please note that the following columns are mandatory:</p>
                    <ul>
                        <li>Company name</li>
                        <li>Email address</li>
                        <li>Country code</li>
                        <li>Language</li>
                    </ul>
                    <div className={'field'}>
                        <label>File</label>
                        <input type="file" required name="file" onchange={(e) => this.parse_file(e)}
                            accept="application/xml,.xls,.xlsx"/>
                    </div>
                    <div className={'field'}>
                        <label>Description</label>
                        <textarea required placeholder="Description" value={this.state.description}
                            oninput={(e) => this.set_state({description: e.target.value})}></textarea>
                    </div>
                    <div className={'field'}>
                        <CheckBox
                            label="Generate redeem codes"
                            checked={this.state.generate_redeem_codes}
                            onchange={() => (this.state.generate_redeem_codes = !this.state.generate_redeem_codes)}
                        />
                    </div>

                    <InfoButton onclick={() => this.submit()} disabled={this.state.saving} icon_class={'fa fa-forward'}
                        title={' Go to column selection'}/>
                </div>
            </form>
        )
    }
}
