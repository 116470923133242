import {CollectionProxy} from '@bitstillery/common/lib/collection'
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/lib/specs'
import {api, events, logger, notifier} from '@bitstillery/common/app'
import {
    Spinner,
    CollectionItems,
    CollectionView,
    RowActionProcessed,
} from '@bitstillery/common/components'
import {Icon} from '@bitstillery/common/components'

import {$s} from '@/app'
import {SourcelineData} from '@/models/pricelists'
import {model} from '@/market/pricelists/list_pricelist_items'

const collection = new CollectionProxy()

export async function context_update() {
    $s.context.loading = true
    const sourceline = $s.context.data as SourcelineData
    logger.debug(`[resolve] update product suggestions: ${sourceline.product_name}`)
    const {result} = await api.post('matcher.get_bottle_suggestions', {
        bottle_gtin_code: sourceline.bottle_gtin_code,
        product_name: sourceline.product_name,
        volume: sourceline.volume,
        alcohol_percentage: sourceline.alcohol_percentage,
        refill_status: sourceline.refill_status,
    }, false) as any

    this.context_id = $s.context.id

    collection.state.items.splice(0, collection.state.items.length, ...result)
    $s.context.loading = false
}

const columns_suggestions = [
    {
        name: 'Product',
        render: (row) => {
            return <div className="td-group">
                <span className="header">
                    {row.product_name}
                </span>
                <span className="details">
                    {to_specs({
                        bottle_alcohol_percentage: row.alcohol_percentage,
                        bottle_refill_status: row.refill_status,
                        bottle_volume: row.volume,
                        case_customs_status: row.customs_status,
                        case_gift_box_type: row.gift_box_type,
                        case_number_of_bottles: row.number_of_bottles_per_case,
                    })}

                </span>
            </div>
        },
    },
    {
        name: 'Category',
        render: (row) => {
            return row.product_category
        },
    },
]

interface ProductSuggestionsAttrs {
    /** This is the unresolved items collection. The component itself also has a collection for the suggestions. */
    collection: CollectionProxy
}

export class ProductSuggestions extends MithrilTsxComponent<ProductSuggestionsAttrs> {

    view(vnode:m.Vnode<ProductSuggestionsAttrs>) {
        return <div className="suggestions content mb-1">
            <div className="persuade">
                {$s.context.loading ? <Spinner size="l" type="info" /> : <Icon name="info" size="l" type="info"/>}
                {(() => {
                    if ($s.context.loading) {
                        return <span>
                        We're looking for the best match for this sourceline.
                        This may take a moment.
                        </span>
                    }
                    if (collection.state.items.length) {
                        return <span>
                        Your expertise is needed!
                        One of the following products may likely be
                        the right product to resolve this sourceline
                        with, but we aren't able to tell from the available data.
                        Help by selecting the right product when you know it matches.
                        </span>
                    } else {
                        return <span>
                        No suggestions found for this sourceline.
                        </span>
                    }
                })()}
            </div>
            <CollectionView inactive={$s.context.loading} mode="table">
                <CollectionItems
                    collection={collection}
                    columns={columns_suggestions}
                    row_actions={(row) => {
                        return <RowActionProcessed
                            row_process={async() => {
                                const data = $s.context.data as SourcelineData
                                // Resolve through a product suggestion
                                data.bottle_artkey = row.bottle_artkey
                                data.product_name = row.product_name
                                data.product_artkey = row.product_artkey
                                data.product_category = row.product_category
                                data.product_category_artkey = row.product_category_artkey

                                const spli_data = {
                                    source_line_artkey: data.artkey,
                                    aux_info: data.aux_info,
                                    availability_status: data.availability_status,
                                    bottle: {
                                        alcohol_percentage: data.alcohol_percentage,
                                        artkey: data.bottle_artkey,
                                        product: {
                                            artkey: data.product_artkey,
                                            category: {
                                                artkey: data.product_category_artkey || null,
                                            },
                                            name: data.product_name,
                                            default_country_code: data.default_country_code,
                                        },
                                        refill_status: data.refill_status || null,
                                        volume: data.volume,
                                    },
                                    bottle_gtin_code: data.bottle_gtin_code,
                                    country_of_origin: data.country_of_origin,
                                    case_gtin_code: data.case_gtin_code,
                                    currency: data.currency,
                                    customs_status: data.customs_status,
                                    gift_box_type: data.gift_box_type || '',
                                    incoterm: data.incoterm_location ? `${data.incoterm} - ${data.incoterm_location}` : data.incoterm,
                                    number_of_bottles: data.number_of_bottles,
                                    number_of_bottles_per_case: data.number_of_bottles_per_case,
                                    number_of_cases: data.number_of_cases,
                                    cases_per_pallet: data.cases_per_pallet,
                                    price_per_bottle: data.price_per_bottle,
                                    price_per_case: data.price_per_case,
                                    supplier_price_list_artkey: model.supplier_pricelist.artkey,
                                }

                                try {

                                    await api.post(`discover/supplier-price-lists/${model.supplier_pricelist.artkey}/supplier-price-list-item`, spli_data, true)
                                    vnode.attrs.collection.select_next(data.artkey)
                                    events.emit('spl:refetch')
                                } catch (err) {
                                    notifier.notify(`Failed to resolve sourceline: ${err}`, 'danger')
                                }
                            }}
                            row={row}
                            tip="Resolve sourceline with this product"
                            type="success"
                        />
                    }}
                />
            </CollectionView>
        </div>
    }
}
