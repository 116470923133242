import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'
import {FieldText} from '@bitstillery/common/components'

import {FilterApi, SaveFilterPresetResponse, SaveFilterPresetRequest} from '@/factserver_api/filter_api'
import {DefaultButton, SaveButton} from '@/components/buttons'

interface SaveFilterPresetAsAttrs {
    filter_preset_data: SaveFilterPresetRequest
    done: (artkey: number) => void
    cancel: () => void
}

/**
 * A modal to save a filter preset under a new name.
 *
 * Usage in livescript:
 *    m Modal, do
 *       title: 'Save filter preset as'
 *       onclose: @onclose
 *    , m SaveFilterPresetAs, do
 *       filter_preset_data: @filter_preset_data
 *       done: @done
 *       cancel: @cancel
 */
export class SaveFilterPresetAs extends MithrilTsxComponent<SaveFilterPresetAsAttrs> {
    filter_preset_data: SaveFilterPresetRequest
    done: (artkey: number) => void
    cancel: () => void

    filter_api = new FilterApi()

    // Keep track of the original name, so we can detect if the name is
    // changed by the user. If so, we present the option to either save and
    // rename the filter preset, create a new filter preset.
    original_name: string | null = ''

    constructor(vnode: m.Vnode<SaveFilterPresetAsAttrs>) {
        super()

        this.filter_preset_data = vnode.attrs.filter_preset_data
        this.done = vnode.attrs.done
        this.cancel = vnode.attrs.cancel

        this.original_name = this.filter_preset_data.name
    }

    /**
     * Save a filter preset with the entered name.
     */
    save_filter_preset(create: boolean): void {
        // If we want to create a new preset from an existing one, remove the
        // artkey from filter_preset_data.
        if (this.filter_preset_data.artkey && create) {
            delete this.filter_preset_data.artkey
        }

        this.filter_api.save_filter_preset(this.filter_preset_data).subscribe((response: SaveFilterPresetResponse) => {
            notifier.notify(`Filter preset "${response.name}" has been saved successfully.`, 'success')
            this.done(response.artkey)
        })
    }

    view(): m.Children {
        return (
            <form className="flex-form">
                <FieldText
                    label="Filter preset name"
                    model={[this.filter_preset_data, 'name']}
                />

                <div className="btn-toolbar">
                    {this.original_name === this.filter_preset_data.name && this.original_name !== '' && (
                        <SaveButton
                            title={' Save Preset'}
                            icon_class={'glyphicon glyphicon-floppy-saved'}
                            disabled={this.filter_preset_data.name === ''}
                            onclick={() => this.save_filter_preset(false)}
                        />
                    )}
                    {this.original_name !== this.filter_preset_data.name && this.original_name !== '' && (
                        <SaveButton
                            title={' Rename and save Preset'}
                            icon_class={'glyphicon glyphicon-floppy-saved'}
                            disabled={this.filter_preset_data.name === ''}
                            onclick={() => this.save_filter_preset(false)}
                        />
                    )}
                    {(this.original_name !== this.filter_preset_data.name || this.original_name === '') && (
                        <SaveButton
                            title={' Create Preset'}
                            icon_class={'glyphicon glyphicon-floppy-saved'}
                            disabled={this.filter_preset_data.name === ''}
                            onclick={() => this.save_filter_preset(true)}
                        />
                    )}
                    <DefaultButton title={'Cancel'} onclick={() => this.cancel()} />
                </div>

            </form>
        )
    }
}
