/** llm:tested */
import {events} from '@bitstillery/common/app'
import {compact, map, groupBy} from 'prelude-ls'

import {$m} from '@/app'
import {Account} from '@/models/accounts'
import {Delivery, InsuranceType, Warehouse} from '@/models/data'
import {Case, Item} from '@/models/stock'
import {Supplier} from '@/models/suppliers'
import {User} from '@/models/users'

export class PurchaseOrderStatus {
    static SAVED = 'Saved'
    static CONFIRMED = 'Confirmed'
    static ON_HOLD = 'On Hold'
    static CANCELLED = 'Cancelled'
    static SAVED_OR_CONFIRMED = 'Saved or confirmed'
    static READY_FOR_FIS = 'Ready for FIS'
    // STOCKED was missing; added here. Does it even exist?
    static STOCKED = 'Stocked'
    static STOCKED_AND_BOOKED = 'Stocked and Booked'

    static all = [
        PurchaseOrderStatus.SAVED,
        PurchaseOrderStatus.CONFIRMED,
        PurchaseOrderStatus.CANCELLED,
        PurchaseOrderStatus.SAVED_OR_CONFIRMED,
        PurchaseOrderStatus.STOCKED,
        PurchaseOrderStatus.READY_FOR_FIS,
        PurchaseOrderStatus.STOCKED_AND_BOOKED,
    ]

    static choices: { [key: string]: string } = {}
    static {
        for (const status of PurchaseOrderStatus.all) {
            PurchaseOrderStatus.choices[status] = status
        }
    }
}

export class PurchaseOrder {
    artkey = window.prop('')
    account = window.prop(new Account())
    account_artkey = window.prop('')
    supplier = window.prop(new Supplier({}))
    supplier_artkey = window.prop('')
    date = window.prop('')
    incoterm = window.prop('')
    incoterm_location = window.prop('')
    reference = window.prop('')
    supplier_reference = window.prop('')
    target_warehouse = window.prop(new Warehouse({}))
    target_warehouse_artkey = window.prop('')
    warehouse_reference = window.prop('')
    was_handled_by_artkey = window.prop('')
    was_handled_by = window.prop(new User({}))
    was_bought_in = window.prop('EUR')
    was_bought_for = window.prop('1')
    number_of_cases = window.prop('')
    status = window.prop('')
    purchase_order_items = window.prop([])
    bought_against_rate = window.prop('')
    confirmation_date = window.prop('')
    is_cancelled = window.prop(false)
    warehouse_base_costs = window.prop(0)
    warehouse_costs_per_case = window.prop(0)
    transport_costs = window.prop(0)
    pallet_costs = window.prop(0)
    other_costs = window.prop(0)
    waste_fund_costs = window.prop(0)
    insurance_type_artkey = window.prop('')
    insurance_type = window.prop(new InsuranceType({}))
    was_sent_to_financial_information_system_on = window.prop('')
    remark = window.prop('')
    bottle_gtin_code = window.prop('')
    case_gtin_code = window.prop('')
    has_attachments = window.prop(false)
    requires_import_statement = window.prop(false)
    has_import_statement = window.prop(false)
    is_communicated_on = window.prop('')
    number_of_euro_pallets = window.prop('')
    number_of_block_pallets = window.prop('')
    sent_to_loendersloot_on = window.prop('')
    expected_delivery_date = window.prop('')
    warehouse_instruction = window.prop('')
    freight_instruction = window.prop('')
    frontoffice_instruction = window.prop('')
    number_of_edi_messages = window.prop('')
    delivery = window.prop(new Delivery({}))
    buy_from_account_sales_order = window.prop({})

    // Invoice data
    is_invoiced_on = window.prop('')
    intake_date = window.prop('')
    supplier_invoice_reference = window.prop('')
    invoiced_transport_costs = window.prop(0)
    invoiced_pallet_costs = window.prop(0)
    invoiced_other_costs = window.prop(0)
    invoice_price_difference = window.prop(0)
    invoiced_amount = window.prop(0)

    constructor(json: any) {
        for (const prop in json) {
            if (prop === 'supplier') {
                this.supplier(new Supplier(json[prop]))
                this.supplier_artkey(this.supplier().artkey())
            } else if (prop === 'account') {
                this.account_artkey(json[prop].artkey)
                this.account(new Account(json[prop]))
            } else if (prop === 'delivery') {
                this.delivery(new Delivery(json[prop]))
            } else if (prop === 'target_warehouse') {
                this.target_warehouse(new Warehouse(json[prop]))
                this.target_warehouse_artkey(json[prop].artkey)
            } else if (prop === 'insurance_type') {
                this.insurance_type($m.data.insurance_type.create_insurance_type(json[prop]))
                this.insurance_type_artkey(this.insurance_type().artkey())
            } else if (prop === 'was_handled_by') {
                this.was_handled_by(new User(json[prop]))
                this.was_handled_by_artkey(this.was_handled_by().artkey())
            } else if (prop === 'buy_from_account_sales_order') {
                if (json[prop]) {
                    this.buy_from_account_sales_order({
                        artkey: window.prop(json[prop]['artkey']),
                        reference: window.prop(json[prop]['reference']),
                        account_slug: window.prop(json[prop]['account']['slug']),
                        account_name: window.prop(json[prop]['account']['name']),
                    })
                } else {
                    this.buy_from_account_sales_order({
                        artkey: window.prop(null),
                        reference: window.prop(null),
                        account_slug: window.prop(null),
                        account_name: window.prop(null),
                    })
                }
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }
    }

    total_costs() {
        return +this.warehouse_base_costs() + +this.transport_costs() + +this.warehouse_costs_per_case() * +this.number_of_cases()
    }

    costs_per_case() {
        return (+this.warehouse_base_costs() + +this.transport_costs()) / +this.number_of_cases() + +this.warehouse_costs_per_case()
    }

    is_confirmed() {
        return this.status() === PurchaseOrderStatus.CONFIRMED
    }

    is_in_stock() {
        return this.status() === PurchaseOrderStatus.IN_STOCK
    }
}

export class PurchaseOrderItem {
    artkey = window.prop('')
    case_artkey = window.prop('')
    case = window.prop(new Case({}))
    creation_date = window.prop('')
    bought_against_rate = window.prop('')
    refers_to_artkey = window.prop('')
    items = window.prop([])
    was_bought_for = window.prop('')
    suggested_price_per_case = window.prop(null)
    euro_was_bought_for = window.prop('')
    euro_was_bought_for_plus_costs = window.prop('')
    currency = window.prop('')
    availability_date = window.prop('')
    number_of_cases = window.prop('')
    is_in_stock = window.prop(false)
    purchase_order = window.prop(new PurchaseOrder({}))
    purchase_order_artkey = window.prop('')
    comment = window.prop('')
    cases_per_pallet = window.prop(null)
    cases_per_pallet_layer = window.prop(null)
    remark = window.prop(null)
    bottle_gtin_code = window.prop('')
    case_gtin_code = window.prop('')
    country_of_origin = window.prop(null)
    hide_from_pricelist_for_suppliers = window.prop([])
    hide_from_pricelist_for_countries = window.prop([])
    bottle_artkey: any
    bottle: any
    number_of_bottles_per_case: any
    gift_box_type: any
    tax_label: any
    customs_status: any
    item_tags: any
    best_before_date: any

    constructor(json: any = {}, purchase_order?: PurchaseOrder) {
        for (const prop in json) {
            if (prop === 'case') {
                this.case_artkey(json[prop].artkey)
                this.case(new Case(json[prop]))
            } else if (prop === 'items') {
                this.items(json[prop].map((item: any) => new Item(item)))
            } else if (!purchase_order && prop === 'purchase_order') {
                this.purchase_order($m.purchase_orders.create_purchase_order(json[prop]))
                this.purchase_order_artkey(this.purchase_order().artkey())
            } else {
                (this as any)[prop] = window.prop(json[prop])
            }
        }

        this.bottle_artkey = this.case().bottle_artkey
        this.bottle = this.case().bottle
        this.number_of_bottles_per_case = this.case().number_of_bottles
        this.gift_box_type = this.case().gift_box_type
        this.tax_label = this.case().tax_label
        this.customs_status = this.case().customs_status
        this.item_tags = this.case().item_tags
        this.best_before_date = this.case().best_before_date
    }

    item_tags_per_category() {
        return groupBy(
            (tag: any) => tag.category_artkey(),
            compact(map($m.data.item_tag.get_item_tag, this.item_tags())),
        )
    }

    was_bought_for_plus_costs() {
        return +this.was_bought_for() + +this.purchase_order().costs_per_case()
    }

    total_price() {
        return +this.number_of_cases() * +this.was_bought_for()
    }

    specs() {
        const size = (+this.bottle().volume()).toFixed(1)
        const alc = (+this.bottle().alcohol_percentage()).toFixed(1)
        const ref = this.bottle().refill_status()
        return `${size}cl / ${alc}% / ${ref}`
    }

    total_number_of_cases_in_items() {
        let total_number_of_cases = 0
        for (const item of this.items()) {
            if (item.number_of_cases()) {
                total_number_of_cases += +item.number_of_cases()
            }
        }
        return total_number_of_cases
    }
}

export class PurchaseOrderItemsDataModel {
    create_purchase_order_items(purchase_order_items: any[], purchase_order: PurchaseOrder) {
        return purchase_order_items.map(poi => this.create_purchase_order_item(poi, purchase_order))
    }

    create_purchase_order_item(purchase_order_item: any, purchase_order: PurchaseOrder) {
        return new PurchaseOrderItem(purchase_order_item, purchase_order)
    }
}

const purchase_orderitems = new PurchaseOrderItemsDataModel()

export class PurchaseOrdersDataModel {
    create_purchase_order(purchase_order: any) {
        const purchase_order_object = new PurchaseOrder(purchase_order)
        if ('purchase_order_items' in purchase_order) {
            purchase_order_object.purchase_order_items(
                purchase_orderitems.create_purchase_order_items(
                    purchase_order.purchase_order_items,
                    purchase_order_object,
                ),
            )
        }
        return purchase_order_object
    }
}

export function bind() {
    events.on('identity.login', async() => {
        $m.purchase_orders = new PurchaseOrdersDataModel()
    })
}
