import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {Link} from './discover'

interface FigureWidgetAttrs {
    figure: string
    title: string
    sub_title?: string
    icon: string
    color: string
    link?: string
}

interface MineDashboardWidgetAttrs {
    link: string
}

export class MineDashboardWidget extends MithrilTsxComponent<MineDashboardWidgetAttrs> {
    view(vnode: m.Vnode<MineDashboardWidgetAttrs>): m.Children {
        return <div className="widget">
            {vnode.attrs.link && <Link href={vnode.attrs.link}>{vnode.children}</Link>}
            {!vnode.attrs.link && vnode.children}
        </div>
    }
}

export class FigureWidget extends MithrilTsxComponent<FigureWidgetAttrs> {
    view(vnode: m.Vnode<FigureWidgetAttrs>): m.Children {
        return (
            <div className="c-figures-widget"
                onclick={() => {
                    if (!vnode.attrs.link) return
                    m.route.set(vnode.attrs.link)
                }}
            >
                <div className={`icon ${vnode.attrs.color}`}>
                    <span className={vnode.attrs.icon} />
                </div>
                <div className={'figure'}>{vnode.attrs.figure}</div>
                <div className={`title ${vnode.attrs.color}`}>{vnode.attrs.title}</div>
                {vnode.attrs.sub_title && <div class={'sub-title'}>{vnode.attrs.sub_title}</div>}
            </div>
        )
    }
}
