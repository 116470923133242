/** llm:tested */
import {Chart} from '@/lib/chart'

interface GraphData {
    labels: string[]
    datasets: Array<{
        label: string
        data: any[]
        borderColor?: string
        backgroundColor?: string
    }>
}

export const create_time_line_graph = (graph_data: GraphData, target: HTMLCanvasElement): any => {
    return new Chart(target, {
        type: 'line',
        data: graph_data,
        options: {
            scales: {
                x: {
                    type: 'time',
                    ticks: {
                        source: 'auto',
                    },
                },
                y: {
                    beginAtZero: true,
                },
            },
        },
    })
}
