import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {api, notifier} from '@bitstillery/common/app'
import {FieldText, FieldTextArea} from '@bitstillery/common/components'
import {merge_deep} from '@bitstillery/common/lib/utils'

import {CountriesSelect, InputDate} from '@/components/html_components'
import {NumberInput} from '@/components/input_numbers'
import {EditAssist, HorizontalForm} from '@/components/form'
import {
    DamagesDropDown,
    GeneralDropDown,
    PackagingLabelDropDown,
    PackSizeDropDown,
} from '@/components/case_inputs'
import {GetItemResponse, UpdateItemRequest} from '@/factserver_api/fact2server_api.ts'
import {TagModel} from '@/factserver_api/item_tags'

export interface ItemEditorAttrs {
    item: GetItemResponse
    edit_assist: EditAssist
}

export class ItemEditor extends MithrilTsxComponent<ItemEditorAttrs> {
    tag_model:any
    update_item_request: UpdateItemRequest

    async oninit(vnode: m.Vnode<ItemEditorAttrs>) {
        this.update_item_request = {
            best_before_date: vnode.attrs.item.case.best_before_date,
            bottle_gtin: vnode.attrs.item.bottle_gtin_code || null,
            case_gtin: vnode.attrs.item.case_gtin_code || null,
            country_of_origin: vnode.attrs.item.country_of_origin || '',
            bottle_lot: vnode.attrs.item.bottle_lot || null,
            remark: vnode.attrs.item.remark || null,
            cases_per_pallet: vnode.attrs.item.cases_per_pallet || null,
            cases_per_pallet_layer: vnode.attrs.item.cases_per_pallet_layer || null,
            item_tag_artkeys: vnode.attrs.item.case.item_tag_artkeys,
        }
        this.tag_model = new TagModel()
        await this.tag_model.load_data()
        this.tag_model.set_selection(vnode.attrs.item.case.item_tag_artkeys)
    }

    async update_item(vnode: m.Vnode<ItemEditorAttrs>) {
        const {response, result} = await api.put(`discover/items/${vnode.attrs.item.artkey}`, merge_deep(this.update_item_request, {
            item_tag_artkeys: this.tag_model.serialize_selection(),
        }))
        if (response.ok) {
            notifier.notify(`Item ${vnode.attrs.item.reference} is updated`)
            m.route.set(`/stock/manage/${vnode.attrs.item.reference}`)
        }

        if (response.status === 400) {
            notifier.notify(`Cannot update item: ${result.detail}`)
        }

    }

    view(vnode: m.Vnode<ItemEditorAttrs>): m.Children {
        return (
            <HorizontalForm
                onsubmit={async() => await this.update_item(vnode)}
                edit_assist={vnode.attrs.edit_assist}
                submit_title={'Update Stock Item'}
            >
                <div className="fieldset-group">
                    <div className="fieldset">
                        <div className="fieldset-label">General</div>

                        <div className={'field-group'}>
                            <CountriesSelect
                                label="Country of Origin"
                                model={[this.update_item_request, 'country_of_origin']}
                            />
                            <InputDate
                                label="Best Before Date"
                                value={this.update_item_request.best_before_date}
                                onchange={(value: DateTime) => {
                                    this.update_item_request.best_before_date = value ? value.toISO() : null
                                }}
                            />
                        </div>

                        <div className="field-group">
                            <NumberInput
                                label="Cases Per Pallet"
                                minimum={this.update_item_request.cases_per_pallet_layer || 0}
                                value={this.update_item_request.cases_per_pallet}
                                oninput={(value: number) => (this.update_item_request.cases_per_pallet = value)}
                            />
                            <NumberInput
                                label="Cases Per Pallet Layer"
                                minimum={0}
                                maximum={this.update_item_request.cases_per_pallet}
                                value={this.update_item_request.cases_per_pallet_layer}
                                oninput={(value: number) => (this.update_item_request.cases_per_pallet_layer = value)}
                            />
                        </div>

                        <FieldText
                            label="Remark"
                            model={[this.update_item_request, 'remark']}
                        />

                        <div className="field-group">
                            <FieldText
                                label="Bottle GTIN"
                                model={[this.update_item_request, 'bottle_gtin']}
                            />

                            <FieldText
                                label="Case GTIN"
                                model={[this.update_item_request, 'case_gtin']}
                            />
                        </div>
                        <div className="field-group">
                            <FieldTextArea
                                disabled={true}
                                label="Bottle coding"
                                model={[vnode.attrs.item, 'bottle_coding']}
                            />

                            <FieldText
                                label="Bottle lot"
                                model={[this.update_item_request, 'bottle_lot']}
                            />
                        </div>
                    </div>

                    {this.tag_model.data && <div className="fieldset">
                        <div className="fieldset-label">Labels</div>

                        <DamagesDropDown
                            model={this.tag_model.data['Damages'].selection}
                            options={this.tag_model.data['Damages'].options}
                        />

                        <GeneralDropDown
                            model={this.tag_model.data['General'].selection}
                            options={this.tag_model.data['General'].options}
                        />

                        <PackSizeDropDown
                            label="Pack Size"
                            model={[this.tag_model.data['Pack Size'], 'selection']}
                            options={this.tag_model.data['Pack Size'].options}
                        />

                        <PackagingLabelDropDown
                            label="Packaging"
                            model={[this.tag_model.data['Packaging'], 'selection']}
                            options={this.tag_model.data['Packaging'].options}
                        />
                    </div>}
                </div>
            </HorizontalForm>
        )
    }
}
