/** llm:production */
import m from 'mithril'
import {Amount} from '@bitstillery/common/components'
import {Spinner} from '@bitstillery/common/components'

import * as utils from '@/_utils'
import panel from '@/components/_panel'
import {table} from '@/components/table'
import {Popover} from '@/components/popover/popover'

export class CreditItems {
    view(vnode: m.Vnode<any>) {
        return panel(vnode.attrs.title,
            vnode.attrs.loading() ?
                m(Spinner)
                : vnode.attrs.data().length > 0 ?
                    table.table(vnode.attrs.data(), [
                        {
                            width: 7,
                            field: 'sales_order.reference',
                            name: '#',
                            function: (credit_item: any) =>
                                m(m.route.Link, {href: `/sales-orders/manage/${credit_item.sales_order.reference}`},
                                    credit_item.sales_order.reference),
                        },
                        {
                            width: 5,
                            field: 'sales_order.created_on',
                            name: 'Date',
                            function: (credit_item: any) =>
                                utils.formatDate(credit_item.sales_order.created_on),
                        },
                        {
                            width: 9,
                            field: 'sales_order.supplier.name',
                            name: 'Relation',
                            ellipsis: true,
                        },
                        {
                            width: 8,
                            field: 'item.case.bottle.product.name',
                            name: 'Product',
                        },
                        {
                            width: 6,
                            field: 'item.case.number_of_bottles',
                            name: 'Btl / cs',
                            classes: 'number',
                        },
                        {
                            width: 4,
                            field: 'item.case.bottle.volume',
                            name: 'Size',
                            classes: 'number',
                            function: (credit_item: any) => (+credit_item.item.case.bottle.volume).toFixed(1) + ' cl',
                        },
                        {
                            width: 4,
                            field: 'item.case.bottle.alcohol_percentage',
                            name: 'Alc %',
                            classes: 'number',
                            function: (credit_item: any) => (+credit_item.item.case.bottle.alcohol_percentage).toFixed(1) + '%',
                        },
                        {
                            width: 3,
                            field: 'item.case.bottle.refill_status',
                            name: 'Ref',
                        },
                        {
                            width: 6,
                            field: 'item.case.gift_box_type',
                            name: 'GB',
                        },
                        {
                            width: 5,
                            field: 'item.case.tax_label',
                            name: 'Tax label',
                            ellipsis: true,
                        },
                        {
                            width: 3,
                            field: 'item.case.customs_status',
                            name: 'Cus.',
                        },
                        {
                            width: 6,
                            field: 'price_per_case',
                            name: 'Price / cs',
                            classes: 'price',
                            function: (credit_item: any) =>
                                m(Amount, {
                                    amount: -1 * credit_item.item.was_bought_for,
                                    currency: credit_item.sales_order.was_sold_in,
                                    rate: credit_item.sales_order.sold_against_rate,
                                }),
                        },
                        {
                            width: 7,
                            field: 'no_field',
                            name: 'Total price',
                            classes: 'price',
                            function: (credit_item: any) =>
                                m(Amount, {
                                    amount: -1 * credit_item.item.was_bought_for * credit_item.number_of_cases,
                                    currency: credit_item.sales_order.was_sold_in,
                                    rate: credit_item.sales_order.sold_against_rate,
                                }),
                        },
                        {
                            width: 7,
                            field: 'sales_order.incoterm_and_location',
                            name: 'Incoterm',
                            ellipsis: true,
                        },
                        {
                            width: 7,
                            field: 'sales_order.destination_warehouse.name',
                            name: 'Destination',
                            ellipsis: true,
                            function: (credit_item: any) => credit_item.sales_order.destination
                                ? credit_item.sales_order.destination.name
                                : credit_item.sales_order.destination_location,
                        },
                        {
                            width: 5,
                            field: 'sales_order.sales_order_status',
                            name: 'Status',
                        },
                        {
                            width: 8,
                            field: 'sales_order.supplier.sales_manager.profile.name',
                            name: 'Sales manager',
                            function: (credit_item: any) =>
                                credit_item.sales_order.supplier.sales_manager.profile.name !== credit_item.sales_order.was_handled_by.profile.name
                                    ? m(Popover, {
                                        title: 'Created by',
                                        content: credit_item.sales_order.was_handled_by.profile.name,
                                    }, [
                                        credit_item.sales_order.supplier.sales_manager.profile.name, ' ', m('span.glyphicon.glyphicon-user'),
                                    ])
                                    : credit_item.sales_order.supplier.sales_manager.profile.name,
                        },
                    ], {
                        search_table_style: false,
                        sticky_header: false,
                    })
                    : 'This item is not a result of a credit order.',
        )
    }
}
