import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {DateTime} from 'luxon'
import {Button, ButtonGroup, FieldText, PanelContext, Spinner} from '@bitstillery/common/components'
import {$t, store} from '@bitstillery/common/app'
import {notifier} from '@bitstillery/common/app'

import {AttachmentHelper, AttachmentList, UploadAttachment} from '../components/attachment_list'
import {EditAssist, HorizontalForm} from '../components/form'
import {NumberInput} from '../components/input_numbers'
import {EdiMessages} from '../components/edi_messages'

import {ItemMutationTargetAndSource} from './components/item_mutation_target_and_source'
import {ItemMutationDetails} from './components/item_mutation_details'

import {Modal} from '@/components/modal/modal'
import {show_confirmation} from '@/components/confirmation'
import {$s} from '@/app'
import {
    GetItemMutationByReferenceResponse,
    ItemMutationReason,
    ItemMutationStatus,
    StockApi,
} from '@/factserver_api/stock_api'
import {AttachmentType} from '@/factserver_api/attachment_api'
import {InputDate} from '@/components/html_components'

enum IS_SHOWING {
    ATTACHMENTS,
    ITEM_MUTATIONS,
    EDI = 3,
}

export class PanelRightMutationList extends MithrilTsxComponent<any> {

    oninit() {
        $s.context.name = 'data_card'
    }

    view(vn: m.Vnode<any>): m.Children {
        return <PanelContext>
            <div className="content">
                <UploadAttachment attachment_helper={vn.attrs.attachment_helper} />
                <ItemMutationDetails item_mutation={vn.attrs.item_mutation} />
            </div>
        </PanelContext>
    }
}

export default class MutationList extends MithrilTsxComponent<unknown> {
    stock_api = new StockApi()
    item_mutation_reference: string
    item_mutation: GetItemMutationByReferenceResponse | null = null
    is_showing = IS_SHOWING.ITEM_MUTATIONS
    attachment_helper: AttachmentHelper
    show_modal_send_to_loendersloot = false
    edit_assist: EditAssist = new EditAssist(m.route)

    constructor() {
        super()

        this.item_mutation_reference = m.route.param('reference')
        const item_mutation_artkey = +m.route.param('artkey')
        this.fetch_mutation()
        this.attachment_helper = new AttachmentHelper(item_mutation_artkey, AttachmentType.ITEM_MUTATION)
    }

    fetch_mutation(): void {
        this.stock_api
            .get_item_mutation_by_reference(this.item_mutation_reference)
            .subscribe((result: GetItemMutationByReferenceResponse) => {
                this.item_mutation = result
                if (!this.attachment_helper.artkey) {
                    this.attachment_helper.artkey = this.item_mutation.artkey
                    this.attachment_helper.load_attachments()
                }
                m.redraw()
            })
    }

    switch_currently_viewing_to(new_view: IS_SHOWING): void {
        this.is_showing = new_view
    }

    delete_mutation(): void {
        if (!this.item_mutation?.artkey) {
            return
        }
        this.stock_api
            .delete_item_mutation(this.item_mutation?.artkey)
            .subscribe(() => m.route.set('/stock/mutations/manage'))
    }

    send_inbound_instructions_xml(): void {
        if (!this.item_mutation) {
            return
        }
        this.stock_api.send_mutation_xml_to_loendersloot(this.item_mutation).subscribe(() => {
            this.fetch_mutation()
            notifier.notify('Successfully sent mutation to Loendersloot', 'success')
            this.show_modal_send_to_loendersloot = false
        })
    }

    view(): m.Children {
        const item_mutation = this.item_mutation
        if (!item_mutation) return <div className="c-stock-mutation view-container"><Spinner/></div>
        const buttons_enabled = (
            item_mutation.status === ItemMutationStatus.IN_TRANSIT &&
            item_mutation.item_mutation_reason === ItemMutationReason.MOVE_TO_WAREHOUSE &&
            item_mutation.reference
        )

        const buttons_tip = buttons_enabled ? '' : $t('stock.tips.mutation_actions_disabled')
        return (
            <div className="c-stock-mutation view-container">
                <div className="view">
                    {this.show_modal_send_to_loendersloot && this.item_mutation && <Modal
                        title={'Confirm send to Loendersloot'}
                        onclose={() => (this.show_modal_send_to_loendersloot = false)}
                    >
                        <HorizontalForm
                            onsubmit={() => this.send_inbound_instructions_xml()}
                            submit_title={$t('stock.actions.to_loe')}
                            edit_assist={this.edit_assist}
                        >

                            <InputDate
                                label="Estimated loading date"
                                value={this.item_mutation.expected_delivery_date || ''}
                                required={true}
                                onchange={(value: DateTime | null) =>
                                    (item_mutation.expected_delivery_date = value
                                        ? value.toISODate()
                                        : null)
                                }
                            />

                            <div className="field-group">
                                <NumberInput
                                    label='Number of euro pallets'
                                    value={item_mutation.number_of_euro_pallets}
                                    required={false}
                                    oninput={(value: number | null) => {
                                        item_mutation.number_of_euro_pallets = value
                                    }}
                                />
                                <NumberInput
                                    label="Number of block pallets"
                                    value={item_mutation.number_of_block_pallets}
                                    required={false}
                                    oninput={(value: number | null) => {
                                        item_mutation.number_of_block_pallets = value
                                    }}
                                />
                            </div>

                            <FieldText
                                label="Frontoffice instruction"
                                model={[item_mutation, 'frontoffice_instruction']}
                                placeholder={'Instructions for the Frontoffice team'}
                            />

                            <FieldText
                                label="Warehouse instruction"
                                model={[item_mutation, 'warehouse_instruction']}
                                placeholder={'Instructions for the Warehouse team'}
                            />

                            <FieldText
                                label="Freight instruction"
                                model={[item_mutation, 'freight_instruction']}
                                placeholder={'Instructions for the Freight team'}
                            />

                        </HorizontalForm>
                    </Modal>
                    }

                    <div className="btn-toolbar">
                        <ButtonGroup>
                            <Button
                                active={$s.context.name === 'data_card'}
                                className="btn-data-card"
                                icon="list"
                                onclick={() => {
                                    Object.assign($s.context, {
                                        default: !$s.context.default,
                                        id: null,
                                        name: $s.context.name === 'data_card' ? null : 'data_card',
                                    })
                                    store.save()
                                }}
                                tip="Toggle Data Card"
                                text="Data Card"
                                type="default"
                                variant="context"
                            />
                            <Button
                                disabled={!buttons_enabled}
                                icon="edit"
                                onclick={() =>
                                    m.route.set(
                                        `/stock/mutations/manage/${item_mutation.reference || ''}/edit`,
                                    )
                                }
                                text={$t('stock.actions.mutation_edit')}
                                tip={buttons_tip}
                                type="info"
                                variant="context"
                            />
                            <Button
                                disabled={!buttons_enabled}
                                icon="download"
                                onclick={() =>
                                    m.route.set(
                                        `/stock/mutations/manage/${
                                            item_mutation.reference || ''
                                        }/outtake-instruction`,
                                    )
                                }
                                text={$t('stock.actions.outtake_instructions')}
                                tip={buttons_tip}
                                type="info"
                                variant="context"
                            />
                            <Button
                                disabled={!buttons_enabled}
                                icon="trash"
                                onclick={() =>
                                    show_confirmation({
                                        title: 'Delete mutation',
                                        message: 'Are you sure you want to delete this mutation?',
                                        unique_name: 'confirm.item_mutation_deletion',
                                        onconfirm: () => this.delete_mutation(),
                                    })
                                }
                                text={$t('stock.actions.mutation_del')}
                                tip={buttons_tip}
                                type="danger"
                                variant="context"
                            />
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button
                                disabled={!buttons_enabled || item_mutation.warehouse_reference}
                                icon="send"
                                onclick={() => (this.show_modal_send_to_loendersloot = true)}
                                text={$t('stock.actions.to_loe')}
                                tip={() => {
                                    if (buttons_tip) return buttons_tip
                                    if (item_mutation.expected_delivery_date) {
                                        return 'Opens a dialog. By clicking the send button in the dialog, Loendersloot will be notified to expect the shipment containing the selected items.'
                                    }
                                    return 'Please enter an expected delivery date.'
                                }}
                                variant="context"
                            />
                            <Button
                                disabled={!buttons_enabled}
                                icon="stock"
                                onclick={() =>
                                    m.route.set(`/stock/mutations/manage/${this.item_mutation?.reference || ''}/instock`)
                                }
                                text={$t('stock.actions.to_non_loe')}
                                tip={buttons_tip}
                                variant="context"
                            />
                        </ButtonGroup>

                        <Button
                            active={false}
                            className="btn-back"
                            link="/stock/mutations/manage"
                            icon="back"
                            variant="toggle"
                        />
                    </div>

                    <ul className={'nav nav-tabs mb-2'}>
                        <li
                            role={'presentation'}
                            className={this.is_showing === IS_SHOWING.ITEM_MUTATIONS ? 'active' : ''}
                        >
                            <a
                                href={m.route.get()}
                                onclick={(click_event: MouseEvent): void => {
                                    click_event.preventDefault()
                                    this.switch_currently_viewing_to(IS_SHOWING.ITEM_MUTATIONS)
                                }}
                            >
                                Mutation items
                            </a>
                        </li>
                        <li
                            role={'presentation'}
                            className={this.is_showing === IS_SHOWING.EDI ? 'active' : ''}
                        >
                            <a
                                href={m.route.get()}
                                onclick={(click_event: MouseEvent): void => {
                                    click_event.preventDefault()
                                    this.switch_currently_viewing_to(IS_SHOWING.EDI)
                                }}
                            >
                                EDI ({item_mutation.number_of_edi_messages})
                            </a>
                        </li>
                        <li
                            role={'presentation'}
                            className={this.is_showing === IS_SHOWING.ATTACHMENTS ? 'active' : ''}
                        >
                            <a
                                href={m.route.get()}
                                onclick={(click_event: MouseEvent): void => {
                                    click_event.preventDefault()
                                    this.switch_currently_viewing_to(IS_SHOWING.ATTACHMENTS)
                                }}
                            >
                                Attachments ({this.attachment_helper.attachments.length})
                            </a>
                        </li>
                    </ul>
                    {this.is_showing === IS_SHOWING.ITEM_MUTATIONS && (
                        <ItemMutationTargetAndSource item_mutation={this.item_mutation} />
                    )}
                    {this.is_showing === IS_SHOWING.ATTACHMENTS && (
                        <AttachmentList attachment_helper={this.attachment_helper} />
                    )}
                    {this.is_showing === IS_SHOWING.EDI && (
                        <EdiMessages
                            item_mutation_artkey={item_mutation.artkey} />
                    )}
                </div>
                <PanelRightMutationList
                    attachment_helper={this.attachment_helper}
                    item_mutation={item_mutation}
                    title={`Mutation ${item_mutation.reference}`}
                />
            </div>
        )
    }
}
