import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {country_for_country_code} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'
import {
    Button,
    ButtonGroup,
    Country,
    FieldCheckbox,
    FieldDate,
    FieldSelect,
    FieldText,
    FieldTextArea,
} from '@bitstillery/common/components'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {invalid_fields, invalid_fields_format, required, validation} from '@bitstillery/common/lib/validation'
import {api, notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'
import {logger} from '@bitstillery/common/app'

import {EditableRelationList, RelationsSearch} from '../components/relation'
import {EditAssist} from '../components/form'
import {icon_for_destination_type} from '../components/destinations'

import {$s} from '@/app'
import {SalesApi} from '@/factserver_api/sales_api'
import {SearchBarControl} from '@/components/collection/search_bar'
import {
    GetIncotermsResponse,
    GetInsuranceTypeResponse,
    GetLocationResponse,
    GetRelationLocationResponse,
    GetRelationResponse,
    GetSalesOrderResponse,
    UpdateSalesOrderRequest,
} from '@/factserver_api/fact2server_api'
import {LOENDERSLOOT_WAREHOUSE_ARTKEY} from '@/factserver_api/factserver_generic.ts'

interface CreateOrEditSalesOrderComponentAttrs {
    onsaved: (artkey: number) => unknown
    relation_artkey?: number
    override_is_new?: boolean
}

interface EditableUpdateSalesOrderRequest extends Pick<
    UpdateSalesOrderRequest,
    'relation_artkey' | 'incoterm' | 'incoterm_location' | 'freight_instruction' | 'warehouse_instruction' |
    'estimated_loading_date' | 'currency' | 'exchange_rate' | 'includes_excise' | 'remark'> {
  destination_artkey?: number
  origin_artkey?: number
  escrow_loendersloot: boolean
  insurance_type_artkey?: number
  updated_exchange_rate: string
}

export class CreateOrEditSalesOrderComponent extends MithrilTsxComponent<CreateOrEditSalesOrderComponentAttrs> {

    sales_api = new SalesApi()

    data = proxy({
        selected_relations: [] as GetRelationResponse[],
        release_at_loendersloot: false,

        relation_locations: [] as GetRelationLocationResponse[],
        origin_locations: [] as GetRelationLocationResponse[],
        selected_origin_location: undefined as GetLocationResponse | undefined,
        selected_destination_location: undefined as GetLocationResponse | undefined,

        update_sales_order_request: {
            destination_artkey: 0,
            origin_artkey: 0,
            relation_artkey: 0,
            currency: 'EUR',
            exchange_rate: 1,
            updated_exchange_rate: '1.00000',
            freight_instruction: '',
            escrow_loendersloot: false,
            includes_excise: false,
            incoterm: '',
            insurance_type_artkey: 0,
            remark: '',
            warehouse_instruction: '',
            incoterm_location: '',
        } as EditableUpdateSalesOrderRequest,
        insurance_type_options: [] as GetInsuranceTypeResponse[],
        incoterm_options: [] as GetIncotermsResponse[],
        destination_message: '',
    })

    $v = {
        origin_artkey: validation([this.data.update_sales_order_request, 'origin_artkey'], required()),
        destination_artkey: validation([this.data.update_sales_order_request, 'destination_artkey'], required()),
        currency: validation([this.data.update_sales_order_request, 'currency'], required()),
        insurance_type_artkey: validation([this.data.update_sales_order_request, 'insurance_type_artkey'], required()),
        incoterm: validation([this.data.update_sales_order_request, 'incoterm'], required()),
        incoterm_location: validation([this.data.update_sales_order_request, 'incoterm_location'], required()),
    }

    watchers: type_remove_watch_function[] = []

    sales_order_artkey: number | null = null
    title = 'Create sales order'
    is_loading = false
    search_bar_control: SearchBarControl | null = null

    // inflight data for form maintenance
    edit_assist: EditAssist
    relation_name: string = ''
    sales_order_status: string = 'Saved'
    onsaved: (number) => unknown

    constructor(vnode: m.Vnode<CreateOrEditSalesOrderComponentAttrs>) {
        super()
        this.edit_assist = new EditAssist(m.route, vnode.attrs.override_is_new)
        this.onsaved = vnode.attrs.onsaved
    }

    async oncreate(vnode: m.Vnode<CreateOrEditSalesOrderComponentAttrs>) {
        this.watchers.push(watch(this.data, 'release_at_loendersloot', () => this.on_loendersloot_release()))
        this.watchers.push(watch(this.data.selected_relations, async() => await this.on_selected_relations()))
        this.watchers.push(
            watch(
                this.data,
                'selected_origin_location',
                () => {
                    this.data.update_sales_order_request.origin_artkey = this.data.selected_origin_location?.artkey
                    logger.debug('[CreateOrEditSalesOrder] set origin location artkey', this.data.selected_origin_location?.artkey)

                    if (this.data.selected_origin_location?.artkey !== LOENDERSLOOT_WAREHOUSE_ARTKEY) {
                        logger.debug('[CreateOrEditSalesOrder] no escrow loendersloot for origin', this.data.selected_origin_location?.artkey)
                        this.data.update_sales_order_request.escrow_loendersloot = false
                    }
                },
            ),
        )
        this.watchers.push(
            watch(
                this.data,
                'selected_destination_location',
                () => {
                    const destination = this .data.selected_destination_location
                    const country = country_for_country_code(this.data.selected_origin_location?.country_code || '')

                    this.data.update_sales_order_request.destination_artkey = destination?.artkey
                    logger.debug('[CreateOrEditSalesOrderComponent] set destination location artkey', destination?.artkey)

                    if (destination) {
                        this.data.update_sales_order_request.incoterm_location = `${destination.city} - ${country}`
                    }
                    this.update_message()
                },
            ),
        )
        this.watchers.push(
            watch(
                this.data.update_sales_order_request,
                'currency',
                () => this.data.update_sales_order_request.updated_exchange_rate = $s.currencies.exchange_rates[this.data.update_sales_order_request.currency].rate,
            ),
        )
        this.watchers.push(watch(this.data.update_sales_order_request, 'incoterm', () => this.update_incoterm()))

        this.is_loading = true
        const api_calls: Promise<void>[] = [
            this.fetch_insurance_types(),
            this.fetch_incoterm(),
        ]
        if (!this.edit_assist.is_creating) {
            api_calls.push(this.fetch_sales_order())
        } else if (vnode.attrs.relation_artkey) {
            api_calls.push(this.fetch_relation(vnode.attrs.relation_artkey))
        }

        api_calls.push(this.fetch_origin_warehouses())
        await Promise.all(api_calls)
        this.is_loading = false
    }

    onremove() {
        this.watchers.forEach((watcher) => watcher())
    }

    async fetch_insurance_types() {
        const {result} = await api.get<GetInsuranceTypeResponse[]>('discover/data/insurance-types')
        this.data.insurance_type_options = result
    }

    async fetch_incoterm() {
        const {result} = await api.get<GetIncotermsResponse[]>('discover/data/incoterms')
        this.data.incoterm_options = result
    }

    async fetch_relation_destinations(relation_artkey?: number) {
        if (!relation_artkey) {
            return
        }
        const {result} = await api.get<GetRelationLocationResponse[]>(`discover/relations/${relation_artkey}/destinations`)
        this.data.relation_locations.splice(0, this.data.relation_locations.length)
        this.data.relation_locations.push(...result)
    }

    async fetch_origin_warehouses() {
        this.data.origin_locations.splice(0, this.data.origin_locations.length)
        const {result} = await api.get<GetRelationLocationResponse[]>('discover/stock/locations')
        this.data.origin_locations.push(...result)
    }

    async fetch_sales_order() {
        if (this.edit_assist.is_creating || !this.edit_assist.artkey) {
            return
        }

        logger.debug('[CreateOrEditSalesOrder] fetch_sales_order', this.edit_assist.artkey)
        const {result} = await api.get<GetSalesOrderResponse>(`discover/sales-orders/${this.edit_assist.artkey}`)
        this.data.update_sales_order_request.relation_artkey = result.supplier_artkey
        await this.fetch_relation(this.data.update_sales_order_request.relation_artkey)

        this.relation_name = result.supplier.name
        this.data.update_sales_order_request.incoterm_location = result.incoterm_location
        this.data.update_sales_order_request.incoterm = result.incoterm
        this.data.update_sales_order_request.freight_instruction = result.freight_instruction
        this.data.update_sales_order_request.warehouse_instruction = result.warehouse_instruction
        this.data.update_sales_order_request.estimated_loading_date = result.estimated_loading_date
        this.data.update_sales_order_request.insurance_type_artkey = result.insurance_type_artkey
        this.data.update_sales_order_request.includes_excise = result.includes_excise
        this.data.update_sales_order_request.remark = result.remark
        this.data.update_sales_order_request.currency = result.was_sold_in
        this.data.update_sales_order_request.exchange_rate = result.sold_against_rate
        this.data.update_sales_order_request.updated_exchange_rate = `${result.sold_against_rate}`
        this.data.update_sales_order_request.escrow_loendersloot = result.escrow_loendersloot
        this.data.update_sales_order_request.destination_artkey = result.destination_artkey
        this.data.update_sales_order_request.origin_artkey = result.origin_artkey
        this.sales_order_status = result.sales_order_status

        this.data.selected_destination_location = this.data.relation_locations.find((warehouse) => warehouse.artkey === result.destination_artkey)
        this.data.selected_origin_location = this.data.origin_locations.find((warehouse) => warehouse.artkey === result.origin_artkey)

        if (this.data.update_sales_order_request.incoterm === 'EXW'
            && this.data.update_sales_order_request.origin_artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY
            && this.data.update_sales_order_request.destination_artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY
        ) {
            this.data.release_at_loendersloot = true
            this.data.update_sales_order_request.origin_artkey = LOENDERSLOOT_WAREHOUSE_ARTKEY
            this.data.update_sales_order_request.destination_artkey = LOENDERSLOOT_WAREHOUSE_ARTKEY
            this.data.selected_origin_location = this.data.selected_destination_location
        }

        this.title = `Edit sales order ${result.reference}`
    }

    async fetch_relation(artkey: number) {
        const {result} = await api.get<GetRelationResponse>(`discover/relations/${artkey}`)
        this.data.selected_relations.splice(0, this.data.selected_relations.length)
        this.data.selected_relations.push(result)
        this.search_bar_control?.set_and_submit_search_text(result.name || '', true)
    }

    relation_loendersloot_warehouse() {
        return this.data.relation_locations.find(
            (warehouse) => warehouse.artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY,
        )
    }

    origin_loendersloot_warehouse() {
        return this.data.origin_locations.find(
            (warehouse) => warehouse.artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY,
        )
    }

    relation_has_loendersloot_warehouse(): boolean {
        return this.relation_loendersloot_warehouse() !== undefined
    }

    async on_selected_relations() {
        this.data.release_at_loendersloot = false

        if (this.data.selected_relations[0]) {
            this.data.update_sales_order_request.relation_artkey = this.data.selected_relations[0].artkey
            await this.fetch_relation_destinations(this.data.selected_relations[0].artkey)
            if (this.data.update_sales_order_request.destination_artkey) {
                const selected_warehouse = this.data.relation_locations.find((location) => location.artkey === this.data.update_sales_order_request.destination_artkey)
                this.data.selected_destination_location = selected_warehouse
            }
            else {
                const primary_warehouse = this.data.relation_locations.find((warehouse) => warehouse.is_primary)
                if (primary_warehouse) {
                    this.data.selected_destination_location = primary_warehouse
                }
                if (this.relation_has_loendersloot_warehouse() && this.edit_assist.is_creating) { // buyer has account at Loendersloot
                    this.data.release_at_loendersloot = true
                }
            }
            if (this.edit_assist.is_creating) {
                this.data.update_sales_order_request.includes_excise = this.data.selected_relations[0].include_excise_in_price
            }
            if (this.edit_assist.is_creating) {
                this.data.update_sales_order_request.currency = this.data.selected_relations[0].currency
            }
        } else {
            this.data.update_sales_order_request.relation_artkey = 0
            this.data.relation_locations.splice(0, this.data.relation_locations.length)
            this.data.update_sales_order_request.currency = 'EUR'
            this.search_bar_control?.clear_search_text()
        }
    }

    on_loendersloot_release() {
        if (this.data.release_at_loendersloot) {
            this.data.selected_origin_location = this.relation_loendersloot_warehouse()
            this.data.selected_destination_location = this.origin_loendersloot_warehouse()
            logger.debug(`[CreateOrEditSalesOrder] on_loendersloot_release ${this.data.selected_origin_location?.artkey} => ${this.data.selected_destination_location?.artkey}`)
            this.data.update_sales_order_request.freight_instruction = ''
            this.data.update_sales_order_request.warehouse_instruction = ''
            this.data.update_sales_order_request.incoterm = 'EXW'
        }
        else {
            // Set origin to Loendersloot warehouse.
            this.data.selected_origin_location = this.origin_loendersloot_warehouse()
            logger.debug(`[CreateOrEditSalesOrder] on_loendersloot_release ${this.data.selected_origin_location?.artkey} => ...`)
        }

    }

    update_incoterm(): void {
        const selected_incoterm = this.data.update_sales_order_request.incoterm
        this.data.update_sales_order_request.incoterm_location = ''
        if ('EXW' === selected_incoterm) {
            this.data.selected_destination_location = this.data.relation_locations.find((warehouse) => warehouse.artkey === this.data.selected_origin_location?.artkey)
        }
        if (['EXW', 'FCA'].includes(selected_incoterm)) {
            if (this.data.selected_origin_location) {
                const country = country_for_country_code(this.data.selected_origin_location?.country_code || '')
                this.data.update_sales_order_request.incoterm_location = `${this.data.selected_origin_location?.city} - ${country}`
            }
        } else if (['DAP', 'DDP', 'CFR', 'CIF'].includes(selected_incoterm)) {
            if (this.data.selected_destination_location) {
                const country = country_for_country_code(this.data.selected_destination_location?.country_code || '')
                this.data.update_sales_order_request.incoterm_location = `${this.data.selected_destination_location?.city} - ${country}`
            }
        }
        this.update_message()
    }

    update_message() {
        this.data.destination_message = ''
        if (this.data.update_sales_order_request.incoterm === 'EXW') {
            if (this.data.selected_destination_location?.artkey !== this.data.selected_origin_location?.artkey) {
                this.data.destination_message = 'Incoterm is EXW but destination and origin do not match.'
            }
        }
    }

    create_or_update_sales_order(): boolean {
        if ('EXW' === this.data.update_sales_order_request.incoterm &&
            this.data.selected_destination_location?.artkey !== this.data.selected_origin_location?.artkey
        ) {
            notifier.notify('With EXW destination should match the origin', 'warning')
            return false
        }

        logger.debug('[CreateOrEditSalesOrder] origin/destination locations', this.data.update_sales_order_request.origin_artkey, this.data.update_sales_order_request.destination_artkey)

        if (this.data.update_sales_order_request.estimated_loading_date) {
            this.data.update_sales_order_request.estimated_loading_date = DateTime.fromISO(
                this.data.update_sales_order_request.estimated_loading_date,
            ).toISO()
        }
        const update_request = {
            destination_artkey: this.data.update_sales_order_request.destination_artkey,
            origin_artkey: this.data.update_sales_order_request.origin_artkey,
            insurance_type_artkey: this.data.update_sales_order_request.insurance_type_artkey,
            ...this.data.update_sales_order_request,
            exchange_rate: +this.data.update_sales_order_request.updated_exchange_rate,
        }
        if (this.edit_assist.is_creating) {
            this.sales_api.create_sales_order(update_request)
                .subscribe((response) => this.onsaved(response.artkey))
        } else if (this.edit_assist.artkey) {
            this.sales_api.update_sales_order(this.edit_assist.artkey, update_request)
                .subscribe(() => this.onsaved(this.edit_assist.artkey))
        }
        return true
    }

    /** Sales order fields are disabled if status = INVOICED or there is no supplier selected.*/
    is_disabled(): boolean {
        return this.data.selected_relations.length === 0 || this.sales_order_status === 'Invoiced' || this.sales_order_status === 'Cancelled'
    }

    view(): m.Children {
        if (this.is_loading) {
            return <Spinner/>
        }

        return (
            <div className="form c-sales-order-edit">
                <div className="fieldset-group">

                    <div className="fieldset">
                        <div className="field">
                            <RelationsSearch
                                label={'Relation'}
                                selected_relations={this.data.selected_relations}
                                selected_relation_name={this.data.selected_relations[0]?.name}
                                only_buyers={true}
                                search_bar_controller={(controller: SearchBarControl) =>
                                    (this.search_bar_control = controller)
                                }
                                is_single_select={true}
                            />
                            <EditableRelationList selected_relations={this.data.selected_relations}/>
                        </div>
                        <div className="field-group">
                            <FieldCheckbox
                                disabled={this.is_disabled()}
                                help="Activate Loendersloot warehouse on the relation edit page to enable release."
                                label={'Release goods at Loendersloot'}
                                model={[this.data, 'release_at_loendersloot']}
                            />
                        </div>
                        {this.data.origin_locations.length && <FieldSelect
                            disabled={this.is_disabled() || this.data.release_at_loendersloot}
                            label="Origin warehouse"
                            model={[this.data.update_sales_order_request, 'origin_artkey']}
                            onchange={(value) => {
                                this.data.selected_origin_location = this.data.origin_locations.find((warehouse) => warehouse.artkey === +value)
                            }}
                            options={this.data.origin_locations.map((i) => ({
                                value: i.artkey,
                                label: `${i.name} - ${i.city} (${i.country_code})`,
                            }))}
                            placeholder="Select origin warehouse..."
                            validation={this.$v.origin_artkey}
                        />}
                        <FieldSelect
                            disabled={this.is_disabled() || this.data.release_at_loendersloot}
                            help={'Destinations can be added or changed on the edit page of the relation. With EXW it should match the origin warehouse.'}
                            label="Destination"
                            model={[this.data.update_sales_order_request, 'destination_artkey']}
                            onchange={(value) => {
                                this.data.selected_destination_location = this.data.relation_locations.find((warehouse) => warehouse.artkey === +value)
                            }}
                            options={this.data.relation_locations.map((i) => ({
                                value: i.artkey,
                                label: `${i.name} - ${i.city} (${i.country_code})`,
                            }))}
                            placeholder={'Select a destination...'}
                            validation={this.$v.destination_artkey}
                        />

                        <FieldSelect
                            disabled={this.is_disabled()}
                            label="Currency"
                            model={[this.data.update_sales_order_request, 'currency']}
                            options={$s.currencies.all.map((i) => ({label: i, value: i}))}
                            validation={this.$v.currency}
                        />

                        {this.data.update_sales_order_request.currency !== 'EUR' && <FieldText
                            label="Exchange rate"
                            model={[this.data.update_sales_order_request, 'updated_exchange_rate']}
                        />}

                        <FieldSelect
                            label="Insurance type"
                            disabled={this.is_disabled()}
                            model={[this.data.update_sales_order_request, 'insurance_type_artkey']}
                            options={this.data.insurance_type_options.map((i) => ({value: i.artkey, label: i.name}))}
                            placeholder=""
                            validation={this.$v.insurance_type_artkey}
                        />

                        <FieldCheckbox
                            disabled={this.is_disabled() || this.data.selected_origin_location?.artkey !== LOENDERSLOOT_WAREHOUSE_ARTKEY}
                            label={'Escrow at Loendersloot'}
                            model={[this.data.update_sales_order_request, 'escrow_loendersloot']}
                        />

                        <FieldCheckbox
                            label={'Includes excise and VAT'}
                            disabled={this.is_disabled()}
                            model={[this.data.update_sales_order_request, 'includes_excise']}
                        />

                        <FieldTextArea
                            label="Remarks"
                            disabled={this.is_disabled()}
                            model={[this.data.update_sales_order_request, 'remark']}
                        />
                        <ButtonGroup>
                            <Button
                                icon="stop"
                                onclick={() => {
                                    if (this.edit_assist.is_creating) {
                                        m.route.set('/sales-orders')
                                    }
                                    else {
                                        m.route.set(`/sales-orders/manage/${this.edit_assist.artkey}`)
                                    }
                                }}
                                text="Cancel"
                            />
                            <Button
                                disabled={invalid_fields(this.$v).length > 0}
                                icon="save"
                                onclick={async() => await this.create_or_update_sales_order()}
                                text={this.edit_assist.is_creating ? 'Create sales order' : 'Update sales order'}
                                tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                                type="success"
                            />
                        </ButtonGroup>

                    </div>

                    <div className="fieldset">
                        {/* Summary of the IncoTerm */}
                        <div className="transport-summary mb-2">
                            {this.data.selected_origin_location &&
                                    <div className="transport">
                                        {icon_for_destination_type(this.data.selected_origin_location.destination_type)}
                                        <div className="details">
                                            <div>{this.data.selected_origin_location.name}</div>
                                            <div>{this.data.selected_origin_location.street_address}</div>
                                            <div>{this.data.selected_origin_location.city}{' '}
                                                <Country country_code={this.data.selected_origin_location.country_code}/>
                                            </div>
                                        </div>
                                    </div>}

                            <div className="transport">
                                <span className={'glyphicon glyphicon-arrow-right'}/>
                                <div className="details">
                                    <div>{this.data.update_sales_order_request.incoterm} - {this.data.update_sales_order_request.incoterm_location}</div>
                                </div>
                            </div>

                            {this.data.selected_destination_location &&
                                    <div className="transport">
                                        {icon_for_destination_type(this.data.selected_destination_location.destination_type)}
                                        <div className="details">
                                            <div>{this.data.selected_destination_location.name}</div>
                                            <div>{this.data.selected_destination_location.street_address}</div>
                                            <div>{this.data.selected_destination_location.city}{' '}
                                                <Country country_code={this.data.selected_destination_location.country_code}/>
                                            </div>
                                        </div>
                                    </div>}
                        </div>

                        <FieldSelect
                            disabled={this.is_disabled() || this.data.release_at_loendersloot}
                            help={this.data.destination_message}
                            label={'Incoterm'}
                            model={[this.data.update_sales_order_request, 'incoterm']}
                            options={this.data.incoterm_options.map((i) => ({
                                label: i.code,
                                value: i.code,
                            }))}
                            validation={this.$v.incoterm}
                        />

                        <FieldText
                            disabled={this.is_disabled() || this.data.release_at_loendersloot}
                            label={'Incoterm location'}
                            model={[this.data.update_sales_order_request, 'incoterm_location']}
                            validation={this.$v.incoterm_location}
                        />

                        <FieldDate
                            label={'Estimated loading date'}
                            disabled={this.is_disabled()}
                            ref={[this.data.update_sales_order_request, 'estimated_loading_date']}
                        />

                        {!this.data.release_at_loendersloot && <FieldText
                            disabled={this.is_disabled()}
                            label={'Warehouse instruction'}
                            model={[this.data.update_sales_order_request, 'warehouse_instruction']}
                        />}

                        {!this.data.release_at_loendersloot && <FieldText
                            disabled={this.is_disabled()}
                            label={'Freight instruction'}
                            model={[this.data.update_sales_order_request, 'freight_instruction']}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}
