/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {stopPropagation} from '@/_utils'

interface ModalAttrs {
    persistent?: boolean
    width?: string
    onclose?: () => void
    title?: string
    footer?: m.Children
}

export class Modal extends MithrilTsxComponent<ModalAttrs> {
    persistent: boolean
    width?: string
    close: () => void

    constructor(vnode: m.CVnode<ModalAttrs>) {
        super()
        this.persistent = vnode.attrs.persistent || false
        this.width = vnode.attrs.width
        this.close = vnode.attrs.onclose || (() => {})
    }

    overlay_click = () => {
        if (!this.persistent) {
            this.close()
        }
    }

    key_handler = (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
            this.close()
        }
    }

    c_modal_attrs = () => {
        const attrs: any = {
            onmouseup: stopPropagation(() => {}),
        }
        if (this.width) {
            attrs.style = `width: ${this.width}`
        }
        return attrs
    }

    oncreate() {
        if (!this.persistent) {
            document.body.addEventListener('keyup', this.key_handler)
        }
    }

    onremove() {
        if (!this.persistent) {
            document.body.removeEventListener('keyup', this.key_handler)
        }
    }

    view(vnode: m.CVnode<ModalAttrs>) {
        return [
            <div class="c-modal-overlay"/>,
            <div class="l-modal-container" onmouseup={stopPropagation(this.overlay_click)}>
                <div class="c-modal" {...this.c_modal_attrs()}>
                    <div class="c-modal-title">
                        {!this.persistent &&
                            <button
                                type="button"
                                class="close"
                                onclick={this.close}
                            >
                                {vnode.attrs.onclose && <span>×</span>}
                            </button>
                        }
                        <h4>{vnode.attrs.title}</h4>
                    </div>
                    <div class="c-modal-body">
                        {vnode.children}
                    </div>
                    {vnode.attrs.footer &&
                        <div class="c-modal-footer">
                            {vnode.attrs.footer}
                        </div>
                    }
                </div>
            </div>,
        ]
    }
}
