import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$t, notifier} from '@bitstillery/common/app'

import {Modal} from '@/components/modal/modal'
import {DefaultButton} from '@/components/buttons'
import {UserManagementApi} from '@/factserver_api/user_management_api'

export default class OtpDialog extends MithrilTsxComponent<any> {

    api = new UserManagementApi()

    reset_user_otp(vn: m.Vnode): void {
        if (!vn.attrs.update_user_request.otp_validated) {
            notifier.notify('The user does not have an OTP set yet', 'warning')
            return
        }

        this.api.reset_user_otp(vn.attrs.update_user_request.artkey).subscribe({
            next: () => {
                notifier.notify('Users OTP has been reset', 'success')
                vn.attrs.update_user_request.otp_validated = false
                m.redraw()
            },
            error: () => {
                notifier.notify('Unable to reset users OTP, please try again', 'danger')
                m.redraw()
            },
        })
    }

    view(vn) {
        if (!vn.attrs.show[0][vn.attrs.show[1]]) return
        return (
            <Modal title={'Reset OTP'} onclose={() => (this.show_reset_otp_modal = false)}>
                <p>
                    {$t('profile.otp.description')}
                </p>

                <div className={'btn-toolbar'}>
                    <DefaultButton
                        title={$t('profile.actions.reset_otp')}
                        icon_class={'glyphicon glyphicon-refresh'}
                        additional_class={'btn-danger'}
                        onclick={() => {
                            this.reset_user_otp(vn)
                            vn.attrs.show[0][vn.attrs.show[1]] = false
                        }}
                    />
                    <DefaultButton
                        title={$t('profile.actions.cancel')}
                        onclick={() => {
                            vn.attrs.show[0][vn.attrs.show[1]] = false
                        }}
                    />
                </div>
            </Modal>
        )
    }
}
