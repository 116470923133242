/** llm:tested */
import m from 'mithril'
import {sortBy} from 'prelude-ls'
import {filter, toArray, mergeAll} from 'rxjs/operators'
import {FieldMoney} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {api} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import * as api_ls from '@/api'
import {OfferItems} from '@/pricelist/components/items'
import {uniqueId} from '@/_utils'
import {icon_button, text_button} from '@/components/_buttons'
import * as inputs from '@/components/inputs'
import {BottleMarket} from '@/components/market_info/bottle_market'
import {BottlePurchaseOrders} from '@/components/market_info/bottle_purchase_orders'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {BottleStock} from '@/components/market_info/bottle_stock'
import {OfferHistory} from '@/components/market_info/offer_history'
import {Product} from '@/models/products'
import {item_tags_editor} from '@/stock/components/item_tags_editor'
import {RelationDropDown} from '@/components/relation'
import {RelationDropDownData} from '@/factserver_api/relation_api'
import {AutocompleteInput} from '@/components/collection/autocomplete_input'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {CustomOfferItem} from '@/models/offers'
import {Bottle} from '@/models/bottles'
import {$m, $s} from '@/app'

export class EditCustomOfferItem extends MithrilTsxComponent<unknown> {
    private product_categories: any
    private custom_offer_item_instance: any
    private selected_tbo_supplier_artkey: any
    private offer_artkey: string
    private always_special: any
    private supplier_artkey: string
    private onsaved: Function
    private bottle: any
    private custom_offer_item: any
    private creating: any
    private error_messages: any
    private focus_product_input: any
    private new_spec: any
    private override_quantity: any
    private override_price: any
    private saving: any
    private product_management_api: ProductManagementApi
    private current_selected_simple_product: any
    private current_search_text: string | null
    private search_bar_controller: any
    private price_per_case_input_key: string
    private purchase_price_per_case_input_key: string

    constructor(vnode: m.Vnode<any, any>) {
        super()
        this.product_categories = window.prop([])

        this.custom_offer_item_instance = vnode.attrs.custom_offer_item_instance
        this.selected_tbo_supplier_artkey = window.prop('')
        this.offer_artkey = vnode.attrs.offer_artkey
        this.always_special = vnode.attrs.always_special
        this.supplier_artkey = vnode.attrs.supplier_artkey
        this.onsaved = vnode.attrs.onsaved || (() => {})

        this.bottle = window.prop(new Bottle())
        this.custom_offer_item = window.prop(new CustomOfferItem())

        this.creating = window.prop(true)
        this.error_messages = window.prop([])
        this.focus_product_input = window.prop(true)
        this.new_spec = window.prop(true)
        this.override_quantity = window.prop(false)
        this.override_price = window.prop(false)
        this.saving = window.prop(false)

        this.product_management_api = new ProductManagementApi()
        this.current_selected_simple_product = null
        this.current_search_text = null
        this.search_bar_controller = null

        this.price_per_case_input_key = uniqueId()

        if (this.custom_offer_item_instance) {
            this.creating = window.prop(false)
            this.custom_offer_item(this.custom_offer_item_instance)

            this.bottle().product().name(this.custom_offer_item().name())
            this.bottle().product().category(this.custom_offer_item().category())
            this.bottle().volume(this.custom_offer_item().volume())
            this.bottle().alcohol_percentage(this.custom_offer_item().alcohol_percentage())
            this.bottle().refill_status(this.custom_offer_item().refill_status().toLowerCase())

            if (this.custom_offer_item().custom_quantity() !== null) {
                this.override_quantity(true)
            }

            if (this.custom_offer_item().custom_price_per_case() !== null) {
                this.override_price(true)
                this.custom_offer_item().price_per_case(this.custom_offer_item().custom_price_per_case())
            }
        } else {
            this.custom_offer_item().currency($s.currencies.default)
            this.custom_offer_item().purchase_currency($s.currencies.default)
        }
    }

    async oncreate() {
        await this.get_categories()
    }

    async get_categories() {
        const {result} = await api.get('discover/product-categories/select')
        this.product_categories(result.map((it: any) => it.name))
    }

    check_can_save() {
        this.error_messages([])

        if (!this.bottle().product().artkey()) {
            if (!this.bottle().product().name()) {
                this.error_messages().push('Please enter a product name.')
            }
            if (!this.bottle().product().category()) {
                this.error_messages().push('Please enter a category.')
            }
        }

        if (this.new_spec()) {
            if (!(+this.bottle().volume() >= 1)) {
                this.error_messages().push('Please enter a size of 1 or greater.')
            }
            if (this.bottle().alcohol_percentage() === '' || !(0 <= +this.bottle().alcohol_percentage() && +this.bottle().alcohol_percentage() <= 100)) {
                this.error_messages().push('Please enter an alcohol percentage between 0 and 100.')
            }
        } else {
            if (!this.bottle().artkey()) {
                this.error_messages().push('Please select specs or create new specs.')
            }
        }

        if (!(+this.custom_offer_item().number_of_bottles() >= 1)) {
            this.error_messages().push('Please enter a number of bottles per case (Btl / cs) of 1 or greater.')
        }

        if ((this.is_manual() || this.override_price()) && !this.custom_offer_item().price_per_case()) {
            this.error_messages().push('Please enter a sales price.')
        }

        if (this.is_manual()) {
            if ((this.custom_offer_item().delivery_period() === null || !(+this.custom_offer_item().delivery_period() >= 1))) {
                this.error_messages().push('Please enter a delivery period (weeks) of 1 or greater. Manually created custom offer items may only be TBOs.')
            }
            if (!this.custom_offer_item().supplier().artkey()) {
                this.error_messages().push('Please select a TBO supplier. Manually created custom offer items may only be TBOs.')
            }
        } else if (this.custom_offer_item().offer_item_type() === 'tbo') {
            if ((this.custom_offer_item().delivery_period() === null || !(+this.custom_offer_item().delivery_period() >= 1))) {
                this.error_messages().push('Please enter a delivery period (weeks) of 1 or greater. This is a TBO offer item.')
            }
            if (!this.custom_offer_item().supplier().artkey()) {
                this.error_messages().push('Please select a TBO supplier. This is a TBO offer item.')
            }
        }

        if ((this.is_manual() || this.override_quantity()) && !(+this.custom_offer_item().quantity() >= 1)) {
            this.error_messages().push('Please enter a quantity (Qty (cs)) of 1 or greater.')
        }

        if (!this.custom_offer_item().customs_status()) {
            this.error_messages().push('Please select a customs status.')
        }

        if (this.is_manual() && !(this.custom_offer_item().purchase_currency() && this.custom_offer_item().purchase_price_per_case())) {
            this.error_messages().push('Please enter a purchase price.')
        }

        return this.error_messages().length === 0
    }

    create_new_specs() {
        this.new_spec(true)
        const current_product = this.bottle().product()
        this.bottle(new Bottle())
        this.bottle().product(current_product)
    }

    known_product() {
        return this.current_selected_simple_product !== null
    }

    is_editing() {
        return this.custom_offer_item().artkey() !== ''
    }

    is_manual() {
        return !this.custom_offer_item().offer_item_artkey()
    }

    is_stock_item_from_pricelist() {
        return (!this.is_manual()) && this.custom_offer_item().offer_item_type && ['stock', 'purchase'].includes(this.custom_offer_item().offer_item_type())
    }

    bottle_market_data() {
        const data: any = {
            bottle_artkey: this.custom_offer_item().bottle_artkey(),
            custom_offer_item: this.custom_offer_item,
        }

        if (this.custom_offer_item().offer_item_type() === 'tbo') {
            data['current_supplier_artkey'] = this.custom_offer_item().supplier().artkey()
        }

        return data
    }

    match_data() {
        const data: any = {
            bottle_artkey: this.custom_offer_item().bottle_artkey(),
            case_artkey: this.custom_offer_item().case_artkey(),
            case_customs_status: this.custom_offer_item().customs_status(),
            item_best_before_date: this.custom_offer_item().best_before_date(),
        }

        if (this.custom_offer_item().offer_item_type() !== 'tbo') {
            data['item_tags_sorted_artkeys'] = this.custom_offer_item().item_tags_sorted_artkeys()
        }

        return data
    }

    async after_update_product(simple_product: any) {
        this.current_selected_simple_product = simple_product
        if (simple_product) {
            const {result: bottles} = await api.get(`discover/products/${simple_product.artkey}/bottles`)
            simple_product.bottles = bottles

            this.bottle().product().artkey(simple_product.artkey)
            this.bottle().product().name(simple_product.name)
            this.bottle().product().category(simple_product.product_category.name)
            this.bottle().product().bottles(simple_product.bottles.map((bottle: any) => {
                bottle = new Bottle(
                    bottle.artkey,
                    bottle.volume,
                    bottle.alcohol_percentage,
                    bottle.refill_status,
                    simple_product.artkey,
                )
                bottle.product = window.prop({
                    name: window.prop(simple_product.name),
                    artkey: window.prop(simple_product.artkey),
                    category: window.prop(simple_product.product_category.name),
                    bottles: window.prop([]),
                })
                return bottle
            }))

            this.new_spec(false)
            this.set_bottle(bottles[0].artkey)
        } else {
            this.bottle().product(new Product())
            this.bottle().product().name(this.current_search_text)
            this.new_spec(true)
        }

        m.redraw()
    }

    reset() {
        this.bottle(new Bottle())
        this.custom_offer_item(new CustomOfferItem())
        this.custom_offer_item().currency($s.currencies.default)
        this.custom_offer_item().purchase_currency($s.currencies.default)

        this.search_bar_controller.clear_search_text()
        this.error_messages([])
        this.focus_product_input(true)
        this.new_spec(true)

        this.price_per_case_input_key = uniqueId()
        this.purchase_price_per_case_input_key = uniqueId()
    }

    save() {
        this.saving(true)
        if (!this.check_can_save()) {
            this.saving(false)
            return
        }

        const data: any = {
            artkey: this.custom_offer_item().artkey(),
            offer_artkey: this.offer_artkey,
            remark: this.custom_offer_item().remark(),
            currency: $s.currencies.default,
            minimum_quantity: this.custom_offer_item().minimum_quantity(),
            maximum_quantity: this.custom_offer_item().maximum_quantity(),
            is_special: this.custom_offer_item().is_special(),
            purchase_price_per_case: this.custom_offer_item().purchase_price_per_case(),
            purchase_currency: this.custom_offer_item().purchase_currency(),
        }

        if (!this.is_stock_item_from_pricelist()) {
            data['supplier_artkey'] = this.custom_offer_item().supplier().artkey()
            data['delivery_period'] = this.custom_offer_item().delivery_period()
            data['name'] = this.bottle().product().name()
            data['category'] = this.bottle().product().category()
            data['volume'] = this.bottle().volume()
            data['alcohol_percentage'] = this.bottle().alcohol_percentage()
            data['refill_status'] = this.bottle().refill_status()
            data['number_of_bottles'] = this.custom_offer_item().number_of_bottles()
            data['gift_box_type'] = this.custom_offer_item().gift_box_type()
            data['tax_label'] = this.custom_offer_item().tax_label()
            data['customs_status'] = this.custom_offer_item().customs_status()
            data['best_before_date'] = this.custom_offer_item().best_before_date()
            data['item_tags'] = this.custom_offer_item().item_tags()
        }

        if (this.is_manual() || this.override_quantity()) {
            data['quantity'] = this.custom_offer_item().quantity()
        }

        if (this.is_manual() || this.override_price()) {
            data['price_per_case'] = this.custom_offer_item().price_per_case()
            data['currency'] = this.custom_offer_item().currency()
        }

        api_ls.callAndThen('offer.create_or_update_custom_offer_item', data, {
            success: (resp: any) => {
                this.saving(false)
                if (this.creating()) {
                    notifier.notify(`Successfully created new custom offer item for ${resp.name}.`, 'success')
                } else {
                    notifier.notify(`Successfully updated custom offer item for ${resp.name}.`, 'success')
                }
                $m.common.observable.broadcast('custom_offer_item_updated', 'A Custom Offer Item has been updated.')
                $m.common.observable.broadcast('offer_updated')
                this.reset()
            },
            failure: (resp: any) => {
                this.error_messages().push(resp.message)
            },
        })
    }

    set_bottle(bottle_artkey: string) {
        const bottle = this.current_selected_simple_product.bottles.find((bottle: any) => bottle.artkey === +bottle_artkey)
        this.bottle().artkey(bottle.artkey)
        this.bottle().alcohol_percentage(bottle.alcohol_percentage)
        this.bottle().volume(bottle.volume)
        this.bottle().refill_status(bottle.refill_status)
    }

    set_focus(vnode: m.VnodeDOM) {
        if (this.focus_product_input()) {
            $(vnode.dom).focus()
            this.focus_product_input(false)
        }
    }

    show_rate() {
        if (this.custom_offer_item().currency() !== $s.currencies.default) {
            return <small> (rate: {$s.currencies.exchange_rates[this.custom_offer_item().currency()].portal_rate})</small>
        }
    }

    view() {
        return <div class="c-edit-custom-offer-item">
            <div class="fieldset largest">
                <div class="field-group">
                    <AutocompleteInput
                        label={this.known_product() ? 'Product (known)' : 'Product'}
                        placeholder="Select a product..."
                        on_get_suggestions$={(filter_text: string) => this.product_management_api.get_simple_products(filter_text)}
                        suggestion_as_label={(simple_product: any) => simple_product.name}
                        on_selected={(simple_product: any) => {
                            if (simple_product) {
                                this.after_update_product(simple_product)
                            }
                        }}
                        default_text={this.bottle().product().name() || ''}
                        required={true}
                        on_new_search_text={(text: string) => {
                            this.current_search_text = text
                            this.after_update_product(null)
                        }}
                        search_bar_controller={(sbc: any) => this.search_bar_controller = sbc}
                        disabled={this.is_stock_item_from_pricelist()}
                    />

                    {inputs.select(this.bottle().product().category, this.product_categories(), {
                        label: 'Category',
                        required: true,
                        empty_option: true,
                        disabled: this.known_product() || this.is_stock_item_from_pricelist(),
                    })}

                    <div class={classes({hidden: this.new_spec()})}>
                        <div class="field">
                            <label>Specs</label>
                            <div class="control">
                                <select
                                    required={true}
                                    onchange={(ev: Event) => {
                                        this.set_bottle((ev.target as HTMLSelectElement).value)
                                    }}
                                    disabled={this.is_stock_item_from_pricelist()}
                                >
                                    {this.bottle().product() &&
                                        sortBy((obj: any) => +obj.volume(), this.bottle().product().bottles())
                                            .map((bottle: any) =>
                                                <option
                                                    value={bottle.artkey()}
                                                    selected={bottle.artkey() === this.bottle().artkey()}
                                                >
                                                    {bottle.to_specs()}
                                                </option>,
                                            )
                                    }
                                </select>

                                {icon_button('plus', {
                                    class: 'btn-default form-control',
                                    tabindex: -1,
                                    onclick: () => this.create_new_specs(),
                                    disabled: this.is_stock_item_from_pricelist(),
                                })}
                            </div>
                        </div>
                    </div>

                    {inputs.number(this.bottle().volume, {
                        classNames: {hidden: !this.new_spec()},
                        label: 'Size in cl',
                        min: 1,
                        step: '0.1',
                        required: true,
                        disabled: this.is_stock_item_from_pricelist(),
                    })}

                    {inputs.number(this.bottle().alcohol_percentage, {
                        classNames: {hidden: !this.new_spec()},
                        label: 'Alcohol %',
                        min: 0,
                        max: 100,
                        step: '0.1',
                        required: true,
                        disabled: this.is_stock_item_from_pricelist(),
                    })}

                    <div class={classes({hidden: !this.new_spec()}, 'field')}>
                        <label>Refill</label>
                        {inputs.refill_status(this.bottle().refill_status, {
                            required: true,
                            disabled: this.is_stock_item_from_pricelist(),
                        })}
                    </div>

                    {inputs.gift_box_type(this.custom_offer_item().gift_box_type, {
                        disabled: this.is_stock_item_from_pricelist(),
                        label: 'Gift box type',
                    })}

                    {inputs.date(this.custom_offer_item().best_before_date, {
                        disabled: this.is_stock_item_from_pricelist(),
                        label: 'Best before date',
                    })}
                </div>

                <div class="field-group">
                    {inputs.number(this.custom_offer_item().number_of_bottles, {
                        label: 'Bottles per case',
                        min: 1,
                        required: true,
                        disabled: this.is_stock_item_from_pricelist(),
                    })}

                    <div class="field">
                        <label>Sales price {this.override_price() && this.show_rate()}</label>
                        <div class="control">
                            {this.is_manual() ? (
                                <FieldMoney
                                    change_currency={true}
                                    currency={[this.custom_offer_item(), 'currency']}
                                    model={[this.custom_offer_item(), 'price_per_case']}
                                    required={true}
                                />
                            ) : (
                                <span>
                                    <FieldMoney
                                        change_currency={true}
                                        currency={[this.custom_offer_item(), 'currency']}
                                        disabled={!this.override_price()}
                                        model={[this.custom_offer_item(), 'price_per_case']}
                                        required={true}
                                    />
                                    {icon_button(this.override_price() ? 'ban-circle' : 'edit', {
                                        class: 'btn-default',
                                        onclick: () => {
                                            this.override_price(!this.override_price())
                                        },
                                        title: this.override_price() ?
                                            'Click to follow the offer item\'s price.' :
                                            'Click to override the price of the original offer item.',
                                    })}
                                </span>
                            )}
                        </div>
                    </div>

                    <div class="field">
                        <label>Purchase price</label>
                        <div class="control">
                            <FieldMoney
                                change_currency={true}
                                currency={[this.custom_offer_item(), 'purchase_currency']}
                                model={[this.custom_offer_item(), 'purchase_price_per_case']}
                                required={this.is_manual()}
                            />
                        </div>
                    </div>

                    {inputs.number(this.custom_offer_item().delivery_period, {
                        label: 'Delivery period (weeks)',
                        min: 1,
                        required: this.is_manual(),
                        disabled: this.is_stock_item_from_pricelist(),
                    })}

                    {this.is_manual() ? (
                        inputs.number(this.custom_offer_item().quantity, {
                            label: 'Quantity (cases)',
                            min: 1,
                            required: true,
                        })
                    ) : (
                        <div class="field">
                            <label>Offer item quantity</label>
                            <div class="control">
                                {this.override_quantity() ? (
                                    inputs.number(this.custom_offer_item().quantity)
                                ) : (
                                    inputs.number(this.custom_offer_item().offer_item_quantity, {
                                        disabled: true,
                                    })
                                )}

                                {icon_button(this.override_quantity() ? 'ban-circle' : 'edit', {
                                    class: 'btn-default',
                                    onclick: () => {
                                        this.override_quantity(!this.override_quantity())
                                    },
                                    title: this.override_quantity() ?
                                        'Click to follow the offer item\'s quantity.' :
                                        'Click to override the quantity of the original offer item.',
                                })}
                            </div>
                        </div>
                    )}

                    {inputs.number(this.custom_offer_item().minimum_quantity, {
                        label: 'MOQ (cs)',
                        min: 1,
                    })}

                    {inputs.number(this.custom_offer_item().maximum_quantity, {
                        label: 'Max Q (cs)',
                        min: 1,
                    })}
                </div>

                {$m.data.item_tag.tags().length &&
                    item_tags_editor(this.custom_offer_item, {
                        editable_country_of_origin: false,
                        only_tags: true,
                        disabled: this.is_stock_item_from_pricelist(),
                    })
                }

                <div class="field-group no-fill">
                    <RelationDropDown
                        disabled={this.is_stock_item_from_pricelist()}
                        get_all_for_drop_down_response$={RelationDropDownData.relations().pipe(
                            mergeAll(),
                            filter((relation: any) => relation.is_supplier),
                            toArray(),
                        )}
                        label="TBO supplier"
                        model={[this.custom_offer_item().supplier(), 'artkey']}
                        onchange={(supplier_artkey: string) => this.custom_offer_item().supplier().artkey(supplier_artkey)}
                        required={this.is_manual()}
                    />

                    {inputs.customs_status(this.custom_offer_item().customs_status, {
                        label: 'Customs status',
                        required: true,
                        disabled: this.is_stock_item_from_pricelist(),
                    })}

                    {inputs.tax_label(this.custom_offer_item().tax_label, {
                        disabled: this.is_stock_item_from_pricelist(),
                        label: 'Tax Label',
                    })}

                    {inputs.text(this.custom_offer_item().remark, {
                        label: 'Remark (internal)',
                        placeholder: 'Remark',
                    })}

                    {inputs.checkbox(this.custom_offer_item().is_special, {
                        checked: () => this.always_special(),
                        disabled: () => this.always_special(),
                        label: 'Special offer',
                    })}
                </div>

                {this.error_messages().length > 0 && (
                    <div class="error-messages alert alert-danger animated fadeInUp">
                        <ul>
                            {this.error_messages().map((message: string) =>
                                <li>{message}</li>,
                            )}
                        </ul>
                    </div>
                )}

                {text_button(`${this.is_editing() ? 'Update' : 'Add'} custom offer item`, {
                    class: 'btn-success btn-submit',
                    disabled: this.saving(),
                    onclick: () => this.save(),
                })}
            </div>

            {this.is_editing() && (
                <div class="panel-body">
                    <div class="columns is-multiline">
                        <div class="column is-6">
                            <OfferItems
                                offer_item_artkey={this.custom_offer_item().offer_item_artkey()}
                            />
                        </div>
                        <div class="column is-6">
                            <OfferHistory
                                bottle_artkey={this.custom_offer_item().bottle_artkey()}
                                customs_status={this.custom_offer_item().customs_status()}
                                supplier_artkey={this.supplier_artkey()}
                            />
                        </div>
                        <div class="column is-12">
                            <BottleMarket {...this.bottle_market_data()} />
                            <BottleStock {...this.match_data()} />
                        </div>
                        <div class="column is-6">
                            <BottlePurchaseOrders {...this.match_data()} />
                        </div>
                        <div class="column is-6">
                            <BottleSalesOrders {...this.match_data()} />
                        </div>
                        <div class="column is-12">
                            {this.supplier_artkey() ? (
                                <BottleSalesOrders
                                    bottle_artkey={this.custom_offer_item().bottle_artkey()}
                                    current_client_artkey={this.supplier_artkey()}
                                />
                            ) : (
                                <p>No relation selected to show sales orders for.</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    }
}
