/** llm:tested */
import api from '@/api'

interface VatCheckResponse {
    valid: boolean
    vat_id: string
    country_code: string
}

interface VatCheckData {
    vat_id: string
    country_code: string
    prod_mode: boolean
}

/**
 * Perform an API request to check the VAT ID.
 * The first two parameters are supposed to be passed in from the caller, to be able to report the results.
 */
export const check_vat_id = (
    result_prop: (value?: VatCheckResponse) => void,
    loading_prop: (value: boolean) => boolean,
    valid_prop: (value: boolean) => void,
    country_code: string,
    vat_id: string,
    prod_mode = true,
): ((value: boolean) => boolean) => {
    loading_prop(true)

    const data: VatCheckData = {
        vat_id,
        country_code,
        prod_mode,
    }

    api.callAndThen('vatid.check', data, {
        success: (resp: VatCheckResponse) => {
            if (result_prop) {
                result_prop(resp)
                result_prop().vat_id = vat_id
                result_prop().country_code = country_code
            }
            if (valid_prop) {
                valid_prop(resp.valid)
            }
        },
        final: () => {
            loading_prop(false)
        },
    })

    return loading_prop
}
