/** llm:tested */
import m from 'mithril'
import {reject} from 'prelude-ls'
import {Amount, Button, FieldMoney} from '@bitstillery/common/components'
import {Tippy} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {text, number, gift_box_type, tax_label} from '@/components/inputs'
import {callAndThen} from '@/api'
import {ifLet, pluralize, randomUuid, update} from '@/_utils'
import {item_tags_editor} from '@/stock/components/item_tags_editor'
import {text_button} from '@/components/_buttons'
import {Popover} from '@/components/popover/popover'
import {Item} from '@/models/stock'
import {$s} from '@/app'

export class InStockPurchaseOrderItem extends MithrilTsxComponent<unknown> {
    purchase_order_item: any
    item: any
    on_in_stock: any
    hide_from_pricelist_for_suppliers_selected: any
    hide_from_pricelist_for_countries_selected: any

    constructor(vnode: any) {
        super()
        this.purchase_order_item = vnode.attrs.purchase_order_item
        this.item = vnode.attrs.item
        if (this.item.loendersloot_inspection_item() && !this.item.lot()) {
            // Use data received from loendersloot to prefill values.
            this.item.lot(this.item.loendersloot_inspection_item().lot)
        }
        this.item.is_last = true // Keep track of the splitting, only last record has a save button.
        this.on_in_stock = vnode.attrs.on_in_stock
        this.hide_from_pricelist_for_suppliers_selected = window.prop(this.item.hide_from_pricelist_for_suppliers().map((item: any) => '' + item.artkey))
        this.hide_from_pricelist_for_countries_selected = window.prop(this.item.hide_from_pricelist_for_countries().map((item: any) => item.country_code))
    }

    oncreate() {
        m.redraw()
    }

    edit() {
        return !this.item.is_in_stock()
    }

    split_item() {
        const new_item = new Item(this.item.toObject())
        new_item.uuid = randomUuid()
        new_item.artkey(null)
        new_item.lot('')
        new_item.entry_date('')
        new_item.number_of_cases('')
        new_item.gift_box_type(this.item.gift_box_type())
        new_item.tax_label(this.item.tax_label())
        new_item.is_last = true

        this.purchase_order_item.items().forEach((item: any) => item.is_last = false)
        this.purchase_order_item.items().push(new_item)
    }

    remove_me() {
        update(this.purchase_order_item.items, reject((i: any) => {
            if (this.item.artkey()) {
                return i.artkey() === this.item.artkey()
            } else {
                return i.uuid === this.item.uuid
            }
        }))
    }

    cases_diff() {
        return this.purchase_order_item.total_number_of_cases_in_items() -
            this.purchase_order_item.number_of_cases()
    }

    is_last_item() {
        return this.item.is_last
    }

    can_delete() {
        return (
            !this.item.artkey() &&
            !this.item.is_in_stock() &&
            this.purchase_order_item.items().length > 1 &&
            this.item.sales_order_items.length === 0
        )
    }

    can_save() {
        return this.cases_diff() === 0 && this.purchase_order_item.items().every((item) =>
            +item.number_of_cases() > 0 &&
            item.lot() !== '' &&
            !item.lot().includes(' ') &&
            item.country_of_origin() !== null &&
            item.country_of_origin() !== '')
    }

    save() {
        const data = {
            artkey: this.purchase_order_item.artkey(),
            suggested_price_per_case: this.purchase_order_item.suggested_price_per_case(),
            items: this.purchase_order_item.items().map((item: any) => ({
                artkey: item.artkey(),
                gift_box_type: item.gift_box_type(),
                tax_label: item.tax_label(),
                number_of_cases: +item.number_of_cases(),
                lot: item.lot(),
                item_tags: item.item_tags(),
                best_before_date: item.best_before_date(),
                cases_per_pallet: item.cases_per_pallet() || this.purchase_order_item.cases_per_pallet(),
                cases_per_pallet_layer: item.cases_per_pallet_layer() || this.purchase_order_item.cases_per_pallet_layer(),
                remark: item.remark(),
                country_of_origin: item.country_of_origin(),
                hide_from_pricelist_for_suppliers: this.hide_from_pricelist_for_suppliers_selected().map((artkey: string) => +artkey),
                hide_from_pricelist_for_countries: this.hide_from_pricelist_for_countries_selected(),
            })),
        }

        callAndThen('purchase.core.instock_purchase_order_item', data, {
            success: (resp) => {this.handle_save(resp)},
        })
    }

    handle_save(resp: any) {
        if (!resp.success) {
            const msg = resp.message || 'Failed bringing the purchase order item in stock. Please try again later. If the problem persists, please contact us.'
            notifier.notify(msg, 'danger')
        } else {
            this.on_in_stock(resp.in_stockable)
            for (const item of this.purchase_order_item.items()) {
                item.is_in_stock(true)
            }
            notifier.notify('The purchase order item is now in stock.', 'success')
        }
    }

    remove_item() {
        if (this.item.artkey()) {
            // Disabled for now.
            notifier.notify('Item can\'t be removed. ' +
                   'If the problem persists, please contact us.', 'danger')
        } else {
            this.remove_me()
        }
    }

    fix_cases() {
        const new_cases = this.item.number_of_cases() - this.cases_diff()
        this.item.number_of_cases(new_cases < 1 ? 1 : new_cases)
    }

    view() {
        return [
            <tr name="search-table-row">
                <td>{this.purchase_order_item.bottle().product().name()}</td>
                <td>{this.purchase_order_item.number_of_bottles_per_case()}</td>
                <td>{this.purchase_order_item.bottle().to_specs()}</td>
                <td>
                    {this.edit()
                        ? gift_box_type(this.item.gift_box_type)
                        : this.item.gift_box_type()}
                </td>
                <td>
                    {this.edit()
                        ? tax_label(this.item.tax_label)
                        : this.item.tax_label()}
                </td>
                <td>
                    {this.purchase_order_item.number_of_cases() === this.purchase_order_item.total_number_of_cases_in_items()
                        ? this.purchase_order_item.number_of_cases()
                        : <Tippy content="An inconsistent number of cases between the purchase order item and all stock items.">
                            <div>{this.purchase_order_item.number_of_cases() + ' '}
                                <span class="glyphicon glyphicon-alert"></span>
                            </div>
                        </Tippy>
                    }
                </td>
                <td>
                    {this.edit()
                        ? <FieldMoney
                            currency={[$s.currencies, 'default']}
                            model={[this.purchase_order_item, 'suggested_price_per_case']}
                        />
                        : this.purchase_order_item.suggested_price_per_case()
                            ? <Amount
                                amount={this.purchase_order_item.suggested_price_per_case()}
                                currency={$s.currencies.default}
                            />
                            : '-'
                    }
                </td>
                <td>
                    {this.edit()
                        ? <div class="input-group">
                            {ifLet(this.cases_diff(), (diff) =>
                                <Popover
                                    title="Incorrect number of cases"
                                    content={diff_message(diff)}
                                    placement="bottom"
                                    max_width={200}
                                    always_show={true}
                                >
                                    <div class="input-group-btn">
                                        {text_button('Fix', {
                                            class: 'btn-warning',
                                            onclick: () => this.fix_cases(),
                                        })}
                                    </div>
                                </Popover>,
                            )}
                            {number(this.item.number_of_cases, {min: 1})}
                        </div>
                        : this.item.number_of_cases()
                    }
                </td>
                <td>
                    {this.edit()
                        ? text(this.item.lot, {
                            after_update: (ev: any) => this.item.lot(ev.target.value.replace(/\s/g, '')),
                        })
                        : this.item.lot()
                    }
                </td>
                <td>
                    <div class="btn-toolbar">
                        <div class="btn-group">
                            {this.is_last_item() && this.edit() &&
                                <Button
                                    type="success"
                                    className="success glyphicon glyphicon-ok"
                                    onclick={() => this.save()}
                                    disabled={!this.can_save()}
                                />}
                            {this.edit() &&
                                <Button
                                    type="default"
                                    icon="plus"
                                    text="Split"
                                    onclick={() => this.split_item()}
                                />
                            }
                            {this.can_delete() && (
                                <Button
                                    type="danger"
                                    icon="trash"
                                    onclick={() => this.remove_item()}
                                />
                            )}
                        </div>
                    </div>
                </td>
            </tr>,
            this.edit() &&
                <tr class="well">
                    <td colspan="100%">
                        <div class="panel panel-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <form class="form-horizontal">
                                        {item_tags_editor(this.item)}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>,
        ]
    }
}

function diff_message(diff: number): string {
    const cases = pluralize(diff, 'case', 'cases')
    return diff > 0
        ? `${diff} ${cases} too many`
        : `${-diff} ${cases} too few`
}
