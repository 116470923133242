/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {ZeroResultsGraph} from '@/data/diana/zero_results_graph'
import {StockPositionGraph} from '@/data/diana/stock_position_graph'
import {ItemSoldGraph} from '@/data/diana/item_sold_graph'
import {ItemStockedGraph} from '@/data/diana/item_stocked_graph'

const tab = (title: string, currently_showing: (value) => string, id: string) => {
    return (
        <li role="presentation" class={currently_showing() === id ? 'active' : ''}>
            {m(m.route.Link, {
                href: m.route.get(),
                onclick: (e) => {
                    e.preventDefault()
                    currently_showing(id)
                },
            }, title)}
        </li>
    )
}

export class DashboardDiana extends MithrilTsxComponent<any> {
    static SHOWING = {
        ZERO_RESULTS: 'ZERO_RESULTS',
        ITEM_SOLD: 'ITEM_SOLD',
        ITEM_STOCKED: 'ITEM_STOCKED',
        STOCK_POSITION: 'STOCK_POSITION',
        MAINTENANCE: 'MAINTENANCE',
    }

    currently_showing: () => string

    constructor() {
        super()
        this.currently_showing = window.prop(DashboardDiana.SHOWING.ZERO_RESULTS)
    }

    view() {
        return (
            <div class="c-diana view">
                <div class="row">
                    <ul class="nav nav-tabs pl-2 mb-2">
                        {tab('Zero results', this.currently_showing, DashboardDiana.SHOWING.ZERO_RESULTS)}
                        {tab('Cases sold', this.currently_showing, DashboardDiana.SHOWING.ITEM_SOLD)}
                        {tab('Cases stocked', this.currently_showing, DashboardDiana.SHOWING.ITEM_STOCKED)}
                        {tab('Stock position', this.currently_showing, DashboardDiana.SHOWING.STOCK_POSITION)}
                    </ul>
                    {this.currently_showing() === DashboardDiana.SHOWING.ZERO_RESULTS && (
                        <ZeroResultsGraph canvas_id="top-missed-searches-canvas" />
                    )}
                    {this.currently_showing() === DashboardDiana.SHOWING.ITEM_SOLD && (
                        <ItemSoldGraph canvas_id="item-sold-canvas" />
                    )}
                    {this.currently_showing() === DashboardDiana.SHOWING.ITEM_STOCKED && (
                        <ItemStockedGraph canvas_id="item-stocked-canvas" />
                    )}
                    {this.currently_showing() === DashboardDiana.SHOWING.STOCK_POSITION && (
                        <StockPositionGraph canvas_id="stock-position-canvas" />
                    )}
                </div>
            </div>
        )
    }
}
