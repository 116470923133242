import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'
import {FieldText} from '@bitstillery/common/components'

import {DefaultButton, SaveButton} from '@/components/buttons'
import {ProductOfInterest, ProductOfInterestApi} from '@/factserver_api/product_of_interest_api'

interface CreateOfferAttrs {
    product_of_interest: ProductOfInterest
    done: () => void
    cancel: () => void
}

export class CreateOffer extends MithrilTsxComponent<CreateOfferAttrs> {
    product_of_interest: ProductOfInterest
    done: () => void
    cancel: () => void

    product_of_interest_api = new ProductOfInterestApi()

    title: string
    remark: string
    saving = false

    constructor(vnode: m.Vnode<CreateOfferAttrs>) {
        super()

        this.product_of_interest = vnode.attrs.product_of_interest
        this.done = vnode.attrs.done
        this.cancel = vnode.attrs.cancel

        // Initialize form fields.
        this.title = this.product_of_interest.product_names
        if (this.product_of_interest.relation_artkeys.length === 1) {
            this.title += ` - ${this.product_of_interest.relation_names}`
        }
        this.remark = this.product_of_interest.reasons.join(', ')
    }

    /**
     * Check if the form values are valid for submission.
     *
     * @return {boolean}: true for a valid form, false otherwise.
     */
    is_valid(): boolean {
        return this.title !== '' && this.remark !== ''
    }

    /**
     * Create an offer for the selected product(s) of interest and relations.
     * Called when submitting the form.
     */
    create_offer(): void {
        if (!this.is_valid()) {
            return
        }

        this.saving = true

        this.product_of_interest_api
            .create_offer({
                title: this.title,
                remark: this.remark,
                offer_item_artkeys: this.product_of_interest.offer_item_artkeys,
                relation_artkeys: this.product_of_interest.relation_artkeys,
            })
            .subscribe({
                next: (result: number) => {
                    notifier.notify(
                        `Successfully created the offer ${this.title}. View it <a href='#!/offer/offers/${result}/custom_products/' target="_blank">here</a>.`,
                        'success',
                    )
                    this.done()
                },
                complete: () => {
                    this.saving = false
                    m.redraw()
                },
                error: () => {
                    this.saving = false
                    m.redraw()
                },
            })
    }

    view(): m.Children {
        return (
            <form className="flex-form" onsubmit={() => this.create_offer()}>
                <div className="fieldset">

                    <FieldText
                        label="Title"
                        model={[this, 'title']}
                    />

                    <FieldText
                        label="Remark"
                        model={[this, 'remark']}
                    />

                    <div className={'btn-toolbar'}>
                        <SaveButton title="Create offer" disabled={!this.is_valid() || this.saving} type="submit" />
                        <DefaultButton title="Cancel" onclick={() => this.cancel()} />
                    </div>
                </div>
            </form>
        )
    }
}
