import {current_account_slug} from '@bitstillery/common/account/account'
import {generate_transaction_id} from '@bitstillery/common/ts_utils'
import {from, Observable, of, throwError} from 'rxjs'
import {catchError, switchMap} from 'rxjs/operators'
import {fromFetch} from 'rxjs/fetch'
import {config, notifier} from '@bitstillery/common/app'

import {generic_handler} from '@/errors'
import {$m, $s} from '@/app'

/** Response from the factserver with the embedded result. */
export interface FactserverResponse<T> {
    success: boolean
    result: T
}

/** Shortcut for a api-response without data. */
export type FactserverEmptyResponse = FactserverResponse<void>

/** The base interface for all data that has to be present in the body. */
export interface FactserverRequestData {
    _account_slug?: string
}

export type FactserverEmtpyRequest = FactserverRequestData

/** Wrapper for the data to be sent to the factserver. */
export interface FactserverRequest<T extends FactserverRequestData> {
    data: T
}

export interface FactserverErrorResponse {
    message: string
    success: boolean
}

export interface FactserverJsonException {
    detail: string
}

class NotAuthorizedError {}
class ForbiddenError {}
class BadRequestError {
    detail$: Promise<FactserverJsonException>
    constructor(detail$: Promise<FactserverJsonException>) {
        this.detail$ = detail$
    }
}
class UnprocessableEntityError {
    detail$: Promise<FactserverJsonException>
    constructor(detail$: Promise<FactserverJsonException>) {
        this.detail$ = detail$
    }
}

class FactserverNetworkingError {}

class UnknownError {
    message: string
    constructor(message: string) {
        this.message = message
    }
}

class FactserverRequestFailed {
    response: FactserverErrorResponse
    constructor(body: FactserverErrorResponse) {
        this.response = body
    }
}

/**
 * Async api interface using rxjs.
 */
export class Api {
    private static async handle_status_code<RESPONSE>(response: Response): Promise<FactserverResponse<RESPONSE>> {
        if (response.ok) {
            return (await response.json()) as FactserverResponse<RESPONSE>
        }
        if (response.status === 401) {
            throw new NotAuthorizedError()
        }
        if (response.status === 0) {
            throw new FactserverNetworkingError()
        }
        throw new Error(`Unknown error, http status code ${response.status}`)
    }

    private static handle_functional_error<RESPONSE>(response: FactserverResponse<RESPONSE>): Observable<RESPONSE> {
        if (response.success) {
            return of(response.result)
        }
        throw new FactserverRequestFailed(response as unknown as FactserverErrorResponse)
    }

    static create_request_body<REQUEST>(data: REQUEST): FactserverRequest<FactserverRequestData> {
        const request_body: FactserverRequest<FactserverRequestData> = {
            data: data,
        }
        request_body.data._account_slug = current_account_slug()
        return request_body
    }

    api_password: string
    current_account_slug: string

    constructor() {
        this.api_password = btoa(`${config.api_user}:${config.api_password}`)
        this.current_account_slug = current_account_slug()
    }

    create_request<REQUEST>(
        api_host: string,
        endpoint: string,
        method: string,
        request_body?: REQUEST,
    ): Request {
        return new Request(`${api_host}/${endpoint}`, {
            method: method,
            headers: {
                Accept: 'application/json, text/*',
                'Accept-Encoding': 'gzip, deflate, br',
                'Content-Type': 'application/json; charset=UTF-8',
                'X-transaction-id': `disco-${generate_transaction_id()}`,
                Authorization: `Basic ${this.api_password}`,
                'X-Auth-Token': $s.identity.token,
                'X-Account-Slug': current_account_slug(),
            },
            body: JSON.stringify(request_body),
        })
    }

    // Return true if error needs to be reraised, false otherwise.
    private generic_error_handler(reason: unknown): boolean {
        if (reason instanceof NotAuthorizedError) {
            $m.identity.logout_debounced()
            return false
        } else if (reason instanceof ForbiddenError) {
            notifier.notify('Operation not allowed.', 'danger')
            return false
        } else if (reason instanceof BadRequestError) {
            from(reason.detail$).subscribe({
                next: (detail) => notifier.notify(`Bad request: ${detail.detail}`, 'danger'),
            })
            return false
        } else if (reason instanceof UnprocessableEntityError) {
            from(reason.detail$).subscribe({
                next: (detail) => notifier.notify(`Unprocessable entity: ${detail.detail}`, 'danger'),
            })
            return false
        } else if (reason instanceof FactserverNetworkingError) {
            // eslint-disable-next-line no-console
            console.log('A networking error happened.')
            return false
        } else if (reason instanceof FactserverRequestFailed) {
            notifier.notify(`Request cannot be completed: ${reason.response.message}`, 'danger')
            return true
        } else if (reason instanceof UnknownError) {
            generic_handler(reason.message)
            return true
        } else if (reason instanceof TypeError) {
            return false
        }
        generic_handler('')
        return true
    }

    /**
     * Issue a DELETE http request for the Fact2Server.
     * @param endpoint The endpoint to use.
     */
    delete<RESPONSE>(endpoint: string): Observable<RESPONSE> {
        const request = this.create_request(config.api_host_new, endpoint, 'DELETE')
        return this.with_error_handling(fromFetch(request))
    }

    /**
     * Issue a GET http request for the Fact2Server.
     * @param endpoint The endpoint to use.
     */
    get<RESPONSE>(endpoint: string): Observable<RESPONSE> {
        const request = this.create_request(config.api_host_new, endpoint, 'GET')
        return this.with_error_handling(fromFetch(request))
    }

    /**
     * Issue a GET http request for the Fact2Server.
     * @param endpoint The endpoint to use.
     */
    async get_async<RESPONSE>(endpoint: string) {
        const request = this.create_request(config.api_host_new, endpoint, 'GET')
        const response = await fetch(request)
        return await this.with_error_handling_async(response) as RESPONSE
    }

    async post_async<REQUEST, RESPONSE>(endpoint: string, request_body: REQUEST) {
        request_body['_account_slug'] = current_account_slug()
        const request = this.create_request(config.api_host_new, endpoint, 'POST', request_body)
        const response = await fetch(request)
        return await this.with_error_handling_async(response) as RESPONSE
    }

    async put_async<REQUEST, RESPONSE>(endpoint: string, request_body: REQUEST) {
        request_body['_account_slug'] = current_account_slug()
        const request = this.create_request(config.api_host_new, endpoint, 'PUT', request_body)
        const response = await fetch(request)
        return await this.with_error_handling_async(response) as RESPONSE
    }

    post_fact2server_request<REQUEST, RESPONSE>(endpoint: string, request_body: REQUEST): Observable<RESPONSE> {
        // @ts-ignore
        request_body['_account_slug'] = current_account_slug()
        const request = this.create_request(config.api_host_new, endpoint, 'POST', request_body)
        return this.with_error_handling(fromFetch(request))
    }

    /**
     * Issue a PUT http request to the Fact2Server.
     * @param endpoint Endpoint to use.
     * @param request_body The request body to PUT.
     */
    put<REQUEST, RESPONSE>(endpoint: string, request_body: REQUEST): Observable<RESPONSE> {
        request_body['_account_slug'] = current_account_slug()
        const request = this.create_request(config.api_host_new, endpoint, 'PUT', request_body)
        return this.with_error_handling(fromFetch(request))
    }

    /**
     * Issue a PATCH http request to the Fact2Server.
     * @param endpoint Endpoint to use.
     * @param request_body The request body to PUT.
     */
    patch<REQUEST, RESPONSE>(endpoint: string, request_body: REQUEST): Observable<RESPONSE> {
        request_body['_account_slug'] = current_account_slug()
        const request = this.create_request(config.api_host_new, endpoint, 'PATCH', request_body)
        return this.with_error_handling(fromFetch(request))
    }

    /**
     * Main method for interacting with the factserver.
     *
     * @param endpoint The rpc method to invoke.
     * @param request_body The body to send in the 'data'.
     * @return An observable with the response | null in case of an error.
     */
    post_request<REQUEST, RESPONSE>(endpoint: string, request_body: REQUEST): Observable<RESPONSE> {
        const body = Api.create_request_body(request_body)
        const request = this.create_request(config.api_host, endpoint, 'POST', body)

        return this.with_error_and_success_handling(fromFetch(request))
    }

    /** Deals with HTTP codes and the factserver response model, the boolean success. **/
    private with_error_and_success_handling<T>(fetch_data: Observable<Response>): Observable<T> {
        return fetch_data.pipe(
            switchMap((response: Response) => {
                return Api.handle_status_code<T>(response)
            }),
            switchMap((response: FactserverResponse<T>) => {
                return Api.handle_functional_error<T>(response)
            }),
            catchError((error) => {
                if (this.generic_error_handler(error)) {
                    return throwError(error)
                }
                return []
            }),
        )
    }

    /** Deals with HTTP codes 401 and 0, and NOT the boolean success. **/
    private with_error_handling<T>(fetch_data: Observable<Response>): Observable<T | Blob> {
        return fetch_data.pipe(
            switchMap(async(response: Response) => {
                const content_type = response.headers.get('content-type')
                if (response.ok && content_type === 'application/json') {
                    return (await response.json()) as T
                } else if (response.ok && content_type === 'application/zip') {
                    return await response.blob()
                } else if (response.ok && content_type === 'application/jpg') {
                    return await response.blob()
                } else if (response.ok && content_type === 'application/vnd.ms-excel') {
                    return await response.blob()
                } else if (response.ok && content_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    return await response.blob()
                } else if (response.status === 400) {
                    throw new BadRequestError(response.json())
                } else if (response.status === 401) {
                    throw new NotAuthorizedError()
                } else if (response.status === 403) {
                    throw new ForbiddenError()
                } else if (response.status === 420) {
                    throw new BadRequestError(response.json())
                } else if (response.status === 422) {
                    throw new UnprocessableEntityError(response.json())
                } else if (response.status === 0) {
                    throw new FactserverNetworkingError()
                }
                throw new Error(`Unknown error, http status code ${response.status}`)
            }),
            catchError((error) => {
                this.generic_error_handler(error)
                return throwError(error)
            }),
        )
    }

    /** Deals with HTTP codes 401 and 0, and NOT the boolean success. **/
    private async with_error_handling_async<T>(response: Response) {
        const content_type = response.headers.get('content-type')
        if (response.ok && content_type === 'application/json') {
            return (await response.json()) as T
        } else if (response.ok && content_type === 'application/zip') {
            return await response.blob()
        } else if (response.ok && content_type === 'application/jpg') {
            return await response.blob()
        } else if (response.ok && content_type === 'application/vnd.ms-excel') {
            return await response.blob()
        } else if (response.ok && content_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return await response.blob()
        } else if (response.status === 400) {
            throw new BadRequestError(response.json())
        } else if (response.status === 401) {
            throw new NotAuthorizedError()
        } else if (response.status === 403) {
            throw new ForbiddenError()
        } else if (response.status === 420) {
            throw new BadRequestError(response.json())
        } else if (response.status === 422) {
            throw new UnprocessableEntityError(response.json())
        } else if (response.status === 0) {
            throw new FactserverNetworkingError()
        }
        throw new Error(`Unknown error, http status code ${response.status}`)
    }
}

export function create_download_for_blob(blob: Blob, file_name: string): boolean {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = file_name
    a.type = blob.type
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
    return true
}

export function construct_query_parameters(
    offset: number,
    search_terms: string[],
    sort_column: string,
    sort_ascending: boolean,
    filters: unknown,
    page_size: number | null,
): URLSearchParams {
    const url_params = new URLSearchParams()
    url_params.set('offset', String(offset))
    url_params.set('page_size', String(page_size || 40))
    url_params.set('search_terms', search_terms.join(','))
    url_params.set('sort_by', sort_column)
    url_params.set('sort_ascending', sort_ascending ? 'ASC' : 'DESC')
    url_params.set('filters', JSON.stringify(filters))
    return url_params
}

export function construct_paged_query_parameters(
    page: number,
    search_terms: string[],
    sort_column: string,
    sort_ascending: boolean,
    filters: unknown,
    page_size: number | null,
): URLSearchParams {
    const url_params = new URLSearchParams()
    url_params.set('page', String(page))
    url_params.set('page_size', String(page_size))
    url_params.set('search_terms', search_terms.join(','))
    url_params.set('sort_by', sort_column)
    url_params.set('sort_ascending', sort_ascending ? 'ASC' : 'DESC')
    url_params.set('filters', JSON.stringify(filters))
    return url_params
}
