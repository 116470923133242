/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericEdit} from '@/data/generic/generic_edit'
import {Field} from '@/data/generic/generic_field'
import {$m} from '@/app'

export class VatTypeUpsert extends MithrilTsxComponent<any> {
    fields: Field[]

    constructor() {
        super()
        this.fields = [
            new Field('Code', 'vat_code', 'input', 'text'),
            new Field('Percentage', 'percentage', 'input', 'number', {min: 0, step: 0.01}),
            new Field('Description', 'description', 'textarea', 'text', {rows: 4, required: false}),
        ]
    }

    view(_vnode: m.Vnode<any>) {
        return <GenericEdit
            readable_entity_name="VAT type"
            base_url="/data/vattypes"
            query_api_call="vattypes.get_vat_type"
            update_or_create_api_call="vattypes.update_or_create_vat_type"
            fields={this.fields}
            create_entity_func={$m.data.vat_type.create}
            broadcast_on_update="vattypes_updated"
        />
    }
}
