import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'

import {LeafletMap} from '../components/adminlte/leaflet_map'

import {WidgetSelectionData, WidgetSelector} from './widgets/widget_selector'

import {Chart, create_time_line_graph} from '@/data/diana/components/graph_utils'
import {
    DashboardApi,
    MostPopularPage,
    PortalSessionPerDay,
    PortalUserActivitiesResponse,
    PortalUserSessionResponse,
} from '@/factserver_api/dashboard_api'

// eslint-disable-next-line @typescript-eslint/ban-types
export default class PortalDashboard extends MithrilTsxComponent<{}> {

    dashboard_api = new DashboardApi()
    portal_user_session_response: PortalUserSessionResponse | null = null
    is_loading_portal_user_sessions = false
    user_activities: PortalUserActivitiesResponse | null = null
    top_ten_pages: MostPopularPage[] = []

    from_period: DateTime = DateTime.local()
    till_period: DateTime = DateTime.local()

    sessions_per_day_chart: Chart | null = null
    portal_visitors_map: LeafletMap | null = null

    constructor() {
        super()
    }

    fetch_all(): void {
        this.load_portal_user_session(this.from_period.toISODate(), this.till_period.toISODate())
        this.load_portal_user_activities(this.from_period.toISODate(), this.till_period.toISODate())
        this.load_portal_user_session_locations(this.from_period.toISODate(), this.till_period.toISODate())
    }

    load_portal_user_activities(from: string, till: string): void {
        this.dashboard_api
            .portal_user_activities({
                period_from: from,
                period_till: till,
            })
            .subscribe((response) => {
                this.user_activities = response
                this.top_ten_pages = response.most_popular.sort((a, b) => (a.hits < b.hits ? 1 : -1)).splice(0, 10)
                m.redraw()
            })
    }

    load_portal_user_session(from: string, till: string): void {
        this.is_loading_portal_user_sessions = true
        this.dashboard_api
            .portal_user_sessions({
                period_from: from,
                period_till: till,
            })
            .subscribe((response) => {
                this.portal_user_session_response = response
                const map_function = (portalSessionPerDay: PortalSessionPerDay) => {
                    return {
                        x: portalSessionPerDay.day.split('T')[0],
                        y: portalSessionPerDay.count,
                    }
                }

                const graph_data = {
                    label: 'Number of sessions per day',
                    lineTension: 0,
                    spanGaps: true,
                    backgroundColor: ['rgba(255, 99, 132, .2)'],
                    borderColor: ['rgba(255, 99, 132, 1)'],
                    data: this.portal_user_session_response.sessions_per_day
                        .sort((a, b) => (a.day < b.day ? -1 : 1))
                        .map(map_function),
                }
                if (this.sessions_per_day_chart) {
                    this.sessions_per_day_chart.data.datasets = [graph_data]
                    this.sessions_per_day_chart?.update()
                }
                m.redraw()
                this.is_loading_portal_user_sessions = false
            })
    }

    load_portal_user_session_locations(from: string, till: string): void {
        this.dashboard_api
            .portal_user_session_location({
                period_from: from,
                period_till: till,
            })
            .subscribe((response) => {
                this.portal_visitors_map?.add_markers(response)
                m.redraw()
            })
    }

    on_widget_selection_changed(widget_selection: WidgetSelectionData): void {
        this.from_period = widget_selection.from_period
        this.till_period = widget_selection.till_period
        this.fetch_all()
    }

    oncreate(): void {
        const target = document.getElementById('sessions-per-day')
        this.sessions_per_day_chart = create_time_line_graph(
            {
                datasets: [],
            },
            target,
        )

        this.portal_visitors_map = new LeafletMap('js-portal-map', {
            zoom_level: 4,
        })
    }

    onremove(): void {
        this.sessions_per_day_chart?.destroy()
        this.portal_visitors_map?.destroy()
    }

    view(): m.Children {
        return (
            <div className="c-dashboard-portal view">
                <div className="c-filter-group">
                    <WidgetSelector
                        initial_period="MONTH"
                        selection_changed={(wd: WidgetSelectionData) => this.on_widget_selection_changed(wd)}
                    />
                </div>

                <div className="columns">
                    <div className="column is-4">
                        <div className="session-stats dashboard-widget">
                            <div className="widget-title">
                                <span className="text">Portal visitors</span>
                            </div>
                            <div className="widget-body">
                                <div className="table-stat">
                                    <div className="stat">
                                        <div className="key">Sessions</div>
                                        <div className="value">{this.portal_user_session_response?.number_of_sessions}</div>
                                    </div>
                                    <div className="stat">
                                        <div className="key">Orders - pending</div>
                                        <div className="value">{this.portal_user_session_response?.portal_pending}</div>
                                    </div>
                                    <div className="stat">
                                        <div className="key">Page views</div>
                                        <div className="value">{this.user_activities?.page_views}</div>
                                    </div>
                                    <div className="stat">
                                        <div className="key">Portal Orders</div>
                                        <div className="value">€ {Number(this.portal_user_session_response?.euro_total_portal_orders).formatMoney()}</div>
                                    </div>
                                    <div className="stat">
                                        <div className="key">Non Portal orders</div>
                                        <div className="value">€ {Number(this.portal_user_session_response?.euro_total_non_portal_orders).formatMoney()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column">
                                <canvas className="sessions-per-day" id="sessions-per-day" height="75" />
                                <div className="page-hits">
                                    {!this.top_ten_pages.length && <span>No data</span>}
                                    {this.top_ten_pages.map((thing) => (
                                        <div className="item">
                                            <div className="key">{thing.page}</div>
                                            <div className="value">{thing.hits}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-8">
                        <div className="portal-map" id="js-portal-map" />
                    </div>
                </div>
            </div>
        )
    }
}
