/**
 * Contains components and utilities relating to the users entity.
 */
import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {store} from '@bitstillery/common/app'
import {FieldSelect} from '@bitstillery/common/components'
import {FieldSelectAttrs} from '@bitstillery/common/types/field'
import {modelref_adapter, modelref_assign} from '@bitstillery/common/lib/store'
import {api} from '@bitstillery/common/app'

import {CheckboxGroup} from '@/components/filter/checkboxgroup'
import {$s} from '@/app'
import {GetAllUsersResponse} from '@/factserver_api/fact2server_api'

interface UsersDropDownAttrs extends FieldSelectAttrs {
    cache_key?: string
    selected_user_artkey?: string // override local storage option
    only_active_traders?: boolean
}

/**
 * A drop down with the msi-users. If the component has a cache_key, the last selected
 * user is stored in the local storage. This causes this component to survive browser reloads.
 *
 * Another option is to use the option selected_user_artkey. The local-storage will not be used,
 * the caller can do whatever it pleases with this selected artkey.
 */
export class UserDropDown extends MithrilTsxComponent<UsersDropDownAttrs> {

    data = proxy({
        options:  [] as GetAllUsersResponse[],
    })

    async oninit(vnode: m.Vnode<UsersDropDownAttrs>) {
        const query_param = '?only_active=true&only_traders=true'
        const {status_code, result} = await api.get<GetAllUsersResponse[]>(`discover/users${query_param}`)

        const {model_value, model_ref} = modelref_adapter(vnode.attrs.model)

        if (vnode.attrs.cache_key && !model_value) {
            const storage_value = Number($s.lookup[vnode.attrs.cache_key])
            modelref_assign(model_ref, storage_value)
            vnode.attrs.onchange(storage_value)
        }
        if (status_code > 299) {
            return
        }

        this.data.options = result
    }

    onchange(vnode: m.Vnode<UsersDropDownAttrs>, selected_artkey: string): void {
        const {model_ref} = modelref_adapter(vnode.attrs.model)
        if (vnode.attrs.cache_key) {
            $s.lookup[vnode.attrs.cache_key] = selected_artkey
            store.save()
        }

        modelref_assign(model_ref, selected_artkey)

        if (vnode.attrs.onchange) {
            vnode.attrs.onchange(selected_artkey)
        }
    }

    view(vnode: m.Vnode<UsersDropDownAttrs>): m.Children {
        return <FieldSelect
            disabled={vnode.attrs.disabled}
            label={vnode.attrs.label}
            model={vnode.attrs.model}
            onchange={(selected_artkey: string) => {
                this.onchange(vnode, selected_artkey)
            }}
            options={this.data.options.map((user) => ({
                label: user.name,
                value: user.artkey,
            }))}
            placeholder={vnode.attrs.placeholder || 'Select a user...'}
            validation={vnode.attrs.validation}
        />
    }
}

interface UserCheckboxAttrs {
    onchange: (user_artkey: string | null) => unknown

    filter_function: () => any
    filter_id: string
    filter_name: string

    only_active_traders: boolean
}

/**
 * Display the users in a checkbox group. Suitable for 'quickly' selecting some users.
 */
export class UserCheckboxGroup extends MithrilTsxComponent<UserCheckboxAttrs> {
    users: [number, string][] = []

    async oncreate() {
        const query_param = '?only_active=true&only_traders=true'
        const {status_code, result} = await api.get<GetAllUsersResponse[]>(`discover/users${query_param}`)
        if (status_code > 299) {
            return
        }

        result.map((usr) => [usr.artkey, usr.name]).forEach((usr) => this.users.push(usr))
    }

    view(vnode: m.Vnode<UserCheckboxAttrs>): m.Children {
        return (
            <CheckboxGroup
                filter_function={vnode.attrs.filter_function}
                key={this.users}
                filter_id={vnode.attrs.filter_id}
                filter_name={vnode.attrs.filter_name}
                filter_options={this.users}
            />
        )
    }
}
