/** llm:tested*/
import m from 'mithril'
import {Amount, FieldMoney, FieldSelect, Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'
import {SelectOption} from '@bitstillery/common/types/field'

import {AddToSalesOrder} from '../components/add_to_sales_order'

import {EditCustomOfferItem} from './components/edit_custom_offer_item'
import {EditPosition} from './components/edit_position'
import {ProcessManageOffer} from './processes'

import api from '@/api'
import {button_with_icon, icon_button, text_button} from '@/components/_buttons'
import {CollectionTable} from '@/components/collection_table'
import {Collection} from '@/components/collection/collection'
import {Modal} from '@/components/modal/modal'
import {header_with_popover} from '@/components/popover/popover'
import {formatDate, formatPercentage, maybeMap, pluralize} from '@/_utils'
import {CustomOfferItem, Offer, OfferType} from '@/models/offers'
import {OfferApi} from '@/factserver_api/offer_api'
import {convert_from_currency_to_euro} from '@/factserver_api/currencies'
import {Supplier} from '@/models/suppliers'
import {icon_with_popover} from '@/components/icon'
import {icon} from '@/components/icon'
import inputs from '@/components/inputs'
import {$m, $s} from '@/app'

interface AddToSalesButtonAttrs {
    record: any
    custom_offer_items: Collection
    item_to_add: (item: any) => void
    relation: () => any
}

export class OfferCustomProducts {
    private offer: any
    private relation_options = [] as SelectOption[]
    private offer_artkey: string
    private offer_api: OfferApi
    private custom_offer_items: Collection
    private preview_supplier_artkey: any
    private preview_supplier: any
    private preview_currency: any
    private bulk_update_mode: any
    private edit_position_for: any
    private email_batch_artkey: string
    private price_analysis: any
    private item_to_add: any
    private saving: any
    private is_exporting: any
    private bulk_quantity_updates = {}
    private bulk_price_updates = {}
    private bulk_is_special_updates = {}
    private is_adding_new_product = window.prop(false)

    constructor() {
        this.offer = window.prop(new Offer())
        this.offer_artkey = m.route.param('artkey')
        this.offer_api = new OfferApi()

        this.custom_offer_items = new Collection({
            additional_params: this.additional_params.bind(this),
            api_function_name: 'offer.get_custom_offer_items_for_offer',
            default_sort_by: 'position',
            default_sort_order: 'asc',
            query_limit: 25,
        })

        this.preview_supplier_artkey = window.prop('')
        this.preview_supplier = window.prop(null)
        this.preview_currency = window.prop($s.currencies.default)

        if (!this.custom_offer_items.supplier) {
            this.custom_offer_items.supplier = window.prop(new Supplier())
        }

        this.bulk_update_mode = window.prop(false)
        this.reset_bulk_update()

        this.edit_position_for = window.prop(null)
        this.email_batch_artkey = m.route.param('email_batch')
        this.price_analysis = window.prop(false)
        this.item_to_add = window.prop(null)
        this.saving = window.prop(false)
        this.is_exporting = window.prop(false)
    }

    oncreate(): void {
        this.query_offer(this.offer_artkey)

        $m.common.observable.subscribe('offer_updated', this, () => this.custom_offer_items.requery())
        $m.common.observable.subscribe('custom_offer_item_updated', this, () => this.custom_offer_items.requery())
    }

    additional_params(): Record<string, any> {
        return {
            offer_artkey: this.offer_artkey,
            supplier_artkey: this.preview_supplier_artkey(),
            price_analysis: this.price_analysis(),
        }
    }

    always_special(): boolean {
        return this.offer().offer_type() === OfferType.SPECIAL
    }

    close_add_to_sales_order(): void {
        this.item_to_add(null)
    }

    delete_custom_offer_item(record: any): void {
        record.is_deleting = true
        const data = {artkey: record.artkey}
        api.callAndThen('offer.delete_custom_offer_item', data, {
            success: () => {
                this.custom_offer_items.requery()
                record.is_deleting = false
                notifier.notify('Successfully deleted the custom offer item.', 'success')
            },
        })
    }

    export_pricelist(): void {
        this.is_exporting(true)
        this.offer_api.export_preview_price_list(
            this.additional_params().supplier_artkey,
            this.additional_params().offer_artkey,
            `Offer ${formatDate(new Date())}.xlsx`,
        ).subscribe({
            next: () => {
                this.is_exporting(false)
                m.redraw()
            },
            error: () => {
                this.is_exporting(false)
                m.redraw()
            },
        })
    }

    extract_rank(record: any): { rank: number; total: number } {
        return {
            rank: record.market_rank,
            total: record.market_total,
        }
    }

    margin_color_class(value: number): string {
        if (value > 8.8) {
            return '.analysis-good-color'
        } else if (value < 0) {
            return '.analysis-bad-color'
        }
        return ''
    }

    move_custom_offer_item(record: any, direction: string, callback: () => void): void {
        const data = {
            artkey: record.artkey,
            direction: direction,
        }

        api.callAndThen('offer.move_custom_offer_item', data, {
            success: callback,
        })
    }

    query_offer(artkey: string): void {
        const data = {
            artkey: artkey,
            include_suppliers: true,
        }

        api.callAndThen('offer.get_offer', data, {
            success: (resp: any) => {
                this.offer(new Offer(resp.result))
                this.relation_options.slice(0)
                resp.result.suppliers.map((it) => ({
                    value: it.artkey,
                    label: it.name,
                })).forEach(it => this.relation_options.push(it))

                if (resp.result.suppliers.length === 1) {
                    this.set_supplier(resp.result.suppliers[0].artkey)
                }

                this.custom_offer_items.init()
            },
            failure: () => {
                notifier.notify('Unknown offer.', 'danger')
                m.route.set('/offer/offers')
            },
        })
    }

    set_supplier(supplier_artkey: string): void {
        this.preview_supplier_artkey(`${supplier_artkey}`)
        if (supplier_artkey) {
            const data = {
                artkey: supplier_artkey,
            }
            api.callAndThen('suppliers.get_supplier', data, {
                success: (resp: any) => {
                    this.preview_supplier(resp.result)
                    this.preview_currency(this.preview_supplier().currency)
                },
            })
        } else {
            this.preview_supplier(null)
            this.preview_currency($s.currencies.default)
        }
        this.custom_offer_items.requery()
    }

    reset_bulk_update() {
        this.bulk_update_mode(false)
    }

    save_bulk_update() {
        const data = {
            quantity_updates: this.bulk_quantity_updates,
            price_updates: this.bulk_price_updates,
            is_special_updates: this.bulk_is_special_updates,
        }

        api.callAndThen('offer.bulk_update', data, {
            success: () => {
                this.custom_offer_items.requery()
                notifier.notify('Successfully updated the products.', 'success')
            },
            final: () => {
                this.reset_bulk_update()
            },
        })
    }

    toggle_price_analysis() {
        this.price_analysis(!this.price_analysis())
        this.custom_offer_items.requery()
    }

    publish_button_text(): string {
        return this.offer().published_timestamp() ? 'Offer is published' : 'Publish offer'
    }

    email_button_text(): string {
        return !this.offer().published_timestamp()
            ? 'Publish offer and compose new email message'
            : 'Compose new email message'
    }

    publish_offer(proceed: boolean) {
        if (!this.offer().published_timestamp() && !this.saving()) {
            this.saving(true)

            const data = {
                artkey: this.offer().artkey(),
            }

            api.callAndThen('offer.publish', data, {
                success: (resp: any) => {
                    $m.common.observable.broadcast('offer_updated')
                    notifier.notify(`Successfully published offer "${this.offer().title()}".`, 'success')
                    if (proceed) {
                        m.route.set(`/offer/offers/${this.offer_artkey}/email/create`)
                    } else {
                        this.offer(new Offer(resp.result))
                        this.saving(false)
                    }
                },
                failure: (resp: any) => {
                    notifier.notify(resp.message, 'danger')
                    this.saving(false)
                },
            })
        } else if (proceed && !this.saving()) {
            m.route.set(`/offer/offers/${this.offer_artkey}/email/create`)
        }
    }

    view(): m.Vnode {
        return <div class="c-process-new-offer-step-4 view process">
            {this.edit_position_for() &&
                <Modal
                    title={`Change position for ${this.edit_position_for().name}`}
                    onclose={() => this.edit_position_for(null)}
                >
                    <EditPosition
                        custom_offer_item={this.edit_position_for()}
                        max={this.custom_offer_items.total_number_of_items()}
                        onchangedsuccessfully={() => this.edit_position_for(null)}
                    />
                </Modal>
            }

            {this.item_to_add() &&
                <Modal
                    title={`Add ${this.item_to_add().name} to sales order`}
                    onclose={() => this.close_add_to_sales_order()}
                >
                    <AddToSalesOrder
                        custom_offer_item={this.item_to_add()}
                        on_added_item={() => this.close_add_to_sales_order()}
                        supplier_artkey={this.preview_supplier().artkey}
                        supplier_currency={this.preview_supplier().currency}
                    />
                </Modal>
            }

            <ProcessManageOffer
                active="custom_products"
                context={{
                    offer_artkey: this.offer_artkey,
                    email_batch_artkey: this.email_batch_artkey,
                }}
            />

            <div class="step-content offer offer-items">
                <div className='filter-result'>
                    <div class="c-filter-group">
                        {this.bulk_update_mode() ?  
                            ([
                                button_with_icon('Cancel bulk updates', 'ban-circle', {
                                    class: 'btn-default',
                                    onclick: () => {this.reset_bulk_update()},
                                }),
                                button_with_icon('Bulk update', 'fa-save', {
                                    class: 'btn-success',
                                    onclick: () => {this.save_bulk_update()},
                                }),
                            ]) : (
                                button_with_icon('Open bulk update mode', 'pencil', {
                                    class: 'btn-success',
                                    onclick: () => {this.bulk_update_mode(true)},
                                })
                            )
                        }
                    </div>
                    <div class="c-filter-group">

                        {button_with_icon(
                            this.publish_button_text(),
                            'glyphicon-globe',
                            {
                                class: this.offer().published_timestamp() ? 'btn-normal' : 'btn-success',
                                disabled: this.saving() || this.offer().published_timestamp(),
                                onclick: () => this.publish_offer(false),
                                title: 'This button instantly publishes the offer, which makes the prices in this offer visible in the portal for the selected relations. It keeps you on this page.',
                            },
                        )}

                        {button_with_icon(
                            this.email_button_text(),
                            'glyphicon-envelope',
                            {
                                class: 'btn-success',
                                disabled: this.saving(),
                                onclick: () => this.publish_offer(true),
                                title: 'This button takes you to the next page where you can compose an email message. If the offer is not already published, it will be published for you.',
                            },
                        )}
                    </div>
                    <div class="c-filter-group">
                        <div class="filter-field vertical">
                            <label>Optionally select a relation to preview its list prices</label>
                            <FieldSelect
                                options={this.relation_options}
                                placeholder={'Select a relation...'}
                                model={[this, 'preview_supplier_artkey']}
                                onchange={() => this.set_supplier(this.preview_supplier_artkey())}
                            />
                            <div class="c-filter-group">
                                {this.is_exporting() ? <Spinner /> : (
                                    button_with_icon(
                                        'Preview offer for relation',
                                        'fa-file-excel',
                                        {
                                            class: 'btn-default',
                                            disabled: !this.additional_params().supplier_artkey,
                                            onclick: () => this.export_pricelist(),
                                        },
                                    )
                                )}
                                {button_with_icon('Toggle price analysis (slow)', 'sort-by-order', {
                                    class: this.price_analysis() ? 'btn-info' : 'btn-default',
                                    onclick: () => {this.toggle_price_analysis()},
                                })}
                            </div>
                        </div>
                    </div>
                    <div class="c-filter-group">
                        <div class="btn-toolbar">
                            {button_with_icon(
                                'Add product to offer',
                                'plus',
                                {
                                    class: 'btn-default mb-2',
                                    onclick: () => this.is_adding_new_product(!this.is_adding_new_product()),
                                    'data-target': '#add_item',
                                    'data-toggle': 'collapse',
                                },
                            )}
                        </div>
                    </div>
                    {this.is_adding_new_product() && <EditCustomOfferItem
                        offer_artkey={this.offer_artkey}
                        always_special={this.always_special}
                        supplier_artkey={this.preview_supplier_artkey}
                    />}
                    <div className='table'>

                        {this.custom_offer_items.show_counter()}

                        <CollectionTable
                            collection={this.custom_offer_items}
                            options={{
                                search_table_style: true,
                                sticky_header: true,
                                with_buttons: false,
                                autoscale: true,
                                unique_name: 'offer_custom_offer_items',
                                onclick: (record: any) => {
                                    if (record.details_expanded()) {
                                        record.details_expanded(false)
                                    } else {
                                        record.details_expanded(true)
                                    }
                                },
                                row_classes: (record: any) => {
                                    if (record.excluded_for_supplier || record.quantity === 0) {
                                        return ['is-hidden']
                                    } else if (this.preview_supplier()) {
                                        const visibility = this.preview_supplier().portal_customs_visibility
                                        if (visibility in $m.data.customs_status && record.customs_status !== visibility) {
                                            return ['is-hidden']
                                        }
                                    }
                                    return []
                                },
                            }}
                            row_model={(row: any) => ({
                                ...row,
                                custom_offer_item_instance: new CustomOfferItem(row),
                            })}
                            view_details={(record: any) => (
                                <EditCustomOfferItem
                                    custom_offer_item_instance={record.custom_offer_item_instance}
                                    offer_artkey={this.offer_artkey}
                                    always_special={this.always_special}
                                    supplier_artkey={this.preview_supplier_artkey}
                                    onsaved={() => {
                                        record.details_expanded(false)
                                    }}
                                />
                            )}
                            columns={[
                                {
                                    width: 8,
                                    header: '',
                                    name: 'Position actions',
                                    function: (record: any) => (
                                        <div class="btn-toolbar no-click">
                                            {icon_button(
                                                'triangle-top',
                                                {
                                                    class: 'btn-default no-click',
                                                    disabled: record.position === 1,
                                                    onclick: () => this.move_custom_offer_item(record, 'up', () => this.custom_offer_items.requery()),
                                                    tabindex: this.bulk_update_mode() ? -1 : undefined,
                                                },
                                            )}
                                            {icon_button(
                                                'triangle-bottom',
                                                {
                                                    class: 'btn-default no-click',
                                                    disabled: record.position === this.custom_offer_items.items().length,
                                                    onclick: () => this.move_custom_offer_item(record, 'down', () => this.custom_offer_items.requery()),
                                                    tabindex: this.bulk_update_mode() ? -1 : undefined,
                                                },
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    width: 6,
                                    name: 'Position',
                                    sort: true,
                                    field: 'position',
                                    function: (record: any) => (
                                        <div class="btn-toolbar no-click">
                                            {text_button(
                                                record.position,
                                                {
                                                    class: 'btn-default',
                                                    onclick: () => this.edit_position_for(record),
                                                    tabindex: this.bulk_update_mode() ? -1 : undefined,
                                                    title: 'Change position',
                                                },
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    width: 4,
                                    name: 'Rank',
                                    sort: true,
                                    field: 'market_score',
                                    descending: true,
                                    default_visible: false,
                                    function: (record: any) => {
                                        if (this.price_analysis() && !this.custom_offer_items.loading()) {
                                            return <rank {...this.extract_rank(record)} />
                                        }
                                        return null
                                    },
                                },
                                {
                                    width: 4,
                                    header: header_with_popover(
                                        `${$s.currencies.default} above average price`,
                                        <a>
                                            <span class="glyphicon glyphicon-stats" />
                                            <span> €</span>
                                        </a>,
                                    ),
                                    name: `Above average (${$s.currencies.default})`,
                                    sort: true,
                                    field: 'avg_competitor_diff',
                                    classes: ['price'],
                                    descending: true,
                                    default_visible: false,
                                    function: (record: any) => {
                                        if (this.price_analysis() && !this.custom_offer_items.loading()) {
                                            return <Amount
                                                amount={+record.avg_competitor_diff}
                                                currency={$s.currencies.default}
                                                display_currency={$s.currencies.default}
                                                rate={1}
                                            />
                                        }
                                        return null
                                    },
                                },
                                {
                                    width: 4,
                                    header: header_with_popover(
                                        `${$s.currencies.default} above average in %`,
                                        <a>
                                            <span class="glyphicon glyphicon-stats" />
                                            <span> %</span>
                                        </a>,
                                    ),
                                    name: 'Percent above average price',
                                    sort: true,
                                    field: 'avg_competitor_diff_percentage',
                                    classes: ['number'],
                                    descending: true,
                                    default_visible: false,
                                    function: (record: any) => {
                                        if (this.price_analysis() && !this.custom_offer_items.loading()) {
                                            return `${(+record.avg_competitor_diff_percentage).toFixed(1)}%`
                                        }
                                        return null
                                    },
                                },
                                {
                                    width: 20,
                                    name: 'Productㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ', // This is a hack to increase the minimum column width
                                    sort: true,
                                    field: 'name',
                                    ellipsis: true,
                                },
                                {
                                    width: 6,
                                    name: 'Category',
                                    sort: true,
                                    field: 'category',
                                    ellipsis: true,
                                    function: (record: any) => {
                                        if (record.category) {
                                            return <span class="text-capitalize">{record.category}</span>
                                        }
                                        return <span>-</span>
                                    },
                                },
                                {
                                    width: 8,
                                    name: 'TBO supplier',
                                    ellipsis: true,
                                    function: (record: any) => {
                                        if (record.supplier && record.supplier.name) {
                                            return record.supplier.name
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 4,
                                    name: 'Btl / cs',
                                    sort: true,
                                    field: 'number_of_bottles',
                                    default_visible: false,
                                    classes: ['number'],
                                },
                                {
                                    width: 4,
                                    name: 'Size',
                                    sort: true,
                                    field: 'volume',
                                    default_visible: false,
                                    classes: ['number'],
                                    function: (record: any) => (
                                        <span>{(+record.volume).toFixed(1)}cl</span>
                                    ),
                                },
                                {
                                    width: 4,
                                    name: 'Alc %',
                                    sort: true,
                                    field: 'alcohol_percentage',
                                    default_visible: false,
                                    classes: ['number'],
                                    function: (record: any) => (
                                        <span>{(+record.alcohol_percentage).toFixed(1)}%</span>
                                    ),
                                },
                                {
                                    width: 3,
                                    name: 'Ref',
                                    sort: true,
                                    field: 'refill_status',
                                    default_visible: false,
                                },
                                {
                                    width: 12,
                                    name: 'Specs',
                                    function: (record: any) => {
                                        const specs = []
                                        specs.push(record.number_of_bottles)
                                        specs.push((+record.volume).toFixed(1))
                                        specs.push((+record.alcohol_percentage).toFixed(1))
                                        specs.push(record.refill_status)
                                        return specs.join(' / ')
                                    },
                                },
                                {
                                    width: 4,
                                    name: 'GB',
                                    sort: true,
                                    field: 'gift_box_type',
                                    default_visible: false,
                                    ellipsis: true,
                                },
                                {
                                    width: 4,
                                    name: 'Tax Label',
                                    sort: true,
                                    field: 'tax_label',
                                    default_visible: false,
                                    ellipsis: true,
                                },
                                {
                                    width: 5,
                                    header: 'BBD',
                                    name: 'Best before date',
                                    sort: true,
                                    field: 'best_before_date',
                                    default_visible: false,
                                    transform: maybeMap(formatDate),
                                },
                                {
                                    width: 8,
                                    name: 'Features',
                                    function: (record: any) => {
                                        const features: string[] = []
                                        if (record.gift_box_type) {
                                            features.push(record.gift_box_type)
                                        }
                                        if (record.tax_label) {
                                            features.push(record.tax_label)
                                        }
                                        if (record.best_before_date) {
                                            features.push(`BBD: ${formatDate(record.best_before_date)}`)
                                        }
                                        if (record.item_tags) {
                                            features.push(`${$m.data.item_tag.get_tag_names(record.item_tags).join(' / ')}`)
                                        }
                                        return features.join(' / ')
                                    },
                                },
                                {
                                    width: 3,
                                    header: 'Cus.',
                                    name: 'Customs status',
                                    sort: true,
                                    field: 'customs_status',
                                },
                                {
                                    width: 8,
                                    name: 'Stock value',
                                    sort: true,
                                    field: 'euro_total_stock_value',
                                    classes: ['price'],
                                    default_visible: false,
                                    function: (record: any) => {
                                        if (record.offer_item_type !== 'tbo') {
                                            return <Amount
                                                amount={+record.euro_total_stock_value}
                                                currency={$s.currencies.default}
                                            />
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 2,
                                    name: 'Number of cases in stock',
                                    header: icon_with_popover({
                                        iconId: 'home',
                                        content: 'Number of cases in stock',
                                    }),
                                    sort: true,
                                    field: 'number_of_cases_in_stock',
                                    classes: ['number'],
                                    default_visible: false,
                                },
                                {
                                    width: 2,
                                    name: 'Number of cases in purchase',
                                    header: icon_with_popover({
                                        iconId: 'shopping-cart',
                                        content: 'Number of cases in purchase',
                                    }),
                                    sort: true,
                                    field: 'number_of_cases_in_purchase',
                                    classes: ['number'],
                                    default_visible: false,
                                },
                                {
                                    width: 2,
                                    name: 'Number of cases in sales',
                                    header: icon_with_popover({
                                        iconId: 'screenshot',
                                        content: 'Number of cases in sales',
                                    }),
                                    sort: true,
                                    field: 'number_of_cases_in_sales',
                                    classes: ['number'],
                                    default_visible: false,
                                },
                                {
                                    width: 2,
                                    name: 'Number of cases available',
                                    header: icon_with_popover({
                                        iconId: 'fa-shield-alt',
                                        content: 'Number of cases available',
                                    }),
                                    sort: true,
                                    field: 'number_of_cases_available',
                                    classes: ['number'],
                                    default_visible: false,
                                },
                                {
                                    width: 3,
                                    name: 'Throughput',
                                    header: icon_with_popover({
                                        iconId: 'flash',
                                        content: 'Throughput',
                                    }),
                                    field: 'item_throughput',
                                    sort: true,
                                    classes: ['number'],
                                    default_visible: false,
                                    transform: maybeMap(formatPercentage),
                                },
                                {
                                    width: 2,
                                    header: 'MOQ',
                                    name: 'Minimum Order Quantity',
                                    sort: true,
                                    field: 'minimum_quantity',
                                    classes: ['number'],
                                    function: (record: any) => {
                                        if (record.minimum_quantity !== null) {
                                            return record.minimum_quantity
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 4,
                                    header: 'List qty',
                                    name: 'List quantity',
                                    sort: true,
                                    field: 'quantity',
                                    classes: ['number'],
                                    function: (record: any) => {
                                        if (this.bulk_update_mode()) {
                                            if (!record.input_quantity_prop) {
                                                record.input_quantity_prop = window.prop()
                                            }
                                            this.bulk_quantity_updates[record.artkey] = record.input_quantity_prop()
                                            return inputs.number(
                                                record.input_quantity_prop,
                                                {
                                                    min: 1,
                                                    required: true,
                                                    class: () => record.input_quantity_prop() === record.quantity
                                                        ? 'changed' : undefined,
                                                },
                                            )
                                        } else if (record.quantity !== null) {
                                            return record.quantity
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 8,
                                    name: 'Avg purchase / cs',
                                    sort: true,
                                    field: 'avg_purchase_price',
                                    classes: ['price'],
                                    function: (record: any) => {
                                        if (record.avg_purchase_price) {
                                            return <Amount
                                                amount={record.avg_purchase_price}
                                                currency={$s.currencies.default}
                                            />
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 8,
                                    name: 'Original price / cs',
                                    sort: true,
                                    field: 'original_price',
                                    classes: ['price'],
                                    function: (record: any) => {
                                        if (record.original_price) {
                                            return <Amount
                                                amount={+record.original_price}
                                                currency={$s.currencies.default}
                                            />
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 8,
                                    name: 'Current portal price / cs',
                                    sort: true,
                                    field: 'current_portal_price',
                                    classes: ['price'],
                                    function: (record: any) => {
                                        if (record.current_portal_price) {
                                            return <Amount
                                                amount={+record.current_portal_price}
                                                currency={record.supplier_currency}
                                                rate={$s.currencies.exchange_rates[record.supplier_currency].portal_rate}
                                            />
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 17,
                                    name: 'Custom price / cs',
                                    sort: true,
                                    field: 'custom_price_per_case',
                                    classes: ['price'],
                                    function: (record: any) => {
                                        if (this.bulk_update_mode()) {
                                            if (!record.input_price_prop) {
                                                record.input_price_prop = window.prop()
                                            }
                                            this.bulk_price_updates[record.artkey] = record.input_price_prop()
                                            return <FieldMoney
                                                className={
                                                    (record.input_price_prop() === '' && record.custom_price_per_case !== null) ||
                                                    (record.input_price_prop() !== '' && record.input_price_prop() !== null && record.custom_price_per_case === null) ||
                                                    +record.input_price_prop() !== +record.custom_price_per_case
                                                        ? 'changed'
                                                        : undefined
                                                }
                                                currency={[record, 'currency']}
                                                model={[record, 'input_price_prop']}
                                            />
                                        } else if (record.custom_price_per_case) {
                                            return <Amount
                                                amount={+record.custom_price_per_case}
                                                currency={record.currency}
                                                rate={$s.currencies.exchange_rates[record.currency].portal_rate}
                                            />
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 6,
                                    name: 'Margin %',
                                    classes: ['price'],
                                    function: (record: any) => {
                                        if (record.avg_purchase_price) {
                                            let price
                                            if (this.bulk_update_mode()) {
                                                if (record.input_price_prop() === '' || record.input_price_prop() === null) {
                                                    price = record.original_price
                                                } else {
                                                    price = record.input_price_prop()
                                                }
                                            } else if (record.custom_price_per_case) {
                                                price = convert_from_currency_to_euro(
                                                    record.custom_price_per_case,
                                                    record.currency,
                                                    $s.currencies.exchange_rates[record.currency].portal_rate,
                                                )
                                            } else {
                                                price = convert_from_currency_to_euro(
                                                    record.current_portal_price,
                                                    this.preview_currency(),
                                                    $s.currencies.exchange_rates[this.preview_currency()].portal_rate,
                                                )
                                            }

                                            const margin_percentage = ((price - record.avg_purchase_price) / record.avg_purchase_price) * 100
                                            return <span class={this.margin_color_class(margin_percentage)}>{margin_percentage.toFixed(2)}%</span>
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 8,
                                    name: 'List price / cs',
                                    sort: true,
                                    field: 'list_price',
                                    classes: ['price'],
                                    function: (record: any) => (
                                        <Amount
                                            amount={+record.list_price}
                                            currency={record.supplier_currency}
                                        />
                                    ),
                                },
                                {
                                    width: 8,
                                    name: 'List price / btl',
                                    classes: ['price'],
                                    default_visible: false,
                                    function: (record: any) => (
                                        <Amount
                                            amount={+record.list_price / +record.number_of_bottles}
                                            currency={record.supplier_currency}
                                            rate={$s.currencies.exchange_rates[record.supplier_currency].portal_rate}
                                        />
                                    ),
                                },
                                {
                                    width: 4,
                                    name: 'Delivery period',
                                    sort: true,
                                    field: 'delivery_period',
                                    classes: ['number'],
                                    function: (record: any) => {
                                        if (record.delivery_period !== null) {
                                            return `${record.delivery_period} ${pluralize(record.delivery_period, 'week', 'weeks')}`
                                        }
                                        return '-'
                                    },
                                },
                                {
                                    width: 8,
                                    name: 'Item type',
                                    sort: true,
                                    field: 'offer_item_type',
                                    transform: (offer_item_type: string) => {
                                        if (offer_item_type === 'tbo') return 'TBO'
                                        if (!offer_item_type) {
                                            return '-'
                                        }
                                        return offer_item_type.charAt(0).toUpperCase() + offer_item_type.slice(1)
                                    },
                                },
                                {
                                    width: 12,
                                    name: 'Remark',
                                    sort: true,
                                    field: 'remark',
                                    ellipsis: true,
                                },
                                {
                                    width: 4,
                                    name: 'Special',
                                    sort: true,
                                    field: 'is_special',
                                    function: (record: any) => {
                                        if (this.always_special()) {
                                            return <div class="disabled-table-column">
                                                {icon_with_popover({
                                                    iconId: 'ok',
                                                    content: 'The entire offer is special.',
                                                })}
                                            </div>
                                        } else if (this.bulk_update_mode()) {
                                            if (!record.input_is_special_price) {
                                                record.input_is_special_price = window.prop()
                                            }
                                            this.bulk_is_special_updates[record.artkey] = record.input_is_special_price()
                                            return <div class="form-group no-click">
                                                {inputs.checkbox(
                                                    record.input_is_special_price,
                                                    {
                                                        className: record.input_is_special_price() !== record.is_special
                                                            ? 'changed' : undefined,
                                                    },
                                                )}
                                            </div>
                                        } else if (record.is_special) {
                                            return icon_with_popover({
                                                iconId: 'ok',
                                                content: 'This product is a special offer.',
                                            })
                                        }
                                        return null
                                    },
                                },
                                {
                                    width: 2,
                                    name: 'Custom product icon',
                                    header: icon('glyphicon-cog'),
                                    function: (record: any) => {
                                        if (!record.offer_item_artkey) {
                                            return icon_with_popover({
                                                iconId: 'glyphicon-cog',
                                                title: 'Custom product',
                                                content: 'This product has been added manually.',
                                            })
                                        }
                                        return null
                                    },
                                },
                                {
                                    width: 2,
                                    name: 'Product photo icon',
                                    header: icon('fa-image'),
                                    field: 'has_product_photo',
                                    function: (record: any) => {
                                        if (record.has_product_photo) {
                                            return icon_with_popover({
                                                iconId: 'fa-image',
                                                title: 'Product photo',
                                                content: 'This product has one or more photos.',
                                            })
                                        }
                                        return null
                                    },
                                },
                                {
                                    width: 15,
                                    name: 'Actions',
                                    header: '',
                                    function: (record: any) => {
                                        if (record.is_deleting) {
                                            return <Spinner />
                                        }
                                        return <div class="btn-toolbar no-click">
                                            <div class="btn-group">
                                                {icon_button(
                                                    record.details_expanded() ? 'ban-circle' : 'pencil',
                                                    {
                                                        title: record.details_expanded() ? 'Close form' : 'Edit product',
                                                        class: 'btn-default no-click',
                                                        onclick: () => {
                                                            if (record.details_expanded()) {
                                                                record.details_expanded(false)
                                                            } else {
                                                                record.details_expanded(true)
                                                            }
                                                        },
                                                        tabindex: this.bulk_update_mode() ? -1 : undefined,
                                                    },
                                                )}
                                                {icon_button(
                                                    'remove',
                                                    {
                                                        title: 'Remove product',
                                                        class: 'btn-danger no-click',
                                                        onclick: () => this.delete_custom_offer_item(record),
                                                        tabindex: this.bulk_update_mode() ? -1 : undefined,
                                                    },
                                                )}
                                                <AddToSalesButton
                                                    record={record}
                                                    custom_offer_items={this.custom_offer_items}
                                                    item_to_add={this.item_to_add}
                                                    relation={this.preview_supplier}
                                                />
                                            </div>
                                        </div>
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}

// AddToSalesButton component
class AddToSalesButton extends MithrilTsxComponent<AddToSalesButtonAttrs> {
    constructor() {
        super()
    }

    view(vnode: m.Vnode<AddToSalesButtonAttrs>) {
        const {relation, record, item_to_add} = vnode.attrs
        const get_add_to_sales_button_disabled = () => {
            if (relation() === null || record.excluded_for_supplier || record.quantity === 0) {
                return 'disabled'
            }
            return undefined
        }

        const get_add_to_sales_button_title = () => {
            if (!relation()) {
                return 'Select a relation first'
            } else if (record.excluded_for_supplier) {
                return 'This product may not be sold to the selected relation'
            } else if (record.quantity === 0) {
                return 'This product is sold out'
            }
            return 'Add to sales order'
        }

        return icon_button(
            'screenshot',
            {
                title: get_add_to_sales_button_title(),
                class: 'btn-default',
                disabled: get_add_to_sales_button_disabled(),
                onclick: () => item_to_add(record),
            },
        )
    }
}

export default OfferCustomProducts
