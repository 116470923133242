/** llm:tested */
import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'

import utils from '@/_utils'
import {Popover} from '@/components/popover/popover'

interface PanelOptions {
    collapsible?: boolean
    unique_name?: string
    collapsed?: boolean
    description?: string
    download_callback?: () => void
    download_content?: any
    toolbar?: m.Children
}

const panel = (
    title: m.Children,
    body: m.Children,
    footer?: m.Children,
    options: PanelOptions = {},
): m.Vnode => {
    if (options.collapsible && !options.unique_name) {
        // eslint-disable-next-line no-console
        console.error('Please provide a unique_name if you want your panel to be collapsible')
    }

    const id = options.unique_name
    const _classes: string[] = []

    if (options.collapsible) {
        _classes.push('panel-collapse')
        _classes.push('collapse')
    }
    if (!options.collapsed) {
        _classes.push('in')
    }

    const description = options.description ? [
        ' ',
        <Popover
            title="Description"
            content={options.description}
        >
            <span class="glyphicon glyphicon-info-sign" />
        </Popover>,
    ] : null

    return (
        <div class={classes({
            'c-panel': true,
            collapsible: options.collapsible,
        })}>
            {title && (
                <div class="panel-heading">
                    {options.collapsible ? (
                        <div class="panel-title"
                            data-toggle="collapse"
                            data-target={'#' + id}
                        >
                            {title}
                            {description}
                            {options.download_callback && (
                                <a onclick={(e) => utils.stopPropagation(e)(options.download_callback)}>
                                    {options.download_content}
                                </a>
                            )}
                            {options.toolbar && (
                                <div class="toolbar" onclick={utils.stopPropagation}>
                                    {options.toolbar}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div class="panel-title">
                            {title}
                            {description}
                            {options.download_callback && (
                                <a onclick={options.download_callback}>
                                    {options.download_content}
                                </a>
                            )}
                            {options.toolbar && (
                                <div class="toolbar" onclick={utils.stopPropagation}>
                                    {options.toolbar}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <div class={_classes.join(' ')} id={id}>
                <div class="panel-body">
                    {body}
                </div>
                {footer && (
                    <div class="panel-footer">
                        {footer}
                    </div>
                )}
            </div>
        </div>
    )
}

export default panel
