/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GenericEdit} from '@/data/generic/generic_edit'
import {Field} from '@/data/generic/generic_field'
import {$m} from '@/app'

export class InsuranceTypesUpsert extends MithrilTsxComponent<any> {
    fields: Field[]

    constructor() {
        super()
        this.fields = [
            new Field('Name', 'name', 'input', 'text'),
            new Field('Fee (permillage)', 'fee', 'input', 'number', {min: 0, step: 0.0001}),
        ]
    }

    view(_vnode: m.Vnode<any>) {
        return <GenericEdit
            readable_entity_name="Insurance type"
            base_url="/data/insurancetypes"
            query_api_call="insurancetypes.get_insurancetype"
            update_or_create_api_call="insurancetypes.update_or_create_insurancetype"
            fields={this.fields}
            create_entity_func={$m.data.insurance_type.create_insurance_type}
            broadcast_on_update="insurancetypes_updated"
        />
    }
}
