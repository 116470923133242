/** llm:tested */
// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {deref} from '@bitstillery/common/utils'

import {accountIcon} from '@/accounts'
import {link} from '@/components/link'

interface ContactPerson {
    supplier: any
    artkey: any
    name: any
}

interface Relation {
    sales_account: any
    artkey: any
    name: any
}

export const contact_person_link = (contact_person: ContactPerson, options: Record<string, any> = {}) => {
    const supplier = deref(contact_person.supplier)
    return link(
        `/crm/relations/${deref(supplier.artkey)}/contact-persons/${deref(contact_person.artkey)}/edit`,
        deref(contact_person.name),
        options,
    )
}

export const relation_link = (relation: Relation) => [
    <span class="mr-05">{accountIcon(deref(relation.sales_account))}</span>,
    link(`/crm/relations/${deref(relation.artkey)}/edit`, deref(relation.name)),
]
