/** llm:tested */
import {events} from '@bitstillery/common/app'

import {MithrilProp} from '@/types'
import {$m} from '@/app'
import {Case, Item} from '@/models/stock'
import {PurchaseOrderItem} from '@/models/purchase_orders'
import {Product} from '@/models/products'

export class Bottle {
    artkey: MithrilProp<string | undefined>
    product: MithrilProp<Product>
    product_artkey: MithrilProp<string | undefined>
    volume: MithrilProp<string>
    alcohol_percentage: MithrilProp<string>
    refill_status: MithrilProp<string>
    excise_nl: MithrilProp<any>
    cbs_code: MithrilProp<any>
    purchase_order_items: MithrilProp<PurchaseOrderItem[]>
    cases: MithrilProp<Case[]>
    items: MithrilProp<Item[]>
    _refill_status: MithrilProp<string>
    _alcohol_percentage: MithrilProp<string>
    _volume: MithrilProp<string | null>

    constructor(
        artkey?: string,
        volume?: number,
        alcohol_percentage?: number,
        refill_status?: string,
        product_artkey?: string,
        purchase_order_items?: any[],
        items?: any[],
        cases?: any[],
        excise_nl?: any,
        product?: Product | null,
        cbs_code?: string,
    ) {
        this.artkey = window.prop(artkey)
        this.product = window.prop(product ? product : new Product())
        this.product_artkey = window.prop(product_artkey)
        this.volume = window.prop(volume !== null ? (+volume).toFixed(1) : '')
        this.alcohol_percentage = window.prop(alcohol_percentage !== null ? (+alcohol_percentage).toFixed(1) : '')
        this.refill_status = window.prop(refill_status || 'ref')
        this.excise_nl = window.prop(excise_nl)
        this.cbs_code = window.prop(cbs_code)

        this.purchase_order_items = window.prop([])
        if (purchase_order_items) {
            this.purchase_order_items(purchase_order_items.map(item => new PurchaseOrderItem(item)))
        }

        this.cases = window.prop([])
        if (cases) {
            this.cases(cases.map(kase => new Case(kase)))
        }

        this.items = window.prop([])
        if (items) {
            this.items(items.map(item => new Item(item)))
        }

        this._refill_status = window.prop(this.refill_status())
        this._alcohol_percentage = window.prop(this.alcohol_percentage())
        this._volume = window.prop(this.volume())
    }

    dirty(): boolean {
        return this._refill_status() !== this.refill_status() ||
            this._alcohol_percentage() !== this.alcohol_percentage() ||
            this._volume() !== this.volume()
    }

    reset = (): void => {
        this.alcohol_percentage(this._alcohol_percentage())
        this.refill_status(this._refill_status())
        this.volume(this._volume())
    }

    apply_update = (): void => {
        this._alcohol_percentage(this.alcohol_percentage())
        this._refill_status(this.refill_status())
        this._volume(this.volume())
    }

    to_string = (): string => {
        // (!) weare.product_categories doesn't exist; assumption: this function call never happens
        return [
            `${this.product().name()}`,
            `${this.volume()} cl`,
            `${this.alcohol_percentage()} %`,
            this.refill_status(),
            (window as any).weare.product_categories[this.product().category().toLowerCase()],
        ].join(', ')
    }

    to_specs = (): string => {
        return [
            (+this.volume()).toFixed(1) + 'cl',
            (+this.alcohol_percentage()).toFixed(1) + '%',
            this.refill_status(),
        ].join(' / ')
    }

    display_volume = (): string => {
        return (+this.volume()).toFixed(1) + 'cl'
    }

    display_alcohol_percentage = (): string => {
        return (+this.alcohol_percentage()).toFixed(1) + '%'
    }
}

export class BottlesDataModel {
    create_bottle = (bottle: any, product: Product): Bottle => {
        return new Bottle(
            bottle.artkey,
            bottle.volume,
            bottle.alcohol_percentage,
            bottle.refill_status,
            bottle.product_artkey,
            bottle.purchase_order_items,
            bottle.items,
            bottle.cases,
            bottle.excise_nl,
            product,
            bottle.cbs_code,
        )
    }

    create_bottles = (bottles: any[], product: Product): Bottle[] => {
        return bottles.map(bottle => this.create_bottle(bottle, product))
    }
}

export function bind() {
    events.on('identity.login', async() => {
        $m.bottles = new BottlesDataModel()
    })
}
