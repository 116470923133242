import m from 'mithril'
import {Button, ButtonGroup, Dialog} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

interface ShowDialogAttrs {
    body: () => m.Children
    confirm: {
        action: () => unknown
        icon: string
        text: string
    }
    model: {
        loading?: boolean
        type?: 'info' | 'default' | 'warning' | 'danger'
        title?: string
    }
}

/** Remove insert location and recreate the insert location. **/
function remove_element_from_dom() {
    const element = document.getElementById('confirmationLocation')
    const parent = element?.parentElement
    // recreate the insert location 'confimationLocation'
    const new_element = document.createElement('div')
    new_element.id = 'confirmationLocation'
    // remove and append to parent.
    element?.remove()
    parent?.append(new_element)
}

export function show_confirmation(attrs: ShowDialogAttrs) {
    const element = document.getElementById('confirmationLocation') as any

    m.mount(element, {
        view: () => {
            const confirmation = {
                ...attrs,
                footer: <ButtonGroup>
                    <Button
                        disabled={attrs.model.loading}
                        onclick={() => {
                            remove_element_from_dom()
                        }}
                        text={$t('dialog.cancel')}
                    ></Button>
                    {attrs.confirm && <Button
                        disabled={attrs.model.loading}
                        loading={attrs.model.loading}
                        icon={attrs.confirm.icon}
                        onclick={() => {
                            if (!attrs.confirm || !attrs.confirm.action) return
                            attrs.confirm.action()
                        }}
                        text={attrs.confirm.text}
                        type={attrs.model.type ? attrs.model.type : 'default'}
                    ></Button>}
                </ButtonGroup>,
                loading: attrs.model.loading,
                onclose: () => {
                    remove_element_from_dom()
                },
                title: attrs.model.title,
                type: attrs.model.type ? attrs.model.type : 'default',
            }

            return m(Dialog, confirmation, m({view: attrs.body}))
        },
    })

    return {
        close: () => {
            remove_element_from_dom()
        },
    }
}
