import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {required, validation} from '@bitstillery/common/lib/validation'
import {notifier} from '@bitstillery/common/app'
import {FieldSelect, FieldText} from '@bitstillery/common/components'

import {CheckBox} from '@/components/html_components'
import {DangerButton} from '@/components/buttons'
import {BackToListButton} from '@/components/discover'
import {EditAssist, Form} from '@/components/form'
import {LoggedInUserData, User} from '@/factserver_api/user_api'
import {LoenderslootTagApi, LoenderslootTag} from '@/factserver_api/loendersloot_tags_api'
import {GetItemTagResponse, ItemTagsDropDownData} from '@/factserver_api/item_tags'

export default class LoenderslootTagEdit extends MithrilTsxComponent<unknown> {
    api = new LoenderslootTagApi()
    create_or_update_loendersloot_tag_request: LoenderslootTag = proxy({
        code: '',
        description: '',
        unapprove_when_added: false,
        show_as_feature: false,
        is_portal_filter: false,
        forbid_in_stock: false,
        is_gift_box: false,
        item_tag_artkey: 1,
    })

    item_tags: GetItemTagResponse[] = []
    get_item_tag_response$ = ItemTagsDropDownData.item_tags$()

    edit_assist: EditAssist
    user: User | null = null
    $v = {
        code: validation([this.create_or_update_loendersloot_tag_request, 'code'], required()),
        description: validation([this.create_or_update_loendersloot_tag_request, 'description'], required()),
    }

    constructor() {
        super()
        this.edit_assist = new EditAssist(m.route)
        this.get_item_tag_response$.subscribe({
            next: (response) => (this.item_tags = response),
        })
    }

    oncreate(): void {
        LoggedInUserData.user().subscribe({
            next: (value) => {
                this.user = value
            },
        })

        if (this.edit_assist.artkey) {
            this.api.get(this.edit_assist.artkey).subscribe({
                next: (response) => {
                    Object.assign(this.create_or_update_loendersloot_tag_request, {
                        ...response,
                    })
                    m.redraw()
                },
                error: () => {
                    m.redraw()
                    m.route.set('/data/loendersloot-tags')
                },
            })
        }
    }

    create_or_update_loendersloot_tag(): void {
        if (this.edit_assist.is_creating) {
            this.create_loendersloot_tag()
        } else {
            this.update_loendersloot_tag()
        }

    }

    create_loendersloot_tag(): void {
        this.api.create(this.create_or_update_loendersloot_tag_request).subscribe({
            next: () => {
                notifier.notify(
                    'Successfully created loendersloot tag',
                    'success',
                )
                m.route.set('/data/loendersloot-tags')
            },
            error: () => {
                m.redraw()
            },
        })
    }

    update_loendersloot_tag(): void {
        this.api.update(this.create_or_update_loendersloot_tag_request).subscribe({
            next: () => {
                notifier.notify(
                    'Successfully updated loendersloot tag',
                    'success',
                )
                m.route.set('/data/loendersloot-tags')
            },
            error: () => {
                m.redraw()
            },
        })
    }

    delete_loendersloot_tag(): void {
        if (this.edit_assist.artkey) {
            this.api.delete(this.edit_assist.artkey).subscribe({
                next: () => {
                    notifier.notify('Successfully deleted the loendersloot tag', 'success')
                    m.route.set('/data/loendersloot-tags')
                },
                error: () => {
                    m.redraw()
                },
            })
        }
    }

    // Inputs and buttons are disabled for non-superusers.
    disabled(): boolean {
        if (!this.user) {
            return true
        }
        return !this.user.is_superuser
    }

    view(): m.Children {
        return (
            <div className="c-loendersloot-tag-edit view">
                <div className="btn-toolbar">
                    <BackToListButton href={'/data/loendersloot-tags'} />
                    {this.edit_assist.artkey && (
                        <DangerButton
                            icon_class={'glyphicon glyphicon-trash'}
                            onclick={() => this.delete_loendersloot_tag()}
                            title="Delete Loendersloot tag"
                            disabled={this.disabled()}
                        />
                    )}
                </div>

                <Form
                    disabled={this.disabled()}
                    onSubmit={() => this.create_or_update_loendersloot_tag()}
                    submitText={this.edit_assist.is_creating ? 'Create Loendersloot Tag' : 'Update Loendersloot Tag'}
                    validations={this.$v}
                >
                    <div className="fieldset">
                        <FieldText
                            disabled={this.disabled()}
                            label="Code"
                            model={[this.create_or_update_loendersloot_tag_request, 'code']}
                            placeholder="Code"
                            validation={this.$v.code}
                        />
                        <FieldText
                            disabled={this.disabled()}
                            label="Description"
                            model={[this.create_or_update_loendersloot_tag_request, 'description']}
                            placeholder={'Description'}
                            validation={this.$v.description}
                        />

                        <CheckBox
                            disabled={this.disabled()}
                            checked={this.create_or_update_loendersloot_tag_request.unapprove_when_added}
                            onchange={() => this.create_or_update_loendersloot_tag_request.unapprove_when_added = !this.create_or_update_loendersloot_tag_request.unapprove_when_added}
                        >
                            Unapprove when added
                        </CheckBox>

                        <CheckBox
                            disabled={this.disabled()}
                            checked={this.create_or_update_loendersloot_tag_request.show_as_feature}
                            onchange={() => {
                                this.create_or_update_loendersloot_tag_request.show_as_feature = !this.create_or_update_loendersloot_tag_request.show_as_feature
                                if (!this.create_or_update_loendersloot_tag_request.show_as_feature) {
                                    this.create_or_update_loendersloot_tag_request.is_portal_filter = false
                                }
                            }}
                        >
                            Show as feature
                        </CheckBox>

                        <CheckBox
                            disabled={this.disabled() || !this.create_or_update_loendersloot_tag_request.show_as_feature}
                            checked={this.create_or_update_loendersloot_tag_request.is_portal_filter}
                            onchange={() => this.create_or_update_loendersloot_tag_request.is_portal_filter = !this.create_or_update_loendersloot_tag_request.is_portal_filter}
                        >
                            Portal filter
                        </CheckBox>

                        <CheckBox
                            disabled={this.disabled()}
                            checked={this.create_or_update_loendersloot_tag_request.forbid_in_stock}
                            onchange={() => this.create_or_update_loendersloot_tag_request.forbid_in_stock = !this.create_or_update_loendersloot_tag_request.forbid_in_stock}
                        >
                            Forbid in stock
                        </CheckBox>

                        <CheckBox
                            disabled={this.disabled()}
                            checked={this.create_or_update_loendersloot_tag_request.is_gift_box}
                            onchange={() => this.create_or_update_loendersloot_tag_request.is_gift_box = !this.create_or_update_loendersloot_tag_request.is_gift_box}
                        >
                            Is gift box
                        </CheckBox>

                        {this.create_or_update_loendersloot_tag_request && <FieldSelect
                            disabled={this.disabled()}
                            label="Item Tag"
                            model={[this.create_or_update_loendersloot_tag_request, 'item_tag_artkey']}
                            options={Object.values(this.item_tags).map((item_tag) => ({
                                label: item_tag.name,
                                value: item_tag.artkey,
                            }))}
                        />}

                    </div>
                </Form>
            </div>
        )
    }
}
