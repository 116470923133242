/** llm:tested */
import m from 'mithril'
import {filter} from 'prelude-ls'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'

type FilterOption = [string, string]

interface CheckboxGroupAttrs {
    filter_function: () => string[]
    filter_id: string
    filter_name: string
    filter_options: FilterOption[]
    show_no_value_checkbox?: boolean
}

export class CheckboxGroup extends MithrilTsxComponent<CheckboxGroupAttrs> {
    private filter_function: any
    private filter_id: string
    private filter_name: string
    private filter_options: FilterOption[]
    private show_no_value_checkbox: boolean
    private select_all_id: string
    private checkbox_ids: string[]
    private collapsed: boolean
    private to_filter: string[]

    constructor(vnode: m.Vnode<CheckboxGroupAttrs>) {
        super()
        this.filter_function = vnode.attrs.filter_function
        this.filter_id = vnode.attrs.filter_id
        this.filter_name = vnode.attrs.filter_name
        this.filter_options = vnode.attrs.filter_options
        this.show_no_value_checkbox = vnode.attrs.show_no_value_checkbox || false

        // Define ids for checkboxes
        this.select_all_id = `${this.filter_id}_select_all`
        this.checkbox_ids = this.filter_options.map(([option_value]) =>
            `${this.filter_id}_${option_value}`,
        )

        if (this.show_no_value_checkbox) {
            this.checkbox_ids = this.checkbox_ids.concat([`${this.filter_id}_`])
        }

        this.collapsed = this.filter_function().length === 0
        this.to_filter = this.filter_function()
    }

    onupdate() {
        if (this.to_filter !== this.filter_function()) {
            let check_select_all = true
            for (const checkbox_id of this.checkbox_ids) {
                const checkbox = document.getElementById(checkbox_id) as HTMLInputElement
                const checked = this.filter_function().includes(checkbox.value)
                checkbox.checked = checked
                if (!checked) check_select_all = false
            }

            const select_all = document.getElementById(this.select_all_id) as HTMLInputElement
            select_all.checked = check_select_all

            this.collapsed = this.filter_function().length === 0
            this.to_filter = this.filter_function()
        }
    }

    private add_checkbox(option_value: string, option_name: string) {
        return (
            <div class="c-field-checkbox field">
                <div class="control">
                    <input {...this.checkbox_attrs(option_value)} />
                    <label for={`${this.filter_id}_${option_value}`}> {option_name}</label>
                </div>
            </div>
        )
    }

    private checkbox_attrs(option_value: string) {
        const attrs: any = {
            type: 'checkbox',
            value: option_value,
            id: `${this.filter_id}_${option_value}`,
            onclick: (e: Event) => {
                this.update_filter(e.target as HTMLInputElement)
            },
        }

        if (this.filter_function().includes(option_value)) {
            attrs.checked = true
        }

        return attrs
    }

    private collapse_css_class() {
        return this.collapsed ? ' collapse' : ' collapse show'
    }

    private toggle_all(select_all_checkbox: HTMLInputElement) {
        for (const checkbox_id of this.checkbox_ids) {
            const checkbox = document.getElementById(checkbox_id) as HTMLInputElement
            checkbox.checked = select_all_checkbox.checked
            this.update_checkbox(checkbox)
        }

        this.filter_function(this.to_filter)
    }

    private update_checkbox(checkbox: HTMLInputElement) {
        if (checkbox.checked) {
            if (!this.to_filter.includes(checkbox.value)) {
                this.to_filter.push(checkbox.value)
            }
        } else {
            this.to_filter = filter((x) => x !== checkbox.value, this.to_filter)
        }
    }

    private update_filter(checkbox: HTMLInputElement) {
        this.update_checkbox(checkbox)
        this.filter_function(this.to_filter)
    }

    view() {
        return (
            <div class={classes('c-checkbox-filter', {collapsed: this.collapsed})}>
                <a class="title"
                    href={`#${this.filter_id}`}
                    data-toggle="collapse"
                    onclick={() => {
                        this.collapsed = !this.collapsed
                    }}>
                    {this.collapsed ?
                        <span class="fas fa-plus-square"></span> :
                        <span class="fas fa-minus-square"></span>}
                    {` ${this.filter_name}${(this.to_filter && this.to_filter.length > 0) ? ' *' : ''}`}
                </a>

                <div class={`items ${this.filter_id}${this.collapse_css_class()}`}>
                    <div class="c-field-checkbox field">
                        <div class="control">
                            <input type="checkbox"
                                value=""
                                id={this.select_all_id}
                                onclick={(e: Event) => {
                                    this.toggle_all(e.target as HTMLInputElement)
                                }} />
                            <label for={this.select_all_id}> Select all</label>
                        </div>
                    </div>

                    {this.filter_options.map(([option_value, option_name]) =>
                        this.add_checkbox(option_value, option_name),
                    )}

                    {this.show_no_value_checkbox &&
                        this.add_checkbox('', ` No ${this.filter_name}`)}
                </div>
            </div>
        )
    }
}
