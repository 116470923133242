import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {required, validation} from '@bitstillery/common/lib/validation'
import {classes} from '@bitstillery/common/lib/utils'
import {notifier} from '@bitstillery/common/app'
import {FieldText, Spinner} from '@bitstillery/common/components'

import {BackToListButton} from '@/components/discover'
import {CheckBox} from '@/components/html_components'
import {EditAssist, Form} from '@/components/form'
import {ProductManagementApi, UpdateBrandHolderRequest} from '@/factserver_api/product_management_api'
import {HiddenBrandHolderHelper, HiddenInformation} from '@/purchase_orders/view_purchase_order/hidden_information'

type SelectableMainTab = 'edit-brand-holder' | 'hidden-information'

interface EditBrandHolderUrlParams {
    selected_tab?: SelectableMainTab
}

export default class BrandHolderEdit extends MithrilTsxComponent<unknown> {
    api = new ProductManagementApi()
    brand_holder: UpdateBrandHolderRequest = {
        name: '',
        requires_import_statement: false,
        hide_from_pricelist_for_countries: [],
        hide_from_pricelist_for_suppliers: [],
    }

    selected_tab: SelectableMainTab = 'edit-brand-holder'
    edit_assist: EditAssist
    is_loading = false
    $v = {
        name: validation([this.brand_holder, 'name'], required()),
    }

    constructor() {
        super()
        this.edit_assist = new EditAssist(m.route)
    }

    oncreate(): void {
        if (this.edit_assist.artkey) {
            this.fetch_brand_holder(this.edit_assist.artkey)
        }
        const params = m.route.param() as EditBrandHolderUrlParams
        if (params.selected_tab) {
            this.selected_tab = params.selected_tab
        }
    }

    update_url_params() {
        let route = m.route.get()
        if (route.includes('?')) {
            route = route.split('?')[0]
        }

        const params: EditBrandHolderUrlParams = {
            selected_tab: this.selected_tab,
        }
        m.route.set(route, params)
    }

    update_selected_tab(selected_tab: SelectableMainTab): void {
        this.selected_tab = selected_tab
        this.update_url_params()
    }

    fetch_brand_holder(artkey: number): void {
        this.is_loading = true
        this.api.get_brand_holder(artkey).subscribe({
            next: (response) => {
                Object.assign(this.brand_holder, {
                    ...response,
                    hide_from_pricelist_for_countries: response.hide_from_pricelist_for_countries.map(
                        (entry) => entry.country_code,
                    ),
                    hide_from_pricelist_for_suppliers: response.hide_from_pricelist_for_suppliers.map(
                        (entry) => `${entry.artkey}`,
                    ),
                })
                this.is_loading = false
                m.redraw()
            },
        })
    }

    update_brand_holder(brand_holder: UpdateBrandHolderRequest): void {
        this.api.update_or_create_brand_holder(brand_holder).subscribe({
            next: () => {
                notifier.notify('Brand holder updated', 'success')
                m.redraw()
            },
        })
    }

    view(): m.Children {
        if (this.is_loading) return <Spinner />
        return (
            <div className="c-data-brandholders-edit view">
                <div className="btn-toolbar">
                    <BackToListButton href={'/data/brand-holders'} />
                </div>
                <div className="c-tabs">
                    <ul className="nav nav-tabs">
                        <li
                            className={classes('nav-link', {
                                active: this.selected_tab === 'edit-brand-holder',
                            })}
                            onclick={() => this.update_selected_tab('edit-brand-holder')}
                        >
                            <a>Edit brand</a>
                        </li>
                        <li
                            className={classes('nav-link', {
                                active: this.selected_tab === 'hidden-information',
                            })}
                            onclick={() => this.update_selected_tab('hidden-information')}
                        >
                            <a>Hidden information</a>
                        </li>
                    </ul>
                    {this.selected_tab === 'hidden-information' && this.edit_assist.artkey &&
                        <HiddenInformation hidden_information_helper={new HiddenBrandHolderHelper(this.edit_assist.artkey)}/>
                    }
                    {this.selected_tab === 'edit-brand-holder' &&
                        <Form
                            onSubmit={() => this.update_brand_holder(this.brand_holder)}
                            submitText={this.edit_assist.is_creating ? 'Create Brand holder' : 'Update Brand holder'}
                            validations={this.$v}
                        >
                            <div className="fieldset">
                                <FieldText
                                    label="Name"
                                    placeholder={'Brand holder name'}
                                    model={[this.brand_holder, 'name']}
                                    validation={this.$v.name}
                                />

                                <div className="field">
                                    <CheckBox
                                        label="Requires import statement"
                                        checked={this.brand_holder.requires_import_statement}
                                        onchange={() =>
                                            (this.brand_holder.requires_import_statement =
                                    !this.brand_holder?.requires_import_statement)
                                        }
                                    />
                                </div>
                            </div>
                        </Form>}
                </div>
            </div>
        )
    }
}
